import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { RootState } from '../../../../types';

import DefaultLayout from '../../Unknown/DefaultLayout';
import OverviewLayout from '../../Unknown/OverviewLayout';

import WhiteListEditing from '../WhiteListEditing';
import WhiteList from '../WhiteList';

import messages from './messages';
import { useParams } from 'react-router';
import getFromToNumbers from '../../../common/getFromToNumbers';
import format from 'date-fns/format';
import { DEFAULT_DATE_FORMAT } from '../../../common/constants';
import { NormalizedBackofficeUser } from '../WhiteList/sortedBackofficeUsersData';

type QueryParams = {
  brandId: string;
};

const WhiteListPage: React.FC = () => {
  const { brandId }: QueryParams = useParams();

  const intl = useIntl();

  const [ipAddressesRowsWithoutFilter, setIpAddressesRowsWithoutFilter] =
    useState<NormalizedBackofficeUser[]>([]);

  const [selectedRow, setSelectedRow] =
    useState<null | NormalizedBackofficeUser>(null);
  const [ipAddressesRows, setIpAddressesRows] = useState(
    ipAddressesRowsWithoutFilter,
  );

  const { from, to } = useMemo(
    () => getFromToNumbers({ list: ipAddressesRows }),
    [ipAddressesRows],
  );

  const { brands } = useSelector((root: RootState) => root.globalData);

  const onCloseEditing = () => {
    setSelectedRow(null);
  };

  const path = [
    { label: intl.formatMessage(messages.system) },
    { label: intl.formatMessage(messages.ipAddresses) },
  ];

  return (
    <DefaultLayout path={path}>
      <OverviewLayout
        headerProps={{
          name: intl.formatMessage(messages.ipAddresses),
          from: format(from, DEFAULT_DATE_FORMAT),
          to: format(to, DEFAULT_DATE_FORMAT),
          brands: brands,
          defaultRows: ipAddressesRowsWithoutFilter,
          setRows: setIpAddressesRows,
          withBrandFilter: true,
          filteredKey: 'brandId',
        }}
      >
        <WhiteList
          ipAddressesRows={ipAddressesRows}
          onIpAddressesRowsWithoutFilterChange={setIpAddressesRowsWithoutFilter}
          setSelectedRow={setSelectedRow}
        />
      </OverviewLayout>
      <WhiteListEditing
        onClose={onCloseEditing}
        isOpen={selectedRow !== null}
        selectedRow={selectedRow}
        brandId={brandId}
      />
    </DefaultLayout>
  );
};

export default WhiteListPage;
