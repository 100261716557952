import { ExtendedBackOfficeUser } from '../../types';

interface CheckIsUserInBrandParams {
  brandId: string;
  user: ExtendedBackOfficeUser;
}

const checkIsUserInBrand = ({
  brandId,
  user,
}: CheckIsUserInBrandParams): boolean => {
  const roles = user?.roles;

  if (!roles?.length) return false;

  const isBrandUser = roles.some(
    ({ brand_id: userBrandId }) => userBrandId === brandId,
  );

  return isBrandUser;
};

export default checkIsUserInBrand;
