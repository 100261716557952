import { Box, UIContext } from '@miyagami-com/lsx-ui-components';
import { Switch, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../../../common/constants';

const ToggleMaintenanceMode = (): JSX.Element => {
  const [isActivateMaintenance, setIsActivateMaintenance] = useState(false);

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const intl = useIntl();
  const { setAlert } = useContext(UIContext);

  useEffect(() => {
    const getIsMaintenanceMode = httpsCallable(
      functions,
      'back-brand-callGetIsMaintenanceMode',
    );

    const fetchMaintenanceData = async () => {
      const maintenanceData = await getIsMaintenanceMode();
      const isMaintenanceActive = maintenanceData.data as boolean;

      setIsActivateMaintenance(isMaintenanceActive);
    };

    fetchMaintenanceData();
  }, [functions]);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const isShouldActivate = event.target.checked;

    const toggleMaintenanceMode = httpsCallable(
      functions,
      'back-brand-callToggleMaintenanceMode',
    );

    try {
      await toggleMaintenanceMode({ isShouldActivate });

      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.successToggleMaintenance),
      });
    } catch (error) {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.errorToggleMaintenance),
      });
    }

    setIsActivateMaintenance(isShouldActivate);
  };

  return (
    <Box display="flex" alignItems="center">
      <Switch checked={isActivateMaintenance} onChange={handleChange}></Switch>
      <Typography>
        {isActivateMaintenance ? (
          <FormattedMessage {...messages.deactivate} />
        ) : (
          <FormattedMessage {...messages.activate} />
        )}
      </Typography>
    </Box>
  );
};

export default ToggleMaintenanceMode;
