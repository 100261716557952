import { UseQueryResult, useQuery } from 'react-query';
import useSupabase from './useSupabase';
import { BrandApiKey } from '../../../types/supabase';

interface UseSharedApiKeyBrandIdsParams {
  apiKeyId: string | null;
}

const useSharedApiKeyBrandIds = (
  params: UseSharedApiKeyBrandIdsParams,
): UseQueryResult<BrandApiKey[] | null> => {
  const { apiKeyId } = params;

  const supabase = useSupabase();

  const queryResult = useQuery(
    ['shared_api_key_brand_ids', apiKeyId],
    async () => {
      const { data } = await supabase
        .from('brand_api_keys')
        .select('*')
        .eq('api_key_id', apiKeyId);

      return data;
    },
  );

  return queryResult;
};

export default useSharedApiKeyBrandIds;
