import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useFirebaseApp } from 'reactfire';
import { Player } from '../../../types/supabase';
import { DEFAULT_REGION } from '../constants';

interface UsePlayersParams {
  page: number;
  size: number;
  brandId?: string;
  isFetchAllPlayers?: boolean;
  parentPath: string;
  isSystemAdmin: boolean;
}

type GetAllPlayersParams = UsePlayersParams;

interface GetAllPlayersResult {
  playersResponse: {
    data: Player[];
    count: number | null;
  };
}

const usePlayers = (params: UsePlayersParams): typeof queryResult => {
  const {
    page,
    size,
    brandId,
    isFetchAllPlayers = false,
    parentPath,
    isSystemAdmin,
  } = params;

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const getAllPlayersFunction = httpsCallable<
    GetAllPlayersParams,
    GetAllPlayersResult
  >(functions, 'back-player-callGetAllPlayers');

  const getPlayers = useCallback(async () => {
    const response = await getAllPlayersFunction({
      page,
      size,
      brandId,
      isFetchAllPlayers,
      parentPath,
      isSystemAdmin,
    });

    const { data, count } = response.data.playersResponse;
    return { data, count };
  }, [
    page,
    size,
    brandId,
    isFetchAllPlayers,
    parentPath,
    getAllPlayersFunction,
    isSystemAdmin,
  ]);

  const queryResult = useQuery<{ data: Player[]; count: number | null }, Error>(
    ['allPlayers', page, size, brandId],
    getPlayers,
    {
      keepPreviousData: true,
      onError: (error) => error,
    },
  );

  return queryResult;
};

export default usePlayers;
