import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  modalActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    gap: theme.spacing(4),
  },
  modalAction: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 320,
    [theme.breakpoints.up('md')]: {
      width: 400,
    },
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
  },
}));

export default useStyles;
