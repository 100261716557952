import { Brand } from '../../../../types';
import { BrandApiKey } from '../../../../types/supabase';
import convertArrayToMap from '../../../common/convertArrayToMap';
import { ListItem } from './types';

interface NormalizeBrandListParams {
  brandApiKeys?: BrandApiKey[] | null;
  brands: Brand[];
}

const normalizeBrandList = (params: NormalizeBrandListParams): ListItem[] => {
  const { brands, brandApiKeys } = params;

  const brandApiKeyMap = convertArrayToMap({
    array: brandApiKeys || [],
    getId: (item) => item.brand_id,
  });

  const brandListItems = brands.map<ListItem>((brand) => {
    const brandApiKey = brandApiKeyMap.get(brand.id);

    return {
      id: brand.id,
      name: brand.name,
      isShared: !!brandApiKey,
    };
  });

  return brandListItems;
};

export default normalizeBrandList;
