import React from 'react';
import { Box, Button, Divider } from '@miyagami-com/lsx-ui-components';
import { ButtonProps } from '@mui/material';

type Action = {
  label: string;
  buttonProps: ButtonProps;
};

interface ActionsBoxProps {
  actions: Action[];
}

const ActionsBox: React.FC<ActionsBoxProps> = ({ actions }) => {
  return (
    <Box display="flex">
      {actions.map(({ label, buttonProps }, index) => {
        const isLastItem = actions.length - 1 === index;
        return (
          <Box key={index} display="flex">
            <Button color="inherit" {...buttonProps}>
              {label}
            </Button>
            {!isLastItem && <Divider orientation="vertical" />}
          </Box>
        );
      })}
    </Box>
  );
};

export default ActionsBox;
