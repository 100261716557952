import { GridRowData } from '@mui/x-data-grid';

const addPathToAgents = (agents: GridRowData[]): GridRowData[] => {
  return agents.map((agent: GridRowData) => {
    if (agent?.parent_path) {
      const path = agent.parent_path
        .split('/')
        .filter(
          (id: string) => agents.findIndex(({ id: aId }) => id === aId) !== -1,
        );

      return {
        ...agent,
        path: [...path, agent.id],
      };
    }

    return { ...agent, path: [agent.id] };
  });
};

export default addPathToAgents;
