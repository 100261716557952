import React from 'react';

import GuestRoot from '../GuestRoot';
import AuthenticatedRoot from '../AuthenticatedRoot';
import LoadingBox from '../LoadingBox';
import useCheckAndRedirectAuth from '../../../common/hooks/useCheckAndRedirectAuth';

const Root: React.FC = () => {
  const { isAuth, isLoading, authUser } = useCheckAndRedirectAuth();

  const { roles } = authUser;

  if (isLoading) {
    return <LoadingBox />;
  }

  if (isAuth && roles && roles.length > 0) {
    return <AuthenticatedRoot roles={roles} />;
  }

  return <GuestRoot />;
};

export default Root;
