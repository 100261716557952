import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types';

type UseRefetchBackofficeUsers = () => void;

const useRefetchBrand = (): UseRefetchBackofficeUsers => {
  const queryClient = useQueryClient();

  const { brandIds } = useSelector((state: RootState) => state.user);

  return async () => {
    await queryClient.refetchQueries(['brands']);
    await queryClient.refetchQueries(['brands', brandIds]);
  };
};

export default useRefetchBrand;
