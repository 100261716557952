import { v4 } from 'uuid';
import { ProfitLossReportLevel } from '../../../../types';
import { DetailedProfitReport } from '../../../common/hooks/useDetailedPLReport';

interface NormalizeReportsParams {
  reports: DetailedProfitReport[] | null;
  reportLevel: ProfitLossReportLevel;
}

export interface PLReportRow {
  id: string;
  refId: string | null;
  name: string;
  level: ProfitLossReportLevel;
  totalBets: number;
  tumOver: number;
  playerWinLoss: number;
  playerCommission: number;
  playerTotal: number;
  systemWinLoss: number;
  systemCommission: number;
  systemTotal: number;
  brandWinLoss: number;
  brandCommission: number;
  brandTotal: number;
  masterAgentWinLoss: number;
  masterAgentCommission: number;
  masterAgentTotal: number;
  superAgentWinLoss: number;
  superAgentCommission: number;
  superAgentTotal: number;
  agentWinLoss: number;
  agentCommission: number;
  agentTotal: number;
  payout: number;
  upLine: number;
}

const normalizeReports = (params: NormalizeReportsParams): PLReportRow[] => {
  const { reports } = params;

  if (!reports) return [];

  const rows = reports.map((reportResponse) => {
    return {
      id: v4(),
      refId: reportResponse.general.refId,
      name: reportResponse.general.name,
      level: reportResponse.general.level,
      totalBets: reportResponse.general?.totalBets,
      tumOver: reportResponse.general.tumOver,
      payout: reportResponse.payout,
      upLine: reportResponse.upLine,
      systemWinLoss: reportResponse?.totals?.systemAdmin?.winLoss,
      systemCommission: reportResponse?.totals?.systemAdmin?.commission,
      systemTotal: reportResponse?.totals?.systemAdmin?.total,
      brandWinLoss: reportResponse?.totals?.brand?.winLoss,
      brandCommission: reportResponse?.totals?.brand?.commission,
      brandTotal: reportResponse?.totals?.brand?.total,
      superAgentWinLoss: reportResponse?.totals?.superAgent?.winLoss,
      superAgentCommission: reportResponse?.totals?.superAgent?.commission,
      superAgentTotal: reportResponse?.totals?.superAgent?.total,
      masterAgentWinLoss: reportResponse?.totals?.masterAgent?.winLoss,
      masterAgentCommission: reportResponse?.totals?.masterAgent?.commission,
      masterAgentTotal: reportResponse?.totals?.masterAgent?.total,
      agentWinLoss: reportResponse?.totals?.agent?.winLoss,
      agentCommission: reportResponse?.totals?.agent?.commission,
      agentTotal: reportResponse?.totals?.agent?.total,
      playerWinLoss: reportResponse.player?.winLoss,
      playerCommission: reportResponse.player?.commission,
      playerTotal: reportResponse.player?.total,
    } as PLReportRow;
  });

  return rows;
};

export default normalizeReports;
