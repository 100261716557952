import React, { useState, useMemo } from 'react';

import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { format } from 'date-fns';
import { Box } from '@miyagami-com/lsx-ui-components';
// import { useFirebaseApp } from 'reactfire';

import { RootState } from '../../../../types';

import {
  WIDGET_STATUS,
  DEFAULT_DATE_FORMAT,
  // DEFAULT_REGION,
  WIDGET_POSITIONS,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
} from '../../../common/constants';
import DataGridTable from '../../Unknown/DataGridTable';
import DefaultLayout from '../../Unknown/DefaultLayout';
import StatusCell from '../../Unknown/StatusCell';
import ActionsBox from '../../Unknown/ActionsBox';
import OverviewLayout from '../../Unknown/OverviewLayout';

import WidgetAddition, { AddWidgetFormValues } from '../WidgetAddition';

import messages from './messages';
import getFromToNumbers from '../../../common/getFromToNumbers';
import useVerifyPermissions from '../../../common/hooks/useVerifyPermissions';
import groupingTimeCellGetter from '../../../common/groupingTimeCellGetter';
import useBrandWidgets from '../../../common/hooks/useBrandWidgets';

type QueryParams = {
  brandId: string;
};

const WidgetOverviewPage: React.FC = () => {
  const [pageSize, setPageSize] = useState(DEFAULT_ROWS_PER_PAGE_OPTIONS[5]);
  const { brandId }: QueryParams = useParams();

  const history = useHistory();
  const intl = useIntl();
  // const { setAlert } = useContext(UIContext);

  const { brands } = useSelector((root: RootState) => root.globalData);

  const { data: widgets, isLoading } = useBrandWidgets({ brandId });

  // const functions = useFirebaseApp().functions(DEFAULT_REGION);

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const handlePushToDetailPage = (id: string) => {
    history.push(`/b/${brandId}/widgets/${id}`);
  };

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const { from, to } = useMemo(
    () => getFromToNumbers({ list: widgets || [] }),
    [widgets],
  );

  const defaultColumnValues = {
    flex: 0.65,
    editable: false,
    filterable: false,
  };

  // const onDelete = async (widgetId: string) => {
  //   const deleteWidgetFunc = functions.httpsCallable(
  //     'back-widget-deleteWidget',
  //   );
  //   try {
  //     await deleteWidgetFunc({
  //       brandId,
  //       widgetId,
  //     });

  //     setAlert({
  //       show: true,
  //       severity: 'success',
  //       message: intl.formatMessage(messages.successDelete),
  //     });
  //   } catch (error) {
  //     setAlert({
  //       show: true,
  //       severity: 'warning',
  //       message: intl.formatMessage(messages.errorDelete),
  //     });
  //   }
  // };

  const { grantedPermissions, isSuspended, isBrandSuspended } =
    useVerifyPermissions([
      `brand/${brandId}/widget/update`,
      `brand/${brandId}/widget/delete`,
      `brand/${brandId}/widget/create`,
    ]);

  const isDisabled = isSuspended || isBrandSuspended;

  const isUpdatePermissionGranted = !!grantedPermissions?.widget?.update;

  // const isDeletePermissionGranted = !!grantedPermissions?.widget?.delete;

  const renderColumnActions = (params: GridRenderCellParams) => {
    if (!params?.row) return null;

    const widgetId = params.row.id;

    const actions = [
      {
        label: intl.formatMessage(messages.view),
        buttonProps: {
          onClick: () => handlePushToDetailPage(widgetId),
        },
      },
    ];

    if (isUpdatePermissionGranted) {
      const updateAction = {
        label: intl.formatMessage(messages.edit),
        buttonProps: {
          onClick: () => handlePushToDetailPage(widgetId),
        },
      };

      actions.push(updateAction);
    }

    return <ActionsBox actions={actions} />;
  };

  const columns: GridColumns = [
    {
      ...defaultColumnValues,
      field: 'id',
      hide: true,
      headerName: intl.formatMessage(messages.id),
      flex: 0.5,
    },
    {
      field: 'created_at',
      headerName: intl.formatMessage(messages.createdAt),
      ...defaultColumnValues,
      groupingValueGetter: groupingTimeCellGetter,
      renderCell: (params: GridRenderCellParams<string>) => {
        const createdAt = params.value;

        if (!createdAt) return null;

        const createdAtTime = new Date(createdAt);

        if (!createdAtTime) return null;

        const createdAtFormat = format(createdAtTime, DEFAULT_DATE_FORMAT);

        return createdAtFormat;
      },
    },
    {
      field: 'position',
      headerName: intl.formatMessage(messages.position),
      valueFormatter: (params) => {
        const widgetPosition = params.value as keyof typeof WIDGET_POSITIONS;

        const widgetPositionLabel = WIDGET_POSITIONS[widgetPosition]?.label;

        return widgetPositionLabel;
      },
      ...defaultColumnValues,
    },
    {
      field: 'title',
      headerName: intl.formatMessage(messages.title),
      ...defaultColumnValues,
    },
    {
      field: 'brand_id',
      headerName: intl.formatMessage(messages.brand),
      ...defaultColumnValues,
    },
    {
      field: 'status',
      headerName: intl.formatMessage(messages.status),
      ...defaultColumnValues,
      renderCell: (params: GridRenderCellParams) => {
        const status = params.value as keyof typeof WIDGET_STATUS;

        const widgetStatus = WIDGET_STATUS[status];

        if (!widgetStatus) return null;
        return <StatusCell {...widgetStatus} />;
      },
    },
    {
      ...defaultColumnValues,
      field: 'actions',
      groupable: false,
      headerName: intl.formatMessage(messages.actions),
      flex: 0.5,
      renderCell: renderColumnActions,
      minWidth: 150,
    },
  ];

  const isCreatePermissionGranted = !!grantedPermissions?.widget?.create;

  const onClickAdd = isCreatePermissionGranted
    ? () => {
        setIsOpenModal(true);
      }
    : undefined;

  const onCloseModal = () => {
    setIsOpenModal(false);
  };

  const initialValues: AddWidgetFormValues = useMemo(() => {
    return {
      title: '',
      description: '',
      link: '',
      brandId: brandId || '',
      position: 'topBar',
      image: null,
    };
  }, [brandId]);

  const path = [
    { label: intl.formatMessage(messages.cms) },
    { label: intl.formatMessage(messages.widgets) },
  ];

  return (
    <DefaultLayout path={path}>
      <OverviewLayout
        headerProps={{
          name: intl.formatMessage(messages.widgets),
          from: format(from, DEFAULT_DATE_FORMAT),
          to: format(to, DEFAULT_DATE_FORMAT),
          brands: brands,
          defaultRows: widgets || [],
          onClickAdd: onClickAdd,
          disabled: isDisabled,
        }}
      >
        <DataGridTable
          rows={widgets || []}
          tableKey="widgets"
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
          disableSelectionOnClick
          autoHeight
          rowHeight={35}
          headerHeight={35}
          loading={isLoading}
          groupingColDef={{
            hideDescendantCount: true,
          }}
        />
      </OverviewLayout>
      <Box px={4}>
        <WidgetAddition
          initialValues={initialValues}
          isOpen={isOpenModal}
          onClose={onCloseModal}
        />
      </Box>
    </DefaultLayout>
  );
};

export default WidgetOverviewPage;
