import { FC, PropsWithChildren, useMemo } from 'react';
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from '@miyagami-com/lsx-ui-components';
import { Form, Formik, FormikHelpers } from 'formik';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { object, string } from 'yup';
import useStyles from './useStyles';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';

type Props = {
  isOpen: boolean;
  onClose: () => void | Promise<void>;
  onConfirm: (code: string) => void | Promise<void>;
};

type FormValues = {
  code: string;
};

const TotpConfirmModal: FC<PropsWithChildren<Props>> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const validationSchema = useMemo(() => {
    return object().shape({
      code: string().required(intl.formatMessage(messages.codeRequired)),
    });
  }, [intl]);

  const onSubmitForm = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    try {
      await onConfirm(values.code);
    } catch (e) {
      actions.setFieldError('code', intl.formatMessage(messages.invalidCode));
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Modal open={isOpen}>
      <Box className={classes.modalContainer}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            marginBottom: 2,
          }}
        >
          <Typography variant="h4" component="h4" textAlign="center">
            <FormattedMessage {...messages.confirmTOTP} />
          </Typography>
          <Typography variant="body1" component="p" textAlign="center">
            <FormattedMessage {...messages.confirmTOTPDescription} />
          </Typography>
        </Box>

        <Formik
          initialValues={{ code: '' }}
          onSubmit={onSubmitForm}
          validationSchema={validationSchema}
        >
          {({ values, errors, handleBlur, handleChange, isSubmitting }) => (
            <Form>
              <Box>
                <TextField
                  name="code"
                  label="Enter code"
                  type="text"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.code}
                  fullWidth
                />

                {errors.code && (
                  <Typography variant="caption" color="error">
                    {errors.code}
                  </Typography>
                )}
              </Box>

              <Box display="flex" flexDirection="column" gap={1} sx={{ mt: 3 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={isSubmitting}
                >
                  <Typography variant="button">Confirm</Typography>
                </Button>

                <Button
                  type="button"
                  variant="text"
                  onClick={onClose}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1,
                    color: 'black',
                  }}
                  disableRipple
                >
                  <ArrowBackIcon />
                  <Typography variant="button" color="info">
                    <FormattedMessage {...messages.returnToSite} />
                  </Typography>
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default TotpConfirmModal;
