import { useCookies } from 'react-cookie';
import axios from 'axios';
import signInRequest, { SignInParams, SignInResponse } from '../signIn';
import { SESSION_EXPIRES_IN } from '../constants';
import useApiClient from './useApiClient';

type UseSignInParams = Omit<SignInParams, 'axiosInstance'>;

const useSignIn = (): typeof signIn => {
  const [, setCookie] = useCookies();

  const axiosInstance = useApiClient();

  const signIn = async (
    params: UseSignInParams,
  ): Promise<SignInResponse | null> => {
    try {
      const { data } = await signInRequest({ ...params, axiosInstance });

      const { session, key } = data;

      setCookie(key, session, {
        secure: true,
        sameSite: 'lax',
        maxAge: SESSION_EXPIRES_IN,
      });

      return data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        throw new Error((error.response?.data as Error)?.message);
      }

      throw error;
    }
  };

  return signIn;
};

export default useSignIn;
