import { defineMessages } from 'react-intl';

export default defineMessages({
  sports: {
    id: 'Exchange.ExchangeSettingsPage.sports',
    defaultMessage: 'Sports',
  },
  leagues: {
    id: 'Exchange.ExchangeSettingsPage.leagues',
    defaultMessage: 'League',
  },
  marketTypes: {
    id: 'Exchange.ExchangeSettingsPage.marketTypes',
    defaultMessage: 'Market type',
  },
  events: {
    id: 'Exchange.ExchangeSettingsPage.events',
    defaultMessage: 'Events',
  },
  limits: {
    id: 'Exchange.ExchangeSettingsPage.limits',
    defaultMessage: 'Limits',
  },
  sportline: {
    id: 'Exchange.ExchangeSettingsPage.sportline',
    defaultMessage: 'Sportline',
  },
  controls: {
    id: 'Exchange.ExchangeSettingsPage.controls',
    defaultMessage: 'Controls',
  },
  exchange: {
    id: 'Exchange.ExchangeSettingsPage.exchange',
    defaultMessage: 'Exchange',
  },
  exchangeSettings: {
    id: 'Exchange.ExchangeSettingsPage.exchangeSettings',
    defaultMessage: 'Exchange settings',
  },
  dateRange: {
    id: 'Exchange.ExchangeSettingsPage.dateRange',
    defaultMessage: 'Date range',
  },
});
