import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgressWithLabel,
  LoadingButton,
} from '@miyagami-com/lsx-ui-components';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Formik, Form } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

import messages from './messages';
import useStyles from './useStyles';
import validationSchema from './validationSchema';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import { SetVerificationId } from '../../Unknown/AuthenticationContext';

export type SubmitPhoneValues = {
  phone: string;
  isResend?: boolean;
};

export type SubmitCodeValues = {
  code: string;
};

type MfaFormProps = {
  onSubmitPhone?: CallableFunction;
  onSubmitMfaCode: CallableFunction;
  onBack: CallableFunction;
  isFirstAuthentication?: boolean;
  phoneNumber?: string | null;
  onVerificationIdChange?: SetVerificationId;
};

const MfaForm: React.FC<MfaFormProps> = ({
  onSubmitPhone,
  onSubmitMfaCode,
  onBack,
  isFirstAuthentication,
  phoneNumber,
  onVerificationIdChange,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [isDisabled, setIsDisabled] = useState<boolean>(
    !!isFirstAuthentication,
  );
  const [progressSeconds, setProgressSeconds] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgressSeconds((prevProgress) =>
        prevProgress === 0 ? 0 : prevProgress - 1,
      );
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const initialValues = useMemo(() => {
    return {
      phone: phoneNumber,
      code: '',
    };
  }, [phoneNumber]);

  useEffect(() => {
    if (phoneNumber) setProgressSeconds(60);
  }, [phoneNumber]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={
        isFirstAuthentication && onSubmitPhone
          ? (values, formikHelpers) =>
              onSubmitPhone({ phone: values.phone }, formikHelpers)
          : (values, formikHelpers) =>
              onSubmitMfaCode({ code: values.code }, formikHelpers)
      }
      validationSchema={validationSchema}
    >
      {({
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        values,
        isSubmitting,
        setSubmitting,
      }) => {
        return (
          <Form>
            <Box px={3} pt={4} pb={3}>
              {onVerificationIdChange && !isFirstAuthentication && (
                <Box pb={3} mt={-1}>
                  <Button
                    color="inherit"
                    onClick={() => onVerificationIdChange(null)}
                    startIcon={<ArrowBackIosIcon />}
                  >
                    <FormattedMessage {...messages.changeNumber} />
                  </Button>
                </Box>
              )}
              <Box pb={2}>
                {isFirstAuthentication ? (
                  <PhoneInput
                    country={'nl'}
                    value={values.phone}
                    inputStyle={{ paddingTop: '15px', paddingBottom: '15px' }}
                    dropdownStyle={{ borderRadius: 'unset' }}
                    onChange={(phone) => setFieldValue('phone', phone)}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    isValid={(value, { iso2 }: any) => {
                      const isPossible = isPossiblePhoneNumber(
                        value,
                        iso2.toUpperCase(),
                      );

                      setIsDisabled(!isPossible);
                      return true;
                    }}
                    placeholder={intl.formatMessage(messages.phoneNumber)}
                  />
                ) : (
                  <TextField
                    name="code"
                    label={intl.formatMessage(messages.mfaCode)}
                    fullWidth
                    variant="outlined"
                    error={Boolean(errors.code)}
                    onChange={handleChange}
                    onBlur={handleBlur as any} // eslint-disable-line
                  />
                )}
              </Box>
              <Box
                pt={1}
                display="flex"
                justifyContent={
                  isFirstAuthentication ? 'flex-end' : 'space-between'
                }
                alignItems="center"
              >
                {!isFirstAuthentication && (
                  <>
                    {phoneNumber ? (
                      <>
                        {progressSeconds === 0 && onSubmitPhone ? (
                          <Button
                            variant="text"
                            color="inherit"
                            onClick={() =>
                              onSubmitPhone(
                                {
                                  phone: phoneNumber,
                                  isResend: true,
                                },
                                { setSubmitting },
                              )
                            }
                          >
                            <FormattedMessage {...messages.resendCode} />
                          </Button>
                        ) : (
                          <CircularProgressWithLabel value={progressSeconds} />
                        )}
                      </>
                    ) : (
                      <Box
                        onClick={() => onBack()}
                        className={classes.pointer}
                        color="primary.dark"
                        display="flex"
                        alignItems="center"
                      >
                        <ArrowBackIosIcon sx={{ fontSize: 10 }} />
                        <Typography>
                          <FormattedMessage {...messages.back} />
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
                <Box>
                  <LoadingButton
                    classes={{ root: classes.button }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    id="sign-in-button"
                    disabled={isDisabled}
                    loading={isSubmitting}
                  >
                    <FormattedMessage {...messages.confirm} />
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MfaForm;
