import { Grid } from '@miyagami-com/lsx-ui-components';
import React from 'react';
import { BackOfficeUser } from '../../../../types';
import TransactionCreation from '../../Transaction/TransactionCreation';
import TransactionsOverview from '../../Transaction/TransactionsOverview';
import useBrandOwnerSenderList from './useBrandOwnerSenderList';

interface BrandOwnerBalancePageProps {
  user: BackOfficeUser;
  brandId: string;
}

const BrandOwnerBalancePage: React.FC<BrandOwnerBalancePageProps> = (props) => {
  const { user, brandId } = props;

  const senderList = useBrandOwnerSenderList({ user, brandId });

  const isDisplayTransactions = user && Boolean(senderList?.length);

  return (
    <Grid container spacing={4} pt={4}>
      <Grid item xs={12}>
        <TransactionsOverview brandId={brandId} userId={user.id} />
      </Grid>
      {isDisplayTransactions && (
        <>
          <Grid item xs={12} md={6}>
            <TransactionCreation
              currentUser={user}
              type="transfer"
              userType="backofficeUser"
              senderList={senderList}
              brandId={brandId}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TransactionCreation
              currentUser={user}
              type="withdraw"
              userType="backofficeUser"
              senderList={senderList}
              brandId={brandId}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default BrandOwnerBalancePage;
