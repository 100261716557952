import React from 'react';
import { Typography } from '@miyagami-com/lsx-ui-components';
import {
  GridAggregationFunction,
  GridAggregationInitialState,
  GridValueFormatterParams,
} from '@mui/x-data-grid-premium';
import { format, startOfDay, subMinutes } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { ProfitLossReportLevel } from '../../../../types';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_CURRENCY,
  PL_REPORT_DETAILED_BASE_URL,
} from '../../../common/constants';
import { DateRange } from '@mui/x-date-pickers-pro';

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  // style: 'currency',
  currency: DEFAULT_CURRENCY,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const aggregationRowTitle: GridAggregationFunction = {
  apply: () => (
    <Typography variant="subtitle2" fontWeight={700}>
      <FormattedMessage {...messages.total} />
    </Typography>
  ),
};

const getTimezoneOffset = (value: Date) => value.getTimezoneOffset() * 60000;

export const makeLocalAppearUTC = (value: DateRange<Date>): DateRange<Date> => {
  const start = new Date(value[0] as Date);
  const end = new Date(value[1] as Date);

  return [
    new Date(start.getTime() + getTimezoneOffset(start)),
    new Date(end.getTime() + getTimezoneOffset(end)),
  ] as DateRange<Date>;
};

export const localToUTC = (
  value: DateRange<Date>,
  endTimeString: Date | null,
): DateRange<Date> => {
  const start = new Date(value[0] as Date);
  const end = new Date(value[1] as Date);

  const endDateExists = value[1] !== null;
  return [
    new Date(start.getTime() - getTimezoneOffset(start)),
    endDateExists && endTimeString
      ? new Date(end.getTime() - getTimezoneOffset(end))
      : new Date(endTimeString as Date),
  ] as DateRange<Date>;
};

export const customSumAggregation: GridAggregationFunction = {
  apply: ({ values }) => {
    const total = values.reduce((acc, curr) => acc + curr, 0);
    const color = total < 0 ? 'error.main' : 'inherit';
    // const formattedValue = currencyFormatter.format(total);

    return (
      <Typography fontSize={14} color={color}>
        {/* {formattedValue} */}
        {(+total).toFixed(2)}
      </Typography>
    );
  },
};

export const aggregationModel: GridAggregationInitialState['model'] = {
  name: 'aggregationRowTitle',
  totalBets: 'sum',
  tumOver: 'sum',
  payout: 'customSumAggregation',
  playerWinLoss: 'customSumAggregation',
  playerCommission: 'customSumAggregation',
  playerTotal: 'customSumAggregation',
  agentWinLoss: 'customSumAggregation',
  agentCommission: 'customSumAggregation',
  agentTotal: 'customSumAggregation',
  masterAgentWinLoss: 'customSumAggregation',
  masterAgentCommission: 'customSumAggregation',
  masterAgentTotal: 'customSumAggregation',
  superAgentWinLoss: 'customSumAggregation',
  superAgentCommission: 'customSumAggregation',
  superAgentTotal: 'customSumAggregation',
  brandWinLoss: 'customSumAggregation',
  brandCommission: 'customSumAggregation',
  brandTotal: 'customSumAggregation',
  systemWinLoss: 'customSumAggregation',
  systemCommission: 'customSumAggregation',
  systemTotal: 'customSumAggregation',
  upLine: 'customSumAggregation',
};

export const currencyValueFormatter = ({
  value,
}: GridValueFormatterParams): React.ReactNode => {
  if (typeof value !== 'number') {
    return value;
  }
  return currencyFormatter.format(value);
};

export const getReportLevelHref = ({
  reportLevel,
  brandId,
  id,
}: {
  reportLevel: ProfitLossReportLevel;
  brandId: string;
  id: string;
}): string => {
  const encodedId = encodeURIComponent(id);

  const urlByReportLevel: Record<ProfitLossReportLevel, string> = {
    systemAdmin: `/b/${encodedId}/${PL_REPORT_DETAILED_BASE_URL}/brand`,
    brand: `/b/${brandId}/${PL_REPORT_DETAILED_BASE_URL}/superAgent/${encodedId}`,
    superAgent: `/b/${brandId}/${PL_REPORT_DETAILED_BASE_URL}/masterAgent/${encodedId}`,
    masterAgent: `/b/${brandId}/${PL_REPORT_DETAILED_BASE_URL}/agent/${encodedId}`,
    agent: `/b/${brandId}/player-overview/${encodedId}/exchange/bets`,
    player: `/b/${brandId}/player-overview/${encodedId}/exchange/bets`,
  };

  return urlByReportLevel[reportLevel];
};

export const convertToUTCDate = (date: string | number | Date): Date => {
  const currentDate = date instanceof Date ? date : new Date(date);

  const utcDate = Date.UTC(
    currentDate.getUTCFullYear(),
    currentDate.getUTCMonth(),
    currentDate.getUTCDate(),
    currentDate.getUTCHours(),
    currentDate.getUTCMinutes(),
  );

  return startOfDay(new Date(utcDate));
};

export const withUTCOffset = (date: Date | string | null): Date | null => {
  if (!date) return null;
  const dateObj = date instanceof Date ? date : new Date(date);
  const offset = dateObj.getTimezoneOffset();
  return subMinutes(dateObj, offset);
};

export const getFormattedDate = (date: Date | null): string | null => {
  return date && format(convertToUTCDate(date), DEFAULT_DATE_FORMAT);
};
