import React, { useState, useEffect, useMemo, useContext } from 'react';
import { CollapseBox, UIContext } from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import { useQuery } from 'react-query';

import { LogType } from '../../../../types';

import IpAddressCell from '../../Unknown/IpAddressCell';

import messages from './messages';
import normalizeLogs, { NormalizedLog } from './normalizeLogs';
import capitalizeFirstLetter from '../../../common/capitalizeFirstLetter';
import DataGridTable from '../../Unknown/DataGridTable';
import getUserLogs from './getUserLogs';

interface LogUserListProps {
  label: string;
  type: LogType;
  userId: string;
  tableKey: string;
}

const defaultColumnParams = {
  editable: false,
  sortable: true,
  filterable: false,
  flex: 1,
};

const rowsPerPageOptions = [5, 10, 15, 25, 50, 100];

const LogUserList: React.FC<LogUserListProps> = ({
  label,
  type,
  userId,
  tableKey,
}) => {
  const [rows, setRows] = useState<NormalizedLog[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(rowsPerPageOptions[5]);

  const { setAlert } = useContext(UIContext);

  const offset = page * pageSize;
  const limit = offset + pageSize - 1;

  const {
    data: logsData,
    isFetching,
    isError,
  } = useQuery(['getLogs', type, userId, offset, limit], () =>
    getUserLogs({ type, userId, offset, limit }),
  );

  const intl = useIntl();

  useEffect(() => {
    const normalizedLogs = normalizeLogs({ logs: logsData?.data || [] });

    setRows(normalizedLogs);
  }, [logsData]);

  useEffect(() => {
    if (isError) {
      setAlert({
        message: intl.formatMessage(messages.errorLogsMessage),
        severity: 'error',
        show: true,
      });
    }
  }, [intl, isError, setAlert]);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const onPageSizeChange = (size: number) => {
    setPageSize(size);
  };

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'id',
        headerName: intl.formatMessage(messages.id),
        ...defaultColumnParams,
      },
      {
        field: 'date',
        headerName: intl.formatMessage(messages.date),
        ...defaultColumnParams,
      },
      {
        field: 'time',
        headerName: intl.formatMessage(messages.time),
        ...defaultColumnParams,
      },
      {
        field: 'action',
        headerName: intl.formatMessage(messages.action),
        ...defaultColumnParams,
        valueFormatter: (params) =>
          capitalizeFirstLetter(params.value?.toString()),
      },
      {
        field: 'ip_address',
        headerName: intl.formatMessage(messages.ipAddress),
        ...defaultColumnParams,
        renderCell: (params: GridRenderCellParams<string, NormalizedLog>) => {
          if (!params.row) return null;

          const { ip_address, country_code } = params.row;

          return (
            <IpAddressCell ipAddress={ip_address} countryCode={country_code} />
          );
        },
      },
    ],
    [intl],
  );

  return (
    <CollapseBox defaultValue label={label}>
      <DataGridTable
        withoutPadding
        tableKey={tableKey}
        columns={columns}
        loading={isFetching}
        rows={rows}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
        rowsPerPageOptions={rowsPerPageOptions}
        disableSelectionOnClick
        autoHeight
        rowCount={logsData?.count || 0}
        rowHeight={35}
        headerHeight={35}
        page={page}
        onPageChange={onPageChange}
        hideFooterRowCount
        pagination
        paginationMode="server"
      />
    </CollapseBox>
  );
};

export default LogUserList;
