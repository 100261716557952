import React from 'react';
import { IntlProvider as IntlProviderBase } from 'react-intl';

import translations from '../../../common/translations';

const IntlProvider: React.FC = ({ ...props }) => {
  return (
    <IntlProviderBase
      key="en"
      locale="en"
      messages={translations.en}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default IntlProvider;
