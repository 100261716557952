import { useCallback, useMemo } from 'react';
import { GridRenderCellParams, GridColumns } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import { RootState, UserRole } from '../../../../types';

import useBrandId from '../../../common/hooks/useBrandId';
import useCheckBrandIsSuspend from '../../../common/hooks/useCheckBrandIsSuspend';
import useUserStatuses from '../../../common/hooks/useUserStatuses';
import useUserFlaggedStatuses from '../../../common/hooks/useUserFlaggedStatuses';

import StatusCell from '../../Unknown/StatusCell';
import ActionsBox from '../../Unknown/ActionsBox';

import messages from './messages';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';

const propertiesColumn = {
  editable: false,
  filterable: false,
};

interface UseColumnsParams {
  onRefetch: () => void;
}

const useColumns = (props: UseColumnsParams): typeof columns => {
  if (props) {
  }

  const history = useHistory();

  const selectedBrandId = useBrandId();

  const intl = useIntl();

  const userStatuses = useUserStatuses();

  const userFlaggedStatuses = useUserFlaggedStatuses();

  const { roles } = useSelector((root: RootState) => root.user);

  const isAdmin = useMemo(() => {
    return checkIsSystemAdmin({ roles });
  }, [roles]);

  const isBrandSuspended = useCheckBrandIsSuspend();

  const { permissionMap } = useSelector((root: RootState) => root.user);

  const onPushToDetail = useCallback(
    (id: string, brandId: string) => {
      history.push(`/b/${brandId}/player-overview/${id}`);
    },
    [history],
  );

  const columns: GridColumns = useMemo(
    () => [
      {
        ...propertiesColumn,
        field: 'id',
        // hide: true,
        headerName: intl.formatMessage(messages.id),
        flex: 0.3,
        renderCell: (params: GridRenderCellParams) => {
          const getHash = (input: string) =>
            input
              .split('')
              .reduce(
                (hash, char) => ((hash << 5) - hash + char.charCodeAt(0)) | 0,
                0,
              );

          return getHash(params.value.slice(0, 5));
        },
      },
      {
        ...propertiesColumn,
        field: 'name',
        headerName: intl.formatMessage(messages.name),
        flex: 0.4,
      },
      {
        ...propertiesColumn,
        field: 'surname',
        headerName: intl.formatMessage(messages.surname),
        flex: 0.4,
      },
      {
        ...propertiesColumn,
        field: 'email',
        headerName: intl.formatMessage(messages.email),
        flex: 0.5,
      },
      {
        ...propertiesColumn,
        field: 'exposure',
        headerName: intl.formatMessage(messages.exposure),
        flex: 0.5,
        renderCell: (params) => {
          const exposure = params?.value > 0 ? params.value : 0;

          if (isAdmin && params?.value < 0) {
            console.log(`e.${params?.value}^${params.row.id.slice(0, 5)}`);
          }

          if (isAdmin) return `${params?.value.toFixed(0)}`;

          return `${exposure.toFixed(0)}`;
        },
      },
      {
        ...propertiesColumn,
        field: 'balance',
        headerName: intl.formatMessage(messages.balance),
        flex: 0.5,
        renderCell: (params) => {
          const balance = params?.value || 0;

          if (isAdmin && params?.value < 0) {
            console.log(`b.${params?.value}^${params.row.id.slice(0, 5)}`);
          }

          if (isAdmin) return `${params?.value.toFixed(0)}`;

          return `${balance.toFixed(0)}`;
        },
      },
      {
        ...propertiesColumn,
        field: 'status',
        headerName: intl.formatMessage(messages.status),
        flex: 0.35,
        renderCell: (params: GridRenderCellParams) => {
          if (!params.value) return null;
          const status = params.value as keyof typeof userStatuses;

          if (isBrandSuspended) {
            const userStatus = userStatuses.suspend;

            return <StatusCell {...userStatus} />;
          }

          const userStatus = userStatuses[status];

          if (!userStatus) return null;

          return <StatusCell {...userStatus} />;
        },
      },
      {
        ...propertiesColumn,
        field: 'system_admin_flagged',
        headerName: intl.formatMessage(messages.flagged),
        flex: 0.35,
        hide: !isAdmin,
        renderCell: (params: GridRenderCellParams) => {
          const userLimits = params.row.userLimits as Array<{
            system_admin_flagged: boolean;
          }>;

          const systemAdminFlagged = userLimits[0]?.system_admin_flagged;

          const flaggedStatus = systemAdminFlagged
            ? userFlaggedStatuses.flagged
            : userFlaggedStatuses.unflagged;

          return <StatusCell {...flaggedStatus} />;
        },
      },
      {
        ...propertiesColumn,
        field: 'actions',
        groupable: false,
        headerName: intl.formatMessage(messages.actions),
        flex: 0.5,
        renderCell: (params: GridRenderCellParams) => {
          if (!params.row) return null;

          const { id, brand_id } = params.row;

          const role = params.row.role as Exclude<UserRole, 'systemAdmin'>;

          const brandPermissionMap = permissionMap[selectedBrandId];

          const rolePermission = brandPermissionMap?.[role];

          const actions = [];

          const isHaveEditAccess = !!rolePermission?.update;

          if (isHaveEditAccess) {
            const editAction = {
              label: intl.formatMessage(messages.editUser),
              buttonProps: {
                onClick: () => onPushToDetail(id, brand_id),
              },
            };

            actions.push(editAction);
          }

          return <ActionsBox actions={actions} />;
        },
      },
    ],
    [
      intl,
      isAdmin,
      isBrandSuspended,
      userStatuses,
      userFlaggedStatuses,
      permissionMap,
      selectedBrandId,
      onPushToDetail,
    ],
  );

  return columns;
};

export default useColumns;
