import { makeStyles } from '@mui/styles';

import { borderWidth } from '../../../common/theme';

const useStyles = makeStyles(() => ({
  parent: {
    position: 'relative',
  },
  backdrop: {
    position: 'absolute',
    borderRadius: borderWidth.l,
  },
}));

export default useStyles;
