import { FC } from 'react';
import MarketTypeSettings from '../../Unknown/MarketTypeSettings';
import HeaderWithFilter from '../../Unknown/HeaderWithFilter';
import { RootState } from '../../../../types';
import { useSelector } from 'react-redux';

interface ExchangeSettingsMarketTypesProps {
  isPlayerExchangePage?: boolean;
  playerId?: string;
}

const ExchangeSettingsMarketTypes: FC<ExchangeSettingsMarketTypesProps> = ({
  playerId,
  isPlayerExchangePage,
}) => {
  const { brands } = useSelector((root: RootState) => root.globalData);

  return (
    <>
      {!isPlayerExchangePage && (
        <HeaderWithFilter name={null} brands={brands} />
      )}
      <MarketTypeSettings playerId={playerId} />
    </>
  );
};

export default ExchangeSettingsMarketTypes;
