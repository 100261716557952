import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Formik, FormikHelpers } from 'formik';

import {
  Box,
  Divider,
  LoadingButton,
  TextField,
  Typography,
} from '@miyagami-com/lsx-ui-components';

import { DomainFormValues } from '../BrandDomains';

import useBrandId from '../../../common/hooks/useBrandId';
import useVerifyPermissions from '../../../common/hooks/useVerifyPermissions';

import { RootState } from '../../../../types';

import validationSchema from './validationSchema';
import messages from './messages';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';

type BrandDomainAdditionProps = {
  onSubmit: ((
    values: DomainFormValues,
    formikHelpers: FormikHelpers<DomainFormValues>,
  ) => Promise<void>) &
    CallableFunction;
};

const initialValues: DomainFormValues = {
  domain: '',
};

const BrandDomainAddition: React.FC<BrandDomainAdditionProps> = ({
  onSubmit,
}) => {
  const brandId = useBrandId();

  const { roles } = useSelector((root: RootState) => root.user);
  const { brands } = useSelector((state: RootState) => state.globalData);

  const brand = brands.find((uploadedBrand) => uploadedBrand.id === brandId);

  const isSystemAdmin = checkIsSystemAdmin({ roles });

  const { grantedPermissions, isSuspended, isBrandSuspended } =
    useVerifyPermissions([`brand/${brandId}/domains/create`]);

  const isDisabled =
    !grantedPermissions?.domains?.create ||
    !isSystemAdmin ||
    brand?.status !== 'active' ||
    isSuspended ||
    isBrandSuspended;

  const intl = useIntl();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        handleChange,
        handleBlur,
        errors,
        values,
        isSubmitting,
        touched,
      }) => {
        const error = (name: keyof DomainFormValues) =>
          Boolean(errors[name] && touched[name]);

        return (
          <Form>
            <Box p={3}>
              <Typography variant="h6">
                <FormattedMessage {...messages.addNewDomain} />
              </Typography>
              <Box pt={4} pb={2}>
                <TextField
                  name="domain"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={error('domain')}
                  fullWidth
                  label={intl.formatMessage(messages.domain)}
                  value={values.domain}
                  variant="outlined"
                  helperText={errors.domain}
                  disabled={isDisabled}
                />
              </Box>
            </Box>
            <Divider />
            <Box p={3}>
              <LoadingButton
                disabled={isDisabled}
                loading={isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
              >
                <FormattedMessage {...messages.save} />
              </LoadingButton>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BrandDomainAddition;
