import { defineMessages } from 'react-intl';

export default defineMessages({
  addWidget: {
    id: 'Widget.WidgetAddition.addWidget',
    defaultMessage: 'Add Widget',
  },
  widget: {
    id: 'Widget.WidgetAddition.widget',
    defaultMessage: 'Widget',
  },
  title: {
    id: 'Widget.WidgetAddition.title',
    defaultMessage: 'Title',
  },
  turkish: {
    id: 'Widget.WidgetAddition.turkish',
    defaultMessage: 'Turkish',
  },
  description: {
    id: 'Widget.WidgetAddition.description',
    defaultMessage: 'Description',
  },
  descriptionPlaceholder: {
    id: 'Widget.WidgetAddition.descriptionPlaceholder',
    defaultMessage: 'TU',
  },
  link: {
    id: 'Widget.WidgetAddition.link',
    defaultMessage: 'Link',
  },
  httpsLink: {
    id: 'Widget.WidgetAddition.httpsLink',
    defaultMessage: 'https://xbet888.com',
  },
  image: {
    id: 'Widget.WidgetAddition.image',
    defaultMessage: 'Image',
  },
  imagePng: {
    id: 'Widget.WidgetAddition.imagePng',
    defaultMessage: 'image.png',
  },
  save: {
    id: 'Widget.WidgetAddition.save',
    defaultMessage: 'Save',
  },
  brand: {
    id: 'Widget.WidgetAddition.brand',
    defaultMessage: 'Brand',
  },
  textSuccessfully: {
    id: 'Widget.WidgetAddition.textSuccessfully',
    defaultMessage: 'Widget was successfully created!',
  },
  imageNotFound: {
    id: 'Widget.WidgetAddition.imageNotFound',
    defaultMessage: 'Image not found.',
  },
  position: {
    id: 'Widget.WidgetAddition.position',
    defaultMessage: 'Position',
  },
  positionAndImage: {
    id: 'Widget.WidgetAddition.positionAndImage',
    defaultMessage: 'Position & Image',
  },
});
