import React, { useState } from 'react';
import { Box, Grid } from '@miyagami-com/lsx-ui-components';
import { useParams } from 'react-router';
import LoadingBox from '../../Unknown/LoadingBox';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types';
import useVerifyPermissions from '../../../common/hooks/useVerifyPermissions';
import BrandOwnerAccountHeader from '../BrandOwnerAccountHeader';
import BrandOwnerAccountTabs from '../BrandOwnerAccountTabs';
import useBrandId from '../../../common/hooks/useBrandId';
import useCheckIsAccountPath from '../../../common/hooks/useCheckIsAccountPath';
import useExtendedBackofficeUser from '../../../common/hooks/useExtendedBackofficeUser';

type QueryParams = {
  userId: string;
};

const BrandOwnerAccountDetail: React.FC = () => {
  const params: QueryParams = useParams();

  const [lastActivityTime, setLastActivityTime] = useState<number | null>(null);

  const { uid } = useSelector((state: RootState) => state.user);

  const isAccountPath = useCheckIsAccountPath();

  const userId = isAccountPath ? uid : params.userId;

  const { data: user, isLoading } = useExtendedBackofficeUser({ userId });

  const brandId = useBrandId();

  const { grantedPermissions } = useVerifyPermissions([
    `brand/${brandId}/permission/update`,
  ]);

  const isUpdatePermissionGranted = grantedPermissions?.permission?.update;

  if (isLoading) {
    return <LoadingBox />;
  }

  return (
    <Box p={4}>
      <Grid container>
        <Grid item xs={12}>
          {user ? (
            <BrandOwnerAccountHeader
              brandId={brandId || null}
              user={user}
              lastActivityTime={lastActivityTime}
              hasEditPermissions={!!isUpdatePermissionGranted}
            />
          ) : null}
        </Grid>
        <Grid container spacing={4} pt={4}>
          {user ? (
            <BrandOwnerAccountTabs
              setLastActivityTime={setLastActivityTime}
              user={user}
              brandId={brandId}
            />
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default BrandOwnerAccountDetail;
