import React, { useMemo } from 'react';
import { Grid } from '@miyagami-com/lsx-ui-components';
import { useSelector } from 'react-redux';

import { Brand, ExtendedBackOfficeUser, RootState } from '../../../../types';

import getRoleData from '../../../common/getRoleData';

import NetworkInformation from '../../Network/NetworkInformation';

import getAllBrandUsers from '../../../common/getAllBrandUsers';
import useUserTree from '../../../common/hooks/useUserTree';
import normalizeTree from '../../Network/NetworkTreeList/normalizeTree';
import NetworkLogs from '../../Network/NetworkLogs';
import { QueryClient } from 'react-query';

interface AgentNetworkPageProps {
  user: ExtendedBackOfficeUser;
  brand: Brand;
}

const AgentNetworkPage: React.FC<AgentNetworkPageProps> = ({ user, brand }) => {
  const queryClient = new QueryClient();

  const { users } = useSelector((root: RootState) => root.globalData);

  const treeParams = {
    brandId: brand.id,
    userId: user.id,
    parentPath: user.parent_path,
  };

  const { isLoading, data: networkTree, refetch } = useUserTree(treeParams);

  const authUser = useSelector((root: RootState) => root.user);

  const isSystemAdmin = !!authUser.roles?.find(
    (role) => role.role_id === 'systemAdmin',
  );

  const normalizedTree = useMemo(() => {
    return normalizeTree({ rows: networkTree || [], isSystemAdmin });
  }, [networkTree, isSystemAdmin]);

  const usersList = useMemo(
    () => getAllBrandUsers({ users, brandId: brand.id }),
    [brand.id, users],
  );

  const parentUser = useMemo(
    () => usersList.find(({ id }) => id === user?.parent_id),
    [usersList, user?.parent_id],
  );

  const parentUserRole = useMemo(
    () =>
      getRoleData({
        roles: parentUser?.roles,
        brandId: brand.id,
      }),
    [brand.id, parentUser?.roles],
  );

  const onRefetchTree = async () => {
    await refetch();
    await queryClient.refetchQueries({
      queryKey: ['getLogs'],
    });
  };

  return (
    <Grid container pt={4} spacing={4}>
      <Grid item xs={12}>
        <NetworkInformation
          parentUserData={{
            parentUser,
            parentUserRoleLabel: parentUserRole?.label || '',
          }}
          playerId={null}
          onRefetchTree={onRefetchTree}
          userId={user.id}
          isLoading={isLoading}
          parentId={parentUser?.id || user.parent_id}
          networkTree={normalizedTree}
          brand={brand}
          isPlayerPage={false}
        />
      </Grid>

      <Grid item xs={12}>
        <NetworkLogs userId={user.id} />
      </Grid>
    </Grid>
  );
};

export default AgentNetworkPage;
