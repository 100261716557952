import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    id: 'Network.NetworkTreeList.label',
    defaultMessage: 'Network tree',
  },
  idColumn: {
    id: 'Network.NetworkTreeList.idColumn',
    defaultMessage: 'ID',
  },
  userName: {
    id: 'Network.NetworkTreeList.userName',
    defaultMessage: 'Name',
  },
  userRole: {
    id: 'Network.NetworkTreeList.userRole',
    defaultMessage: 'Role',
  },
  userPT: {
    id: 'Network.NetworkTreeList.userPT',
    defaultMessage: 'PT',
  },
  minUserPT: {
    id: 'Network.NetworkTreeList.minUserPT',
    defaultMessage: 'Min. PT',
  },
  maxUserPT: {
    id: 'Network.NetworkTreeList.maxUserPT',
    defaultMessage: 'Max. PT',
  },
  extraPositionTaking: {
    id: 'Network.NetworkTreeList.extraPT',
    defaultMessage: 'Extra PT',
  },
  userCommission: {
    id: 'Network.NetworkTreeList.userCommission',
    defaultMessage: 'Com.',
  },
  actionColumn: {
    id: 'Network.NetworkTreeList.actionColumn',
    defaultMessage: 'Action',
  },
  actionView: {
    id: 'Network.NetworkTreeList.actionView',
    defaultMessage: 'View',
  },
  availablePT: {
    id: 'Network.NetworkTreeList.availablePT',
    defaultMessage: 'Available PT: {value}',
  },
});
