import * as Yup from 'yup';
import { REG_EXP_PASSWORD } from '../../../common/constants';

import { Password, Field } from '../../../common/validationMessages';

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .matches(REG_EXP_PASSWORD, Password.validationMessage)
    .required(Field.required)
    .trim(),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], Password.mustMatch)
    .required(Password.validationMessage)
    .trim(),
});

export default validationSchema;
