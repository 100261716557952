import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Paper, Typography } from '@miyagami-com/lsx-ui-components';

import AuthenticationLayout from '../../Unknown/AuthenticationLayout';
import MfaForm from '../MfaForm';
import messages from './messages';
import useStyles from './useStyles';
import AuthenticationForm from './AuthenticationForm';
import useAuth from '../../Unknown/AuthContext/useAuth';
import useSignUp from './useSignUp';
import useSubmitPhoneNumber from './useSubmitPhoneNumber';
import useSubmitMfaCode from './useSubmitMfaCode';
import useAuthenticationContext from '../../../common/hooks/useAuthenticationContext';

const AuthenticationPage: React.FC = () => {
  const { phoneNumber, setPhoneNumber, isWithMfa } = useAuth();

  const classes = useStyles();

  const {
    verificationId,
    recaptchaVerifier,
    setVerificationId,
    setRecaptchaVerifier,
  } = useAuthenticationContext();

  const onSignUp = useSignUp();

  const onSubmitPhone = useSubmitPhoneNumber({
    savedRecaptchaVerifier: recaptchaVerifier,
    setSavedRecaptchaVerifier: setRecaptchaVerifier,
    setPhoneNumber,
    setVerificationId,
  });

  const onSubmitMfaCode = useSubmitMfaCode({ verificationId });

  return (
    <AuthenticationLayout>
      <Paper>
        <Box minWidth="300px">
          <Box
            py={2.3}
            px={3}
            className={classes.borderRadius}
            bgcolor="grey.900"
            color="common.white"
          >
            <Typography>
              {isWithMfa ? (
                <>
                  {phoneNumber ? (
                    <FormattedMessage {...messages.descriptionForCode} />
                  ) : (
                    <FormattedMessage {...messages.descriptionForPhone} />
                  )}
                </>
              ) : (
                <FormattedMessage {...messages.description} />
              )}
            </Typography>
          </Box>
          <div id="recaptcha" />
          {!isWithMfa ? (
            <AuthenticationForm onSubmit={onSignUp} />
          ) : (
            <MfaForm
              onBack={() => {}}
              onSubmitPhone={onSubmitPhone}
              onSubmitMfaCode={onSubmitMfaCode}
              isFirstAuthentication={!verificationId}
              phoneNumber={phoneNumber}
              onVerificationIdChange={setVerificationId}
            />
          )}
        </Box>
      </Paper>
    </AuthenticationLayout>
  );
};

export default AuthenticationPage;
