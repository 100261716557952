import { defineMessages } from 'react-intl';

export default defineMessages({
  email: {
    id: 'Brand.BrandDetailsHeader.email',
    defaultMessage: 'E-mail: {email}',
  },
  addedOn: {
    id: 'Brand.BrandDetailsHeader.addedOn',
    defaultMessage: 'Added on ',
  },
  lastUpdate: {
    id: 'Brand.BrandDetailsHeader.lastUpdate',
    defaultMessage: 'Last update: {time} ago',
  },
});
