import {
  FormControl,
  InputLabel,
  OutlinedInput,
  SelectProps,
  Typography,
  Box,
} from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useMemo, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { ManualRegistrationFormFields } from '.';
import { RootState } from '../../../../types';
import useUserRoleLabel from '../../../common/hooks/useUserRoleLabel';
import Select from '../../Unknown/Select';
import useSelectableRoles, { SelectRole } from './useSelectableRoles';
import messages from './messages';

const SelectParentUser: React.FC<SelectProps> = (props) => {
  const { name, ...otherProps } = props;

  const { values, setFieldValue } =
    useFormikContext<ManualRegistrationFormFields>();

  const userRoleLabel = useUserRoleLabel();

  const selectableRoles = useSelectableRoles();

  const roles = Object.keys(selectableRoles);

  const { users } = useSelector((root: RootState) => root.globalData);

  const { roleId, brandId } = values;

  const userRole = roleId as SelectRole;

  const isSelectableRole = roles.includes(roleId);

  const nextRole = isSelectableRole
    ? selectableRoles?.[userRole].parentRoleId
    : 'player';

  const menuItemsUsers = useMemo(() => {
    if (userRole === 'superAgent') {
      const owners = users.brandOwners.filter((user) =>
        user.roles.some(
          (backofficeUserRole) =>
            backofficeUserRole.brand_id === brandId &&
            backofficeUserRole.role_id === 'owner',
        ),
      );

      return owners;
    }

    const agents =
      users.byBrandId?.[brandId]?.agents?.filter((user) =>
        user.roles.some(
          (backofficeUserRole) =>
            backofficeUserRole.brand_id === brandId &&
            backofficeUserRole.role_id === nextRole,
        ),
      ) || [];

    return agents;
  }, [brandId, nextRole, userRole, users.brandOwners, users.byBrandId]);

  useEffect(() => {
    const [defaultUsers] = menuItemsUsers;

    setFieldValue(name || 'parentId', defaultUsers?.id || null);
  }, [name, setFieldValue, menuItemsUsers]);

  if (!menuItemsUsers.length) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={1}
      >
        <Typography variant="h6">
          <FormattedMessage {...messages.notFoundUsers} />
        </Typography>
      </Box>
    );
  }

  const menuItems = menuItemsUsers?.map((user) => {
    const { id, username } = user;

    const menuItem = {
      value: id,
      label: username,
    };

    return menuItem;
  });

  const label = userRoleLabel[nextRole];

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel shrink>{label}</InputLabel>
      <Select
        {...otherProps}
        input={<OutlinedInput notched label={label} />}
        name={name}
        menuItems={menuItems}
      />
    </FormControl>
  );
};

export default SelectParentUser;
