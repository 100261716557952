import { useQuery } from 'react-query';
import useSupabase from './useSupabase';
import { UserStat } from '../../../types/supabase';

interface UseUserStatsParams {
  userId: string;
}

const useUserStats = (params: UseUserStatsParams): typeof queryResult => {
  const { userId } = params;

  const supabase = useSupabase();

  const queryResult = useQuery<UserStat | null, Error>(
    ['userStat', userId],
    async () => {
      const response = await supabase
        .from('user_stats')
        .select('*')
        .eq('id', userId)
        .maybeSingle();

      return response.data;
    },
  );

  return queryResult;
};

export default useUserStats;
