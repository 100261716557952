import { defineMessages } from 'react-intl';

export default defineMessages({
  player: {
    id: 'Player.PlayerDuplicatesPage.player',
    defaultMessage: 'Player',
  },
  duplicatePlayers: {
    id: 'Player.PlayerDuplicatesPage.duplicatePlayers',
    defaultMessage: 'Duplicate players',
  },
});
