import { useQuery } from 'react-query';
import useSupabase from './useSupabase';
import { ApiKey } from '../../../types';
import { useEffect, useState } from 'react';

interface UseBrandApiKeysParams {
  brandId: string;
  select?: string;
}

const useBrandApiKeys = <T extends ApiKey>(
  params: UseBrandApiKeysParams,
): T[] => {
  const { brandId, select = '*' } = params;

  const supabase = useSupabase();

  const [apiKeys, setApiKeys] = useState<T[]>([]);

  useEffect(() => {
    const subscription = supabase
      .channel(`${brandId}_api_keys`)
      .on<T>(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'api_keys',
          filter: `brand_id=eq.${brandId}`,
        },
        (payload) => {
          const { eventType, old: oldApiKey, new: newApiKey } = payload;

          if (eventType === 'INSERT') {
            setApiKeys((prev) => [...prev, newApiKey]);
          }
          if (eventType === 'UPDATE') {
            setApiKeys((prev) =>
              prev.map((apiKey) => {
                if (apiKey.id === newApiKey.id) {
                  return newApiKey;
                }
                return apiKey;
              }),
            );
          }
          if (eventType === 'DELETE') {
            setApiKeys((prev) =>
              prev.filter((apiKey) => apiKey.id !== oldApiKey.id),
            );
          }
        },
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [brandId, supabase]);

  useQuery<void, Error>(['api_keys', brandId], async () => {
    const response = await supabase
      .from('api_keys')
      .select<typeof select, T>(select)
      .eq('brand_id', brandId)
      .throwOnError();

    if (response.data) {
      setApiKeys(response.data);
    }
  });

  return apiKeys;
};

export default useBrandApiKeys;
