import { useState, useEffect, useCallback, useContext } from 'react';
import { useFirebaseApp } from 'reactfire';

import { GridApiPremium, UIContext } from '@miyagami-com/lsx-ui-components';

import { DEFAULT_REGION } from '../constants';
import { useQueryClient } from 'react-query';
import { RootState } from '../../../types';
import { useSelector } from 'react-redux';
import { getFunctions, httpsCallable } from 'firebase/functions';

type UseDataGridOrderedColumnsChangeProps = {
  tableKey?: string;
  apiRef: React.MutableRefObject<GridApiPremium>;
};

const useDataGridOrderedColumnsChange = ({
  tableKey,
  apiRef,
}: UseDataGridOrderedColumnsChangeProps): boolean => {
  const [isLoading, setIsLoading] = useState(false);
  const { setAlert } = useContext(UIContext);

  const { uid } = useSelector((root: RootState) => root.user);

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const queryClient = useQueryClient();

  const onColumnHeaderDragEnd = useCallback(async () => {
    if (tableKey) {
      setIsLoading(true);
      const allColumns = apiRef.current.getAllColumns();

      const orderedColumns = allColumns
        .map((column) => column.field)
        .filter((col) => col !== '__tree_data_group__');

      const updateUserDataGridConfig = httpsCallable(
        functions,
        'back-user-updateUserDataGridConfig',
      );

      try {
        await updateUserDataGridConfig({
          tableKey,
          columns: orderedColumns,
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setAlert({
          show: true,
          severity: 'error',
          message: error.toString(),
        });
      } finally {
        setIsLoading(false);
        queryClient.refetchQueries(['dataGridConfig', tableKey, uid]);
      }
    }
  }, [apiRef, functions, queryClient, setAlert, tableKey, uid]);

  useEffect(() => {
    if (tableKey) {
      apiRef.current.subscribeEvent(
        'columnHeaderDragEnd',
        onColumnHeaderDragEnd,
      );
    }
  }, [tableKey, apiRef, onColumnHeaderDragEnd]);

  return isLoading;
};

export default useDataGridOrderedColumnsChange;
