import React, { useMemo } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  LoadingButton,
  TextField,
  Typography,
} from '@miyagami-com/lsx-ui-components';
import { SelectChangeEvent } from '@mui/material';

import {
  BackofficeUserRole,
  BackOfficeUserStatus,
  Brand,
  RootState,
} from '../../../../types';

import useBrandId from '../../../common/hooks/useBrandId';
import useVerifyPermissions from '../../../common/hooks/useVerifyPermissions';
import useUserStatuses, {
  UseUserStatusesMap,
} from '../../../common/hooks/useUserStatuses';

import PasswordOutlinedInput from '../PasswordOutlinedInput';
import { RadioButtonCheckedIcon, RadioButtonUncheckedIcon } from '../Icons';
import Select from '../Select';

import {
  createValidationSchema,
  editValidationSchema,
} from './validationSchema';
import messages from './messages';
import totpMessages from '../../../components/BackofficeUser/Totp/messages';
import getPermissionGrantedUsers from './getPermissionGrantedUsers';
// import NetworkConfigSection from './NetworkConfigSection';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';
import useTotpAuthContext from '../../BackofficeUser/Totp/useTotpAuthContext';
import PromptModal from '../PromptModal';

export type BackofficeUserFormValues = {
  name: string;
  surname: string;
  email: string;
  role: BackofficeUserRole;
  brandId?: string;
  mfa: boolean;
  status?: BackOfficeUserStatus;
  phoneNumber?: string;
  password?: string;
  isEnableFirewall: boolean;
};

export type DialogType = 'systemAdmins' | 'brandOwners' | 'backofficeUsers';

interface BackofficeUserFormProps {
  initialValues: BackofficeUserFormValues;
  onSubmit: (
    values: BackofficeUserFormValues,
    formikHelpers: FormikHelpers<BackofficeUserFormValues>,
  ) => void | Promise<void>;
  brands?: Brand[];
  isButtonLoading: boolean;
  isEdit?: boolean;
  isCurrentUser?: boolean;
  type?: DialogType;
}

type FieldDefaultProps = {
  fullWidth: boolean;
  name: keyof BackofficeUserFormValues;
  variant: 'outlined';
  value?: string | boolean;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<unknown>,
  ) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  error: boolean;
};

const getStatusesMenuItems = (
  userStatuses: UseUserStatusesMap,
  isCurrentUser?: boolean,
  type?: DialogType,
  isDisabled?: boolean,
) => {
  const userStatusesKeys = Object.keys(userStatuses) as BackOfficeUserStatus[];

  const isSystemAdminType = type === 'systemAdmins';

  const filteredStatuses = userStatusesKeys.filter((status) =>
    (isSystemAdminType || !isDisabled) && isCurrentUser
      ? status !== 'closed' && status !== 'suspend'
      : isSystemAdminType
      ? status !== 'suspend'
      : status,
  );

  return filteredStatuses.map((value) => {
    const { label, hidden } = userStatuses[value];

    return {
      value,
      label,
      hidden: isSystemAdminType ? false : hidden,
    };
  });
};

const BackofficeUserForm: React.FC<BackofficeUserFormProps> = ({
  initialValues,
  onSubmit,
  brands,
  isButtonLoading,
  isEdit,
  isCurrentUser,
  type = 'backofficeUsers',
}) => {
  const intl = useIntl();

  const userStatuses = useUserStatuses();

  const brandId = useBrandId();

  const { requestTotpAuth, getEnrolledFactors, unenrollTotp } =
    useTotpAuthContext();

  const [showConfirmRevoke, setShowConfirmRevoke] = React.useState(false);

  const enrolledFactors = getEnrolledFactors();

  const isTotpActive = enrolledFactors.some(
    (factor) => factor.factorId === 'totp',
  );

  const { roles, permissionMap } = useSelector((root: RootState) => root.user);

  const isSystemAdmin = checkIsSystemAdmin({ roles });

  const brandsMenuItems = useMemo(() => {
    const menuItems = brands?.map((brand) => {
      const { id, name } = brand;

      const menuItem = {
        value: id,
        label: name,
      };

      return menuItem;
    });

    return menuItems;
  }, [brands]);

  const { grantedPermissions, isSuspended, isBrandSuspended } =
    useVerifyPermissions([
      `brand/${brandId}/finance/update`,
      `brand/${brandId}/support/update`,
      `brand/${brandId}/owner/update`,
      `brand/${brandId}/marketing/update`,
    ]);

  const isUpdatePermissionGranted =
    !!grantedPermissions?.finance?.update ||
    !!grantedPermissions?.support?.update ||
    !!grantedPermissions?.owner?.update ||
    !!grantedPermissions?.marketing?.update;

  const isBrandOwner = initialValues.role === 'owner';

  const isDisabled =
    !(isUpdatePermissionGranted || !!isCurrentUser) ||
    isSuspended ||
    (isBrandSuspended && type === 'backofficeUsers' && !isBrandOwner);

  const statusesMenuItems = useMemo(() => {
    return getStatusesMenuItems(userStatuses, isCurrentUser, type, isDisabled);
  }, [isCurrentUser, type, isDisabled, userStatuses]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={isEdit ? editValidationSchema : createValidationSchema}
      enableReinitialize
    >
      {({ handleChange, handleBlur, errors, values, touched }) => {
        const error = (name: keyof BackofficeUserFormValues) =>
          Boolean(errors[name] && touched[name]);

        const selectedBrandId = values.brandId || brandId;

        const brandPermissionMap = permissionMap[selectedBrandId];

        const permissionGrantedUsers = getPermissionGrantedUsers({
          isSystemAdmin,
          permissionMap: brandPermissionMap,
          type,
        });

        const isCandidateSystemAdmin = values.role === 'systemAdmin';

        const isCandidateOwner = values.role === 'owner';

        const isCandidateAdminOwner =
          isCandidateOwner || isCandidateSystemAdmin;

        const getFieldDefaultProps = (
          name: keyof BackofficeUserFormValues,
        ): FieldDefaultProps => {
          return {
            fullWidth: true,
            name,
            variant: 'outlined',
            value: values[name],
            onChange: handleChange,
            onBlur: handleBlur,
            error: error(name),
          };
        };

        return (
          <Form>
            <Grid container rowSpacing={4}>
              <Grid paddingX={3} spacing={4} container item xs={12}>
                <Grid marginTop={3} item xs={12}>
                  <Typography variant="h6">
                    <FormattedMessage {...messages.personal} />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    {...getFieldDefaultProps('name')}
                    disabled={isDisabled}
                    label={intl.formatMessage(messages.name)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    {...getFieldDefaultProps('surname')}
                    disabled={isDisabled}
                    label={intl.formatMessage(messages.surname)}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid paddingX={3} spacing={4} container item xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    <FormattedMessage {...messages.account} />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    {...getFieldDefaultProps('email')}
                    disabled={isDisabled}
                    label={intl.formatMessage(messages.email)}
                  />
                </Grid>
                {isEdit && (
                  <>
                    <Grid item xs={12} md={6}>
                      <PasswordOutlinedInput
                        {...getFieldDefaultProps('password')}
                        id="password"
                        disabled={isDisabled}
                        label={intl.formatMessage(messages.password)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Select
                        {...getFieldDefaultProps('status')}
                        label={intl.formatMessage(messages.status)}
                        labelId="select-status"
                        disabled={isDisabled}
                        menuItems={statusesMenuItems}
                      />
                    </Grid>
                    {values.mfa && !isTotpActive && (
                      <Grid item xs={12} md={6}>
                        <TextField
                          {...getFieldDefaultProps('phoneNumber')}
                          disabled={isDisabled}
                          label={intl.formatMessage(messages.phoneNumber)}
                        />
                      </Grid>
                    )}
                  </>
                )}
                {!isTotpActive && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    display="flex"
                    alignItems="center"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedIcon />}
                          checkedIcon={
                            <RadioButtonCheckedIcon color="primary" />
                          }
                          name="mfa"
                          checked={values.mfa}
                          onChange={handleChange}
                          disabled={isDisabled}
                        />
                      }
                      label={intl.formatMessage(messages.enableMFA)}
                    />
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  display="flex"
                  alignItems="center"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<RadioButtonCheckedIcon color="primary" />}
                        name="isEnableFirewall"
                        checked={values.isEnableFirewall}
                        onChange={handleChange}
                        disabled={isDisabled}
                      />
                    }
                    label={intl.formatMessage(messages.enableFirewall)}
                  />
                </Grid>
              </Grid>
              {brandsMenuItems && (
                <>
                  {(!isCandidateAdminOwner || !isEdit) && !isCurrentUser && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                  <Grid paddingX={3} spacing={4} container item xs={12}>
                    {permissionGrantedUsers?.length > 0 &&
                      (!isCandidateAdminOwner || !isEdit) &&
                      !isCurrentUser && (
                        <>
                          <Grid item xs={12}>
                            <Typography variant="h6">
                              <FormattedMessage
                                {...messages.roleSectionTitle}
                              />
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Select
                              {...getFieldDefaultProps('role')}
                              labelId="select-role"
                              label={intl.formatMessage(messages.role)}
                              menuItems={permissionGrantedUsers}
                              disabled={
                                permissionGrantedUsers.length === 1 ||
                                isDisabled
                              }
                            />
                          </Grid>
                        </>
                      )}
                    {!isCandidateAdminOwner && values.role && isEdit && (
                      <Grid item xs={12} sm={6} md={6}>
                        <Select
                          {...getFieldDefaultProps('brandId')}
                          labelId="select-brand"
                          label={intl.formatMessage(messages.brand)}
                          menuItems={brandsMenuItems}
                          disabled={isDisabled}
                        />
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Divider />
              </Grid>

              {isCurrentUser && !values.mfa && (
                <>
                  <Grid item xs={12} padding={3}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Typography variant="h6">
                        <FormattedMessage {...messages.configureTOTP} />
                      </Typography>

                      <Box display="inline-flex" alignItems="center" gap={2}>
                        {isTotpActive ? (
                          <Typography color="green" variant="h3">
                            Active
                          </Typography>
                        ) : (
                          <Typography color="error" variant="h3">
                            Disable
                          </Typography>
                        )}

                        {isTotpActive ? (
                          <LoadingButton
                            disabled={isDisabled}
                            onClick={() => setShowConfirmRevoke(true)}
                            variant="contained"
                            color="error"
                            type="button"
                            sx={{ color: 'background.paper' }}
                            loading={isButtonLoading}
                          >
                            <Typography variant="button">
                              <FormattedMessage {...messages.unenrollTotp} />
                            </Typography>
                          </LoadingButton>
                        ) : (
                          <LoadingButton
                            disabled={isDisabled}
                            onClick={() => requestTotpAuth()}
                            variant="contained"
                            color="info"
                            type="button"
                            sx={{ color: 'background.paper' }}
                            loading={isButtonLoading}
                          >
                            <Typography variant="button">
                              <FormattedMessage {...messages.configure} />
                            </Typography>
                          </LoadingButton>
                        )}
                      </Box>
                    </Box>
                  </Grid>

                  <PromptModal
                    isOpen={showConfirmRevoke}
                    title={intl.formatMessage(totpMessages.revokeTotp)}
                    description={intl.formatMessage(
                      totpMessages.revokeTotpDescription,
                    )}
                    onCancel={() => {
                      setShowConfirmRevoke(false);
                    }}
                    onConfirm={async () => {
                      await unenrollTotp();
                      setShowConfirmRevoke(false);
                    }}
                  />

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              )}
              {/* {isCandidateOwner && (
                <NetworkConfigSection
                  containerProps={{
                    paddingX: 3,
                    spacing: 4,
                    container: true,
                    item: true,
                    xs: 12,
                  }}
                  itemContainerProps={{ item: true, xs: 12, md: 6 }}
                  positionTakingTextFieldProps={getFieldDefaultProps(
                    'positionTakingRate',
                  )}
                  commissionTextFieldProps={getFieldDefaultProps(
                    'commissionRate',
                  )}
                />
              )} */}
              <Grid item xs={12} padding={3}>
                <LoadingButton
                  disabled={isDisabled}
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={isButtonLoading}
                >
                  <FormattedMessage {...messages.save} />
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BackofficeUserForm;
