import { GridRowData } from '@mui/x-data-grid';

import { Brand, FirebaseId, ExtendedBackOfficeUser } from '../../../../types';
import { Log } from '../../../../types/supabase';
import { USER_ROLES } from '../../../common/constants';

const sortedSystemLogsData = (
  systemLogs?: Log[],
  users?: ExtendedBackOfficeUser[],
  brands?: (Brand & FirebaseId)[],
): GridRowData[] => {
  if (systemLogs) {
    return systemLogs.map((systemLog) => {
      const foundUser = users?.find((user) => user.id === systemLog.user_id);

      const foundBrand = brands?.find(
        (brand) => brand.id === systemLog.brand_id,
      );

      const createdAt = systemLog?.created_at;

      const userRoles = foundUser?.roles?.flatMap((permissionRole) => {
        const userRoleSettings = USER_ROLES.find(
          (userRole) => userRole.value === permissionRole.role_id,
        );
        return userRoleSettings?.label || [];
      });
      const uniqueUserRoles = new Set(userRoles || []);

      return {
        id: systemLog.id,
        username: foundUser?.username,
        firstName: foundUser?.name,
        surname: foundUser?.surname,
        role: [...uniqueUserRoles]?.join(', '),
        email: foundUser?.email,
        brandName: foundBrand?.name,
        brandId: foundBrand?.id,
        ipAddress: systemLog.ip_address,
        countryCode: systemLog.country_code,
        action: systemLog.message,
        createdAt,
      };
    });
  }

  return [];
};

export default sortedSystemLogsData;
