import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types';
import HeaderWithFilter from '../../Unknown/HeaderWithFilter';
import SportLine from '../../Unknown/SportLine';

const ExchangeSettingSportline: React.FC = () => {
  const { brands } = useSelector((state: RootState) => state.globalData);

  return (
    <>
      <HeaderWithFilter name={null} brands={brands} />
      <SportLine />
    </>
  );
};

export default ExchangeSettingSportline;
