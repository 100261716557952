import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import React from 'react';
import { useIntl } from 'react-intl';
import { ExtendedExchangeBet } from '../../../../types';
import {
  CURRENCY_SYMBOL,
  DEFAULT_CURRENCY,
  DEFAULT_DATE_FORMAT,
} from '../../../common/constants';
import useExchangeBetStatusMap from '../../../common/hooks/useExchangeBetStatusMap';
import usePlayer from '../../../common/hooks/usePlayer';
import DetailsInformationHeader from '../../Unknown/DetailsInformationHeader';

import messages from './messages';

interface ExchangeBetHeaderParams {
  exchangeBet: ExtendedExchangeBet;
}

const ExchangeBetHeader: React.FC<ExchangeBetHeaderParams> = (props) => {
  const { exchangeBet } = props;

  const { betfairExchangeBet, player_id, size, price, updated_at, status } =
    exchangeBet;

  const { placed_at, event_name, average_price } = betfairExchangeBet;

  const intl = useIntl();
  const exchangeBetStatusMap = useExchangeBetStatusMap();
  const { data: player } = usePlayer({ playerId: player_id });

  const placeAtDate = new Date(placed_at);

  const formatPlaceAtDate = format(placeAtDate, DEFAULT_DATE_FORMAT);

  const eventData = {
    mainText: event_name,
    secondText: intl.formatMessage(messages.placetAt, {
      date: formatPlaceAtDate,
    }),
  };

  const userData = {
    mainText: player?.username,
  };

  const oddsStakeData = {
    mainText: intl.formatMessage(messages.priceLabel, {
      price,
      avp: average_price,
    }),
    secondText: intl.formatMessage(messages.stakeLabel, {
      currency: CURRENCY_SYMBOL[DEFAULT_CURRENCY],
      stake: size,
    }),
  };

  const updatedAtDate = new Date(updated_at);
  const currentDate = new Date();

  const distance = formatDistance(updatedAtDate, currentDate);

  const { label, color } = exchangeBetStatusMap[status];

  const statusUpdatedAtData = {
    statusColor: color,
    statusText: label,
    secondText: intl.formatMessage(messages.updatedAtLabel, {
      time: distance,
    }),
  };

  return (
    <DetailsInformationHeader
      data={{
        left: eventData,
        centerLeft: userData,
        centerRight: oddsStakeData,
        right: statusUpdatedAtData,
      }}
    />
  );
};

export default ExchangeBetHeader;
