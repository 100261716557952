import React, { useContext, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  Button,
  CollapseBox,
  Divider,
  LoadingButton,
  TextField,
  Typography,
  UIContext,
} from '@miyagami-com/lsx-ui-components';
import { useHistory } from 'react-router';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';

import Select from '../../Unknown/Select';

import {
  BackOfficeUser,
  Brand,
  BrandStatus,
  RootState,
} from '../../../../types';

import useVerifyPermissions from '../../../common/hooks/useVerifyPermissions';
import useMyAccountPath from '../../../common/hooks/useMyAccountPath';
import useBrandStatuses from '../../../common/hooks/useBrandStatuses';
import useBrandId from '../../../common/hooks/useBrandId';

import validationSchema from './validationSchema';
import useStyles from './useStyles';
import messages from './messages';
import useUpdateBrand from '../../../common/hooks/useUpdateBrand';
import { Checkbox, FormControlLabel, Switch } from '@mui/material';

type BrandDetailsProps = {
  brand: Brand;
  brandOwner: BackOfficeUser | undefined;
  isSystemAdmin?: boolean;
  refetchBrands: () => void;
};

type FormValues = {
  liquidity_multiplier?: number;
  is_required_login?: boolean;
  is_hide_brand_name?: boolean;
  name: string;
  status: BrandStatus;
};

const BrandDetails: React.FC<BrandDetailsProps> = ({
  brand,
  brandOwner,
  refetchBrands,
  isSystemAdmin,
}) => {
  const brandId = useBrandId();
  const brandStatuses = useBrandStatuses();

  const brandStatusesKeys = Object.keys(brandStatuses);

  const myAccountPath = useMyAccountPath();

  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const { uid } = useSelector((state: RootState) => state.user);

  const { grantedPermissions, isSuspended } = useVerifyPermissions([
    `brand/${brandId}/brand/update`,
  ]);

  const isDisabled = !grantedPermissions?.brand?.update || isSuspended;

  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();

  const { setAlert } = useContext(UIContext);

  const updateBrand = useUpdateBrand({ brandId });

  const onUpdateBrand = async (
    payload: FormValues,
    actions: { resetForm: () => void },
  ) => {
    setIsButtonLoading(true);
    try {
      await updateBrand(payload);

      actions.resetForm();

      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.successfullyEdit, {
          brandName: brand.name,
        }),
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setAlert({
        show: true,
        severity: 'error',
        message: error.toString(),
      });
    } finally {
      refetchBrands();
    }
    setIsButtonLoading(false);
  };

  const goAdminInfo = () => {
    const isCurrentUser = uid === brand.owner_id;

    if (isCurrentUser) {
      history.push(myAccountPath);
      return;
    }
    history.push(`/b/${brandId}/brand-owners/${brand.owner_id}`);
  };

  const brandStatusesMenuItems = useMemo(() => {
    return brandStatusesKeys.map((status) => {
      const brandStatus = brandStatuses[status as BrandStatus];

      return {
        label: brandStatus.label,
        value: status,
      };
    });
  }, [brandStatuses, brandStatusesKeys]);

  const initialValues: FormValues = useMemo(() => {
    return {
      name: brand.name ? brand.name : '',
      status: brand.status as BrandStatus,
      is_required_login: brand.is_required_login || false,
      is_hide_brand_name: brand.is_hide_brand_name || false,
      liquidity_multiplier: brand.liquidity_multiplier || 1,
    };
  }, [
    brand.is_hide_brand_name,
    brand.is_required_login,
    brand.liquidity_multiplier,
    brand.name,
    brand.status,
  ]);

  return (
    <CollapseBox
      defaultValue={true}
      label={intl.formatMessage(messages.brandDetails)}
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onUpdateBrand}
        validationSchema={validationSchema}
      >
        {({ handleChange, handleBlur, errors, values, touched }) => {
          const error = (name: keyof FormValues) =>
            Boolean(errors[name] && touched[name]);

          return (
            <Form>
              <Box>
                <Box p={3}>
                  <Typography variant="h6">
                    <FormattedMessage {...messages.brandOwner} />
                  </Typography>
                  <Box
                    pt={3}
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={{ xs: 'column', md: 'row' }}
                  >
                    <Box width={1}>
                      <Typography variant="h4">
                        {brandOwner?.username}
                      </Typography>
                      <Typography variant="h3" color="textSecondary">
                        <FormattedMessage
                          {...messages.email}
                          values={{ email: brandOwner?.email }}
                        />
                      </Typography>
                    </Box>
                    <Box whiteSpace="nowrap" pt={{ xs: 2, md: 0 }}>
                      <Button
                        variant="contained"
                        onClick={goAdminInfo}
                        color="primary"
                      >
                        <FormattedMessage {...messages.seeProfile} />
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Divider />
                <Box
                  p={3}
                  sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
                >
                  <Box>
                    <Typography variant="h6">
                      <FormattedMessage {...messages.name} />
                    </Typography>
                    <Box pt={4} pb={2}>
                      <TextField
                        name="name"
                        InputProps={{
                          classes: {
                            root: classes.transparent,
                          },
                        }}
                        label={intl.formatMessage(messages.name)}
                        value={values.name}
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={error('name')}
                        disabled={isDisabled}
                      />
                    </Box>
                  </Box>

                  <Box pt={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.is_hide_brand_name}
                          name="is_hide_brand_name"
                          onChange={(e) => {
                            handleChange({
                              target: {
                                name: 'is_hide_brand_name',
                                value: e.target.checked,
                              },
                            });
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="Hide name from header"
                    />
                  </Box>
                </Box>
                <Divider />
                <Box p={3}>
                  <Typography variant="h6">
                    <FormattedMessage {...messages.status} />
                  </Typography>
                  <Box pt={4} pb={2}>
                    <Select
                      sx={{ minWidth: '230px' }}
                      labelId="select-status"
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                      label={intl.formatMessage(messages.status)}
                      className={classes.select}
                      error={error('status')}
                      disabled={isDisabled}
                      menuItems={brandStatusesMenuItems}
                    />
                  </Box>
                </Box>
                <Divider />
                <Box p={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.is_required_login}
                        name="is_required_login"
                        onChange={handleChange}
                      />
                    }
                    label={
                      <Typography variant="h6">
                        <FormattedMessage {...messages.isRequiredLogin} />
                      </Typography>
                    }
                  />
                </Box>
                <Divider />
                {isSystemAdmin && (
                  <>
                    <Box p={3}>
                      <Typography variant="h6">
                        <FormattedMessage {...messages.liquidityMultiplier} />
                      </Typography>
                      <Box pt={4} pb={2}>
                        <TextField
                          sx={{ minWidth: '230px' }}
                          name="liquidity_multiplier"
                          value={values.liquidity_multiplier}
                          type="number"
                          inputProps={{
                            min: 0,
                            step: 0.1,
                          }}
                          onChange={handleChange}
                          label={intl.formatMessage(
                            messages.liquidityMultiplier,
                          )}
                          className={classes.select}
                          error={error('liquidity_multiplier')}
                          disabled={isDisabled}
                        />
                      </Box>
                    </Box>
                    <Divider />
                  </>
                )}
                <Box p={3}>
                  <LoadingButton
                    disabled={isDisabled}
                    loading={isButtonLoading}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    <FormattedMessage {...messages.save} />
                  </LoadingButton>
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </CollapseBox>
  );
};

export default BrandDetails;
