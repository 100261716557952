import { v4 } from 'uuid';
import { ProfitLossReportLevel, PublicPLReport } from '../../../../types';
import { DEFAULT_AMOUNT } from '../../../common/constants';

interface NormalizeReportsParams {
  reports: PublicPLReport[] | null;
}

export interface PLReportRow {
  id: string;
  refId: string | null;
  name: string;
  level: ProfitLossReportLevel;
  totalBets: number;
  tumOver: number;
  playerWinLoss: number;
  playerCommission: number;
  playerTotal: number;
  parentWinLoss: number;
  parentCommission: number;
  parentTotal: number;
  payout: number;
  upLine: number;
}

const normalizeReports = (params: NormalizeReportsParams): PLReportRow[] => {
  const { reports } = params;

  if (!reports) return [];

  const rows = reports.map<PLReportRow>((report) => {
    const { general, parent, player, upLine, payout } = report;

    const level = (general?.level || 'player') as ProfitLossReportLevel;

    const row: PLReportRow = {
      id: general?.id || v4(),
      refId: general?.refId || null,
      name: general?.name || '',
      level,
      totalBets: general?.totalBets || DEFAULT_AMOUNT,
      tumOver: general?.tumOver || DEFAULT_AMOUNT,
      // shown only on agent level
      playerWinLoss: player?.winLoss || DEFAULT_AMOUNT,
      playerCommission: player?.commission || DEFAULT_AMOUNT,
      playerTotal: player?.total || DEFAULT_AMOUNT,
      parentWinLoss: parent?.winLoss || DEFAULT_AMOUNT,
      parentCommission: parent?.commission || DEFAULT_AMOUNT,
      parentTotal: parent?.total || DEFAULT_AMOUNT,
      upLine,
      payout,
    };

    return row;
  });

  return rows;
};

export default normalizeReports;
