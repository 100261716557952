import { defineMessages } from 'react-intl';

export default defineMessages({
  edit: {
    id: 'WhiteList.WhiteList.edit',
    defaultMessage: 'Edit',
  },
  firstName: {
    id: 'WhiteList.WhiteList.firstName',
    defaultMessage: 'First name',
  },
  surname: {
    id: 'WhiteList.WhiteList.surname',
    defaultMessage: 'Surname',
  },
  email: {
    id: 'WhiteList.WhiteList.email',
    defaultMessage: 'E-mail',
  },
  telephone: {
    id: 'WhiteList.WhiteList.telephone',
    defaultMessage: 'Telephone',
  },
  type: {
    id: 'WhiteList.WhiteList.type',
    defaultMessage: 'Type',
  },
  status: {
    id: 'WhiteList.WhiteList.status',
    defaultMessage: 'Status',
  },
  ip: {
    id: 'WhiteList.WhiteList.ip',
    defaultMessage: 'IP',
  },
  actions: {
    id: 'WhiteList.WhiteList.actions',
    defaultMessage: 'Actions',
  },
});
