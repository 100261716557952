import { createContext } from 'react';
import {
  MultiFactorError,
  MultiFactorInfo,
  MultiFactorResolver,
  TotpSecret,
} from 'firebase/auth';

type CurrentMFAResolver = {
  mfaResolver: MultiFactorResolver;
  factorId?: MultiFactorInfo['factorId'];
  factorUuid: string;
};

export type TotpAuthContextValue = {
  totpSecret: TotpSecret | null;
  totpUri: string | null;
  resetTotpSecret: () => void;
  mfaResolver: CurrentMFAResolver | null;
  enrollTotp: (code: string) => Promise<void>;
  unenrollTotp: () => Promise<void>;
  resolveTotp: (code: string) => Promise<void>;
  resolveMFA: (
    error: MultiFactorError,
  ) => Promise<CurrentMFAResolver> | CurrentMFAResolver;
  requestTotpAuth: () => void;
  getEnrolledFactors: () => MultiFactorInfo[];
};

const TotpAuthContext = createContext<TotpAuthContextValue>(
  {} as TotpAuthContextValue,
);

export default TotpAuthContext;
