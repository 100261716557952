import { useMemo } from 'react';
import { EventDetailExchangeBet } from '../../../common/hooks/useEventDetail';
import createExchangeBetStatus from '../../../common/createExchangeBetStatus';
import { ExchangeBetStatus } from '../../../common/hooks/useExtendedExchangeBetStatusMap';

interface UseNormalizeBetsParams {
  exchangeBets: EventDetailExchangeBet[];
}

export type NormalizedBet = {
  id: string;
  date: Date;
  time: Date;
  stake: number;
  odds: number;
  status: ExchangeBetStatus;
  selectionName: string;
  playerName: string;
  marketName: string;
  side: string;
  playerId: string;
};

const useNormalizeBets = (params: UseNormalizeBetsParams): NormalizedBet[] => {
  const { exchangeBets } = params;

  return useMemo(
    () =>
      exchangeBets.map<NormalizedBet>((exchangeBet) => {
        const {
          id,
          created_at,
          size,
          price,
          player_id,
          player_username,
          market_name,
          side,
          selection_name,
        } = exchangeBet;

        const createdAtDate = new Date(created_at);

        const status = createExchangeBetStatus({
          isClosed: exchangeBet.is_closed,
          betfairExchangeBetStatus: exchangeBet.betfair_exchange_bet_status,
          betfairExchangeSizes: exchangeBet.betfair_exchange_sizes,
        });

        return {
          id,
          time: createdAtDate,
          date: createdAtDate,
          stake: size,
          odds: price,
          selectionName: selection_name,
          side,
          marketName: market_name,
          status,
          playerId: player_id,
          playerName: player_username,
        };
      }),
    [exchangeBets],
  );
};

export default useNormalizeBets;
