import { FC, useState, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth, useFirebaseApp } from 'reactfire';
import { CollapseBox, UIContext, Grid } from '@miyagami-com/lsx-ui-components';
import { FormikHelpers } from 'formik';

import { DEFAULT_REGION } from '../../../common/constants';
import {
  BackOfficeUserStatus,
  BackofficeUserRole,
  ExtendedBackOfficeUser,
  RootState,
} from '../../../../types';
import { loginUserAction } from '../../../store/user';
import getFirstPermissionWithRole from '../../../common/permissions/getFirstPermissionWithRole';
import BackofficeUserForm, {
  BackofficeUserFormValues,
  DialogType,
} from '../../Unknown/BackofficeUserForm';
import useCheckBrandIsSuspend from '../../../common/hooks/useCheckBrandIsSuspend';

import messages from './messages';
import useSignIn from '../../../common/hooks/useSignIn';
import { useQueryClient } from 'react-query';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { signInWithCustomToken } from 'firebase/auth';
import useTotpAuthContext from '../Totp/useTotpAuthContext';

interface BackofficeUserAccountInformationProps {
  user: ExtendedBackOfficeUser;
  type: DialogType;
}

export type NetworkConfig = {
  positionTakingRate?: number;
  commissionRate?: number;
};

interface UserParams {
  uid: string;
  email: string;
  password: string | null;
  phoneNumber: string | null;
  name: string;
  surname: string;
  status: BackOfficeUserStatus;
  role: string;
  mfa: boolean;
  brandId: string | null;
  isTotpEnabled?: boolean;
  isEnableFirewall: boolean;
  country?: string;
  city?: string;
  street?: string;
  zipCode?: string;
}

export type UpdateUserParams = UserParams & NetworkConfig;

const BackofficeUserAccountInformation: FC<BackofficeUserAccountInformationProps> =
  ({ user, type }) => {
    const intl = useIntl();
    const isBrandSuspended = useCheckBrandIsSuspend();
    const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
    const { setAlert } = useContext(UIContext);
    const auth = useAuth();
    const dispatch = useDispatch();

    const signIn = useSignIn();
    const { getEnrolledFactors } = useTotpAuthContext();

    const { uid } = useSelector((state: RootState) => state.user);

    const permission = getFirstPermissionWithRole(user.roles);

    const queryClient = useQueryClient();

    const firebase = useFirebaseApp();

    const functions = getFunctions(firebase, DEFAULT_REGION);

    const onEditUser = async (
      values: BackofficeUserFormValues,
      { resetForm }: FormikHelpers<BackofficeUserFormValues>,
    ) => {
      const updateUser = httpsCallable<
        Partial<UpdateUserParams>,
        { token: string }
      >(functions, 'back-user-updateUser');
      setIsButtonLoading(true);
      const { ...updateParams } = values;

      const phoneNumber = values.mfa ? values.phoneNumber || null : null;
      const isTotpEnabled = !!getEnrolledFactors().find(
        (item) => item.factorId === 'totp',
      );

      const userData: Partial<UpdateUserParams> = {
        uid: user.id,
        ...updateParams,
        phoneNumber,
        isTotpEnabled,
      };

      try {
        const {
          data: { token },
        } = await updateUser(userData);
        if (token) {
          const { user: updatedUser } = await signInWithCustomToken(
            auth,
            token,
          );

          if (!updatedUser) throw new Error('Failed update user.');

          const idTokenResult = await updatedUser.getIdTokenResult();

          const response = await signIn({
            idToken: idTokenResult.token,
            isAfterUpdate: true,
          });

          if (response?.user) {
            dispatch(loginUserAction(response?.user));
          }
        }

        setAlert({
          show: true,
          severity: 'success',
          message: intl.formatMessage(messages.textWhenTheUserIsUpdated),
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setAlert({
          show: true,
          severity: 'error',
          message: error.toString(),
        });
      } finally {
        resetForm();
        setIsButtonLoading(false);
        queryClient.refetchQueries(['backofficeUser', user.id]);
      }
    };

    const initialValues: BackofficeUserFormValues = useMemo(() => {
      return {
        name: user?.name,
        surname: user?.surname,
        email: user?.email,
        status:
          isBrandSuspended &&
          permission?.role_id !== 'owner' &&
          type === 'backofficeUsers'
            ? 'suspend'
            : user?.status || '',
        phoneNumber: user?.phone_number || undefined,
        role: (permission?.role_id || 'support') as BackofficeUserRole,
        brandId: permission?.brand_id || undefined,
        mfa: user.is_enable_mfa,
        isEnableFirewall: user.is_enable_firewall,
      };
    }, [
      user?.name,
      user?.surname,
      user?.email,
      user?.status,
      user?.phone_number,
      user.is_enable_mfa,
      user.is_enable_firewall,
      isBrandSuspended,
      permission?.role_id,
      permission?.brand_id,
      type,
    ]);

    return (
      <Grid container>
        <Grid item xs={12} pb={4}>
          <CollapseBox
            label={intl.formatMessage(messages.accountInformation)}
            defaultValue
          >
            <BackofficeUserForm
              initialValues={initialValues}
              onSubmit={onEditUser}
              isButtonLoading={isButtonLoading}
              isEdit
              isCurrentUser={uid === user?.id}
              type={type}
            />
          </CollapseBox>
        </Grid>
      </Grid>
    );
  };

export default BackofficeUserAccountInformation;
