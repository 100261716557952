import { useQuery, useQueryClient } from 'react-query';
import { ExtendedBackOfficeUser } from '../../../types';
import useSupabase from './useSupabase';
import { SELECT_EXTENDED_BACKOFFICE_USER } from '../constants';

interface UseUserTransactionsParams {
  brandId: string;
  backOfficeUserId?: string;
  isSystemAdmin?: boolean;
}

const useBackofficeUsers = (
  params: UseUserTransactionsParams,
): typeof returnFunction => {
  const { brandId, isSystemAdmin, backOfficeUserId } = params;

  const queryClient = useQueryClient();

  const supabase = useSupabase();

  const returnFunction = useQuery(
    ['backofficeUsers', brandId],
    async () => {
      if (isSystemAdmin) {
        const response = await supabase
          .from('backoffice_users')
          .select<
            typeof SELECT_EXTENDED_BACKOFFICE_USER,
            ExtendedBackOfficeUser
          >(SELECT_EXTENDED_BACKOFFICE_USER)
          .throwOnError();
        return response.data;
      } else {
        const response = await supabase
          .from('backoffice_users')
          .select<
            typeof SELECT_EXTENDED_BACKOFFICE_USER,
            ExtendedBackOfficeUser
          >(SELECT_EXTENDED_BACKOFFICE_USER)
          .or(
            `backoffice_user_detail.eq.${backOfficeUserId},parent_path.ilike.%${backOfficeUserId}%`,
          )
          .throwOnError();
        return response.data;
      }
    },
    {
      onSuccess: (users) => {
        if (!users) return;

        users.forEach((user) => {
          queryClient.setQueryData('backofficeUser', user.id);
        });
      },
    },
  );

  return returnFunction;
};

export default useBackofficeUsers;
