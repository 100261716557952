import { defineMessages } from 'react-intl';

export default defineMessages({
  id: {
    id: 'Log.LogOverviewList.id',
    defaultMessage: 'ID',
  },
  firstName: {
    id: 'Log.LogOverviewList.firstName',
    defaultMessage: 'First name',
  },
  surname: {
    id: 'Log.LogOverviewList.surname',
    defaultMessage: 'Surname',
  },
  email: {
    id: 'Log.LogOverviewList.email',
    defaultMessage: 'E-mail',
  },
  role: {
    id: 'Log.LogOverviewList.role',
    defaultMessage: 'Role',
  },
  brand: {
    id: 'Log.LogOverviewList.brand',
    defaultMessage: 'Brand',
  },
  action: {
    id: 'Log.LogOverviewList.action',
    defaultMessage: 'Action',
  },
  ip: {
    id: 'Log.LogOverviewList.ip',
    defaultMessage: 'IP',
  },
});
