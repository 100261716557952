import { GridColumns } from '@mui/x-data-grid';
import { OutcomeBets } from './useGetOutcomes';
// import { Link } from 'react-router-dom';

const useColumns = (): GridColumns<OutcomeBets> => {
  return [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      editable: false,
      filterable: false,
      flex: 0.6,
    },
    {
      field: 'event_name',
      headerName: 'Event',
      editable: false,
      filterable: false,
      flex: 0.6,
    },
    {
      field: 'market_name',
      headerName: 'Market',
      editable: false,
      filterable: false,
      flex: 0.6,
      renderCell: ({ row }) => {
        return row.exchange_bets?.[0]?.market_name || '';
      },
    },
    {
      field: 'brand_id',
      headerName: 'Brand',
      editable: false,
      filterable: false,
      flex: 0.6,
    },
    {
      field: 'players',
      headerName: 'Player',
      editable: false,
      filterable: false,
      flex: 0.6,
      // REF: BUG: JSX return breaks
      // renderCell: ({ row }) => {
      //   const { brand_id } = row;
      //   const { id, name, surname } = row?.players;
      //   return (
      //     <Link to={`/b/${brand_id}/player-overview/${id}`}>
      //       {`${name} ${surname}`}
      //     </Link>
      //   );
      // },
      renderCell: ({ row }) => {
        const { name, surname } = row?.players;
        return `${name} ${surname}`;
      },
    },
    {
      field: 'total_bets',
      headerName: 'Total bets',
      editable: false,
      filterable: false,
      flex: 0.6,
    },
    {
      field: 'total_volume',
      headerName: 'Total volume',
      editable: false,
      filterable: false,
      flex: 0.6,
    },
    {
      field: 'total_return',
      headerName: 'Total return',
      editable: false,
      filterable: false,
      flex: 0.6,
      renderCell: ({ row }) => {
        return row.total_return.toFixed(2);
      },
    },
  ];
};

export default useColumns;
