import { FC, useState, useMemo, useEffect, useContext } from 'react';
import { Paper, UIContext } from '@miyagami-com/lsx-ui-components';
import { useSelector } from 'react-redux';
import { GridRowData } from '@mui/x-data-grid';
import { useQuery } from 'react-query';
import { useIntl } from 'react-intl';

import DefaultLayout from '../../Unknown/DefaultLayout';
import { LogType, RootState } from '../../../../types';
import LogOverviewList from '../LogOverviewList';
import HeaderWithFilter from '../../Unknown/HeaderWithFilter';

import sortedSystemLogsData from './sortedSystemLogsData';
import messages from './messages';
import { useParams } from 'react-router';
import LoadingBox from '../../Unknown/LoadingBox';
import getAllBrandUsers from '../../../common/getAllBrandUsers';
import getFromToNumbersSupabase from '../../../common/getFromToNumbersSupabase';
import getBrandLogs from './getBrandLogs';

const logTypes: LogType[] = ['system', 'domain', 'apiKey', 'widget'];

type QueryParams = {
  brandId: string;
};

const rowsPerPageOptions = [5, 10, 15, 25, 50, 100];

const SystemLogsPage: FC = () => {
  const { brandId } = useParams<QueryParams>();

  const intl = useIntl();
  const { setAlert } = useContext(UIContext);

  const [systemLogsRows, setSystemLogsRows] = useState<GridRowData[]>([]);
  const [pageSize, setPageSize] = useState<number>(rowsPerPageOptions[5]);
  const [page, setPage] = useState<number>(0);

  const { brands, users } = useSelector((root: RootState) => root.globalData);

  const offset = page * pageSize;
  const limit = offset + pageSize - 1;

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const onPageSizeChange = (size: number) => {
    setPageSize(size);
  };

  const allUsers = useMemo(
    () => getAllBrandUsers({ brandId, users }),
    [brandId, users],
  );

  const { from, to } = useMemo(
    () => getFromToNumbersSupabase({ list: systemLogsRows, key: 'createdAt' }),
    [systemLogsRows],
  );

  const {
    data: logsData,
    isFetching,
    isError,
  } = useQuery(['getLogs', logTypes, brandId, offset, limit], () =>
    getBrandLogs({ types: logTypes, brandId, offset, limit }),
  );

  useEffect(() => {
    setPage(0);
  }, [brandId]);

  useEffect(() => {
    if (isError) {
      setAlert({
        message: intl.formatMessage(messages.errorLogsMessage),
        severity: 'error',
        show: true,
      });
    }
  }, [intl, isError, setAlert]);

  const defaultSystemLogs = useMemo(() => {
    if (!(logsData?.data && allUsers && brands)) return [];
    const sortedSystemLogs = sortedSystemLogsData(
      logsData.data,
      allUsers,
      brands,
    );
    return sortedSystemLogs;
  }, [allUsers, brands, logsData?.data]);

  const path = [
    { label: intl.formatMessage(messages.system) },
    { label: intl.formatMessage(messages.systemLogs) },
  ];

  return (
    <DefaultLayout path={path}>
      <Paper sx={{ m: 4 }}>
        <HeaderWithFilter
          name={intl.formatMessage(messages.systemLogs)}
          from={from}
          to={to}
          defaultRows={defaultSystemLogs}
          setRows={setSystemLogsRows}
          brands={brands}
        />
        {isFetching ? (
          <LoadingBox />
        ) : (
          <LogOverviewList
            pageSize={pageSize}
            rows={systemLogsRows || []}
            rowsPerPageOptions={rowsPerPageOptions}
            onPageChange={onPageChange}
            page={page}
            isGettingLoading={isFetching}
            onPageSizeChange={onPageSizeChange}
          />
        )}
      </Paper>
    </DefaultLayout>
  );
};

export default SystemLogsPage;
