import { useFirebaseApp } from 'reactfire';
import { BetfairBetType } from '../../../types';
import { DEFAULT_REGION } from '../constants';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface CreateBrandParams {
  name: string;
  ownerId: string;
  pt: number;
  commission: number;
  isDirectPlayerEnabled: boolean;
  directPlayerComission: number;
  betfairBetType: BetfairBetType;
}

type UseCreateBrand = (params: CreateBrandParams) => Promise<void>;

const useCreateBrand = (): UseCreateBrand => {
  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const createBrand = async (params: CreateBrandParams) => {
    const createBrandFunction = httpsCallable(
      functions,
      'back-brand-createBrand',
    );

    await createBrandFunction(params);
  };

  return createBrand;
};

export default useCreateBrand;
