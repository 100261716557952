import * as Yup from 'yup';

import { Field } from '../../../common/validationMessages';

const validationSchema = Yup.object().shape({
  domain: Yup.string().trim(Field.trim).required(Field.required),
  locationId: Yup.string().trim(Field.trim).required(Field.required),
  username: Yup.string(),
  password: Yup.string(),
  certificate: Yup.object().nullable(),
  key: Yup.object().nullable(),
  apiKeyId: Yup.string(),
});

export default validationSchema;
