import { PayloadAction, createSlice, Dispatch } from '@reduxjs/toolkit';

import {
  Brand,
  BackOfficeUserStatus,
  GlobalDataState,
  ExtendedBackOfficeUser,
} from '../../types';
import filterUsersByRole from '../common/filterUsersByRole';
import { SYSTEM_ADMIN_ROLE } from '../common/constants';

import { setBrandIds } from './user';
import checkIsBrandOwner from '../common/checkIsBrandOwner';

const initialState: GlobalDataState = {
  users: {
    systemAdmins: [],
    brandOwners: [],
    byBrandId: {},
  },
  brands: [],
  locationName: null,
};

export type UserType = 'agents' | 'backofficeUsers';

type BrandIdType = {
  brandId: string;
  type: UserType;
};

type UpdateBrandAgentsStatusPayloadAction = {
  status: BackOfficeUserStatus;
  brandId: string;
  agentId: string;
};

type UsersPayloadAction = {
  users: ExtendedBackOfficeUser[];
  brandId: string;
};

type UsersByRolePayloadAction = {
  usersList: ExtendedBackOfficeUser[];
};

type UserPayloadAction = BrandIdType & {
  user: ExtendedBackOfficeUser;
};

type DeleteUser = BrandIdType & {
  userId: string;
};

const { reducer, actions } = createSlice({
  name: 'globalData',
  initialState,
  reducers: {
    updateBrandAgentsStatus(
      state,
      {
        payload: { status, agentId, brandId },
      }: PayloadAction<UpdateBrandAgentsStatusPayloadAction>,
    ) {
      const users = { ...state.users.byBrandId };
      const usersByBrand = { ...users[brandId] };

      const agents = usersByBrand.agents?.map((agent) => {
        if (agent?.parent_path?.includes(agentId)) {
          return {
            ...agent,
            status,
          };
        }

        return agent;
      });

      state.users.byBrandId[brandId].agents = agents;
    },

    updateUsers(state, { payload }: PayloadAction<UsersPayloadAction>) {
      const { users, brandId } = payload;

      const filteredUsersByRole = filterUsersByRole({
        users,
        brandId,
      });

      state.users.byBrandId[brandId] = { ...filteredUsersByRole };
    },

    updateUsersByRole(
      state,
      { payload: { usersList } }: PayloadAction<UsersByRolePayloadAction>,
    ) {
      const brandOwners = usersList.filter(({ roles }) =>
        roles.some((role) => role.role_id === 'owner'),
      );

      const systemAdmins = usersList.filter(({ roles }) =>
        roles.some((role) => role.role_id === SYSTEM_ADMIN_ROLE),
      );

      state.users.brandOwners = brandOwners;
      state.users.systemAdmins = systemAdmins;
    },

    updateUserDataById(
      state,
      { payload: { user, brandId, type } }: PayloadAction<UserPayloadAction>,
    ) {
      const users = { ...state.users.byBrandId };

      const usersByBrand = { ...users[brandId] };

      const usersByType = usersByBrand[type] || [];

      const indexOfUpdatedUser = usersByType.findIndex(
        (currentUser) => currentUser.id === user.id,
      );

      if (indexOfUpdatedUser + 1) {
        usersByType.splice(indexOfUpdatedUser, 1, user);

        state.users.byBrandId[brandId][type] = usersByType;
      }
    },

    addNewUser(
      state,
      { payload: { user, brandId, type } }: PayloadAction<UserPayloadAction>,
    ) {
      const newUserIsBrandOwner = checkIsBrandOwner({ roles: user.roles });
      if (newUserIsBrandOwner) {
        state.users.brandOwners = [user, ...state.users.brandOwners];
      }

      if (!brandId && !newUserIsBrandOwner) {
        state.users.systemAdmins = [user, ...state.users.systemAdmins];
      }

      if (brandId && !newUserIsBrandOwner) {
        const users = state.users.byBrandId[brandId]?.[type] || [];

        const newUserList = [user, ...users];

        state.users.byBrandId[brandId][type] = newUserList;
      }
    },

    deleteUser(
      state,
      { payload: { userId, brandId, type } }: PayloadAction<DeleteUser>,
    ) {
      if (!brandId) return;

      const updatedUserList = state.users.byBrandId?.[brandId]?.[type]?.filter(
        ({ id }) => id !== userId,
      );

      state.users.byBrandId[brandId][type] = updatedUserList;
    },

    updateBrands(state, action: PayloadAction<Brand[]>) {
      state.brands = [...action.payload];
    },

    setLocationName(state, action: PayloadAction<string>) {
      const locationName = action.payload;

      state.locationName = locationName;
    },

    resetGlobalData(state) {
      state.users = initialState.users;
      state.brands = initialState.brands;
    },
  },
});

export const {
  updateUsers,
  updateBrandAgentsStatus,
  updateUsersByRole,
  addNewUser,
  deleteUser,
  updateBrands,
  setLocationName,
  resetGlobalData,
  updateUserDataById,
} = actions;

export const updateBrandsAction =
  (brands: Brand[]) =>
  async (dispatch: Dispatch): Promise<void> => {
    const brandIds = brands.map((brand) => brand.id);

    dispatch(updateBrands(brands));

    dispatch(setBrandIds(brandIds));
  };

export default reducer;
