import { Box, Button } from '@miyagami-com/lsx-ui-components';
import DateRangePicker from '../../Unknown/DateRangePicker';
import { FC } from 'react';
import { DateRange } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { useIntl } from 'react-intl';
import messages from './messages';

type Props = {
  createdAtDateRange: DateRange<Date>;
  onChangeCreatedAtDateRange: (range: DateRange<Date>) => void;
  settledAtDateRange: DateRange<Date>;
  onChangeSettledAtDateRange: (range: DateRange<Date>) => void;
};

const inputStyles = {
  minWidth: '120px',
  '& .MuiInputLabel-root': {
    fontSize: '0.75rem',
    transform: 'translate(14px, 6px) scale(1)',
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(14px, -6px) scale(0.75)',
  },
};

const ExchangeBetDateRangeFilter: FC<Props> = ({
  createdAtDateRange,
  onChangeCreatedAtDateRange,
  settledAtDateRange,
  onChangeSettledAtDateRange,
}) => {
  const intl = useIntl();
  const renderDatePicker = (
    startLabel: string,
    endLabel: string,
    dateRange: DateRange<Date>,
    onChange: (range: DateRange<Date>) => void,
    resetFilter: () => void,
  ) => {
    return (
      <Box
        key={startLabel}
        pr={2}
        py={{ xs: 2, md: 0 }}
        display="flex"
        alignItems="center"
      >
        <DateRangePicker
          localizationProviderProps={{}}
          startInputProps={{
            label: startLabel,
            sx: inputStyles,
          }}
          endInputProps={{
            label: endLabel,
            sx: inputStyles,
          }}
          dateRangePickerProps={{
            value: dateRange,
            onChange: (range: DateRange<Date>) => onChange(range),
          }}
        />

        {dateRange.some((date) => date !== null) && (
          <Button variant="text" onClick={() => resetFilter()}>
            <CloseIcon color="primary" />
          </Button>
        )}
      </Box>
    );
  };

  return (
    <>
      {[
        {
          startLabel: intl.formatMessage(messages.createdAtStart),
          endLabel: intl.formatMessage(messages.createdAtEnd),
          dateRange: createdAtDateRange,
          onChange: onChangeCreatedAtDateRange,
          resetFilter: () => onChangeCreatedAtDateRange([null, null]),
        },
        {
          startLabel: intl.formatMessage(messages.settledAtStart),
          endLabel: intl.formatMessage(messages.settledAtEnd),
          dateRange: settledAtDateRange,
          onChange: onChangeSettledAtDateRange,
          resetFilter: () => onChangeSettledAtDateRange([null, null]),
        },
      ].map(({ startLabel, endLabel, dateRange, onChange, resetFilter }) => {
        return renderDatePicker(
          startLabel,
          endLabel,
          dateRange,
          onChange,
          resetFilter,
        );
      })}
    </>
  );
};

export default ExchangeBetDateRangeFilter;
