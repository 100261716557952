import { useFirebaseApp } from 'reactfire';
import { ProfitLossReportLevel } from '../../../types';
import { DEFAULT_REGION } from '../constants';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface ReportParams {
  reportLevel: ProfitLossReportLevel;
  from: string;
  to: string;
  brandId: string | null;
  parentId: string | null;
}

export interface DetailedProfitReport {
  id: string | null;
  refId: string | null;
  name: string;
  player: {
    winLoss: number;
    commission: number;
    total: number;
  };
  general: {
    level: ProfitLossReportLevel;
    totalBets: number;
    tumOver: number;
    refId: string | null;
    name: string;
  };
  totals: {
    [K in ProfitLossReportLevel]: {
      winLoss: number;
      commission: number;
      total: number;
    };
  };
  payout: number;
  upLine: number;
}

type FetchReports = (
  params: ReportParams,
) => Promise<DetailedProfitReport[] | null>;

const useDetailedPLReport = (): FetchReports => {
  const firebase = useFirebaseApp();

  const functions = getFunctions(firebase, DEFAULT_REGION);

  const fetchReport = async (params: ReportParams) => {
    const fetchFunction = httpsCallable(
      functions,
      'back-profitLoss-detailedReport',
    );

    const response = await fetchFunction(params);

    const reports = response.data as DetailedProfitReport[];

    return reports;
  };

  return fetchReport;
};

export default useDetailedPLReport;
