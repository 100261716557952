import React, { useEffect, useState } from 'react';
import { FormattedDateParts, FormattedTime } from 'react-intl';

import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { Box, Typography } from '@miyagami-com/lsx-ui-components';

type RenderTimeProps = {
  open: boolean;
};

const RenderTime: React.FC<RenderTimeProps> = ({ open }) => {
  const [date, setDate] = useState<number>(Date.now());

  useEffect(() => {
    const timeInterval = setInterval(() => {
      setDate(Date.now());
    }, 1000 * 60);
    return () => {
      clearInterval(timeInterval);
    };
  }, [date]);

  return (
    <Box
      px={open ? 2 : 0}
      py={1.6}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      whiteSpace="nowrap"
      minHeight="33px"
    >
      {open ? (
        <>
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Box pr={0.5} display="flex" alignItems="center">
              <AccessTimeIcon fontSize="small" />
            </Box>
            <Box pr={1}>
              <Typography>
                <FormattedTime timeStyle="short" value={date} hour12={false} />
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography variant="body2">
              <FormattedDateParts
                value={new Date()}
                year="numeric"
                month="short"
                day="2-digit"
              >
                {(parts) => (
                  <span>
                    {parts[2].value} {parts[0].value} {parts[4].value}
                  </span>
                )}
              </FormattedDateParts>
            </Typography>
          </Box>
        </>
      ) : (
        <Box pl={2} display="flex" alignItems="center">
          <AccessTimeIcon fontSize="small" />
        </Box>
      )}
    </Box>
  );
};

export default RenderTime;
