import { getFunctions, httpsCallable } from 'firebase/functions';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../../common/constants';
import { useCallback } from 'react';

interface PlayerData {
  id: string;
  name: string;
  surname: string;
}

export interface BetData {
  id: string;
  size: number;
  price: number;
  side: string;
  selection_name: string;
  status: string;
  market_name: string;
  outcomeId: string;
}

export interface OutcomeBets {
  id: string;
  total_return: number;
  liability: number;
  total_bets: number;
  total_volume: number;
  brand_id: string;
  players: PlayerData;
  exchange_bets: BetData[];
}

interface OutcomesData {
  data: OutcomeBets[];
}

const normaliseOutcomes = (outcomes: OutcomeBets[]) => {
  return outcomes
    .filter((outcome) => outcome.exchange_bets.length)
    .sort(
      (a, b) =>
        a.brand_id.localeCompare(b.brand_id) ||
        a.players.name.localeCompare(b.players.name),
    );
};

const useGetOutcomes = (): ((marketId: string) => Promise<OutcomeBets[]>) => {
  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const getOutcomesByMarketId = httpsCallable(
    functions,
    'back-market-callGetOutcomesByMarketId',
  );

  const callGetOutcomesByMarketId = useCallback(
    async (marketId: string) => {
      const outcomesData = (await getOutcomesByMarketId({
        marketId,
      })) as OutcomesData;

      const outcomes = outcomesData.data;
      const normalisedOutcomes = normaliseOutcomes(outcomes);

      return normalisedOutcomes;
    },
    [getOutcomesByMarketId],
  );

  return callGetOutcomesByMarketId;
};

export default useGetOutcomes;
