import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { colors } from '../theme';

import messages from '../messages';

export type UserFlaggedStatus = 'flagged' | 'unflagged';

export type UserStatus = {
  label: string;
  color: string;
  hidden: boolean;
};

export type UseUserFlaggedStatusesMap = Record<UserFlaggedStatus, UserStatus>;

const useUserFlaggedStatuses = (): UseUserFlaggedStatusesMap => {
  const intl = useIntl();

  return useMemo(() => {
    return {
      flagged: {
        label: intl.formatMessage(messages.playerFlagged),
        color: colors.errorMain,
        hidden: false,
      },
      unflagged: {
        label: intl.formatMessage(messages.playerNotFlagged),
        color: colors.grey300,
        hidden: false,
      },
    };
  }, [intl]);
};

export default useUserFlaggedStatuses;
