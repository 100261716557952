import { PermissionMap } from '../../../types';
import { SYSTEM_ADMIN_ROLE } from '../constants';
import findParsedRoleByBrandId from '../role/findParsedRoleByBrandId';
import parsePermissions from './parsePermissions';
import parseRoles from './parseRoles';
import permissionMapByRole from './permissionMapByRole';

interface CreateUserPermissionsMapParams {
  permissions: string[];
  roles: string[];
  brandIds: string[];
}

const createUserPermissionsMap = ({
  permissions,
  roles,
  brandIds,
}: CreateUserPermissionsMapParams): Record<string, PermissionMap> => {
  const permissionMapByBrandId: Record<string, PermissionMap> = {};

  const parsedRoles = parseRoles({ roles });

  const isAdmin = roles.includes(SYSTEM_ADMIN_ROLE);

  brandIds.forEach((brandId) => {
    const permissionRole = isAdmin
      ? SYSTEM_ADMIN_ROLE
      : findParsedRoleByBrandId({ roles: parsedRoles, brandId })?.role;

    if (!permissionRole) return permissionMapByBrandId;

    permissionMapByBrandId[brandId] = {
      ...permissionMapByRole[permissionRole],
    };

    const parsedPermissions = parsePermissions({ permissions });

    const userPermissionsByBrandId = parsedPermissions.filter(
      ({ brandId: permissionBrandId }) => permissionBrandId === brandId,
    );

    if (!userPermissionsByBrandId.length) return permissionMapByBrandId;

    userPermissionsByBrandId.forEach(
      ({ permissionType, operation, brandId: userBrandId }) => {
        permissionMapByBrandId[userBrandId] = {
          ...permissionMapByBrandId[userBrandId],
          [permissionType]: {
            ...permissionMapByBrandId[userBrandId][permissionType],
            [operation]: true,
          },
        };
      },
    );
  });

  return permissionMapByBrandId;
};

export default createUserPermissionsMap;
