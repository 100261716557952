import { FC } from 'react';
import { Link } from '@miyagami-com/lsx-ui-components';
import { Link as RouterLink } from 'react-router-dom';

interface LinkCellProps {
  url: string;
}

const LinkCell: FC<LinkCellProps> = ({ url, children }) => {
  return (
    <Link
      component={RouterLink}
      underline="always"
      color="primary.dark"
      to={url}
    >
      {children}
    </Link>
  );
};

export default LinkCell;
