import { ExtendedExchangeBet } from '../../types';
import Big from 'big.js';

interface CalculateMatchedStakeParams {
  exchangeBet: ExtendedExchangeBet;
}

const calculateMatchedStake = (params: CalculateMatchedStakeParams): number => {
  const { exchangeBet } = params;

  const { betfairExchangeBet, size } = exchangeBet;

  const { betfairExchangeSizes, betfair_bet_size: betfairBetSize } =
    betfairExchangeBet;

  const matchedSize =
    betfairExchangeSizes.find((betfairSize) => betfairSize.type === 'matched')
      ?.size || 0;

  const matchedStake = new Big(matchedSize).div(betfairBetSize).times(size);

  return matchedStake.toNumber();
};

export default calculateMatchedStake;
