import { FC, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState, UserPlayerNote } from '../../../../types';

import DataGridTable from '../../Unknown/DataGridTable';

import useColumns from './useColumns';
import getAllBrandUsers from '../../../common/getAllBrandUsers';
import { PlayerNote } from '../../../../types/supabase';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../../../common/constants';
import { UIContext } from '@miyagami-com/lsx-ui-components';
import messages from './messages';
import { getFunctions, httpsCallable } from 'firebase/functions';

type RowWithUserNote = Omit<UserPlayerNote, 'createdAt' | 'updatedAt'> & {
  date: string;
  time: string;
  author: string;
  isLoading?: boolean;
};

export type OnDeleteNote = () => Promise<void> | void;

interface UserNoteListProps {
  brandId: string;
  playerId: string;
  isLoading: boolean;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPage: number) => void;
  notes: PlayerNote[];
  page: number;
  pageSize: number;
  onDeleteNote: OnDeleteNote;
}

const UserNoteList: FC<UserNoteListProps> = (props) => {
  const {
    playerId,
    brandId,
    isLoading,
    onPageChange,
    onPageSizeChange,
    notes,
    page,
    pageSize,
    onDeleteNote,
  } = props;

  const { setAlert } = useContext(UIContext);

  const { users } = useSelector((state: RootState) => state.globalData);

  const allUsers = useMemo(
    () => getAllBrandUsers({ users, brandId }),
    [brandId, users],
  );

  const intl = useIntl();

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const onDelete = async (noteId: string) => {
    try {
      const deleteUserNoteFunction = httpsCallable(
        functions,
        'back-userNote-deleteUserNote',
      );

      await deleteUserNoteFunction({
        noteId,
        userId: playerId,
        brandId,
      });

      if (onDeleteNote) {
        await onDeleteNote();
      }
    } catch (error) {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.errorMessage),
      });
    }
  };

  const columns = useColumns({ onDelete });

  const rows = useMemo(() => {
    if (!notes?.length) return [];

    return notes.map((playerNote) => {
      const { created_at, id, author_id, note } = playerNote;

      const createdAtDate = new Date(created_at);

      const date = intl.formatDate(createdAtDate, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });

      const time = intl.formatDate(createdAtDate, {
        hour: 'numeric',
        minute: 'numeric',
      });

      const author =
        allUsers.find(({ id: userId }) => author_id === userId)?.username || '';

      const createdAt = new Date().toISOString();

      const row: RowWithUserNote = {
        id,
        author_id,
        date,
        time,
        note,
        author,
        isLoading: false,
        created_at: createdAt,
        updated_at: createdAt,
      };

      return row;
    });
  }, [allUsers, intl, notes]);

  return (
    <DataGridTable
      tableKey="player-note-list"
      loading={isLoading}
      boxProps={{ pb: 0 }}
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={onPageSizeChange}
      rowsPerPageOptions={[5, 10, 15, 25]}
      disableSelectionOnClick
      autoHeight
      rowHeight={35}
      headerHeight={35}
      page={page}
      pagination
      onPageChange={onPageChange}
      hideFooterRowCount
    />
  );
};

export default UserNoteList;
