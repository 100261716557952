import React, { useState } from 'react';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Box,
  FormHelperText,
  Typography,
} from '@miyagami-com/lsx-ui-components';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { VisibilityOff, Visibility } from '../Icons';

interface PasswordOutlinedInputProps extends OutlinedInputProps {
  helperText?: string;
  validationText?: string;
}

const PasswordOutlinedInput: React.FC<PasswordOutlinedInputProps> = ({
  label,
  id,
  error,
  helperText,
  validationText,
  disabled,
  ...restProps
}) => {
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

  const onClickShowPassword = () => {
    setIsShowPassword((prev) => !prev);
  };

  return (
    <>
      {validationText && (
        <Box pb={1}>
          <Typography variant="body2">{validationText}</Typography>
        </Box>
      )}
      <FormControl variant="outlined" fullWidth id={id}>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <OutlinedInput
          id={id}
          label={label}
          type={isShowPassword ? 'text' : 'password'}
          disabled={disabled}
          {...restProps}
          error={error}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={onClickShowPassword}
                edge="end"
                tabIndex={-1}
                disabled={disabled}
              >
                {isShowPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        {error && helperText && (
          <Box>
            <FormHelperText error={error} variant="outlined" id={id}>
              {helperText}
            </FormHelperText>
          </Box>
        )}
      </FormControl>
    </>
  );
};

export default PasswordOutlinedInput;
