import { defineMessages } from 'react-intl';

export default defineMessages({
  domain: {
    id: 'BrandDomain.BrandDomainVerification.domain',
    defaultMessage: 'Domain',
  },
  reason: {
    id: 'BrandDomain.BrandDomainVerification.reason',
    defaultMessage: 'Reason',
  },
  type: {
    id: 'BrandDomain.BrandDomainVerification.type',
    defaultMessage: 'Type',
  },
  value: {
    id: 'BrandDomain.BrandDomainVerification.value',
    defaultMessage: 'Value',
  },
  challenges: {
    id: 'BrandDomain.BrandDomainVerification.challenges',
    defaultMessage:
      'A list of verification challenges, one of which must be completed to verify the domain for use on the project',
  },
  domainError: {
    id: 'BrandDomain.BrandDomainVerification.domainError',
    defaultMessage: 'An error occurred while getting a domain!',
  },
  domainVerified: {
    id: 'BrandDomain.BrandDomainVerification.domainVerified',
    defaultMessage: 'Brand domain successfully verified',
  },
  domainVerification: {
    id: 'BrandDomain.BrandDomainVerification.domainVerification',
    defaultMessage: 'Brand domain verification',
  },
  close: {
    id: 'BrandDomain.BrandDomainVerification.close',
    defaultMessage: 'Close',
  },
  verify: {
    id: 'BrandDomain.BrandDomainVerification.verify',
    defaultMessage: 'Verify',
  },
});
