import { Box } from '@miyagami-com/lsx-ui-components';
import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import DefaultLayout from '../../Unknown/DefaultLayout';

import WidgetDetails from '../WidgetDetails';

import messages from './messages';
import LoadingBox from '../../Unknown/LoadingBox';
import useBrandWidget from '../../../common/hooks/useBrandWidget';
import useBrandId from '../../../common/hooks/useBrandId';
import { SubTagPath } from '../../Unknown/SubTags';

type QueryParams = {
  widgetId: string;
};

const WidgetDetailsPage: React.FC = () => {
  const intl = useIntl();

  const { widgetId }: QueryParams = useParams();

  const brandId = useBrandId();

  const { data: widget, isLoading } = useBrandWidget({ widgetId });

  const path: SubTagPath[] = [
    { label: intl.formatMessage(messages.cms) },
    {
      label: intl.formatMessage(messages.widgets),
      url: `/b/${brandId}/widgets`,
    },
    { label: widget?.title || '' },
  ];

  if (isLoading) {
    return <LoadingBox />;
  }

  return (
    <DefaultLayout path={path}>
      <Box p={4}>
        {widget && brandId && (
          <WidgetDetails widget={widget} brandId={brandId} />
        )}
      </Box>
    </DefaultLayout>
  );
};

export default WidgetDetailsPage;
