import { defineMessages } from 'react-intl';

export default defineMessages({
  id: {
    id: 'Brand.BrandOverviewPage.id',
    defaultMessage: 'ID',
  },
  name: {
    id: 'Brand.BrandOverviewPage.name',
    defaultMessage: 'Name',
  },
  administrator: {
    id: 'Brand.BrandOverviewPage.administrator',
    defaultMessage: 'Administrator',
  },
  pl: {
    id: 'Brand.BrandOverviewPage.pl',
    defaultMessage: 'P/L',
  },
  domains: {
    id: 'Brand.BrandOverviewPage.domains',
    defaultMessage: 'Domains',
  },
  netCommission: {
    id: 'Brand.BrandOverviewPage.netCommission',
    defaultMessage: 'Net commission',
  },
  directPlayerComission: {
    id: 'Brand.BrandOverviewPage.directPlayerComission',
    defaultMessage: 'Player Commission',
  },
  actions: {
    id: 'Brand.BrandOverviewPage.actions',
    defaultMessage: 'Actions',
  },
  netPl: {
    id: 'Brand.BrandOverviewPage.netPl',
    defaultMessage: 'Net P/L',
  },
  status: {
    id: 'Brand.BrandOverviewPage.status',
    defaultMessage: 'Status',
  },
  view: {
    id: 'Brand.BrandOverviewPage.view',
    defaultMessage: 'View',
  },
  editBrand: {
    id: 'Brand.BrandOverviewPage.editBrand',
    defaultMessage: 'Edit',
  },
  brandOverview: {
    id: 'Brand.BrandOverviewPage.brandOverview',
    defaultMessage: 'Brand overview',
  },
  addBrand: {
    id: 'Brand.BrandOverviewPage.addBrand',
    defaultMessage: 'Add brand',
  },
  system: {
    id: 'Brand.BrandOverviewPage.system',
    defaultMessage: 'System',
  },
  successfullyDeleteBrand: {
    id: 'Brand.BrandOverviewPage.successfullyDeleteBrand',
    defaultMessage: 'Successfully deleting brand.',
  },
  deleteBrand: {
    id: 'Brand.BrandOverviewPage.deleteBrand',
    defaultMessage: 'Delete',
  },
});
