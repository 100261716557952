import { defineMessages } from 'react-intl';

export default defineMessages({
  settings: {
    id: 'Unknown.Header.settings',
    defaultMessage: 'Settings',
  },
  myAccount: {
    id: 'Unknown.Header.myAccount',
    defaultMessage: 'My account',
  },
  logout: {
    id: 'Unknown.Header.logout',
    defaultMessage: 'Logout',
  },
  lsxTech: {
    id: 'Unknown.Header.lsxTech',
    defaultMessage: 'TechSoftX',
  },
});
