import { defineMessages } from 'react-intl';

export default defineMessages({
  betLog: {
    id: 'Event.EventDetailExchangeBetLogs.betLog',
    defaultMessage: 'Bet Log',
  },
  id: {
    id: 'Event.EventDetailExchangeBetLogs.id',
    defaultMessage: 'ID',
  },
  date: {
    id: 'Event.EventDetailExchangeBetLogs.date',
    defaultMessage: 'Date',
  },
  time: {
    id: 'Event.EventDetailExchangeBetLogs.time',
    defaultMessage: 'Time',
  },
  stake: {
    id: 'Event.EventDetailExchangeBetLogs.stake',
    defaultMessage: 'Stake',
  },
  odds: {
    id: 'Event.EventDetailExchangeBetLogs.odds',
    defaultMessage: 'Odds',
  },
  status: {
    id: 'Event.EventDetailExchangeBetLogs.status',
    defaultMessage: 'Status',
  },
  player: {
    id: 'Event.EventDetailExchangeBetLogs.player',
    defaultMessage: 'Player',
  },
  selection: {
    id: 'Event.EventDetailExchangeBetLogs.selection',
    defaultMessage: 'Selection',
  },
  side: {
    id: 'Event.EventDetailExchangeBetLogs.side',
    defaultMessage: 'Side',
  },
  market: {
    id: 'Event.EventDetailExchangeBetLogs.market',
    defaultMessage: 'Market',
  },
});
