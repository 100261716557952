import { defineMessages } from 'react-intl';

export default defineMessages({
  personal: {
    id: 'Unknown.BackofficeUserForm.personal',
    defaultMessage: 'Personal',
  },
  name: {
    id: 'Unknown.BackofficeUserForm.name',
    defaultMessage: 'Name',
  },
  surname: {
    id: 'Unknown.BackofficeUserForm.surname',
    defaultMessage: 'Surname',
  },
  account: {
    id: 'Unknown.BackofficeUserForm.account',
    defaultMessage: 'Account',
  },
  email: {
    id: 'Unknown.BackofficeUserForm.email',
    defaultMessage: 'E-mail',
  },
  enableMFA: {
    id: 'Unknown.BackofficeUserForm.enableMFA',
    defaultMessage: 'Enable MFA',
  },
  configureTOTP: {
    id: 'Unknown.BackofficeUserForm.configureTOTP',
    defaultMessage: 'Configure TOTP',
  },
  configure: {
    id: 'Unknown.BackofficeUserForm.configure',
    defaultMessage: 'Configure',
  },
  unenrollTotp: {
    id: 'Unknown.BackofficeUserForm.unenrollTotp',
    defaultMessage: 'Revoke',
  },
  enableFirewall: {
    id: 'Unknown.BackofficeUserForm.enableFirewall',
    defaultMessage: 'Enable Firewall',
  },
  roleSectionTitle: {
    id: 'Unknown.BackofficeUserForm.roleSectionTitle',
    defaultMessage: 'Role',
  },
  role: {
    id: 'Unknown.BackofficeUserForm.role',
    defaultMessage: 'Role',
  },
  brand: {
    id: 'Unknown.BackofficeUserForm.brand',
    defaultMessage: 'Brand',
  },
  save: {
    id: 'Unknown.BackofficeUserForm.save',
    defaultMessage: 'Save',
  },
  status: {
    id: 'Unknown.BackofficeUserForm.status',
    defaultMessage: 'Status',
  },
  password: {
    id: 'Unknown.BackofficeUserForm.password',
    defaultMessage: 'Password',
  },
  phoneNumber: {
    id: 'Unknown.BackofficeUserForm.phoneNumber',
    defaultMessage: 'Phone number',
  },
  userRole: {
    id: 'Unknown.BackofficeUserForm.userRole',
    defaultMessage: 'Role: {role}',
  },
  networkSelectionLabel: {
    id: 'Unknown.BackofficeUserForm.networkSelectionLabel',
    defaultMessage: 'Network config',
  },
  positionTakingLabel: {
    id: 'Unknown.BackofficeUserForm.positionTakingLabel',
    defaultMessage: 'PT',
  },
  commissionLabel: {
    id: 'Unknown.BackofficeUserForm.commissionLabel',
    defaultMessage: 'Commission',
  },
});
