import { AxiosResponse } from 'axios';

const getErrorMessage = (error: {
  message?: string;
  response?: AxiosResponse<{ message?: string }>;
}): string => {
  let message = error?.message;
  if (error?.response?.data?.message) {
    message = error.response.data.message;
  }
  if (!message) return 'Unknown error, please inform the administrator';
  return message;
};

export default getErrorMessage;
