import React from 'react';
import Header from '../Header';
import useStyles from './useStyles';
import { Box, Modal } from '@miyagami-com/lsx-ui-components';
import { useMediaQuery, useTheme } from '@mui/material';

const AuthenticationLayout: React.FC = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box width={1} height="100vh">
      <Box className={classes.header} position="sticky">
        <Header isMobile={isMobile} isAuth={false} />
      </Box>
      <Box width={1} height={1} className={classes.container}>
        <Modal className={classes.modal} open>
          <Box className={classes.modalContainer}>{children}</Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default AuthenticationLayout;
