import { defineMessages } from 'react-intl';

export default defineMessages({
  id: {
    id: 'ExchangeBet.ExchangeBetOverviewList.id',
    defaultMessage: 'ID',
  },
  date: {
    id: 'ExchangeBet.ExchangeBetOverviewList.date',
    defaultMessage: 'Date',
  },
  settled: {
    id: 'ExchangeBet.ExchangeBetOverviewList.settled',
    defaultMessage: 'Settled',
  },
  eventType: {
    id: 'ExchangeBet.ExchangeBetOverviewList.eventType',
    defaultMessage: 'Event type',
  },
  event: {
    id: 'ExchangeBet.ExchangeBetOverviewList.event',
    defaultMessage: 'Event',
  },
  market: {
    id: 'ExchangeBet.ExchangeBetOverviewList.market',
    defaultMessage: 'Market',
  },
  marketId: {
    id: 'ExchangeBet.ExchangeBetOverviewList.marketId',
    defaultMessage: 'Market id',
  },
  initialPrice: {
    id: 'ExchangeBet.ExchangeBetOverviewList.initialPrice',
    defaultMessage: 'Initial price',
  },
  averagePrice: {
    id: 'ExchangeBet.ExchangeBetOverviewList.averagePrice',
    defaultMessage: 'Average price',
  },
  priceReduction: {
    id: 'ExchangeBet.ExchangeBetOverviewList.priceReduction',
    defaultMessage: 'Price reduction',
  },
  stake: {
    id: 'ExchangeBet.ExchangeBetOverviewList.stake',
    defaultMessage: 'Stake',
  },
  matchedStake: {
    id: 'ExchangeBet.ExchangeBetOverviewList.matchedStake',
    defaultMessage: 'Matched Stake',
  },
  player: {
    id: 'ExchangeBet.ExchangeBetOverviewList.player',
    defaultMessage: 'Player',
  },
  status: {
    id: 'ExchangeBet.ExchangeBetOverviewList.status',
    defaultMessage: 'Status',
  },
  betStatus: {
    id: 'ExchangeBet.ExchangeBetOverviewList.betStatus',
    defaultMessage: 'Bet Status',
  },
  actions: {
    id: 'ExchangeBet.ExchangeBetOverviewList.actions',
    defaultMessage: 'Actions',
  },
  outcome: {
    id: 'ExchangeBet.ExchangeBetOverviewList.outcome',
    defaultMessage: 'Outcome',
  },
  selection: {
    id: 'ExchangeBet.ExchangeBetOverviewList.selection',
    defaultMessage: 'Selection',
  },
  viewBet: {
    id: 'ExchangeBet.ExchangeBetOverviewList.viewBet',
    defaultMessage: 'View',
  },
  editBet: {
    id: 'ExchangeBet.ExchangeBetOverviewList.editBet',
    defaultMessage: 'Edit',
  },
  sideLabel: {
    id: 'ExchangeBet.ExchangeBetOverviewList.sideLabel',
    defaultMessage: 'Side',
  },
});
