import { DataGridProps } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../../common/constants';
import useEventOverviewBets, {
  MarketExposure,
} from '../../../common/hooks/useEventOverviewBets';
import OverviewLayout from '../../Unknown/OverviewLayout';
import EventOverviewList from '../EventOverviewList';
import messages from './messages';
import normalizeEventOverviewRows from './normalizeEventOverviewRows';
interface EventOverviewProps {
  brandId: string;
  userId: string;
  isAdmin: boolean;
}

const EventOverview: React.FC<EventOverviewProps> = (props) => {
  const { userId } = props;

  const intl = useIntl();

  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);

  const onPageChange = useCallback(
    (newPage: number) => {
      setPage(newPage);
    },
    [setPage],
  );

  const onPageSizeChange = useCallback(
    (newPage: number) => {
      setPageSize(newPage);
    },
    [setPageSize],
  );

  const { data: defaultRows, isLoading } = useEventOverviewBets({
    brandId: userId,
  });

  const [rows, setRows] = useState<MarketExposure[]>([]);

  useEffect(() => {
    if (defaultRows) {
      setRows(defaultRows);
    }
  }, [defaultRows]);

  const normalizedRows = useMemo(() => {
    return normalizeEventOverviewRows({ betEvents: rows });
  }, [rows]);

  const dataGridProps: Omit<DataGridProps, 'columns'> = useMemo(() => {
    return {
      page,
      pageSize,
      rows: normalizedRows,
      loading: isLoading,
      onPageChange,
      onPageSizeChange,
    };
  }, [
    isLoading,
    normalizedRows,
    onPageChange,
    onPageSizeChange,
    page,
    pageSize,
  ]);

  return (
    <OverviewLayout
      headerProps={{
        name: intl.formatMessage(messages.eventOverview),
        // brands: brands,
        defaultRows: rows,
        setRows: setRows,
      }}
    >
      <EventOverviewList dataGridParams={dataGridProps} />
    </OverviewLayout>
  );
};

export default EventOverview;
