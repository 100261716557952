import {
  createClient,
  SupabaseClient,
  SupabaseClientOptions,
  SupportedStorage,
} from '@supabase/supabase-js';
import { Database } from '../../types/supabase.db';

interface CreateSupabaseClientParams {
  accessToken?: null | string;
}

const createSupabaseClient = (
  params: CreateSupabaseClientParams,
): SupabaseClient<Database, 'public'> => {
  const { accessToken } = params;

  if (!process.env.REACT_APP_SUPABASE_URL) {
    throw new Error('Please provide REACT_APP_SUPABASE_URL in .env file');
  }
  if (!process.env.REACT_APP_SUPABASE_KEY) {
    throw new Error('Please provide REACT_APP_SUPABASE_KEY in .env file');
  }

  function inMemoryStorageProvider(): SupportedStorage {
    const items = new Map();
    return {
      getItem: (key: string) => items.get(key),
      setItem: (key: string, value: string) => {
        items.set(key, value);
      },
      removeItem: (key: string) => {
        items.delete(key);
      },
    } as SupportedStorage;
  }

  const clientOptions: SupabaseClientOptions<'public'> = {
    db: {
      schema: 'public',
    },
    auth: { storage: inMemoryStorageProvider() },
    global: accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : undefined,
  };

  const client = createClient<Database, 'public'>(
    process.env.REACT_APP_SUPABASE_URL,
    process.env.REACT_APP_SUPABASE_KEY,
    clientOptions,
  );

  return client;
};

export default createSupabaseClient;
