import { defineMessages } from 'react-intl';

export default defineMessages({
  mfaCode: {
    id: 'BackofficeUser.MfaForm.mfaCode',
    defaultMessage: 'MFA code',
  },
  confirm: {
    id: 'BackofficeUser.MfaForm.confirm',
    defaultMessage: 'Confirm',
  },
  back: {
    id: 'BackofficeUser.MfaForm.back',
    defaultMessage: 'Back',
  },
  phoneNumber: {
    id: 'BackofficeUser.MfaForm.phoneNumber',
    defaultMessage: 'Phone',
  },
  resendCode: {
    id: 'BackofficeUser.MfaForm.resendCode',
    defaultMessage: 'Resend code',
  },
  changeNumber: {
    id: 'BackofficeUser.MfaForm.changeNumber',
    defaultMessage: 'Change number',
  },
});
