import { useMemo } from 'react';
import { ProfitLossReportLevel } from '../../../../types';
import { useIntl } from 'react-intl';
import messages from './messages';
import { GridColumnGroupingModel } from '@mui/x-data-grid-premium';

interface UseGroupingModelParams {
  level: ProfitLossReportLevel;
  headerGroupTitle: string;
}

const useGroupingModel = (
  params: UseGroupingModelParams,
): GridColumnGroupingModel => {
  const { headerGroupTitle } = params;

  const intl = useIntl();

  const defaultColumnGroupingModel: GridColumnGroupingModel = useMemo(() => {
    return [
      {
        groupId: 'general-report-group',
        headerName: intl.formatMessage(messages.general),
        children: [
          { field: 'id' },
          { field: 'name' },
          { field: 'level' },
          { field: 'totalBets' },
          { field: 'tumOver' },
          { field: 'payout' },
        ],
      },
      {
        groupId: 'player-report-group',
        headerName: intl.formatMessage(messages.player),
        children: [
          { field: 'playerWinLoss' },
          { field: 'playerTotal' },
          { field: 'playerCommission' },
        ],
      },
      {
        groupId: 'parent-report-group',
        headerName: headerGroupTitle,
        children: [
          { field: 'parentWinLoss' },
          { field: 'parentTotal' },
          { field: 'parentCommission' },
          { field: 'upLine' },
        ],
      },
    ];
  }, [headerGroupTitle, intl]);

  return defaultColumnGroupingModel;
};

export default useGroupingModel;
