import { defineMessages } from 'react-intl';

export default defineMessages({
  systemOverview: {
    id: 'SecurityOverview.PerformanceOverviewChart.systemOverview',
    defaultMessage: 'System Overview',
  },
  activeThreads: {
    id: 'SecurityOverview.PerformanceOverviewChart.activeThreads',
    defaultMessage: 'Active Threads',
  },
});
