import {
  addDays,
  addMonths,
  addYears,
  endOfDay,
  endOfYear,
  startOfDay,
  startOfYear,
} from 'date-fns';
import { ProfitLossReportPeriod } from '../../../../types';
import { useCallback } from 'react';

export interface FromTo {
  from: string;
  to: string;
}

export type Period = Exclude<ProfitLossReportPeriod, 'custom'>;

export type Convert = (period: Period) => FromTo;

const useConvertPeriodToFromDate = (): Convert => {
  const convert = (period: Period) => {
    const currentDate = new Date();

    const startOfCurrentDate = startOfDay(currentDate);

    const endOfCurrentDate = endOfDay(startOfCurrentDate);

    const fromToByPeriod: Record<Period, FromTo> = {
      today: {
        from: startOfCurrentDate.toISOString(),
        to: endOfCurrentDate.toISOString(),
      },
      yesterday: {
        from: addDays(startOfCurrentDate, -1).toISOString(),
        to: startOfCurrentDate.toISOString(),
      },
      lastWeek: {
        from: addDays(startOfCurrentDate, -7).toISOString(),
        to: startOfCurrentDate.toISOString(),
      },
      lastMonth: {
        from: addMonths(startOfCurrentDate, -1).toISOString(),
        to: startOfCurrentDate.toISOString(),
      },
      lastYear: {
        from: startOfYear(addYears(currentDate, -1)).toISOString(),
        to: endOfYear(addYears(currentDate, -1)).toISOString(),
      },
      currentYear: {
        from: startOfYear(startOfCurrentDate).toISOString(),
        to: startOfCurrentDate.toISOString(),
      },
    };

    const fromTo = fromToByPeriod[period];

    return fromTo;
  };

  return useCallback(convert, []);
};

export default useConvertPeriodToFromDate;
