import { useQuery } from 'react-query';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION, DEFAULT_TRANSACTION_CHUNK_SIZE } from '../constants';
import { ExtendedTransaction } from '../../../types';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface UseUserTransactionsParams {
  userId: string;
  brandId: string;
}

const useUserTransactions = (
  params: UseUserTransactionsParams,
): typeof returnFunction => {
  const { userId, brandId } = params;

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);
  const getTransactionsByUserId = httpsCallable(
    functions,
    'back-transaction-getTransactionsByUserId',
  );

  const returnFunction = useQuery(['userTransactions', userId], async () => {
    let offset = 0;
    const fetchedTransactions: ExtendedTransaction[] = [];
    let responseTransaction: ExtendedTransaction[] = [];

    do {
      const response = await getTransactionsByUserId({
        userId,
        brandId,
        offset,
      });

      responseTransaction = (response.data || []) as ExtendedTransaction[];
      fetchedTransactions.push(...responseTransaction);
      offset += DEFAULT_TRANSACTION_CHUNK_SIZE;
    } while (responseTransaction.length > DEFAULT_TRANSACTION_CHUNK_SIZE - 1);

    return fetchedTransactions;
  });

  return returnFunction;
};

export default useUserTransactions;
