import { ExtendedBackOfficeUser } from '../../../../types';
import normalizeAgent, { NormalizedAgent } from './normalizeAgent';

interface NormalizeAgentsDataParams {
  users?: ExtendedBackOfficeUser[];
  brandId: string | null;
}

const normalizeAgentsData = ({
  users,
  brandId,
}: NormalizeAgentsDataParams): NormalizedAgent[] => {
  if (!users) return [];

  const sortedUsers = users?.map((user) => normalizeAgent({ user, brandId }));

  const filteredUsers = sortedUsers.filter((user) => user) as NormalizedAgent[];

  return filteredUsers;
};

export default normalizeAgentsData;
