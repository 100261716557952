import getMinMaxNumber from './getMinMaxNumber';

interface CreatedAtField {
  created_at?: string | null;
}

interface GetFromToNumbersParams<T> {
  list: T[];
}

const getFromToNumbers = <T extends CreatedAtField>({
  list,
}: GetFromToNumbersParams<T>): typeof fromToNumbers => {
  const fromToNumbers = {
    from: 0,
    to: 0,
  };

  const createdAtMills = list.map(
    (item) => new Date(item?.created_at || 0).getTime() || 0,
  );

  if (!createdAtMills) return fromToNumbers;

  const { min, max } = getMinMaxNumber(createdAtMills);

  fromToNumbers.from = min;
  fromToNumbers.to = max;

  return fromToNumbers;
};

export default getFromToNumbers;
