import { UIContext } from '@miyagami-com/lsx-ui-components';
import { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useFirebaseApp } from 'reactfire';
import { TreeRow } from '../../components/Network/NetworkTreeList/createTreeRow';
import { DEFAULT_AMOUNT, DEFAULT_REGION } from '../constants';
import messages from '../messages';
import { UseQueryResult, useQuery } from 'react-query';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface UseNetworkTreeByBrand {
  brandId: string;
}

const useNetworkTreeByBrand = (
  params: UseNetworkTreeByBrand,
): UseQueryResult<TreeRow[][] | null> => {
  const { setAlert } = useContext(UIContext);

  const intl = useIntl();

  const firebase = useFirebaseApp();

  const functions = getFunctions(firebase, DEFAULT_REGION);

  const getNetworkTree = httpsCallable(
    functions,
    'back-networkTree-getNetworkTreeByBrand',
  );

  const fetchPlayerTree = useCallback(async () => {
    try {
      const { data } = await getNetworkTree({ brandId: params.brandId });

      const trees = data as TreeRow[][];

      const sortedTrees = trees.map((treeData) => {
        const sortedTree = treeData.sort(
          (a, b) =>
            (a?.sortPriority || DEFAULT_AMOUNT) -
            (b?.sortPriority || DEFAULT_AMOUNT),
        );

        return sortedTree;
      });

      return sortedTrees;
    } catch (error) {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.failedUploadNetworkTree),
      });
      return [];
    }
  }, [getNetworkTree, intl, params, setAlert]);

  const queryResult = useQuery(
    ['fullNetworkTreeByBrand', params.brandId],
    () => {
      return fetchPlayerTree();
    },
  );

  return queryResult;
};

export default useNetworkTreeByBrand;
