import React from 'react';
import { Box, Typography } from '@miyagami-com/lsx-ui-components';
import ReactCountryFlag from 'react-country-flag';

interface IpAddressCellProps {
  ipAddress?: string | null;
  countryCode?: string | null;
}

const IpAddressCell: React.FC<IpAddressCellProps> = (props) => {
  const { ipAddress, countryCode } = props;

  return (
    <Box display="flex">
      {countryCode && (
        <Box px={1} display="flex">
          <ReactCountryFlag
            style={{ width: 27, height: 19 }}
            svg
            countryCode={countryCode}
          />{' '}
        </Box>
      )}
      <Box pl={!countryCode ? 1 : 0}>
        <Typography>{ipAddress}</Typography>
      </Box>
    </Box>
  );
};

export default IpAddressCell;
