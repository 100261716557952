import roundToDecimalPlaces from './roundToDecimalPlaces';

const generateAvailableOptions = (
  min: number,
  max: number,
  step: number,
  decimalPlaces: number,
): { value: number; label: string }[] => {
  const options = [];

  for (let i = min; roundToDecimalPlaces(i, decimalPlaces) <= max; i += step) {
    const value = roundToDecimalPlaces(i, decimalPlaces);
    options.push({
      value: value,
      label: `${roundToDecimalPlaces(i * 100, 3)}%`,
    });
  }
  return options;
};

function roundHalf(num: number) {
  return Math.round(num * 2) / 2;
}

export const generateAvailableOptionsHalfs = (
  min: number,
  max: number,
  step: number,
): { value: number; label: string }[] => {
  const options = [];
  for (let i = min; roundToDecimalPlaces(i, 3) <= max; i += step) {
    const value = roundToDecimalPlaces(i, 3);
    options.push({
      value,
      label: `${roundHalf(i * 100)}%`,
    });
  }
  return options;
};

export default generateAvailableOptions;
