import parsePermission, { ParsedPermission } from './parsePermission';

interface ParsePermissionsParams {
  permissions: string[];
}

const parsePermissions = ({
  permissions,
}: ParsePermissionsParams): ParsedPermission[] => {
  const parsedPermissions = permissions.map((permission) =>
    parsePermission({ permission }),
  );

  return parsedPermissions;
};

export default parsePermissions;
