import React from 'react';
import { Grid } from '@miyagami-com/lsx-ui-components';

import { ExtendedBackOfficeUser } from '../../../../types';
import BackofficeUserAccountInformation from '../../BackofficeUser/BackofficeUserAccountInformation';
import LogAccountList from '../../Log/LogAccountList';

interface AgentAccountPageProps {
  user: ExtendedBackOfficeUser;
  brandId: string;
  setLastActivityTime: React.Dispatch<React.SetStateAction<number | null>>;
}

const BrandOwnerAccountPage: React.FC<AgentAccountPageProps> = (props) => {
  const { user, setLastActivityTime } = props;

  return (
    <Grid container pt={4} spacing={4}>
      <Grid item xs={12} md={6}>
        <BackofficeUserAccountInformation user={user} type="brandOwners" />
      </Grid>
      <Grid item xs={12} md={6}>
        <LogAccountList
          setLastActivityTime={setLastActivityTime}
          userId={user.id}
        />
      </Grid>
    </Grid>
  );
};

export default BrandOwnerAccountPage;
