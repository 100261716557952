import Big from 'big.js';

interface UseDesktopColumNumberProps<T> {
  items: Array<T>;
}

const gridColumSize = 3;
const gridDividerSize = 1;

const useDesktopColumNumber = <T>(
  params: UseDesktopColumNumberProps<T>,
): number => {
  const { items } = params;

  const numberOfDividers = new Big(items.length)
    .minus(1)
    .times(gridDividerSize);

  const numberItemColum = new Big(items.length).times(gridColumSize);

  const numberOfColumn = new Big(numberOfDividers)
    .plus(numberItemColum)
    .toNumber();

  return numberOfColumn;
};

export default useDesktopColumNumber;
