import { defineMessages } from 'react-intl';

export default defineMessages({
  system: {
    id: 'PerformanceOverview.PerformanceOverviewPage.system',
    defaultMessage: 'System',
  },
  performance: {
    id: 'PerformanceOverview.PerformanceOverviewPage.performance',
    defaultMessage: 'Performance',
  },
});
