import { defineMessages } from 'react-intl';

export default defineMessages({
  bettingOverview: {
    id: 'ExchangeBet.ExchangeBetDetailPage.bettingOverview',
    defaultMessage: 'Betting overview',
  },
  exchange: {
    id: 'ExchangeBet.ExchangeBetDetailPage.exchange',
    defaultMessage: 'Exchange',
  },
});
