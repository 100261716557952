import { ExtendedBackOfficeUser, GlobalUsers } from '../../types';

interface GetAllUsersParams {
  brandId: string;
  users: GlobalUsers;
}

const getAllUsers = ({
  users,
  brandId,
}: GetAllUsersParams): ExtendedBackOfficeUser[] => {
  const brandUsers = users.byBrandId[brandId];

  const agents = brandUsers?.agents || [];

  const backofficeUsers = brandUsers?.backofficeUsers || [];

  const allUsers = [
    ...agents,
    ...backofficeUsers,
    ...users.brandOwners,
    ...users.systemAdmins,
  ];

  return allUsers;
};

export default getAllUsers;
