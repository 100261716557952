import React, { useContext, useMemo, useState } from 'react';
import { CollapseBox, Grid, UIContext } from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { FormikHelpers } from 'formik';
import { useFirebaseApp } from 'reactfire';

import { RootState, UserRole } from '../../../../types';

import { DEFAULT_REGION } from '../../../common/constants';
import getRoleData from '../../../common/getRoleData';

import DefaultLayout from '../../Unknown/DefaultLayout';
import OverviewLayout from '../../Unknown/OverviewLayout';

import UserManualRegistrationForm from './UserManualRegistrationForm';
import messages from './messages';
import { useParams } from 'react-router';
import useUserRoleLabel from '../../../common/hooks/useUserRoleLabel';
import useSelectableRoles, { SelectRole } from './useSelectableRoles';
import getAllUsers from '../../../common/getAllUsers';
import { CreateUserParams } from '../../BackofficeUser/BackofficeUserAddition';
import createParentPath from './createParentPath';
import { useQueryClient } from 'react-query';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface NetworkConfig {
  positionTakingRate?: number;
  commissionRate: number;
}

type CreatedUser = {
  parentId: string;
  parentPath: string;
};

type Body = Omit<CreateUserParams, 'mfa' | 'isEnableFirewall' | 'status'> &
  NetworkConfig &
  CreatedUser;

export interface ManualRegistrationFormFields extends Body {
  roleId: UserRole;
  isActivate: boolean;
  password?: string;
  brandId: string;
  parentPositionTakingRate: number;
  parentCommissionRate: number;
  minPositionTakingRate: number;
  maxPositionTakingRate: number;
  extraPositionTakingRate: number;
  isExtraPositionTakingEnabled: boolean;
}

const roleTree = {
  support: 'superAgent',
  systemAdmin: 'superAgent',
  owner: 'superAgent',
  superAgent: 'masterAgent',
  masterAgent: 'agent',
  agent: 'player',
};

export type FieldKey = keyof ManualRegistrationFormFields;

type QueryParams = {
  brandId: string;
};

const defaultNetworkConfig = {
  positionTakingRate: undefined,
  commissionRate: 0,
};

const UserManualRegistration: React.FC = () => {
  const { brandId }: QueryParams = useParams();

  const userRoleLabel = useUserRoleLabel();

  const selectableRoles = useSelectableRoles();

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const { users, brands } = useSelector((root: RootState) => root.globalData);
  const { uid } = useSelector((root: RootState) => root.user);

  const queryClient = useQueryClient();

  const intl = useIntl();
  const { setAlert } = useContext(UIContext);

  const allUsers = useMemo(
    () => getAllUsers({ users, brandId }),
    [brandId, users],
  );

  const [totals, setTotals] = useState({
    minPositionTakingRate: 0,
    availablePT: 0,
    availableCommission: 0,
    maxPositionTakingRate: 0,
    extraPositionTakingRate: 0,
  });

  const parentUser = useMemo(() => {
    const user = allUsers.find(({ id }) => id === uid);

    if (!user) return null;

    return user;
  }, [allUsers, uid]);

  const parentUserRoleData = useMemo(
    () =>
      getRoleData({
        roles: parentUser?.roles,
        brandId,
      }),
    [brandId, parentUser?.roles],
  );

  const initialValues = useMemo(() => {
    const parentId = parentUser?.id;

    const parentRole = (parentUserRoleData?.value ||
      'player') as keyof typeof roleTree;

    const availableRole = (roleTree[parentRole] as UserRole) || 'superAgent';

    const initialParentId = parentId || uid;

    const initialParentPath = createParentPath({
      parentUser,
      parentId: initialParentId,
    });

    const defaultInitialValues: ManualRegistrationFormFields = {
      name: '',
      surname: '',
      email: '',
      phoneNumber: '',
      country: 'Country',
      city: 'City',
      street: 'Street',
      zipCode: 'Zipcode',
      parentId: parentId || uid,
      ...defaultNetworkConfig,
      roleId: availableRole,
      brandId,
      isActivate: false,
      parentPath: initialParentPath,
      parentCommissionRate: 0,
      parentPositionTakingRate: 0,
      positionTakingRate: 0,
      minPositionTakingRate: 0,
      maxPositionTakingRate: 0,
      extraPositionTakingRate: 0,
      isExtraPositionTakingEnabled: false,
    };

    if (parentUserRoleData?.value === 'agent') {
      return {
        ...defaultInitialValues,
        password: undefined,
      };
    }

    return defaultInitialValues;
  }, [brandId, parentUser, parentUserRoleData?.value, uid]);

  const brand = useMemo(() => {
    return brands.find(({ id }) => id === brandId);
  }, [brandId, brands]);

  const onSubmit = async (
    {
      roleId,
      isActivate,
      password,
      parentId,
      positionTakingRate,
      commissionRate,
      parentCommissionRate,
      minPositionTakingRate,
      maxPositionTakingRate,
      extraPositionTakingRate,
      isExtraPositionTakingEnabled,
      parentPositionTakingRate,
      ...otherValues
    }: ManualRegistrationFormFields,
    {
      resetForm,
      setSubmitting,
      setFieldValue,
    }: FormikHelpers<ManualRegistrationFormFields>,
  ) => {
    setSubmitting(true);

    const selectableRole = roleId as SelectRole;

    const { parentRoleId } = selectableRoles[selectableRole];

    const roleLabel = userRoleLabel[parentRoleId];

    const { availableCommission, availablePT } = totals;

    if (!parentId) {
      setAlert({
        show: true,
        severity: 'info',
        message: intl.formatMessage(messages.infoParentId, { role: roleLabel }),
      });

      return;
    }

    const selectedParentUser = allUsers.find((user) => user.id === parentId);

    const parentPath = createParentPath({
      parentUser: selectedParentUser,
      parentId,
    });

    try {
      if (positionTakingRate === undefined) throw Error('PT is undefined.');

      const ptRate = positionTakingRate as number;

      if (ptRate > availablePT)
        throw Error('PT is greater than the available PT');

      if (commissionRate > availableCommission)
        throw Error('Commission is greater than the available commission.');

      if (roleId === 'player') {
        const createPlayer = httpsCallable(
          functions,
          'back-player-createPlayer',
        );

        await createPlayer({
          ...otherValues,
          activateAccount: isActivate,
          parentId,
          parentPath,
          password,
          brandId,
          positionTakingRate,
          commissionRate,
        });
      } else {
        const createAgent = httpsCallable(functions, 'back-user-createAgent');

        await createAgent({
          ...otherValues,
          parentId,
          parentPath,
          positionTakingRate,
          commissionRate,
          roleId,
          minPositionTakingRate,
          maxPositionTakingRate,
          extraPositionTakingRate,
          isExtraPositionTakingEnabled,
          parentPositionTakingRate,
          parentCommissionRate,
        });
      }

      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.successCreatingUser),
      });

      setFieldValue('password', '');

      setFieldValue('positionTakingRate', undefined);

      resetForm();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setAlert({
        show: true,
        severity: 'error',
        message:
          error?.message || intl.formatMessage(messages.errorCreatingUser),
      });
      return;
    } finally {
      setSubmitting(false);
      queryClient.refetchQueries(['backofficeUsers', brandId]);
      queryClient.refetchQueries(['unsettledBrandBackofficeUsers']);
    }
  };

  const path = [
    { label: intl.formatMessage(messages.playersAndAgents) },
    { label: intl.formatMessage(messages.manualRegistration) },
  ];

  return (
    <DefaultLayout path={path}>
      <OverviewLayout withHeader={false}>
        <CollapseBox
          label={intl.formatMessage(messages.manualRegistration)}
          defaultValue
          isSquare
        >
          <Grid container>
            <Grid item xs={12}>
              <UserManualRegistrationForm
                brand={brand}
                parentUser={parentUser}
                initialValues={initialValues}
                withPassword={Boolean(parentUserRoleData?.value === 'agent')}
                onSubmit={onSubmit}
                setTotals={setTotals}
                totals={totals}
              />
            </Grid>
          </Grid>
        </CollapseBox>
      </OverviewLayout>
    </DefaultLayout>
  );
};

export default UserManualRegistration;
