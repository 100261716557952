import { User } from 'firebase/auth';
import { createContext, useState } from 'react';
import { useAuth } from 'reactfire';
export interface AuthContextProps {
  firebaseUser: User | null;
}

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps,
);

export const AuthContextProvider: React.FC = (props) => {
  const { children } = props;

  const firebaseAuth = useAuth();

  const [firebaseUser, setFirebaseUser] = useState<null | User>(null);

  firebaseAuth.onAuthStateChanged((user) => {
    setFirebaseUser(user);
  });

  return (
    <AuthContext.Provider value={{ firebaseUser }}>
      {children}
    </AuthContext.Provider>
  );
};
