import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import messages from '../../../common/messages';

export type SelectRole = 'player' | 'agent' | 'masterAgent' | 'superAgent';

export type SelectableRole = {
  [role in SelectRole]: {
    label: string;
    parentRoleId: SelectRole | 'owner';
  };
};

const useSelectableRoles = (): SelectableRole => {
  const intl = useIntl();

  const selectableRoles = useMemo(() => {
    const roleObject: SelectableRole = {
      player: {
        label: intl.formatMessage(messages.playerLabel),
        parentRoleId: 'agent',
      },
      agent: {
        label: intl.formatMessage(messages.agentLabel),
        parentRoleId: 'masterAgent',
      },
      masterAgent: {
        label: intl.formatMessage(messages.masterAgentLabel),
        parentRoleId: 'superAgent',
      },
      superAgent: {
        label: intl.formatMessage(messages.superAgentLabel),
        parentRoleId: 'owner',
      },
    };

    return roleObject;
  }, [intl]);

  return selectableRoles;
};

export default useSelectableRoles;
