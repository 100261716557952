import { useStorage } from 'reactfire';
import {
  UploadMetadata,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';

const useUploadFile = (): typeof uploadFile => {
  const storage = useStorage();

  const uploadFile = async (
    path: string,
    file: Blob | Uint8Array | ArrayBuffer,
    metadata?: UploadMetadata,
  ) => {
    const storageRef = ref(storage, path);

    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    await new Promise<void>((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        null,
        (error) => {
          reject(error);
        },
        () => {
          resolve();
        },
      );
    });

    const downloadURL = await getDownloadURL(storageRef);

    return downloadURL;
  };

  return uploadFile;
};

export default useUploadFile;
