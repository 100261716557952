import { FC, useState, useMemo } from 'react';
import { Box } from '@miyagami-com/lsx-ui-components';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';

import { BackOfficeUser, RootState } from '../../../../types';
import { DEFAULT_DATE_FORMAT } from '../../../common/constants';
import DefaultLayout from '../../Unknown/DefaultLayout';
import OverviewLayout from '../../Unknown/OverviewLayout';
import BackofficeUserAddition from '../../BackofficeUser/BackofficeUserAddition';
import BackofficeUserList from '../../BackofficeUser/BackofficeUserList';
import getFromToNumbers from '../../../common/getFromToNumbers';

import messages from './messages';
import sortedUserList from './sortedUserList';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';

interface UserListByRolePageProps {
  type: 'brandOwners' | 'systemAdmins';
}

const UserListByRolePage: FC<UserListByRolePageProps> = ({ type }) => {
  const intl = useIntl();

  const { brands } = useSelector((state: RootState) => state.globalData);

  const currentUsers = useSelector(
    (state: RootState) => state.globalData.users[type],
  );

  const { roles } = useSelector((state: RootState) => state.user);

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [usersRows, setUsersRows] = useState<BackOfficeUser[]>([]);

  const defaultBrandOwners = useMemo(() => {
    const userList = sortedUserList(brands, currentUsers);

    setUsersRows(userList);
    return userList;
  }, [currentUsers, brands]);

  const { from, to } = useMemo(
    () => getFromToNumbers({ list: usersRows }),
    [usersRows],
  );

  const isSystemAdmin = checkIsSystemAdmin({ roles });

  const onClickAddButton = isSystemAdmin
    ? () => setIsOpenModal(true)
    : undefined;

  const onCloseModal = () => {
    setIsOpenModal(false);
  };

  const path = [
    { label: intl.formatMessage(messages.system) },
    { label: intl.formatMessage(messages[type]) },
  ];

  const mapDialogTitleByType = {
    brandOwners: intl.formatMessage(messages.addBrandOwnerModalTitle),
    systemAdmins: intl.formatMessage(messages.addSystemAdminModalTitle),
  };

  return (
    <DefaultLayout path={path}>
      <OverviewLayout
        headerProps={{
          name: intl.formatMessage(messages[type]),
          from: format(from, DEFAULT_DATE_FORMAT),
          to: format(to, DEFAULT_DATE_FORMAT),
          setRows: setUsersRows,
          defaultRows: defaultBrandOwners,
          onClickAdd: onClickAddButton,
          tooltipAdd: intl.formatMessage(messages.addUser),
        }}
      >
        <BackofficeUserList rows={usersRows} type={type} />
      </OverviewLayout>
      <Box pt={3} />
      <BackofficeUserAddition
        isOpen={isOpenModal}
        brands={brands}
        onClose={onCloseModal}
        dialogTitle={mapDialogTitleByType[type]}
        type={type}
      />
    </DefaultLayout>
  );
};

export default UserListByRolePage;
