import { GridEventListener, GridEvents } from '@mui/x-data-grid-premium';
import getCellEditCommitUpdatedRow, {
  GetCellEditCommitUpdatedRowParams,
} from '../../../common/getCellEditCommitUpdatedRow';
import {
  ExchangeBetCurrencyParams,
  ExchangeBetCurrencyParamsType,
} from '../../../../types';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../../../common/constants';
import { UIContext } from '@miyagami-com/lsx-ui-components';
import { useCallback, useContext } from 'react';
import messages from './messages';
import { useIntl } from 'react-intl';
import { UseQueryResult } from 'react-query';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface UseOnCellEditCommitParams {
  brandId?: string;
  playerId?: string;
  currencyQuery: UseQueryResult<ExchangeBetCurrencyParams[], unknown>;
}

const typeByParams = (
  params: UseOnCellEditCommitParams,
): ExchangeBetCurrencyParamsType => {
  const { brandId, playerId } = params;

  if (playerId) return 'player';

  if (brandId) return 'brand';

  return 'system';
};

interface FunctionParams {
  brandId?: string;
  payload: ExchangeBetCurrencyParams;
}

const useOnCellEditCommit = (
  props: UseOnCellEditCommitParams,
): GridEventListener<GridEvents.cellEditCommit> => {
  const { brandId, playerId, currencyQuery } = props;

  const firebase = useFirebaseApp();

  const { setAlert } = useContext(UIContext);

  const intl = useIntl();

  const functions = getFunctions(firebase, DEFAULT_REGION);

  const onCellEditCommit: GridEventListener<GridEvents.cellEditCommit> =
    useCallback(
      async (params) => {
        try {
          const editCommitParams = params as GetCellEditCommitUpdatedRowParams;

          const updatedRow = getCellEditCommitUpdatedRow(
            editCommitParams,
          ) as ExchangeBetCurrencyParams;

          const type = typeByParams(props);

          const row: ExchangeBetCurrencyParams = {
            ...updatedRow,
            brand_id: updatedRow.brand_id || brandId || null,
            player_id: updatedRow.player_id || playerId || null,
            type: type,
          };

          const functionParams: FunctionParams = {
            payload: row,
            brandId,
          };

          const firebaseFunction = httpsCallable(
            functions,
            'back-currency-updateCurrencyParams',
          );

          await firebaseFunction(functionParams);

          setAlert({
            show: true,
            severity: 'success',
            message: intl.formatMessage(messages.successUpdate),
          });
        } catch (error) {
          setAlert({
            show: true,
            severity: 'error',
            message: intl.formatMessage(messages.errorUpdate),
          });
        } finally {
          currencyQuery.refetch();
        }
      },
      [brandId, currencyQuery, functions, intl, playerId, props, setAlert],
    );

  return onCellEditCommit;
};

export default useOnCellEditCommit;
