import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { BackOfficeUser } from '../../../../types';
import useBrandId from '../../../common/hooks/useBrandId';
import { DialogType } from '../../Unknown/BackofficeUserForm';
import { SubTagPath } from '../../Unknown/SubTags';
import messages from './messages';

interface UsePathParams {
  type: DialogType;
  user?: BackOfficeUser | null;
}

const usePath = (params: UsePathParams): SubTagPath[] => {
  const { type, user } = params;

  const brandId = useBrandId();

  const intl = useIntl();

  const breadcrumbsLinks = useMemo(
    () => ({
      systemAdmins: '/system-admins',
      brandOwners: `/brand-owners`,
      backofficeUsers: `/b/${brandId}/backoffice-users`,
    }),
    [brandId],
  );

  const breadcrumbsTitle = useMemo(
    () => ({
      systemAdmins: intl.formatMessage(messages.systemAdmins),
      brandOwners: intl.formatMessage(messages.brandOwners),
      backofficeUsers: intl.formatMessage(messages.backofficeUser),
    }),
    [intl],
  );

  const path = useMemo(() => {
    const subTagPaths: SubTagPath[] = [
      { label: intl.formatMessage(messages.system) },
      {
        label: breadcrumbsTitle[type],
        url: breadcrumbsLinks[type],
      },
      { label: user?.username || '' },
    ];

    return subTagPaths;
  }, [breadcrumbsLinks, breadcrumbsTitle, intl, type, user?.username]);

  return path;
};

export default usePath;
