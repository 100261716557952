import { Theme, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { borderWidth, colors } from '../../../common/theme';

const borderStyle = `${borderWidth.s}px solid ${colors.actionFocus}`;

export default makeStyles((theme: Theme) => ({
  root: {
    color: alpha(theme.palette.common.black, 0.87),
  },
  blueText: {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
    textDecorationColor: theme.palette.primary.dark,
  },
  main: {
    borderLeft: borderStyle,
    borderTop: borderStyle,
    borderRight: borderStyle,
  },
  columnHeaders: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  columnHeader: {
    backgroundColor: theme.palette.grey[100],
    borderRight: borderStyle,
    borderBottom: borderStyle,
  },
  columnHeaderTitleContainer: {
    justifyContent: 'center',
  },
  aggregationColumnHeaderLabel: {
    display: 'none',
  },
  cell: {
    borderRight: borderStyle,
    borderBottom: borderStyle,
  },
  cellError: {
    color: theme.palette.error.main,
  },
  cellErrorWithUnderline: {
    color: theme.palette.error.main,
    textDecoration: 'underline',
    textDecorationColor: theme.palette.error.main,
  },
  footerCell: {
    textDecoration: 'underline',
    textDecorationColor: `${theme.palette.common.black} !important`,
  },
  pinnedRows: {
    boxShadow: 'none',
    backgroundColor: theme.palette.grey[300],
    borderTop: borderStyle,
    '& .MuiDataGrid-cell': {
      borderRight: borderStyle,
    },
  },
}));
