import { FormikHelpers } from 'formik';
import { useCallback, useContext } from 'react';
import messages from './messages';
import { FormValues, ListItem } from './types';
import useShareApiKey from '../../../common/hooks/useShareApiKey';
import { UIContext } from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';

interface UseOnSubmitParams {
  listItems: ListItem[];
  apiKeyId: string | null;
}

type OnSubmit = (
  formValues: FormValues,
  formikHelpers: FormikHelpers<FormValues>,
) => Promise<null | undefined>;

const useOnSubmit = (params: UseOnSubmitParams): OnSubmit => {
  const { listItems, apiKeyId } = params;

  const shareApiKey = useShareApiKey();

  const { setAlert } = useContext(UIContext);

  const intl = useIntl();

  const onSubmit = useCallback(
    async (
      formValues: FormValues,
      formikHelpers: FormikHelpers<FormValues>,
    ) => {
      const { setSubmitting } = formikHelpers;

      try {
        setSubmitting(true);

        const addedBrandIds: string[] = [];
        const removedBrandIds: string[] = [];

        formValues.listItems.forEach((item, index) => {
          if (item.isShared && !listItems[index].isShared) {
            addedBrandIds.push(item.id);
          }

          if (!item.isShared && listItems[index].isShared) {
            removedBrandIds.push(item.id);
          }
        });

        if (!apiKeyId) return null;

        await shareApiKey({ apiKeyId, removedBrandIds, addedBrandIds });
        setAlert({
          show: true,
          severity: 'success',
          message: intl.formatMessage(messages.success),
        });
      } catch (error) {
        setAlert({
          show: true,
          severity: 'error',
          message: intl.formatMessage(messages.error),
        });
      } finally {
        setSubmitting(false);
      }
    },
    [apiKeyId, intl, listItems, setAlert, shareApiKey],
  );

  return onSubmit;
};

export default useOnSubmit;
