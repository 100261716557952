import { defineMessages } from 'react-intl';

export default defineMessages({
  id: {
    id: 'Currency.CurrencyTable.id',
    defaultMessage: 'ID',
  },
  currencyCode: {
    id: 'Currency.CurrencyTable.currencyCode',
    defaultMessage: 'Currency code',
  },
  minBetSize: {
    id: 'Currency.CurrencyTable.minBetSize',
    defaultMessage: 'Min bet size',
  },
  successUpdate: {
    id: 'Currency.CurrencyTable.successUpdate',
    defaultMessage: 'Currency settings successfully updated.',
  },
  errorUpdate: {
    id: 'Currency.CurrencyTable.errorUpdate',
    defaultMessage: 'Currency settings have not been successfully updated.',
  },
});
