import { PostgrestResponse } from '@supabase/postgrest-js';
import { Language } from '../../../../types/supabase';
import createSupabaseClient from '../../../common/createSupabaseClient';

const getLanguages = async (): Promise<PostgrestResponse<Language>> => {
  const supabaseClient = createSupabaseClient({});

  const ref = supabaseClient.from('languages');

  const response = await ref.select('*', { count: 'exact' }).throwOnError();

  return response;
};

export default getLanguages;
