import { BackofficeUserRole } from '../../types/supabase';

interface CheckIsSystemAdminParams {
  roles?: BackofficeUserRole[] | null;
}

const checkIsSystemAdmin = (params: CheckIsSystemAdminParams): boolean => {
  const { roles } = params;
  if (!roles) return false;

  const isOwner = roles.some(({ role_id }) => role_id === 'systemAdmin');

  return isOwner;
};

export default checkIsSystemAdmin;
