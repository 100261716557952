import { FC, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useFirebaseApp } from 'reactfire';
import { Box, UIContext, ModalCenter } from '@miyagami-com/lsx-ui-components';
import { DEFAULT_REGION } from '../../../common/constants';
import ClosableBox from '../../Unknown/ClosableBox';
import LanguageAdditionForm, {
  LanguageAdditionFormValues,
} from '../LanguageAdditionForm';
import languageOptionsData from './languageOptions';
import messages from './messages';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface LanguageAdditionDialogProps {
  open: boolean;
  languageIds: string[];
  onAddLanguage: () => void;
  onClose: () => void;
}

const LanguageAdditionDialog: FC<LanguageAdditionDialogProps> = ({
  open,
  languageIds,
  onAddLanguage,
  onClose,
}) => {
  const intl = useIntl();
  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);
  const { setAlert } = useContext(UIContext);
  const addLanguage = httpsCallable(functions, 'back-language-addLanguage');

  const onAddLanguageSubmit = async ({
    id,
    name,
    countryCode,
  }: LanguageAdditionFormValues) => {
    try {
      await addLanguage({ id, name, countryCode });
      setAlert({
        message: intl.formatMessage(messages.languageAddedSuccessfully),
        severity: 'success',
        show: true,
      });
      onAddLanguage();
      onClose();
    } catch (error) {
      setAlert({
        message: intl.formatMessage(messages.failToAddLanguage),
        severity: 'error',
        show: true,
      });
    }
  };

  const languageOptions = useMemo(() => {
    return languageOptionsData.filter(({ id }) => !languageIds.includes(id));
  }, [languageIds]);

  return (
    <ModalCenter open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: 380, sm: 520 },
          p: 4,
        }}
      >
        <ClosableBox
          label={intl.formatMessage(messages.addLanguage)}
          onClose={onClose}
        >
          <LanguageAdditionForm
            languageOptions={languageOptions}
            onSubmit={onAddLanguageSubmit}
          />
        </ClosableBox>
      </Box>
    </ModalCenter>
  );
};

export default LanguageAdditionDialog;
