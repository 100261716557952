import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  buttonEditPermissions: {
    fontSize: 12,
    marginLeft: theme.spacing(-1),
    marginBottom: theme.spacing(-1),
  },
}));

export default useStyles;
