import { UIContext } from '@miyagami-com/lsx-ui-components';
import { MultiFactorResolver } from 'firebase/auth';
import { FormikHelpers } from 'formik';
import { Dispatch, SetStateAction, useCallback, useContext } from 'react';
import getErrorMessage from '../../../common/getErrorMessage';
import useAuth from '../../Unknown/AuthContext/useAuth';
import { LoginFormValues } from './LoginForm';
import useTotpAuthContext from '../Totp/useTotpAuthContext';
import useAuthenticationContext from '../../../common/hooks/useAuthenticationContext';
import handleIfErrorCodeEqualsMfaRequired from '../../../common/generalFunctions/handleIfErrorCodeEqualsMfaRequired';

interface UseSignInParams {
  recaptcha: HTMLDivElement | null;
  resolverState: [
    MultiFactorResolver | null,
    Dispatch<SetStateAction<MultiFactorResolver | null>>,
  ];
  verificationIdState: [string | null, Dispatch<SetStateAction<string | null>>];
  stepState: [number, Dispatch<SetStateAction<number>>];
}

const useSignIn = (params: UseSignInParams): typeof onSignIn => {
  const { recaptcha, resolverState, verificationIdState, stepState } = params;

  const { setIsWithMfa } = useAuthenticationContext();

  const [, setResolver] = resolverState;

  const [, setVerificationId] = verificationIdState;

  const [, setStep] = stepState;

  const { resolveMFA } = useTotpAuthContext();

  const { signIn } = useAuth();

  const { setAlert } = useContext(UIContext);

  const onSignIn = useCallback(
    async (
      values: LoginFormValues,
      { setSubmitting }: FormikHelpers<LoginFormValues>,
    ) => {
      setSubmitting(true);

      try {
        const signInParams = {
          email: values.email,
          password: values.password,
        };

        await signIn(signInParams);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.code === 'auth/multi-factor-auth-required' && recaptcha) {
          const currentResolver = await resolveMFA(error);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any

          if (currentResolver.factorId === 'phone') {
            handleIfErrorCodeEqualsMfaRequired({
              setWithMFA: setIsWithMfa,
              setResolver,
              resolver: currentResolver?.mfaResolver,
              recaptcha,
              setVerificationId,
              setStep,
              setSubmitting,
            });
          }
        } else {
          const message = getErrorMessage(error);
          setAlert({
            show: true,
            severity: 'warning',
            message,
          });
        }
      }
      setSubmitting(false);
    },
    [
      recaptcha,
      resolveMFA,
      setAlert,
      setIsWithMfa,
      setResolver,
      setStep,
      setVerificationId,
      signIn,
    ],
  );

  return onSignIn;
};

export default useSignIn;
