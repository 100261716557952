import { FC, useState, useContext } from 'react';
import { CollapseBox, UIContext } from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';
import { useFirebaseApp } from 'reactfire';

import { DEFAULT_REGION } from '../../../common/constants';

import UserPersonalInformationForm, {
  FunctionUserParams,
} from '../../User/UserPersonalInformationForm';

import messages from './messages';
import { ExtendedPlayer } from '../../../../types';
import { useQueryClient } from 'react-query';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FirebaseError } from 'firebase/app';

type Action = {
  nameFunction: string;
  successMessage: 'successMessageForUpdating' | 'successMessageForCreating';
  errorMessage: 'errorMessageForUpdating' | 'errorMessageForCreating';
};

type ActionData = { update: Action; create: Action };

interface PlayerPersonalInformationProps {
  player?: ExtendedPlayer;
  brandId?: string | null;
  type: 'create' | 'update';
  isDisabled: boolean;
}

const PlayerPersonalInformation: FC<PlayerPersonalInformationProps> = ({
  player,
  brandId,
  type,
  isDisabled,
}) => {
  const intl = useIntl();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { setAlert } = useContext(UIContext);

  const queryClient = useQueryClient();

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);
  const actionData: ActionData = {
    update: {
      nameFunction: 'back-player-updatePlayer',
      successMessage: 'successMessageForUpdating',
      errorMessage: 'errorMessageForUpdating',
    },
    create: {
      nameFunction: 'back-player-createPlayer',
      successMessage: 'successMessageForCreating',
      errorMessage: 'errorMessageForCreating',
    },
  };

  const onSubmit = async ({ phoneNumber, ...values }: FunctionUserParams) => {
    setIsButtonLoading(true);
    try {
      const currentFunction = httpsCallable(
        functions,
        actionData[type].nameFunction,
      );

      const data = type === 'update' ? { ...values, uid: player?.id } : values;
      const responseForPlayer = await currentFunction({
        ...data,
        phoneNumber: !!phoneNumber ? phoneNumber : null,
      });

      if (responseForPlayer) {
        setAlert({
          show: true,
          severity: 'success',
          message: intl.formatMessage(
            messages[actionData[type].successMessage],
          ),
        });
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        setAlert({
          show: true,
          severity: 'error',
          message: error.message,
        });

        return;
      }

      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages[actionData[type].errorMessage]),
      });
    } finally {
      setIsButtonLoading(false);
      queryClient.fetchQuery(['player', player?.id]);
    }
  };

  const initialValues: FunctionUserParams = {
    name: player?.name || '',
    surname: player?.surname || '',
    email: player?.email || '',
    phoneNumber: player?.phone_number || '',
    country: player?.playerDetail?.country || '',
    city: player?.playerDetail?.city || '',
    street: player?.playerDetail?.street || '',
    zipCode: player?.playerDetail?.zip_code || '',
    brandId: brandId || '',
    isEnableTotp: player?.is_enable_totp || false,
    authUsername: player?.auth_username || null,
  };

  return (
    <CollapseBox
      label={intl.formatMessage(messages.personalInformation)}
      defaultValue
    >
      <UserPersonalInformationForm
        isDisabled={isDisabled}
        onSubmit={onSubmit}
        isButtonLoading={isButtonLoading}
        initialValues={initialValues}
        isEdit
      />
    </CollapseBox>
  );
};

export default PlayerPersonalInformation;
