import React, { useContext, useEffect } from 'react';
import { UIContext } from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../../types';
import { updateUsers } from '../../../store/globalData';
import useBrandId from '../../../common/hooks/useBrandId';
import { createPermissionMap } from '../../../store/user';

import messages from './messages';
import useBackofficeUsers from '../../../common/hooks/useBackofficeUsers';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';

const UserWrapper: React.FC = ({ children }) => {
  const brandId = useBrandId();

  const intl = useIntl();

  const dispatch = useDispatch();
  const { brands } = useSelector((root: RootState) => root.globalData);
  const { roles } = useSelector((root: RootState) => root.user);

  const isSystemAdmin = checkIsSystemAdmin({ roles });
  const { uid: backOfficeUserId } = useSelector((root: RootState) => root.user);

  const { setAlert } = useContext(UIContext);

  const { data: backofficeUsers, status } = useBackofficeUsers({
    brandId,
    backOfficeUserId,
    isSystemAdmin,
  });

  useEffect(() => {
    if (status === 'error') {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.errorUploadingUsers),
      });
    }

    if (status !== 'success' || !backofficeUsers?.length) return;

    dispatch(
      updateUsers({
        users: backofficeUsers,
        brandId,
      }),
    );
  }, [backofficeUsers, brandId, dispatch, intl, setAlert, status]);

  useEffect(() => {
    const isAdmin = checkIsSystemAdmin({ roles });
    const brandIds =
      !isAdmin && brands.length ? brands.map((brand) => brand.id) : [brandId];

    dispatch(createPermissionMap({ brandIds }));
  }, [brandId, roles, brands, dispatch]);

  return <>{children}</>;
};

export default UserWrapper;
