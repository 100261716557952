import { defineMessages } from 'react-intl';

export default defineMessages({
  language: {
    id: 'Language.LanguageList.language',
    defaultMessage: 'Language',
  },
  id: {
    id: 'Language.LanguageList.id',
    defaultMessage: 'ID',
  },
  flag: {
    id: 'Language.LanguageList.flag',
    defaultMessage: 'Flag',
  },
  status: {
    id: 'Language.LanguageList.status',
    defaultMessage: 'Status',
  },
  actions: {
    id: 'Language.LanguageList.actions',
    defaultMessage: 'Actions',
  },
  active: {
    id: 'Language.LanguageList.active',
    defaultMessage: 'Active',
  },
  inactive: {
    id: 'Language.LanguageList.inactive',
    defaultMessage: 'Inactive',
  },
  view: {
    id: 'Language.LanguageList.view',
    defaultMessage: 'View',
  },
  activate: {
    id: 'Language.LanguageList.activate',
    defaultMessage: 'Activate',
  },
  deactivate: {
    id: 'Language.LanguageList.deactivate',
    defaultMessage: 'Deactivate',
  },
  delete: {
    id: 'Language.LanguageList.delete',
    defaultMessage: 'Delete',
  },
  successDelete: {
    id: 'Language.LanguageList.successDelete',
    defaultMessage: 'The language was successfully deleted',
  },
  errorDelete: {
    id: 'Language.LanguageList.errorDelete',
    defaultMessage: 'Error while deleting a language',
  },
});
