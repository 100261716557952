import { defineMessages } from 'react-intl';

export default defineMessages({
  permissions: {
    id: 'UserPermission.UserPermissionEdit.permissions',
    defaultMessage: 'Permissions',
  },
  permissionTemplate: {
    id: 'UserPermission.UserPermissionEdit.permissionTemplate',
    defaultMessage: 'Permission template',
  },
  role: {
    id: 'UserPermission.UserPermissionEdit.role',
    defaultMessage: 'Role',
  },
  save: {
    id: 'UserPermission.UserPermissionEdit.save',
    defaultMessage: 'Save',
  },
  messageSuccess: {
    id: 'UserPermission.UserPermissionEdit.messageSuccess',
    defaultMessage: 'The permissions were successfully updated!',
  },
  messageError: {
    id: 'UserPermission.UserPermissionEdit.messageError',
    defaultMessage: 'An error occurred while updating permissions!',
  },
  noBrand: {
    id: 'UserPermission.UserPermissionEdit.noBrand',
    defaultMessage: 'No brand selected for this user!',
  },
});
