import React from 'react';
import { GridRenderCellParams } from '@miyagami-com/lsx-ui-components';
import { RISK_LEVELS } from '../../../common/constants';

const renderRiskLevelCell = (
  params: GridRenderCellParams<keyof typeof RISK_LEVELS>,
): React.ReactNode => {
  const { value } = params;

  if (!value) return null;

  const riskLevel = RISK_LEVELS[value];

  return riskLevel.label;
};

export default renderRiskLevelCell;
