import { useIntl } from 'react-intl';
import messages from './messages';

const usePagePath = (): typeof path => {
  const intl = useIntl();

  const path = [
    { label: intl.formatMessage(messages.event) },
    { label: intl.formatMessage(messages.eventOverview) },
  ];

  return path;
};

export default usePagePath;
