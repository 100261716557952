import { defineMessages } from 'react-intl';

export default defineMessages({
  general: {
    id: 'Network.NetworkInformation.general',
    defaultMessage: 'General',
  },
  name: {
    id: 'Network.NetworkInformation.name',
    defaultMessage: 'Name',
  },
  role: {
    id: 'Network.NetworkInformation.role',
    defaultMessage: 'Role',
  },
  collapseBoxLabel: {
    id: 'Network.NetworkInformation.collapseBoxLabel',
    defaultMessage: 'Network information',
  },
  networkConfigSuccessChanged: {
    id: 'common.networkConfigSuccessChanged',
    defaultMessage: 'User network config has been successfully changed',
  },
  saveButton: {
    id: 'Network.NetworkInformation.saveButton',
    defaultMessage: 'Save',
  },
});
