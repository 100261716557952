import { useCallback, useMemo } from 'react';
import { GridRenderCellParams, GridColumns } from '@mui/x-data-grid';
import { sentenceCase } from 'change-case';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import useCheckBrandIsSuspend from '../../../common/hooks/useCheckBrandIsSuspend';
import useUserStatuses from '../../../common/hooks/useUserStatuses';

import StatusCell from '../../Unknown/StatusCell';
import ActionsBox from '../../Unknown/ActionsBox';

import IpAddressCell from '../../Unknown/IpAddressCell';
import messages from './messages';

const propertiesColumn = {
  editable: false,
  filterable: false,
};

const useColumns = (): typeof columns => {
  const history = useHistory();

  const intl = useIntl();

  const userStatuses = useUserStatuses();

  const isBrandSuspended = useCheckBrandIsSuspend();

  const onViewClick = useCallback(
    (id: string, brandId: string) => {
      history.push(`/b/${brandId}/player-overview/${id}`);
    },
    [history],
  );

  const columns: GridColumns = useMemo(
    () => [
      {
        ...propertiesColumn,
        field: 'player_id',
        hide: true,
        headerName: intl.formatMessage(messages.id),
        flex: 1,
      },
      {
        ...propertiesColumn,
        field: 'player_surname',
        headerName: intl.formatMessage(messages.surname),
        flex: 1,
      },
      {
        ...propertiesColumn,
        field: 'player_email',
        headerName: intl.formatMessage(messages.email),
        flex: 1,
      },
      {
        ...propertiesColumn,
        field: 'player_phone_number',
        headerName: intl.formatMessage(messages.phoneNumber),
        flex: 1,
      },
      {
        ...propertiesColumn,
        field: 'player_brand_id',
        headerName: intl.formatMessage(messages.brand),
        flex: 1,
      },
      {
        ...propertiesColumn,
        field: 'log_type',
        valueGetter: ({ value }) => {
          return sentenceCase(value);
        },
        headerName: intl.formatMessage(messages.type),
        flex: 1,
      },
      {
        ...propertiesColumn,
        field: 'player_status',
        headerName: intl.formatMessage(messages.status),
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          if (!params.value) return null;
          const status = params.value as keyof typeof userStatuses;

          if (isBrandSuspended) {
            const userStatus = userStatuses.suspend;

            return <StatusCell {...userStatus} />;
          }

          const userStatus = userStatuses[status];

          if (!userStatus) return null;

          return <StatusCell {...userStatus} />;
        },
      },
      {
        ...propertiesColumn,
        field: 'ip_address',
        headerName: intl.formatMessage(messages.ipAddress),
        renderCell: (params) => {
          if (!params.row) return null;

          const { log_ip_address, log_country_code } = params.row;

          return (
            <IpAddressCell
              ipAddress={log_ip_address}
              countryCode={log_country_code}
            />
          );
        },
      },
      {
        ...propertiesColumn,
        field: 'actions',
        headerName: intl.formatMessage(messages.actions),
        flex: 0.5,
        renderCell: (params: GridRenderCellParams) => {
          if (!params.row) return null;

          const { id, player_brand_id } = params.row;

          const actions = [
            {
              label: intl.formatMessage(messages.view),
              buttonProps: {
                onClick: () => onViewClick(id, player_brand_id),
              },
            },
          ];

          return <ActionsBox actions={actions} />;
        },
      },
    ],
    [intl, isBrandSuspended, userStatuses, onViewClick],
  );

  return columns;
};

export default useColumns;
