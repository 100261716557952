import { defineMessages } from 'react-intl';
export default defineMessages({
  id: {
    id: 'Common.id',
    defaultMessage: 'Id',
  },
  settled: {
    id: 'Common.settled',
    defaultMessage: 'Settled',
  },
  eventType: {
    id: 'ExchangeBetOverviewList.eventType',
    defaultMessage: 'Event type',
  },
  event: {
    id: 'ExchangeBetOverviewList.event',
    defaultMessage: 'Event',
  },
  market: {
    id: 'Common.market',
    defaultMessage: 'Market',
  },
  selectionLabel: {
    id: 'RiskMatrix.selectionLabel',
    defaultMessage: 'Selection',
  },
  side: {
    id: 'Common.side',
    defaultMessage: 'Side',
  },
  odds: {
    id: 'Common.odds',
    defaultMessage: 'Odds',
  },
  totalBets: {
    id: 'Common.totalBets',
    defaultMessage: 'Total bets',
  },
  status: {
    id: 'Common.status',
    defaultMessage: 'Status',
  },
  totalVolume: {
    id: 'Common.totalVolume',
    defaultMessage: 'Total volume',
  },
  grossProfit: {
    id: 'Common.grossProfit',
    defaultMessage: 'Gross profit',
  },
  netProfit: {
    id: 'Common.netProfit',
    defaultMessage: 'Net profit',
  },
  total: {
    id: 'Common.total',
    defaultMessage: 'Total',
  },
  warning: {
    id: 'DatePicker.warning',
    defaultMessage: 'All dates are displayed in UTC time.',
  },
  plOverview: {
    id: 'ProfitLoss.overview',
    defaultMessage: 'Profit & Loss Overview',
  },
});
