import { getApp, getApps, initializeApp } from 'firebase/app';
import { initializeAuth, inMemoryPersistence } from 'firebase/auth';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import firebaseConfig from './firebaseConfig';

import 'firebase/database';
import { DEFAULT_REGION } from './constants';

const app = getApps() ? initializeApp(firebaseConfig) : getApp();

initializeAuth(app, {
  persistence: inMemoryPersistence,
});

const isLocal = process.env.REACT_APP_NODE_ENV === ('local' as string);

if (isLocal) {
  const localHost = process.env.REACT_APP_LOCAL_HOST ?? '127.0.0.1';

  const localPort = process.env.REACT_APP_LOCAL_PORT ?? '5001';

  const functions = getFunctions(app, DEFAULT_REGION);

  connectFunctionsEmulator(functions, localHost, Number(localPort));
}

export default app;
