import * as Yup from 'yup';
import { Field, PhoneNumber } from '../../../common/validationMessages';

import { REG_EXP_PHONE_NUMBER } from '../../../common/constants';

const messageMax20Characters = 'Maximum 20 characters';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(20, messageMax20Characters)
    .trim(Field.required)
    .required(Field.required),
  surname: Yup.string()
    .max(20, messageMax20Characters)
    .trim(Field.required)
    .required(Field.required),
  email: Yup.string().trim(Field.required).email().required(Field.required),
  phoneNumber: Yup.string()
    .trim(Field.required)
    .matches(REG_EXP_PHONE_NUMBER, PhoneNumber.notValid),
  country: Yup.string().trim(Field.required).required(Field.required),
  city: Yup.string().trim(Field.required).required(Field.required),
  street: Yup.string().trim(Field.required).required(Field.required),
  zipCode: Yup.string().trim(Field.required).required(Field.required),
  brandId: Yup.string().required(Field.required),
  isEnableTotp: Yup.boolean().default(false),
});

export default validationSchema;
