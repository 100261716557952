import { useLocation } from 'react-router';
import useMyAccountPath from './useMyAccountPath';

const useCheckIsAccountPath = (): boolean => {
  const myAccountPath = useMyAccountPath();

  const { pathname } = useLocation();

  if (!pathname) return false;

  const isAccountPath = pathname.includes(myAccountPath);

  return isAccountPath;
};

export default useCheckIsAccountPath;
