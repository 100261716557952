import { useCallback } from 'react';
import { DEFAULT_REGION } from '../constants';
import { useFirebaseApp } from 'reactfire';
import { Brand } from '../../../types';
import { getFunctions, httpsCallable } from 'firebase/functions';

type Payload = Partial<
  Pick<Brand, 'name' | 'status' | 'max_credit' | 'max_exposure'>
>;

type UpdateBrandParams = {
  payload: Payload;
  brandId: string;
};

interface UseUpdateBrandParams {
  brandId: string;
}

type UpdateBrand = (payload: Payload) => Promise<void>;

const useUpdateBrand = (params: UseUpdateBrandParams): UpdateBrand => {
  const { brandId } = params;

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const updateBrand = useCallback(
    async (payload: Payload) => {
      const updateBrandFunction = httpsCallable(
        functions,
        'back-brand-updateBrand',
      );

      const updateBrandParams: UpdateBrandParams = {
        brandId,
        payload,
      };

      await updateBrandFunction(updateBrandParams);
    },
    [brandId, functions],
  );

  return updateBrand;
};

export default useUpdateBrand;
