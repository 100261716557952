import { defineMessages } from 'react-intl';

export default defineMessages({
  pendingStatus: {
    id: 'common.pendingStatus',
    defaultMessage: 'Pending',
  },
  won: {
    id: 'common.wonStatus',
    defaultMessage: 'Won',
  },
  loss: {
    id: 'common.lossStatus',
    defaultMessage: 'Loss',
  },
  executableStatus: {
    id: 'common.voidedStatus',
    defaultMessage: 'Executable',
  },
  executionCompleteStatus: {
    id: 'common.executionCompleteStatus',
    defaultMessage: 'Execution complete',
  },
  unmatchedStatus: {
    id: 'common.unmatchedStatus',
    defaultMessage: 'Unmatched',
  },
  matchedStatus: {
    id: 'common.matchedStatus',
    defaultMessage: 'Matched',
  },
  settledStatus: {
    id: 'common.settledStatus',
    defaultMessage: 'Settled',
  },
  backSide: {
    id: 'common.backSide',
    defaultMessage: 'Back',
  },
  laySide: {
    id: 'common.laySide',
    defaultMessage: 'Lay',
  },
  voidedStatus: {
    id: 'common.voidedStatus',
    defaultMessage: 'Voided',
  },
  cancelledStatus: {
    id: 'common.cancelledStatus',
    defaultMessage: 'Cancelled',
  },
  lapsedStatus: {
    id: 'common.lapsedStatus',
    defaultMessage: 'Lapsed',
  },
  connectingStatus: {
    id: 'common.connectingStatus',
    defaultMessage: 'Connecting',
  },
  failStatus: {
    id: 'common.failStatus',
    defaultMessage: 'Fail',
  },
  successStatus: {
    id: 'common.successStatus',
    defaultMessage: 'Success',
  },
  systemAdminLabel: {
    id: 'common.systemAdminLabel',
    defaultMessage: 'System admin',
  },
  ownerLabel: {
    id: 'common.ownerLabel',
    defaultMessage: 'Brand owner',
  },
  superAgentLabel: {
    id: 'common.superAgentLabel',
    defaultMessage: 'Super agent',
  },
  masterAgentLabel: {
    id: 'common.masterAgentLabel',
    defaultMessage: 'Master agent',
  },
  agentLabel: {
    id: 'common.agentLabel',
    defaultMessage: 'Agent',
  },
  supportLabel: {
    id: 'common.supportLabel',
    defaultMessage: 'Support',
  },
  marketingLabel: {
    id: 'common.marketingLabel',
    defaultMessage: 'Marketing',
  },
  financeLabel: {
    id: 'common.financeLabel',
    defaultMessage: 'Finance',
  },
  playerLabel: {
    id: 'common.playerLabel',
    defaultMessage: 'Player',
  },
  failedUploadNetworkTree: {
    id: 'common.failedUploadNetworkTree',
    defaultMessage: 'An error while trying upload network tree',
  },
  inactive: {
    id: 'common.status.inactive',
    defaultMessage: 'Inactive',
  },
  active: {
    id: 'common.status.active',
    defaultMessage: 'Active',
  },
  pending: {
    id: 'common.status.pending',
    defaultMessage: 'Pending',
  },
  disabled: {
    id: 'common.status.disabled',
    defaultMessage: 'Disabled',
  },
  closed: {
    id: 'common.status.closed',
    defaultMessage: 'Closed',
  },
  suspend: {
    id: 'common.status.suspend',
    defaultMessage: 'Suspend',
  },
  failedUpload: {
    id: 'common.failedUpload',
    defaultMessage: 'An error while trying upload data',
  },
  bothTeamsToScore: {
    id: 'common.bothTeamsToScore',
    defaultMessage: 'Both Teams to Score',
  },
  doubleChance: {
    id: 'common.doubleChance',
    defaultMessage: 'Double Chance',
  },
  drawNoBet: {
    id: 'common.drawNoBet',
    defaultMessage: 'Draw no bet',
  },
  firstHalfGoals: {
    id: 'common.firstHalfGoals',
    defaultMessage: 'First half goals {value}',
  },
  halfMatchOdds: {
    id: 'common.halfMatchOdds',
    defaultMessage: 'Half Match Odds',
  },
  halfTime: {
    id: 'common.halfTime',
    defaultMessage: 'Half Time',
  },
  halfTotalPoints: {
    id: 'common.halfTotalPoints',
    defaultMessage: 'Half Total Points',
  },
  matchOdds: {
    id: 'common.matchOdds',
    defaultMessage: 'Match Odds',
  },
  moneyLine: {
    id: 'common.moneyLine',
    defaultMessage: 'Money Line',
  },
  overUnder: {
    id: 'common.overUnder',
    defaultMessage: 'Over/Under {value} Goals',
  },
  rtMatchOdds: {
    id: 'common.rtMatchOdds',
    defaultMessage: 'RT Match Odds',
  },
  fetchErrorMessage: {
    id: 'common.fetchErrorMessage',
    defaultMessage: 'An error occurred during the request',
  },
  limitExceeded: {
    id: 'common.limitExceeded',
    defaultMessage: 'Not enough {value} available',
  },
  networkConfigSuccessChanged: {
    id: 'common.networkConfigSuccessChanged',
    defaultMessage: 'User network config has been success changed',
  },
  inPlayStatus: {
    id: 'common.inPlayStatus',
    defaultMessage: 'In-Play',
  },
  notStartedStatus: {
    id: 'common.notStartedStatus',
    defaultMessage: 'Not started',
  },
  expiredStatus: {
    id: 'common.expiredStatus',
    defaultMessage: 'Expired',
  },
  playerFlagged: {
    id: 'common.playerFlagged',
    defaultMessage: 'Flagged',
  },
  playerNotFlagged: {
    id: 'common.playerNotFlagged',
    defaultMessage: 'Unflagged',
  },
});
