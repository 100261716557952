import { useContext, useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, UIContext } from '@miyagami-com/lsx-ui-components';
import { FormattedMessage, useIntl } from 'react-intl';
import DefaultLayout from '../Unknown/DefaultLayout';
import OverviewLayout from '../Unknown/OverviewLayout';
import MarketList from './MarketBetsList';
import messages from './messages';
import useGetOutcomes, { OutcomeBets } from './useGetOutcomes';
import LoadingBox from '../Unknown/LoadingBox';
import { useFirebaseApp } from 'reactfire';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { DEFAULT_REGION } from '../../common/constants';

const ResettleMarketPage = (): JSX.Element => {
  const path = [{ label: 'Resettle market' }, { label: 'Resettle market' }];

  const intl = useIntl();
  const { setAlert } = useContext(UIContext);

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [marketId, setMarketId] = useState('');
  const [rows, setRows] = useState<OutcomeBets[]>([]);
  const callGetOutcomesByMarketId = useGetOutcomes();

  useEffect(() => {
    setIsLoading(true);

    const fetchOutcomes = async () => {
      const outcomes = await callGetOutcomesByMarketId(marketId);

      setRows(outcomes);
      setIsLoading(false);
    };

    fetchOutcomes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketId]);

  const handleResettle = async () => {
    const resettleBets = httpsCallable(
      functions,
      'back-exchangeBet-invokeResettleMarket',
    );

    try {
      setIsSubmitting(true);
      await resettleBets({ marketId });

      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.resettleSuccess),
      });
    } catch (error) {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.resettleError),
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DefaultLayout path={path}>
      <OverviewLayout
        headerProps={{
          name: 'Resettle market',
          isResettleMarketPage: true,
          marketId,
          setMarketId,
        }}
      >
        {isLoading ? <LoadingBox /> : <MarketList rows={rows} />}
        {!!rows.length && (
          <Box p={4}>
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleResettle}
            >
              <FormattedMessage {...messages.resetAll} />
            </LoadingButton>
          </Box>
        )}
      </OverviewLayout>
    </DefaultLayout>
  );
};

export default ResettleMarketPage;
