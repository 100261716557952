import { Language } from '../../../../types/supabase';
import createSupabaseClient from '../../../common/createSupabaseClient';

const getLanguageById = async ({
  id,
}: {
  id: string;
}): Promise<Language | null> => {
  const supabaseClient = createSupabaseClient({});

  const ref = supabaseClient.from('languages');

  const response = await ref.select('*').eq('id', id).throwOnError().single();

  return response.data;
};

export default getLanguageById;
