import { defineMessages } from 'react-intl';

export default defineMessages({
  fromTo: {
    id: 'Unknown.HeaderWithFilter.fromTo',
    defaultMessage: 'From { from } to { to }.',
  },
  warning: {
    id: 'ProfitLossDatePicker.warning',
    defaultMessage: 'All dates are displayed in UTC time.',
  },
  globalExposure: {
    id: 'Unkown.HeaderWithFilter.globalExposure',
    defaultMessage: 'Total exposure:',
  },
  globalBalance: {
    id: 'Unkown.HeaderWithFilter.globalBalance',
    defaultMessage: 'Total balance:',
  },
});
