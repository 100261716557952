import React from 'react';
import { Switch, Route } from 'react-router';
import AuthenticationPage from '../../BackofficeUser/AuthenticationPage';
import ResetPasswordPage from '../../BackofficeUser/ResetPasswordPage';
import LoginPage from '../../BackofficeUser/LoginPage';
import AuthenticationContextProvider from '../AuthenticationContext';

const GuestRoot: React.FC = () => {
  return (
    <AuthenticationContextProvider>
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/reset-password" component={ResetPasswordPage} />
        <Route exact path="/authentication" component={AuthenticationPage} />
      </Switch>
    </AuthenticationContextProvider>
  );
};

export default GuestRoot;
