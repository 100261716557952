import React, { useMemo } from 'react';
import { Box, Grid } from '@miyagami-com/lsx-ui-components';
import { useParams, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import DefaultLayout from '../../Unknown/DefaultLayout';
import { RootState } from '../../../../types';
import BackofficeUserAccountHeader from '../../BackofficeUser/BackofficeUserAccountHeader';
import UserPermissionEdit from '../../UserPermission/UserPermissionEdit';

import messages from './messages';

type QueryParams = {
  brandId: string;
  userId: string;
};

const UserPermissionEditPage: React.FC = () => {
  const intl = useIntl();
  const { replace } = useHistory();
  const { userId, brandId }: QueryParams = useParams();

  const {
    users: { byBrandId: users },
  } = useSelector((state: RootState) => state.globalData);

  const user = useMemo(() => {
    const brandUsers = users[brandId];

    const foundedUser = brandUsers?.backofficeUsers?.find(
      ({ id }) => id === userId,
    );
    if (!foundedUser) replace(`/backoffice-users`);

    return foundedUser;
  }, [brandId, replace, userId, users]);

  const path = [
    { label: intl.formatMessage(messages.system) },
    {
      label: intl.formatMessage(messages.backofficeUsers),
      url: `/b/${brandId}/backoffice-users`,
    },
    { label: user?.username || '' },
  ];

  return (
    <>
      {user && (
        <DefaultLayout path={path}>
          <Box p={4}>
            <Grid container>
              <Grid item xs={12}>
                <BackofficeUserAccountHeader
                  user={user}
                  brandId={brandId}
                  lastActivityTime={null} // TODO: will done in another task
                />
              </Grid>
              <Grid container spacing={4} pt={4}>
                <Grid item xs={12}>
                  <UserPermissionEdit user={user} brandId={brandId} />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DefaultLayout>
      )}
    </>
  );
};

export default UserPermissionEditPage;
