import { defineMessages } from 'react-intl';

export default defineMessages({
  cms: {
    id: 'Widget.WidgetDetailsPage.cms',
    defaultMessage: 'CMS',
  },
  widgets: {
    id: 'Widget.WidgetDetailsPage.widgets',
    defaultMessage: 'Widgets',
  },
});
