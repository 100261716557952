import { defineMessages } from 'react-intl';

export default defineMessages({
  system: {
    id: 'WhiteList.WhiteListPage.system',
    defaultMessage: 'System',
  },
  ipAddresses: {
    id: 'WhiteList.WhiteListPage.ipAddresses',
    defaultMessage: 'IP addresses',
  },
});
