import { defineMessages } from 'react-intl';

export default defineMessages({
  system: {
    id: 'UserPermission.UserPermissionEditPage.system',
    defaultMessage: 'System',
  },
  backofficeUsers: {
    id: 'UserPermission.UserPermissionEditPage.backofficeUsers',
    defaultMessage: 'Backoffice users',
  },
});
