import { defineMessages } from 'react-intl';

export default defineMessages({
  successUpdateUser: {
    id: 'Agent.AgentPersonalInformation.successUpdateUser',
    defaultMessage: 'User successfully updated!',
  },
  errorUpdateUser: {
    id: 'Agent.AgentPersonalInformation.errorUpdateUser',
    defaultMessage: 'An error has occurred while updating user!',
  },
  label: {
    id: 'Agent.AgentPersonalInformation.label',
    defaultMessage: 'Personal information',
  },
});
