import { defineMessages } from 'react-intl';

export default defineMessages({
  exchange: {
    id: 'ExchangeBet.ExchangeBetPage.exchange',
    defaultMessage: 'Exchange',
  },
  bettingOverview: {
    id: 'ExchangeBet.ExchangeBetPage.bettingOverview',
    defaultMessage: 'Betting overview',
  },
});
