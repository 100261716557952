import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import { ProfitLossReportLevel } from '../../../../types';

const useReportLevelMessage = (): typeof reportItemLabelByReportLevel => {
  const intl = useIntl();

  const reportItemLabelByReportLevel: Record<ProfitLossReportLevel, string> =
    useMemo(() => {
      return {
        systemAdmin: intl.formatMessage(messages.system),
        brand: intl.formatMessage(messages.brand),
        superAgent: intl.formatMessage(messages.superAgent),
        masterAgent: intl.formatMessage(messages.masterAgent),
        agent: intl.formatMessage(messages.agent),
        player: intl.formatMessage(messages.player),
      };
    }, [intl]);

  return reportItemLabelByReportLevel;
};

export default useReportLevelMessage;
