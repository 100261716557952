import { getFunctions, httpsCallable } from 'firebase/functions';
import { useCallback } from 'react';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../constants';

interface AddBrandDomainParams {
  domain: string;
  brandId: string;
}

export type AddBrandDomain = (params: AddBrandDomainParams) => Promise<void>;

const useAddBrandDomain = (): AddBrandDomain => {
  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const addBrandDomain = useCallback(
    async (params: AddBrandDomainParams) => {
      const addBrandDomainFunction = httpsCallable(
        functions,
        'back-brandDomain-addBrandDomain',
      );

      await addBrandDomainFunction(params);
    },
    [functions],
  );

  return addBrandDomain;
};

export default useAddBrandDomain;
