import { useSelector } from 'react-redux';
import { RootState } from '../../../types';

const useIsLoadingUser = (): boolean => {
  const { permissionMap, isAuth } = useSelector((root: RootState) => root.user);

  return !Object.keys(permissionMap).length && isAuth;
};

export default useIsLoadingUser;
