export default [
  {
    id: 'en',
    name: 'English',
    countryCode: 'gb',
  },
  {
    id: 'es',
    name: 'Spanish',
    countryCode: 'es',
  },
  {
    id: 'zh',
    name: 'Chinese',
    countryCode: 'cn',
  },
  {
    id: 'pt-br',
    name: 'Brazilian',
    countryCode: 'br',
  },
  {
    id: 'hi',
    name: 'Hindi',
    countryCode: 'in',
  },
  {
    id: 'bn',
    name: 'Bengali',
    countryCode: 'bd',
  },
  {
    id: 'uk',
    name: 'Ukrainian',
    countryCode: 'ua',
  },
  {
    id: 'ru',
    name: 'Russian',
    countryCode: 'ru',
  },
  {
    id: 'ja',
    name: 'Japanese',
    countryCode: 'jp',
  },
  {
    id: 'vi',
    name: 'Vietnamese',
    countryCode: 'vn',
  },
  {
    id: 'tr',
    name: 'Turkish',
    countryCode: 'tr',
  },
  {
    id: 'ko',
    name: 'Korean',
    countryCode: 'kr',
  },
  {
    id: 'fr',
    name: 'French',
    countryCode: 'fr',
  },
  {
    id: 'de',
    name: 'German',
    countryCode: 'de',
  },
  {
    id: 'it',
    name: 'Italian',
    countryCode: 'it',
  },
  {
    id: 'ar',
    name: 'Arabic',
    countryCode: 'ae',
  },
  {
    id: 'id',
    name: 'Indonesian',
    countryCode: 'id',
  },
  {
    id: 'pa',
    name: 'Punjabi',
    countryCode: 'pk',
  },
  {
    id: 'el',
    name: 'Greek',
    countryCode: 'gr',
  },
  {
    id: 'du',
    name: 'Dutch',
    countryCode: 'nl',
  },
  {
    id: 'sv',
    name: 'Swedish',
    countryCode: 'se',
  },
];
