import { defineMessages } from 'react-intl';

export default defineMessages({
  collapseLabel: {
    id: 'User.UserStatus.collapseLabel',
    defaultMessage: 'Status',
  },
  saveButton: {
    id: 'User.UserStatus.saveButton',
    defaultMessage: 'Save',
  },
  brand: {
    id: 'User.UserStatus.brand',
    defaultMessage: 'Brand',
  },
  status: {
    id: 'User.UserStatus.status',
    defaultMessage: 'Status',
  },
  riskLevel: {
    id: 'User.UserStatus.riskLevel',
    defaultMessage: 'Risk level',
  },
  successChangeStatus: {
    id: 'User.UserStatus.successChangePassword',
    defaultMessage: 'Status was successfully changed.',
  },
  errorChangeStatus: {
    id: 'User.UserStatus.errorChangePassword',
    defaultMessage: 'An error occurred while changing status.',
  },
});
