import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import { Button } from '@miyagami-com/lsx-ui-components';

import StatusCell from '../../Unknown/StatusCell';
import DataGridTable from '../../Unknown/DataGridTable';

import BrandDomainConfig from '../BrandDomainConfig';
import BrandDomainVerification from '../BrandDomainVerification';

import { DOMAIN_STATUSES } from '../../../common/constants';

import { BrandDomainStatus } from '../../../../types';

import messages from './messages';
import { DomainData } from '../BrandDomains/sortedDomainsData';

type BrandDomainListProps = {
  rows: DomainData[];
  brandId: string;
  loading: boolean;
};

interface OnSelectDomainParams {
  domainId: string;
  domainName: string;
  domainStatus: BrandDomainStatus;
  misconfigured: boolean;
}

const BrandDomainList: React.FC<BrandDomainListProps> = ({
  rows,
  brandId,
  loading,
}) => {
  const intl = useIntl();

  const [selectedDomainId, setSelectedDomainId] = useState<string>('');
  const [selectedDomainName, setSelectedDomainName] = useState<string>('');
  const [domainMisconfigured, setDomainMisconfigured] =
    useState<boolean>(false);

  const handleCloseVerification = useCallback(() => {
    setSelectedDomainId('');
    setDomainMisconfigured(false);
  }, [setSelectedDomainId]);

  const handleSelectDomainId = async (params: OnSelectDomainParams) => {
    const { domainId, domainName, domainStatus, misconfigured } = params;

    if (domainStatus !== 'verification' && domainStatus !== 'misconfigured') {
      return;
    }

    if (domainStatus === 'misconfigured') {
      setDomainMisconfigured(misconfigured);
    }

    setSelectedDomainId(domainId);
    setSelectedDomainName(domainName);
  };

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: intl.formatMessage(messages.id),
      flex: 1,
      editable: false,
      sortable: true,
      filterable: false,
    },
    {
      field: 'date',
      headerName: intl.formatMessage(messages.date),
      flex: 1,
      editable: false,
      sortable: true,
      filterable: false,
    },
    {
      field: 'domain',
      headerName: intl.formatMessage(messages.domain),
      flex: 1,
      editable: false,
      sortable: true,
      filterable: false,
    },
    {
      field: 'status',
      headerName: intl.formatMessage(messages.status),
      flex: 1,
      editable: false,
      sortable: true,
      filterable: false,
      renderCell: (
        params: GridRenderCellParams<BrandDomainStatus, DomainData>,
      ) => {
        const row = params.row;
        if (!row) return null;

        const { id, is_misconfigured, domain } = row;
        const status = params.value;

        if (!status) return null;

        const domainStatus = DOMAIN_STATUSES[status];

        if (!domainStatus || !id) return null;

        return (
          <Button
            onClick={() =>
              handleSelectDomainId({
                domainId: id,
                misconfigured: is_misconfigured,
                domainStatus: status,
                domainName: domain,
              })
            }
            color="secondary"
            disabled={false}
          >
            <StatusCell {...domainStatus} />
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <DataGridTable
        loading={loading}
        tableKey="brand-domain-list"
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        autoHeight
        rowHeight={32}
        headerHeight={36}
      />
      {domainMisconfigured ? (
        <BrandDomainConfig
          brandId={brandId}
          domainId={selectedDomainId}
          domainName={selectedDomainName}
          handleClose={handleCloseVerification}
        />
      ) : (
        <BrandDomainVerification
          brandId={brandId}
          domainId={selectedDomainId}
          handleClose={handleCloseVerification}
        />
      )}
    </>
  );
};

export default BrandDomainList;
