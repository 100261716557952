import React, { useCallback, useEffect, useState } from 'react';
import { SportLineRow } from './useColumns';
import { GridRowParams, useGridApiContext } from '@mui/x-data-grid-premium';
import createFetchParamsByRow from './createFetchParamsByRow';
import useNavigationNode from '../../../common/hooks/useNavigationNode';
import { Box, LoadingButton } from '@miyagami-com/lsx-ui-components';
import { RootNode } from '../../../../types';
import { useIntl } from 'react-intl';
import messages from './messages';

interface DetailPanelProps extends GridRowParams<SportLineRow> {
  addNode: (node: RootNode) => void;
  brandId: string;
  playerId: string | undefined;
}

const DetailPanel: React.FC<DetailPanelProps> = (props) => {
  const { row, addNode, brandId, playerId } = props;

  const intl = useIntl();

  const [isClicked, setIsClicked] = useState<boolean>(false);

  const fetchParams = createFetchParamsByRow({
    row,
    brandId,
    playerId,
  });

  const {
    data: node,
    isLoading,
    isSuccess,
  } = useNavigationNode(fetchParams, isClicked);

  const apiRef = useGridApiContext();

  const onClick = useCallback(() => {
    setIsClicked(true);
  }, []);

  useEffect(() => {
    if (isLoading || !node) return;

    if (isSuccess) {
      apiRef.current.toggleDetailPanel(row.id);
      apiRef.current.setRowChildrenExpansion(row.id, true);
    }

    addNode(node);
  }, [addNode, apiRef, isLoading, isSuccess, node, row.id]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor="grey.200"
      color="primary.main"
      height={1}
      width={1}
    >
      <LoadingButton
        loading={isLoading}
        onClick={onClick}
        variant="text"
        color="inherit"
      >
        {intl.formatMessage(messages.loadMore)}
      </LoadingButton>
    </Box>
  );
};

export default DetailPanel;
