import * as Yup from 'yup';
import { REG_EXP_NUMBER_ALPHABETS } from '../../../common/constants';
import { Field } from '../../../common/validationMessages';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(20, 'Maximum 20 characters')
    .trim(Field.trim)
    .matches(REG_EXP_NUMBER_ALPHABETS, {
      message: Field.withoutSpecialCharacters,
    })
    .required(Field.required),
  status: Yup.string().trim(Field.trim).required(Field.required),
});

export default validationSchema;
