import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

import { RootState, ExchangeBet } from '../../../../types';
import HeaderWithFilter from '../../Unknown/HeaderWithFilter';
import { DEFAULT_DATE_FORMAT } from '../../../common/constants';

import messages from './messages';
import getMinMaxNumber from '../../../common/getMinMaxNumber';
import {
  localToUTC,
  makeLocalAppearUTC,
} from '../../ProfitLoss/ProfitLossReportList/utils';
import type { DateRange } from '@mui/x-date-pickers-pro';
import { tomorrow } from '../../ProfitLoss/ProfitLossReportPage/reportPeriodData';

interface ExchangeBetOverviewHeaderProps {
  exchangeBets: ExchangeBet[];
  dateRange: DateRange<Date>;
  setDateRange: (dateRange: DateRange<Date>) => void;
  hideBrandFilter?: boolean;
}

const ExchangeBetOverviewHeader: FC<ExchangeBetOverviewHeaderProps> = (
  props,
) => {
  const { exchangeBets, dateRange, setDateRange, hideBrandFilter } = props;

  const intl = useIntl();
  const { brands } = useSelector((state: RootState) => state.globalData);

  const { from, to } = useMemo(() => {
    const exchangeBetDateList = exchangeBets.map((exchangeBet) =>
      new Date(exchangeBet.created_at).getMilliseconds(),
    );

    const { min, max } = getMinMaxNumber(exchangeBetDateList);

    return {
      from: min,
      to: max,
    };
  }, [exchangeBets]);

  return (
    <HeaderWithFilter
      dateRangeProps={{
        dateRangePickerProps: {
          value: makeLocalAppearUTC(dateRange),
          onChange: (newValue) => {
            const newValueUTC = localToUTC(
              newValue as DateRange<Date>,
              dateRange[1],
            );
            // onDatePickerChange(newValueUTC);
            setDateRange(newValueUTC);
          },
          // onClose: changeReportDateRange,
          maxDate: tomorrow,
          closeOnSelect: false,
        },
        localizationProviderProps: {},
      }}
      name={intl.formatMessage(messages.bettingOverview)}
      from={format(from, DEFAULT_DATE_FORMAT)}
      to={format(to, DEFAULT_DATE_FORMAT)}
      brands={hideBrandFilter ? undefined : brands}
    />
  );
};

export default ExchangeBetOverviewHeader;
