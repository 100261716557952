import { defineMessages } from 'react-intl';

export default defineMessages({
  id: {
    id: 'Log.LogUserList.id',
    defaultMessage: 'ID',
  },
  date: {
    id: 'Log.LogUserList.date',
    defaultMessage: 'Date',
  },
  time: {
    id: 'Log.LogUserList.time',
    defaultMessage: 'Time',
  },
  action: {
    id: 'Log.LogUserList.action',
    defaultMessage: 'Action',
  },
  ipAddress: {
    id: 'Log.LogUserList.ipAddress',
    defaultMessage: 'IP address',
  },
  errorLogsMessage: {
    id: 'Log.LogUserList.errorLogsMessage',
    defaultMessage: 'An error occurred while loading logs!',
  },
});
