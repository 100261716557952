import {
  GridCellEditCommitParams,
  GridRowModel,
} from '@mui/x-data-grid-premium';

export interface GetCellEditCommitUpdatedRowParams
  extends GridCellEditCommitParams {
  row: GridRowModel;
}

const getCellEditCommitUpdatedRow = (
  params: GetCellEditCommitUpdatedRowParams,
): GridRowModel => {
  const { row, field, value } = params;

  const updatedRow = {
    ...row,
    [field]: value,
  };

  return updatedRow;
};

export default getCellEditCommitUpdatedRow;
