import { defineMessages } from 'react-intl';

export default defineMessages({
  changeUserIpAddress: {
    id: 'WhiteList.WhiteListEditing.changeUserIpAddress',
    defaultMessage: 'Change user IP address',
  },
  ipAddress: {
    id: 'WhiteList.WhiteListEditing.ipAddress',
    defaultMessage: 'IP address',
  },
  save: {
    id: 'WhiteList.WhiteListEditing.save',
    defaultMessage: 'Save',
  },
  username: {
    id: 'WhiteList.WhiteListEditing.username',
    defaultMessage: 'Username:',
  },
  successfulUpdate: {
    id: 'WhiteList.WhiteListEditing.successfulUpdate',
    defaultMessage: 'Successful update user IP',
  },
  accessRights: {
    id: 'WhiteList.WhiteListEditing.accessRights',
    defaultMessage: 'You do not have access rights',
  },
  validIp: {
    id: 'WhiteList.WhiteListEditing.validIp',
    defaultMessage: 'IP address value should be less or equal to 255',
  },
});
