import { defineMessages } from 'react-intl';

export default defineMessages({
  personal: {
    id: 'User.UserPersonalInformationForm.personal',
    defaultMessage: 'Personal',
  },
  name: {
    id: 'User.UserPersonalInformationForm.name',
    defaultMessage: 'Name',
  },
  authUsername: {
    id: 'User.UserPersonalInformationForm.authUsername',
    defaultMessage: 'Username',
  },
  surname: {
    id: 'User.UserPersonalInformationForm.surname',
    defaultMessage: 'Surname',
  },
  contact: {
    id: 'User.UserPersonalInformationForm.contact',
    defaultMessage: 'Contact',
  },
  email: {
    id: 'User.UserPersonalInformationForm.email',
    defaultMessage: 'Email address',
  },
  phoneNumber: {
    id: 'User.UserPersonalInformationForm.phoneNumber',
    defaultMessage: 'Telephone',
  },
  address: {
    id: 'User.UserPersonalInformationForm.address',
    defaultMessage: 'Address',
  },
  country: {
    id: 'User.UserPersonalInformationForm.country',
    defaultMessage: 'Country',
  },
  city: {
    id: 'User.UserPersonalInformationForm.city',
    defaultMessage: 'City',
  },
  street: {
    id: 'User.UserPersonalInformationForm.street',
    defaultMessage: 'Street',
  },
  zipCode: {
    id: 'User.UserPersonalInformationForm.zipCode',
    defaultMessage: 'ZIP code',
  },
  brandId: {
    id: 'User.UserPersonalInformationForm.brandId',
    defaultMessage: 'Brand',
  },
  totpRequired: {
    id: 'User.UserPersonalInformationForm.totpRequired',
    defaultMessage: 'TOTP required',
  },
  save: {
    id: 'User.UserPersonalInformationForm.save',
    defaultMessage: 'Save',
  },
});
