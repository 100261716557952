import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../constants';
import useBrandId from './useBrandId';
import { TreeRow } from '../../components/Network/NetworkTreeList/createTreeRow';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface NetworkConfig {
  positionTakingRate: number;
  minPositionTakingRate?: number;
  maxPositionTakingRate?: number;
  extraPositionTakingRate?: number;
  isExtraPTEnabled?: boolean;
  commissionRate: number;
}
interface UseUpdateUserNetworkConfigParams {
  userId: string;
  playerId: string | null;
  childUserId: string | null;
  brandId: string;
  payload: NetworkConfig;
  networkTree?: TreeRow[][];
}

const useUpdateUserNetworkConfig = (): typeof updateUserNetworkConfig => {
  const firebase = useFirebaseApp();

  const functions = getFunctions(firebase, DEFAULT_REGION);

  const brandId = useBrandId();

  const updateConfigFunction = httpsCallable(
    functions,
    'back-networkConfig-updateUserNetworkConfig',
  );
  const updateUserNetworkConfig = (
    params: Omit<UseUpdateUserNetworkConfigParams, 'brandId'>,
  ) => {
    return updateConfigFunction({ ...params, brandId });
  };

  return updateUserNetworkConfig;
};

export default useUpdateUserNetworkConfig;
