import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  DataGridProps,
  GridValueGetterParams,
  GridRowParams,
} from '@mui/x-data-grid';
import DataGridTable from '../../Unknown/DataGridTable';
import useColumns from './useColumns';
import { DEFAULT_ROWS_PER_PAGE } from '../../../common/constants';
import messages from './messages';
import useStyles from './useStyles';

interface PlayerListParams {
  dataGridProps: Omit<DataGridProps, 'columns'>;
}

const PlayerListList: FC<PlayerListParams> = ({ dataGridProps }) => {
  const columns = useColumns();
  const classes = useStyles();
  const intl = useIntl();

  const groupingColDef = useMemo(
    () => ({
      headerName: intl.formatMessage(messages.name),
      valueGetter: ({ row }: GridValueGetterParams) => {
        return row.player_name;
      },
      filterable: false,
      sortable: true,
      hideSortIcons: false,
      disableColumnMenu: false,
      cellClassName: classes.groupingCellClassName,
    }),
    [classes.groupingCellClassName, intl],
  );

  const getRowClassName = (params: GridRowParams) => {
    return params.row.path?.length > 1 ? classes.firstChild : '';
  };

  return (
    <DataGridTable
      {...dataGridProps}
      tableKey="duplicate_players"
      rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE}
      disableSelectionOnClick={true}
      autoHeight={true}
      rowHeight={35}
      headerHeight={35}
      treeData
      getTreeDataPath={(row) => row.path}
      getRowClassName={getRowClassName}
      columns={columns}
      pagination
      hideFooterRowCount
      hideFooterSelectedRowCount
      groupingColDef={groupingColDef}
    />
  );
};

export default PlayerListList;
