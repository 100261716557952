import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  GridColumns,
  GridRenderCellParams,
  GridAggregationFunction,
  GridColDef,
  GridValueFormatterParams,
  GRID_TREE_DATA_GROUPING_FIELD,
} from '@mui/x-data-grid-premium';
import { DataGridProps } from '@mui/x-data-grid';
import {
  SimpleDataGridPremium,
  Typography,
} from '@miyagami-com/lsx-ui-components';
import { DateRange } from '@mui/x-date-pickers-pro';
import useStyles from './useStyles';
import { today } from '../../ProfitLoss/ProfitLossReportPage/reportPeriodData';
import {
  localToUTC,
  makeLocalAppearUTC,
} from '../../ProfitLoss/ProfitLossReportList/utils';
import {
  ExchangeBetSide,
  ExchangeBetStatus,
  ExtendedOutcomes,
} from '../../../../types';
import useExchangeBetSideMap from '../../../common/hooks/useExchangeBetSideMap';
import useBetfairExchangeBetStatusMapFront from '../../../common/hooks/useBetfairExchangeBetStatusMapFront';
import useExchangeBetOutcome from '../../../common/hooks/useExchangeBetOutcomes';
import { DEFAULT_AMOUNT } from '../../../common/constants';
import normalizeOutcomes from './normalizeOutcomes';
import convertArrayToMap from '../../../common/convertArrayToMap';
import calculateTotals from './calculateTotals';
import messages from './messages';
import HeaderWithFilter from '../../Unknown/HeaderWithFilter';
import FormattedData from '../../Unknown/FormattedData';

const propertiesColumn: Partial<GridColDef> = {
  editable: false,
  filterable: false,
  flex: 1,
  align: 'left',
  headerAlign: 'left',
};

const rowHeight = 35;

const rowsPerPageOptions = [5, 10, 15, 25, 50, 100];

interface ProfitLossReportTableProps {
  playerId: string;
}

const ProfitLossReportTable: React.FC<ProfitLossReportTableProps> = (props) => {
  const intl = useIntl();

  const classes = useStyles({ rowHeight });

  const exchangeBetSideMap = useExchangeBetSideMap();

  const exchangeBetStatusMap = useBetfairExchangeBetStatusMapFront();

  const { playerId } = props;
  const userId = playerId;

  const [dateRange, setDateRange] = useState<DateRange<Date>>([
    new Date(),
    new Date(),
  ]);

  const dateRangeStrings = [
    dateRange[0]?.toISOString() || '',
    dateRange[1]?.toISOString() || '',
  ];

  const {
    data: outcomes,
    // isError,
    isLoading,
  } = useExchangeBetOutcome<ExtendedOutcomes>({
    playerId: userId,
    dateRange: dateRangeStrings,
  });

  const outcomeMap = useMemo(() => {
    return convertArrayToMap({
      array: outcomes || [],
      getId: (outcome) => outcome.id,
    });
  }, [outcomes]);

  const totals = useMemo(() => {
    return calculateTotals({ outcomes: outcomes || [] });
  }, [outcomes]);
  const count = outcomes?.length || DEFAULT_AMOUNT;

  const rows = useMemo(() => {
    if (!outcomes) return [];

    return normalizeOutcomes({ outcomes });
  }, [outcomes]);

  const dataGridProps: Omit<DataGridProps, 'columns'> = {
    loading: isLoading,
    rows,
    rowsPerPageOptions,
    pagination: true,
    rowCount: count,
  };
  const renderCurrencyCell = ({ value }: GridRenderCellParams) =>
    value ? Number(value || DEFAULT_AMOUNT).toFixed(2) : null;

  const columns: GridColumns = [
    {
      ...propertiesColumn,
      field: 'id',
      headerName: intl.formatMessage(messages.id),
      hide: true,
    },
    {
      ...propertiesColumn,
      field: 'settledAt',
      headerName: intl.formatMessage(messages.settled),
      renderCell: ({ value }: GridRenderCellParams) => {
        if (!value) {
          return null;
        }

        return <FormattedData dateValue={value} />;
      },
    },
    {
      ...propertiesColumn,
      field: 'eventTypeName',
      headerName: intl.formatMessage(messages.eventType),
    },
    {
      ...propertiesColumn,
      field: 'eventName',
      headerName: intl.formatMessage(messages.event),
    },
    {
      ...propertiesColumn,
      field: 'marketName',
      headerName: intl.formatMessage(messages.market),
    },
    {
      ...propertiesColumn,
      field: 'outcome',
      headerName: intl.formatMessage(messages.selectionLabel),
    },
    {
      ...propertiesColumn,
      field: 'side',
      headerName: intl.formatMessage(messages.side),
      valueFormatter: (params: GridValueFormatterParams<ExchangeBetSide>) => {
        const { value } = params;

        if (!value) return null;

        const side = exchangeBetSideMap[value];

        if (!side) return null;

        return side.label;
      },
    },
    {
      ...propertiesColumn,
      field: 'odds',
      headerName: intl.formatMessage(messages.odds),
      renderCell: renderCurrencyCell,
    },
    {
      ...propertiesColumn,
      field: 'totalBets',
      type: 'number',
      headerName: intl.formatMessage(messages.totalBets),
    },
    {
      ...propertiesColumn,
      field: 'status',
      headerName: intl.formatMessage(messages.status),
      valueFormatter: (params: GridValueFormatterParams<ExchangeBetStatus>) => {
        const { value } = params;

        if (!value) return null;

        const side = exchangeBetStatusMap[value];

        if (!side) return null;

        return side.label;
      },
    },
    {
      ...propertiesColumn,
      field: 'totalVolume',
      type: 'number',
      headerName: intl.formatMessage(messages.totalVolume),
      renderCell: renderCurrencyCell,
    },
    {
      ...propertiesColumn,
      field: 'grossProfit',
      type: 'number',
      headerName: intl.formatMessage(messages.grossProfit),
      renderCell: renderCurrencyCell,
    },
    {
      ...propertiesColumn,
      field: 'netProfit',
      type: 'number',
      headerName: intl.formatMessage(messages.netProfit),
      renderCell: renderCurrencyCell,
    },
  ];

  const getTotalNetProfit: GridAggregationFunction = {
    apply: (params) => {
      const { groupId } = params;

      const outcome = outcomeMap.get(groupId.toString());

      if (groupId) return outcome?.net_profit || null;

      return totals.totalNetProfit;
    },
  };

  const getTotalVolume: GridAggregationFunction = {
    apply: (params) => {
      const { groupId } = params;

      const outcome = outcomeMap.get(groupId.toString());

      if (groupId) return outcome?.total_volume || null;

      return totals.totalVolume;
    },
  };

  const getTotalBets: GridAggregationFunction = {
    apply: (params) => {
      const { groupId } = params;

      const outcome = outcomeMap.get(groupId.toString());

      if (groupId) return outcome?.total_bets || null;

      return totals.totalBets;
    },
  };

  const getTotalGrossProfit: GridAggregationFunction = {
    apply: (params) => {
      const { groupId } = params;

      const outcome = outcomeMap.get(groupId.toString());

      if (groupId) return outcome?.gross_profit || null;

      return totals.totalGrossProfit;
    },
  };

  const getAggregationLabel: GridAggregationFunction = {
    apply: (params) => {
      const { groupId } = params;

      if (groupId) return groupId;

      return (
        <Typography fontSize={14} fontWeight={700} color="common.black">
          {intl.formatMessage(messages.total)}
        </Typography>
      );
    },
  };

  return (
    <>
      <HeaderWithFilter
        dateRangeProps={{
          dateRangePickerProps: {
            value: makeLocalAppearUTC(dateRange),
            onChange: (newValue) => {
              const newValueUTC = localToUTC(
                newValue as DateRange<Date>,
                dateRange[1],
              );
              setDateRange(newValueUTC);
            },
            maxDate: today,
            closeOnSelect: false,
          },
          localizationProviderProps: {},
        }}
        name={intl.formatMessage(messages.plOverview)}
        from={dateRange[0]?.toISOString()}
        to={dateRange[1]?.toISOString()}
      />
      <SimpleDataGridPremium
        {...dataGridProps}
        loading={isLoading}
        classes={classes}
        disableSelectionOnClick
        autoHeight
        rowHeight={rowHeight}
        headerHeight={35}
        columns={columns}
        aggregationFunctions={{
          getTotalNetProfit,
          getTotalVolume,
          getTotalBets,
          getAggregationLabel,
          getTotalGrossProfit,
        }}
        initialState={{
          aggregation: {
            model: {
              [GRID_TREE_DATA_GROUPING_FIELD]: 'getAggregationLabel',
              netProfit: 'getTotalNetProfit',
              totalVolume: 'getTotalVolume',
              totalBets: 'getTotalBets',
              grossProfit: 'getTotalGrossProfit',
            },
          },
        }}
        experimentalFeatures={{ aggregation: true }}
        treeData
        getTreeDataPath={(row) => row.path}
      />
    </>
  );
};

export default ProfitLossReportTable;
