import { NormalizedPlayerData } from './normalizePlayerData';

interface CalculateTotalsPerPageProps {
  rows: readonly NormalizedPlayerData[];
  page: number;
  pageSize: number;
}

interface CalculateTotalsPerPageResult {
  totalExposure: string;
  totalBalance: string;
}

const calculateTotalsPerPage = (
  props: CalculateTotalsPerPageProps,
): CalculateTotalsPerPageResult => {
  const { rows, page, pageSize } = props;

  let currentPageRows = rows;

  if (page === 0) {
    const startIndex = page * pageSize;
    const endIndex = startIndex + pageSize;

    currentPageRows = rows.slice(startIndex, endIndex);
  }

  let totalExposure = 0;
  let totalBalance = 0;

  currentPageRows.forEach((row) => {
    if (row.status !== 'active') return;

    totalExposure += row.exposure;
    totalBalance += row.balance;
  });

  return {
    totalExposure: totalExposure.toFixed(2),
    totalBalance: totalBalance.toFixed(2),
  };
};

export default calculateTotalsPerPage;
