import { FC } from 'react';
import { FormattedDate } from 'react-intl';

interface FormattedDataProps {
  dateValue: string | Date;
}

const FormattedData: FC<FormattedDataProps> = ({ dateValue }) => {
  const date: Date = new Date(dateValue);

  return (
    <>
      <FormattedDate
        value={date}
        year="numeric"
        month="numeric"
        day="numeric"
      />
      {' - '}
      <FormattedDate value={date} hour="numeric" minute="numeric" />
    </>
  );
};

export default FormattedData;
