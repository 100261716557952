import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { FC, PropsWithChildren } from 'react';
import {
  AuthProvider,
  DatabaseProvider,
  FirestoreProvider,
  StorageProvider,
  useFirebaseApp,
} from 'reactfire';

const FirebaseSDKWrapper: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const firebase = useFirebaseApp();
  const firestoreInstance = getFirestore(firebase);
  const authInstance = getAuth(firebase);
  const databaseInstnace = getDatabase(firebase);
  const storageInstance = getStorage(firebase);

  return (
    <AuthProvider sdk={authInstance}>
      <DatabaseProvider sdk={databaseInstnace}>
        <FirestoreProvider sdk={firestoreInstance}>
          <StorageProvider sdk={storageInstance}>{children}</StorageProvider>
        </FirestoreProvider>
      </DatabaseProvider>
    </AuthProvider>
  );
};

export default FirebaseSDKWrapper;
