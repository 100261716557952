import { BackofficeUserRole } from '../../../types/supabase';

const getFirstPermissionWithRole = (
  roles: BackofficeUserRole[] | undefined,
): typeof foundPermissionWithRole => {
  const foundPermissionWithRole = roles?.find(
    (userPermission) => userPermission.role_id,
  );

  return foundPermissionWithRole;
};

export default getFirstPermissionWithRole;
