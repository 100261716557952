import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useUser } from 'reactfire';
import { RootState } from '../../../../types';
import useIsLoadingUser from '../../../common/hooks/useIsLoadingUser';
import DefaultLayout from '../../Unknown/DefaultLayout';
import EventOverview from '../EventOverview';
import usePagePath from './usePagePath';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';
import checkIsBrandOwner from '../../../common/checkIsBrandOwner';

export type PageParams = {
  brandId: string;
};

const EventOverviewPage: React.FC = () => {
  const path = usePagePath();

  const router = useHistory();

  const { brandId } = useParams<PageParams>();

  const { data: user } = useUser();

  const { roles, permissionMap, isAuth } = useSelector(
    (root: RootState) => root.user,
  );

  const isHaveAccess = permissionMap?.[brandId]?.event?.read;

  const isLoadingUser = useIsLoadingUser();

  const isAdmin = checkIsSystemAdmin({ roles });

  const isOwner = checkIsBrandOwner({ roles });

  useEffect(() => {
    if (isAdmin || isLoadingUser || isHaveAccess) return;

    router.push('../');
  }, [isAdmin, isAuth, isHaveAccess, isLoadingUser, router]);

  return (
    <DefaultLayout path={path}>
      {!!user?.uid && (
        <EventOverview
          isAdmin={isAdmin || isOwner}
          brandId={brandId}
          userId={user.uid}
        />
      )}
    </DefaultLayout>
  );
};

export default EventOverviewPage;
