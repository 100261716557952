import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from '@miyagami-com/lsx-ui-components';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC, useMemo } from 'react';
import { object, string } from 'yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { REG_EXP_PASSWORD } from '../../../common/constants';
import { Field, Password } from '../../../common/validationMessages';
import useStyles from './useStyles';
import { MultiFactorError } from 'firebase/auth';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';

type Props = {
  title?: string;
  subtitle?: string;
  isOpen: boolean;
  onClose: () => void | Promise<void>;
  onReAuth: (password: string) => void | Promise<void>;
};

type FormValues = {
  password: string;
};

const ReAuthUserModal: FC<Props> = ({
  title,
  subtitle,
  isOpen,
  onClose,
  onReAuth,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const validationSchema = useMemo(() => {
    return object().shape({
      password: string()
        .matches(REG_EXP_PASSWORD, Password.validationMessage)
        .required(Field.required)
        .trim(Field.trim),
    });
  }, []);

  const onSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    actions.setSubmitting(true);
    try {
      await onReAuth(values.password);
    } catch (e) {
      const totpAuthError = e as MultiFactorError;

      switch (totpAuthError.code) {
        case 'auth/wrong-password': {
          actions.setFieldError(
            'password',
            intl.formatMessage(messages.wrongPassword),
          );
          break;
        }
        default: {
          console.error(e);
          break;
        }
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  const renderReAuthForm = () => {
    return (
      <Formik<FormValues>
        initialValues={{
          password: '',
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, values, handleBlur, handleChange, isSubmitting }) => (
          <Form>
            <Box marginTop={2} display="flex" flexDirection="column" gap={1}>
              <TextField
                name="password"
                type="password"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                label={intl.formatMessage(messages.enterPassword)}
                fullWidth
              />
              {errors.password && (
                <Typography variant="caption" color="error">
                  {errors.password}
                </Typography>
              )}
            </Box>

            <Box display="flex" flexDirection="column" gap={2} marginTop={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={isSubmitting}
              >
                <Typography variant="button">
                  <FormattedMessage {...messages.confirm} />
                </Typography>
              </Button>

              <Button
                type="button"
                disabled={isSubmitting}
                variant="text"
                onClick={onClose}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1,
                  color: 'black',
                }}
                disableRipple
              >
                <ArrowBackIcon />
                <Typography variant="button">
                  <FormattedMessage {...messages.returnToSite} />
                </Typography>
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <Modal open={isOpen}>
      <Box className={classes.modalContainer}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="h4" textAlign="center">
            {title || intl.formatMessage(messages.totpAuthentication)}
          </Typography>
          <Typography variant="body1" textAlign="center">
            {subtitle || intl.formatMessage(messages.enterYourPassword)}
          </Typography>
        </Box>
        {renderReAuthForm()}
      </Box>
    </Modal>
  );
};

export default ReAuthUserModal;
