import { makeStyles } from '@mui/styles';
import { authBackground } from '../Images';
import { Theme } from '@mui/material';
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundImage: `url(${authBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  header: {
    zIndex: theme.zIndex.modal + 1,
    backgroundColor: 'white',
  },
  modal: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  modalContainer: {
    outline: 'none',
  },
}));

export default useStyles;
