interface ArrayByFieldParams<T> {
  array: T[] | null;
  field?: keyof T;
  getId?: (item: T) => string | number;
}

const arrayByField = <T>(
  params: ArrayByFieldParams<T>,
): Record<string | number, T[]> => {
  const { array, field, getId } = params;

  const getArrayKey = field ? (item: T) => item[field] : getId;

  if (!getArrayKey) return {};

  const fieldArray =
    array?.reduce<Record<string, T[]>>((map, arrayItem) => {
      const fieldValue = getArrayKey(arrayItem) as string;

      const mapItems = map?.[fieldValue] || [];

      const newMapItems = [...mapItems, arrayItem];

      return {
        ...map,
        [fieldValue]: newMapItems,
      };
    }, {}) || {};

  return fieldArray;
};

export default arrayByField;
