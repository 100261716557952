import { SelectProps } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import Select from '../../Unknown/Select';
import useSelectableRoles, { SelectRole } from './useSelectableRoles';
import messages from './messages';

const RoleSelect: React.FC<SelectProps> = (props) => {
  const selectableRoles = useSelectableRoles();

  const intl = useIntl();

  const roles = Object.keys(selectableRoles) as SelectRole[];

  const menuItems = roles.map((role) => {
    const { label } = selectableRoles[role];

    const menuItem = {
      value: role,
      label,
    };

    return menuItem;
  });

  const label = intl.formatMessage(messages.role);

  return <Select {...props} label={label} menuItems={menuItems} />;
};

export default RoleSelect;
