import { FC } from 'react';
import {
  Box,
  Grid,
  Typography,
  Divider,
  TextField,
  LoadingButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@miyagami-com/lsx-ui-components';
import { Formik, Form } from 'formik';
import { useIntl, FormattedMessage } from 'react-intl';

import messages from './messages';
import validationSchema from './validationSchema';
import useVerifyPermissions from '../../../common/hooks/useVerifyPermissions';

export type SenderList = {
  value?: string;
  label?: string;
};

export type OnSubmitValues = {
  sender: string;
  receiver: string;
  amount: number;
  note?: string;
};

export type OnSubmitActions = {
  resetForm: () => void;
};

type InputItems = {
  name: 'sender' | 'receiver' | 'amount' | 'note';
  type: 'text' | 'number' | 'select';
  label: string;
};

type InputProps = {
  [key in 'transfer' | 'withdraw']: {
    senderInputType: 'text' | 'select';
    receiverInputType: 'text' | 'select';
    disabledField: string;
  };
};

const inputProps: InputProps = {
  transfer: {
    senderInputType: 'select',
    receiverInputType: 'text',
    disabledField: 'receiver',
  },
  withdraw: {
    senderInputType: 'text',
    receiverInputType: 'select',
    disabledField: 'sender',
  },
};

interface TransactionCreationFormProps {
  type: 'transfer' | 'withdraw';
  initialValues: {
    sender: string;
    receiver: string;
    amount: number;
    note: string;
  };
  onCreateTransaction: (
    values: OnSubmitValues,
    actions: OnSubmitActions,
  ) => Promise<void>;
  senderList: SenderList[];
}

const TransactionCreationForm: FC<TransactionCreationFormProps> = ({
  type,
  initialValues,
  onCreateTransaction,
  senderList,
}) => {
  const intl = useIntl();
  const { isSuspended, isBrandSuspended } = useVerifyPermissions();

  const isDisabled = isSuspended || isBrandSuspended;

  const inputItems: InputItems[] = [
    {
      name: 'sender',
      type: inputProps[type].senderInputType,
      label: intl.formatMessage(messages.sender),
    },
    {
      name: 'receiver',
      type: inputProps[type].receiverInputType,
      label: intl.formatMessage(messages.receiver),
    },
    {
      name: 'amount',
      type: 'number',
      label: intl.formatMessage(messages.amount),
    },
    {
      name: 'note',
      type: 'text',
      label: intl.formatMessage(messages.note),
    },
  ];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onCreateTransaction}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleChange, handleBlur, errors, values, isSubmitting }) => {
        return (
          <Form>
            <Box p={3}>
              <Typography variant="h6">
                <FormattedMessage {...messages.transfer} />
              </Typography>
              <Grid container pt={3} spacing={3}>
                {inputItems.map(({ name, label, type: inputType }) => (
                  <Grid item xs={12} sm={6} key={name}>
                    {inputType === 'select' ? (
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id={`select-${name}`}>{label}</InputLabel>
                        <Select
                          fullWidth
                          labelId={`select-${name}`}
                          name={name}
                          value={values[name]}
                          onChange={handleChange}
                          label={label}
                          error={Boolean(errors[name])}
                          disabled={isDisabled}
                        >
                          {senderList.map(({ value, label: itemLabel }) => (
                            <MenuItem value={value} key={value}>
                              {itemLabel}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <TextField
                        fullWidth
                        label={label}
                        name={name}
                        variant="outlined"
                        type={inputType}
                        value={values[name]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors[name])}
                        disabled={
                          name === inputProps[type].disabledField || isDisabled
                        }
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Divider />
            <Box p={3} display="flex">
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                loading={isSubmitting}
                disabled={isDisabled}
              >
                <FormattedMessage {...messages.save} />
              </LoadingButton>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TransactionCreationForm;
