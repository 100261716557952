import { useQuery } from 'react-query';
import useSupabase from './useSupabase';

interface UseUserBalanceParams {
  userId: string;
}

const useUserBalance = (params: UseUserBalanceParams): typeof queryResult => {
  const { userId } = params;

  const supabase = useSupabase();

  const queryResult = useQuery(['userBalance', userId], async () => {
    const response = await supabase
      .from('user_stats')
      .select('balance')
      .eq('user_id', userId)
      .single();

    return response.data;
  });

  return queryResult;
};

export default useUserBalance;
