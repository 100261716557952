interface LayGrossProfitParams {
  size: number;
}

const layGrossProfit = (params: LayGrossProfitParams): number => {
  const grossProfit = params.size;

  return grossProfit;
};

export default layGrossProfit;
