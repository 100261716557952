import { useCallback } from 'react';
import { DEFAULT_AMOUNT } from '../../../common/constants';
import useTreeStyles, { colorSeparator } from './useTreeStyles';

export type ColorIndex =
  | 50
  | 100
  | 200
  | 300
  | 400
  | 500
  | 500
  | 600
  | 700
  | 800
  | 900;

const defaultTreeColorIndex = 50;

const colorIndexStep = 100;

const useRowTreeStyles = (): ((path: string) => string) => {
  const classes = useTreeStyles();

  const rowClassName = useCallback(
    (path: string) => {
      const pathLength = path?.split('/')?.length || DEFAULT_AMOUNT;

      const colorIndex = (
        !!pathLength && pathLength !== 1
          ? pathLength * colorIndexStep
          : defaultTreeColorIndex
      ) as ColorIndex;

      const greyColorClassName = `${colorSeparator}${colorIndex}`;

      const className = classes[greyColorClassName];

      return className;
    },
    [classes],
  );

  return rowClassName;
};

export default useRowTreeStyles;
