import { BackOfficeUser } from '../../../../types';

interface CreateParentPathParams {
  parentUser?: BackOfficeUser | null;
  parentId: string;
}

const createParentPath = (params: CreateParentPathParams): string => {
  const { parentId, parentUser } = params;

  const parentPath = parentUser?.parent_path
    ? `${parentUser.parent_path}/${parentId}`
    : parentId;

  return parentPath;
};

export default createParentPath;
