import { ExchangeConfigType } from '../../../../types';
import { SportLineRow } from './useColumns';

interface CreateExchangeConfigTypeBySportLineTypeParams {
  row: SportLineRow;
}

const createExchangeConfigTypeBySportLineType = (
  params: CreateExchangeConfigTypeBySportLineTypeParams,
): ExchangeConfigType => {
  const { row } = params;

  switch (row.type) {
    case 'COUNTRY': {
      return 'country';
    }
    case 'EVENT': {
      return 'event';
    }
    case 'EVENT_TYPE': {
      return 'eventType';
    }
    case 'MARKET': {
      return 'market';
    }
    case 'GROUP': {
      return 'competition';
    }
    case 'RACE': {
      return 'venue';
    }
    case 'ROOT': {
      return 'root';
    }
    default:
      throw new Error('Not found error');
  }
};

export default createExchangeConfigTypeBySportLineType;
