import { useSelector } from 'react-redux';
import { RootState } from '../../../types';
import checkIsBrandPendingByRoleClaims from '../checkIsBrandPendingByRoleClaims';
import useBrandId from './useBrandId';

const myAccountPath = 'my-account';

const abbreviatedMyAccountRoute = `/${myAccountPath}`;

const useMyAccountPath = (): string => {
  const { unParsedRoles: roles } = useSelector(
    (state: RootState) => state.user,
  );

  const brandId = useBrandId();

  const isSystemAdmin = roles?.includes('systemAdmin');

  const { isBrandPending } = checkIsBrandPendingByRoleClaims({ roles });

  if (isSystemAdmin || isBrandPending) return abbreviatedMyAccountRoute;

  const defaultMyAccountRole = `/b/${brandId}${abbreviatedMyAccountRoute}`;

  return defaultMyAccountRole;
};

export default useMyAccountPath;
