import { defineMessages } from 'react-intl';

export default defineMessages({
  id: {
    id: 'Event.EventOverviewList.id',
    defaultMessage: 'ID',
  },
  actions: {
    id: 'Event.EventOverviewList.actions',
    defaultMessage: 'Actions',
  },
  viewEvent: {
    id: 'Event.EventOverviewList.viewEvent',
    defaultMessage: 'View',
  },
  status: {
    id: 'Event.EventOverviewList.status',
    defaultMessage: 'Status',
  },
  eventTypeName: {
    id: 'Event.EventOverviewList.eventTypeName',
    defaultMessage: 'Event type',
  },
  marketName: {
    id: 'Event.EventOverviewList.marketName',
    defaultMessage: 'Market',
  },
  eventName: {
    id: 'Event.EventOverviewList.eventName',
    defaultMessage: 'Event',
  },
  competitionName: {
    id: 'Event.EventOverviewList.competitionName',
    defaultMessage: 'League',
  },
  totalStake: {
    id: 'Event.EventOverviewList.totalStake',
    defaultMessage: 'Total stake',
  },
  exposure: {
    id: 'Event.EventOverviewList.exposure',
    defaultMessage: 'Exposure',
  },
  openDate: {
    id: 'Event.EventOverviewList.openDate',
    defaultMessage: 'Date',
  },
});
