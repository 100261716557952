import { ExtendedBackOfficeUser } from '../../types';
import checkIsBrandPendingRole from './checkIsBrandPendingRole';
import { AGENTS_ROLES, SYSTEM_ADMIN_ROLE } from './constants';
import getRoleByBrandId from './getRoleByBrandId';

interface FilterUsersByRoleParams {
  users: ExtendedBackOfficeUser[];
  brandId: string;
}

type UserType = 'agents' | 'backofficeUsers';

const filterUsersByRole = (
  params: FilterUsersByRoleParams,
): typeof globalUsers => {
  const { users, brandId } = params;

  const initialUsers: Record<UserType, Set<ExtendedBackOfficeUser>> = {
    agents: new Set(),
    backofficeUsers: new Set(),
  };

  const usersSet = users.reduce((setMap, user) => {
    const roles = user.roles;

    if (!roles) return setMap;

    const isSystemAdmin = roles.some(
      (userRole) => userRole.role_id === SYSTEM_ADMIN_ROLE,
    );

    const isBrandPending = checkIsBrandPendingRole({ roles });

    const currentBrandRole = getRoleByBrandId({ brandId, roles });

    const isAgent =
      currentBrandRole?.value &&
      roles.some((role) => AGENTS_ROLES.includes(role.role_id));

    if (isAgent) {
      setMap.agents.add(user);
    } else if (isSystemAdmin || isBrandPending) {
      setMap.backofficeUsers.add(user);
    } else if (currentBrandRole?.value) {
      setMap.backofficeUsers.add(user);
    }

    return setMap;
  }, initialUsers);

  const globalUsers: Record<UserType, ExtendedBackOfficeUser[]> = {
    agents: Array.from(usersSet.agents),
    backofficeUsers: Array.from(usersSet.backofficeUsers),
  };

  return globalUsers;
};
export default filterUsersByRole;
