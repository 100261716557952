import { BackofficeUserRole } from '../../../types';
import { BRAND_PENDING_ROLE } from '../constants';

export type ParsedPermissions = {
  brandId: string | null;
  role: BackofficeUserRole | null;
};

const parsePermissions = (permissions?: string | null): ParsedPermissions => {
  if (!permissions) return { brandId: null, role: null };

  const isSystemAdmin = permissions.includes('systemAdmin');

  if (isSystemAdmin) return { brandId: null, role: 'systemAdmin' };

  const isBrandPending = permissions?.indexOf(BRAND_PENDING_ROLE) === 0;

  if (isBrandPending) {
    const [, , role] = permissions.split('/');

    return {
      role,
      brandId: null,
    } as ParsedPermissions;
  }

  const isBrandRole = permissions?.indexOf('brand/') === 0;

  if (!isBrandRole) return { brandId: null, role: null };

  const [, brandId, role] = permissions.split('/');

  return { brandId, role } as ParsedPermissions;
};

export default parsePermissions;
