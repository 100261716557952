import { FC, useContext } from 'react';
import {
  Box,
  Typography,
  TextField,
  LoadingButton,
  UIContext,
  Grid,
  Divider,
} from '@miyagami-com/lsx-ui-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form, FormikHelpers } from 'formik';
import { useFirebaseApp } from 'reactfire';

import { DEFAULT_REGION } from '../../../common/constants';

import messages from './messages';
import validationSchema from './validationSchema';
import { getFunctions, httpsCallable } from 'firebase/functions';

export type OnAddNote = () => Promise<void> | void;

interface UserNoteAdditionProps {
  brandId: string;
  userId: string;
  isDisabled: boolean;
  onAddNote?: OnAddNote;
  userType: 'player' | 'backoffice';
}

type UserNoteParams = {
  note: string;
  brandId: string;
  userId: string;
  userType: 'player' | 'backoffice';
};

type NoteFormValues = {
  note: string;
};

const UserNoteAddition: FC<UserNoteAdditionProps> = (props) => {
  const { brandId, userId, isDisabled, onAddNote, userType } = props;

  const intl = useIntl();
  const { setAlert } = useContext(UIContext);
  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const onSubmit = async (
    values: NoteFormValues,
    { resetForm, setSubmitting }: FormikHelpers<NoteFormValues>,
  ) => {
    setSubmitting(true);
    try {
      const addUserNoteFunction = httpsCallable(
        functions,
        'back-userNote-addUserNote',
      );

      const noteParams: UserNoteParams = {
        ...values,
        userId,
        brandId,
        userType,
      };

      await addUserNoteFunction(noteParams);

      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.successMessage),
      });

      if (onAddNote) {
        await onAddNote();
      }
    } catch (error) {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.errorMessage),
      });
    } finally {
      resetForm();
      setSubmitting(false);
    }
  };

  const initialValues: NoteFormValues = { note: '' };

  return (
    <Box p={3}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({
          handleBlur,
          errors,
          values,
          handleChange,
          isSubmitting,
          touched,
        }) => {
          const error = (name: keyof NoteFormValues) =>
            Boolean(errors[name] && touched[name]);

          return (
            <Form>
              <Grid container spacing={3}>
                <Grid container xs={12} pt={3} spacing={3}>
                  <Grid marginX={3} item xs={12}>
                    <Typography variant="h6">
                      <FormattedMessage {...messages.addNote} />
                    </Typography>
                  </Grid>
                  <Grid marginX={3} item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      label={intl.formatMessage(messages.note)}
                      name="note"
                      variant="outlined"
                      value={values.note}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={error('note')}
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    disabled={isDisabled}
                    loading={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    <FormattedMessage {...messages.save} />
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default UserNoteAddition;
