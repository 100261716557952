import { defineMessages } from 'react-intl';

export default defineMessages({
  system: {
    id: 'Brand.BrandDetailsPage.system',
    defaultMessage: 'System',
  },
  brandOverview: {
    id: 'Brand.BrandDetailsPage.brandOverview',
    defaultMessage: 'Brand overview',
  },
});
