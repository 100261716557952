import { UseQueryResult, useQuery } from 'react-query';
import { PublicPLReport, ProfitLossReportLevel } from '../../../../types';
import commonMessages from '../../../common/messages';
import useConvertPeriodToFromDate, {
  Period,
} from './useConvertPeriodToFromDate';
import useProfitLossReport from '../../../common/hooks/useProfitLossReport';
import { UIContext } from '@miyagami-com/lsx-ui-components';
import { useContext } from 'react';
import { useIntl } from 'react-intl';

interface UseProfitLossReportsParams {
  brandId: string;
  reportLevel: ProfitLossReportLevel | null;
  period: Period;
  userId: string | null;
  isEnabled: boolean;
}

const useProfitLossReports = (
  params: UseProfitLossReportsParams,
): UseQueryResult<PublicPLReport[] | null> => {
  const { brandId, reportLevel, userId, period, isEnabled } = params;

  const fromToByPeriod = useConvertPeriodToFromDate();

  const fetchReports = useProfitLossReport();

  const intl = useIntl();

  const { setAlert } = useContext(UIContext);

  const queryResult = useQuery(
    ['reportGraph', reportLevel, brandId, userId, period],
    async () => {
      if (!reportLevel) return null;

      const { from, to } = fromToByPeriod(period);

      const isSystemLevel = reportLevel === 'systemAdmin';

      const reportBrandId = isSystemLevel ? null : brandId;

      const reports = await fetchReports({
        brandId: reportBrandId,
        parentId: userId,
        from,
        to,
        reportLevel,
      });

      return reports;
    },
    {
      onError: () => {
        setAlert({
          message: intl.formatMessage(commonMessages.fetchErrorMessage),
          severity: 'error',
          show: true,
        });
      },
      enabled: isEnabled,
    },
  );

  return queryResult;
};

export default useProfitLossReports;
