import { defineMessages } from 'react-intl';

export default defineMessages({
  disable: {
    id: 'Unknown.MarketTypeSettings.disable',
    defaultMessage: 'Disable',
  },
  marketType: {
    id: 'Unknown.MarketTypeSettings.marketType',
    defaultMessage: 'Market Type',
  },
  errorUpdate: {
    id: 'Exchange.MarketTypeSettings.errorUpdate',
    defaultMessage: 'An error occurred while updating configuration',
  },
  successUpdate: {
    id: 'Exchange.MarketTypeSettings.successUpdate',
    defaultMessage:
      'Configuration successfully updated, updates will be applied within 1 hour',
  },
});
