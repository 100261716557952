import { PlayerWithStats } from '../../../../types/supabase';
import normalizePlayerData, {
  NormalizedPlayerData,
} from './normalizePlayerData';

interface NormalizePlayersParams {
  players?: PlayerWithStats[];
}

const normalizePlayers = ({
  players,
}: NormalizePlayersParams): NormalizedPlayerData[] => {
  if (!players) return [];

  const normalizedPlayers = players.map((player) =>
    normalizePlayerData({ player }),
  );

  return normalizedPlayers;
};

export default normalizePlayers;
