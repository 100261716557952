import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 320,
    [theme.breakpoints.up('md')]: {
      width: 400,
    },
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
  },
  otpCodeInput: {
    textAlign: 'center',
    fontSize: 20,
    border: `1px solid ${theme.palette.grey[400]}`,
    flex: 1,
  },
  factorButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export default useStyles;
