import { useSelector } from 'react-redux';
import HeaderWithFilter from '../../Unknown/HeaderWithFilter';
import { RootState } from '../../../../types';
import DateRangeSettings from '../../Unknown/DateRangeSettings';

export interface ExchangeSettingsDateRangeTabProps {
  isPlayerExchangePage?: boolean;
  playerId?: string;
}

const ExchangeSettingsDateRangeTab: React.FC<ExchangeSettingsDateRangeTabProps> =
  ({ isPlayerExchangePage, playerId }) => {
    const { brands } = useSelector((root: RootState) => root.globalData);

    return (
      <>
        {!isPlayerExchangePage && (
          <HeaderWithFilter name={null} brands={brands} />
        )}
        <DateRangeSettings playerId={playerId} />
      </>
    );
  };

export default ExchangeSettingsDateRangeTab;
