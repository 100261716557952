import { useIntl } from 'react-intl';
import { DEFAULT_CURRENCY } from '../constants';

interface UseCurrencyFormatNumberParams {
  value: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

const useCurrencyFormatNumber = (
  params: UseCurrencyFormatNumberParams,
): string => {
  const {
    value,
    maximumFractionDigits = 2,
    minimumFractionDigits = 2,
  } = params;

  const intl = useIntl();

  const formattedValue = intl.formatNumber(value, {
    style: 'currency',
    currency: DEFAULT_CURRENCY,
    minimumFractionDigits,
    maximumFractionDigits,
  });

  return formattedValue;
};

export default useCurrencyFormatNumber;
