import { useQuery, useQueryClient } from 'react-query';
import useSupabase from './useSupabase';
import { ExtendedBackOfficeUser } from '../../../types';
import { SELECT_EXTENDED_BACKOFFICE_USER } from '../constants';

interface UseBrandsOwnersParams {
  brandIds: string[];
  isSystemAdmin?: boolean;
  backOfficeUserId?: string;
}

const useBrandsOwners = (params: UseBrandsOwnersParams): typeof queryResult => {
  const { brandIds, isSystemAdmin, backOfficeUserId } = params;

  const supabase = useSupabase();

  const queryClient = useQueryClient();

  const queryResult = useQuery<ExtendedBackOfficeUser[] | null, Error>(
    ['brandsOwners', brandIds],
    async () => {
      if (isSystemAdmin) {
        const { data: users } = await supabase
          .from('backoffice_users')
          .select<
            typeof SELECT_EXTENDED_BACKOFFICE_USER,
            ExtendedBackOfficeUser
          >(SELECT_EXTENDED_BACKOFFICE_USER)
          .eq('backoffice_user_roles.role_id', 'owner')
          .in('backoffice_user_roles.brand_id', brandIds)
          .throwOnError();

        return users;
      } else {
        const { data: users } = await supabase
          .from('backoffice_users')
          .select<
            typeof SELECT_EXTENDED_BACKOFFICE_USER,
            ExtendedBackOfficeUser
          >(SELECT_EXTENDED_BACKOFFICE_USER)
          .eq('backoffice_user_roles.role_id', 'owner')
          .in('backoffice_user_roles.brand_id', brandIds)
          .or(
            `backoffice_user_detail.eq.${backOfficeUserId},parent_path.ilike.%${backOfficeUserId}%`,
          )
          .throwOnError();

        return users;
      }
    },
    {
      onSuccess: (users) => {
        if (!users?.length) return;

        users.forEach((user) => {
          queryClient.setQueryData('backofficeUser', user.id);
        });
      },
    },
  );

  return queryResult;
};

export default useBrandsOwners;
