import { useQuery } from 'react-query';
import useSupabase from './useSupabase';
import { BrandWidget } from '../../../types';

interface UseBrandWidgetsParams {
  widgetId: string;
  select?: string;
}

const useBrandWidget = <T extends BrandWidget>(
  params: UseBrandWidgetsParams,
): typeof queryResult => {
  const { widgetId, select = '*' } = params;

  const supabase = useSupabase();

  const queryResult = useQuery<T | null, Error>(
    ['brandWidget', widgetId],
    async () => {
      const response = await supabase
        .from('brand_widgets')
        .select<typeof select, T>(select)
        .eq('id', widgetId)
        .throwOnError()
        .maybeSingle();

      return response.data;
    },
  );

  return queryResult;
};

export default useBrandWidget;
