import { PostgrestResponse } from '@supabase/postgrest-js';
import createSupabaseClient from '../../../common/createSupabaseClient';

export interface Translation {
  translation_id: string;
  translation_category: string;
  translation_key: string;
  translation_message: string;
}

const getTranslationsByLanguageId = async ({
  languageId,
  offset,
  limit,
}: {
  languageId: string;
  offset: number;
  limit: number;
}): Promise<Translation[] | null> => {
  const supabaseClient = createSupabaseClient({});

  const response = (await supabaseClient
    .rpc(
      'translation__get_translations_by_language_id',
      {
        translations_language_id: languageId,
        translations_offset: offset,
        translations_limit: limit,
      },
      { count: 'exact' },
    )
    .select('*')
    .throwOnError()) as unknown as PostgrestResponse<Translation>;

  return response.data;
};

export default getTranslationsByLanguageId;
