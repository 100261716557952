import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { DataGridProps } from '@mui/x-data-grid';
import useBrandId from '../../../common/hooks/useBrandId';
import OverviewLayout from '../../Unknown/OverviewLayout';
import DefaultLayout from '../../Unknown/DefaultLayout';
import ProfitLossReportList, {
  ProfitLossReportQuery,
} from '../ProfitLossReportList';
import messages from './messages';
import { tomorrow, useReportPeriodMenuItems } from './reportPeriodData';
import useDataPicker from './useDataPicker';
import useTranslations from './useTranslations';
import useRows from './useRows';
import useParentHeaderGroupTitleByLevel from './useParentHeaderGroupTitleByLevel';
import { DateRange } from '@mui/x-date-pickers-pro';
import { localToUTC, makeLocalAppearUTC } from '../ProfitLossReportList/utils';
import ProfitLossManualCreation from '../ProfitLossManualCreation';
import { Box } from '@mui/material';
import useBackOfficeUserType from '../../Unknown/AuthenticatedRoot/useBackOfficeUserType';

const ProfitLossReportPage: React.FC = () => {
  const brandId = useBrandId();

  const userRole = useBackOfficeUserType();

  const isAdmin = userRole === 'systemAdmins';

  const {
    onDatePickerChange,
    changeReportDateRange,
    changeReportPeriod,
    dateRange,
    datePickerValues,
    routerQuery,
  } = useDataPicker();

  const { reportLevel, agentId } = useParams<ProfitLossReportQuery>();

  const { path, tableName } = useTranslations({
    brandId,
    agentId,
    reportLevel,
  });

  const { reportPeriod = 'today' } = routerQuery || {};

  const intl = useIntl();

  const reportPeriodMenuItems = useReportPeriodMenuItems();

  const { queryResult } = useRows({
    brandId,
    agentId: agentId || null,
    dateRange,
    reportLevel,
  });

  const { isLoading, data: defaultReports } = queryResult;

  const dataGridProps = useMemo<Omit<DataGridProps, 'columns'>>(() => {
    return {
      rows: defaultReports || [],
      loading: isLoading,
    };
  }, [isLoading, defaultReports]);

  const parentHeaderGroupTitleByLevel = useParentHeaderGroupTitleByLevel();

  const headerParentTitle = parentHeaderGroupTitleByLevel[reportLevel];

  const utcFrom =
    typeof dateRange[0] === 'string'
      ? new Date(dateRange[0]).toLocaleDateString()
      : dateRange[0]?.toLocaleDateString();

  const utcTo =
    typeof dateRange[1] === 'string'
      ? new Date(dateRange[1]).toLocaleDateString()
      : dateRange[1]?.toLocaleDateString();

  return (
    <DefaultLayout path={path}>
      <OverviewLayout
        headerProps={{
          name: tableName,
          from: utcFrom,
          to: utcTo,
          datePeriodSelectProps: {
            size: 'small',
            menuItems: reportPeriodMenuItems,
            onChange: changeReportPeriod,
            value: reportPeriod,
            label: intl.formatMessage(messages.period),
          },
          dateRangeProps: {
            localizationProviderProps: {
              localeText: {
                start: intl.formatMessage(messages.from),
                end: intl.formatMessage(messages.to),
              },
            },
            dateRangePickerProps: {
              value: makeLocalAppearUTC(datePickerValues),
              onChange: (newValue) => {
                const newValueUTC = localToUTC(
                  newValue as DateRange<Date>,
                  datePickerValues[1],
                );
                onDatePickerChange(newValueUTC);
              },
              onClose: changeReportDateRange,
              maxDate: tomorrow,
              closeOnSelect: false,
            },
          },
        }}
      >
        <ProfitLossReportList
          isLoading={isLoading}
          dataGridProps={dataGridProps}
          headerGroupTitle={headerParentTitle}
        />
        {isAdmin && (
          <Box marginTop={3}>
            <ProfitLossManualCreation />
          </Box>
        )}
      </OverviewLayout>
    </DefaultLayout>
  );
};

export default ProfitLossReportPage;
