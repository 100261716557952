import React from 'react';
import { CircularProgress } from '@mui/material';
import { Box } from '@miyagami-com/lsx-ui-components';

import { logo } from '../Images';

import useStyles from './useStyles';

export interface ImageUploadPreviewProps {
  imageSrc?: string;
  status?: 'loading' | 'error' | 'success';
}

const ImageUploadPreview: React.FC<ImageUploadPreviewProps> = ({
  imageSrc,
  status = 'success',
}) => {
  const classes = useStyles();

  return (
    <Box
      mr={3}
      minWidth={{ xs: 100, md: '229px' }}
      display="flex"
      alignItems="center"
    >
      {status !== 'loading' ? (
        <img src={imageSrc || logo} className={classes.image} />
      ) : (
        <Box display="flex" justifyContent="center" width={1}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default ImageUploadPreview;
