import { FC, useState, useContext, useMemo } from 'react';
import { CollapseBox, UIContext } from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';
import { useFirebaseApp } from 'reactfire';

import { DEFAULT_REGION } from '../../../common/constants';
import { ExtendedBackOfficeUser } from '../../../../types';

import UserPersonalInformationForm, {
  FunctionUserParams,
} from '../../User/UserPersonalInformationForm';

import messages from './messages';
import useExtendedBackofficeUser from '../../../common/hooks/useExtendedBackofficeUser';
import { useQueryClient } from 'react-query';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface AgentPersonalInformationProps {
  user: ExtendedBackOfficeUser;
  brandId?: string | null;
  role?: string;
  isDisabled: boolean;
}

const AgentPersonalInformation: FC<AgentPersonalInformationProps> = ({
  user,
  brandId,
  role,
  isDisabled,
}) => {
  const intl = useIntl();

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { setAlert } = useContext(UIContext);
  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const queryClient = useQueryClient();

  const { refetch } = useExtendedBackofficeUser({ userId: user.id });

  const onSubmit = async ({
    phoneNumber,
    ...otherValues
  }: FunctionUserParams) => {
    setIsButtonLoading(true);
    try {
      const updateUser = httpsCallable(functions, 'back-user-updateUser');

      const mfa = !!phoneNumber;

      await updateUser({
        ...otherValues,
        uid: user?.id,
        status: user?.status,
        role,
        mfa,
        isEnableFirewall: false,
        phoneNumber: phoneNumber || null,
      });

      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.successUpdateUser),
      });
    } catch (error) {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.errorUpdateUser),
      });
    } finally {
      setIsButtonLoading(false);
      refetch();
      queryClient.refetchQueries(['backofficeUser', user.id]);
    }
  };

  const initialValues: FunctionUserParams = useMemo(() => {
    return {
      name: user?.name || '',
      surname: user?.surname || '',
      email: user?.email || '',
      phoneNumber: user?.phone_number || '',
      country: user?.detail.country || '',
      city: user?.detail.city || '',
      street: user?.detail.street || '',
      zipCode: user?.detail.zip_code || '',
      brandId: brandId || '',
    };
  }, [
    brandId,
    user?.detail.city,
    user?.detail.country,
    user?.detail.street,
    user?.detail.zip_code,
    user?.email,
    user?.name,
    user?.phone_number,
    user?.surname,
  ]);

  return (
    <CollapseBox label={intl.formatMessage(messages.label)} defaultValue>
      <UserPersonalInformationForm
        isDisabled={isDisabled}
        onSubmit={onSubmit}
        isButtonLoading={isButtonLoading}
        initialValues={initialValues}
        isAgentOverviewPage
        isEdit
      />
    </CollapseBox>
  );
};

export default AgentPersonalInformation;
