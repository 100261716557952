import React from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import PriorityEditCell from './PriorityEditCell';

const renderPriorityEditCell: GridColDef['renderCell'] = (
  params,
): React.ReactNode => {
  return <PriorityEditCell {...params} />;
};

export default renderPriorityEditCell;
