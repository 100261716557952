import useSupabase from './useSupabase';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { UIContext } from '@miyagami-com/lsx-ui-components';
import { useContext } from 'react';

interface SetSystemAdminFlaggedParams {
  userId: string;
  isFlagged: boolean;
}

export const useSetSystemAdminFlagged = (
  params: SetSystemAdminFlaggedParams,
): UseMutationResult<void, unknown, void, unknown> => {
  const supabase = useSupabase();
  const queryClient = useQueryClient();
  const { setAlert } = useContext(UIContext);

  const { userId, isFlagged } = params;

  const mutation = useMutation(
    async () => {
      await supabase
        .from('user_limits')
        .update({ system_admin_flagged: isFlagged })
        .eq('id', userId)
        .select()
        .throwOnError();
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(['userLimit', userId]);
        setAlert({
          show: true,
          severity: 'success',
          message: 'User flagged',
        });
      },
      onError: () => {
        setAlert({
          show: true,
          severity: 'error',
          message: 'Error flagging user',
        });
      },
    },
  );

  return mutation;
};
