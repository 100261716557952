import { UseQueryResult, useQuery } from 'react-query';
import useSupabase from './useSupabase';
import { BrandDomain } from '../../../types';

interface UseBrandDomainParams {
  domainId: string;
  select?: string;
}

const useBrandDomain = <T extends BrandDomain>(
  params: UseBrandDomainParams,
): UseQueryResult<T | null> => {
  const { domainId, select = '*' } = params;

  const supabase = useSupabase();

  const queryString = useQuery(['brandDomain', domainId], async () => {
    const { data: domain } = await supabase
      .from('brand_domains')
      .select<typeof select, T>(select)
      .eq('id', domainId)
      .maybeSingle();

    return domain;
  });

  return queryString;
};

export default useBrandDomain;
