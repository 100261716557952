import { getFunctions, httpsCallable } from 'firebase/functions';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../constants';

interface ShareApiKeyParams {
  apiKeyId: string;
  addedBrandIds: string[];
  removedBrandIds: string[];
}

const useShareApiKey = (): ((params: ShareApiKeyParams) => Promise<void>) => {
  const firebase = useFirebaseApp();

  const functions = getFunctions(firebase, DEFAULT_REGION);

  const shareApiKey = async (params: ShareApiKeyParams) => {
    const shareApiKeyFunction = httpsCallable(
      functions,
      'back-brandApiKey-shareApiKey',
    );

    await shareApiKeyFunction(params);
  };

  return shareApiKey;
};

export default useShareApiKey;
