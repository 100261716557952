import { defineMessages } from 'react-intl';

export default defineMessages({
  system: {
    id: 'BackofficeUser.BackofficeUserOverviewPage.system',
    defaultMessage: 'System',
  },
  backofficeUsers: {
    id: 'BackofficeUser.BackofficeUserOverviewPage.backofficeUsers',
    defaultMessage: 'Backoffice users',
  },
  addUser: {
    id: 'BackofficeUser.BackofficeUserOverviewPage.addUser',
    defaultMessage: 'Add user',
  },
});
