import { DateRange } from '@mui/x-date-pickers-pro';
import { addDays, subDays, subWeeks, subMonths } from 'date-fns';
import messages from './messages';
import { useIntl } from 'react-intl';
import { SelectProps } from '../../Unknown/Select';
import { ProfitLossReportPeriod } from '../../../../types';
import { convertToUTCDate } from '../ProfitLossReportList/utils';

export type ReportPeriod =
  | Extract<
      ProfitLossReportPeriod,
      'today' | 'yesterday' | 'lastWeek' | 'lastMonth'
    >
  | 'custom';

const date = new Date();
export const defaultReportPeriod = 'today';
export const today = convertToUTCDate(date);
export const tomorrow = addDays(today, 1);
export const yesterday = subDays(today, 1);
export const lastWeekStart = subWeeks(tomorrow, 1);
export const lastMonthStart = subMonths(tomorrow, 1);

export const dateRangeByReportPeriod: Record<
  Exclude<ReportPeriod, 'custom'>,
  DateRange<Date>
> = {
  today: [today, tomorrow],
  yesterday: [yesterday, today],
  lastWeek: [lastWeekStart, tomorrow],
  lastMonth: [lastMonthStart, tomorrow],
};

export const useReportPeriodMenuItems = (): SelectProps['menuItems'] => {
  const intl = useIntl();

  return [
    {
      value: 'today',
      label: intl.formatMessage(messages.today),
    },
    {
      value: 'yesterday',
      label: intl.formatMessage(messages.yesterday),
    },
    {
      value: 'lastWeek',
      label: intl.formatMessage(messages.lastWeek),
    },
    {
      value: 'lastMonth',
      label: intl.formatMessage(messages.lastMonth),
    },
    {
      value: 'custom',
      label: intl.formatMessage(messages.custom),
    },
  ];
};
