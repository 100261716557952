import {
  GridRenderCellParams,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-premium';
import InputCell from '../../Unknown/InputCell';
import { ExchangeBetCurrencyParams } from '../../../../types';

const defaultRenderCell = (
  params:
    | GridRenderCellParams<number, ExchangeBetCurrencyParams>
    | GridRenderEditCellParams<number, ExchangeBetCurrencyParams>,
): JSX.Element => {
  const { api: dataGridApi, id, value, field } = params;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value);

    dataGridApi.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <InputCell
      type="number"
      InputProps={{
        inputProps: {
          min: 0,
        },
      }}
      onChange={onChange}
      value={value?.toString()?.replace(/^0+./, '')}
    />
  );
};

export default defaultRenderCell;
