import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { PlayerNote } from '../../../types/supabase';
import getPagination from '../getPagination';
import useSupabase from './useSupabase';

interface UsePlayerNotesParams {
  page: number;
  size: number;
  playerId?: string;
}

const usePlayerNotes = (params: UsePlayerNotesParams): typeof queryResult => {
  const { page, size, playerId } = params;

  const supabase = useSupabase();

  const playersRef = supabase.from('player_notes');

  const selectQuery = playersRef.select('*', { count: 'exact' });

  const getPlayers = useCallback(() => {
    const { from, to } = getPagination({ page, size });

    const rangeQuery = selectQuery.range(from, to).eq('player_id', playerId);

    return rangeQuery;
  }, [page, playerId, selectQuery, size]);

  const queryResult = useQuery<PlayerNote[], Error>({
    queryKey: ['player_notes', playerId],
    keepPreviousData: true,
    queryFn: async () => {
      const playersResponse = await getPlayers();

      const { data, error } = playersResponse;

      if (error) throw new Error(error.message);

      return data;
    },
    onError: (error) => error,
  });

  return queryResult;
};

export default usePlayerNotes;
