import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { RootState } from '../../../types';

type QueryParams = {
  brandId?: string;
};

const useBrandId = (): string => {
  const { brands } = useSelector((root: RootState) => root.globalData);

  const { brandId: queryBrandId } = useParams<QueryParams>();

  const brandId = useMemo(() => {
    if (!!queryBrandId) return queryBrandId;

    const [firstBrand] = brands;

    if (!firstBrand?.id) return '';

    return firstBrand.id;
  }, [brands, queryBrandId]);

  return brandId;
};

export default useBrandId;
