import { BackOfficeUser } from '../../types';
import { Player } from '../../types/supabase';

const isPlayer = (player: BackOfficeUser | Player): player is Player => {
  if ('player_detail' in player) {
    return true;
  }
  return false;
};

export default isPlayer;
