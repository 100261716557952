import React, { useEffect, useState, useRef } from 'react';
import { useClickAway } from 'react-use';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { useMediaQuery, useTheme } from '@mui/material';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';

import useStyles from './useStyles';

import {
  Box,
  Drawer,
  Typography,
  Divider,
} from '@miyagami-com/lsx-ui-components';

import messages from './messages';
import RenderTime from './RenderTime';
import RenderItems from './RenderItems';
import useBrandId from '../../../common/hooks/useBrandId';

const AppNavigation: React.FC = ({ children }) => {
  const [open, setOpen] = useState<boolean>(true);

  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const brandId = useBrandId();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [isMobile]);

  const ref = useRef(null);

  useClickAway(ref, () => {
    if (isMobile) {
      setOpen(false);
    }
  });

  return (
    <Box display="flex" width={1} overflow="auto">
      <Drawer
        variant="permanent"
        className={cn(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
          [classes.drawerMobile]: isMobile,
        })}
        classes={{
          paper: cn(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
            [classes.drawerPaperMobile]: isMobile,
          }),
        }}
      >
        <Box
          height={1}
          pt={{ xs: 1, md: 0 }}
          display="grid"
          alignContent="space-between"
          color="common.white"
          ref={open && isMobile ? ref : null}
        >
          <Box>
            <RenderTime open={open} />
            <Divider />
            <Box
              onClick={() => history.push('/')}
              className={cn(classes.onHoverBgColorGrey700, {
                [classes.bgColorGrey700]: history.location.pathname === '/',
              })}
              px={2}
              py={1.6}
              display="flex"
              alignItems="center"
              height="33px"
            >
              <Box pr={2} display="flex" alignItems="center">
                <MultilineChartIcon fontSize="small" />
              </Box>
              {open && (
                <Box>
                  <Typography>
                    <FormattedMessage {...messages.dashboard} />
                  </Typography>
                </Box>
              )}
            </Box>
            <Divider />
            <RenderItems brandId={brandId} open={open} />
          </Box>
          <Box>
            <Box
              onClick={() => setOpen(!open)}
              className={classes.onHoverBgColorGrey700}
              px={1.8}
              py={1.5}
              display="flex"
              alignItems="center"
            >
              <Box pr={1.5} display="flex" alignItems="center">
                {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </Box>
              {open && (
                <Box>
                  <Typography>
                    <FormattedMessage {...messages.hideMenu} />
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Drawer>
      <Box width={1} height={1} pl={{ xs: 7, sm: 0 }}>
        {children}
      </Box>
    </Box>
  );
};

export default AppNavigation;
