import React from 'react';
import { Menu, MenuItem } from '@miyagami-com/lsx-ui-components';
import { useHistory } from 'react-router-dom';

interface CompressedMenuItem {
  name: string;
  url: string;
}

interface CompressedMenuProps {
  menuItems: CompressedMenuItem[];
  anchorElement: HTMLElement | null;
  onClose: () => void;
  labeledBy: string;
}

const CompressedMenu: React.FC<CompressedMenuProps> = ({
  labeledBy,
  anchorElement,
  onClose,
  menuItems,
}) => {
  const history = useHistory();

  const isOpen = anchorElement?.id === labeledBy;

  return (
    <Menu
      aria-labelledby={labeledBy}
      anchorEl={anchorElement}
      open={isOpen}
      onClose={onClose}
    >
      {menuItems.map(({ name, url }) => {
        const onClick = () => {
          history.push(url);
        };

        const isSelected = history.location.pathname.includes(url);

        return (
          <MenuItem
            sx={{
              backgroundColor: isSelected ? 'grey.600' : 'inherit',
            }}
            key={url}
            onClick={onClick}
          >
            {name}
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default CompressedMenu;
