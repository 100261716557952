import React, { useMemo } from 'react';
import { Grid } from '@miyagami-com/lsx-ui-components';
import { useSelector } from 'react-redux';

import { RootState, Brand, ExtendedPlayer } from '../../../../types';

import getRoleData from '../../../common/getRoleData';

import NetworkInformation from '../../Network/NetworkInformation';

import getAllBrandUsers from '../../../common/getAllBrandUsers';

import usePlayerTree from '../../../common/hooks/usePlayerTree';
// import PlayerNoteOverview from '../../PlayerNote/PlayerNoteOverview';
import normalizeTree from '../../Network/NetworkTreeList/normalizeTree';
import NetworkLogs from '../../Network/NetworkLogs';

interface PlayerNetworkPageProps {
  brand: Brand;
  player: ExtendedPlayer;
}

const PlayerNetworkPage: React.FC<PlayerNetworkPageProps> = ({
  brand,
  player,
}) => {
  const { users } = useSelector((root: RootState) => root.globalData);

  const usersList = useMemo(
    () => getAllBrandUsers({ users, brandId: brand.id }),
    [brand.id, users],
  );

  const parentUser = useMemo(() => {
    const user = usersList.find(({ id }) => id === player?.parent_id);

    return user;
  }, [player?.parent_id, usersList]);

  const parentUserRole = useMemo(
    () =>
      getRoleData({
        roles: parentUser?.roles,
        brandId: brand.id,
      }),
    [brand.id, parentUser?.roles],
  );

  const treeParams = {
    brandId: brand.id,
    playerId: player.id,
    parentPath: player.parent_path,
  };

  const { isLoading, data: networkTree, refetch } = usePlayerTree(treeParams);

  const authUser = useSelector((root: RootState) => root.user);

  const isSystemAdmin = !!authUser.roles?.find(
    (role) => role.role_id === 'systemAdmin',
  );

  const isBrandOwner = !!authUser.roles?.find(
    (role) => role.role_id === 'owner',
  );

  const isAdminOrOwner = isSystemAdmin || isBrandOwner;

  const normalizedTree = useMemo(() => {
    const normalized = normalizeTree({
      rows: [networkTree || []],
      isSystemAdmin: isAdminOrOwner,
    });

    return normalized;
  }, [networkTree, isAdminOrOwner]);

  const onRefetchTree = async () => {
    await refetch();
  };

  return (
    <Grid container pt={4} spacing={4}>
      <Grid item xs={12}>
        <NetworkInformation
          parentUserData={{
            parentUser,
            parentUserRoleLabel: parentUserRole?.label || '',
          }}
          networkTree={normalizedTree}
          isLoading={isLoading}
          userId={player.id}
          playerId={player.id}
          parentId={player.parent_id || player.id}
          brand={brand}
          isPlayerPage={true}
          onRefetchTree={onRefetchTree}
        />
      </Grid>

      <Grid item xs={12}>
        <NetworkLogs userId={player.id} />
      </Grid>
      {/* <Grid item xs={12} md={12}>
        <NetworkTransfer
          isDisabled={isDisabled}
          playerId={player.id}
          parentUserId={player.parent_id}
          brandId={brand.id}
        />
      </Grid> */}
    </Grid>
  );
};

export default PlayerNetworkPage;
