import React, { useState } from 'react';
import { Box, Button, Menu, MenuItem } from '@miyagami-com/lsx-ui-components';

import { KeyboardArrowDownIcon } from '../Icons';

interface SelectCellProps {
  optionsList: {
    [key: string]: {
      label: string;
    };
  };
  onChangeValue: (option: string) => void;
  defaultValue: string;
  disabled?: boolean;
}

const SelectCell: React.FC<SelectCellProps> = ({
  optionsList,
  onChangeValue,
  defaultValue,
  disabled,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const valueList = Object.keys(optionsList);

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onChangeOption = (selectedValue: string) => {
    onChangeValue(selectedValue);
    onClose();
  };

  return (
    <Box>
      <Button
        id="header-button"
        onClick={onClick}
        variant="text"
        color="info"
        endIcon={<KeyboardArrowDownIcon />}
        disabled={disabled}
      >
        {defaultValue}
      </Button>
      <Menu
        aria-labelledby="header-button"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onClose}
      >
        {valueList.map((currentValue) => (
          <MenuItem
            key={currentValue}
            onClick={() => onChangeOption(currentValue)}
          >
            {optionsList[currentValue].label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default SelectCell;
