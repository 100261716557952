import { FC } from 'react';
import { DataGridProps } from '@mui/x-data-grid';
import DataGridTable from '../../Unknown/DataGridTable';
import useColumns from './useColumns';
import { DEFAULT_ROWS_PER_PAGE } from '../../../common/constants';

interface PlayerListParams {
  dataGridProps: Omit<DataGridProps, 'columns'>;
  onRefetch: () => void;
}

const PlayerListList: FC<PlayerListParams> = (props) => {
  const { dataGridProps, onRefetch } = props;

  const columns = useColumns({ onRefetch });

  return (
    <DataGridTable
      {...dataGridProps}
      tableKey={'player-overview'}
      rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE}
      disableSelectionOnClick={true}
      autoHeight={true}
      rowHeight={35}
      headerHeight={35}
      columns={columns}
      pagination
      hideFooterRowCount
      hideFooterSelectedRowCount
    />
  );
};

export default PlayerListList;
