import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useCookie } from 'react-use';
import { useUser } from 'reactfire';
import { SESSION_KEY } from '../../../common/constants';
import useApiClient from '../../../common/hooks/useApiClient';
import useSignIn from '../../../common/hooks/useSignIn';
import useSupabaseContext from '../../../common/hooks/useSupabaseContext';
import { loginUserAction, logoutUserAction } from '../../../store/user';
import useFirebaseAuth, {
  OnSuccessSignInCallback,
  SingInParams as FirebaseSignInParams,
  SignUpParams as FirebaseSignUpParams,
  OnSuccessSignUpCallback,
  SubmitPhoneNumberParams,
} from './useFirebaseAuth';
import useAuthenticationContext from '../../../common/hooks/useAuthenticationContext';

export interface SingInParams {
  email: string;
  password: string;
}

export interface SingUpParams {
  token: string;
  password: string;
}

export interface SubmitMfaCodeParams {
  verificationId: string | null;
  verificationCode: string;
  type: 'signIn' | 'signUp';
}

const useAuth = (): typeof authFunctions => {
  const dispatch = useDispatch();

  const {
    userPassword,
    setUserPassword,
    isWithMfa,
    setIsWithMfa,
    phoneNumber,
    setPhoneNumber,
  } = useAuthenticationContext();

  const [, , deleteCookie] = useCookie(SESSION_KEY);

  const { setAuth: setSupabaseAuthToken, removeAuth } = useSupabaseContext();

  const serverSignIn = useSignIn();

  const apiClient = useApiClient();

  const {
    signIn: firebaseSignIn,
    signUp: firebaseSignUp,
    signOut: firebaseSignOut,
    submitMfaCode: firebaseSubmitMfaCode,
    submitPhoneNumber: firebaseSubmitPhoneNumber,
  } = useFirebaseAuth();

  const firebaseUser = useUser();

  const signOut = useCallback(async () => {
    if (firebaseUser) {
      deleteCookie();

      await firebaseSignOut();

      await apiClient.get('/back-auth-logout');

      await dispatch(logoutUserAction());

      removeAuth();
    }
  }, [
    apiClient,
    deleteCookie,
    dispatch,
    firebaseSignOut,
    firebaseUser,
    removeAuth,
  ]);

  const history = useHistory();

  const onSuccessSignInCallback: OnSuccessSignInCallback = useCallback(
    async (params) => {
      const { tokenResult } = params;

      const { token: idToken } = tokenResult;

      const response = await serverSignIn({
        idToken,
        isFirstLogin: false,
      });

      if (!response) throw new Error('An error while trying auth');

      setSupabaseAuthToken(response.supabaseAccessToken);

      dispatch(loginUserAction(response.user));

      history.push('/');
    },
    [dispatch, history, serverSignIn, setSupabaseAuthToken],
  );

  const onSuccessSignUpCallback: OnSuccessSignUpCallback = useCallback(
    async (params) => {
      const { tokenResult, user } = params;

      const { token: idToken } = tokenResult;

      if (!user.email) throw new Error('Not found user email');

      const response = await serverSignIn({
        idToken,
        isFirstLogin: true,
      });

      if (!response) throw new Error('An error while trying auth');

      setSupabaseAuthToken(response.supabaseAccessToken);

      dispatch(loginUserAction(response.user));

      history.push('/');
    },
    [dispatch, history, serverSignIn, setSupabaseAuthToken],
  );

  const submitMfaCode = useCallback(
    async (params: SubmitMfaCodeParams) => {
      if (!userPassword) throw new Error('Password not found');

      const submitMfaCodeParams = {
        ...params,
        password: userPassword,
        onSuccessSignUpCallback,
        onSuccessSignInCallback,
      };

      await firebaseSubmitMfaCode(submitMfaCodeParams);
    },
    [
      firebaseSubmitMfaCode,
      onSuccessSignInCallback,
      onSuccessSignUpCallback,
      userPassword,
    ],
  );

  const signIn = useCallback(
    async (params: SingInParams) => {
      const { email, password } = params;
      setUserPassword(password);
      const defaultSignInParams = {
        email,
        password,
      };

      const firebsaeSignInParams: FirebaseSignInParams = {
        ...defaultSignInParams,
        onSuccessSignInCallback,
      };

      await firebaseSignIn(firebsaeSignInParams);
    },
    [firebaseSignIn, onSuccessSignInCallback, setUserPassword],
  );

  const onPhoneNumberCallback = useCallback(
    (phone: string) => {
      setPhoneNumber(phone);
    },
    [setPhoneNumber],
  );

  const onMfaCallback = useCallback(() => {
    setIsWithMfa(true);
  }, [setIsWithMfa]);

  const signUp = useCallback(
    async (params: SingUpParams) => {
      const { token, password } = params;
      setUserPassword(password);
      const firebaseSignUpParams: FirebaseSignUpParams = {
        token,
        password,
        onPhoneNumberCallback,
        onMfaCallback,
        onSuccessSignUpCallback,
      };

      await firebaseSignUp(firebaseSignUpParams);
    },
    [
      firebaseSignUp,
      onMfaCallback,
      onPhoneNumberCallback,
      onSuccessSignUpCallback,
      setUserPassword,
    ],
  );

  const submitPhoneNumber = useCallback(
    async (params: SubmitPhoneNumberParams) => {
      const submitPhoneResponse = await firebaseSubmitPhoneNumber(params);

      return submitPhoneResponse;
    },
    [firebaseSubmitPhoneNumber],
  );

  const authFunctions = {
    signIn,
    signUp,
    signOut,
    submitMfaCode,
    submitPhoneNumber,
    password: userPassword,
    setUserPassword,
    isWithMfa,
    phoneNumber,
    setIsWithMfa,
    setPhoneNumber,
  };

  return authFunctions;
};

export default useAuth;
