import React, { useMemo, useContext, useState } from 'react';
import {
  Box,
  CollapseBox,
  Divider,
  Grid,
  LoadingButton,
  Typography,
  UIContext,
} from '@miyagami-com/lsx-ui-components';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { ExchangeBet, ExchangeBetStatus, RootState } from '../../../../types';
import messages from './messages';
import { DEFAULT_REGION } from '../../../common/constants';
import { useFirebaseApp } from 'reactfire';
import ExchangeBetStatusSelect from './ExchangeBetStatusSelect';
import { QueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface ExchangeBetEditingProps {
  exchangeBet: ExchangeBet;
  brandId: string;
  refetchBet: () => void;
  refetchLog: () => void;
}

type ExchangeBetEditingUpdateParams = {
  status: ExchangeBetStatus;
};

const ExchangeBetEditing: React.FC<ExchangeBetEditingProps> = (props) => {
  const { exchangeBet, brandId, refetchBet, refetchLog } = props;

  const { status, id } = exchangeBet;

  const { roles } = useSelector((root: RootState) => root.user);

  const isSystemAdmin = checkIsSystemAdmin({ roles });

  const queryClient = new QueryClient();

  const { setAlert } = useContext(UIContext);
  const intl = useIntl();

  const initialValues: ExchangeBetEditingUpdateParams = useMemo(() => {
    return {
      status,
    };
  }, [status]);

  const firebase = useFirebaseApp();

  const functions = getFunctions(firebase, DEFAULT_REGION);

  const [isUpdatingVoided, setIsUpdatingVoided] = useState(false);

  const handleVoidBet = async (betId: string) => {
    const voidBetfairBet = httpsCallable(
      functions,
      'back-exchangeBet-voidBetfairBet',
    );

    const callUpdateExposure = httpsCallable(
      functions,
      'back-exchangeBet-callUpdateExposure',
    );

    try {
      setIsUpdatingVoided(true);

      await voidBetfairBet({ betId });
      await callUpdateExposure({
        playerId: exchangeBet?.player_id || '',
        brandId,
      });
    } catch (error) {
      console.log(error);

      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.errorVoidBet),
      });
    } finally {
      setIsUpdatingVoided(false);

      refetchBet();
      refetchLog();
    }
  };

  const onSubmitUpdate = async (
    { status: newStatus }: ExchangeBetEditingUpdateParams,
    { setSubmitting }: FormikHelpers<ExchangeBetEditingUpdateParams>,
  ) => {
    const updateExchangeBet = httpsCallable(
      functions,
      'back-exchangeBet-updateExchangeBet',
    );

    try {
      setSubmitting(true);

      const updateParams = {
        betId: id,
        brandId,
        payload: { status: newStatus },
      };

      await updateExchangeBet(updateParams);
    } catch (error) {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.errorUpdateExchangeBetStatus),
      });
    } finally {
      setSubmitting(false);
      queryClient.fetchQuery(['exchangeBet', id]);
    }
  };

  return (
    <>
      <CollapseBox
        defaultValue
        label={intl.formatMessage(messages.editBetLabel)}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitUpdate}
          enableReinitialize
        >
          {({
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
            isSubmitting,
          }) => {
            return (
              <Form>
                <Grid container rowSpacing={3} pt={3}>
                  <Grid paddingX={3} spacing={3} container item xs={12}>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        <FormattedMessage {...messages.editBetResultLabel} />
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <ExchangeBetStatusSelect
                        name="status"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.status}
                        label={intl.formatMessage(messages.editBetResultLabel)}
                        error={Boolean(errors.status && touched.status)}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid padding={3} item xs={12}>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      loading={isSubmitting}
                    >
                      <FormattedMessage {...messages.saveLabel} />
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </CollapseBox>
      {isSystemAdmin ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 5,
          }}
        >
          <LoadingButton
            variant="contained"
            color="primary"
            loading={isUpdatingVoided}
            onClick={() => handleVoidBet(exchangeBet.id)}
          >
            <FormattedMessage {...messages.voidBetLabel} />
          </LoadingButton>
        </Box>
      ) : null}
    </>
  );
};

export default ExchangeBetEditing;
