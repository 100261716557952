import { UseQueryResult, useQuery, useQueryClient } from 'react-query';
import useSupabase from './useSupabase';
import { BrandDomain } from '../../../types';

interface UseBrandDomainsParams {
  brandId: string;
  select?: string;
}

const useBrandDomains = <T extends BrandDomain>(
  params: UseBrandDomainsParams,
): UseQueryResult<T[] | null> => {
  const { brandId, select = '*' } = params;

  const supabase = useSupabase();

  const queryClient = useQueryClient();

  const queryString = useQuery(
    ['brandDomains', brandId],
    async () => {
      const { data: domains } = await supabase
        .from('brand_domains')
        .select<typeof select, T>(select)
        .eq('brand_id', brandId);

      return domains;
    },
    {
      onSuccess: (domains) => {
        domains?.forEach((domain) => {
          queryClient.setQueryData(['brandDomain', domain.id], domain);
        });
      },
    },
  );

  return queryString;
};

export default useBrandDomains;
