import { useQuery, useQueryClient } from 'react-query';
import useSupabase from './useSupabase';
import { Brand } from '../../../types';

interface UseBrandsParams {
  select?: string;
}

const useBrands = <T extends Brand>(
  params: UseBrandsParams,
): typeof queryResult => {
  const { select = '*' } = params;

  const supabase = useSupabase();

  const queryClient = useQueryClient();

  const queryResult = useQuery<T[] | null, Error>(
    ['brands'],
    async () => {
      const response = await supabase
        .from('brands')
        .select<typeof select, T>(select)
        .throwOnError();

      return response.data;
    },
    {
      onSuccess: (brands) => {
        if (!brands) return;

        brands.map((brand) => {
          queryClient.setQueryData('brand', brand.id);
        });
      },
    },
  );

  return queryResult;
};

export default useBrands;
