import { defineMessages } from 'react-intl';

export default defineMessages({
  general: {
    id: 'ProfitLoss.ProfitLossReportList.general',
    defaultMessage: 'General',
  },
  player: {
    id: 'ProfitLoss.ProfitLossReportList.player',
    defaultMessage: 'Player',
  },
  name: {
    id: 'ProfitLoss.ProfitLossReportList.name',
    defaultMessage: 'Name',
  },
  tumOver: {
    id: 'ProfitLoss.ProfitLossReportList.tumOver',
    defaultMessage: 'Tumover',
  },
  totalBets: {
    id: 'ProfitLoss.ProfitLossReportList.totalBets',
    defaultMessage: 'Total bets',
  },
  grossCommission: {
    id: 'ProfitLoss.ProfitLossReportList.grossCommission',
    defaultMessage: 'Gross commission',
  },
  payout: {
    id: 'ProfitLoss.ProfitLossReportList.payout',
    defaultMessage: 'Payout',
  },
  level: {
    id: 'ProfitLoss.ProfitLossReportList.level',
    defaultMessage: 'Level',
  },
  winLoss: {
    id: 'ProfitLoss.ProfitLossReportList.winLoss',
    defaultMessage: 'Win/Loss',
  },
  commission: {
    id: 'ProfitLoss.ProfitLossReportList.commission',
    defaultMessage: 'Comm.',
  },
  total: {
    id: 'ProfitLoss.ProfitLossReportList.total',
    defaultMessage: 'Total',
  },
  brand: {
    id: 'ProfitLoss.ProfitLossReportPage.brand',
    defaultMessage: 'Brand',
  },
  agent: {
    id: 'ProfitLoss.ProfitLossReportPage.agent',
    defaultMessage: 'Agent',
  },
  masterAgent: {
    id: 'ProfitLoss.ProfitLossReportPage.masterAgent',
    defaultMessage: 'Master agent',
  },
  superAgent: {
    id: 'ProfitLoss.ProfitLossReportPage.superAgent',
    defaultMessage: 'Super agent',
  },
  system: {
    id: 'ProfitLoss.ProfitLossReportPage.system',
    defaultMessage: 'System',
  },
  upLine: {
    id: 'ProfitLoss.ProfitLossReportPage.upLine',
    defaultMessage: 'Upline',
  },
});
