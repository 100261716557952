import { defineMessages } from 'react-intl';

export default defineMessages({
  internalization: {
    id: 'Language.LanguageListPage.internalization',
    defaultMessage: 'Internalization',
  },
  cms: {
    id: 'Language.LanguageListPage.cms',
    defaultMessage: 'CMS',
  },
  addLanguage: {
    id: 'Language.LanguageListPage.addLanguage',
    defaultMessage: 'Add language',
  },
  updateError: {
    id: 'Language.LanguageListPage.updateError',
    defaultMessage: 'An error occurred during the update',
  },
  successfullyUpdated: {
    id: 'Language.LanguageListPage.successfullyUpdated',
    defaultMessage: 'Successfully updated',
  },
});
