import { defineMessages } from 'react-intl';

export default defineMessages({
  accountLabel: {
    id: 'BrandOwner.BrandOwnerAccountTabs.accountLabel',
    defaultMessage: 'Account',
  },
  balanceLabel: {
    id: 'BrandOwner.BrandOwnerAccountTabs.balanceLabel',
    defaultMessage: 'Balance',
  },
});
