import * as Yup from 'yup';

import { Field, Date } from '../../../common/validationMessages';

const DATE_REG_EXP = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;

const validationSchema = Yup.object().shape({
  date: Yup.string()
    .matches(DATE_REG_EXP, Date.validationMessage)
    .required(Field.required)
    .trim(),
});

export default validationSchema;
