import { defineMessages } from 'react-intl';

export default defineMessages({
  id: {
    id: 'UserNote.UserNoteList.id',
    defaultMessage: 'ID',
  },
  date: {
    id: 'UserNote.UserNoteList.date',
    defaultMessage: 'Date',
  },
  time: {
    id: 'UserNote.UserNoteList.time',
    defaultMessage: 'Time',
  },
  author: {
    id: 'UserNote.UserNoteList.author',
    defaultMessage: 'Author',
  },
  note: {
    id: 'UserNote.UserNoteList.note',
    defaultMessage: 'Note',
  },
  action: {
    id: 'UserNote.UserNoteList.action',
    defaultMessage: 'Action',
  },
  delete: {
    id: 'UserNote.UserNoteList.delete',
    defaultMessage: 'Delete',
  },
  successMessage: {
    id: 'UserNote.UserNoteList.successMessage',
    defaultMessage: 'Note was successfully deleted.',
  },
  errorMessage: {
    id: 'UserNote.UserNoteList.errorMessage',
    defaultMessage: 'An error occurred while a note was being deleted.',
  },
  errorPaginationMessage: {
    id: 'UserNote.UserNoteList.errorPaginationMessage',
    defaultMessage: 'An error occurred while getting player notes.',
  },
});
