import { BackofficeUserRole } from '../../types/supabase';
import { PLAYER_ROLE, USER_ROLES } from './constants';

interface GetRoleDataParams {
  roles?: BackofficeUserRole[];
  brandId: string;
}

const allUserRole = [...USER_ROLES, PLAYER_ROLE];

type UserRole = typeof allUserRole[number];

const getRoleData = ({
  roles,
  brandId,
}: GetRoleDataParams): UserRole | undefined => {
  const isSystemAdmin = roles?.some((role) => role.role_id === 'systemAdmin');

  if (isSystemAdmin) {
    return allUserRole.find(({ value }) => value === 'systemAdmin');
  }

  const currentBrandParsedRole = roles?.find(
    ({ brand_id }) => brand_id === brandId,
  );

  const currentRole = currentBrandParsedRole?.role_id;

  const roleData = allUserRole.find(({ value }) => value === currentRole);

  return roleData;
};

export default getRoleData;
