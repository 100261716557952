import { useQuery, UseQueryResult } from 'react-query';
import useSupabaseContext from '../../../common/hooks/useSupabaseContext';
import { IBetRow, IExchangeBet } from '../ExchangeBetExportList/interfaces';
import { DEFAULT_FETCH_LIMIT_BETS } from '../../../common/constants';

interface IBetRowQuery {
  shouldFetch: boolean;
  brandId: string;
  createdAtStart: Date | null;
  createdAtEnd: Date | null;
  settledAtStart: Date | null;
  settledAtEnd: Date | null;
  sportType: string | null;
}

const useBetRows = (queryParams: IBetRowQuery): UseQueryResult<IBetRow[]> => {
  const {
    shouldFetch,
    brandId,
    createdAtEnd,
    createdAtStart,
    settledAtEnd,
    settledAtStart,
    sportType,
  } = queryParams;
  const { supabase: supabaseClient } = useSupabaseContext();

  return useQuery<IBetRow[]>({
    enabled: !!brandId && shouldFetch,
    queryKey: ['exchange_bets', brandId],
    queryFn: async () => {
      let offset = 0;
      const exchangeBets: IBetRow[] = [];
      let fetchedExchangeBets: IExchangeBet[];

      const request = supabaseClient
        .from('exchange_bets')
        .select(
          `
            id,
            market_name,
            status,
            created_at,
            settled_at,
            price,
            side,
            size,
            selection_name,
            betfair_exchange_bets!inner(
              event_type_name,
              event_name,
              competition_name,
              betfair_bet_size
            )
          `,
        )
        .eq('brand_id', brandId);

      if (createdAtStart) {
        request.gte('created_at', createdAtStart.toISOString());
      }

      if (createdAtEnd) {
        request.lte('created_at', createdAtEnd.toISOString());
      }

      if (settledAtStart) {
        request.gte('settled_at', settledAtStart.toISOString());
      }

      if (settledAtEnd) {
        request.lte('settled_at', settledAtEnd.toISOString());
      }

      if (sportType) {
        request.eq('betfair_exchange_bets.event_type_name', sportType);
      }

      do {
        const { data, error } = await request.range(
          offset,
          offset + DEFAULT_FETCH_LIMIT_BETS - 1,
        );

        if (error) {
          console.error(`Error fetching bet details: ${error.message}`);
        }

        const { data: exchangeBetSizes } = await supabaseClient
          .from('betfair_exchange_sizes')
          .select(`betfair_exchange_bet_id, size,type`)
          .in('betfair_exchange_bet_id', data?.map((row) => row.id) || []);
        const betfairSizeMap = exchangeBetSizes?.reduce<
          Map<string, Partial<IBetRow>>
        >((acc, row) => {
          let currentBet: Partial<IBetRow> | undefined = acc.get(
            row.betfair_exchange_bet_id,
          );

          if (!currentBet) {
            currentBet = { matched_size: 0, cancelled_size: 0 };
          }

          if (row.type === 'matched') {
            currentBet.matched_size = row.size;
          }

          if (row.type === 'cancelled') {
            currentBet.cancelled_size = row.size;
          }

          acc.set(row.betfair_exchange_bet_id, currentBet);
          return acc;
        }, new Map());

        fetchedExchangeBets = data as IExchangeBet[];

        // Normalize data
        const normalizedExchange: IBetRow[] = fetchedExchangeBets
          .map((row) => {
            const betfairSizeItem = betfairSizeMap?.get(row.id);
            const { betfair_exchange_bets } = row;

            const normalizedData: IBetRow = {
              id: row.id,
              market_name: row.market_name,
              status: row.status,
              price: row.price,
              side: row.side,
              size: row.size,
              selection_name: row.selection_name,
              created_at: new Date(row.created_at),
              settled_at: row.settled_at ? new Date(row.settled_at) : null,
              sport_type: betfair_exchange_bets?.event_type_name,
              event_name: betfair_exchange_bets?.event_name,
              competition_name: betfair_exchange_bets?.competition_name || null,
              cancelled_size: betfairSizeItem?.cancelled_size,
            };

            if (
              betfair_exchange_bets &&
              betfair_exchange_bets.betfair_bet_size > 0
            ) {
              normalizedData.matched_size =
                (row.size / betfair_exchange_bets.betfair_bet_size) *
                (betfairSizeItem?.matched_size || 0);
            }

            return normalizedData;
          })
          .filter((row): row is IBetRow => row !== undefined);
        exchangeBets.push(...normalizedExchange);
        offset += DEFAULT_FETCH_LIMIT_BETS;
      } while (
        fetchedExchangeBets &&
        fetchedExchangeBets.length >= DEFAULT_FETCH_LIMIT_BETS
      );

      return exchangeBets;
    },
  });
};

export default useBetRows;
