import * as Yup from 'yup';
import { REG_EXP_PASSWORD } from '../../../common/constants';
import { Password, Field } from '../../../common/validationMessages';

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(Field.required),
  password: Yup.string()
    .matches(REG_EXP_PASSWORD, Password.validationMessage)
    .trim(Field.trim)
    .required(Field.required),
});

export default validationSchema;
