import { UseQueryResult } from 'react-query';
import useBackofficeUser from './useBackofficeUser';
import { ExtendedBackOfficeUser } from '../../../types';
import { SELECT_EXTENDED_BACKOFFICE_USER } from '../constants';

interface UseExtendedBackofficeUserParams {
  userId: string;
}

const useExtendedBackofficeUser = (
  params: UseExtendedBackofficeUserParams,
): UseQueryResult<ExtendedBackOfficeUser | null> => {
  const { userId } = params;

  return useBackofficeUser<ExtendedBackOfficeUser>({
    userId,
    select: SELECT_EXTENDED_BACKOFFICE_USER,
  });
};

export default useExtendedBackofficeUser;
