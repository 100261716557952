import React, { useContext, useMemo } from 'react';
import {
  CollapseBox,
  Divider,
  LoadingButton,
  TextField,
  Typography,
  Grid,
  UIContext,
} from '@miyagami-com/lsx-ui-components';
import { Form, Formik, FormikHelpers } from 'formik';
import { useIntl, FormattedMessage } from 'react-intl';
import { useFirebaseApp } from 'reactfire';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../types';

import {
  DEFAULT_REGION,
  DEFAULT_THEME_SETTINGS,
} from '../../../common/constants';

import ColorPickerInput, {
  ColorPickerInputProps,
} from '../../Unknown/ColorPickerInput';
import Select from '../../Unknown/Select';

import messages from './messages';
import validationSchema from './validationSchema';
import fonts from './fonts';
import SelectBrand from '../../Unknown/SelectBrand';
import { SelectProps, TextFieldProps } from '@mui/material';
import useBrandTheme from '../../../common/hooks/useBrandTheme';
import LoadingBox from '../../Unknown/LoadingBox';
import usePlatformForceCache from '../../../common/hooks/usePlatformForceCache';
import { getFunctions, httpsCallable } from 'firebase/functions';

type ThemeSettingsFormValues = {
  brandId: string;
  slogan: string;
  mainColor: string | null;
  secondaryColor: string | null;
  mainFont: string;
  secondaryFont: string;
};

type ThemeSettingsFormKeys = keyof ThemeSettingsFormValues;

type RenderItem = {
  name: ThemeSettingsFormKeys;
  label: string;
  placeholder?: string;
  className?: string;
};

interface ThemeSettingsProps {
  brandId: string;
  isDisabled: boolean;
}

const ThemeSettings: React.FC<ThemeSettingsProps> = ({
  brandId,
  isDisabled,
}) => {
  const { data: brandTheme, isLoading, refetch } = useBrandTheme({ brandId });

  const { brands } = useSelector((root: RootState) => root.globalData);

  const intl = useIntl();

  const forceCache = usePlatformForceCache({ brandId });

  const { setAlert } = useContext(UIContext);

  const firebase = useFirebaseApp();

  const functions = getFunctions(firebase, DEFAULT_REGION);

  const initialValues: ThemeSettingsFormValues = useMemo(() => {
    return {
      brandId: brandTheme?.id || '',
      slogan: brandTheme?.slogan || '',
      mainColor: brandTheme?.main_color || DEFAULT_THEME_SETTINGS.mainColor,
      secondaryColor:
        brandTheme?.secondary_color || DEFAULT_THEME_SETTINGS.secondaryColor,
      mainFont: brandTheme?.main_font || DEFAULT_THEME_SETTINGS.mainFont,
      secondaryFont:
        brandTheme?.secondary_font || DEFAULT_THEME_SETTINGS.secondaryFont,
    };
  }, [
    brandTheme?.id,
    brandTheme?.main_color,
    brandTheme?.main_font,
    brandTheme?.secondary_color,
    brandTheme?.secondary_font,
    brandTheme?.slogan,
  ]);

  const onSubmit = async (
    themeSettings: ThemeSettingsFormValues,
    { setSubmitting }: FormikHelpers<ThemeSettingsFormValues>,
  ) => {
    const updateTheme = httpsCallable(functions, 'back-theme-updateTheme');
    setSubmitting(true);
    try {
      if (!brandTheme) {
        throw new Error(intl.formatMessage(messages.errorUnknownBrand));
      }

      await updateTheme({
        ...themeSettings,
        brandId,
      });

      await forceCache([{ type: 'brandContext' }]);

      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.successUpdateTheme),
      });
    } catch (error) {
      setAlert({
        show: true,
        severity: 'warning',
        message: intl.formatMessage(messages.errorUpdateTheme),
      });
    } finally {
      setSubmitting(false);
      refetch();
    }
  };

  const renderFonts: RenderItem[] = [
    {
      name: 'mainFont',
      label: intl.formatMessage(messages.mainThemeFont),
      placeholder: intl.formatMessage(messages.mainThemeFontExample),
    },
    {
      name: 'secondaryFont',
      label: intl.formatMessage(messages.bodyThemeFont),
      placeholder: intl.formatMessage(messages.bodyThemeFontExample),
    },
  ];

  const renderColorPickers: RenderItem[] = [
    {
      name: 'mainColor',
      label: intl.formatMessage(messages.mainThemeColor),
    },
    {
      name: 'secondaryColor',
      label: intl.formatMessage(messages.secondaryThemeColor),
    },
  ];

  if (isLoading) {
    return <LoadingBox />;
  }

  return (
    <CollapseBox
      defaultValue={true}
      label={intl.formatMessage(messages.themeSettings)}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          handleChange,
          handleBlur,
          errors,
          values,
          setFieldValue,
          touched,
          isSubmitting,
          isValid,
        }) => {
          const error = (name: ThemeSettingsFormKeys) =>
            Boolean(errors[name] && touched[name]);

          const touchedKeys = Object.keys(touched);

          const isTouched = touchedKeys.length !== 0;

          const getDefaultFieldParams = (
            name: ThemeSettingsFormKeys,
          ): typeof fieldValues => {
            const fieldValues = {
              name,
              variant: 'outlined',
              value: values[name],
              onChange: handleChange,
              onBlur: handleBlur,
              error: error(name),
              fullWidth: true,
              disabled: isDisabled && name !== 'brandId',
            };

            return fieldValues;
          };

          return (
            <Form>
              <Grid container rowSpacing={3}>
                <Grid paddingX={3} spacing={3} container item xs={12}>
                  <Grid marginTop={3} item xs={12}>
                    <Typography variant="h6">
                      <FormattedMessage {...messages.brand} />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SelectBrand
                      {...(getDefaultFieldParams('brandId') as SelectProps)}
                      value={values.brandId}
                      brands={brands}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={intl.formatMessage(messages.slogan)}
                      placeholder={intl.formatMessage(messages.sloganExample)}
                      {...(getDefaultFieldParams('slogan') as TextFieldProps)}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid paddingX={3} spacing={3} container item xs={12}>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      <FormattedMessage {...messages.colors} />
                    </Typography>
                  </Grid>
                  {renderColorPickers.map(({ name, ...otherRest }) => (
                    <Grid key={name} item xs={12} md={6}>
                      <ColorPickerInput
                        {...otherRest}
                        {...(getDefaultFieldParams(
                          name,
                        ) as ColorPickerInputProps)}
                        onChange={({ hex }) => setFieldValue(name, hex)}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid paddingX={3} spacing={3} container item xs={12}>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      <FormattedMessage {...messages.typography} />
                    </Typography>
                  </Grid>
                  {renderFonts.map(({ name, ...otherRest }) => (
                    <Grid key={name} item xs={12} md={6}>
                      <Select
                        {...otherRest}
                        {...(getDefaultFieldParams(name) as SelectProps)}
                        menuItems={fonts}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid padding={3} item xs={12}>
                  <LoadingButton
                    disabled={isDisabled || !isTouched || !isValid}
                    variant="contained"
                    color="primary"
                    type="submit"
                    loading={isSubmitting}
                  >
                    <FormattedMessage {...messages.save} />
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </CollapseBox>
  );
};

export default ThemeSettings;
