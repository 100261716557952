import { Grid } from '@miyagami-com/lsx-ui-components';
import React from 'react';
import { UseQueryResult } from 'react-query';
import EventDetailHeader from '../EventDetailHeader';
import { EventDetail as EventDetailParams } from '../../../common/hooks/useEventDetail';
import EventDetailExchangeBetLogs from '../EventDetailExchangeBetLogs';
// import EventDetailExposureMatrix from '../EventDetailExposureMatrix';
import LoadingBox from '../../Unknown/LoadingBox';

interface EventDetailProps {
  eventDetailQuery: UseQueryResult<EventDetailParams, Error>;
  brandId: string;
}

const EventDetail: React.FC<EventDetailProps> = (props) => {
  const { eventDetailQuery, brandId } = props;

  const { isLoading } = eventDetailQuery;

  // if (isError) return <div>Something went wrong</div>;

  if (isLoading) return <LoadingBox />;

  return (
    <Grid container spacing={4} p={4}>
      <Grid item xs={12}>
        <EventDetailHeader eventDetailQuery={eventDetailQuery} />
      </Grid>
      {/* <Grid item xs={6} md={6}>
        {eventDetailQuery.data?.exchange_bets && (
          <EventDetailExposureMatrix
            exchangeBets={eventDetailQuery.data.exchange_bets}
            brandId={brandId}
          />
        )}
      </Grid> */}

      <Grid item xs={12}>
        <EventDetailExchangeBetLogs
          brandId={brandId}
          eventDetailQuery={eventDetailQuery}
        />
      </Grid>
    </Grid>
  );
};

export default EventDetail;
