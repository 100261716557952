import { defineMessages } from 'react-intl';

export default defineMessages({
  accountLabel: {
    id: 'Agent.AgentDetailPage.accountLabel',
    defaultMessage: 'Account',
  },
  balanceLabel: {
    id: 'Agent.AgentDetailPage.balanceLabel',
    defaultMessage: 'Balance',
  },
  securityLabel: {
    id: 'Agent.AgentDetailPage.securityLabel',
    defaultMessage: 'Security',
  },
  networkLabel: {
    id: 'Agent.AgentDetailPage.networkLabel',
    defaultMessage: 'Network',
  },
  limitsLabel: {
    id: 'Agent.AgentDetailPage.limitsLabel',
    defaultMessage: 'Limits',
  },
  playersAndAgents: {
    id: 'Agent.AgentDetailPage.playersAndAgents',
    defaultMessage: 'Players & Agents',
  },
  agentOverview: {
    id: 'Agent.AgentDetailPage.agentOverview',
    defaultMessage: 'Agent overview',
  },
});
