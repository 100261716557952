import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Grid, Button, Typography } from '@miyagami-com/lsx-ui-components';

import messages from './messages';
import { BrandDomainConfig } from '../../../common/hooks/useBrandDomainConfig';

export type DNSConflict = {
  name: string;
  type: string;
  value: string;
};

type BrandDomainConfigConflictsProps = {
  brandDomainConflicts: DNSConflict[];
  getBrandDomainConfig: BrandDomainConfig;
  domainName: string;
  brandId: string;
  isVerificationLoading: boolean;
};

const BrandDomainConfigConflicts: React.FC<BrandDomainConfigConflictsProps> = (
  props,
) => {
  const {
    brandDomainConflicts,
    getBrandDomainConfig,
    isVerificationLoading,
    domainName,
    brandId,
  } = props;

  return (
    <>
      <Box pb={4}>
        <Typography fontWeight={700}>
          <FormattedMessage {...messages.conflictDescription} />
        </Typography>
      </Box>
      <Box pb={4}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Typography fontWeight={700}>
              <FormattedMessage {...messages.dnsRecordName} />
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography fontWeight={700}>
              <FormattedMessage {...messages.dnsRecordType} />
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography fontWeight={700}>
              <FormattedMessage {...messages.dnsRecordValue} />
            </Typography>
          </Grid>
          {brandDomainConflicts.map((conflict) => (
            <Grid item container key={conflict.value} xs={12} spacing={2}>
              <Grid item xs={5}>
                {conflict.name}
              </Grid>
              <Grid item xs={2}>
                {conflict.type}
              </Grid>
              <Grid item xs={5}>
                {conflict.value}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Button
        variant="contained"
        fullWidth
        onClick={() => getBrandDomainConfig({ domain: domainName, brandId })}
        disabled={isVerificationLoading}
      >
        <FormattedMessage {...messages.conflictButtonText} />
      </Button>
    </>
  );
};

export default BrandDomainConfigConflicts;
