import { useQuery, UseQueryResult } from 'react-query';
import { BetfairExchangeSize } from '../../../types';
import useSupabase from './useSupabase';

interface UseExchangeBetfairSizesParams {
  betId: string;
  select?: string;
}

const useExchangeBetfairSizes = <T extends BetfairExchangeSize>(
  params: UseExchangeBetfairSizesParams,
): UseQueryResult<T[], Error> => {
  const { betId, select = '*' } = params;

  const supabase = useSupabase();

  const exchangeBetsRef = supabase.from('betfair_exchange_sizes');

  const exchangeBetSelect = exchangeBetsRef
    .select<typeof select, T>(select)
    .eq('betfair_exchange_bet_id', betId);

  const getExchangeBet = () => {
    return exchangeBetSelect;
  };

  const queryResult = useQuery<T[], Error>({
    queryKey: ['exchangeBetfairSizes', betId],
    queryFn: async () => {
      const playerResponse = await getExchangeBet();

      const { data, error } = playerResponse;

      if (error) throw new Error(error.message);

      return data;
    },
    onError: (error) => error,
  });

  return queryResult;
};

export default useExchangeBetfairSizes;
