import React, { useContext, useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  Paper,
  Typography,
  UIContext,
} from '@miyagami-com/lsx-ui-components';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useAuth, useFirebaseApp } from 'reactfire';
import qs from 'qs';

import AuthenticationLayout from '../../Unknown/AuthenticationLayout';
import MfaForm, { SubmitCodeValues } from '../MfaForm';
import { loginUserAction } from '../../../store/user';
import confirmMfaCode from '../../../common/generalFunctions/confirmMfaCode';
import handleIfErrorCodeEqualsMfaRequired from '../../../common/generalFunctions/handleIfErrorCodeEqualsMfaRequired';
import Recaptcha from '../Recaptcha';
import useClientRect from '../../../common/hooks/useClientRect';

import ResetPasswordForm from './ResetPasswordForm';
import messages from './messages';
import useStyles from './useStyles';
import { DEFAULT_REGION } from '../../../common/constants';
import { FormikHelpers } from 'formik';
import useSignIn from '../../../common/hooks/useSignIn';
import {
  confirmPasswordReset,
  MultiFactorResolver,
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
  updatePassword,
} from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import useTotpAuthContext from '../Totp/useTotpAuthContext';

type SubmitPasswordValues = {
  password: string;
};

type SubmitEmailValues = {
  email: string;
};

type UserLastData = {
  email: string;
  password: string;
};

type QueryString = {
  oobCode: string;
};

const ResetPasswordPage: React.FC = () => {
  const [recaptcha, recaptchaRef] = useClientRect<HTMLDivElement>();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const auth = useAuth();
  const { search } = useLocation();

  const signIn = useSignIn();

  const { resolveMFA } = useTotpAuthContext();

  const [withMFA, setWithMFA] = useState<boolean>(false);
  const [userLastData, setUserLastData] = useState<null | UserLastData>(null);

  const [step, setStep] = useState<number>(0);
  const [verificationId, setVerificationId] = useState<string | null>(null);
  const [resolver, setResolver] = useState<MultiFactorResolver | null>(null);
  const { setAlert } = useContext(UIContext);

  const firebase = useFirebaseApp();

  const functions = getFunctions(firebase, DEFAULT_REGION);

  const queryParams = useMemo(() => {
    if (!search) return null;

    const parsedString = qs.parse(search) as QueryString;

    const { oobCode } = parsedString;

    return { oobCode };
  }, [search]);

  const isReset = useMemo(
    () => !!history.location.search,
    [history.location.search],
  );

  const onSubmitEmail = async (values: SubmitEmailValues) => {
    try {
      const forgotPassword = httpsCallable(
        functions,
        'back-auth-forgotPassword',
      );
      await forgotPassword({
        userEmail: values.email,
        url: window.location.origin,
      });
      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.successSendResetPassword),
      });
      history.push('/login');
    } catch (error) {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.errorResetPassword),
      });
    }
  };

  const onSubmitNewPassword = async (
    { password }: SubmitPasswordValues,
    { setSubmitting }: FormikHelpers<SubmitPasswordValues>,
  ) => {
    setSubmitting(true);
    try {
      if (!userLastData) {
        const oobCode = queryParams?.oobCode;

        if (!oobCode)
          throw new Error(intl.formatMessage(messages.notFoundOobCode));

        const accountEmail = await verifyPasswordResetCode(auth, oobCode);
        setUserLastData({ email: accountEmail, password });

        await confirmPasswordReset(auth, oobCode, password);
        await signInWithEmailAndPassword(auth, accountEmail, password);
      } else {
        await signInWithEmailAndPassword(
          auth,
          userLastData.email,
          userLastData.password,
        );

        setUserLastData({ email: userLastData.email, password });
      }

      const user = auth.currentUser;

      if (!user) throw new Error(intl.formatMessage(messages.authFailed));

      const idTokenResult = await user.getIdTokenResult();

      const response = await signIn({
        idToken: idTokenResult.token,
      });

      if (response?.user) {
        dispatch(loginUserAction(response?.user));
      }

      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.textDoneResetPassword),
      });
      history.push('/');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.code === 'auth/multi-factor-auth-required' && recaptcha) {
        const currentResolver = await resolveMFA(error);

        if (currentResolver?.factorId === 'phone') {
          handleIfErrorCodeEqualsMfaRequired({
            setWithMFA,
            setResolver,
            resolver: currentResolver?.mfaResolver,
            recaptcha,
            setVerificationId,
            setStep,
            setSubmitting,
          });
        }
      } else {
        setAlert({
          show: true,
          severity: 'error',
          message: error.toString(),
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  const onSubmitMfaCode = async (
    values: SubmitCodeValues,
    { setSubmitting }: FormikHelpers<SubmitCodeValues>,
  ) => {
    try {
      setSubmitting(true);

      if (resolver) {
        await confirmMfaCode({
          values,
          verificationId,
          resolver,
          dispatch,
          setAlert,
          history,
          setSubmitting,
          messageForResetPassword: intl.formatMessage(
            messages.textDoneResetPassword,
          ),
        });
      }

      if (userLastData) {
        const user = auth.currentUser;

        if (!user)
          throw new Error(intl.formatMessage(messages.errorSubmitNewPassword));

        await updatePassword(user, userLastData.password);
      }
    } catch (error) {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.errorSubmitNewPassword),
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <AuthenticationLayout>
      <Paper>
        <Box minWidth="252px">
          <Box
            py={2.3}
            px={3}
            className={classes.borderRadius}
            bgcolor="grey.900"
            color="common.white"
          >
            <Typography>
              {isReset ? (
                <FormattedMessage {...messages.resetPassword} />
              ) : (
                <FormattedMessage {...messages.forgotPassword} />
              )}
            </Typography>
          </Box>
          {step === 0 && (
            <>
              <Recaptcha recaptchaRef={recaptchaRef} recaptcha={recaptcha} />
              <ResetPasswordForm
                onSubmitEmail={onSubmitEmail}
                onSubmitNewPassword={onSubmitNewPassword}
                isReset={isReset}
              />
            </>
          )}
          {withMFA && step === 1 && (
            <MfaForm
              onBack={() => setStep(0)}
              onSubmitMfaCode={onSubmitMfaCode}
            />
          )}
        </Box>
      </Paper>
    </AuthenticationLayout>
  );
};

export default ResetPasswordPage;
