import { QueryClient, useQuery } from 'react-query';
import useSupabase from './useSupabase';
import { BrandWidget } from '../../../types';

interface UseBrandWidgetsParams {
  brandId: string;
  select?: string;
}

const useBrandWidgets = <T extends BrandWidget>(
  params: UseBrandWidgetsParams,
): typeof queryResult => {
  const { brandId, select = '*' } = params;

  const supabase = useSupabase();

  const queryClient = new QueryClient();

  const queryResult = useQuery<T[] | null, Error>(
    ['brandWidgets', brandId],
    async () => {
      const response = await supabase
        .from('brand_widgets')
        .select<typeof select, T>(select)
        .eq('brand_id', brandId)
        .throwOnError();

      return response.data;
    },
    {
      onSuccess: (widgets) => {
        widgets?.forEach((widget) => {
          queryClient.setQueryData('brandWidget', widget.id);
        });
      },
    },
  );

  return queryResult;
};

export default useBrandWidgets;
