import { FC, useMemo } from 'react';
import {
  DataGridProps,
  GridColumns,
  GridPreProcessEditCellProps,
} from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import DataGridTable from '../../Unknown/DataGridTable';
import messages from './messages';

const defaultRowsPerPageOptions = [5, 10, 15, 25, 50, 100];

const propertiesColumn = {
  editable: false,
  filterable: false,
};

interface LanguageTranslationsListProps {
  dataGridProps: Omit<DataGridProps, 'columns'>;
}

const LanguageTranslationsList: FC<LanguageTranslationsListProps> = ({
  dataGridProps,
}) => {
  const intl = useIntl();

  const columns: GridColumns = useMemo(
    () => [
      {
        ...propertiesColumn,
        field: 'id',
        hide: true,
        headerName: intl.formatMessage(messages.id),
        flex: 1,
      },
      {
        ...propertiesColumn,
        field: 'category',
        headerName: intl.formatMessage(messages.category),
        flex: 0.6,
      },
      {
        ...propertiesColumn,
        field: 'key',
        headerName: intl.formatMessage(messages.key),
        flex: 0.6,
      },
      {
        ...propertiesColumn,
        field: 'translation',
        editable: true,
        headerName: intl.formatMessage(messages.translation),
        flex: 1,
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
          const hasError = !params.props.value.length;
          return { ...params.props, error: hasError };
        },
      },
    ],
    [intl],
  );

  return (
    <DataGridTable
      {...dataGridProps}
      rowsPerPageOptions={defaultRowsPerPageOptions}
      disableSelectionOnClick={true}
      autoHeight={true}
      rowHeight={35}
      headerHeight={35}
      columns={columns}
      pagination
      hideFooterRowCount
      hideFooterSelectedRowCount
    />
  );
};

export default LanguageTranslationsList;
