import { defineMessages } from 'react-intl';

export default defineMessages({
  seeProfile: {
    id: 'Brand.BrandDetails.seeProfile',
    defaultMessage: 'See Profile',
  },
  save: {
    id: 'Brand.BrandDetails.save',
    defaultMessage: 'Save',
  },
  successfullyEdit: {
    id: 'Brand.BrandDetails.successfullyEdit',
    defaultMessage: 'User has successfully edited a brand {brandName}',
  },
  brandDetails: {
    id: 'Brand.BrandDetails.brandDetails',
    defaultMessage: 'Brand details',
  },
  name: {
    id: 'Brand.BrandDetails.name',
    defaultMessage: 'Name',
  },
  status: {
    id: 'Brand.BrandDetails.status',
    defaultMessage: 'Status',
  },
  liquidityMultiplier: {
    id: 'Brand.BrandDetails.liquidityMultiplier',
    defaultMessage: 'Liquidity Multiplier',
  },
  isRequiredLogin: {
    id: 'Brand.BrandDetails.isRequiredLogin',
    defaultMessage: 'Login Required',
  },
  brandOwner: {
    id: 'Brand.BrandDetails.brandOwner',
    defaultMessage: 'Brand Owner',
  },
  email: {
    id: 'Brand.BrandDetails.email',
    defaultMessage: 'E-mail: {email}',
  },
});
