import {
  ASIAN_HANDICAP_TEXT,
  GOAL_LINES_TEXT,
} from '../../../common/constants';
import convertExchangeBetToPL from './convertExchangeBetToPL';
import { ExtendedOutcomes, ProfitLossRow } from '../../../../types';

interface NormalizeOutcomesParams {
  outcomes: ExtendedOutcomes[];
}

const normalizeOutcomes = (
  params: NormalizeOutcomesParams,
): ProfitLossRow[] => {
  const { outcomes } = params;

  const profitLoss = outcomes.flatMap<ProfitLossRow>((outcome) => {
    const {
      id,
      created_at,
      total_bets,
      total_volume,
      net_profit,
      liability,
      gross_profit,
      exchange_bets,
    } = outcome;

    const [bet] = exchange_bets || [];

    const isAsianOrGoalLine =
      bet?.market_name === (ASIAN_HANDICAP_TEXT || GOAL_LINES_TEXT);

    const marketName = isAsianOrGoalLine
      ? `${bet?.market_name} ${bet?.handicap}`
      : `${bet?.market_name}`;

    const profitLossRow: ProfitLossRow = {
      id,
      path: [id],
      settledAt: created_at,
      eventTypeName: bet?.betfairExchangeBet?.event_type_name || null,
      eventName: bet?.betfairExchangeBet?.event_name || null,
      marketName: marketName || null,
      totalBets: total_bets,
      totalVolume: total_volume,
      netProfit: net_profit,
      grossProfit: gross_profit,
      liability,
      outcome: null,
      side: null,
      odds: null,
      status: null,
    };

    const childPL = exchange_bets.map((exchangeBet) => {
      return convertExchangeBetToPL({ exchangeBet, outcomeId: id });
    });

    return [...childPL, profitLossRow];
  });

  const filteredProfitLoss = profitLoss.filter((pl) => {
    const { marketName } = pl;

    return marketName && marketName !== 'undefined';
  });

  return filteredProfitLoss;
};

export default normalizeOutcomes;
