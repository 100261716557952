import { BackofficeUserRole, ExtendedBackOfficeUser } from '../../../../types';
import { USER_ROLE_MAP } from '../../../common/constants';
import { BackofficeUserWhitelist } from '../../../../types/supabase';

export type NormalizedBackofficeUser = {
  id: string;
  username: string;
  firstName: string;
  surname: string;
  email: string;
  telephone: string | null;
  type: string;
  isFirewallEnabled: boolean;
  status: string;
  brandId: string;
  whitelist: BackofficeUserWhitelist[];
  role_id?: string;
  created_at: string | null;
};

const sortedBackofficeUsersData = (
  backofficeUsers: ExtendedBackOfficeUser[],
  selectedBrandId: string,
): NormalizedBackofficeUser[] => {
  return backofficeUsers.map((user) => {
    const selectedRole = user.roles?.find(
      ({ brand_id }) => brand_id === selectedBrandId,
    );

    const roleLabel = selectedRole?.role_id
      ? USER_ROLE_MAP[selectedRole?.role_id as BackofficeUserRole]
      : '';

    const isFirewallEnabled = user.is_enable_firewall;

    const userParams: NormalizedBackofficeUser = {
      id: user.id,
      username: user.username,
      firstName: user.name,
      surname: user.surname,
      email: user.email,
      telephone: user.phone_number,
      type: roleLabel,
      isFirewallEnabled,
      status: user.status || '',
      brandId: selectedBrandId,
      whitelist: user.whitelist,
      role_id: selectedRole?.role_id,
      created_at: user?.created_at,
    };

    return userParams;
  });
};

export default sortedBackofficeUsersData;
