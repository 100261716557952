import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Box,
  Menu,
  Button,
  MenuItem,
  IconButton,
  Typography,
} from '@miyagami-com/lsx-ui-components';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { KeyboardArrowDownIcon, AccountCircleIcon } from '../Icons';
import { RootState } from '../../../../types';

import useMyAccountPath from '../../../common/hooks/useMyAccountPath';
import useBrandId from '../../../common/hooks/useBrandId';

import messages from './messages';
import useAuth from '../AuthContext/useAuth';
import clearFirestoreCache from '../../../common/clearFirestoreCache';
import getRoleDataByClaims from '../../../common/getRoleDataByClaims';
import useUserBalance from '../../../common/hooks/useUserBalance';

type HeaderMenuProps = {
  isMobile: boolean;
};

const HeaderMenu: React.FC<HeaderMenuProps> = ({ isMobile }) => {
  const history = useHistory();

  const { signOut } = useAuth();

  const myAccountPath = useMyAccountPath();

  const brandId = useBrandId();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const { email, unParsedRoles } = useSelector(
    (state: RootState) => state.user,
  );

  const { uid: userId } = useSelector((state: RootState) => state.user);

  const { data: userBalanceResponse } = useUserBalance({ userId });

  const userRoleData = useMemo(() => {
    const roleData = getRoleDataByClaims({
      roles: unParsedRoles || [],
      brandId: brandId || '',
    });

    return roleData;
  }, [brandId, unParsedRoles]);

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onClickMenu = (path: string) => {
    history.push(path);

    onClose();
  };

  const onLogout = useCallback(async () => {
    await signOut();
    clearFirestoreCache();

    history.push('/login');
  }, [history, signOut]);

  return (
    <>
      {isMobile ? (
        <IconButton onClick={onClick} color="secondary">
          <AccountCircleIcon fontSize="large" />
        </IconButton>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Button
            id="header-button"
            onClick={onClick}
            variant="text"
            color="primary"
            endIcon={<KeyboardArrowDownIcon />}
          >
            {email}
          </Button>
          {userRoleData && (
            <Box pt={1} display={'flex'} flexDirection={'row'} gap={5}>
              <Box>
                <Typography
                  color="white"
                  fontWeight="bold"
                  variant="subtitle2"
                  lineHeight={1}
                >
                  Balance: {userBalanceResponse?.balance || 0}
                </Typography>
              </Box>
              <Box>
                <Typography
                  color="white"
                  fontWeight="bold"
                  variant="subtitle2"
                  lineHeight={1}
                >
                  {userRoleData.label}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      )}
      <Menu
        id="header-menu"
        aria-labelledby="header-button"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onClose}
      >
        <MenuItem onClick={() => onClickMenu(myAccountPath)}>
          <FormattedMessage {...messages.myAccount} />
        </MenuItem>
        <MenuItem onClick={() => onClickMenu('/settings')}>
          <FormattedMessage {...messages.settings} />
        </MenuItem>
        <MenuItem onClick={onLogout}>
          <FormattedMessage {...messages.logout} />
        </MenuItem>
      </Menu>
    </>
  );
};

export default HeaderMenu;
