import React, { useMemo } from 'react';
import { Grid, TextField } from '@miyagami-com/lsx-ui-components';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { ManualRegistrationFormFields } from '.';

import messages from './messages';
import { ExtendedBackOfficeUser } from '../../../../types';
import getRoleData from '../../../common/getRoleData';
import { USER_ROLES, PLAYER_ROLE } from '../../../common/constants';

interface NetworkHeaderProps {
  parentUser: ExtendedBackOfficeUser | null;
  brandId?: string;
}

const NetworkHeader: React.FC<NetworkHeaderProps> = ({
  parentUser,
  brandId,
}) => {
  const intl = useIntl();

  const { values, handleBlur, errors } =
    useFormikContext<ManualRegistrationFormFields>();

  const parentUserRoleData = useMemo(() => {
    const roleData = getRoleData({
      roles: parentUser?.roles,
      brandId: brandId || '',
    });

    return roleData;
  }, [brandId, parentUser?.roles]);

  const currentRoleData = useMemo(() => {
    const roleData = [...USER_ROLES, PLAYER_ROLE].find(
      ({ value }) => value === values.roleId,
    );

    return roleData;
  }, [values.roleId]);

  return (
    <Grid item container xs={12} spacing={3}>
      <Grid item xs={12} sm={6} md={6}>
        <TextField
          fullWidth
          value={currentRoleData?.label || ''}
          onBlur={handleBlur}
          error={Boolean(errors.roleId)}
          variant="outlined"
          label={intl.formatMessage(messages.role)}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <TextField
          fullWidth
          label={
            parentUserRoleData?.label || intl.formatMessage(messages.nextRole)
          }
          value={parentUser?.username || ''}
          variant="outlined"
          disabled
        />
      </Grid>
    </Grid>
  );
};

export default NetworkHeader;
