import { useState } from 'react';
import { ProfitLossReportPeriod } from '../../../../types';
import { SelectChangeEvent } from '@mui/material/Select';
import { Period } from './useConvertPeriodToFromDate';

const defaultReportPeriod: ProfitLossReportPeriod = 'today';

const useReportPeriod = (): typeof returnParams => {
  const [reportPeriod, setReportPeriod] = useState<Period>(defaultReportPeriod);

  const changeReportPeriod = ({ target }: SelectChangeEvent<unknown>) => {
    setReportPeriod(target.value as Period);
  };

  const returnParams = {
    reportPeriod,
    changeReportPeriod,
  };

  return returnParams;
};

export default useReportPeriod;
