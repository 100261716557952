import React from 'react';
import { Box } from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';

import LogUserList from '../../Log/LogUserList';

import messages from './messages';

interface AgentSecurityPageProps {
  agentId: string;
}

const AgentSecurityPage: React.FC<AgentSecurityPageProps> = ({ agentId }) => {
  const intl = useIntl();

  return (
    <Box pt={4}>
      <LogUserList
        label={intl.formatMessage(messages.tableLabel)}
        type="user"
        userId={agentId}
        tableKey="agent-log-list"
      />
    </Box>
  );
};

export default AgentSecurityPage;
