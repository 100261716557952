import { Box, IconButton } from '@miyagami-com/lsx-ui-components';
import { CSVLink } from 'react-csv';
import React from 'react';
import TableSearch, { TableSearchProps } from '../TableSearch';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import AddIcon from '@mui/icons-material/Add';
import { Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import messages from './messages';
import { GridRowData } from '@mui/x-data-grid';

export type TableDefaultToolbarProps = Partial<TableSearchProps> & {
  withExport?: boolean;
  withSearch?: boolean;
  withAdd?: boolean;
  onClickAdd?: CallableFunction;
  tooltipAdd?: string;
  showRowsWithNoBrand?: boolean;
  disabled?: boolean;
  rows?: GridRowData[];
};

const TableDefaultToolbar: React.FC<TableDefaultToolbarProps> = ({
  defaultRows,
  setRows,
  withExport = true,
  withSearch = true,
  withAdd = true,
  onClickAdd,
  tooltipAdd,
  filteredKey,
  withBrandFilter,
  disabled,
  rows,
}) => {
  const intl = useIntl();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width={1}
    >
      {defaultRows && setRows && withSearch && (
        <TableSearch
          defaultRows={defaultRows}
          setRows={setRows}
          filteredKey={filteredKey}
          withBrandFilter={withBrandFilter}
        />
      )}
      {withExport && defaultRows && (
        <CSVLink filename="data.csv" data={rows || defaultRows} target="_blank">
          <Tooltip
            title={intl.formatMessage(messages.downloadAsCsv)}
            placement="top"
          >
            <IconButton disabled={disabled}>
              <SaveAltIcon />
            </IconButton>
          </Tooltip>
        </CSVLink>
      )}
      {withAdd && onClickAdd && (
        <Tooltip
          title={tooltipAdd ? tooltipAdd : intl.formatMessage(messages.add)}
          placement="top"
        >
          <IconButton onClick={() => onClickAdd()} disabled={disabled}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default TableDefaultToolbar;
