import { defineMessages } from 'react-intl';

export default defineMessages({
  sport: {
    id: 'Unknown.SportLineList.sport',
    defaultMessage: 'Sport',
  },
  fromLabel: {
    id: 'Unknown.SportLineList.fromLabel',
    defaultMessage: 'From',
  },
  toLabel: {
    id: 'Unknown.SportLineList.toLabel',
    defaultMessage: 'To',
  },
  status: {
    id: 'Unknown.SportLineList.status',
    defaultMessage: 'Status',
  },
  enable: {
    id: 'Unknown.SportLineList.enable',
    defaultMessage: 'Enable',
  },
  riskLevel: {
    id: 'Unknown.SportLineList.riskLevel',
    defaultMessage: 'Risk level',
  },
  priority: {
    id: 'Unknown.SportLineList.priority',
    defaultMessage: 'Priority',
  },
  errorMessage: {
    id: 'Unknown.SportLineList.errorMessage',
    defaultMessage: 'Error while trying to upload sportline.',
  },
  successUpdate: {
    id: 'Exchange.ExchangeSettingSportline.successUpdate',
    defaultMessage:
      'Exchange configuration successfully updated, updates will be applied within 1 hour',
  },
  errorUpdate: {
    id: 'Exchange.ExchangeSettingSportline.errorUpdate',
    defaultMessage: 'Error while trying update exchange config',
  },
  unknownType: {
    id: 'Exchange.ExchangeSettingSportline.unknownType',
    defaultMessage: 'Unknown exchange type',
  },
  loadMore: {
    id: 'Exchange.ExchangeSettingSportline.loadMore',
    defaultMessage: 'Load more',
  },
});
