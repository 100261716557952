import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { LoadingButtonProps } from '@mui/lab';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  Divider,
  FormControl,
  TextField,
  Typography,
  InputLabel,
  MenuItem,
  LoadingButton,
  Select,
} from '@miyagami-com/lsx-ui-components';

import { BrandWidgetStatus } from '../../../../types';

import { WIDGET_POSITIONS } from '../../../common/constants';

import { DropzoneFile } from '../../Unknown/ImageUpload';
import ImageUploadWithPreview from '../../Unknown/ImageUploadWithPreview';

import validationSchema from './validationSchema';
import messages from './messages';
import useStyles from './useStyles';

import { WidgetDetailsFormValues } from '.';
import useVerifyPermissions from '../../../common/hooks/useVerifyPermissions';
import useBrandId from '../../../common/hooks/useBrandId';

type WidgetPositionKey = keyof typeof WIDGET_POSITIONS;

const widgetPositionKeys = Object.keys(WIDGET_POSITIONS) as WidgetPositionKey[];
interface WidgetDetailsFormProps {
  imageLink: string;
  initialValues: WidgetDetailsFormValues;
  onSubmit: (
    values: WidgetDetailsFormValues,
    formikHelpers: FormikHelpers<WidgetDetailsFormValues>,
  ) => void;
  isLoadingUpdateStatus: boolean;
  onUpdateStatus: (status: Omit<BrandWidgetStatus, 'draft'>) => void;
  isPublished: boolean;
}

const WidgetDetailsForm: React.FC<WidgetDetailsFormProps> = ({
  imageLink,
  initialValues,
  isLoadingUpdateStatus,
  onSubmit,
  onUpdateStatus,
  isPublished,
}) => {
  const brandId = useBrandId();

  const { grantedPermissions, isSuspended, isBrandSuspended } =
    useVerifyPermissions([`brand/${brandId}/widget/update`]);

  const isUpdatePermissionGranted = !!grantedPermissions?.widget?.update;

  const isDisabled =
    !isUpdatePermissionGranted || isSuspended || isBrandSuspended;

  const intl = useIntl();
  const classes = useStyles();

  const defaultButtonProps: LoadingButtonProps = {
    className: classes.fieldSpace,
    variant: 'contained',
    color: 'primary',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        values,
        setFieldValue,
        handleChange,
        handleBlur,
        errors,
        touched,
        isSubmitting,
      }) => {
        const error = (name: keyof WidgetDetailsFormValues) =>
          Boolean(errors[name] && touched[name]);

        const handleUploadFile = (files: DropzoneFile[], name: string) => {
          setFieldValue(name, files[0]);
        };
        const widgetStatus = isPublished ? 'archive' : 'published';
        const updateStatusLabel = isPublished
          ? intl.formatMessage(messages.unpublish)
          : intl.formatMessage(messages.publish);

        const getFieldDefaultValues = (name: keyof WidgetDetailsFormValues) => {
          return {
            name,
            onBlur: handleBlur,
            onChange: handleChange,
            error: error(name),
            value: values[name],
            disabled: isDisabled,
          };
        };

        return (
          <Form>
            <Box p={3}>
              <Typography variant="h6">
                <FormattedMessage {...messages.widget} />
              </Typography>
              <Box py={1} display="flex">
                <TextField
                  className={classes.fieldSpace}
                  {...getFieldDefaultValues('title')}
                  label={intl.formatMessage(messages.title)}
                  placeholder={intl.formatMessage(messages.discount, {
                    value: 15,
                  })}
                />
                <TextField
                  className={classes.fieldSpace}
                  {...getFieldDefaultValues('description')}
                  label={intl.formatMessage(messages.description)}
                  placeholder={intl.formatMessage(messages.winMoreMoneyNow)}
                />
                <FormControl
                  variant="outlined"
                  className={classes.fieldSpace}
                  sx={{ minWidth: '235px' }}
                >
                  <InputLabel id="select-position">
                    <FormattedMessage {...messages.brand} />
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="select-position"
                    {...getFieldDefaultValues('position')}
                    label={intl.formatMessage(messages.brand)}
                  >
                    {widgetPositionKeys.map((position) => {
                      const { label } = WIDGET_POSITIONS[position];

                      return (
                        <MenuItem key={position} value={position}>
                          {label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Divider />
            <Box p={3}>
              <Typography variant="h6">
                <FormattedMessage {...messages.brand} />
              </Typography>
              <Box py={1} display="flex">
                <TextField
                  name="brandId"
                  label={intl.formatMessage(messages.brand)}
                  value={values.brandId}
                  className={classes.fieldSpace}
                  disabled={isDisabled}
                />
                <TextField
                  {...getFieldDefaultValues('link')}
                  label={intl.formatMessage(messages.link)}
                  placeholder={intl.formatMessage(messages.httpsLink)}
                />
              </Box>
            </Box>
            <Divider />
            <Box p={3}>
              <Typography variant="h6">
                <FormattedMessage {...messages.image} />
              </Typography>
              <Box display="flex">
                <ImageUploadWithPreview
                  imageSrc={values.image?.preview || imageLink}
                  imageUploadProps={{
                    dropzoneProps: {
                      accept: 'image/*',
                    },
                    textFieldProps: {
                      name: 'image',
                      translations: {
                        label: intl.formatMessage(messages.image),
                        placeholder: intl.formatMessage(messages.dragAnImage),
                      },
                      onChange: handleUploadFile,
                      value:
                        values.image?.name ||
                        intl.formatMessage(messages.dragAnImage),
                      multiline: true,
                      fullWidth: true,
                      rows: 3,
                      error: error('image'),
                      disabled: isDisabled,
                    },
                  }}
                />
              </Box>
            </Box>
            <Divider />
            <Box p={3} display="flex">
              <LoadingButton
                disabled={isDisabled}
                type="submit"
                loading={isSubmitting}
                {...defaultButtonProps}
              >
                <FormattedMessage {...messages.save} />
              </LoadingButton>
              <LoadingButton
                disabled={isDisabled}
                loading={isLoadingUpdateStatus}
                onClick={() => onUpdateStatus(widgetStatus)}
                {...defaultButtonProps}
              >
                {updateStatusLabel}
              </LoadingButton>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default WidgetDetailsForm;
