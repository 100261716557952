import { defineMessages } from 'react-intl';

export default defineMessages({
  cms: {
    id: 'Theme.ThemeSettingsPage.cms',
    defaultMessage: 'CMS',
  },
  themeSettings: {
    id: 'Theme.ThemeSettingsPage.themeSettings',
    defaultMessage: 'Theme settings',
  },
  selectBrandLabel: {
    id: 'Theme.ThemeSettingsPage.selectBrandLabel',
    defaultMessage: 'Brand',
  },
});
