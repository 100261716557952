import { defineMessages } from 'react-intl';

export default defineMessages({
  event: {
    id: 'Event.EventOverviewPage.event',
    defaultMessage: 'Event',
  },
  eventOverview: {
    id: 'Event.EventOverviewPage.eventOverview',
    defaultMessage: 'Event overview',
  },
});
