import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  ips: Yup.array().of(
    Yup.string()
      .matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, { excludeEmptyString: true })
      .test(
        'ip',
        'IP address value should be less or equal to 255',
        (value) => {
          if (value === undefined || value.trim() === '') return false;
          return value.split('.').find((i) => parseInt(i) > 255) === undefined;
        },
      ),
  ),
});

export default validationSchema;
