import { NavigationNode } from '../../../../types';

const createCountryGroup = (
  node: NavigationNode,
  child: NavigationNode,
): NavigationNode => ({
  type: 'COUNTRY',
  id: `${node.id}-${child.countryCode}`,
  name: child?.countryCode || '',
  config: null,
  children: [],
  countryCode: child.countryCode,
  marketCount: 0,
  startTime: null,
});

const addChildToGroup = (
  groups: Record<string, NavigationNode>,
  child: NavigationNode,
) => {
  if (!child.countryCode) return;

  groups[child.countryCode].children.push(child);
};

const groupChildrenByCountry = (node: NavigationNode): NavigationNode[] => {
  const countryGroups: Record<string, NavigationNode> = node.children.reduce(
    (groups: Record<string, NavigationNode>, child: NavigationNode) => {
      if ('countryCode' in child && child.countryCode) {
        if (!groups[child.countryCode]) {
          groups[child.countryCode] = createCountryGroup(node, child);
        }
        addChildToGroup(groups, child);
      }
      return groups;
    },
    {},
  );
  return Object.values(countryGroups);
};

export default groupChildrenByCountry;
