import React from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import DefaultLayout from '../../Unknown/DefaultLayout';
import { RootState } from '../../../../types';
import { DialogType } from '../../Unknown/BackofficeUserForm';

import DefaultBackofficeUserAccountPage from './DefaultBackofficeUserAccountPage';
import usePath from './usePath';
import checkIsBrandOwner from '../../../common/checkIsBrandOwner';
import BrandOwnerAccountDetail from '../../BrandOwner/BrandOwnerAccountDetail';
import useCheckIsAccountPath from '../../../common/hooks/useCheckIsAccountPath';
import useExtendedBackofficeUser from '../../../common/hooks/useExtendedBackofficeUser';

type QueryParams = {
  userId: string;
  brandId?: string;
};

interface BackofficeUserAccountPageProps {
  type?: DialogType;
}

const BackofficeUserAccountPage: React.FC<BackofficeUserAccountPageProps> = ({
  type = 'brandOwners',
}) => {
  const params: QueryParams = useParams();

  const { uid } = useSelector((state: RootState) => state.user);

  const isCurrentUser = useCheckIsAccountPath();

  const userId = isCurrentUser ? uid : params.userId;

  const { data: user } = useExtendedBackofficeUser({ userId });

  const isBrandOwner = checkIsBrandOwner({ roles: user?.roles });

  const path = usePath({ type, user });

  return (
    <DefaultLayout path={path}>
      {isBrandOwner ? (
        <BrandOwnerAccountDetail />
      ) : (
        <DefaultBackofficeUserAccountPage type={type} />
      )}
    </DefaultLayout>
  );
};

export default BackofficeUserAccountPage;
