import { defineMessages } from 'react-intl';

export default defineMessages({
  profit: {
    id: 'ProfitLoss.ProfitLossReportDashboardGraph.profit',
    defaultMessage: 'Profit',
  },
  loss: {
    id: 'ProfitLoss.ProfitLossReportDashboardGraph.loss',
    defaultMessage: 'Loss',
  },
});
