import { Box } from '@miyagami-com/lsx-ui-components';
import React from 'react';
import { useRouteMatch, Route, Switch, Redirect } from 'react-router-dom';
import { ExtendedBackOfficeUser } from '../../../../types';
import checkIsBrandPendingRole from '../../../common/checkIsBrandPendingRole';
import BrandOwnerTabs from './BrandOwnerTabs';
import useBrandOwnerTabList from './useBrandOwnerTabList';

interface BrandOwnerAccountTabsProps {
  user: ExtendedBackOfficeUser;
  brandId: string;
  setLastActivityTime: React.Dispatch<React.SetStateAction<number | null>>;
}

const BrandOwnerAccountTabs: React.FC<BrandOwnerAccountTabsProps> = (props) => {
  const { user, brandId, setLastActivityTime } = props;

  const { path, url } = useRouteMatch();

  const tabs = useBrandOwnerTabList({ user, brandId, setLastActivityTime });

  const { isBrandPending } = checkIsBrandPendingRole({
    roles: user?.roles,
  });

  return (
    <Box width={1}>
      <Box pl={4}>
        <BrandOwnerTabs isBrandPending={isBrandPending} tabs={tabs} />
      </Box>
      <Switch>
        {tabs.map(({ value, component }) => (
          <Route key={value} path={`${path}/${value}`}>
            <Box pl={4} width={1}>
              {component}
            </Box>
          </Route>
        ))}
        <Route path={`/b/${brandId}/brand-owners/:userId`}>
          <Redirect to={`${url}/${tabs[0].value}`} />
        </Route>
      </Switch>
    </Box>
  );
};

export default BrandOwnerAccountTabs;
