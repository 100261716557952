import { BackofficeUserRole } from '../../../types';
import { BRAND_PENDING_ROLE } from '../constants';

interface ParseRolesParams {
  roles: string[];
}

export interface ParsedRole {
  role: BackofficeUserRole;
  brandId: string | null;
}

const parseRoles = ({ roles }: ParseRolesParams): ParsedRole[] => {
  const parsedRoles = roles.map((role) => {
    const isSystemAdmin = role === 'systemAdmin';

    if (isSystemAdmin) return { role, brandId: null };

    const isBrandPending = role?.indexOf(BRAND_PENDING_ROLE) === 0;

    if (isBrandPending) {
      const [, , userRole] = role.split('/');

      return { role: userRole, brandId: null };
    }

    const isBrandRole = role.indexOf('brand/') === 0;

    if (!isBrandRole) return null;

    const [, brandId, userRole] = role.split('/');

    return { role: userRole, brandId };
  });

  const filteredRoles = parsedRoles.filter((role) => role) as ParsedRole[];

  return filteredRoles;
};

export default parseRoles;
