import * as Yup from 'yup';

import { IMAGE_TYPES } from '../../../common/constants';
import { Field } from '../../../common/validationMessages';

const validationSchema = Yup.object().shape({
  title: Yup.string().trim(Field.trim).required(Field.required),
  description: Yup.string().max(255).trim(Field.trim).required(Field.required),
  link: Yup.string().max(255).trim(Field.trim).required(Field.required),
  brandId: Yup.string().required(Field.required),
  position: Yup.string().required(Field.required),
  image: Yup.mixed().when('position', {
    is: 'externalRegistration',
    then: Yup.mixed().notRequired(),
    otherwise: Yup.mixed()
      .test('fileType', 'Unsupported File Format', (value) => {
        if (!value || !value.type) return false;

        return IMAGE_TYPES.includes(value?.type);
      })
      .required(Field.required),
  }),
});

export default validationSchema;
