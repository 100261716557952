import React from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { LoadingButton, Grid } from '@miyagami-com/lsx-ui-components';

import PasswordOutlinedInput from '../../Unknown/PasswordOutlinedInput';

import messages from './messages';
import useStyles from './useStyles';
import validationSchema from './validationSchema';
import { Password } from '../../../common/validationMessages';

export type AuthenticationFormValues = {
  password: string;
  passwordConfirm: string;
};

type AuthenticationFormProps = {
  onSubmit: (
    values: AuthenticationFormValues,
    { setSubmitting }: FormikHelpers<AuthenticationFormValues>,
  ) => void;
};

const initialValues = {
  password: '',
  passwordConfirm: '',
};

type RenderFields = typeof initialValues;

type Fields = {
  name: keyof RenderFields;
  label: string;
  validationText: string;
};

const AuthenticationForm: React.FC<AuthenticationFormProps> = ({
  onSubmit,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const renderPasswordFields: Fields[] = [
    {
      name: 'password',
      label: intl.formatMessage(messages.password),
      validationText: Password.validationMessage,
    },
    {
      name: 'passwordConfirm',
      label: intl.formatMessage(messages.passwordConfirm),
      validationText: Password.confirmMessage,
    },
  ];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        errors,
        handleBlur,
        values,
        touched,
        handleChange,
        isSubmitting,
      }) => {
        return (
          <Form>
            <Grid container spacing={3} paddingY={4} maxWidth="673px">
              <Grid item container xs={12} marginX={3} rowSpacing={2}>
                {renderPasswordFields.map(({ name, label, validationText }) => {
                  const error = errors[name];
                  const isTouched = touched[name];

                  return (
                    <Grid key={name} item xs={12}>
                      <PasswordOutlinedInput
                        value={values[name]}
                        validationText={validationText}
                        fullWidth
                        name={name}
                        label={label}
                        id={name}
                        error={Boolean(error && isTouched)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors[name]}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item container xs={12} marginX={3}>
                <LoadingButton
                  classes={{ root: classes.button }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  id="sign-in-button "
                  loading={isSubmitting}
                >
                  <FormattedMessage {...messages.login} />
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AuthenticationForm;
