import { PLAYER_ROLE, USER_ROLES } from './constants';
import mapPermissions from './permissions/mapPermissions';

interface GetRoleDataByClaimsParams {
  roles?: string[];
  brandId: string;
}

const allUserRole = [...USER_ROLES, PLAYER_ROLE];

type UserRole = typeof allUserRole[number];

const getRoleDataByClaims = (
  params: GetRoleDataByClaimsParams,
): UserRole | undefined => {
  const { roles, brandId } = params;

  const isSystemAdmin = roles?.includes('systemAdmin');

  if (isSystemAdmin) {
    return allUserRole.find(({ value }) => value === 'systemAdmin');
  }

  const mappedRoles = mapPermissions(roles);

  const currentBrandParsedRole = mappedRoles?.find(
    ({ brandId: roleBrandId }) => roleBrandId === brandId,
  );

  const currentRole = currentBrandParsedRole?.role;

  const roleData = allUserRole.find(({ value }) => value === currentRole);

  return roleData;
};

export default getRoleDataByClaims;
