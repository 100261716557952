interface GetEventIdParams {
  id: string;
}

const keySymbol = '?eid=';

const getEventId = (params: GetEventIdParams): string => {
  const { id } = params;

  const isIncludeEventId = id.includes(keySymbol);

  if (!isIncludeEventId) return id;

  const splitedRaceId = id.split(keySymbol);

  const [, eventTime] = splitedRaceId;

  const splitedEid = eventTime.split('.');

  const eventId = splitedEid?.[0];

  return eventId;
};

export default getEventId;
