import { defineMessages } from 'react-intl';

export default defineMessages({
  description: {
    id: 'BackofficeUser.AuthenticationPage.description',
    defaultMessage:
      'Confirm your email and set a password to log in to your account',
  },
  email: {
    id: 'BackofficeUser.AuthenticationPage.email',
    defaultMessage: 'Email',
  },
  password: {
    id: 'BackofficeUser.AuthenticationPage.password',
    defaultMessage: 'Password',
  },
  passwordConfirm: {
    id: 'BackofficeUser.AuthenticationPage.confirmPassword',
    defaultMessage: 'Password confirmation',
  },
  login: {
    id: 'BackofficeUser.AuthenticationPage.login',
    defaultMessage: 'Login',
  },
  descriptionForPhone: {
    id: 'BackofficeUser.AuthenticationPage.descriptionForPhone',
    defaultMessage: 'Enter your phone number for MFA',
  },
  descriptionForCode: {
    id: 'BackofficeUser.AuthenticationPage.descriptionForCode',
    defaultMessage: 'Enter your confirmation code',
  },
  authFailed: {
    id: 'BackofficeUser.AuthenticationPage.authFailed',
    defaultMessage: 'Auth failed, please contact with administrator',
  },
  logoutError: {
    id: 'BackofficeUser.AuthenticationPage.logoutError',
    defaultMessage: 'Some error while logout user.',
  },
});
