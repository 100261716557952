import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    id: 'ExchangeBet.ExchangeBetLogOverview.label',
    defaultMessage: 'Bet sizes',
  },
  idLabel: {
    id: 'ExchangeBet.ExchangeBetLogOverview.idLabel',
    defaultMessage: 'ID',
  },
  dateLabel: {
    id: 'ExchangeBet.ExchangeBetLogOverview.dateLabel',
    defaultMessage: 'Date',
  },
  timeLabel: {
    id: 'ExchangeBet.ExchangeBetLogOverview.timeLabel',
    defaultMessage: 'Time',
  },
  statusLabel: {
    id: 'ExchangeBet.ExchangeBetLogOverview.statusLabel',
    defaultMessage: 'Status',
  },
  sizeLabel: {
    id: 'ExchangeBet.ExchangeBetLogOverview.sizeLabel',
    defaultMessage: 'Betfair size',
  },
  actionsLabel: {
    id: 'ExchangeBet.ExchangeBetLogOverview.actionsLabel',
    defaultMessage: 'Actions',
  },
  editSizes: {
    id: 'ExchangeBet.ExchangeBetLogOverview.editSizes',
    defaultMessage: 'Edit',
  },
});
