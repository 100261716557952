import React, { Fragment, ReactNode, useContext, useMemo } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import {
  Box,
  Divider,
  Typography,
  TextField,
  LoadingButton,
  Grid,
  FormControlLabel,
  Checkbox,
  MenuItem,
  UIContext,
} from '@miyagami-com/lsx-ui-components';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { Brand, ExtendedBackOfficeUser, RootState } from '../../../../types';

import {
  RadioButtonUncheckedIcon,
  RadioButtonCheckedIcon,
} from '../../Unknown/Icons';

import useStyles from './useStyles';
import messages from './messages';
import { FieldKey, ManualRegistrationFormFields } from '.';
import NetworkHeader from './NetworkHeader';
import NetworkTree from '../../Network/NetworkTree';
import useBrandId from '../../../common/hooks/useBrandId';
import useVerifyPermissions from '../../../common/hooks/useVerifyPermissions';
import SelectableNetworkHeader from './SelectableNetworkHeader';
import PasswordOutlinedInput from '../../Unknown/PasswordOutlinedInput';
import checkIsBrandOwner from '../../../common/checkIsBrandOwner';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';
import useValidationSchema from './useValidationSchema';
import {
  DEFAULT_AMOUNT,
  MAX_COMMISSION_RATE,
  MAX_PT_RATE,
} from '../../../common/constants';
import generateAvailableOptions, {
  generateAvailableOptionsHalfs,
} from '../../../common/generateAvailableOptions';
import { TreeRow } from '../../Network/NetworkTreeList/createTreeRow';
import Big from 'big.js';

type FieldType = 'text' | 'password' | 'checkbox' | 'select';

type InputItem = {
  name: FieldKey;
  type?: FieldType;
  label: string;
  options?: { value?: number; label?: string }[];
  disabled?: boolean;
  value?: number | string | boolean;
};

type RenderDataForInputs = {
  isHide?: boolean;
  label?: ReactNode;
  inputItems: InputItem[];
};

type Totals = {
  minPositionTakingRate: number;
  availablePT: number;
  availableCommission: number;
  maxPositionTakingRate: number;
  extraPositionTakingRate: number;
  parentUserRow?: TreeRow;
};

export type ChosenTotals = {
  minPositionTakingRate: number;
  maxPositionTakingRate: number;
  positionTaking: number;
  extraPositionTaking: number;
  isExtraPositionTakingEnabled: boolean;
};

interface UserManualRegistrationFormProps {
  brand?: Brand;
  parentUser: ExtendedBackOfficeUser | null;
  initialValues: ManualRegistrationFormFields;
  onSubmit: (
    values: ManualRegistrationFormFields,
    formikHelpers: FormikHelpers<ManualRegistrationFormFields>,
  ) => Promise<void> | void;
  withPassword?: boolean;
  setTotals: React.Dispatch<React.SetStateAction<Totals>>;
  totals: Totals;
}

const defaultChosenTotals: ChosenTotals = {
  minPositionTakingRate: 0,
  maxPositionTakingRate: 0,
  positionTaking: 0,
  extraPositionTaking: 0,
  isExtraPositionTakingEnabled: false,
};

const UserManualRegistrationForm: React.FC<UserManualRegistrationFormProps> = ({
  brand,
  parentUser,
  initialValues,
  onSubmit,
  withPassword,
  totals,
  setTotals,
}) => {
  const [playerSelected, setPlayerSelected] = React.useState(false);
  const [isExtraPositionTakingEnabled, setExtraPositionTakingEnabled] =
    React.useState(false);
  const [chosenTotals, setChosenTotals] =
    React.useState<ChosenTotals>(defaultChosenTotals);

  const brandId = useBrandId();
  const classes = useStyles();
  const { setAlert } = useContext(UIContext);

  const intl = useIntl();
  const validationSchema = useValidationSchema({
    maxPT: MAX_PT_RATE,
    maxCommission: MAX_COMMISSION_RATE,
  });

  const { roles } = useSelector((root: RootState) => root.user);

  const isSystemAdmin = checkIsSystemAdmin({ roles });

  const isBrandOwner = checkIsBrandOwner({ roles });

  const { grantedPermissions, isSuspended, isBrandSuspended } =
    useVerifyPermissions([
      `brand/${brandId}/superAgent/create`,
      `brand/${brandId}/masterAgent/create`,
      `brand/${brandId}/agent/create`,
      `brand/${brandId}/player/create`,
    ]);

  const isCreatePermissionGranted =
    !!grantedPermissions.superAgent?.create ||
    !!grantedPermissions.masterAgent?.create ||
    !!grantedPermissions.agent?.create ||
    !!grantedPermissions.player?.create;

  const isDisabled =
    !isCreatePermissionGranted || isSuspended || isBrandSuspended;

  const { availableCommission, parentUserRow } = totals;

  const maxPT = parentUserRow?.maxPositionTakingRate || DEFAULT_AMOUNT;

  const availablePTOptions = generateAvailableOptionsHalfs(
    DEFAULT_AMOUNT,
    maxPT,
    0.005,
  );

  const availableCommissionOptions = generateAvailableOptions(
    DEFAULT_AMOUNT,
    availableCommission,
    0.001,
    3,
  );

  const availableMinPTOptions = generateAvailableOptionsHalfs(
    DEFAULT_AMOUNT,
    parentUserRow?.maxPositionTakingRate || DEFAULT_AMOUNT,
    0.005,
  );

  const availableMaxPTOptions = generateAvailableOptionsHalfs(
    DEFAULT_AMOUNT,
    maxPT,
    0.005,
  );

  const validateInputs = () => {
    const parentUserLevel = parentUserRow?.role;
    const isPlayerCreation = parentUserLevel === 'agent';
    const parentUserRowMinPT = parentUserRow?.minPositionTakingRate || 0;
    const parentUserRowMaxPT = parentUserRow?.maxPositionTakingRate || 0;

    const chosenPt =
      !isPlayerCreation && chosenTotals.isExtraPositionTakingEnabled
        ? chosenTotals.extraPositionTaking
        : chosenTotals.positionTaking;

    const isNotPlayerAndNotExtraPTEnabled =
      !isPlayerCreation && !isExtraPositionTakingEnabled;

    const maxDownLinePTExtraPT = Math.max(
      chosenTotals.maxPositionTakingRate,
      chosenTotals.extraPositionTaking,
    );

    const maxDownLinePTNormalPT = new Big(chosenTotals.positionTaking)
      .add(chosenTotals.maxPositionTakingRate)
      .toNumber();

    const downlineMaxPT = isNotPlayerAndNotExtraPTEnabled
      ? maxDownLinePTNormalPT
      : maxDownLinePTExtraPT;

    if (downlineMaxPT > parentUserRowMaxPT) {
      return 'Sum of PT and Max PT cannot be greater than upline Max PT';
    }

    if (
      !isPlayerCreation &&
      chosenTotals.minPositionTakingRate > chosenTotals.maxPositionTakingRate
    ) {
      return 'Min PT cannot be greater than Max PT';
    }

    if (
      isExtraPositionTakingEnabled &&
      chosenTotals.extraPositionTaking < chosenTotals.minPositionTakingRate
    ) {
      return 'Extra PT must be greater than Min PT';
    }

    const minDownLinePTExtraPT = Math.max(
      chosenTotals.minPositionTakingRate,
      chosenTotals.extraPositionTaking,
    );

    const minDownLinePTNormalPT = new Big(chosenTotals.positionTaking)
      .add(chosenTotals.minPositionTakingRate)
      .toNumber();

    const downlineMinPT = isNotPlayerAndNotExtraPTEnabled
      ? minDownLinePTNormalPT
      : minDownLinePTExtraPT;

    if (!isPlayerCreation && downlineMinPT < parentUserRowMinPT) {
      return 'Sum of Min PT and PT cannot be less than upline Min PT';
    }

    if (chosenPt > parentUserRowMaxPT) {
      return 'PT cannot be greater than upline Max PT';
    }

    if (
      !isPlayerCreation &&
      chosenTotals.maxPositionTakingRate > parentUserRowMaxPT
    ) {
      return 'Max PT cannot be greater than upline Max PT';
    }

    if (isPlayerCreation && chosenTotals.positionTaking < parentUserRowMinPT) {
      return 'PT cannot be less than upline Min PT';
    }

    if (isPlayerCreation && chosenTotals.positionTaking > parentUserRowMaxPT) {
      return 'PT cannot be greater than upline Max PT';
    }

    return true;
  };

  const renderDataForInputs: RenderDataForInputs[] = useMemo(() => {
    return [
      {
        label: <FormattedMessage {...messages.personal} />,
        inputItems: [
          {
            name: 'name',
            label: intl.formatMessage(messages.name),
            type: 'text',
          },
          {
            name: 'surname',
            label: intl.formatMessage(messages.surname),
            type: 'text',
          },
          {
            name: 'email',
            label: intl.formatMessage(messages.email),
            type: 'text',
          },
          {
            name: 'authUsername',
            label: intl.formatMessage(messages.authUsername),
            type: 'text',
          },
          // {
          //   name: 'phoneNumber',
          //   label: intl.formatMessage(messages.phoneNumber),
          // },
        ],
      },
      {
        label: <FormattedMessage {...messages.commissionAndPT} />,
        inputItems: [
          {
            name: 'commissionRate',
            type: 'select',
            label: intl.formatMessage(messages.userCommission),
            options: availableCommissionOptions,
            defaultValue: availableCommissionOptions[0].value,
          },
          {
            name: 'minPositionTakingRate',
            type: 'select',
            label: intl.formatMessage(messages.minUserPT),
            options: availableMinPTOptions,
            defaultValue: availableMinPTOptions?.[0]?.value || 0,
          },
          {
            name: 'maxPositionTakingRate',
            type: 'select',
            label: intl.formatMessage(messages.maxUserPT),
            options: availableMaxPTOptions,
            defaultValue: availableMaxPTOptions?.[0]?.value || 0,
          },
          {
            name: 'positionTakingRate',
            type: 'select',
            label: intl.formatMessage(messages.userPT),
            options: availablePTOptions,
            // defaultValue: availablePTOptions[0,
            disabled: isExtraPositionTakingEnabled,
          },
          // {
          //   name: 'isExtraPositionTakingEnabled',
          //   type: 'checkbox',
          //   label: intl.formatMessage(messages.extraPositionTakingCheckbox),
          // },
          // {
          //   name: 'extraPositionTakingRate',
          //   type: 'select',
          //   label: intl.formatMessage(messages.extraPositionTaking),
          //   options: availablePTOptions,
          //   defaultValue: availablePTOptions?.[0]?.value || 0,
          //   disabled: !isExtraPositionTakingEnabled,
          // },
        ],
      },
      {
        isHide: !withPassword,
        label: <FormattedMessage {...messages.activateDirectlyTitle} />,
        inputItems: [
          {
            name: 'isActivate',
            type: 'checkbox',
            label: intl.formatMessage(messages.activate),
          },
          {
            name: 'password',
            type: 'password',
            label: intl.formatMessage(messages.password),
          },
        ],
      },
    ];
  }, [
    intl,
    withPassword,
    availablePTOptions,
    availableCommissionOptions,
    availableMinPTOptions,
    availableMaxPTOptions,
    isExtraPositionTakingEnabled,
  ]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(fields, helpers) => {
        const validationResult = validateInputs();

        if (typeof validationResult === 'string') {
          setAlert({
            show: true,
            severity: 'error',
            message: validationResult,
          });

          helpers.setSubmitting(false);
        } else {
          onSubmit(fields, helpers);
          setChosenTotals(defaultChosenTotals);
        }
      }}
      validationSchema={validationSchema}
    >
      {({
        handleChange,
        handleBlur,
        errors,
        values,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        setFieldError,
      }) => {
        const error = (name: FieldKey) =>
          Boolean(errors[name] && touched[name]);

        if (values.roleId === 'player') {
          setPlayerSelected(true);
          setExtraPositionTakingEnabled(false);
        } else {
          setPlayerSelected(false);
        }

        const getCheckboxFieldDefaultProps = (
          name: FieldKey,
        ): typeof defaultProps => {
          const onFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('password', '');
            setFieldTouched('password', false);
            setFieldError('password', undefined);
            handleChange(e);
          };

          const defaultProps = {
            name,
            fullWidth: true,
            checked: Boolean(values[name]),
            onChange: name === 'isActivate' ? onFieldChange : handleChange,
            onBlur: handleBlur,
            error: error(name),
            disabled: isDisabled,
          };

          return defaultProps;
        };

        const getFieldDefaultProps = (name: FieldKey): typeof defaultProps => {
          const defaultProps = {
            name,
            id: name,
            fullWidth: true,
            value: values[name],
            onChange: handleChange,
            onBlur: handleBlur,
            error: error(name),
          };

          return defaultProps;
        };

        return (
          <Form
            onChange={(e) => {
              const target = e.target as HTMLInputElement;

              if (target.name === 'isExtraPositionTakingEnabled') {
                setExtraPositionTakingEnabled(target.checked);
                setChosenTotals({
                  ...chosenTotals,
                  isExtraPositionTakingEnabled: target.checked,
                  positionTaking: 0,
                });
                setFieldValue('positionTakingRate', 0);
              }
            }}
          >
            <Grid container>
              <Grid item xs={4} className={classes.containerBorder}>
                <Grid px={3} container item xs={12} spacing={3}>
                  <Grid item xs={12} mt={3}>
                    <Typography variant="h6">
                      <FormattedMessage {...messages.networkLabel} />
                    </Typography>
                  </Grid>
                  {isSystemAdmin || isBrandOwner ? (
                    <SelectableNetworkHeader isDisabled={isDisabled} />
                  ) : (
                    <NetworkHeader
                      parentUser={parentUser}
                      brandId={brand?.id}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container item xs={8} spacing={3} p={3}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    <FormattedMessage {...messages.commissionAndPT} />
                  </Typography>
                </Grid>
                {renderDataForInputs?.map(({ inputItems, isHide }, index) => {
                  if (isHide) return null;

                  const isLastItem = renderDataForInputs.length - 1 === index;

                  return (
                    <Fragment key={index}>
                      {/* <Grid item xs={12}>
                            <Typography variant="h6">{label}</Typography>
                          </Grid> */}
                      {inputItems.map(
                        (
                          { name, type, label: fieldLabel, options, disabled },
                          fieldIndex,
                        ) => {
                          const isLastField =
                            inputItems.length - 1 === fieldIndex;
                          if (
                            playerSelected &&
                            (name === 'minPositionTakingRate' ||
                              name === 'maxPositionTakingRate' ||
                              name === 'extraPositionTakingRate' ||
                              name === 'isExtraPositionTakingEnabled')
                          ) {
                            return null;
                          }

                          // only show username for player registration
                          if (!playerSelected && name === 'authUsername') {
                            return null;
                          }

                          return (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              key={name}
                              className={classNames({
                                [classes.fieldContainer]:
                                  isLastField && isLastItem,
                              })}
                            >
                              {type === 'password' && (
                                <PasswordOutlinedInput
                                  label={fieldLabel}
                                  disabled={!values.isActivate || isDisabled}
                                  helperText={errors[name]}
                                  {...getFieldDefaultProps(name)}
                                />
                              )}
                              {type === 'checkbox' && (
                                <Box display="flex" py={1.5}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={<RadioButtonUncheckedIcon />}
                                        checkedIcon={
                                          <RadioButtonCheckedIcon color="primary" />
                                        }
                                        {...getCheckboxFieldDefaultProps(name)}
                                      />
                                    }
                                    label={fieldLabel}
                                  />
                                </Box>
                              )}
                              {type === 'select' && (
                                <TextField
                                  select
                                  label={fieldLabel}
                                  variant="outlined"
                                  disabled={isDisabled || disabled}
                                  {...getFieldDefaultProps(name)}
                                  onChange={(e) => {
                                    if (name === 'positionTakingRate')
                                      setChosenTotals({
                                        ...chosenTotals,
                                        positionTaking: Number(e.target.value),
                                      });
                                    if (name === 'minPositionTakingRate') {
                                      setChosenTotals({
                                        ...chosenTotals,
                                        minPositionTakingRate: Number(
                                          e.target.value,
                                        ),
                                      });
                                    }
                                    if (name === 'maxPositionTakingRate') {
                                      setChosenTotals({
                                        ...chosenTotals,
                                        maxPositionTakingRate: Number(
                                          e.target.value,
                                        ),
                                      });
                                    }
                                    if (name === 'extraPositionTakingRate') {
                                      setChosenTotals({
                                        ...chosenTotals,
                                        extraPositionTaking: Number(
                                          e.target.value,
                                        ),
                                      });
                                    }
                                    handleChange(e);
                                  }}
                                  children={options?.map(
                                    ({ value, label }, idx) => (
                                      <MenuItem
                                        key={`${fieldLabel} ${value}`}
                                        value={value}
                                        selected={idx === 0}
                                      >
                                        {label}
                                      </MenuItem>
                                    ),
                                  )}
                                />
                              )}
                              {(!type || type === 'text') && (
                                <TextField
                                  label={fieldLabel}
                                  variant="outlined"
                                  disabled={isDisabled}
                                  {...getFieldDefaultProps(name)}
                                />
                              )}
                            </Grid>
                          );
                        },
                      )}

                      {/* {!isLastItem && (
                          <Grid item xs={12}>
                            <Divider orientation="horizontal" />
                          </Grid>
                        )} */}
                    </Fragment>
                  );
                })}
              </Grid>

              <Grid item xs={12}>
                <Divider orientation="horizontal" />
                {brand && (
                  <NetworkTree
                    brand={brand}
                    setTotals={setTotals}
                    chosenTotals={chosenTotals}
                  />
                )}
              </Grid>
              <Grid item xs={12} p={3}>
                <LoadingButton
                  disabled={isDisabled}
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={isSubmitting}
                >
                  <FormattedMessage {...messages.save} />
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserManualRegistrationForm;
