import { DialogType } from '.';
import { PermissionMap, UserRole } from '../../../../types';
import { USER_ROLES, AGENTS_ROLES } from '../../../common/constants';

interface GetPermissionGrantedUsersParams {
  permissionMap?: PermissionMap;
  isSystemAdmin: boolean;
  type: DialogType;
}

const filteredUserRoles = USER_ROLES.filter(
  (userRole) => !AGENTS_ROLES.includes(userRole.value),
);

type RoleData = {
  value: string;
  label: string;
};

const getPermissionGrantedUsers = ({
  permissionMap,
  isSystemAdmin,
  type,
}: GetPermissionGrantedUsersParams): RoleData[] => {
  if (type === 'systemAdmins' && isSystemAdmin) {
    return filteredUserRoles.filter((role) => role.value === 'systemAdmin');
  }

  if (type === 'brandOwners') {
    return filteredUserRoles.filter((role) => role.value === 'owner');
  }

  if (!permissionMap) return [];

  const permissionGrantedRoles: RoleData[] = [];

  const userRoles = filteredUserRoles.filter(
    (role) => role.value !== 'systemAdmin' && role.value !== 'owner',
  );

  userRoles.forEach(({ value, label }) => {
    const role = value as Exclude<UserRole, 'systemAdmin'>;

    const isCreatePermissionGranted = !!permissionMap[role]?.create;

    if (isCreatePermissionGranted)
      permissionGrantedRoles.push({ value, label });
  });

  return permissionGrantedRoles;
};

export default getPermissionGrantedUsers;
