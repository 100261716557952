import React, { useCallback, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFirebaseApp } from 'reactfire';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  LoadingButton,
  ModalCenter,
  TextField,
  Typography,
  UIContext,
} from '@miyagami-com/lsx-ui-components';

import ClosableBox from '../../Unknown/ClosableBox';

import {
  DEFAULT_REGION,
  SELECT_EXTENDED_BRAND_DOMAIN,
} from '../../../common/constants';

import { ExtendedBrandDomain } from '../../../../types';

import messages from './messages';
import useBrandDomain from '../../../common/hooks/useBrandDomain';
import LoadingBox from '../../Unknown/LoadingBox';
import { useMutation } from 'react-query';
import { getFunctions, httpsCallable } from 'firebase/functions';

type BrandDomainsVerificationProps = {
  brandId: string;
  domainId: string;
  handleClose: () => void;
};

const BrandDomainVerification: React.FC<BrandDomainsVerificationProps> = ({
  brandId,
  domainId,
  handleClose,
}) => {
  const intl = useIntl();
  const { setAlert } = useContext(UIContext);

  const [isVerified, setIsVerified] = useState(false);

  const { data: brandDomain, isLoading } = useBrandDomain<ExtendedBrandDomain>({
    domainId,
    select: SELECT_EXTENDED_BRAND_DOMAIN,
  });

  const handleCloseModal = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const { mutate, isLoading: isLoadingVerify } = useMutation(async () => {
    if (!domainId || !brandDomain?.domain) return;

    const verifyBrandDomain = httpsCallable(
      functions,
      'back-brandDomain-verifyBrandDomain',
    );

    try {
      const verification = await verifyBrandDomain({
        domainId,
        brandId,
      });

      setIsVerified(!!verification);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setAlert({
        show: true,
        severity: 'error',
        message: error.toString(),
      });
    }
  });

  if (isLoading) {
    return (
      <ModalCenter open={!!domainId} onClose={handleCloseModal}>
        <ClosableBox
          label={intl.formatMessage(messages.domainVerification)}
          onClose={handleCloseModal}
        >
          <Box p={4}>
            <LoadingBox />
          </Box>
        </ClosableBox>
      </ModalCenter>
    );
  }

  return (
    <ModalCenter open={!!domainId} onClose={handleCloseModal}>
      <ClosableBox
        label={intl.formatMessage(messages.domainVerification)}
        onClose={handleCloseModal}
      >
        <Box p={4}>
          {isVerified ? (
            <Box>
              <Typography gutterBottom>
                <FormattedMessage {...messages.domainVerified} />
              </Typography>
              <Button fullWidth onClick={handleCloseModal}>
                <FormattedMessage {...messages.close} />
              </Button>
            </Box>
          ) : (
            <Box>
              <Typography>
                <FormattedMessage {...messages.challenges} />
              </Typography>
              {brandDomain ? (
                <>
                  {brandDomain.verifications?.map((verify) => (
                    <Box py={1} key={verify.domain}>
                      <Box pt={1}>
                        <Typography variant="h6">
                          <FormattedMessage {...messages.domain} />
                        </Typography>
                        <Box py={2}>
                          <TextField
                            fullWidth
                            label={intl.formatMessage(messages.domain)}
                            value={verify.domain}
                          />
                        </Box>
                      </Box>
                      <Box pt={1}>
                        <Typography variant="h6">
                          <FormattedMessage {...messages.reason} />
                        </Typography>
                        <Box py={2}>
                          <TextField
                            fullWidth
                            label={intl.formatMessage(messages.reason)}
                            value={verify.reason}
                          />
                        </Box>
                      </Box>
                      <Box pt={1}>
                        <Typography variant="h6">
                          <FormattedMessage {...messages.type} />
                        </Typography>
                        <Box py={2}>
                          <TextField
                            fullWidth
                            label={intl.formatMessage(messages.type)}
                            value={verify.type}
                          />
                        </Box>
                      </Box>
                      <Box pt={1}>
                        <Typography variant="h6">
                          <FormattedMessage {...messages.value} />
                        </Typography>
                        <Box py={2}>
                          <TextField
                            fullWidth
                            label={intl.formatMessage(messages.value)}
                            value={verify.value}
                          />
                        </Box>
                      </Box>
                      <Divider />
                      <Divider />
                    </Box>
                  ))}
                </>
              ) : (
                <Box display="flex" justifyContent="center" py={2}>
                  <CircularProgress />
                </Box>
              )}
              <LoadingButton
                loading={isLoadingVerify}
                variant="contained"
                fullWidth
                onClick={() => mutate()}
              >
                <FormattedMessage {...messages.verify} />
              </LoadingButton>
            </Box>
          )}
        </Box>
      </ClosableBox>
    </ModalCenter>
  );
};

export default BrandDomainVerification;
