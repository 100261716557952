import { defineMessages } from 'react-intl';

export default defineMessages({
  create: {
    id: 'UserPermission.UserPermissionEditCheckbox.create',
    defaultMessage: 'Create { value }',
  },
  read: {
    id: 'UserPermission.UserPermissionEditCheckbox.read',
    defaultMessage: 'Read { value }',
  },
  update: {
    id: 'UserPermission.UserPermissionEditCheckbox.update',
    defaultMessage: 'Update { value }',
  },
  delete: {
    id: 'UserPermission.UserPermissionEditCheckbox.delete',
    defaultMessage: 'Delete { value }',
  },
});
