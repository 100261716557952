import { defineMessages } from 'react-intl';

export default defineMessages({
  bets: {
    id: 'PlayerExchange.PlayerExchangePage.bets',
    defaultMessage: 'Bets',
  },
  sportline: {
    id: 'PlayerExchange.PlayerExchangePage.sportline',
    defaultMessage: 'Sportline',
  },
  profitLoss: {
    id: 'PlayerExchange.PlayerExchangePage.profitLoss',
    defaultMessage: 'Profit & Loss',
  },
  dateRange: {
    id: 'Exchange.ExchangeSettingsPage.dateRange',
    defaultMessage: 'Date range',
  },
  marketType: {
    id: 'PlayerExchange.PlayerExchangePage.marketType',
    defaultMessage: 'Market Type',
  },
});
