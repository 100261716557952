import { defineMessages } from 'react-intl';

export default defineMessages({
  player: {
    id: 'Player.PlayerOverviewPage.player',
    defaultMessage: 'Player',
  },
  playerOverview: {
    id: 'Player.PlayerOverviewPage.playerOverview',
    defaultMessage: 'Player overview',
  },
  errorPaginationMessage: {
    id: 'Player.PlayerOverviewPage.errorPaginationMessage',
    defaultMessage: 'An error has occurred while getting player list.',
  },
  id: {
    id: 'User.SimpleUserList.id',
    defaultMessage: 'ID',
  },
  name: {
    id: 'User.SimpleUserList.name',
    defaultMessage: 'First name',
  },
  surname: {
    id: 'User.SimpleUserList.surname',
    defaultMessage: 'Surname',
  },
  email: {
    id: 'User.SimpleUserList.email',
    defaultMessage: 'E-mail',
  },
  exposure: {
    id: 'User.SimpleUserList.exposure',
    defaultMessage: 'Exposure',
  },
  balance: {
    id: 'User.SimpleUserList.balance',
    defaultMessage: 'Balance',
  },
  phoneNumber: {
    id: 'User.SimpleUserList.phoneNumber',
    defaultMessage: 'Telephone',
  },
  brand: {
    id: 'User.SimpleUserList.brand',
    defaultMessage: 'Brand',
  },
  role: {
    id: 'User.SimpleUserList.role',
    defaultMessage: 'Role',
  },
  status: {
    id: 'User.SimpleUserList.status',
    defaultMessage: 'Status',
  },
  flagged: {
    id: 'User.SimpleUserList.flagged',
    defaultMessage: 'Flagged',
  },
  actions: {
    id: 'User.SimpleUserList.actions',
    defaultMessage: 'Actions',
  },
  viewUser: {
    id: 'User.SimpleUserList.viewUser',
    defaultMessage: 'View',
  },
  editUser: {
    id: 'User.SimpleUserList.editUser',
    defaultMessage: 'Edit',
  },
  deleteUser: {
    id: 'User.SimpleUserList.deleteUser',
    defaultMessage: 'Delete',
  },
  successDeletePlayer: {
    id: 'User.SimpleUserList.successDeletePlayer',
    defaultMessage: 'Player was successfully deleted!',
  },
  errorDeletePlayer: {
    id: 'User.SimpleUserList.errorDeletePlayer',
    defaultMessage: 'An error has occurred while deleting player!',
  },
  totalExposure: {
    id: 'User.SimpleUserList.totalExposure',
    defaultMessage: 'Total Exposure per page: { totExposure }',
  },
  totalBalance: {
    id: 'User.SimpleUserList.totalBalance',
    defaultMessage: 'Total Balance per page: { totBalance }',
  },
});
