import { useIntl } from 'react-intl';
import { UseQueryResult } from 'react-query';
import useBrandId from '../../../common/hooks/useBrandId';
import { EventDetail } from '../../../common/hooks/useEventDetail';
import { SubTagPath } from '../../Unknown/SubTags';
import messages from './messages';

interface UsePathParams {
  eventDetailQuery: UseQueryResult<EventDetail, Error>;
}

const usePath = (params: UsePathParams): SubTagPath[] => {
  const { eventDetailQuery } = params;

  const { data } = eventDetailQuery;

  const intl = useIntl();

  const brandId = useBrandId();

  const defaultPath: SubTagPath[] = [
    {
      label: intl.formatMessage(messages.exchange),
    },
    {
      label: intl.formatMessage(messages.events),
      url: `/b/${brandId}/event-overview`,
    },
  ];

  if (!data) return defaultPath;

  const { event_name } = data;

  const eventItem: SubTagPath = {
    label: event_name,
  };

  return [...defaultPath, eventItem];
};

export default usePath;
