import { Formik, Form, FormikHelpers } from 'formik';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import {
  LoadingButton,
  TextField,
  Typography,
  Grid,
  Box,
} from '@miyagami-com/lsx-ui-components';

import PasswordOutlinedInput from '../../Unknown/PasswordOutlinedInput';

import messages from './messages';
import useStyles from './useStyles';
import validationSchema from './validationSchema';

export type LoginFormValues = {
  email: string;
  password: string;
};

type LoginFormProps = {
  onSubmit: (
    values: LoginFormValues,
    formikHelpers: FormikHelpers<LoginFormValues>,
  ) => Promise<void> | void;
};

const initialValues = {
  email: '',
  password: '',
};

type Fields = keyof typeof initialValues;

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();

  const onPushToResetPassword = () => {
    history.push('/reset-password');
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ errors, handleBlur, handleChange, touched, isSubmitting }) => {
        const getFieldData = (name: Fields): typeof fieldValue => {
          const fieldValue = {
            fullWidth: true,
            name,
            error: Boolean(errors[name] && touched[name]),
            onChange: handleChange,
            onBlur: handleBlur,
            helperText: errors[name],
            id: name,
          };

          return fieldValue;
        };

        return (
          <Form>
            <Grid container spacing={2} p={4} maxWidth={600}>
              <Grid item xs={12}>
                <TextField
                  label={intl.formatMessage(messages.email)}
                  variant="outlined"
                  {...getFieldData('email')}
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordOutlinedInput
                  label={intl.formatMessage(messages.password)}
                  {...getFieldData('password')}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    onClick={onPushToResetPassword}
                    className={classes.pointer}
                    color="primary.dark"
                  >
                    <FormattedMessage {...messages.forgotPassword} />
                  </Typography>
                  <LoadingButton
                    classes={{ root: classes.button }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    id="sign-in-button "
                    loading={isSubmitting}
                  >
                    <FormattedMessage {...messages.login} />
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
