import { v4 } from 'uuid';
import { BackofficeUserWhitelist } from '../../../../types/supabase';
import { NormalizedBackofficeUser } from '../WhiteList/sortedBackofficeUsersData';

interface UseVirtualWhitelistParams {
  item: NormalizedBackofficeUser | null;
}

const useVirtualWhitelist = (
  params: UseVirtualWhitelistParams,
): BackofficeUserWhitelist[] => {
  const { item } = params;

  if (!item || !item.whitelist.length) {
    const createdAt = new Date().toISOString();

    const virtualWhitelistItem: BackofficeUserWhitelist = {
      backoffice_user_id: item?.id || '',
      country_code: null,
      created_at: createdAt,
      updated_at: createdAt,
      ip_address: '',
      id: v4(),
      note: '',
    };

    return [virtualWhitelistItem];
  }

  return item?.whitelist || [];
};

export default useVirtualWhitelist;
