interface BackLiabilityParams {
  size: number;
}

const backLiability = (params: BackLiabilityParams): number => {
  const { size } = params;

  return size;
};

export default backLiability;
