import { defineMessages } from 'react-intl';

export default defineMessages({
  system: {
    id: 'BackofficeUser.BackofficeUserAccountPage.system',
    defaultMessage: 'System',
  },
  backofficeUser: {
    id: 'BackofficeUser.BackofficeUserAccountPage.backofficeUser',
    defaultMessage: 'Backoffice users',
  },
  systemAdmins: {
    id: 'BackofficeUser.BackofficeUserAccountPage.systemAdmins',
    defaultMessage: 'System admins',
  },
  brandOwners: {
    id: 'BackofficeUser.BackofficeUserAccountPage.brandOwners',
    defaultMessage: 'Brand owners',
  },
});
