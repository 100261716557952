import { FC, useRef, useEffect } from 'react';
import { TextField } from '@miyagami-com/lsx-ui-components';
import { TextFieldProps } from '@mui/material';

const InputCell: FC<TextFieldProps> = (textFieldProps) => {
  const { defaultValue } = textFieldProps;
  const textFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.value = defaultValue as string;
    }
  }, [defaultValue]);

  return (
    <TextField
      {...textFieldProps}
      fullWidth
      variant="outlined"
      size="small"
      inputRef={textFieldRef}
    />
  );
};

export default InputCell;
