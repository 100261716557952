import { defineMessages } from 'react-intl';

export default defineMessages({
  player: {
    id: 'Player.PlayerDetailPage.player',
    defaultMessage: 'Player',
  },
  playersAndAgents: {
    id: 'Player.PlayerDetailPage.playersAndAgents',
    defaultMessage: 'Players & Agents',
  },
  playerOverview: {
    id: 'Player.PlayerDetailPage.playerOverview',
    defaultMessage: 'Player overview',
  },
  accountLabel: {
    id: 'Player.PlayerDetailPage.accountLabel',
    defaultMessage: 'Account',
  },
  networkLabel: {
    id: 'Player.PlayerDetailPage.networkLabel',
    defaultMessage: 'Network',
  },
  balanceLabel: {
    id: 'Player.PlayerDetailPage.balanceLabel',
    defaultMessage: 'Balance',
  },
  exchangeLabel: {
    id: 'Player.PlayerDetailPage.exchangeLabel',
    defaultMessage: 'Exchange',
  },
  securityLabel: {
    id: 'Player.PlayerDetailPage.securityLabel',
    defaultMessage: 'Security',
  },
  limitsLabel: {
    id: 'Player.PlayerDetailPage.limitsLabel',
    defaultMessage: 'Limits',
  },
  currencyLabel: {
    id: 'Player.PlayerDetailPage.currencyLabel',
    defaultMessage: 'Currency',
  },
});
