import { defineMessages } from 'react-intl';

export default defineMessages({
  language: {
    id: 'Language.LanguageAdditionForm.language',
    defaultMessage: 'Language',
  },
  name: {
    id: 'Language.LanguageAdditionForm.name',
    defaultMessage: 'Name',
  },
  flag: {
    id: 'Language.LanguageAdditionForm.flag',
    defaultMessage: 'Flag',
  },
  save: {
    id: 'Language.LanguageAdditionForm.save',
    defaultMessage: 'Save',
  },
});
