import Big from 'big.js';
import { DEFAULT_AMOUNT } from './constants';

interface SumArrayByFieldParams<T> {
  array: T[];
  field: keyof T;
  initialValue?: number;
}

const sumArrayByField = <T>(
  params: SumArrayByFieldParams<T>,
): typeof fieldSum => {
  const { array, field, initialValue = DEFAULT_AMOUNT } = params;

  const fieldSum = array.reduce<number>((sum, item) => {
    const value = item[field];

    const isNumberValue = typeof value === 'number';

    if (!isNumberValue) return sum;

    const newSum = new Big(sum).plus(value).toNumber();

    return newSum;
  }, initialValue);

  return fieldSum;
};

export default sumArrayByField;
