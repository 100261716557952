import { UseQueryResult, useQuery } from 'react-query';
import useSupabase from './useSupabase';
import { ApiKey } from '../../../types';

interface UseSharedApiKeysParams {
  brandId: string;
  select?: string;
}

const useSharedApiKeys = <T extends ApiKey>(
  params: UseSharedApiKeysParams,
): UseQueryResult<T[] | null, Error> => {
  const { brandId, select = '*' } = params;

  const supabase = useSupabase();

  const queryResult = useQuery<T[] | null, Error>(
    ['shared_api_keys', brandId],
    async () => {
      const { data } = await supabase
        .from('brand_api_keys')
        .select('*')
        .eq('brand_id', brandId);

      if (!data?.length) return null;

      const apiKeyIds = data.map((brandApiKey) => brandApiKey.api_key_id);

      const { data: apiKeys } = await supabase
        .from('api_keys')
        .select<typeof select, T>(select)
        .in('id', apiKeyIds)
        .throwOnError();

      return apiKeys;
    },
  );

  return queryResult;
};

export default useSharedApiKeys;
