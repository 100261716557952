import { useIntl } from 'react-intl';
import useTranslationsByReportLevel from './useTranslationsByReportLevel';
import getReportUrlByPermission from './getReportUrlByPermission';
import { useSelector } from 'react-redux';
import messages from './messages';
import { ProfitLossReportLevel, RootState } from '../../../../types';
import { useMemo } from 'react';
import getAgentNameById from './getAgentNameById';

interface UseTranslationParams {
  reportLevel: ProfitLossReportLevel;
  brandId: string;
  agentId?: string | null;
}

const useTranslations = (params: UseTranslationParams): typeof returnParams => {
  const { reportLevel, brandId, agentId } = params;

  const intl = useIntl();

  const reportItemLabelByReportLevel: Record<ProfitLossReportLevel, string> = {
    systemAdmin: intl.formatMessage(messages.brand),
    brand: intl.formatMessage(messages.superAgent),
    superAgent: intl.formatMessage(messages.masterAgent),
    masterAgent: intl.formatMessage(messages.agent),
    agent: intl.formatMessage(messages.player),
    player: intl.formatMessage(messages.player),
  };

  const reportItemLabel = reportItemLabelByReportLevel[reportLevel];

  const {
    users: { byBrandId: usersByBrands },
  } = useSelector((root: RootState) => root.globalData);

  const name = useMemo(() => {
    if (
      ['superAgent', 'masterAgent', 'agent'].includes(reportLevel) &&
      agentId
    ) {
      return getAgentNameById(usersByBrands, agentId);
    }
    return brandId;
  }, [agentId, brandId, reportLevel, usersByBrands]);

  const translations = useTranslationsByReportLevel({
    reportLevel,
    intl,
    name,
  });

  const user = useSelector((root: RootState) => root.user);

  const path = [
    { label: intl.formatMessage(messages.reports) },
    {
      label: intl.formatMessage(messages.plReport),
      url: getReportUrlByPermission({ user, brandId }),
    },
    { label: translations.reportLevel },
  ];

  const tableName = `${intl.formatMessage(messages.plReport)} - ${
    translations.tableTitle
  }`;

  const returnParams = {
    path,
    tableName,
    reportItemLabel,
    translations,
  };

  return returnParams;
};

export default useTranslations;
