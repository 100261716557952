import { useEffect, useState } from 'react';
import { QueryClient, useQuery } from 'react-query';
import { ExchangeBet } from '../../../types';
import useSupabase from './useSupabase';
import type { DateRange } from '@mui/x-date-pickers-pro';
import chunkArray from '../chunkArray';
import { DEFAULT_EXCHANGE_BET_CHUNK_SIZE } from '../constants';

type ConvertToUTCStringParams = {
  endOfDay: boolean;
  date: Date;
};

export const convertToUTCString = (
  params: ConvertToUTCStringParams,
): string => {
  const { endOfDay, date } = params;

  const year = date.getFullYear();

  const month = date.getMonth() + 1; // Month is zero-based, so add 1

  const day = date.getDate();

  const time = endOfDay ? `23:59:59` : `00:00:00`;

  return `${year}-${month}-${day} ${time}`;
};

interface UseExchangeBetsParams {
  playerId?: string;
  brandId?: string;
  page: number;
  size: number;
  select?: string;
  count?: 'exact' | 'planned' | 'estimated';
  dateRange?: DateRange<Date>;
}

const useExchangeBets = <T extends ExchangeBet>(
  params: UseExchangeBetsParams,
): typeof returnParams => {
  const {
    playerId,
    brandId,
    select = '*',
    page,
    size,
    count,
    dateRange,
  } = params;

  const queryClient = new QueryClient();

  const [rows, setRows] = useState<T[]>([]);

  useEffect(() => {
    setRows([]);
  }, [brandId]);

  const supabase = useSupabase();

  const exchangeBetsRef = supabase.from('exchange_bets');

  const exchangeBetSelect = exchangeBetsRef
    .select<typeof select, T>(select, {
      count,
    })
    .order('settled_at', { ascending: false });

  let betSelect = exchangeBetSelect;

  if (playerId) {
    betSelect = betSelect.eq('player_id', playerId);
  }

  if (brandId) {
    betSelect = betSelect.eq('brand_id', brandId);
  }

  if (dateRange) {
    const startDate = convertToUTCString({
      date: dateRange[0] || new Date(),
      endOfDay: false,
    });

    const endDate = convertToUTCString({
      date: dateRange[1] || new Date(),
      endOfDay: true,
    });

    betSelect = betSelect
      .gte('settled_at', startDate)
      .lte('settled_at', endDate);
  }

  const getExchangeBet = async () => {
    // const { from, to } = getPagination({ page, size });
    const { data: exchangeBetsData, error: exchangeBetsError } =
      await betSelect.order('settled_at', { ascending: false });

    if (!exchangeBetsData) {
      return {
        data: [],
        error: exchangeBetsError,
        count: 0,
      };
    }

    const exchangeBetsChunked = chunkArray(
      exchangeBetsData,
      DEFAULT_EXCHANGE_BET_CHUNK_SIZE,
    );

    const chunkPromises = exchangeBetsChunked.map(async (exchangeBetsChunk) => {
      const exchangeBetIds = exchangeBetsChunk.map(
        (exchangeBet) => exchangeBet.id,
      );

      const { data: betfairBetsData } = await supabase
        .from('betfair_exchange_bets')
        .select('*')
        .in('id', exchangeBetIds);

      const { data: sizesData } = await supabase
        .from('betfair_exchange_sizes')
        .select('*')
        .in('betfair_exchange_bet_id', exchangeBetIds);

      if (!betfairBetsData) return [];
      if (!sizesData) return [];

      const exchangeBets = exchangeBetsChunk.map((exchangeBet) => {
        const betfairExchangeBetId = exchangeBet.betfair_exchange_bet_id;
        const betfairExchangeBet = betfairBetsData.find(
          (bet) => bet.id === betfairExchangeBetId,
        );
        const betfairExchangeSizes = sizesData.filter(
          (sizeRes) => sizeRes.betfair_exchange_bet_id === betfairExchangeBetId,
        );

        const betfairExchangeBetWithSizes = {
          ...betfairExchangeBet,
          betfairExchangeSizes,
        };
        return {
          ...exchangeBet,
          betfairExchangeBet: betfairExchangeBetWithSizes,
        };
      });

      return exchangeBets;
    });

    const exchangeBetsChunks = await Promise.all(chunkPromises);
    const exchangeBets = exchangeBetsChunks.flat();

    return {
      data: exchangeBets as T[],
      error: exchangeBetsError,
      count: exchangeBets.length,
    };
  };

  const queryResult = useQuery({
    queryKey: ['exchangeBet', playerId, brandId, page, size, dateRange],
    queryFn: async () => {
      const playerResponse = await getExchangeBet();

      const { error } = playerResponse;

      if (error) throw new Error(error.message);

      return playerResponse;
    },
    onError: (error) => error,
    onSuccess: (exchangeBetsQuery) => {
      exchangeBetsQuery?.data?.forEach((exchangeBetQuery) => {
        queryClient.setQueryData(
          ['exchangeBet', exchangeBetQuery.id],
          exchangeBetQuery,
        );
      });

      setRows((prevRows) => {
        if (!exchangeBetsQuery?.data?.length) return [];
        const newRows =
          exchangeBetsQuery?.data?.filter((newItem) => {
            return !prevRows.some((prevItem) => prevItem.id === newItem.id);
          }) || [];

        return prevRows.concat(newRows);
      });
    },
  });

  const returnParams = {
    ...queryResult,
    data: {
      ...queryResult.data,
      data: rows,
      count: queryResult.data?.count,
    },
  };

  return returnParams;
};

export default useExchangeBets;
