import { Box, Grid, Typography } from '@miyagami-com/lsx-ui-components';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormValues } from '.';
import messages from './messages';
import useStyles from './useStyles';

const BetfairBetTypeFormControl: React.FC = () => {
  const intl = useIntl();

  const { values, handleChange, handleBlur } = useFormikContext<FormValues>();

  const classes = useStyles();

  return (
    <Box p={3} pb={4}>
      <Typography variant="h6">
        <FormattedMessage {...messages.betfairBetTypeLabel} />
      </Typography>
      <FormControl className={classes.formControlContainer}>
        <RadioGroup
          value={values.betfairBetType}
          defaultValue={values.betfairBetType}
          name="betfairBetType"
        >
          <Grid container pt={3} spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                value="minimumBet"
                control={<Radio />}
                label={intl.formatMessage(messages.minimumBetLabel)}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                value="pt"
                control={<Radio />}
                label={intl.formatMessage(messages.basedOnPtLabel)}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default BetfairBetTypeFormControl;
