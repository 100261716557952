import { useMemo } from 'react';
import { colors } from '../theme';
import messages from '../messages';
import { useIntl } from 'react-intl';
import { BetfairExchangeSizeType } from '../../../types';

export type StatusMapParams = {
  label: string;
  color: string;
};

const useBetfairExchangeSizeStatusMap = (): Record<
  BetfairExchangeSizeType,
  StatusMapParams
> => {
  const intl = useIntl();

  const exchangeBetStatusMap: Record<BetfairExchangeSizeType, StatusMapParams> =
    useMemo(() => {
      return {
        cancelled: {
          label: intl.formatMessage(messages.cancelledStatus),
          color: colors.grey300,
        },
        lapsed: {
          label: intl.formatMessage(messages.lapsedStatus),
          color: colors.successMain,
        },
        matched: {
          label: intl.formatMessage(messages.matchedStatus),
          color: colors.successMain,
        },
        unmatched: {
          label: intl.formatMessage(messages.unmatchedStatus),
          color: colors.successMain,
        },
        voided: {
          label: intl.formatMessage(messages.voidedStatus),
          color: colors.successMain,
        },
      };
    }, [intl]);

  return exchangeBetStatusMap;
};

export default useBetfairExchangeSizeStatusMap;
