import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  Fragment,
  useMemo,
} from 'react';
import { Formik, Form, FormikValues } from 'formik';
import {
  Box,
  Divider,
  Typography,
  TextField,
  LoadingButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  FormControlLabel,
} from '@miyagami-com/lsx-ui-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Switch } from '@mui/material';

import { BackOfficeUserStatus, RootState } from '../../../../types';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';

import messages from './messages';
import validationSchema from './validationSchema';

export interface UserParams {
  uid: string;
  email: string;
  password: string | null;
  phoneNumber: string | null;
  name: string;
  surname: string;
  status: BackOfficeUserStatus;
  role: string;
  mfa: boolean;
  brandId: string | null;
  isEnableFirewall: boolean;
  isEnableTotp?: boolean;
  authUsername?: string | null;
  country?: string;
  city?: string;
  street?: string;
  zipCode?: string;
}

export type FunctionUserParams = Omit<
  UserParams,
  'uid' | 'password' | 'status' | 'role' | 'mfa' | 'isEnableFirewall'
>;

type InputName = keyof FunctionUserParams;

type InputProps = {
  name: InputName;
  label: string;
  readOnly?: boolean;
  type?: 'text' | 'switch';
};

type RenderDataForInputs = {
  label: JSX.Element;
  inputItems: InputProps[];
}[];

interface UserPersonalInformationFormProps {
  onSubmit: (values: FunctionUserParams) => Promise<void>;
  isButtonLoading: boolean;
  initialValues: FunctionUserParams;
  isEdit: boolean;
  isDisabled: boolean;
  isAgentOverviewPage?: boolean;
}

const UserPersonalInformationForm: FC<UserPersonalInformationFormProps> = ({
  onSubmit,
  isButtonLoading,
  initialValues,
  isEdit,
  isDisabled,
  isAgentOverviewPage = false,
}) => {
  const intl = useIntl();

  const { brands } = useSelector((state: RootState) => state.globalData);
  const { roles } = useSelector((root: RootState) => root.user);

  const isSystemAdmin = checkIsSystemAdmin({ roles });

  const renderDataForInputs: RenderDataForInputs = useMemo(() => {
    return [
      {
        label: <FormattedMessage {...messages.personal} />,
        inputItems: [
          {
            name: 'name',
            label: intl.formatMessage(messages.name),
          },
          {
            name: 'surname',
            label: intl.formatMessage(messages.surname),
          },
          {
            name: 'authUsername',
            label: intl.formatMessage(messages.authUsername),
            readOnly: !!initialValues.authUsername,
          },
        ],
      },
      {
        label: <FormattedMessage {...messages.contact} />,
        inputItems: [
          {
            name: 'email',
            label: intl.formatMessage(messages.email),
            disabled: !isSystemAdmin || !isAgentOverviewPage,
            readOnly: !isSystemAdmin || !isAgentOverviewPage,
          },
          {
            name: 'phoneNumber',
            label: intl.formatMessage(messages.phoneNumber),
          },
        ],
      },
      {
        label: <FormattedMessage {...messages.address} />,
        inputItems: [
          {
            name: 'country',
            label: intl.formatMessage(messages.country),
          },
          {
            name: 'city',
            label: intl.formatMessage(messages.city),
          },
          {
            name: 'street',
            label: intl.formatMessage(messages.street),
          },
          {
            name: 'zipCode',
            label: intl.formatMessage(messages.zipCode),
          },
        ],
      },
      {
        label: <FormattedMessage {...messages.totpRequired} />,
        inputItems: [
          {
            name: 'isEnableTotp',
            label: intl.formatMessage(messages.totpRequired),
            type: 'switch',
          },
        ],
      },
    ];
  }, [initialValues.authUsername, intl, isAgentOverviewPage, isSystemAdmin]);

  const renderInputs = (
    inputProps: InputProps,
    values: FormikValues,
    handleChange:
      | ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
      | undefined,
    handleBlur:
      | FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
      | undefined,
    error: boolean | undefined,
  ) => {
    const { name, label: fieldLabel, readOnly, type } = inputProps;

    if (name === 'authUsername' && isAgentOverviewPage) {
      return null;
    }

    switch (type) {
      case 'switch':
        return (
          <FormControlLabel
            control={
              <Switch
                name={name}
                size="small"
                onChange={handleChange}
                checked={values[name]}
              />
            }
            label={fieldLabel}
          />
        );
      default:
        return (
          <TextField
            disabled={isDisabled || inputProps.readOnly}
            fullWidth
            label={fieldLabel}
            InputProps={{ readOnly }}
            name={name}
            variant="outlined"
            value={values[name]}
            onChange={handleChange}
            onBlur={handleBlur}
            error={error}
          />
        );
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleChange, handleBlur, errors, values, touched }) => {
        const error = (name: keyof FunctionUserParams) =>
          Boolean(errors[name] && touched[name]);

        return (
          <Form>
            {renderDataForInputs.map(({ label, inputItems }, index) => (
              <Fragment key={index}>
                <Box p={3}>
                  <Typography variant="h6">{label}</Typography>
                  <Grid container pt={3} spacing={3}>
                    {inputItems.map(
                      ({
                        name,
                        label: fieldLabel,
                        readOnly,
                        type,
                      }: InputProps) => (
                        <Grid item xs={12} sm={6} key={name}>
                          {renderInputs(
                            { name, label: fieldLabel, readOnly, type },
                            values,
                            handleChange,
                            handleBlur,
                            error(name as InputName),
                          )}
                        </Grid>
                      ),
                    )}
                  </Grid>
                </Box>
                <Divider />
              </Fragment>
            ))}
            {!isEdit && (
              <>
                <Box p={3}>
                  <Typography variant="h6">
                    <FormattedMessage {...messages.brandId} />
                  </Typography>
                  <Grid container pt={3} spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="select-brand">
                          <FormattedMessage {...messages.brandId} />
                        </InputLabel>
                        <Select
                          disabled={isDisabled}
                          fullWidth
                          labelId="select-brand"
                          name="brandId"
                          value={values.brandId}
                          onChange={handleChange}
                          label={intl.formatMessage(messages.brandId)}
                          error={error('brandId')}
                        >
                          {brands &&
                            brands.map((brand) => (
                              <MenuItem key={brand.id} value={brand.id}>
                                {brand.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
              </>
            )}
            <Box p={3} display="flex">
              <LoadingButton
                disabled={isDisabled}
                variant="contained"
                color="primary"
                type="submit"
                loading={isButtonLoading}
              >
                <FormattedMessage {...messages.save} />
              </LoadingButton>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserPersonalInformationForm;
