import { BackofficeUserRole } from '../../types/supabase';

interface CheckIsBrandPendingRoleParams {
  roles?: BackofficeUserRole[] | null;
}

interface ReturnParams {
  mappedRoles?: BackofficeUserRole[];
  isBrandPending: boolean;
}

const checkIsBrandPendingRole = ({
  roles,
}: CheckIsBrandPendingRoleParams): ReturnParams => {
  if (!roles?.length)
    return {
      isBrandPending: false,
      mappedRoles: roles || [],
    };

  const isBrandPendingUser =
    roles?.length === 1
      ? roles.some((role) => role.role_id === 'owner' && !role.brand_id)
      : false;

  return {
    isBrandPending: isBrandPendingUser,
    mappedRoles: roles,
  };
};

export default checkIsBrandPendingRole;
