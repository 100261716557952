import { UIContext } from '@miyagami-com/lsx-ui-components';
import { FormikHelpers } from 'formik';
import { useCallback, useContext } from 'react';
import getErrorMessage from '../../../common/getErrorMessage';
import useQuery from '../../../common/hooks/useQuery';
import useAuth from '../../Unknown/AuthContext/useAuth';
import { AuthenticationFormValues } from './AuthenticationForm';

const useSignUp = (): typeof onSignUp => {
  const { signUp } = useAuth();

  const query = useQuery();

  const token = query.get('token');

  const { setAlert } = useContext(UIContext);

  const onSignUp = useCallback(
    async (
      values: AuthenticationFormValues,
      { setSubmitting }: FormikHelpers<AuthenticationFormValues>,
    ) => {
      const { password } = values;

      setSubmitting(true);
      try {
        if (!token) throw new Error('Not found token in url');

        const signUpParams = {
          token,
          password,
        };

        await signUp(signUpParams);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        const message = getErrorMessage(error);
        setAlert({
          show: true,
          severity: 'warning',
          message,
        });
      } finally {
        setSubmitting(false);
      }
    },
    [setAlert, signUp, token],
  );

  return onSignUp;
};

export default useSignUp;
