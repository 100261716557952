import { BackofficeUserRole } from '../../types/supabase';
import { USER_ROLES } from './constants';

interface GetRoleByBrandIdParams {
  roles?: BackofficeUserRole[] | null;
  brandId: string;
}

type UserRoles = typeof USER_ROLES;

const getRoleByBrandId = ({
  roles,
  brandId,
}: GetRoleByBrandIdParams): UserRoles[number] | null => {
  const selectedRole = roles?.find(
    ({ brand_id: userBrandId }) => brandId === userBrandId,
  );

  if (!selectedRole) return null;

  const role = USER_ROLES.find(({ value }) => value === selectedRole.role_id);

  if (!role) return null;

  return role;
};

export default getRoleByBrandId;
