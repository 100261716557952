import { getFunctions, httpsCallable } from 'firebase/functions';
import { UseQueryResult, useQuery } from 'react-query';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../constants';

interface UseBrandDomainDNSParams {
  domain: string;
  brandId: string;
  onError?: (err: unknown) => void;
  onSuccess?: () => void;
}

export type DNSRecord = {
  type: string;
  value: string;
};

const useBrandDomainDNS = (
  params: UseBrandDomainDNSParams,
): UseQueryResult<DNSRecord[]> => {
  const { domain, brandId, onError, onSuccess } = params;

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const queryResult = useQuery(
    ['brandDomainDNS', brandId, domain],
    async () => {
      const getDomainDNSList = httpsCallable(
        functions,
        'back-brandDomain-getBrandDomainDNSList',
      );

      const domainDNSList = await getDomainDNSList({
        domain,
        brandId,
      });

      const dnsRecords = domainDNSList.data as DNSRecord[];

      return dnsRecords;
    },
    {
      onError,
      onSuccess,
    },
  );

  return queryResult;
};

export default useBrandDomainDNS;
