import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { BackOfficeUserStatus } from '../../../types';

import { colors } from '../theme';

import messages from '../messages';

export type UserStatus = {
  label: string;
  color: string;
  hidden: boolean;
};

export type UseUserStatusesMap = Record<BackOfficeUserStatus, UserStatus>;

const useUserStatuses = (): UseUserStatusesMap => {
  const intl = useIntl();

  return useMemo(() => {
    return {
      inactive: {
        label: intl.formatMessage(messages.inactive),
        color: colors.grey300,
        hidden: true,
      },
      active: {
        label: intl.formatMessage(messages.active),
        color: colors.successMain,
        hidden: false,
      },
      pending: {
        label: intl.formatMessage(messages.pending),
        color: colors.warningMain,
        hidden: true,
      },
      closed: {
        label: intl.formatMessage(messages.closed),
        color: colors.errorMain,
        hidden: false,
      },
      disabled: {
        label: intl.formatMessage(messages.disabled),
        color: colors.errorMain,
        hidden: false,
      },
      suspend: {
        label: intl.formatMessage(messages.suspend),
        color: colors.infoMain,
        hidden: false,
      },
    };
  }, [intl]);
};

export default useUserStatuses;
