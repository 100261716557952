import { useFirebaseApp } from 'reactfire';
import { TreeRow } from '../../components/Network/NetworkTreeList/createTreeRow';
import { DEFAULT_REGION } from '../constants';
import { UseQueryResult, useQuery } from 'react-query';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface UseUserTreeParams {
  userId: string;
  brandId: string;
  registeredUserId: string;
  parentPath: string | null;
  enabled?: boolean;
  userRole?: string;
}

const useManualRegistrationTree = (
  params: UseUserTreeParams,
): UseQueryResult<TreeRow[][] | null> => {
  const firebase = useFirebaseApp();

  const functions = getFunctions(firebase, DEFAULT_REGION);

  const getManualTree = httpsCallable(
    functions,
    'back-networkTree-getManualRegistrationTree',
  );

  const fetchUserTree = async () => {
    try {
      const { userId, brandId, parentPath, registeredUserId, userRole } =
        params;

      if (!userId || !brandId || !parentPath || !registeredUserId) {
        return [];
      }

      const { data } = await getManualTree({
        userId,
        brandId,
        parentPath,
        registeredUserId,
        role: userRole,
      });

      const trees = data as TreeRow[][];

      return trees;
    } catch (error) {
      return [];
    }
  };

  const queryResult = useQuery(
    [
      'manualRegistrationTree',
      params.parentPath,
      params.brandId,
      params.registeredUserId,
      params.userId,
      params.enabled,
    ],
    () => {
      return fetchUserTree();
    },
    {
      enabled: params.enabled,
    },
  );

  return queryResult;
};

export default useManualRegistrationTree;
