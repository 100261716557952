import React, { useContext } from 'react';
import {
  CollapseBox,
  Divider,
  Grid,
  LoadingButton,
  Typography,
  UIContext,
} from '@miyagami-com/lsx-ui-components';
import { Formik, FormikHelpers, Form } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFirebaseApp } from 'reactfire';

import { DEFAULT_REGION } from '../../../common/constants';

import PasswordOutlinedInput from '../../Unknown/PasswordOutlinedInput';

import messages from './messages';
import validationSchema from './validationSchema';
import { getFunctions, httpsCallable } from 'firebase/functions';

const initialValues = {
  newPassword: '',
  passwordConfirm: '',
};

type RenderInputs = {
  name: keyof typeof initialValues;
};

const renderPasswordInputs: RenderInputs[] = [
  {
    name: 'newPassword',
  },
  {
    name: 'passwordConfirm',
  },
];

type FormValues = typeof initialValues;

interface UserChangePasswordProps {
  brandId?: string;
  userId: string;
  isDisabled: boolean;
}

const UserChangePassword: React.FC<UserChangePasswordProps> = ({
  brandId,
  userId,
  isDisabled,
}) => {
  const intl = useIntl();
  const { setAlert } = useContext(UIContext);

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const onSubmit = async (
    { newPassword }: FormValues,
    { resetForm, setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    setSubmitting(true);
    const changePassword = httpsCallable(functions, 'back-user-changePassword');
    try {
      if (!brandId) {
        setAlert({
          show: true,
          severity: 'error',
          message: intl.formatMessage(messages.notBrandId),
        });

        return;
      }

      await changePassword({
        password: newPassword,
        userId,
        brandId,
      });

      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.successChangePassword),
      });
    } catch (error) {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.errorChangePassword),
      });
    } finally {
      resetForm();
      setSubmitting(false);
    }
  };

  return (
    <CollapseBox
      label={intl.formatMessage(messages.collapseBoxLabel)}
      defaultValue={true}
    >
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          handleChange,
          handleBlur,
          errors,
          values,
          isSubmitting,
          touched,
        }) => {
          const error = (name: keyof FormValues) =>
            Boolean(errors[name] && touched[name]);

          return (
            <Form>
              <Grid container spacing={3}>
                <Grid container marginX={3} pt={3} spacing={3}>
                  <Grid item xs={12} marginTop={3}>
                    <Typography variant="h6">
                      <FormattedMessage {...messages.label} />
                    </Typography>
                  </Grid>
                  {renderPasswordInputs.map(({ name }) => {
                    return (
                      <Grid item xs={12} md={6} key={name}>
                        <PasswordOutlinedInput
                          disabled={isDisabled}
                          label={intl.formatMessage(messages[name])}
                          name={name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values[name]}
                          helperText={errors[name]}
                          error={error(name)}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} marginX={3} marginBottom={3}>
                  <LoadingButton
                    disabled={isDisabled}
                    loading={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    <FormattedMessage {...messages.saveButton} />
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </CollapseBox>
  );
};

export default UserChangePassword;
