import { UseQueryResult, useQuery } from 'react-query';
import useBrandDomainConfig from './useBrandDomainConfig';

interface UseBrandDomainDNSConflictsParams {
  domain: string;
  brandId: string;
  onError?: (err: unknown) => void;
  onSuccess?: () => void;
}

export type DNSConflict = {
  name: string;
  type: string;
  value: string;
};

const useBrandDomainDNSConflicts = (
  params: UseBrandDomainDNSConflictsParams,
): UseQueryResult<DNSConflict[]> => {
  const { domain, brandId, onError, onSuccess } = params;

  const getDomainConfig = useBrandDomainConfig();

  const queryResult = useQuery(
    ['brandDomainConfig', domain],
    async () => {
      const dnsConflicts = getDomainConfig({ brandId, domain });

      return dnsConflicts;
    },
    {
      onError,
      onSuccess,
    },
  );

  return queryResult;
};

export default useBrandDomainDNSConflicts;
