import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { GridRowData, GridRowsProp, GridValidRowModel } from '@mui/x-data-grid';
import { format } from 'date-fns';
import messages from './messages';
import normalizeAgentsData from './normalizeAgentsData';
import sortAgentsByParentPathLength from './sortAgentsByParentPathLength';
import addPathToAgents from './addPathToAgents';
import { RootState } from '../../../../types';
import getFromToNumbers from '../../../common/getFromToNumbers';
import { AGENT_ROLE, DEFAULT_DATE_FORMAT } from '../../../common/constants';
import DefaultLayout from '../../Unknown/DefaultLayout';
import OverviewLayout from '../../Unknown/OverviewLayout';
import UserListCollapse from '../../User/UserListCollapse';
import useAgents from './useAgents';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';
import checkIsBrandOwner from '../../../common/checkIsBrandOwner';

interface QueryParams {
  brandId: string;
}

const AgentOverviewPage: React.FC = () => {
  const intl = useIntl();

  const { brandId } = useParams<QueryParams>();
  const { brands } = useSelector((root: RootState) => root.globalData);
  const { uid: userId, roles } = useSelector((root: RootState) => root.user);

  const isSystemAdmin = checkIsSystemAdmin({ roles });
  const isBrandOwner = checkIsBrandOwner({ roles });

  const [playerAgentIds, setPlayerAgentIds] = useState<string[]>([]);

  const [currentRows, setCurrentRows] = useState<GridRowsProp>([]);

  const agents = useAgents({ brandId });

  const { from, to } = useMemo(
    () => getFromToNumbers({ list: agents }),
    [agents],
  );

  const defaultAgents = useMemo(() => {
    const agentsData = normalizeAgentsData({ users: agents, brandId });

    const filteredAgents = isSystemAdmin
      ? agentsData
      : agentsData.filter((agent) => agent.status !== 'closed');

    return filteredAgents;
  }, [agents, brandId, isSystemAdmin]);

  const [normalizedAgents, setNormalizedAgents] =
    useState<GridRowData[]>(defaultAgents);

  useEffect(() => {
    const agentIds = normalizedAgents.map((agent) => {
      if (agent.role === AGENT_ROLE) {
        return agent.id;
      }
      return null;
    });

    setPlayerAgentIds(agentIds.filter((agentId) => agentId));
  }, [normalizedAgents]);

  useEffect(() => {
    const sortedAgents = sortAgentsByParentPathLength(normalizedAgents);
    const rowsWithChild = addPathToAgents(sortedAgents);

    if (isSystemAdmin || isBrandOwner) {
      setCurrentRows(rowsWithChild);
      return;
    }

    const rowsVisibleForUser = rowsWithChild.filter(
      (row: GridValidRowModel) => {
        return row.path.includes(userId);
      },
    );

    const rowsVisibleForUserCopy = rowsVisibleForUser.map(
      (row: GridValidRowModel) => {
        if (row.path.length > 1) row.path.shift();

        return row;
      },
    );

    const rowsWithoutUser = rowsVisibleForUserCopy.filter((row) => {
      return row.id !== userId;
    });

    setCurrentRows(rowsWithoutUser);
  }, [brandId, brands, normalizedAgents, userId, isSystemAdmin, isBrandOwner]);

  const path = [
    { label: intl.formatMessage(messages.playersAndAgents) },
    { label: intl.formatMessage(messages.agentOverview) },
  ];

  return (
    <DefaultLayout path={path}>
      <OverviewLayout
        headerProps={{
          name: intl.formatMessage(messages.agentOverview),
          from: format(from, DEFAULT_DATE_FORMAT),
          to: format(to, DEFAULT_DATE_FORMAT),
          brands,
          setRows: setNormalizedAgents,
          defaultRows: defaultAgents,
          withBrandFilter: true,
        }}
      >
        <UserListCollapse
          dataGridProps={{
            rows: currentRows,
          }}
          playerAgentIds={playerAgentIds}
        />
      </OverviewLayout>
    </DefaultLayout>
  );
};
export default AgentOverviewPage;
