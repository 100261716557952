import { Box, Typography } from '@miyagami-com/lsx-ui-components';
import format from 'date-fns/format';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { UseQueryResult } from 'react-query';
import {
  DEFAULT_AMOUNT,
  DEFAULT_CURRENCY,
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from '../../../common/constants';
import useBetEventStatusMap, {
  BetEventStatus,
} from '../../../common/hooks/useBetEventStatusMap';
import { EventDetail } from '../../../common/hooks/useEventDetail';
import DetailHeader, { HeaderItem } from '../../Unknown/DetailHeader';
import messages from './messages';

interface EventDetailHeaderProps {
  eventDetailQuery: UseQueryResult<EventDetail, Error>;
}

const EventDetailHeader: React.FC<EventDetailHeaderProps> = (props) => {
  const { eventDetailQuery } = props;

  const { data: eventDetail } = eventDetailQuery;

  const intl = useIntl();

  const betEventStatusMap = useBetEventStatusMap();

  if (!eventDetail) return null;

  const {
    event_id,
    event_name,
    event_open_date,
    event_type_name,
    competition_name,
    total_exposure,
  } = eventDetail;

  const eventStartOnDate = new Date(event_open_date || DEFAULT_AMOUNT);

  const formattedDate = format(eventStartOnDate, DEFAULT_DATE_FORMAT);

  const formattedTIme = format(eventStartOnDate, DEFAULT_TIME_FORMAT);

  const eventItem: HeaderItem = {
    id: event_id,
    main: <Typography variant="h4">{event_name}</Typography>,
    secondary: (
      <Typography variant="h3">
        <FormattedMessage
          {...messages.startOn}
          values={{ date: formattedDate, time: formattedTIme }}
        />
      </Typography>
    ),
  };

  const eventTypeCompetitionItem: HeaderItem = {
    id: 'eventTypeCompetitionItem',
    main: <Typography variant="h4">{event_type_name}</Typography>,
    secondary: <Typography variant="h3">{competition_name}</Typography>,
  };

  const exposure = total_exposure;

  const formattedTotalExposure = intl.formatNumber(exposure || DEFAULT_AMOUNT, {
    currency: DEFAULT_CURRENCY,
    style: 'currency',
  });

  const exchangeBetsTotalItem: HeaderItem = {
    id: 'exchangeBetsTotalItem',
    main: (
      <Typography variant="h4">
        <FormattedMessage
          {...messages.exposure}
          values={{ amount: formattedTotalExposure }}
        />
      </Typography>
    ),
  };

  const currentDate = new Date();

  const isComingEvent = eventStartOnDate.getTime() > currentDate.getTime();

  const eventStatus: BetEventStatus = isComingEvent ? 'coming' : 'in_play';

  const eventStatusParams = betEventStatusMap[eventStatus];

  const eventStatusItem: HeaderItem = {
    id: 'eventStatusItem',
    main: (
      <Box display="flex" alignItems="center">
        <Box
          width={14}
          height={14}
          borderRadius="50%"
          bgcolor={eventStatusParams.color}
          mr={1}
        />
        <Typography variant="h4">{eventStatusParams.label}</Typography>
      </Box>
    ),
  };

  const items = [
    eventItem,
    eventTypeCompetitionItem,
    exchangeBetsTotalItem,
    eventStatusItem,
  ];

  return <DetailHeader items={items} />;
};

export default EventDetailHeader;
