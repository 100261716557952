import { GlobalUsers } from '../../../../types';

const getAgentNameById = (
  users: GlobalUsers['byBrandId'],
  agentId?: string,
): string | undefined => {
  let currentUser;
  Object.values(users).forEach(({ agents }) => {
    currentUser = agents?.find(({ id }) => id === agentId)?.username;
  });
  return currentUser;
};

export default getAgentNameById;
