import { FC, useCallback, useMemo } from 'react';
import { useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { DataGridProps, GridColumns } from '@mui/x-data-grid';
import ReactCountryFlag from 'react-country-flag';
import { useIntl } from 'react-intl';
import DataGridTable from '../../Unknown/DataGridTable';
import StatusCell from '../../Unknown/StatusCell';
import ActionsBox from '../../Unknown/ActionsBox';
import messages from './messages';
// import useDeleteLanguage from '../../../common/hooks/useDeleteLanguage';
// import { UIContext } from '@miyagami-com/lsx-ui-components';
// import { useQueryClient } from 'react-query';

const propertiesColumn = {
  editable: false,
  filterable: false,
};

interface LanguageListProps {
  dataGridProps: Omit<DataGridProps, 'columns'>;
  onUpdateStatus: (args: { id: string; status: 'active' | 'inactive' }) => void;
}

const LanguageList: FC<LanguageListProps> = ({
  dataGridProps,
  onUpdateStatus,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const history = useHistory();

  // const { setAlert } = useContext(UIContext);

  // const queryClient = useQueryClient();

  // const { mutate: deleteLanguage } = useDeleteLanguage({
  //   onSuccess: () => {
  //     setAlert({
  //       show: true,
  //       severity: 'success',
  //       message: intl.formatMessage(messages.successDelete),
  //     });

  //     queryClient.refetchQueries(['getSupabaseLanguages']);
  //   },
  //   onError: () => {
  //     setAlert({
  //       show: true,
  //       severity: 'error',
  //       message: intl.formatMessage(messages.errorDelete),
  //     });
  //   },
  // });

  const languageStatuses = useMemo(() => {
    return {
      active: {
        label: intl.formatMessage(messages.active),
        color: theme.palette.success.main,
        actionLabel: intl.formatMessage(messages.deactivate),
      },
      inactive: {
        label: intl.formatMessage(messages.inactive),
        color: theme.palette.error.main,
        actionLabel: intl.formatMessage(messages.activate),
      },
    };
  }, [intl, theme.palette.error.main, theme.palette.success.main]);

  const updateStatus = useCallback(
    ({
      id,
      prevStatus,
    }: {
      id: string;
      prevStatus: keyof typeof languageStatuses;
    }) => {
      const newStatus = prevStatus === 'active' ? 'inactive' : 'active';
      onUpdateStatus({ id, status: newStatus });
    },
    [onUpdateStatus],
  );

  const columns: GridColumns = useMemo(
    () => [
      {
        ...propertiesColumn,
        field: 'id',
        hide: true,
        headerName: intl.formatMessage(messages.id),
        flex: 1,
      },
      {
        ...propertiesColumn,
        field: 'name',
        headerName: intl.formatMessage(messages.language),
        flex: 1,
      },
      {
        ...propertiesColumn,
        field: 'country_code',
        headerName: intl.formatMessage(messages.flag),
        flex: 1,
        renderCell: (params) => {
          return (
            <ReactCountryFlag
              svg
              style={{ width: 30, height: 22 }}
              countryCode={params.value}
            />
          );
        },
      },
      {
        ...propertiesColumn,
        field: 'status',
        headerName: intl.formatMessage(messages.status),
        flex: 1,
        renderCell: (params) => {
          if (!params.value) return null;
          const status = params.value as keyof typeof languageStatuses;
          const languageStatus = languageStatuses[status];

          if (!languageStatus) return null;

          return <StatusCell {...languageStatus} />;
        },
      },
      {
        ...propertiesColumn,
        field: 'action',
        headerName: intl.formatMessage(messages.actions),
        flex: 1,
        renderCell: (params) => {
          if (!params.row?.status) return null;
          const status = params.row.status as keyof typeof languageStatuses;
          const actionLabel = languageStatuses[status]?.actionLabel;

          const isDefaultRow = params.row.isDefault;

          const actions = [
            {
              label: intl.formatMessage(messages.view),
              buttonProps: {
                onClick: () => {
                  history.push(`/internalization/${params.row.id}`);
                },
              },
            },
          ];

          if (actionLabel && !isDefaultRow) {
            actions.push({
              label: actionLabel,
              buttonProps: {
                onClick: () =>
                  updateStatus({
                    id: params.row.id,
                    prevStatus: params.row.status,
                  }),
              },
            });
          }

          return <ActionsBox actions={actions} />;
        },
      },
    ],
    [history, intl, languageStatuses, updateStatus],
  );

  return (
    <DataGridTable
      {...dataGridProps}
      disableSelectionOnClick={true}
      autoHeight={true}
      rowHeight={35}
      headerHeight={35}
      columns={columns}
      pagination
      hideFooterRowCount
      hideFooterSelectedRowCount
    />
  );
};

export default LanguageList;
