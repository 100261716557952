import { defineMessages } from 'react-intl';

export default defineMessages({
  transactions: {
    id: 'Transaction.TransactionsOverview.transactions',
    defaultMessage: 'Transactions',
  },
  errorPaginationMessage: {
    id: 'Transaction.TransactionsOverview.errorPaginationMessage',
    defaultMessage: 'An error occurred while getting transactions.',
  },
  system: {
    id: 'Transaction.TransactionsOverview.system',
    defaultMessage: 'System',
  },
});
