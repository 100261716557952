import { Box, TextField } from '@miyagami-com/lsx-ui-components';
import { TextFieldProps } from '@mui/material';
import React from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import useStyles from './useStyles';

export interface DropzoneFile extends File {
  path: string;
  preview: string;
}

type TranslationsProps = {
  defaultValue?: string;
  label?: string;
  placeholder?: string;
};

export interface ImageUploadProps {
  dropzoneProps: DropzoneOptions;
  textFieldProps: Omit<
    TextFieldProps,
    'onChange' | 'name' | keyof TranslationsProps
  > & {
    name: string;
    onChange: (files: DropzoneFile[], name: string) => void;
    translations?: TranslationsProps;
  };
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  dropzoneProps,
  textFieldProps,
}) => {
  const { onChange, name, translations, ...restProps } = textFieldProps;

  const styles = useStyles();

  const { getRootProps, getInputProps, inputRef, rootRef } = useDropzone({
    ...dropzoneProps,
    onDropAccepted: (acceptedFiles) => {
      onChange(
        acceptedFiles.map(
          (file: File) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }) as DropzoneFile,
        ),
        name,
      );
    },
  });

  return (
    <Box width={1}>
      <Box ref={rootRef} height={1} {...getRootProps()}>
        <input
          disabled={restProps?.disabled}
          ref={inputRef}
          {...getInputProps()}
        />
        <TextField
          name={name}
          InputLabelProps={{ shrink: true }}
          InputProps={{ className: styles.colorSecondary }}
          {...restProps}
          {...translations}
        />
      </Box>
    </Box>
  );
};

export default ImageUpload;
