import { Box, Typography } from '@miyagami-com/lsx-ui-components';

export interface AdditionalDetailBoxProps {
  title: string;
  value: string;
}

const AdditionalDetailBox = (props: AdditionalDetailBoxProps): JSX.Element => {
  const { title, value } = props;

  return (
    <Box display="flex" alignItems="baseline">
      <Typography variant={'h3'} mb={1.5} mr={1}>
        {title}:
      </Typography>
      <Typography variant={'body1'}>{value}</Typography>
    </Box>
  );
};

export default AdditionalDetailBox;
