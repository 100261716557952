import { useMutation, UseMutationResult } from 'react-query';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../../../common/constants';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface UpdateMarketTypeConfigParams {
  brandId: string;
  ref: string;
  isDisabled: boolean;
  playerId?: string;
}

const useUpdateMarketTypeConfig = (): UseMutationResult<
  unknown,
  unknown,
  UpdateMarketTypeConfigParams,
  unknown
> => {
  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);
  const callUpdateMarketTypeConfig = httpsCallable(
    functions,
    'back-exchangeConfig-callUpdateMarketTypeConfig',
  );
  return useMutation({
    mutationFn: async (params) => {
      const { brandId, ref, isDisabled, playerId } = params;
      return callUpdateMarketTypeConfig({
        brandId,
        ref,
        isDisabled,
        playerId,
      });
    },
  });
};

export default useUpdateMarketTypeConfig;
