import { UIContext } from '@miyagami-com/lsx-ui-components';
import {
  multiFactor,
  PhoneAuthProvider,
  RecaptchaVerifier,
} from 'firebase/auth';
import { FormikHelpers } from 'formik';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import getErrorMessage from '../../../common/getErrorMessage';
import useAuth from '../../Unknown/AuthContext/useAuth';
import { useAuth as useFirebaseAuthInstance } from 'reactfire';
import { SubmitPhoneNumberParams } from '../../Unknown/AuthContext/useFirebaseAuth';
import { SubmitPhoneValues } from '../MfaForm';
import messages from './messages';

interface UseSubmitPhoneNumberParams {
  setPhoneNumber: (phone: string) => void;
  savedRecaptchaVerifier: RecaptchaVerifier | undefined;
  setSavedRecaptchaVerifier: (verifier: RecaptchaVerifier) => void;
  setVerificationId: (verificationId: string) => void;
}

const useSubmitPhoneNumber = (
  params: UseSubmitPhoneNumberParams,
): typeof onSubmitPhone => {
  const {
    setPhoneNumber,
    savedRecaptchaVerifier,
    setSavedRecaptchaVerifier,
    setVerificationId,
  } = params;

  const intl = useIntl();

  const { submitPhoneNumber } = useAuth();
  const auth = useFirebaseAuthInstance();

  const { setAlert } = useContext(UIContext);

  const onSubmitPhone = async (
    values: SubmitPhoneValues,
    { setSubmitting }: FormikHelpers<SubmitPhoneValues>,
  ) => {
    setSubmitting(true);

    setPhoneNumber(values.phone);

    if (values.isResend && savedRecaptchaVerifier) {
      savedRecaptchaVerifier.render().then((widgetId) => {
        grecaptcha.reset(widgetId);
      });
    }
    let recaptchaVerifier;

    // eslint-disable-next-line prefer-const
    if (!savedRecaptchaVerifier) {
      recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', {
        size: 'invisible',
      });
      setSavedRecaptchaVerifier(recaptchaVerifier);
    }

    try {
      const submitPhoneNumberParams: SubmitPhoneNumberParams = {
        phoneNumber: values.phone,
      };

      const submitPhoneResponse = await submitPhoneNumber(
        submitPhoneNumberParams,
      );

      if (!submitPhoneResponse)
        throw new Error(intl.formatMessage(messages.authFailed));

      const { user, phoneNumber: phone } = submitPhoneResponse;

      const session = await multiFactor(user).getSession();

      const phoneOpts = {
        phoneNumber: phone,
        session,
      };

      const phoneAuthProvider = new PhoneAuthProvider(auth);

      const verifier = recaptchaVerifier || savedRecaptchaVerifier;
      if (verifier) {
        const verId = await phoneAuthProvider.verifyPhoneNumber(
          phoneOpts,
          verifier,
        );
        setVerificationId(verId);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message = getErrorMessage(error);
      setAlert({
        show: true,
        severity: 'warning',
        message,
      });
    }
    setSubmitting(false);
  };

  return onSubmitPhone;
};

export default useSubmitPhoneNumber;
