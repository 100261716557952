import { useMutation, UseMutationResult } from 'react-query';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../../../common/constants';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface UpdateDateRangeConfigParams {
  brandId: string;
  ref: string;
  isDisabled: boolean;
  playerId?: string;
}

const useUpdateDateRangeConfig = (): UseMutationResult<
  unknown,
  unknown,
  UpdateDateRangeConfigParams,
  unknown
> => {
  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const callUpdateDateRangeConfig = httpsCallable(
    functions,
    'back-exchangeConfig-callUpdateDateRangeConfig',
  );

  return useMutation({
    mutationFn: async (params) => {
      const { brandId, ref, isDisabled, playerId } = params;
      return callUpdateDateRangeConfig({
        brandId,
        ref,
        isDisabled,
        playerId,
      });
    },
  });
};

export default useUpdateDateRangeConfig;
