import { defineMessages } from 'react-intl';

export default defineMessages({
  addNote: {
    id: 'UserNote.UserNoteAddition.addNote',
    defaultMessage: 'Add new note',
  },
  note: {
    id: 'UserNote.UserNoteAddition.note',
    defaultMessage: 'Note',
  },
  save: {
    id: 'UserNote.UserNoteAddition.save',
    defaultMessage: 'Save',
  },
  successMessage: {
    id: 'UserNote.UserNoteAddition.successMessage',
    defaultMessage: 'Note was successfully added.',
  },
  errorMessage: {
    id: 'UserNote.UserNoteAddition.errorMessage',
    defaultMessage: 'An error occurred while a note was being added.',
  },
});
