import { IntlShape } from 'react-intl';
import { BrandDomain } from '../../../../types';

export interface DomainData extends BrandDomain {
  date: string;
}

const sortedDomainsData = (
  intl: IntlShape,
  domains: BrandDomain[] | undefined,
): DomainData[] => {
  if (domains) {
    return domains.map((domain) => {
      return {
        ...domain,
        date: intl.formatDate(new Date(domain.updated_at || 0), {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }),
      };
    });
  } else {
    return [];
  }
};
export default sortedDomainsData;
