import { defineMessages } from 'react-intl';

export default defineMessages({
  addBackofficeUser: {
    id: 'BackofficeUser.BackofficeUserAddition.addBackofficeUser',
    defaultMessage: 'Add backoffice user',
  },
  textForUserCreated: {
    id: 'BackofficeUser.BackofficeUserAddition.textForUserCreated',
    defaultMessage: 'User was successfully created!',
  },
  errorCreatingUser: {
    id: 'BackofficeUser.BackofficeUserAddition.errorCreatingUser',
    defaultMessage: 'Error creating user, please contact an administrator!',
  },
});
