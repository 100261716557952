import { defineMessages } from 'react-intl';

export default defineMessages({
  selectLabel: {
    id: 'Theme.ThemeSettings.selectLabel',
    defaultMessage: 'Brand',
  },
  themeSettings: {
    id: 'Theme.ThemeSettings.themeSettings',
    defaultMessage: 'Theme settings',
  },
  name: {
    id: 'Theme.ThemeSettings.name',
    defaultMessage: 'Name',
  },
  nameExample: {
    id: 'Theme.ThemeSettings.nameExample',
    defaultMessage: 'Xbet 888',
  },
  slogan: {
    id: 'Theme.ThemeSettings.slogan',
    defaultMessage: 'Slogan',
  },
  sloganExample: {
    id: 'Theme.ThemeSettings.sloganExample',
    defaultMessage: 'Win more!',
  },
  mainThemeColor: {
    id: 'Theme.ThemeSettings.mainThemeColor',
    defaultMessage: 'Main color',
  },
  secondaryThemeColor: {
    id: 'Theme.ThemeSettings.secondaryThemeColor',
    defaultMessage: 'Secondary color',
  },
  mainThemeFont: {
    id: 'Theme.ThemeSettings.mainThemeFont',
    defaultMessage: 'Main font',
  },
  mainThemeFontExample: {
    id: 'Theme.ThemeSettings.mainThemeFontExample',
    defaultMessage: 'Helvetica',
  },
  bodyThemeFont: {
    id: 'Theme.ThemeSettings.bodyThemeFont',
    defaultMessage: 'Body font',
  },
  bodyThemeFontExample: {
    id: 'Theme.ThemeSettings.bodyThemeFontExample',
    defaultMessage: 'Aria',
  },
  save: {
    id: 'Theme.ThemeSettings.save',
    defaultMessage: 'Save',
  },
  brand: {
    id: 'Theme.ThemeSettings.brand',
    defaultMessage: 'Brand',
  },
  colors: {
    id: 'Theme.ThemeSettings.colors',
    defaultMessage: 'Colors',
  },
  typography: {
    id: 'Theme.ThemeSettings.typography',
    defaultMessage: 'Typography',
  },
  successUpdateTheme: {
    id: 'Theme.ThemeSettings.successUpdateTheme',
    defaultMessage: 'Theme has been successfully updated.',
  },
  errorUpdateTheme: {
    id: 'Theme.ThemeSettings.errorUpdateTheme',
    defaultMessage: 'Error while updating theme.',
  },
  errorUnknownBrand: {
    id: 'Theme.ThemeSettings.errorUnknownBrand',
    defaultMessage: 'Please select a brand.',
  },
});
