import { PostgrestResponse } from '@supabase/postgrest-js';
import { LogType } from '../../../../types';
import { Log } from '../../../../types/supabase';
import createSupabaseClient from '../../../common/createSupabaseClient';

interface GetUserLogsParams {
  types: LogType[];
  brandId: string;
  offset: number;
  limit: number;
}

const getBrandLogs = async ({
  types,
  brandId,
  limit,
  offset,
}: GetUserLogsParams): Promise<PostgrestResponse<Log>> => {
  const supabase = createSupabaseClient({});

  const response = await supabase
    .from('logs')
    .select('*', { count: 'exact' })
    .in('type', types)
    .eq('brand_id', brandId)
    .order('created_at', { ascending: false })
    .range(offset, limit)
    .throwOnError();

  return response;
};

export default getBrandLogs;
