import React, { useMemo } from 'react';
import { SelectChangeEvent, SelectProps } from '@mui/material';
import { Brand } from '../../../../types';

import Select from '../Select';
import { useHistory } from 'react-router';

interface SelectBrandProps extends SelectProps {
  brands: Brand[];
}

const SelectBrand: React.FC<SelectBrandProps> = ({
  brands,
  value,
  onChange: onChangeBase,
  ...otherSelectProps
}) => {
  const history = useHistory();

  const brandsMenuItems = useMemo(() => {
    const menuItems = brands.map(({ id, name }) => {
      return {
        label: name,
        value: id,
      };
    });

    const sortByName = menuItems.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return sortByName || [];
  }, [brands]);

  const onChange = async (
    e: SelectChangeEvent<unknown>,
    child: React.ReactNode,
  ) => {
    if (onChangeBase) {
      onChangeBase(e, child);
    }

    const targetValue = e.target.value as string;

    const pathName = history.location.pathname;

    const brandQuery = `/b/${value}`;

    const newPath = pathName.replace(brandQuery, `/b/${targetValue}`);

    history.push(newPath);
  };

  return (
    <Select
      {...otherSelectProps}
      onChange={onChange}
      value={value}
      menuItems={brandsMenuItems}
    />
  );
};

export default SelectBrand;
