import { Timestamp } from '@firebase/firestore-types';
import { GridGroupingValueGetterParams } from '@mui/x-data-grid-premium';

import format from 'date-fns/format';

import { DEFAULT_DATE_FORMAT } from './constants';

const groupingTimeCellGetter = (
  params: GridGroupingValueGetterParams<Timestamp>,
): string | null => {
  const date = params.value;

  const dateTime = date?.toDate();

  if (!dateTime) return null;

  const dateFormat = format(dateTime, DEFAULT_DATE_FORMAT);

  return dateFormat;
};

export default groupingTimeCellGetter;
