import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Box, CircularProgress } from '@miyagami-com/lsx-ui-components';
import { format } from 'date-fns';
import { BackofficeUserRole } from '../../../../types';
import useBrandId from '../../../common/hooks/useBrandId';
import { DEFAULT_DATE_FORMAT } from '../../../common/constants';
import { colors } from '../../../common/theme';
import OverviewLayout from '../../Unknown/OverviewLayout';
import ProfitLossReportDashboardGraph from '../ProfitLossReportDashboardGraph';
import useReportPeriodMenuItems from './useReportPeriodMenuItems';
import messages from './messages';
import useReportPeriod from './useReportPeriod';
import useReportLevelByPermissions from './useReportLevelByPermissions';
import useProfitLossReports from './useProfitLossReports';
import useConvertPeriodToFromDate from './useConvertPeriodToFromDate';
import normalizeReports from './normalizeReports';

const allowedRoles: BackofficeUserRole[] = [
  'systemAdmin',
  'superAgent',
  'masterAgent',
  'agent',
  'owner',
];

const ProfitLossReportDashboardSection: React.FC = () => {
  const { reportPeriod, changeReportPeriod } = useReportPeriod();
  const intl = useIntl();

  const brandId = useBrandId();

  const reportPeriodMenuItems = useReportPeriodMenuItems();

  const { user, reportLevel } = useReportLevelByPermissions({ brandId });

  const hasAccess = useMemo(() => {
    const userRoles = (user?.roles?.map((userRole) => userRole.role_id) ||
      []) as BackofficeUserRole[];

    return userRoles?.some((role) => allowedRoles.includes(role));
  }, [user.roles]);

  const fromToByPeriod = useConvertPeriodToFromDate();

  const { from, to } = useMemo(() => {
    return fromToByPeriod(reportPeriod);
  }, [fromToByPeriod, reportPeriod]);

  const isAdminLevel = reportLevel === 'systemAdmin';

  const parentId = isAdminLevel ? null : user.uid;

  const { data: reports, isLoading } = useProfitLossReports({
    reportLevel,
    brandId,
    period: reportPeriod,
    isEnabled: !!(reportLevel && hasAccess),
    userId: parentId,
  });

  const graphItems = useMemo(() => {
    return normalizeReports({ reports });
  }, [reports]);

  if (!hasAccess || !reportLevel) return null;

  return (
    <OverviewLayout
      headerProps={{
        name: intl.formatMessage(messages.profitLoss),
        from: format(new Date(from), DEFAULT_DATE_FORMAT),
        to: format(new Date(to), DEFAULT_DATE_FORMAT),
        datePeriodSelectProps: {
          minWidth: 150,
          menuItems: reportPeriodMenuItems,
          onChange: changeReportPeriod,
          value: reportPeriod,
          label: intl.formatMessage(messages.period),
        },
      }}
    >
      <Box position="relative">
        <ProfitLossReportDashboardGraph
          data={graphItems}
          reportPeriod={reportPeriod}
        />
        {isLoading && (
          <Box
            bgcolor={colors.actionDisabledBackground}
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            display="flex"
            zIndex={1}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </OverviewLayout>
  );
};

export default ProfitLossReportDashboardSection;
