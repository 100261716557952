import { UIContext } from '@miyagami-com/lsx-ui-components';
import { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useFirebaseApp } from 'reactfire';
import { TreeRow } from '../../components/Network/NetworkTreeList/createTreeRow';
import { DEFAULT_AMOUNT, DEFAULT_REGION } from '../constants';
import messages from '../messages';
import { UseQueryResult, useQuery } from 'react-query';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface UseUserTreeParams {
  userId: string;
  brandId: string;
  parentPath: string | null;
  enabled?: boolean;
}

const useUserTree = (
  params: UseUserTreeParams,
): UseQueryResult<TreeRow[][] | null> => {
  const { setAlert } = useContext(UIContext);

  const intl = useIntl();

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const getUserTree = httpsCallable(functions, 'back-networkTree-getUserTree');

  const fetchPlayerTree = useCallback(async () => {
    try {
      const { data } = await getUserTree({
        userId: params.userId,
        brandId: params.brandId,
        parentPath: params.parentPath,
      });

      const trees = data as TreeRow[][];

      const sortedTrees = trees.map((treeData) => {
        const sortedTree = treeData.sort(
          (a, b) =>
            (a?.sortPriority || DEFAULT_AMOUNT) -
            (b?.sortPriority || DEFAULT_AMOUNT),
        );

        return sortedTree;
      });

      return sortedTrees;
    } catch (error) {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.failedUploadNetworkTree),
      });
      return [];
    }
  }, [getUserTree, intl, params, setAlert]);

  const queryResult = useQuery(
    ['userTree', params.userId],
    () => {
      return fetchPlayerTree();
    },
    {
      enabled: params.enabled,
    },
  );

  return queryResult;
};

export default useUserTree;
