/* eslint-disable import/no-extraneous-dependencies */

import {
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  MultiFactorResolver,
} from 'firebase/auth';
import { AlertColor } from '@mui/material';
import { Dispatch } from 'redux';
import { History } from 'history';

import { loginUserAction } from '../../store/user';
import apiClient from '../apiClient';
import { SignInResponse } from '../signIn';

interface AlertProps {
  show: boolean;
  severity?: AlertColor | undefined;
  message?: string;
}

type ConfirmMfaCodeProps = {
  values: { code: string };
  verificationId: string | null;
  resolver: MultiFactorResolver;
  dispatch: Dispatch<any>; // eslint-disable-line
  setAlert: (value: React.SetStateAction<AlertProps>) => void;
  history: History<unknown>;
  setSubmitting: (isSubmitting: boolean) => void;
  messageForResetPassword?: string;
};

const confirmMfaCode = async ({
  values,
  verificationId,
  resolver,
  dispatch,
  setAlert,
  history,
  setSubmitting,
  messageForResetPassword,
}: ConfirmMfaCodeProps): Promise<void> => {
  try {
    if (verificationId && resolver) {
      const credential = PhoneAuthProvider.credential(
        verificationId,
        values.code,
      );

      const multiFactorAssertion =
        PhoneMultiFactorGenerator.assertion(credential);

      const userCredential = await resolver.resolveSignIn(multiFactorAssertion);

      if (userCredential.user) {
        const idTokenResult = await userCredential.user.getIdTokenResult();

        const { data } = await apiClient.post<SignInResponse>(
          '/back-auth-signIn',
          {
            idToken: idTokenResult.token,
          },
        );

        dispatch(loginUserAction({ ...data.user }));

        history.push('/');

        if (messageForResetPassword) {
          setAlert({
            show: true,
            severity: 'success',
            message: messageForResetPassword,
          });
        }
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    let message = error?.message;
    if (error?.response?.data?.message) {
      message = error.response.data.message;
    }
    setAlert({
      show: true,
      severity: 'error',
      message,
    });
  } finally {
    setSubmitting(false);
  }
};

export default confirmMfaCode;
