import { useQuery } from 'react-query';
import useSupabase from './useSupabase';
import { Location } from '../../../types/supabase';

const useLocationList = (): typeof queryResult => {
  const supabase = useSupabase();

  const queryResult = useQuery<Location[] | null, Error>(
    ['locations'],
    async () => {
      const response = await supabase
        .from('locations')
        .select('*')
        .throwOnError();

      return response.data;
    },
  );

  return queryResult;
};

export default useLocationList;
