import { useState, useMemo } from 'react';
import { CollapseBox } from '@miyagami-com/lsx-ui-components';
import DataGridTable from '../../Unknown/DataGridTable';
import { useQuery } from 'react-query';
import getUserLogs from '../../Log/LogUserList/getUserLogs';
import messages from './messages';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from '../../../common/constants';

type Params = {
  userId: string;
};

const NetworkLogs = (params: Params): JSX.Element => {
  const { userId } = params;

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(
    DEFAULT_ROWS_PER_PAGE_OPTIONS[5],
  );

  const type = 'system';
  const offset = page * pageSize;
  const limit = offset + pageSize - 1;

  const { data: logsData, isLoading } = useQuery(
    ['getLogs', type, userId, offset, limit],
    () => getUserLogs({ type, userId, offset, limit }),
  );

  const rows = useMemo(
    () =>
      logsData?.data?.map((log) => ({
        ...log,
        time: new Date(log.created_at).toLocaleString(),
      })) || [],
    [logsData],
  );

  const onPageSizeChange = (newPageSize: number): void => {
    setPageSize(newPageSize);
  };

  return (
    <CollapseBox defaultValue label={messages.networkLogs.defaultMessage}>
      <DataGridTable
        disableSelectionOnClick
        autoHeight
        rowHeight={32}
        hideFooterRowCount
        pagination
        loading={isLoading}
        columns={[
          { field: 'time', headerName: 'Time' },
          { field: 'message', headerName: 'Message', flex: 1 },
          { field: 'ip_address', headerName: 'IP Address' },
        ]}
        rows={rows}
        onPageSizeChange={onPageSizeChange}
        pageSize={pageSize}
        page={page}
        onPageChange={setPage}
      />
    </CollapseBox>
  );
};

export default NetworkLogs;
