import { defineMessages } from 'react-intl';

export default defineMessages({
  reports: {
    id: 'ProfitLoss.ProfitLossReportPage.reports',
    defaultMessage: 'Reports',
  },
  plReport: {
    id: 'ProfitLoss.ProfitLossReportPage.plReport',
    defaultMessage: 'P/L Report',
  },
  system: {
    id: 'ProfitLoss.ProfitLossReportPage.system',
    defaultMessage: 'System',
  },
  player: {
    id: 'ProfitLoss.ProfitLossReportPage.player',
    defaultMessage: 'Player',
  },
  systemLevel: {
    id: 'ProfitLoss.ProfitLossReportPage.systemLevel',
    defaultMessage: 'System level',
  },
  brand: {
    id: 'ProfitLoss.ProfitLossReportPage.brand',
    defaultMessage: 'Brand',
  },
  brandLevel: {
    id: 'ProfitLoss.ProfitLossReportPage.brandLevel',
    defaultMessage: 'Brand level ({name})',
  },
  superAgent: {
    id: 'ProfitLoss.ProfitLossReportPage.superAgent',
    defaultMessage: 'Super agent',
  },
  superAgentLevel: {
    id: 'ProfitLoss.ProfitLossReportPage.superAgentLevel',
    defaultMessage: 'Super agent level ({name})',
  },
  masterAgent: {
    id: 'ProfitLoss.ProfitLossReportPage.masterAgent',
    defaultMessage: 'Master agent',
  },
  masterAgentLevel: {
    id: 'ProfitLoss.ProfitLossReportPage.masterAgentLevel',
    defaultMessage: 'Master agent level ({name})',
  },
  agent: {
    id: 'ProfitLoss.ProfitLossReportPage.agent',
    defaultMessage: 'Agent',
  },
  agentLevel: {
    id: 'ProfitLoss.ProfitLossReportPage.agentLevel',
    defaultMessage: 'Agent level ({name})',
  },
  from: {
    id: 'ProfitLoss.ProfitLossReportPage.from',
    defaultMessage: 'From',
  },
  to: {
    id: 'ProfitLoss.ProfitLossReportPage.to',
    defaultMessage: 'To',
  },
  period: {
    id: 'ProfitLoss.ProfitLossReportPage.period',
    defaultMessage: 'Period',
  },
  today: {
    id: 'ProfitLoss.ProfitLossReportPage.today',
    defaultMessage: 'Today',
  },
  yesterday: {
    id: 'ProfitLoss.ProfitLossReportPage.yesterday',
    defaultMessage: 'Yesterday',
  },
  lastWeek: {
    id: 'ProfitLoss.ProfitLossReportPage.lastWeek',
    defaultMessage: 'Last week',
  },
  lastMonth: {
    id: 'ProfitLoss.ProfitLossReportPage.lastMonth',
    defaultMessage: 'Last month',
  },
  custom: {
    id: 'ProfitLoss.ProfitLossReportPage.custom',
    defaultMessage: 'Custom',
  },
  platform: {
    id: 'ProfitLoss.ProfitLossReportPage.platform',
    defaultMessage: 'Platform',
  },
});
