import { UserRole } from '../../../../types';
import { DEFAULT_AMOUNT } from '../../../common/constants';

interface CreateTreeRowParams {
  id: string;
  name?: string;
  role?: UserRole;
  positionTakingRate?: number;
  commissionRate?: number;
  minPositionTakingRate?: number;
  maxPositionTakingRate?: number;
  extraPositionTakingRate?: number;
  isExtraPositionTakingEnabled?: boolean;
}

export type TreeRow = {
  id: string;
  name: string | null;
  role: string | null;
  positionTakingRate: number;
  commissionRate: number;
  sortPriority?: number;
  isLoading?: boolean;
  minPositionTakingRate?: number;
  maxPositionTakingRate?: number;
  extraPositionTakingRate?: number;
  isExtraPTEnabled?: boolean;
  childUserId?: string;
};

const createTreeRow = ({
  role,
  positionTakingRate,
  commissionRate,
  name,
  id,
  minPositionTakingRate,
  extraPositionTakingRate,
  maxPositionTakingRate,
  isExtraPositionTakingEnabled,
}: CreateTreeRowParams): TreeRow => {
  return {
    id,
    name: name || null,
    role: role || null,
    commissionRate: commissionRate || DEFAULT_AMOUNT,
    positionTakingRate: positionTakingRate || DEFAULT_AMOUNT,
    isLoading: false,
    minPositionTakingRate: minPositionTakingRate || DEFAULT_AMOUNT,
    maxPositionTakingRate: maxPositionTakingRate || DEFAULT_AMOUNT,
    extraPositionTakingRate: extraPositionTakingRate || DEFAULT_AMOUNT,
    isExtraPTEnabled: isExtraPositionTakingEnabled,
  };
};

export default createTreeRow;
