import { UIContext } from '@miyagami-com/lsx-ui-components';
import { FormikHelpers } from 'formik';
import { useCallback, useContext } from 'react';
import getErrorMessage from '../../../common/getErrorMessage';
import useAuth, {
  SubmitMfaCodeParams,
} from '../../Unknown/AuthContext/useAuth';
import { SubmitCodeValues, SubmitPhoneValues } from '../MfaForm';

interface UseSubmitMfaCodeParams {
  verificationId: string | null;
}

const useSubmitMfaCode = (
  params: UseSubmitMfaCodeParams,
): typeof onSubmitMfaCode => {
  const { verificationId } = params;

  const { submitMfaCode } = useAuth();

  const { setAlert } = useContext(UIContext);

  const onSubmitMfaCode = useCallback(
    async (
      values: SubmitCodeValues,
      { setSubmitting }: FormikHelpers<SubmitPhoneValues>,
    ) => {
      setSubmitting(true);
      try {
        const submitMfaCodeParams: SubmitMfaCodeParams = {
          verificationId,
          verificationCode: values.code,
          type: 'signUp',
        };

        await submitMfaCode(submitMfaCodeParams);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        const message = getErrorMessage(error);
        setAlert({
          show: true,
          severity: 'warning',
          message: message,
        });
      } finally {
        setSubmitting(false);
      }
    },
    [setAlert, submitMfaCode, verificationId],
  );

  return onSubmitMfaCode;
};

export default useSubmitMfaCode;
