import {
  GridColumns,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import format from 'date-fns/format';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '../../../../types';
import {
  DEFAULT_AMOUNT,
  DEFAULT_DATE_TIME_FORMAT,
} from '../../../common/constants';
import useBetEventStatusMap, {
  BetEventStatus,
} from '../../../common/hooks/useBetEventStatusMap';
import useBrandId from '../../../common/hooks/useBrandId';
import { ExtendedBetEvent } from '../../../common/hooks/useEventOverviewBets';
import ActionsBox from '../../Unknown/ActionsBox';
// import Currency from '../../Unknown/Currency';
import StatusCell from '../../Unknown/StatusCell';
import messages from './messages';

const propertiesColumn = {
  editable: false,
  filterable: false,
  flex: 1,
};

const useColumns = (): GridColumns<ExtendedBetEvent> => {
  const brandId = useBrandId();

  const intl = useIntl();

  const history = useHistory();

  const betEventStatusMap = useBetEventStatusMap();

  const { permissionMap } = useSelector((root: RootState) => root.user);

  const onPushToDetail = useCallback(
    (id: string) => {
      history.push(`/b/${brandId}/event-overview/${id}`);
    },
    [brandId, history],
  );

  const columns: GridColumns<ExtendedBetEvent> = [
    {
      ...propertiesColumn,
      field: 'id',
      hide: true,
      headerName: intl.formatMessage(messages.id),
    },
    {
      ...propertiesColumn,
      field: 'eventOpenDate',
      headerName: intl.formatMessage(messages.openDate),
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        const { value } = params;

        if (!value) return null;

        const date = new Date(value);

        return format(date, DEFAULT_DATE_TIME_FORMAT);
      },
    },
    {
      ...propertiesColumn,
      field: 'status',
      headerName: intl.formatMessage(messages.status),
      renderCell: (params: GridRenderCellParams<BetEventStatus>) => {
        const { eventOpenDate } = params.row;

        if (!eventOpenDate) return null;

        const eventStartOnDate = new Date(eventOpenDate || DEFAULT_AMOUNT);

        const currentDate = new Date();

        const isComingEvent =
          eventStartOnDate.getTime() > currentDate.getTime();

        const eventStatus: BetEventStatus = isComingEvent
          ? 'coming'
          : 'in_play';

        const cellParams = betEventStatusMap[eventStatus];

        return <StatusCell {...cellParams} />;
      },
    },
    {
      ...propertiesColumn,
      field: 'eventName',
      headerName: intl.formatMessage(messages.eventName),
    },
    {
      ...propertiesColumn,
      field: 'marketName',
      headerName: intl.formatMessage(messages.marketName),
    },
    {
      ...propertiesColumn,
      field: 'exposure',
      headerName: intl.formatMessage(messages.exposure),
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        const exposure = params?.value;

        if (!exposure) {
          return null;
        }

        // Round to 2 decimal places
        return exposure.toFixed(2);
      },
      // renderCell: (params: GridRenderCellParams<number>) => {
      //   const { value } = params;

      //   if (!value) return null;

      //   return <Currency value={value} />;
      // },
    },
    {
      ...propertiesColumn,
      field: 'actions',
      groupable: false,
      headerName: intl.formatMessage(messages.actions),
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.row) return null;

        const { id } = params.row;

        const brandPermissionMap = permissionMap[brandId];

        const rolePermission = brandPermissionMap?.event;

        const actions = [];

        const isHaveReadAccess = !!rolePermission?.read;

        if (isHaveReadAccess) {
          const editAction = {
            label: intl.formatMessage(messages.viewEvent),
            buttonProps: {
              onClick: () => onPushToDetail(id),
            },
          };

          actions.push(editAction);
        }

        return <ActionsBox actions={actions} />;
      },
    },
  ];

  return columns;
};

export default useColumns;
