import { useSelector } from 'react-redux';
import { ExtendedBackOfficeUser, RootState } from '../../../../types';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';
import checkIsBrandOwner from '../../../common/checkIsBrandOwner';
import { useMemo } from 'react';

interface UseAgentsParams {
  brandId: string;
}

const useAgents = (params: UseAgentsParams): ExtendedBackOfficeUser[] => {
  const { brandId } = params;

  const { roles, uid } = useSelector((root: RootState) => root.user);

  const {
    users: { byBrandId: users },
  } = useSelector((root: RootState) => root.globalData);

  const isSystemAdmin = checkIsSystemAdmin({ roles });

  const isBrandOwner = checkIsBrandOwner({ roles });

  const brandAgents = useMemo(
    () => users[brandId]?.agents || [],
    [brandId, users],
  );

  const agentUsers = useMemo(() => {
    const currentAgent = brandAgents.find((user) => user.id === uid);

    if (!currentAgent) return [];

    const { parent_path } = currentAgent;

    const [, , ...parentUserIds] = parent_path?.split('/') || [];

    const availableParentUserIds = [...parentUserIds, uid];

    return brandAgents.filter((agent) => {
      const agentParentIds = agent.parent_path?.split('/') || [];

      const isAvailableUser = availableParentUserIds.includes(agent.id);

      if (isAvailableUser) return isAvailableUser;

      const isTreeUser = agentParentIds.some((parentId) =>
        availableParentUserIds.includes(parentId),
      );

      return isTreeUser;
    });
  }, [brandAgents, uid]);

  if (isBrandOwner || isSystemAdmin) {
    return brandAgents;
  }

  return agentUsers;
};

export default useAgents;
