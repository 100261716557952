import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Box, CollapseBox, UIContext } from '@miyagami-com/lsx-ui-components';
import messages from './messages';
import sortedDomainsData from './sortedDomainsData';
import BrandDomainList from '../BrandDomainList';
import BrandDomainAddition from '../BrandDomainAddition';
import { FormikHelpers } from 'formik';
import useBrandDomains from '../../../common/hooks/useBrandDomains';
import useAddBrandDomain from '../../../common/hooks/useAddBrandDomain';
import { SELECT_EXTENDED_BRAND_DOMAIN } from '../../../common/constants';
import { Timestamp } from 'firebase/firestore';

type BrandDomainsProps = {
  brandId: string;
};

export type DomainFormValues = {
  domain: string;
};

export const serverStamp = Timestamp;

const BrandDomains: React.FC<BrandDomainsProps> = ({ brandId }) => {
  const { setAlert } = useContext(UIContext);
  const intl = useIntl();

  const {
    data: domains,
    isLoading,
    refetch,
  } = useBrandDomains({ brandId, select: SELECT_EXTENDED_BRAND_DOMAIN });

  const addDomain = useAddBrandDomain();

  const sortedDomains = sortedDomainsData(intl, domains || []);

  const onAddDomain = async (
    { domain }: DomainFormValues,
    { setSubmitting, resetForm }: FormikHelpers<DomainFormValues>,
  ) => {
    try {
      setSubmitting(true);

      await addDomain({ domain, brandId });

      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.domainAddition),
      });
    } catch (error) {
      setAlert({
        show: true,
        severity: 'error',
        message:
          error instanceof Error
            ? error.message
            : intl.formatMessage(messages.domainAdditionError),
      });
    } finally {
      setSubmitting(false);
      resetForm();
      refetch();
    }
  };

  return (
    <CollapseBox
      defaultValue={true}
      label={intl.formatMessage(messages.domains)}
    >
      <Box>
        <BrandDomainList
          rows={sortedDomains ? sortedDomains : []}
          brandId={brandId}
          loading={isLoading}
        />
        <BrandDomainAddition onSubmit={onAddDomain} />
      </Box>
    </CollapseBox>
  );
};

export default BrandDomains;
