import React from 'react';
import { Box, Divider, Typography } from '@miyagami-com/lsx-ui-components';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import messages from './messages';
import useStyles from './useStyles';

const PerformanceOverviewChart: React.FC = () => {
  const intl = useIntl();
  const classes = useStyles();

  const data = [
    {
      time: '18 May',
      cpu: 0,
      database: 0,
      businessRule: 0,
      network: 0,
      concurrency: 0,
    },
    {
      time: '01:00',
      cpu: 0,
      database: 0,
      businessRule: 0,
      network: 0,
      concurrency: 0,
    },
    {
      time: '02:00',
      cpu: 0,
      database: 0,
      businessRule: 0,
      network: 0,
      concurrency: 0,
    },
    {
      time: '03:00',
      cpu: 0,
      database: 0,
      businessRule: 0,
      network: 0,
      concurrency: 0,
    },
    {
      time: '04:00',
      cpu: 0,
      database: 0,
      businessRule: 0,
      network: 0,
      concurrency: 0,
    },
    {
      time: '05:00',
      cpu: 0.1,
      database: 0.5,
      businessRule: 1,
      network: 0.6,
      concurrency: 0.3,
    },
    {
      time: '06:00',
      cpu: 0.2,
      database: 0.8,
      businessRule: 1.2,
      network: 0.8,
      concurrency: 0.5,
    },
    {
      time: '07:00',
      cpu: 0.3,
      database: 0.7,
      businessRule: 1.4,
      network: 0.4,
      concurrency: 0.5,
    },
    {
      time: '08:00',
      cpu: 0.4,
      database: 0.8,
      businessRule: 2,
      network: 0.8,
      concurrency: 0.5,
    },
    {
      time: '09:00',
      cpu: 0.5,
      database: 1,
      businessRule: 2,
      network: 1,
      concurrency: 0.5,
    },
    {
      time: '10:00',
      cpu: 0.5,
      database: 1,
      businessRule: 2,
      network: 1,
      concurrency: 0.5,
    },
    {
      time: '11:00',
      cpu: 0.7,
      database: 1,
      businessRule: 2,
      network: 1.3,
      concurrency: 0.5,
    },
    {
      time: '12:00',
      cpu: 1,
      database: 1,
      businessRule: 2,
      network: 0.5,
      concurrency: 0.5,
    },
    {
      time: '13:00',
      cpu: 0.5,
      database: 1,
      businessRule: 2,
      network: 1,
      concurrency: 0.5,
    },
    {
      time: '14:00',
      cpu: 0.5,
      database: 1,
      businessRule: 2,
      network: 1,
      concurrency: 0.5,
    },
    {
      time: '15:00',
      cpu: 0.5,
      database: 1,
      businessRule: 2,
      network: 1,
      concurrency: 0.5,
    },
    {
      time: '16:00',
      cpu: 0.5,
      database: 1,
      businessRule: 2,
      network: 1,
      concurrency: 0.5,
    },
    {
      time: '17:00',
      cpu: 0.5,
      database: 1,
      businessRule: 2,
      network: 1,
      concurrency: 0.5,
    },
    {
      time: '18:00',
      cpu: 0.5,
      database: 1,
      businessRule: 2,
      network: 1,
      concurrency: 0.5,
    },
    {
      time: '19:00',
      cpu: 0.5,
      database: 1,
      businessRule: 2,
      network: 1,
      concurrency: 0.5,
    },
    {
      time: '20:00',
      cpu: 0.5,
      database: 1,
      businessRule: 2,
      network: 1,
      concurrency: 0.5,
    },
    {
      time: '21:00',
      cpu: 0.5,
      database: 1,
      businessRule: 2,
      network: 1,
      concurrency: 0.5,
    },
    {
      time: '22:00',
      cpu: 0.5,
      database: 1,
      businessRule: 2,
      network: 1,
      concurrency: 0.5,
    },
    {
      time: '23:00',
      cpu: 0.5,
      database: 1,
      businessRule: 2,
      network: 1,
      concurrency: 0.5,
    },
    {
      time: '24:00',
      cpu: 0.5,
      database: 1,
      businessRule: 2,
      network: 1,
      concurrency: 0.5,
    },
  ];

  return (
    <>
      <Box>
        <Box pt={3} width={1} display="flex" justifyContent="center">
          <Typography variant="h6">
            <FormattedMessage {...messages.systemOverview} />
          </Typography>
        </Box>
        <ResponsiveContainer width="100%" height={450}>
          <BarChart
            data={data}
            margin={{
              top: 15,
              right: 15,
              left: 15,
              bottom: 15,
            }}
            barGap={0}
            barCategoryGap={-0.5}
          >
            <CartesianGrid strokeDasharray="0 0" vertical={false} />
            <XAxis dataKey="time" name="Time" interval={2} />
            <YAxis
              ticks={[0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6]}
              domain={[0, 6]}
              label={{
                value: intl.formatMessage(messages.activeThreads),
                angle: -90,
                position: 'insideLeft',
                offset: 10,
                className: classes.label,
              }}
            />
            <Tooltip />
            <Legend iconType="circle" />
            <Bar dataKey="cpu" name="CPU" stackId="a" fill="#82ca9d" />
            <Bar
              dataKey="database"
              name="Database"
              stackId="a"
              fill="#6660ce"
            />
            <Bar
              dataKey="businessRule"
              name="Business Rule"
              stackId="a"
              fill="#f66707"
            />
            <Bar dataKey="network" name="Network" stackId="a" fill="#EEE396" />
            <Bar
              dataKey="concurrency"
              name="Concurrency"
              stackId="a"
              fill="#f05c5c"
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
      <Divider />
    </>
  );
};

export default PerformanceOverviewChart;
