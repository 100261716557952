import { Grid, Paper } from '@miyagami-com/lsx-ui-components';
import React from 'react';
import CurrencyTable from '../../Currency/CurrencyTable';
import useCurrencyParams from '../../../common/hooks/useCurrencyParams';

interface PlayerCurrencyPageProps {
  brandId: string;
  playerId: string;
}

const PlayerCurrencyPage: React.FC<PlayerCurrencyPageProps> = (props) => {
  const { playerId, brandId } = props;

  const currencyQuery = useCurrencyParams({
    brandId,
    playerId,
  });

  return (
    <Grid container spacing={4} pt={4}>
      <Grid item xs={12}>
        <Paper>
          <CurrencyTable
            currencyQuery={currencyQuery}
            playerId={playerId}
            brandId={brandId}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PlayerCurrencyPage;
