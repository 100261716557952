import { defineMessages } from 'react-intl';

export default defineMessages({
  submit: {
    id: 'MFA.submit',
    defaultMessage: 'Submit',
  },
  returnToSite: {
    id: 'MFA.returnToSite',
    defaultMessage: 'return to site',
  },
  confirm: {
    id: 'MFA.confirm',
    defaultMessage: 'Confirm',
  },
  downloadApp: {
    id: 'MFA.downloadApp',
    defaultMessage: 'Download app',
  },
  downloadAppDescription: {
    id: 'MFA.downloadAppDescription',
    defaultMessage:
      'Use a mobile app like Google Authenticator to generate verification codes.',
  },
  scanQRCode: {
    id: 'MFA.scanQRCode',
    defaultMessage: 'Scan QR code',
  },
  scanQRDescription: {
    id: 'MFA.scanQRDescription',
    defaultMessage:
      'Scan the QR code with your 2FA app to get a verification code.',
  },
  enterOTP: {
    id: 'MFA.enterOTP',
    defaultMessage: 'Enter OTP',
  },
  enterOTPDescription: {
    id: 'MFA.enterOTPDescription',
    defaultMessage: 'Enter the OTP code from your 2FA app.',
  },
  manuallyDescription: {
    id: 'MFA.manuallyDescription',
    defaultMessage: 'Can not scan the QR code? Enter this code manually:',
  },
  verify: {
    id: 'MFA.verify',
    defaultMessage: 'Verify',
  },
  confirmTOTP: {
    id: 'MFA.confirmTOTP',
    defaultMessage: 'Confirm TOTP',
  },
  confirmTOTPDescription: {
    id: 'MFA.confirmTOTPDescription',
    defaultMessage: 'Enter the code from the app to confirm TOTP.',
  },
  invalidCode: {
    id: 'MFA.invalidCode',
    defaultMessage: 'Invalid code',
  },
  unknownError: {
    id: 'MFA.unknownError',
    defaultMessage: 'Unknown error',
  },
  wrongPassword: {
    id: 'MFA.wrongPassword',
    defaultMessage: 'Wrong password',
  },
  recaptchaNotFound: {
    id: 'MFA.recaptchaNotFound',
    defaultMessage: 'Recaptcha not found',
  },
  enterPassword: {
    id: 'MFA.enterPassword',
    defaultMessage: 'Enter password',
  },
  enableTotpAuthentication: {
    id: 'MFA.enableTotpAuthentication',
    defaultMessage: 'Enable Two-Factor Authentication',
  },
  enableTotpAuthenticationDescription: {
    id: 'MFA.enableTotpAuthenticationDescription',
    defaultMessage:
      'Add an extra layer of security to your account by enabling Two-Factor Authentication.',
  },
  totpAuthentication: {
    id: 'MFA.totpAuthentication',
    defaultMessage: 'Two-Factor authentication',
  },
  totpAuthenticationDescription: {
    id: 'MFA.totpAuthenticationDescription',
    defaultMessage: 'Enter the TOTP code from the app.',
  },
  enterYourPassword: {
    id: 'MFA.enterYourPassword',
    defaultMessage: 'Enter your password',
  },
  codeRequired: {
    id: 'MFA.codeRequired',
    defaultMessage: 'Code is required',
  },
  revokeTotp: {
    id: 'MFA.revokeTotp',
    defaultMessage: 'Revoke TOTP',
  },
  revokeTotpDescription: {
    id: 'MFA.revokeTotpDescription',
    defaultMessage: 'Do you want to revoke TOTP?',
  },
});
