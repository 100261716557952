import { alpha, createTheme, ThemeOptions, Theme } from '@mui/material/styles';
import { commonTheme } from '@miyagami-com/lsx-ui-components';

const opacity = {
  none: 1,
  s: 0.04,
  xs: 0.08,
  xxs: 0.12,
  m: 0.26,
  xm: 0.38,
  l: 0.54,
  xl: 0.6,
  xxl: 0.7,
  xxxl: 0.87,
};

export const colors = {
  contrastWhite: '#FFFFFF',
  contrastBlack: '#000000',
  primaryMain: '#B0D7FB',
  primaryLight: '#DFEEFD',
  primaryDark: '#3F51B5',
  secondaryMain: '#F2CBD1',
  secondaryLight: '#FAEAEE',
  secondaryDark: '#C51162',
  textPrimary: alpha('#000000', opacity.xxl),
  textSecondary: alpha('#000000', opacity.xl),
  textDisabled: alpha('#000000', opacity.xm),
  actionActive: alpha('#000000', opacity.l),
  actionHover: alpha('#000000', opacity.s),
  actionSelected: alpha('#000000', opacity.xs),
  actionDisabled: alpha('#000000', opacity.m),
  actionDisabledBackground: alpha('#000000', opacity.xxs),
  actionFocus: alpha('#000000', opacity.xxs),
  errorMain: '#F44336',
  errorDark: '#E31B0C',
  errorLight: '#F88078',
  infoMain: '#2196F3',
  infoDark: '#0B79D0',
  infoLight: '#FFB400',
  warningMain: '#FF9800',
  warningDark: '#C77700',
  warningLight: '#FFB547',
  successMain: '#4CAF50',
  successDark: '#3B873E',
  successLight: '#7BC67E',
  grey800: '#424242',
  grey400: '#BDBDBD',
  grey300: '#E0E0E0',
  grey900: '#212121',
  grey200: '#EEEEEE',
  grey100: '#F5F5F5',
  grey50: '#FAFAFA',
  whiteLight: '#FAFAFA',
  whiteDark: alpha('#FFFFFF', opacity.l),
  blueDark: '#3F51B5',
  suspended: alpha('#FFFFFF', opacity.xl),
  backgroundTypeA: '#E3E3E3',
};

const defaultTheme: Theme = createTheme({
  spacing: 5,
  breakpoints: {
    values: {
      xs: 0,
      sm: 675,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
});

export const borderWidth = {
  xl: 8,
  l: 4,
  m: 2,
  s: 1,
  none: 0,
};

const primaryFontFamily = `Roboto, UniversalSans,"system-ui",-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,Noto Sans,"sans-serif",Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;`;

const objTheme = commonTheme({
  primaryFontFamily,
  defaultTheme,
  colors,
  borderWidth,
}) as unknown as ThemeOptions;

const palette = {
  ...objTheme.palette,
  background: {
    ...objTheme.palette?.background,
    typeA: colors.backgroundTypeA,
  },
  grey: {
    ...objTheme.palette?.grey,
    [50]: colors.grey50,
    [100]: colors.grey100,
    [200]: colors.grey200,
    [300]: colors.grey300,
  },
};

const theme = createTheme({
  ...objTheme,
  typography: {
    body2: {
      fontSize: 10,
      lineHeight: '14.3px',
      letterSpacing: '0.15px',
    },
    body1: {
      fontSize: 12,
      lineHeight: '17.16px',
      letterSpacing: '0.15px',
    },
    h1: {
      fontSize: 32,
      lineHeight: '48px',
      letterSpacing: '0.15px',
      fontWeight: 900,
    },
    h2: {
      fontSize: 24,
      lineHeight: '24px',
      letterSpacing: '0.15px',
      fontWeight: 900,
    },
    h3: {
      fontSize: 12,
      fontWeight: 700,
      letterSpacing: '0.15px',
    },
    h4: {
      fontSize: 16,
      fontWeight: 700,
      letterSpacing: '0.15px',
    },
    h5: {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: '0.15px',
    },
    h6: {
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '0.15px',
      fontWeight: 400,
    },
  },
  components: {
    ...objTheme.components,
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100vh',
        },
        '#root': {
          height: '100%',
        },
        body: {
          height: '100%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: colors.textPrimary,
        },
        text: {
          textTransform: 'none',
          padding: 0,
          paddingLeft: defaultTheme.spacing(1),
          paddingRight: defaultTheme.spacing(1),
          minWidth: 'auto',
        },
        textPrimary: {
          color: colors.contrastWhite,
        },
        containedPrimary: {
          fontSize: 14,
          backgroundColor: colors.grey900,
          color: colors.contrastWhite,
          '&:hover': {
            backgroundColor: colors.grey900,
            '@media (hover: none)': {
              backgroundColor: colors.grey900,
            },
          },
          [defaultTheme.breakpoints.up('md')]: {
            color: colors.contrastWhite,
          },
        },
        colorInherit: {
          color: colors.primaryDark,
          fontWeight: 400,
        },
        startIcon: {
          '&>*:nth-of-type(1)': {
            fontSize: 10,
          },
          marginRight: 0,
          marginLeft: 0,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {},
      },
    },
    MuiDivider: {
      styleOverrides: {
        vertical: {
          height: 'auto',
          backgroundColor: colors.primaryDark,
          marginTop: defaultTheme.spacing(1),
          marginBottom: defaultTheme.spacing(1),
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        ...objTheme?.components?.MuiSelect?.styleOverrides,
        outlined: {
          padding: defaultTheme.spacing(2),
          paddingLeft: defaultTheme.spacing(2.5),
          paddingRight: defaultTheme.spacing(7),
          display: 'flex',
          alignItems: 'center',
          '&.MuiInputBase-inputSizeSmall': {
            padding: defaultTheme.spacing(1),
            paddingLeft: defaultTheme.spacing(2),
            paddingRight: defaultTheme.spacing(6),
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          paddingTop: defaultTheme.spacing(1),
          paddingBottom: defaultTheme.spacing(1),
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: borderWidth.s,
          },
        },
        input: {
          paddingTop: defaultTheme.spacing(2),
          paddingBottom: defaultTheme.spacing(2),
          fontSize: 16,
        },
        inputSizeSmall: {
          fontSize: 12,
          paddingLeft: defaultTheme.spacing(2),
          paddingRight: defaultTheme.spacing(2),
          paddingTop: defaultTheme.spacing(1),
          paddingBottom: defaultTheme.spacing(1),
        },
        inputMultiline: {
          padding: 0,
          lineHeight: 'normal',
        },
      },
    },
    MuiInput: {},
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
        outlined: {
          color: colors.textSecondary,
          transform: 'translate(14px, 10px) scale(1)',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(13px, -7px) scale(0.575)',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: defaultTheme.spacing(0),
        },
        label: {
          fontSize: 16,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          width: '100%',
          backgroundColor: 'transparent',
          boxShadow: defaultTheme.shadows[0],
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(0),
          minHeight: 0,
          '&.Mui-expanded': {
            minHeight: 0,
          },
        },
        content: {
          margin: defaultTheme.spacing(0),
          '&.Mui-expanded': {
            margin: defaultTheme.spacing(0),
          },
        },
        expandIconWrapper: {
          marginRight: defaultTheme.spacing(3),
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          width: '100%',
          padding: defaultTheme.spacing(0),
          display: 'inline-block',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          color: defaultTheme.palette.common.white,
          backgroundColor: colors.grey900,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        ...objTheme?.components?.MuiTab?.styleOverrides,
        root: {
          fontSize: 14,
          fontWeight: 500,
          lineHeight: '24px',
          letterSpacing: '0.4px',
          textTransform: 'none',
          paddingTop: defaultTheme.spacing(0),
          paddingBottom: defaultTheme.spacing(0),
          paddingLeft: defaultTheme.spacing(2),
          paddingRight: defaultTheme.spacing(2),
          borderTopLeftRadius: 'none',
          borderTopRightRadius: 'none',
          minHeight: '30px',
          minWidth: '0',
          [defaultTheme.breakpoints.up('sm')]: {
            minWidth: '0',
          },
        },
        textColorInherit: {
          color: colors.textSecondary,
          backgroundColor: colors.grey300,
          paddingLeft: defaultTheme.spacing(5),
          paddingRight: defaultTheme.spacing(5),
          borderRightColor: colors.textDisabled,
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          '&:last-child': {
            border: 'unset',
          },
          '&.Mui-selected': {
            color: colors.contrastWhite,
            backgroundColor: colors.grey800,
          },
          '& .MuiTab-wrapper': {
            fontSize: 12,
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
  },
  palette: palette,
});

export default theme;
