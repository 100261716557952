import {
  Box,
  Button,
  Modal,
  Typography,
} from '@miyagami-com/lsx-ui-components';
import { FC, useState } from 'react';
import useStyles from './useStyles';

type Props = {
  isOpen: boolean;
  title?: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm: () => void | Promise<void>;
  onCancel: () => void | Promise<void>;
};

const PromptModal: FC<Props> = ({
  isOpen,
  title,
  description,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmitConfirm = async () => {
    setLoading(true);

    try {
      await onConfirm();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={isOpen}>
      <Box className={classes.modalContainer}>
        <Box className={classes.modalContent}>
          {!!title && (
            <Box>
              <Typography variant="h2" textAlign="center">
                {title}
              </Typography>
            </Box>
          )}
          {!!description && (
            <Box>
              <Typography variant="body1" textAlign="center">
                {description}
              </Typography>
            </Box>
          )}
          <Box className={classes.modalActions}>
            <Box className={classes.modalAction}>
              <Button
                disabled={loading}
                variant="contained"
                color="primary"
                onClick={onSubmitConfirm}
              >
                {confirmText || 'Confirm'}
              </Button>
            </Box>
            <Box className={classes.modalAction}>
              <Button
                variant="contained"
                color="secondary"
                disabled={loading}
                onClick={onCancel}
              >
                {cancelText || 'Cancel'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default PromptModal;
