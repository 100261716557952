import React, { useState } from 'react';
import useColumn from './useColumns';
import DataGridTable from '../../Unknown/DataGridTable';
import { ExchangeBetCurrencyParams } from '../../../../types';
import usePrimaryCurrencyParams from './usePrimaryCurrencyParams';
import useOnCellEditCommit from './useOnCellEditCommit';
import { UseQueryResult } from 'react-query';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from '../../../common/constants';

interface CurrencyTableProps {
  currencyQuery: UseQueryResult<ExchangeBetCurrencyParams[], unknown>;
  playerId?: string;
  brandId?: string;
}

const CurrencyTable: React.FC<CurrencyTableProps> = (props) => {
  const { currencyQuery, brandId, playerId } = props;

  const columns = useColumn();

  const [pageSize, setPageSize] = useState(DEFAULT_ROWS_PER_PAGE_OPTIONS[5]);

  const { data: currencies, isLoading } = currencyQuery;

  const rows = usePrimaryCurrencyParams({
    exchangeBetCurrencyParams: currencies,
  });

  const onCellEditCommit = useOnCellEditCommit({
    brandId,
    playerId,
    currencyQuery,
  });

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  return (
    <DataGridTable
      columns={columns}
      loading={isLoading}
      tableKey="currency"
      rows={rows}
      disableSelectionOnClick
      autoHeight
      rowHeight={35}
      rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
      headerHeight={35}
      pagination
      pageSize={pageSize}
      onPageSizeChange={onPageSizeChange}
      onCellEditCommit={onCellEditCommit}
    />
  );
};

export default CurrencyTable;
