export type Id = string | number;

interface ConvertArrayToMapParams<T> {
  array?: Array<T>;
  getId: (item: T) => Id | null | undefined;
}

const convertArrayToMap = <T>(
  params: ConvertArrayToMapParams<T>,
): Map<Id, T> => {
  const { array, getId } = params;

  const map = new Map<Id, T>();

  array?.forEach((item) => {
    const id = getId(item);

    if (!id) return;

    map.set(id, item);
  });

  return map;
};

export default convertArrayToMap;
