import React from 'react';
import { Tab, Tabs } from '@miyagami-com/lsx-ui-components';
import { useHistory, useParams } from 'react-router-dom';

import useStyles from './useStyles';

type Params = {
  userTab: string;
  brandId: string;
  userId: string;
};

export type TabsData = {
  label: string;
  value: string;
  component: React.ReactNode;
};

export interface UserAccountTabsProps {
  tabs: TabsData[];
  type: 'player' | 'agent';
}

const UserAccountTabs: React.FC<UserAccountTabsProps> = ({ tabs, type }) => {
  const classes = useStyles();
  const history = useHistory();
  const { userTab, brandId, userId }: Params = useParams();

  return (
    <Tabs
      value={userTab || false}
      onChange={(_e, value) =>
        history.push(`/b/${brandId}/${type}-overview/${userId}/${value}`)
      }
      textColor="inherit"
      variant="fullWidth"
    >
      {tabs.map(({ value, label }) => (
        <Tab
          className={classes.tabsBorder}
          key={value}
          label={label}
          value={value}
        />
      ))}
    </Tabs>
  );
};

export default UserAccountTabs;
