import { MarketFilter } from '../../../../types';
import getEventId from './getEventId';
import { SportLineRow } from './useColumns';

interface CreateMarketFilterByNodeParams {
  row: SportLineRow;
}

const createMarketFilterByNode = (
  params: CreateMarketFilterByNodeParams,
): MarketFilter => {
  const { row } = params;

  let marketFilter: MarketFilter = {};

  if (row.type === 'EVENT') {
    const eventId = getEventId({ id: row.itemId });
    marketFilter = {
      eventIds: [eventId],
    };
  } else if (row.type === 'EVENT_TYPE') {
    marketFilter = {
      eventTypeIds: [row.itemId],
    };
  } else if (row.type === 'GROUP') {
    marketFilter = {
      competitionIds: [row.itemId],
    };
  }

  if (row.parentRow) {
    const parentMarketFilter = createMarketFilterByNode({ row: row.parentRow });
    marketFilter = {
      ...marketFilter,
      ...parentMarketFilter,
    };
  }

  return marketFilter;
};

export default createMarketFilterByNode;
