import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useUser } from 'reactfire';
import { RootState } from '../../../../types';
import useEventDetail from '../../../common/hooks/useEventDetail';
import useIsLoadingUser from '../../../common/hooks/useIsLoadingUser';
import DefaultLayout from '../../Unknown/DefaultLayout';
import EventDetail from '../EventDetail';
import usePath from './usePath';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';

type QueryParams = {
  brandId: string;
  eventId: string;
};

const EventDetailPage: React.FC = () => {
  const { brandId, eventId } = useParams<QueryParams>();

  const { data: user } = useUser();

  const router = useHistory();

  const { roles, permissionMap } = useSelector((root: RootState) => root.user);

  const isHaveAccess = permissionMap?.[brandId]?.event?.read;

  const isLoadingUser = useIsLoadingUser();

  const isAdmin = checkIsSystemAdmin({ roles });

  useEffect(() => {
    if (isHaveAccess || isAdmin || isLoadingUser) return;

    router.push('../');
  }, [isAdmin, isHaveAccess, isLoadingUser, router]);

  const eventDetailQuery = useEventDetail({
    marketId: eventId,
    userId: user?.uid,
  });

  const path = usePath({ eventDetailQuery });

  return (
    <DefaultLayout path={path}>
      <EventDetail eventDetailQuery={eventDetailQuery} brandId={brandId} />
    </DefaultLayout>
  );
};

export default EventDetailPage;
