import { defineMessages } from 'react-intl';

export default defineMessages({
  resetAll: {
    id: 'Unknown.ResettleMarket.resetAll',
    defaultMessage: 'Resettle Entire Market',
  },
  resettleSuccess: {
    id: 'Unknown.ResettleMarket.resettleSuccess',
    defaultMessage: 'Bets succesfully resettled!',
  },
  resettleError: {
    id: 'Unknown.ResettleMarket.resettleError',
    defaultMessage: 'An error occurred while resettling bets',
  },
});
