import { defineMessages } from 'react-intl';

export default defineMessages({
  placetAt: {
    id: 'ExchangeBet.ExchangeBetHeader.placetAt',
    defaultMessage: 'Placed at { date }',
  },
  stakeLabel: {
    id: 'ExchangeBet.ExchangeBetHeader.stakeLabel',
    defaultMessage: 'Stake: { currency }{ stake }',
  },
  priceLabel: {
    id: 'ExchangeBet.ExchangeBetHeader.stakeLabel',
    defaultMessage: 'Initial price: { price }, Average price: { avp }',
  },
  updatedAtLabel: {
    id: 'ExchangeBet.ExchangeBetHeader.updatedAtLabel',
    defaultMessage: 'Last update: { time }',
  },
});
