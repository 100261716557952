import { defineMessages } from 'react-intl';

export default defineMessages({
  id: {
    id: 'Player.PlayerDuplicatesList.id',
    defaultMessage: 'ID',
  },
  name: {
    id: 'Player.PlayerDuplicatesList.name',
    defaultMessage: 'First name',
  },
  surname: {
    id: 'Player.PlayerDuplicatesList.surname',
    defaultMessage: 'Surname',
  },
  email: {
    id: 'Player.PlayerDuplicatesList.email',
    defaultMessage: 'E-mail',
  },
  phoneNumber: {
    id: 'Player.PlayerDuplicatesList.phoneNumber',
    defaultMessage: 'Telephone',
  },
  brand: {
    id: 'Player.PlayerDuplicatesList.brand',
    defaultMessage: 'Brand',
  },
  type: {
    id: 'Player.PlayerDuplicatesList.type',
    defaultMessage: 'Type',
  },
  status: {
    id: 'Player.PlayerDuplicatesList.status',
    defaultMessage: 'Status',
  },
  actions: {
    id: 'Player.PlayerDuplicatesList.actions',
    defaultMessage: 'Actions',
  },
  view: {
    id: 'Player.PlayerDuplicatesList.view',
    defaultMessage: 'View',
  },
  ipAddress: {
    id: 'Player.PlayerDuplicatesList.ipAddress',
    defaultMessage: 'IP Address',
  },
});
