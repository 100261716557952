import { DateRangeRow } from './useFetchDateRange';

const DATE_RANGES_MAP = {
  inplay: 'In Play',
  today: 'Today',
  tomorrow: 'Tomorrow',
  future: 'Future',
};

const useSanitiseRows = (rows: DateRangeRow[]): DateRangeRow[] => {
  return rows.map((row) => {
    const dateRange = row.ref as keyof typeof DATE_RANGES_MAP;

    return {
      ...row,
      ref: DATE_RANGES_MAP[dateRange],
    };
  });
};

export default useSanitiseRows;
