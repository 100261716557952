import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export type ColorIndex =
  | 50
  | 100
  | 200
  | 300
  | 400
  | 500
  | 500
  | 600
  | 700
  | 800
  | 900;

const colorIndexes: ColorIndex[] = [
  50, 100, 200, 300, 400, 500, 600, 700, 800, 900,
];

type GreyColor = {
  [color: string]: {
    backgroundColor: string;
  };
};

export const colorSeparator = 'grey_';

const useTreeStyles = makeStyles<Theme>((theme) => {
  const colorByIndex: Record<ColorIndex, string> = {
    50: theme.palette.common.white,
    100: theme.palette.grey[100],
    200: theme.palette.grey[200],
    300: theme.palette.grey[300],
    400: theme.palette.grey[400],
    500: theme.palette.grey[50],
    600: theme.palette.grey[100],
    700: theme.palette.grey[200],
    800: theme.palette.grey[300],
    900: theme.palette.grey[400],
  };

  const greyColors = colorIndexes.reduce<GreyColor>((colors, colorIndex) => {
    const colorName = `${colorSeparator}${colorIndex}`;

    const newColor: GreyColor = {
      [colorName]: {
        backgroundColor: colorByIndex[colorIndex],
      },
    };

    return {
      ...newColor,
      ...colors,
    };
  }, {});

  return greyColors;
});

export default useTreeStyles;
