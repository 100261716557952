import { defineMessages } from 'react-intl';

export default defineMessages({
  internalization: {
    id: 'Language.LanguageTranslationsPage.internalization',
    defaultMessage: 'Internalization',
  },
  cms: {
    id: 'Language.LanguageTranslationsPage.cms',
    defaultMessage: 'CMS',
  },
  successfullyUpdated: {
    id: 'Language.LanguageTranslationsPage.successfullyUpdated',
    defaultMessage: 'Successfully updated',
  },
  updateErrorMessage: {
    id: 'Language.LanguageTranslationsPage.updateErrorMessage',
    defaultMessage: 'An error occurred during the update',
  },
});
