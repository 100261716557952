import React, { createContext, useState } from 'react';
import { RecaptchaVerifier } from 'firebase/auth';

export type SetIsWithMfa = (isWithMfa: boolean) => void;

export type SetPhoneNumber = (phoneNumber: string | null) => void;

export type SetUserPassword = (password: string | null) => void;

export type SetVerificationId = (id: string | null) => void;

export type SetRecaptchaVerifier = (
  verifier: RecaptchaVerifier | undefined,
) => void;

export interface AuthenticationContextProps {
  isWithMfa: boolean;
  setIsWithMfa: SetIsWithMfa;
  phoneNumber: string | null;
  setPhoneNumber: SetPhoneNumber;
  userPassword: string | null;
  setUserPassword: SetUserPassword;
  verificationId: string | null;
  setVerificationId: SetVerificationId;
  recaptchaVerifier?: RecaptchaVerifier;
  setRecaptchaVerifier: SetRecaptchaVerifier;
}

export const AuthenticationContext = createContext<AuthenticationContextProps>(
  {} as AuthenticationContextProps,
);

const AuthenticationContextProvider: React.FC = (props) => {
  const { children } = props;

  const [isWithMfa, setIsWithMfa] = useState<boolean>(false);

  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);

  const [userPassword, setUserPassword] = useState<string | null>(null);

  const [verificationId, setVerificationId] = useState<string | null>(null);

  const [recaptchaVerifier, setRecaptchaVerifier] = useState<
    RecaptchaVerifier | undefined
  >(undefined);

  return (
    <AuthenticationContext.Provider
      value={{
        isWithMfa,
        recaptchaVerifier,
        setRecaptchaVerifier,
        verificationId,
        phoneNumber,
        userPassword,
        setIsWithMfa: (withMfa: boolean) => setIsWithMfa(withMfa),
        setPhoneNumber: (phone) => setPhoneNumber(phone),
        setUserPassword: (password) => setUserPassword(password),
        setVerificationId: (id) => setVerificationId(id),
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationContextProvider;
