import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  containerBorder: {
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.grey[300],
    borderLeftWidth: '1px',
  },
  fieldContainer: {
    marginBottom: theme.spacing(3),
  },
}));

export default useStyles;
