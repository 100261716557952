import * as Yup from 'yup';
import { REG_EXP_COLOR } from '../../../common/constants';
import { Field } from '../../../common/validationMessages';
import fonts from './fonts';

const oneOfFont = fonts.map((font) => font.value);

const validationSchema = Yup.object().shape({
  brandId: Yup.string().trim(Field.trim).required(Field.required),
  slogan: Yup.string().trim(Field.trim).required(Field.required),
  mainColor: Yup.string().matches(REG_EXP_COLOR).required(Field.required),
  secondaryColor: Yup.string().matches(REG_EXP_COLOR).required(Field.required),
  mainFont: Yup.mixed().oneOf(oneOfFont).required(Field.required),
  secondaryFont: Yup.mixed().oneOf(oneOfFont).required(Field.required),
});

export default validationSchema;
