import { useCallback } from 'react';
import useSupabase from './useSupabase';
import { SYSTEM_TRANSACTION_PARTICIPANT } from '../constants';

interface CheckIsCanCreateTransactionParams {
  amount: number;
  userId: string;
}

const useCheckIsCanCreateTransaction =
  (): typeof checkIsCanCreateTransaction => {
    const supabase = useSupabase();

    const checkIsCanCreateTransaction = useCallback(
      async (params: CheckIsCanCreateTransactionParams) => {
        try {
          const { amount, userId } = params;

          if (userId === SYSTEM_TRANSACTION_PARTICIPANT) return true;

          const { data: userStat } = await supabase
            .from('user_stats')
            .select('*')
            .eq('id', userId)
            .maybeSingle();

          if (!userStat?.balance) return false;

          const isEnoughBalance = userStat?.balance >= amount;

          return isEnoughBalance;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          throw new Error(error.message);
        }
      },
      [supabase],
    );

    return checkIsCanCreateTransaction;
  };

export default useCheckIsCanCreateTransaction;
