import { defineMessages } from 'react-intl';

export default defineMessages({
  collapseBoxLabel: {
    id: 'User.UserChangePassword.collapseBoxLabel',
    defaultMessage: 'Change password',
  },
  newPassword: {
    id: 'User.UserChangePassword.newPassword',
    defaultMessage: 'New password',
  },
  passwordConfirm: {
    id: 'User.UserChangePassword.passwordConfirm',
    defaultMessage: 'Password confirmation',
  },
  saveButton: {
    id: 'User.UserChangePassword.saveButton',
    defaultMessage: 'Save',
  },
  label: {
    id: 'User.UserChangePassword.label',
    defaultMessage: 'Password',
  },
  successChangePassword: {
    id: 'User.UserChangePassword.successChangePassword',
    defaultMessage: 'Password was successfully changed.',
  },
  errorChangePassword: {
    id: 'User.UserChangePassword.errorChangePassword',
    defaultMessage: 'An error occurred while changing the password.',
  },
  notBrandId: {
    id: 'User.UserChangePassword.notBrandId',
    defaultMessage: 'Please select brand.',
  },
});
