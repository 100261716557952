import * as Yup from 'yup';
import { REG_EXP_PASSWORD } from '../../../common/constants';
import getMessageMaxCharacters from '../../../common/getMessageMaxCharacters';

import { Field, PhoneNumber } from '../../../common/validationMessages';

const maxCharacters = getMessageMaxCharacters(20);

export const createValidationSchema = Yup.object().shape({
  name: Yup.string().max(20, maxCharacters).trim().required(Field.required),
  surname: Yup.string().max(20, maxCharacters).trim().required(Field.required),
  email: Yup.string().email().trim().required(Field.required),
  role: Yup.string().trim().required(Field.required),
});

export const editValidationSchema = Yup.object().shape({
  name: Yup.string().max(20, maxCharacters).trim().required(Field.required),
  surname: Yup.string().trim().max(20, maxCharacters).required(Field.required),
  email: Yup.string().email().trim().required(Field.required),
  password: Yup.string().trim().matches(REG_EXP_PASSWORD, PhoneNumber.notValid),
  status: Yup.string().trim().required(Field.required),
  phoneNumber: Yup.string().when('mfa', {
    is: true,
    then: Yup.string().required(Field.required),
    otherwise: Yup.string(),
  }),
  role: Yup.string().trim().required(Field.required),
  brandId: Yup.string().when('role', {
    is: (val: string) => val == 'systemAdmin' || val == 'owner',
    then: Yup.string(),
    otherwise: Yup.string().required(Field.required),
  }),
  positionTakingRate: Yup.number().min(0).max(1).optional(),
  commissionRate: Yup.number().min(0).max(1).optional(),
});
