import { FC } from 'react';
import { useIntl } from 'react-intl';
import {
  Grid,
  FormControlLabel,
  Checkbox,
} from '@miyagami-com/lsx-ui-components';

import {
  RadioButtonUncheckedIcon,
  RadioButtonCheckedIcon,
} from '../../Unknown/Icons';
import {
  PermissionOperationMap,
  PermissionOperation,
  PermissionObjectType,
} from '../../../../types';

import messages from './messages';

export type CategoryMessages = {
  [key in PermissionObjectType]: string;
};

type OperationMessages = {
  [key in PermissionOperation]: string;
};

interface UserPermissionEditCheckboxProps {
  category: PermissionObjectType;
  operation: PermissionOperation;
  categoryForCurrentValues: PermissionOperationMap;
  categoryForCurrentRole: PermissionOperationMap;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  categoryMessages: CategoryMessages;
}

const UserPermissionEditCheckbox: FC<UserPermissionEditCheckboxProps> = ({
  category,
  operation,
  categoryForCurrentValues,
  categoryForCurrentRole,
  handleChange,
  categoryMessages,
}) => {
  const intl = useIntl();

  const operationMessages: OperationMessages = {
    read: intl.formatMessage(messages.read, {
      value: categoryMessages[category],
    }),
    create: intl.formatMessage(messages.create, {
      value: categoryMessages[category],
    }),
    update: intl.formatMessage(messages.update, {
      value: categoryMessages[category],
    }),
    delete: intl.formatMessage(messages.delete, {
      value: categoryMessages[category],
    }),
  };

  return (
    <Grid item xs={12} sm={6} md={3}>
      <FormControlLabel
        control={
          <Checkbox
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<RadioButtonCheckedIcon color="primary" />}
            name={`${category}.${operation}`}
            checked={
              categoryForCurrentValues[operation] ||
              !!categoryForCurrentRole[operation]
            }
            disabled={categoryForCurrentRole[operation]}
            onChange={handleChange}
          />
        }
        label={operationMessages[operation]}
      />
    </Grid>
  );
};

export default UserPermissionEditCheckbox;
