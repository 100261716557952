import { LoadingButton } from '@miyagami-com/lsx-ui-components';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';

const propertiesColumn = {
  editable: false,
  filterable: false,
};

interface UseColumnsParams {
  onDelete: (noteId: string) => Promise<void> | void;
}

const useColumns = (params: UseColumnsParams): typeof columns => {
  const { onDelete } = params;

  const intl = useIntl();

  const columns: GridColumns = [
    {
      ...propertiesColumn,
      field: 'id',
      headerName: intl.formatMessage(messages.id),
      flex: 0.5,
    },
    {
      ...propertiesColumn,
      field: 'date',
      headerName: intl.formatMessage(messages.date),
      flex: 0.7,
    },
    {
      ...propertiesColumn,
      field: 'time',
      headerName: intl.formatMessage(messages.time),
      flex: 0.5,
    },
    {
      ...propertiesColumn,
      field: 'author',
      headerName: intl.formatMessage(messages.author),
      flex: 0.7,
    },
    {
      ...propertiesColumn,
      field: 'note',
      headerName: intl.formatMessage(messages.note),
      flex: 1,
    },
    {
      ...propertiesColumn,
      field: 'action',
      headerName: intl.formatMessage(messages.action),
      flex: 0.6,
      renderCell: (rowParams: GridRenderCellParams) => {
        return (
          <LoadingButton
            color="inherit"
            onClick={() => onDelete(rowParams.row.id)}
          >
            <FormattedMessage {...messages.delete} />
          </LoadingButton>
        );
      },
    },
  ];

  return columns;
};

export default useColumns;
