import { defineMessages } from 'react-intl';

export default defineMessages({
  domainConfig: {
    id: 'BrandDomain.BrandDomainConfig.domainConfig',
    defaultMessage: 'Domain config',
  },
  verifyBrandDomainSuccess: {
    id: 'BrandDomain.BrandDomainConfig.verifyBrandDomainSuccess',
    defaultMessage: 'Domain config success verification',
  },
  verifyBrandDomainFailed: {
    id: 'BrandDomain.BrandDomainConfig.verifyBrandDomainFailed',
    defaultMessage: 'Domain config verification conditions are not met.',
  },
  verifyBrandDomainConflicts: {
    id: 'BrandDomain.BrandDomainConfig.verifyBrandDomainConflicts',
    defaultMessage:
      'You need first to fix conflicts before verification domain config.',
  },
  domainError: {
    id: 'BrandDomain.BrandDomainConfig.domainError',
    defaultMessage: 'An error occurred while getting a domain!',
  },
});
