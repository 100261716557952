import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { Player } from '../../../types/supabase';
import useSupabase from './useSupabase';

interface UsePlayerParams {
  playerId: string;
  select?: string;
}

const usePlayer = <T extends Player>(
  params: UsePlayerParams,
): typeof queryResult => {
  const { playerId, select = '*' } = params;

  const supabase = useSupabase();

  const playersRef = supabase.from('players');

  const playerSelect = playersRef
    .select<typeof select, T>(select)
    .eq('id', playerId);

  const playerSingleRequest = playerSelect.single();

  const getPlayer = useCallback(() => {
    return playerSingleRequest;
  }, [playerSingleRequest]);

  const queryResult = useQuery<T, Error>({
    queryKey: ['player', playerId],
    queryFn: async () => {
      const playerResponse = await getPlayer();

      const { data, error } = playerResponse;

      if (error) throw new Error(error.message);

      return data;
    },
    onError: (error) => error,
  });

  return queryResult;
};

export default usePlayer;
