import { defineMessages } from 'react-intl';

export default defineMessages({
  addLanguage: {
    id: 'Language.LanguageAdditionDialog.addLanguage',
    defaultMessage: 'Add Language',
  },
  failToAddLanguage: {
    id: 'Language.LanguageAdditionDialog.failToAddLanguage',
    defaultMessage: 'Failed to add language',
  },
  languageAddedSuccessfully: {
    id: 'Language.LanguageAdditionDialog.languageAddedSuccessfully',
    defaultMessage: 'Language added successfully',
  },
});
