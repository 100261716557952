import { UserRole } from '../../../../types';
import { AGENTS_ROLES } from '../../../common/constants';

interface GetPathByRoleParams {
  role: UserRole | null;
}

type PagePath =
  | 'player-overview'
  | 'agent-overview'
  | 'backoffice-users'
  | 'brand-owners';

const getPathByRole = ({ role }: GetPathByRoleParams): PagePath | null => {
  if (!role) return null;

  if (role === 'player') return 'player-overview';

  if (role === 'owner') return 'brand-owners';

  const isAgentRole = AGENTS_ROLES.includes(role);
  if (isAgentRole) return 'agent-overview';

  return 'backoffice-users';
};

export default getPathByRole;
