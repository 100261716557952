import { defineMessages } from 'react-intl';

export default defineMessages({
  startOn: {
    id: 'Event.EventDetailHeader.startOn',
    defaultMessage: 'Starts on {date} at {time}',
  },
  lastUpdate: {
    id: 'Event.EventDetailHeader.lastUpdate',
    defaultMessage: 'Last update: {agoTime}',
  },
  exposure: {
    id: 'Event.EventDetailHeader.exposure',
    defaultMessage: 'Exposure: {amount}',
  },
});
