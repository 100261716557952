import {
  Brand,
  ExtendedBackOfficeUser,
  BackofficeUserRole as UserRole,
} from '../../../../types';
import { BackofficeUserRole } from '../../../../types/supabase';
import { USER_ROLE_MAP } from '../../../common/constants';
import convertArrayToMap from '../../../common/convertArrayToMap';

type SortedUserList = ExtendedBackOfficeUser & {
  brandId: string | null;
  brandName: string | null;
  role: string | null;
};

const sortedUserList = (
  brands: Brand[],
  users: ExtendedBackOfficeUser[],
): SortedUserList[] => {
  const brandMap = convertArrayToMap({
    array: brands,
    getId: (brand) => brand.id,
  });

  const userList = users.map((user) => {
    const adminOwnerRole = user.roles.find(
      (role) => role.role_id === 'systemAdmin' || role.role_id === 'owner',
    );

    if (adminOwnerRole) {
      const brand = adminOwnerRole.brand_id
        ? brandMap.get(adminOwnerRole.brand_id)
        : null;

      const roleLabel = USER_ROLE_MAP?.[adminOwnerRole.role_id as UserRole];

      return {
        ...user,
        role: roleLabel || null,
        brandId: adminOwnerRole.brand_id,
        brandName: brand?.name || null,
      };
    }

    const userRoleByBrandId = user.roles?.[0] as BackofficeUserRole | undefined;

    const brand = userRoleByBrandId?.brand_id
      ? brandMap.get(userRoleByBrandId.brand_id)
      : null;

    const roleLabel = USER_ROLE_MAP?.[userRoleByBrandId?.role_id as UserRole];
    return {
      ...user,
      role: roleLabel || null,
      brandId: userRoleByBrandId?.brand_id,
      brandName: brand?.name || null,
    };
  });

  const filteredUserList = userList.filter((user) => user) as SortedUserList[];

  return filteredUserList;
};

export default sortedUserList;
