import { useContext } from 'react';
import { SupabaseContext } from '../../components/Unknown/SupabaseProvider';

const useSupabaseContext = (): typeof context => {
  const context = useContext(SupabaseContext);

  return context;
};

export default useSupabaseContext;
