import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Grid } from '@miyagami-com/lsx-ui-components';

import { RootState } from '../../../../types';

import useVerifyPermissions from '../../../common/hooks/useVerifyPermissions';
import useBrandId from '../../../common/hooks/useBrandId';

import DefaultLayout from '../../Unknown/DefaultLayout';

import BrandDomains from '../../BrandDomain/BrandDomains';

import ApiKeysPage from '../../BrandApiKey/BrandApiKeys';

import BrandDetailsHeader from '../BrandDetailsHeader';
import BrandDetails from '../BrandDetails';

import messages from './messages';
import { useQueryClient } from 'react-query';
import BrandDetailCurrency from '../BrandDetailCurrency';
import BrandDetailNetworkUpdate from '../BrandDetailNetworkUpdate';

const BrandDetailsPage: React.FC = () => {
  const intl = useIntl();
  const brandId = useBrandId();
  const {
    brands,
    users: { byBrandId: users },
  } = useSelector((state: RootState) => state.globalData);

  const authUser = useSelector((state: RootState) => state.user);

  const isSystemAdmin = !!authUser.roles?.find(
    (role) => role.role_id === 'systemAdmin',
  );

  const backofficeUsers = useMemo(
    () => users[brandId]?.backofficeUsers,
    [brandId, users],
  );
  const brand = brands.find((uploadedBrand) => uploadedBrand.id === brandId);

  const queryClient = useQueryClient();

  const refetchBrands = () => {
    queryClient.refetchQueries(['brands']);

    const brandIds = brands.map((brandItem) => brandItem.id);

    queryClient.refetchQueries(['brands', brandIds]);
  };

  const brandOwner = backofficeUsers?.find(
    (uploadedUser) => uploadedUser.id === brand?.owner_id,
  );

  const { grantedPermissions } = useVerifyPermissions([
    `brand/${brandId}/brand/read`,
  ]);

  const isReadPermissionGranted = !!grantedPermissions?.brand?.read;

  const path = [
    { label: intl.formatMessage(messages.system) },
    {
      label: intl.formatMessage(messages.brandOverview),
      url: `./`,
    },
    { label: brand?.name || '' },
  ];

  return (
    <>
      {brand && (
        <DefaultLayout path={path}>
          <Grid container spacing={4} p={4}>
            <Grid item xs={12}>
              <BrandDetailsHeader brandOwner={brandOwner} brand={brand} />
            </Grid>
            {isReadPermissionGranted && (
              <>
                <Grid item xs={12} md={6}>
                  <BrandDetails
                    refetchBrands={refetchBrands}
                    isSystemAdmin={isSystemAdmin}
                    brandOwner={brandOwner}
                    brand={brand}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <BrandDetailCurrency brandId={brandId} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <BrandDomains brandId={brandId} />
                </Grid>
                {isSystemAdmin && !!brand.owner_id && (
                  <Grid item xs={12} md={6}>
                    <BrandDetailNetworkUpdate
                      brand={brand}
                      authUser={authUser}
                      brandOwner={brandOwner}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <ApiKeysPage brandId={brandId} />
                </Grid>
              </>
            )}
          </Grid>
        </DefaultLayout>
      )}
    </>
  );
};

export default BrandDetailsPage;
