import { useMemo } from 'react';
import { ProfitLossReportLevel } from '../../../../types';
import { useIntl } from 'react-intl';
import messages from './messages';

type ParentHeaderGroupTitleByLevel = Record<ProfitLossReportLevel, string>;

const useParentHeaderGroupTitleByLevel = (): ParentHeaderGroupTitleByLevel => {
  const intl = useIntl();

  const parentHeaderGroupTitleByLevel: ParentHeaderGroupTitleByLevel =
    useMemo(() => {
      return {
        systemAdmin: intl.formatMessage(messages.system),
        brand: intl.formatMessage(messages.brand),
        superAgent: intl.formatMessage(messages.superAgent),
        masterAgent: intl.formatMessage(messages.masterAgent),
        agent: intl.formatMessage(messages.agent),
        player: intl.formatMessage(messages.player),
      };
    }, [intl]);

  return parentHeaderGroupTitleByLevel;
};

export default useParentHeaderGroupTitleByLevel;
