import React from 'react';
import { Grid } from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';

import { ExtendedBackOfficeUser } from '../../../../types';
import UserNoteOverview from '../../UserNote/UserNoteOverview';
import UserStatus from '../../User/UserStatus';
import UserChangePassword from '../../User/UserChangePassword';
import AgentPersonalInformation from '../AgentPersonalInformation';

import messages from './messages';
import { AgentRole } from '../AgentDetailPage';
import useVerifyPermissions from '../../../common/hooks/useVerifyPermissions';

interface AgentAccountPageProps {
  user: ExtendedBackOfficeUser;
  brandId: string;
  role?: AgentRole;
}

const AgentAccountPage: React.FC<AgentAccountPageProps> = ({
  user,
  brandId,
  role,
}) => {
  const intl = useIntl();

  const { grantedPermissions, isSuspended, isBrandSuspended } =
    useVerifyPermissions([`brand/${brandId}/${role}/update`]);

  const isUpdatePermissionGranted = role && !!grantedPermissions[role]?.update;

  const isDisabled =
    !isUpdatePermissionGranted || isSuspended || isBrandSuspended;

  return (
    <Grid container pt={4} spacing={4}>
      <Grid item xs={12} md={6}>
        <AgentPersonalInformation
          isDisabled={isDisabled}
          user={user}
          brandId={brandId}
          role={role}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <UserNoteOverview
          isDisabled={isDisabled}
          brandId={brandId}
          userId={user?.id}
          type="backofficeUser"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <UserStatus
          isDisabled={isDisabled}
          brandId={brandId}
          user={user}
          translations={{ label: intl.formatMessage(messages.userStatusLabel) }}
          type="backofficeUser"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <UserChangePassword
          isDisabled={isDisabled}
          userId={user?.id}
          brandId={brandId}
        />
      </Grid>
    </Grid>
  );
};

export default AgentAccountPage;
