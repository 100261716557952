import React from 'react';
import { GridRenderCellParams } from '@miyagami-com/lsx-ui-components';
import CheckBoxCell from '../../Unknown/CheckBoxCell';
import { useGridApiContext } from '@mui/x-data-grid';

function IsEnabledCell(props: GridRenderCellParams<boolean>): JSX.Element {
  const { value, id, field, row } = props;
  const { type } = row;
  const apiRef = useGridApiContext();

  const onClickEnabledCell = () => {
    apiRef.current.setEditCellValue({
      id,
      field,
      value: !value,
    });
    apiRef.current.commitCellChange({ id, field });
  };

  if (type === 'COUNTRY') {
    return <></>;
  }

  return (
    <CheckBoxCell
      checkboxProps={{ checked: value, onClick: onClickEnabledCell }}
      label=""
    />
  );
}

export default IsEnabledCell;
