import { useQuery } from 'react-query';
import { BackofficeUser } from '../../../types/supabase';
import useSupabase from './useSupabase';

interface UseBackofficeUserParams {
  userId: string;
  select?: string;
}

const useBackofficeUser = <T extends BackofficeUser>(
  params: UseBackofficeUserParams,
): typeof queryResult => {
  const { userId, select = '*' } = params;

  const supabase = useSupabase();

  const queryResult = useQuery<T | null, Error>(
    ['backofficeUser', userId],
    async () => {
      const response = await supabase
        .from('backoffice_users')
        .select<typeof select, T>(select)
        .eq('id', userId)
        .maybeSingle();

      return response.data;
    },
  );

  return queryResult;
};

export default useBackofficeUser;
