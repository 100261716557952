import format from 'date-fns/format';
import { BetfairExchangeSize } from '../../../../types';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from '../../../common/constants';

interface NormalizeBetLogProps {
  exchangeSize: BetfairExchangeSize;
}

const normalizeBetLog = (
  params: NormalizeBetLogProps,
): typeof normalizedBetLog => {
  const { exchangeSize } = params;

  const { updated_at, id, type, size } = exchangeSize;

  const updatedAtDate = new Date(updated_at);

  const createdAtDateLabel = format(updatedAtDate, DEFAULT_DATE_FORMAT);

  const createdAtTimeLabel = format(updatedAtDate, DEFAULT_TIME_FORMAT);

  const normalizedBetLog = {
    id,
    date: createdAtDateLabel,
    time: createdAtTimeLabel,
    type,
    size,
  };

  return normalizedBetLog;
};

export default normalizeBetLog;
