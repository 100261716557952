import React, { useContext, useRef } from 'react';
import { UIContext } from '@miyagami-com/lsx-ui-components';
import IdleTimerBase from 'react-idle-timer';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Big from 'big.js';

import { resetStateAction } from '../../../store/user';

import messages from './messages';
import useAuth from '../AuthContext/useAuth';
import clearFirestoreCache from '../../../common/clearFirestoreCache';

const idleTime = new Big(6).times(60).times(60).times(1000).toNumber(); // 6 hours

const IdleTimer: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  const { setAlert } = useContext(UIContext);

  const { signOut } = useAuth();

  const idleTimerRef = useRef<IdleTimerBase | null>(null);

  const onIdle = async () => {
    try {
      dispatch(resetStateAction());
      await signOut();
      clearFirestoreCache();
      history.push('/login');
    } catch (error) {
      setAlert({
        severity: 'error',
        show: true,
        message: intl.formatMessage(messages.errorLogout),
      });
    }
  };

  return (
    <IdleTimerBase
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={idleTimerRef as any}
      timeout={idleTime}
      onIdle={onIdle}
    ></IdleTimerBase>
  );
};

export default IdleTimer;
