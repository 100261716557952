import { defineMessages } from 'react-intl';

export default defineMessages({
  domain: {
    id: 'BrandDomain.BrandDomainAddition.domain',
    defaultMessage: 'Domain',
  },
  addNewDomain: {
    id: 'BrandDomain.BrandDomainAddition.addNewDomain',
    defaultMessage: 'Add new domain',
  },
  save: {
    id: 'BrandDomain.BrandDomainAddition.save',
    defaultMessage: 'Save',
  },
});
