import * as Yup from 'yup';
import { Field } from '../../../common/validationMessages';

const validationSchema = Yup.object().shape({
  sender: Yup.string().trim(Field.required).required(Field.required),
  receiver: Yup.string().trim(Field.required).required(Field.required),
  amount: Yup.number().required(Field.required).min(1),
});

export default validationSchema;
