import { NavigationNode } from '../../../../types';
import { COUNTRY_EVENT_TYPE_IDS } from '../../../common/constants';
import convertNodeToSportLineRow from './convertNodeToSportLineRow';
import groupChildrenByCountry from './groupChildrenByCountry';
import { SportLineRow } from './useColumns';

interface ConvertNodeToRowsParams {
  node?: NavigationNode[] | null;
  parentRow: SportLineRow | null;
}

const convertNodeToRows = (params: ConvertNodeToRowsParams): SportLineRow[] => {
  const { node, parentRow } = params;

  const rows = new Map<string, SportLineRow>();

  if (!node?.length) return Array.from(rows.values());

  const nodes = Array.isArray(node) ? node : [node];

  nodes.forEach((parentNode) => {
    const row = convertNodeToSportLineRow({ node: parentNode, parentRow });

    if (row) {
      rows.set(row.id, row);
    }

    let children = parentNode.children;
    if (
      parentNode.type === 'EVENT_TYPE' &&
      COUNTRY_EVENT_TYPE_IDS.includes(parentNode.id)
    ) {
      children = groupChildrenByCountry(parentNode);
    }

    if (children) {
      children.forEach((child) => {
        const childRows = convertNodeToRows({ node: [child], parentRow: row });
        childRows.forEach((childRow) => rows.set(childRow.id, childRow));
      });
    }
  });

  return Array.from(rows.values());
};

export default convertNodeToRows;
