import { FC, Fragment, useContext, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  CollapseBox,
  Grid,
  Box,
  Typography,
  Divider,
  TextField,
  LoadingButton,
  UIContext,
} from '@miyagami-com/lsx-ui-components';
import { Formik, Form } from 'formik';
import { useFirebaseApp } from 'reactfire';
import { useHistory } from 'react-router';

import messagesForCategory from '../../../common/permission/categoryMessages';
import permissionMapByRole, {
  operationListForPermissions,
} from '../../../common/permission/permissionMapByRole';
import parsePermissionsMap from '../../../common/permission/parsePermissionsMap';
import stringifyPermissionMap from '../../../common/permission/stringifyPermissionMap';
import { USER_ROLES, DEFAULT_REGION } from '../../../common/constants';
import UserPermissionEditCheckbox, {
  CategoryMessages,
} from '../UserPermissionEditCheckbox';

import messages from './messages';
import useVerifyPermissions from '../../../common/hooks/useVerifyPermissions';
import {
  BackofficeUserRole,
  ExtendedBackOfficeUser,
  PermissionMap,
} from '../../../../types';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface UserPermissionEditProps {
  user: ExtendedBackOfficeUser;
  brandId: string;
}

const UserPermissionEdit: FC<UserPermissionEditProps> = ({ user, brandId }) => {
  const { setAlert } = useContext(UIContext);

  const { grantedPermissions } = useVerifyPermissions([
    `brand/${brandId}/permission/update`,
  ]);

  const isDisabled = !grantedPermissions?.permission?.update;

  const intl = useIntl();
  const history = useHistory();

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const categoryMessages: CategoryMessages = {
    systemAdmin: intl.formatMessage(messagesForCategory.systemAdmin),
    brand: intl.formatMessage(messagesForCategory.brand),
    owner: intl.formatMessage(messagesForCategory.owner),
    superAgent: intl.formatMessage(messagesForCategory.superAgent),
    masterAgent: intl.formatMessage(messagesForCategory.masterAgent),
    agent: intl.formatMessage(messagesForCategory.agent),
    player: intl.formatMessage(messagesForCategory.player),
    support: intl.formatMessage(messagesForCategory.support),
    marketing: intl.formatMessage(messagesForCategory.marketing),
    finance: intl.formatMessage(messagesForCategory.finance),
    casinoBets: intl.formatMessage(messagesForCategory.casinoBets),
    casinoGames: intl.formatMessage(messagesForCategory.casinoGames),
    transaction: intl.formatMessage(messagesForCategory.transaction),
    bet: intl.formatMessage(messagesForCategory.bet),
    sport: intl.formatMessage(messagesForCategory.sport),
    competition: intl.formatMessage(messagesForCategory.competition),
    event: intl.formatMessage(messagesForCategory.event),
    market: intl.formatMessage(messagesForCategory.market),
    domains: intl.formatMessage(messagesForCategory.domains),
    theme: intl.formatMessage(messagesForCategory.theme),
    widget: intl.formatMessage(messagesForCategory.widget),
    language: intl.formatMessage(messagesForCategory.language),
    ticket: intl.formatMessage(messagesForCategory.ticket),
    control: intl.formatMessage(messagesForCategory.control),
    permission: intl.formatMessage(messagesForCategory.permission),
    riskBets: intl.formatMessage(messagesForCategory.riskBets),
    exchange: intl.formatMessage(messagesForCategory.exchange),
    sportline: intl.formatMessage(messagesForCategory.sportline),
    dateRange: intl.formatMessage(messagesForCategory.dateRange),
    marketType: intl.formatMessage(messagesForCategory.marketType),
  };

  const userRoleData = user.roles?.find(
    ({ brand_id: brandIdInRoleData }) => brandIdInRoleData === brandId,
  );

  const roleName = (userRoleData?.role_id || '') as BackofficeUserRole;
  const roleData = USER_ROLES.find(({ value }) => value === roleName);

  const permissionMapForCurrentRole = useMemo(() => {
    return roleName ? permissionMapByRole[roleName] : {};
  }, [roleName]);

  const categoriesList = Object.keys(
    permissionMapForCurrentRole,
  ) as (keyof PermissionMap)[];

  const initialValues = useMemo(() => {
    const permissions = user.permissions || [];

    const additionalPermissionMap = parsePermissionsMap({ permissions });
    const additionalPermissionMapForCurrentBrand =
      additionalPermissionMap[brandId] || {};

    const initialPermissionMap: PermissionMap = {
      ...permissionMapForCurrentRole,
    };

    categoriesList.forEach((category) => {
      const currentCategory = {
        ...additionalPermissionMapForCurrentBrand[category],
      };

      operationListForPermissions.forEach((operation) => {
        initialPermissionMap[category] = {
          ...initialPermissionMap[category],
          [operation]: !!currentCategory[operation],
        };
      });
    });

    return initialPermissionMap;
  }, [user.permissions, brandId, permissionMapForCurrentRole, categoriesList]);

  const onSubmit = async (values: PermissionMap) => {
    try {
      if (!brandId) {
        throw new Error('no brand');
      }

      const updateUserPermissionsFunction = httpsCallable(
        functions,
        'back-permission-updateUserPermissions',
      );

      const newPermissions = stringifyPermissionMap({
        permissionMap: values,
        brandId: brandId,
      });

      await updateUserPermissionsFunction({
        userId: user.id,
        permissions: newPermissions,
        brandId: brandId,
      });

      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.messageSuccess),
      });

      history.push(`/b/${brandId}/backoffice-users/${user.id}`);
    } catch (error) {
      const message = error === 'no brand' ? 'noBrand' : 'messageError';
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages[message]),
      });
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} pb={4}>
        <CollapseBox
          label={intl.formatMessage(messages.permissions)}
          defaultValue
        >
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ handleChange, values, dirty, isSubmitting }) => {
              return (
                <Form>
                  <Box p={3} pb={5}>
                    <Typography variant="h6">
                      <FormattedMessage {...messages.permissionTemplate} />
                    </Typography>
                    <Grid container pt={3} spacing={3}>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          fullWidth
                          label={intl.formatMessage(messages.role)}
                          name="role"
                          variant="outlined"
                          value={roleData?.label}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider />
                  {categoriesList.map((category) => {
                    const categoryForCurrentValues = { ...values[category] };
                    const categoryForCurrentRole = {
                      ...permissionMapForCurrentRole[category],
                    };
                    return (
                      <Fragment key={category}>
                        <Box p={3} maxWidth="md">
                          <Typography variant="h6">
                            {categoryMessages[category]}
                          </Typography>
                          <Grid container pt={3} spacing={3}>
                            {operationListForPermissions.map((operation) => (
                              <UserPermissionEditCheckbox
                                key={`${category}_${operation}`}
                                category={category}
                                operation={operation}
                                categoryForCurrentValues={
                                  categoryForCurrentValues
                                }
                                categoryForCurrentRole={categoryForCurrentRole}
                                handleChange={handleChange}
                                categoryMessages={categoryMessages}
                              />
                            ))}
                          </Grid>
                        </Box>
                        <Divider />
                      </Fragment>
                    );
                  })}
                  <Box p={3} display="flex">
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!dirty || isDisabled}
                      loading={isSubmitting}
                    >
                      <FormattedMessage {...messages.save} />
                    </LoadingButton>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </CollapseBox>
      </Grid>
    </Grid>
  );
};

export default UserPermissionEdit;
