import sumArrayByField from '../../../.././src/common/sumArrayByField';
import { ExchangeBetOutcome } from '../../../../types/supabase';

interface CalculateTotals {
  outcomes: ExchangeBetOutcome[];
}

export interface Totals {
  totalVolume: number;
  totalNetProfit: number;
  totalBets: number;
  totalGrossProfit: number;
}

const calculateTotals = (params: CalculateTotals): Totals => {
  const { outcomes } = params;

  const totalVolume = sumArrayByField({
    array: outcomes,
    field: 'total_volume',
  });

  const totalNetProfit = sumArrayByField({
    array: outcomes,
    field: 'net_profit',
  });

  const totalBets = sumArrayByField({
    array: outcomes,
    field: 'total_bets',
  });

  const totalGrossProfit = sumArrayByField({
    array: outcomes,
    field: 'gross_profit',
  });

  const returnParams = {
    totalNetProfit,
    totalVolume,
    totalBets,
    totalGrossProfit,
  };

  return returnParams;
};

export default calculateTotals;
