import { BackofficeUserRole } from '../../types/supabase';
import checkIsBrandPendingRole from './checkIsBrandPendingRole';

interface CheckIsBrandOwnerParams {
  roles?: BackofficeUserRole[] | null;
}

const checkIsBrandOwner = (params: CheckIsBrandOwnerParams): boolean => {
  const { roles } = params;

  const { isBrandPending, mappedRoles } = checkIsBrandPendingRole({ roles });

  if (isBrandPending) return true;

  if (!mappedRoles) return false;

  const isOwner = mappedRoles.some(({ role_id }) => role_id === 'owner');

  return isOwner;
};

export default checkIsBrandOwner;
