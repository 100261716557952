import { PostgrestResponse } from '@supabase/postgrest-js';
import createSupabaseClient from '../../../common/createSupabaseClient';

export interface DuplicatePlayerData {
  player_id: string;
  player_surname: string;
  player_name: string;
  player_brand_id: string;
  player_email: string;
  player_phone_number: string;
  player_status: string;
  player_created_at: string;
  log_ip_address: string;
  log_country_code: string;
  log_type: string;
}

const getDuplicatePlayers = async ({
  brandId,
}: {
  brandId: string;
}): Promise<PostgrestResponse<DuplicatePlayerData>> => {
  const supabaseClient = createSupabaseClient({});

  const response = (await supabaseClient
    .rpc(
      'player__get_duplicate_players',
      {
        brand_id_filter: brandId,
      },
      { count: 'exact' },
    )
    .select('*')
    .throwOnError()) as unknown as PostgrestResponse<DuplicatePlayerData>;

  return response;
};

export default getDuplicatePlayers;
