import React, { useMemo, useState } from 'react';
import { CollapseBox } from '@miyagami-com/lsx-ui-components';
import { GridColumns } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import {
  BetfairExchangeSize,
  BetfairExchangeSizeType,
} from '../../../../types';
import useBetfairExchangeSizeStatusMap from '../../../common/hooks/useBetfairExchangeSizeStatusMap';

import messages from './messages';
import normalizeBetLog from './normalizeBetLog';
import DataGridTable from '../../Unknown/DataGridTable';
import useUpdateExchangeBetfairSizes from '../../../common/hooks/useUpdateBetfairExchangeSizes';
import ActionsBox from '../../Unknown/ActionsBox';

const defaultColumnOptions = {
  editable: false,
  sortable: false,
  disableColumnMenu: true,
  flex: 1,
};

interface ExchangeBetLogOverviewParams {
  data: BetfairExchangeSize[];
  isLoading: boolean;
}

const pageSize = 100;

const ExchangeBetLogOverview: React.FC<ExchangeBetLogOverviewParams> = (
  props,
) => {
  const { data, isLoading } = props;

  const intl = useIntl();
  const exchangeBetStatusMap = useBetfairExchangeSizeStatusMap();

  const updateBetfairExchangeSizes = useUpdateExchangeBetfairSizes();

  const [editingRowId, setEditingRowId] = useState<string | null>(null);
  const [newSize, setNewSize] = useState<number | null>(null);

  const handleSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewSize(Number(event.target.value));
  };

  const handleSizeBlur = async () => {
    if (editingRowId && newSize !== null) {
      await updateBetfairExchangeSizes.mutate({ betId: editingRowId, newSize });
    }
    setEditingRowId(null);
  };

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: intl.formatMessage(messages.idLabel),
      ...defaultColumnOptions,
    },
    {
      field: 'date',
      headerName: intl.formatMessage(messages.dateLabel),
      ...defaultColumnOptions,
    },
    {
      field: 'time',
      headerName: intl.formatMessage(messages.timeLabel),
      ...defaultColumnOptions,
    },
    {
      field: 'type',
      headerName: intl.formatMessage(messages.statusLabel),
      ...defaultColumnOptions,
      valueFormatter: (params) => {
        const status = params.value as BetfairExchangeSizeType;

        const { label } = exchangeBetStatusMap[status];

        return label;
      },
    },
    {
      field: 'size',
      headerName: intl.formatMessage(messages.sizeLabel),
      ...defaultColumnOptions,
      renderCell: (params) => {
        if (params.row.id !== editingRowId) {
          return params.value;
        }

        return (
          <form
            onSubmit={(event) => {
              event.preventDefault();
              handleSizeBlur();
            }}
          >
            <input
              defaultValue={params.value}
              onChange={handleSizeChange}
              onBlur={handleSizeBlur}
            />
          </form>
        );
      },
    },
    {
      field: 'actions',
      headerName: intl.formatMessage(messages.actionsLabel),
      ...defaultColumnOptions,
      sortable: false,
      width: 100,
      renderCell: (params) => {
        const editAction = {
          label: intl.formatMessage(messages.editSizes),
          buttonProps: {
            onClick: () => setEditingRowId(params.row.id),
          },
        };

        return <ActionsBox actions={[editAction]} />;
      },
    },
  ];

  const rows = useMemo(() => {
    const normalizedBetLogs = data?.map((exchangeSize) =>
      normalizeBetLog({ exchangeSize }),
    );

    return normalizedBetLogs || [];
  }, [data]);

  const isHideFooter = useMemo(() => rows?.length <= pageSize, [rows?.length]);

  return (
    <CollapseBox defaultValue label={intl.formatMessage(messages.label)}>
      <DataGridTable
        tableKey="exchange-bet"
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        disableSelectionOnClick
        autoHeight
        rowHeight={35}
        headerHeight={35}
        hideFooter={isHideFooter}
        hideFooterPagination={isHideFooter}
        pagination
        loading={isLoading}
      />
    </CollapseBox>
  );
};

export default ExchangeBetLogOverview;
