import { RACING_SPORT_IDS } from '../../../common/constants';
import { UseNavigationNodeParams } from '../../../common/hooks/useNavigationNode';
import createMarketFilterByNode from './createMarketFilterByNode';
import getEventId from './getEventId';
import { SportLineRow } from './useColumns';

interface CreateFetchParamsByRowParams {
  row: SportLineRow;
  brandId: string;
  playerId?: string;
}

const createFetchParamsByRow = (
  params: CreateFetchParamsByRowParams,
): UseNavigationNodeParams | null => {
  const { row, brandId, playerId } = params;

  const marketFilter = createMarketFilterByNode({ row });

  const isRaceEventType = marketFilter.eventTypeIds?.some((eventTypeId) =>
    RACING_SPORT_IDS.includes(eventTypeId),
  );

  switch (row.type) {
    case 'ROOT': {
      const fetchParams: UseNavigationNodeParams = {
        key: 'exchange',
        brandId,
        filter: {},
        type: 'root',
        playerId,
      };

      return fetchParams;
    }
    case 'EVENT_TYPE': {
      const fetchParams: UseNavigationNodeParams = {
        key: `event_type_${row.itemId}`,
        brandId,
        filter: marketFilter,
        type: 'eventType',
        playerId,
      };

      return fetchParams;
    }
    case 'GROUP': {
      const fetchParams: UseNavigationNodeParams = {
        key: `group_${row.itemId}`,
        brandId,
        filter: marketFilter,
        type: 'competition',
        playerId,
      };

      return fetchParams;
    }
    case 'EVENT': {
      const eventId = getEventId({ id: row.itemId });
      const fetchParams: UseNavigationNodeParams = {
        key: `event_${eventId}`,
        brandId,
        filter: marketFilter,
        type: isRaceEventType ? 'raceMarket' : 'event',
        playerId,
      };

      return fetchParams;
    }
    default:
      return null;
  }
};

export default createFetchParamsByRow;
