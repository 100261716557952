import { PostgrestResponse } from '@supabase/postgrest-js';
import { LogType } from '../../../../types';
import { Log } from '../../../../types/supabase';
import createSupabaseClient from '../../../common/createSupabaseClient';

interface GetUserLogsParams {
  type: LogType;
  userId: string;
  offset: number;
  limit: number;
}

const getUserLogs = async ({
  type,
  userId,
  limit,
  offset,
}: GetUserLogsParams): Promise<PostgrestResponse<Log>> => {
  const supabase = createSupabaseClient({});

  const response = await supabase
    .from('logs')
    .select('*', { count: 'exact' })
    .eq('type', type)
    .eq('user_id', userId)
    .order('created_at', { ascending: false })
    .range(offset, limit)
    .throwOnError();

  return response;
};

export default getUserLogs;
