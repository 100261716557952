import { UseQueryResult } from 'react-query';
import { UserStat } from '../../../types/supabase';
import useUserStats from './useUserStats';

interface UsePlayerStatsParams {
  playerId: string;
}

const usePlayerStats = (
  params: UsePlayerStatsParams,
): UseQueryResult<UserStat | null> => {
  const { playerId } = params;

  return useUserStats({ userId: playerId });
};

export default usePlayerStats;
