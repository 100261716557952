import { defineMessages } from 'react-intl';

export default defineMessages({
  exchange: {
    id: 'Event.EventDetailPage.exchange',
    defaultMessage: 'Exchange',
  },
  events: {
    id: 'Event.EventDetailPage.events',
    defaultMessage: 'Events',
  },
});
