import { BackOfficeUser, GlobalUsers } from '../../types';
import { Player } from '../../types/supabase';
import getAllBrandUsers from './getAllBrandUsers';
import getUserParentData from './user/getUserParentData';

type GetDefaultSenderListParams = {
  authUserId: string;
  users: GlobalUsers;
  brandId: string;
  currentUser: Player | BackOfficeUser;
};

type DefaultSenderList = {
  authUserIsParent: boolean;
  defaultSenderList: {
    value: string;
    label: string;
  }[];
};

const getDefaultSenderList = ({
  authUserId,
  users,
  brandId,
  currentUser,
}: GetDefaultSenderListParams): DefaultSenderList => {
  const allUsers = getAllBrandUsers({ users, brandId });

  const { parentId } = getUserParentData({ user: currentUser });

  const parentUser = allUsers?.find(({ id }) => id === parentId);

  const authUserIsParent = authUserId === parentUser?.id;

  const defaultSenderList = parentUser
    ? [
        {
          value: parentUser.id,
          label: parentUser.username,
        },
      ]
    : [];

  return { authUserIsParent, defaultSenderList };
};

export default getDefaultSenderList;
