import { GridColumns } from '@mui/x-data-grid';
import { ExchangeBetCurrencyParams } from '../../../../types';
import { useIntl } from 'react-intl';
import messages from './messages';
import defaultRenderCell from './defaultRenderCell';

const propertiesColumn = {
  editable: false,
  filterable: false,
  minWidth: 50,
  flex: 0.4,
};

const useColumn = (): GridColumns<ExchangeBetCurrencyParams> => {
  const intl = useIntl();

  const columns: GridColumns<ExchangeBetCurrencyParams> = [
    {
      ...propertiesColumn,
      field: 'id',
      headerName: intl.formatMessage(messages.id),
    },
    {
      ...propertiesColumn,
      field: 'currency_code',
      headerName: intl.formatMessage(messages.currencyCode),
    },
    {
      ...propertiesColumn,
      field: 'min_bet_size',
      headerName: intl.formatMessage(messages.minBetSize),
      renderCell: defaultRenderCell,
      renderEditCell: defaultRenderCell,
    },
  ];

  return columns;
};

export default useColumn;
