import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { RootState, UserState } from '../../../types';
import { useAuth } from 'reactfire';
import useApiClient from './useApiClient';
import useSupabaseContext from './useSupabaseContext';
import { useQuery } from 'react-query';
import { loginUserAction } from '../../store/user';
import { signInWithCustomToken } from 'firebase/auth';

const authRoutes = ['/login', '/authentication', '/reset-password'];

interface UseCheckAndRedirectAuth {
  isLoading: boolean;
  authUser: UserState;
  isAuth: boolean;
}

enum AuthStatus {
  success = 'success',
  failure = 'failure',
}

type ProfileResponse = {
  user: UserState;
  token: string;
  status: AuthStatus;
  supabaseAccessToken: string;
};

const useCheckAndRedirectAuth = (): UseCheckAndRedirectAuth => {
  const auth = useAuth();

  const apiClient = useApiClient();

  const { setAuth } = useSupabaseContext();

  const dispatch = useDispatch();

  const history = useHistory();

  const authUser = useSelector((state: RootState) => state.user);

  const { isAuth } = authUser;

  const fetchAuthProfile = useCallback(async () => {
    const { data } = await apiClient.get('/back-auth-profile');
    return data;
  }, [apiClient]);

  const { isLoading } = useQuery('authProfile', fetchAuthProfile, {
    enabled: !isAuth,
    retry: false,
    onSuccess: async (response: ProfileResponse) => {
      const { user, token, supabaseAccessToken } = response;

      setAuth(supabaseAccessToken);

      if (user) {
        await signInWithCustomToken(auth, token);
        dispatch(loginUserAction(user));

        if (authRoutes.includes(history.location.pathname)) {
          history.replace('/');
        }
      } else {
        if (!authRoutes.includes(history.location.pathname)) {
          history.replace('/login');
        }
      }
    },
  });

  return { isLoading, authUser, isAuth };
};

export default useCheckAndRedirectAuth;
