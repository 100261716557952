import { DEFAULT_REGION } from './constants';
import firebaseConfig from './firebaseConfig';

const getApiURL = (): string => {
  const serverBaseURL = `https://${DEFAULT_REGION}-${firebaseConfig.projectId}.cloudfunctions.net`;

  const localHost = process.env.REACT_APP_LOCAL_HOST ?? '127.0.0.1';

  const localPort = process.env.REACT_APP_LOCAL_PORT ?? '5001';

  const localBaseURL = `http://${localHost}:${localPort}/${firebaseConfig.projectId}/${DEFAULT_REGION}`;

  const isLocal = process.env.REACT_APP_NODE_ENV === ('local' as string);

  const baseURL = isLocal ? localBaseURL : serverBaseURL;

  return baseURL;
};

export default getApiURL;
