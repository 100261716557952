import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { colors } from '../theme';
import useBetfairExchangeSizeStatusMap from './useBetfairExchangeSizeStatusMap';
import messages from '../messages';
import { BetfairExchangeSizeType } from '../../../types';

export type StatusMapParams = {
  label: string;
  color: string;
};

export type ExchangeBetStatus = BetfairExchangeSizeType | 'settled' | string;

const useExtendedExchangeBetStatusMap = (): Record<
  ExchangeBetStatus,
  StatusMapParams
> => {
  const intl = useIntl();

  const betfairExchangeBetStatusMap = useBetfairExchangeSizeStatusMap();

  const exchangeBetStatusMap: Record<ExchangeBetStatus, StatusMapParams> =
    useMemo(() => {
      return {
        ...betfairExchangeBetStatusMap,
        settled: {
          label: intl.formatMessage(messages.settledStatus),
          color: colors.primaryMain,
        },
      };
    }, [betfairExchangeBetStatusMap, intl]);

  return exchangeBetStatusMap;
};

export default useExtendedExchangeBetStatusMap;
