import * as Yup from 'yup';
import { IMAGE_TYPES } from '../../../common/constants';
import { Field } from '../../../common/validationMessages';

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .max(20, 'Maximum 20 characters')
    .trim(Field.trim)
    .required(Field.required),
  description: Yup.string()
    .max(255, 'Maximum 255 characters')
    .trim(Field.trim)
    .required(Field.required),
  brandId: Yup.string().required(Field.required),
  link: Yup.string().trim(Field.trim).required(Field.required),
  image: Yup.object()
    .shape({
      type: Yup.string().test('file type', 'Unsupported File Format', (text) =>
        IMAGE_TYPES.includes(text || ''),
      ),
    })
    .nullable(),
});

export default validationSchema;
