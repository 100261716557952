import { ParsedRole } from '../permission/parseRoles';

interface FindParsedRoleByBrandIdParams {
  roles: ParsedRole[];
  brandId: string;
}

const findParsedRoleByBrandId = ({
  roles,
  brandId,
}: FindParsedRoleByBrandIdParams): ParsedRole | undefined => {
  const foundRole = roles.find(
    ({ brandId: userBrandId }) => brandId === userBrandId,
  );

  return foundRole;
};

export default findParsedRoleByBrandId;
