import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  transparent: {
    backgroundColor: 'transparent',
  },
  select: {
    '& .MuiSelect-selectMenu': {
      fontSize: '16px',
    },
  },
}));

export default useStyles;
