const chunkArray = <T>(baseArray: Array<T>, chunkSize: number): T[][] => {
  const chunks = [];

  while (baseArray.length) {
    chunks.push(baseArray.splice(0, chunkSize));
  }

  return chunks;
};

export default chunkArray;
