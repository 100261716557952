import { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  LoadingButton,
  Grid,
} from '@miyagami-com/lsx-ui-components';
import { Formik, Form, FormikHelpers } from 'formik';

import Select from '../../Unknown/Select';

import { ApiKey, BrandApiKeyDomain } from '../../../../types';

import useVerifyPermissions from '../../../common/hooks/useVerifyPermissions';
import useBrandId from '../../../common/hooks/useBrandId';

import validationSchema from './validationSchema';
import messages from './messages';
import useLocationList from '../../../common/hooks/useLocationList';

export type AddBrandApiKeyFormParams = {
  domain?: BrandApiKeyDomain;
  locationId: string;
  username: string;
  password: string;
  certificate?: File;
  key?: File;
  apiKeyId?: string;
};
type RenderInputData = { name: 'username' | 'password' | 'apiKeyId' };

type RenderButtonData = {
  name: 'certificate' | 'key';
  accept: string;
  btnText: JSX.Element;
};

interface BrandApiKeyAdditionProps {
  initialValues: AddBrandApiKeyFormParams;
  onSubmitForm: (
    values: AddBrandApiKeyFormParams,
    formikHelpers: FormikHelpers<AddBrandApiKeyFormParams>,
  ) => Promise<void>;
  rows?: ApiKey[];
}

const BrandApiKeyAddition: FC<BrandApiKeyAdditionProps> = ({
  initialValues,
  onSubmitForm,
  rows,
}) => {
  const intl = useIntl();

  const brandId = useBrandId();

  const { grantedPermissions, isSuspended, isBrandSuspended } =
    useVerifyPermissions([`brand/${brandId}/brand/update`]);

  const isDisabled =
    !grantedPermissions?.brand?.update || isSuspended || isBrandSuspended;

  const domainMenuItems = useMemo(() => {
    const menuItems = [
      {
        value: 'betfair',
        label: intl.formatMessage(messages.betfair),
      },
    ];

    const filteredMenuItems = menuItems.filter(
      ({ value }) => !rows?.find((row) => row.domain === value),
    );
    return filteredMenuItems;
  }, [intl, rows]);

  const locationList = useLocationList();

  const locationMenuItems = useMemo(() => {
    if (!locationList?.data?.length) return [];

    return locationList.data.map((proxy) => {
      const { id, name } = proxy;

      return {
        value: id,
        label: name,
      };
    });
  }, [locationList?.data]);

  const renderInputData: RenderInputData[] = [
    { name: 'username' },
    { name: 'password' },
    { name: 'apiKeyId' },
  ];

  const renderButtonData: RenderButtonData[] = [
    {
      name: 'certificate',
      accept: '.crt',
      btnText: <FormattedMessage {...messages.uploadCert} />,
    },
    {
      name: 'key',
      accept: '.key',
      btnText: <FormattedMessage {...messages.uploadKey} />,
    },
  ];

  const fieldsByDomain = {
    betfair: {
      renderInputData,
      renderButtonData,
    },
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmitForm}
      validationSchema={validationSchema}
    >
      {({
        handleChange,
        handleBlur,
        errors,
        values,
        setFieldValue,
        touched,
        isSubmitting,
      }) => {
        const error = (name: keyof AddBrandApiKeyFormParams) =>
          Boolean(errors[name] && touched[name]);

        return (
          <Form>
            <Box>
              <Box p={3}>
                <Typography variant="h6">
                  <FormattedMessage {...messages.addNewApiKey} />
                </Typography>
                <Grid container pt={4} spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <Select
                      label={intl.formatMessage(messages.domain)}
                      labelId="domainSelect"
                      fullWidth
                      menuItems={domainMenuItems}
                      name="domain"
                      value={values.domain}
                      onChange={handleChange}
                      error={error('domain')}
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      label={intl.formatMessage(messages.location)}
                      labelId="locationSelect"
                      fullWidth
                      menuItems={locationMenuItems}
                      name="locationId"
                      value={values.locationId}
                      onChange={handleChange}
                      error={error('locationId')}
                      disabled={isDisabled}
                    />
                  </Grid>
                  {values.domain && (
                    <>
                      {fieldsByDomain[values.domain]?.renderInputData.map(
                        ({ name }) => (
                          <Grid item key={name} xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label={intl.formatMessage(messages[name])}
                              name={name}
                              variant="outlined"
                              value={values[name]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={error(name)}
                              disabled={isDisabled}
                            />
                          </Grid>
                        ),
                      )}
                      {fieldsByDomain[values.domain]?.renderButtonData.map(
                        ({ name, accept, btnText }) => (
                          <Grid item key={name} xs={12} sm={6}>
                            <label htmlFor={`button-file-${name}`}>
                              <input
                                style={{ display: 'none' }}
                                accept={accept}
                                id={`button-file-${name}`}
                                type="file"
                                name={name}
                                disabled={isDisabled}
                                onChange={(value) => {
                                  if (
                                    value.target.files &&
                                    value.target.files?.length > 0
                                  ) {
                                    setFieldValue(name, value.target.files[0]);
                                  }
                                }}
                              />
                              <Button
                                variant="contained"
                                component="span"
                                disabled={isDisabled}
                              >
                                {btnText}
                              </Button>
                            </label>
                            {values[name] && (
                              <Typography ml={2} variant="caption">
                                {values[name]?.name}
                              </Typography>
                            )}
                          </Grid>
                        ),
                      )}
                    </>
                  )}
                </Grid>
              </Box>
              <Divider />
              <Box p={3}>
                <LoadingButton
                  disabled={!values.domain || isDisabled}
                  loading={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  <FormattedMessage {...messages.save} />
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BrandApiKeyAddition;
