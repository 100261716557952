import { defineMessages } from 'react-intl';

export default defineMessages({
  name: {
    id: 'Brand.BrandOverviewAddition.name',
    defaultMessage: 'Name',
  },
  addBrand: {
    id: 'Brand.BrandOverviewAddition.addBrand',
    defaultMessage: 'Add Brand',
  },
  brand: {
    id: 'Brand.BrandOverviewAddition.brand',
    defaultMessage: 'Brand',
  },
  brandInformation: {
    id: 'Brand.BrandOverviewAddition.brandInformation',
    defaultMessage: 'Brand information',
  },
  isRequiredLogin: {
    id: 'Brand.BrandOverviewAddition.isRequiredLogin',
    defaultMessage: 'Login Required',
  },
  commission: {
    id: 'Brand.BrandOverviewAddition.commission',
    defaultMessage: 'Commission',
  },
  brandMinPT: {
    id: 'Brand.BrandOverviewAddition.brandMinPT',
    defaultMessage: 'Brand min. PT',
  },
  brandMaxPT: {
    id: 'Brand.BrandOverviewAddition.brandMaxPT',
    defaultMessage: 'Brand max. PT',
  },
  brandExtraPT: {
    id: 'Brand.BrandOverviewAddition.brandExtraPT',
    defaultMessage: 'System Extra PT on Brand',
  },
  brandExtraPTCheckbox: {
    id: 'Brand.BrandOverviewAddition.brandExtraPTCheckbox',
    defaultMessage: 'Extra PT',
  },
  extraPTEnabled: {
    id: 'Brand.BrandOverviewAddition.extraPTEnabled',
    defaultMessage: 'Extra PT Enabled',
  },
  revenue: {
    id: 'Brand.BrandOverviewAddition.revenue',
    defaultMessage: 'Revenue',
  },
  systemRevenue: {
    id: 'Brand.BrandOverviewAddition.systemRevenue',
    defaultMessage: 'System PT & Commission',
  },
  directPlayers: {
    id: 'Brand.BrandOverviewAddition.directPlayers',
    defaultMessage: 'Direct Players',
  },
  allowDirectPlayers: {
    id: 'Brand.BrandOverviewAddition.allowDirectPlayers',
    defaultMessage: 'Allow direct players',
  },
  directPlayerComission: {
    id: 'Brand.BrandOverviewAddition.directPlayerComission',
    defaultMessage: 'Player Commission',
  },
  save: {
    id: 'Brand.BrandOverviewAddition.save',
    defaultMessage: 'Save',
  },
  publish: {
    id: 'Brand.BrandOverviewAddition.publish',
    defaultMessage: 'Publish',
  },
  brandOwner: {
    id: 'Brand.BrandOverviewAddition.brandOwner',
    defaultMessage: 'Brand owner',
  },
  createdBrand: {
    id: 'Brand.BrandOverviewAddition.createdBrand',
    defaultMessage: 'Brand was successfully created!',
  },
  errorCreateBrand: {
    id: 'Brand.BrandOverviewAddition.errorCreateBrand',
    defaultMessage: 'An error occurred while creating a brand!',
  },
  errorMaxPT: {
    id: 'Brand.BrandOverviewAddition.errorMaxPT',
    defaultMessage: 'Maximum PT must be greater than or equal to minimum PT',
  },
  errorPTandMaxPT: {
    id: 'Brand.BrandOverviewAddition.errorPTandMaxPT',
    defaultMessage: 'Brand max PT cannot be greater than 100% - PT',
  },
  pt: {
    id: 'Brand.BrandOverviewAddition.pt',
    defaultMessage: 'PT',
  },
  minimumBet: {
    id: 'Brand.BrandOverviewAddition.minimumBet',
    defaultMessage: 'Minimum bet',
  },
  betfairBetTypeLabel: {
    id: 'Brand.BrandOverviewAddition.betfairBetType',
    defaultMessage: 'Betfair bet type',
  },
  basedOnPtLabel: {
    id: 'Brand.BrandOverviewAddition.basedOnPtLabel',
    defaultMessage: 'Based on PT',
  },
  minimumBetLabel: {
    id: 'Brand.BrandOverviewAddition.minimumBetLabel',
    defaultMessage: 'Minimum bet',
  },
  betfair: {
    id: 'Brand.BrandOverviewAddition.betfair',
    defaultMessage: 'Betfair',
  },
  betfairPositionTaking: {
    id: 'Brand.BrandOverviewAddition.betfairPositionTaking',
    defaultMessage: 'Betfair position taking',
  },
  networkConfig: {
    id: 'Brand.BrandOverviewAddition.networkConfig',
    defaultMessage: 'Network Config',
  },
  updateNetworkConfigSuccess: {
    id: 'Brand.BrandOverviewAddition.updateNetworkConfigSuccess',
    defaultMessage: 'Network config was successfully updated',
  },
});
