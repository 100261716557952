import * as Yup from 'yup';

export const playerValidationSchema = Yup.object().shape({
  brandId: Yup.string().trim().required(),
  status: Yup.string().trim().required(),
  riskLevel: Yup.string().trim().nullable().optional(),
});

export const backofficeUserValidationSchema = Yup.object().shape({
  brandId: Yup.string().trim().required(),
  status: Yup.string().trim().required(),
});
