import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Grid, Button, Typography } from '@miyagami-com/lsx-ui-components';

import { DNSConflict } from '../BrandDomainConfigConflicts';

import messages from './messages';

export type DNSRecord = {
  type: string;
  value: string;
};

type BrandDomainConfigDNSListProps = {
  selectedDomainDNSList?: DNSRecord[];
  handleVerifyConfig: () => void;
  isVerificationLoading: boolean;
  brandDomainConflicts?: DNSConflict[];
};

const BrandDomainConfigDNSList: React.FC<BrandDomainConfigDNSListProps> = ({
  selectedDomainDNSList,
  handleVerifyConfig,
  isVerificationLoading,
  brandDomainConflicts,
}) => {
  return (
    <>
      <Box pb={4}>
        <Typography fontWeight={700}>
          <FormattedMessage {...messages.description} />
        </Typography>
      </Box>
      <Box pb={4}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography fontWeight={700}>
              <FormattedMessage {...messages.dnsRecordType} />
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography fontWeight={700}>
              <FormattedMessage {...messages.dnsRecordValue} />
            </Typography>
          </Grid>
          {selectedDomainDNSList?.map((item) => (
            <Grid item container key={item.value} xs={12} spacing={2}>
              <Grid item xs={4}>
                {item.type}
              </Grid>
              <Grid item xs={8}>
                {item.value}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Button
        variant="contained"
        fullWidth
        onClick={handleVerifyConfig}
        disabled={isVerificationLoading || !!brandDomainConflicts?.length}
      >
        <FormattedMessage {...messages.buttonText} />
      </Button>
    </>
  );
};

export default BrandDomainConfigDNSList;
