import { FC } from 'react';
import { CollapseBox, Divider } from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';

import UserNoteAddition from '../UserNoteAddition';
import UserNoteList from '../UserNoteList';

import messages from './messages';
import useUserNotes from '../../../common/hooks/useUserNotes';
import LoadingBox from '../../Unknown/LoadingBox';

interface UserNoteOverviewProps {
  brandId: string;
  userId: string;
  type: 'player' | 'backofficeUser';
  isDisabled: boolean;
}

const UserNoteOverview: FC<UserNoteOverviewProps> = (params) => {
  const { brandId, userId, isDisabled } = params;

  const { isLoading, data: notes, refetch } = useUserNotes({ userId });

  const intl = useIntl();

  if (isLoading) {
    return <LoadingBox />;
  }

  const onAddNote = () => {
    refetch();
  };

  return (
    <CollapseBox label={intl.formatMessage(messages.notes)} defaultValue>
      <UserNoteList notes={notes} brandId={brandId} userId={userId} />
      <Divider />
      <UserNoteAddition
        isDisabled={isDisabled}
        brandId={brandId}
        userId={userId}
        onAddNote={onAddNote}
        userType="backoffice"
      />
    </CollapseBox>
  );
};

export default UserNoteOverview;
