import React, { useEffect } from 'react';
import { RecaptchaVerifier } from 'firebase/auth';
import { useAuth } from 'reactfire';

type RecaptchaProps = {
  recaptchaRef: (node: HTMLDivElement) => void;
  recaptcha: HTMLDivElement | null;
};

const Recaptcha: React.FC<RecaptchaProps> = ({ recaptchaRef, recaptcha }) => {
  const auth = useAuth();

  useEffect(() => {
    let verifier: RecaptchaVerifier;
    if (recaptcha && auth) {
      verifier = new RecaptchaVerifier(auth, recaptcha, {
        size: 'invisible',
      });
    }

    return () => {
      if (verifier) verifier.clear();
    };
  }, [recaptcha, auth]);

  return <div ref={recaptchaRef}></div>;
};

export default Recaptcha;
