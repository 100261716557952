import React from 'react';
import SportLine from '../../Unknown/SportLine';

interface PlayerExchangeSportlineProps {
  playerId: string;
}

const PlayerExchangeSportline: React.FC<PlayerExchangeSportlineProps> = (
  props,
) => {
  const { playerId } = props;

  return (
    <>
      <SportLine playerId={playerId} />
    </>
  );
};

export default PlayerExchangeSportline;
