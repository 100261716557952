import { defineMessages } from 'react-intl';

export default defineMessages({
  dataSet: {
    id: 'SecurityOverview.SecurityOverviewHeader.dataSet',
    defaultMessage: 'Data set',
  },
  dateFrom: {
    id: 'SecurityOverview.SecurityOverviewHeader.dateFrom',
    defaultMessage: 'Date from',
  },
  dateTo: {
    id: 'SecurityOverview.SecurityOverviewHeader.dateTo',
    defaultMessage: 'Date to',
  },
  performance: {
    id: 'SecurityOverview.SecurityOverviewHeader.performance',
    defaultMessage: 'Performance',
  },
});
