import { GridRowData } from '@mui/x-data-grid';
import getParentIdList from './getParentIdList';

const sortAgentsByParentPathLength = (agents: GridRowData[]): GridRowData[] => {
  return agents.sort((a, b) => {
    const parentIdListA = getParentIdList(a.parentPath || undefined);
    const parentIdListB = getParentIdList(b.parentPath || undefined);
    const lengthIdListA = parentIdListA.length || 0;
    const lengthIdListB = parentIdListB.length || 0;
    return lengthIdListA > lengthIdListB ? 1 : -1;
  });
};

export default sortAgentsByParentPathLength;
