import { defineMessages } from 'react-intl';

export default defineMessages({
  createdAtStart: {
    id: 'ExchangeBet.createdAtStart',
    defaultMessage: 'Created At Start',
  },
  createdAtEnd: {
    id: 'ExchangeBet.createdAtEnd',
    defaultMessage: 'Created At End',
  },
  settledAtStart: {
    id: 'ExchangeBet.settledAtStart',
    defaultMessage: 'Settled At Start',
  },
  settledAtEnd: {
    id: 'ExchangeBet.settledAtEnd',
    defaultMessage: 'Settled At End',
  },
});
