import { defineMessages } from 'react-intl';

export default defineMessages({
  conflictDescription: {
    id: 'BrandDomain.BrandDomainConfigConflicts.conflictDescription',
    defaultMessage:
      'Detected conflicts in DNS zones. Remove the following entries from the DNS zone editor to correctly verify the domain configuration. Fix the conflicts and click the button below. Data synchronization may take some time.',
  },
  dnsRecordName: {
    id: 'BrandDomain.BrandDomainConfigConflicts.dnsRecordName',
    defaultMessage: 'Name',
  },
  dnsRecordType: {
    id: 'BrandDomain.BrandDomainConfigConflicts.dnsRecordType',
    defaultMessage: 'Type',
  },
  dnsRecordValue: {
    id: 'BrandDomain.BrandDomainConfigConflicts.dnsRecordValue',
    defaultMessage: 'Value',
  },
  conflictButtonText: {
    id: 'BrandDomain.BrandDomainConfigConflicts.conflictButtonText',
    defaultMessage: 'Refresh domain config',
  },
});
