import { useMemo } from 'react';

import { GridColumns } from '@miyagami-com/lsx-ui-components/dist/components/DataGridPremium';

import useUserDataGridColumnsConfig from './useUserDataGridColumnsConfig';

type UseDataGridOrderedColumnsProps = {
  tableKey?: string;
  unorderedColumns: GridColumns;
};

const useDataGridOrderedColumns = ({
  tableKey,
  unorderedColumns,
}: UseDataGridOrderedColumnsProps): GridColumns => {
  const { data: userDataGridColumnsConfig } = useUserDataGridColumnsConfig({
    tableKey,
  });

  return useMemo(() => {
    if (
      !userDataGridColumnsConfig?.length ||
      unorderedColumns.length !== userDataGridColumnsConfig.length
    ) {
      return unorderedColumns;
    }

    const sortedColumns = unorderedColumns.reduce((allCols, col) => {
      const userColIndex = userDataGridColumnsConfig.findIndex(
        (userColumn) => userColumn === col.field,
      );

      const allColsCopy = [...allCols];

      allColsCopy[userColIndex] = col;

      return allColsCopy;
    }, [] as GridColumns);

    return sortedColumns;
  }, [unorderedColumns, userDataGridColumnsConfig]);
};

export default useDataGridOrderedColumns;
