import React, { useContext } from 'react';
import {
  Box,
  Divider,
  TextField,
  Typography,
  ModalCenter,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  UIContext,
  FormControlLabel,
  Checkbox,
  LoadingButton,
  Grid,
} from '@miyagami-com/lsx-ui-components';
import { Formik, Form, FormikHelpers } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import FormHelperText from '@mui/material/FormHelperText';

import {
  DEFAULT_COMMISSION,
  DEFAULT_PT,
  DEFAULT_MIN_BRAND_PT,
  DEFAULT_AMOUNT,
  MAX_PT_RATE,
  DEFAULT_STEP_SIZE,
  MAX_COMMISSION_RATE,
} from '../../../common/constants';

import {
  RadioButtonCheckedIcon,
  RadioButtonUncheckedIcon,
} from '../../Unknown/Icons';
import ClosableBox from '../../Unknown/ClosableBox';
import { BetfairBetType, RootState } from '../../../../types';

import messages from './messages';
import validationSchema from './validationSchema';
import BetfairBetTypeFormControl from './BetfairBetTypeFormControl';
import useRefetchBrand from '../../../common/hooks/useRefetchBrand';
import useCreateBrand from '../../../common/hooks/useCreateBrand';
import generateAvailableOptions, {
  generateAvailableOptionsHalfs,
} from '../../../common/generateAvailableOptions';
import { Switch } from '@mui/material';

type BrandOverviewAdditionProps = {
  isOpen: boolean;
  onClose: CallableFunction;
};

type SelectOption = {
  value: number;
  label: string;
};

export type FormValues = {
  name: string;
  ownerId: string;
  pt: number;
  commission: number;
  isDirectPlayerEnabled: boolean;
  directPlayerComission: number;
  betfairBetType: BetfairBetType;
  brandMinPT: number;
  brandMaxPT: number;
  brandExtraPT: number;
  isExtraPositionTakingEnabled?: boolean;
  isRequiredLogin?: boolean;
};

const initialValues: FormValues = {
  name: '',
  ownerId: '',
  pt: 0,
  commission: 0,
  isDirectPlayerEnabled: false,
  directPlayerComission: 0,
  betfairBetType: 'pt',
  brandMinPT: 0,
  brandMaxPT: 1,
  brandExtraPT: 0,
  isRequiredLogin: false,
};

const BrandOverviewAddition: React.FC<BrandOverviewAdditionProps> = ({
  isOpen,
  onClose,
}) => {
  const intl = useIntl();

  const { setAlert } = useContext(UIContext);

  const [isExtraPositionTakingEnabled, setExtraPositionTakingEnabled] =
    React.useState(false);

  const { users } = useSelector((root: RootState) => root.globalData);

  const brandOwners = users.brandOwners;

  const createBrand = useCreateBrand();
  const refetchBrands = useRefetchBrand();

  const onCreateBrand = async (
    formParams: FormValues,
    { resetForm, setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    setSubmitting(true);

    try {
      await createBrand({ ...formParams });

      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.createdBrand),
      });

      resetForm();
    } catch (error) {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.errorCreateBrand),
      });
    } finally {
      setSubmitting(false);
      onClose();
      refetchBrands();
    }
  };

  const availablePTOptions = generateAvailableOptionsHalfs(
    DEFAULT_AMOUNT,
    MAX_PT_RATE,
    0.005,
  );

  const availableCommissionOptions = generateAvailableOptions(
    DEFAULT_AMOUNT,
    MAX_COMMISSION_RATE,
    0.001,
    3,
  );

  const availableMinPTOptions = generateAvailableOptionsHalfs(
    DEFAULT_AMOUNT,
    MAX_PT_RATE,
    0.005,
  );

  const validateInputs = (values: FormValues) => {
    const { brandMinPT, brandMaxPT, pt } = values;

    if (brandMaxPT < brandMinPT) {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.errorMaxPT),
      });
      return false;
    }
    if (pt + brandMaxPT > MAX_PT_RATE) {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.errorPTandMaxPT),
      });
      return false;
    }
    return true;
  };

  return (
    <ModalCenter open={isOpen} onClose={() => onClose()}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: 380, sm: 620, md: 900 },
          p: 4,
        }}
      >
        <ClosableBox
          label={intl.formatMessage(messages.addBrand)}
          onClose={onClose}
        >
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={async (values, formikHelpers) => {
              const formValues = {
                ...values,
                isExtraPositionTakingEnabled,
              };

              const valid = validateInputs(formValues);

              if (valid) await onCreateBrand(formValues, formikHelpers);

              formikHelpers.setSubmitting(false);
            }}
            validationSchema={validationSchema}
          >
            {({
              handleChange,
              handleBlur,
              errors,
              values,
              touched,
              isSubmitting,
              setFieldValue,
              setFieldError,
              setFieldTouched,
            }) => {
              const error = (name: keyof FormValues) =>
                Boolean(errors[name] && touched[name]);

              const handleDirectPlayerChange = (
                event: React.ChangeEvent<HTMLInputElement>,
                checked: boolean,
              ) => {
                handleChange(event);

                if (!checked) {
                  setFieldValue('directPlayerComission', 0);
                  setFieldTouched('directPlayerComission', false);
                  setFieldError('directPlayerComission', undefined);
                }
              };

              return (
                <Form>
                  <Box>
                    <Box p={3} pb={4}>
                      <Typography variant="h6">
                        <FormattedMessage {...messages.brandInformation} />
                      </Typography>
                      <Grid container pt={3} spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={error('name')}
                            label={intl.formatMessage(messages.name)}
                            value={values.name}
                            variant="outlined"
                          />
                          <FormHelperText error={error('name')}>
                            {errors.name || (errors.ownerId ? '' : errors.name)}
                          </FormHelperText>
                        </Grid>
                        {brandOwners && (
                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth variant="outlined">
                              <InputLabel id="select-ownerId">
                                <FormattedMessage {...messages.brandOwner} />
                              </InputLabel>
                              <Select
                                labelId="select-ownerId"
                                name="ownerId"
                                value={values.ownerId}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                label={intl.formatMessage(messages.brandOwner)}
                                error={error('ownerId')}
                              >
                                {brandOwners?.map((user) => (
                                  <MenuItem value={user.id}>
                                    {user.email}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormHelperText error={error('ownerId')}>
                              {errors.ownerId ||
                                (errors.name ? '' : errors.ownerId)}
                            </FormHelperText>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                    <Divider />
                    <Box p={3} pb={4}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.isRequiredLogin}
                            name="isRequiredLogin"
                            onChange={handleChange}
                          />
                        }
                        label={
                          <Typography variant="h6">
                            <FormattedMessage {...messages.isRequiredLogin} />
                          </Typography>
                        }
                      />
                    </Box>
                    <Divider />
                    <Box p={3} pb={4}>
                      <Typography variant="h6">
                        <FormattedMessage {...messages.systemRevenue} />
                      </Typography>
                      <Grid container pt={3} spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            name="pt"
                            onChange={handleChange}
                            select
                            disabled={isExtraPositionTakingEnabled}
                            onBlur={handleBlur}
                            error={error('pt')}
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: DEFAULT_AMOUNT,
                                max: MAX_PT_RATE,
                                step: DEFAULT_STEP_SIZE,
                              },
                            }}
                            value={values.pt}
                            label={intl.formatMessage(messages.pt)}
                            defaultValue={DEFAULT_PT}
                            variant="outlined"
                            fullWidth
                            helperText={errors.pt}
                            children={availablePTOptions.map(
                              // eslint-disable-next-line @typescript-eslint/no-shadow
                              ({ value, label }: SelectOption) => (
                                <MenuItem key={`pt ${value}`} value={value}>
                                  {label}
                                </MenuItem>
                              ),
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Box display="flex" py={1.5}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<RadioButtonUncheckedIcon />}
                                  checkedIcon={
                                    <RadioButtonCheckedIcon color="primary" />
                                  }
                                  name="brandExtraPTEnabled"
                                  checked={isExtraPositionTakingEnabled}
                                  onChange={() => {
                                    setExtraPositionTakingEnabled(
                                      !isExtraPositionTakingEnabled,
                                    );
                                    setFieldValue('pt', 0);
                                  }}
                                />
                              }
                              label={intl.formatMessage(
                                messages.brandExtraPTCheckbox,
                              )}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            name="brandExtraPT"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="number"
                            select
                            error={error('brandExtraPT')}
                            disabled={!isExtraPositionTakingEnabled}
                            InputProps={{
                              inputProps: {
                                min: DEFAULT_AMOUNT,
                                max: MAX_PT_RATE,
                                step: DEFAULT_STEP_SIZE,
                              },
                            }}
                            value={values.brandExtraPT}
                            label={intl.formatMessage(messages.brandExtraPT)}
                            defaultValue={DEFAULT_MIN_BRAND_PT}
                            variant="outlined"
                            fullWidth
                            helperText={errors.brandExtraPT}
                            children={availableMinPTOptions.map(
                              // eslint-disable-next-line @typescript-eslint/no-shadow
                              ({ value, label }: SelectOption) => (
                                <MenuItem key={`extra ${value}`} value={value}>
                                  {label}
                                </MenuItem>
                              ),
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            name="commission"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            select
                            // type="number"
                            error={error('commission')}
                            InputProps={{
                              inputProps: {
                                min: DEFAULT_AMOUNT,
                                max: MAX_COMMISSION_RATE,
                                step: DEFAULT_STEP_SIZE,
                              },
                            }}
                            value={values.commission}
                            label={intl.formatMessage(messages.commission)}
                            defaultValue={DEFAULT_COMMISSION}
                            variant="outlined"
                            fullWidth
                            helperText={errors.commission}
                            children={availableCommissionOptions.map(
                              ({ value, label }: SelectOption) => (
                                <MenuItem key={`com. ${value}`} value={value}>
                                  {label}
                                </MenuItem>
                              ),
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            name="brandMinPT"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="number"
                            select
                            error={error('brandMinPT')}
                            InputProps={{
                              inputProps: {
                                min: DEFAULT_AMOUNT,
                                max: MAX_PT_RATE,
                                step: DEFAULT_STEP_SIZE,
                              },
                            }}
                            value={values.brandMinPT}
                            label={intl.formatMessage(messages.brandMinPT)}
                            defaultValue={DEFAULT_MIN_BRAND_PT}
                            variant="outlined"
                            fullWidth
                            helperText={errors.brandMinPT}
                            children={availableMinPTOptions.map(
                              // eslint-disable-next-line @typescript-eslint/no-shadow
                              ({ value, label }: SelectOption) => (
                                <MenuItem key={`minPT ${value}`} value={value}>
                                  {label}
                                </MenuItem>
                              ),
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            name="brandMaxPT"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="number"
                            select
                            error={error('brandMaxPT')}
                            InputProps={{
                              inputProps: {
                                min: DEFAULT_AMOUNT,
                                max: MAX_PT_RATE,
                                step: DEFAULT_STEP_SIZE,
                              },
                            }}
                            value={values.brandMaxPT}
                            label={intl.formatMessage(messages.brandMaxPT)}
                            defaultValue={MAX_PT_RATE}
                            variant="outlined"
                            fullWidth
                            helperText={errors.brandMaxPT}
                            children={availableMinPTOptions.map(
                              // eslint-disable-next-line @typescript-eslint/no-shadow
                              ({ value, label }: SelectOption) => (
                                <MenuItem key={`maxPT ${value}`} value={value}>
                                  {label}
                                </MenuItem>
                              ),
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider />
                    <BetfairBetTypeFormControl />
                    <Divider />
                    <Box p={3}>
                      <Typography variant="h6">
                        <FormattedMessage {...messages.directPlayers} />
                      </Typography>
                      <Grid container pt={3} spacing={3}>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="flex-start"
                          item
                          xs={12}
                          sm={6}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={
                                  <RadioButtonCheckedIcon color="inherit" />
                                }
                                name="isDirectPlayerEnabled"
                                checked={values.isDirectPlayerEnabled}
                                onChange={handleDirectPlayerChange}
                              />
                            }
                            label={intl.formatMessage(
                              messages.allowDirectPlayers,
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="directPlayerComission"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="number"
                            error={error('directPlayerComission')}
                            InputProps={{
                              inputProps: {
                                min: DEFAULT_AMOUNT,
                                max: MAX_COMMISSION_RATE,
                                step: DEFAULT_STEP_SIZE,
                              },
                            }}
                            select
                            defaultValue={0}
                            value={values.directPlayerComission}
                            label={intl.formatMessage(
                              messages.directPlayerComission,
                            )}
                            variant="outlined"
                            fullWidth
                            helperText={errors.directPlayerComission}
                            disabled={!values.isDirectPlayerEnabled}
                            children={availableCommissionOptions.map(
                              ({ value, label }: SelectOption) => (
                                <MenuItem key={`com. ${value}`} value={value}>
                                  {label}
                                </MenuItem>
                              ),
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider />
                    <Box p={3} display="flex">
                      <LoadingButton
                        type="submit"
                        sx={{ mr: 3 }}
                        variant="contained"
                        color="primary"
                        loading={isSubmitting}
                      >
                        <FormattedMessage {...messages.save} />
                      </LoadingButton>
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </ClosableBox>
      </Box>
    </ModalCenter>
  );
};

export default BrandOverviewAddition;
