import React from 'react';
import { Box } from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';

import DefaultLayout from '../../Unknown/DefaultLayout';

import messages from './messages';
import ProfitLossReportDashboardSection from '../../ProfitLoss/ProfitLossReportDashboardSection';

const HomePage: React.FC = () => {
  const intl = useIntl();

  const path = [{ label: intl.formatMessage(messages.dashboard) }];

  return (
    <DefaultLayout path={path}>
      <Box width={1} p={3}>
        <ProfitLossReportDashboardSection />
      </Box>
    </DefaultLayout>
  );
};

export default HomePage;
