import {
  Box,
  Grid,
  Typography,
  TextField,
  LoadingButton,
  UIContext,
  ModalCenter,
  Divider,
} from '@miyagami-com/lsx-ui-components';
import { Formik, Form, FormikHelpers } from 'formik';
import React, { useContext, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useFirebaseApp } from 'reactfire';

import { RootState, BrandWidgetPosition } from '../../../../types';
import {
  DEFAULT_REGION,
  IMAGE_TYPES,
  WIDGET_POSITIONS,
} from '../../../common/constants';

import ImageUpload, { DropzoneFile } from '../../Unknown/ImageUpload';

import messages from './messages';
import validationSchema from './validationSchema';
import useStyles from './useStyles';
import ClosableBox from '../../Unknown/ClosableBox';
import Select from '../../Unknown/Select';
import useUploadFile from '../../../common/hooks/useUploadFile';
import { v4 } from 'uuid';
import { useQueryClient } from 'react-query';
import { getFunctions, httpsCallable } from 'firebase/functions';

export type AddWidgetFormValues = {
  title: string;
  description: string;
  link: string;
  brandId: string;
  image: DropzoneFile | null;
  position: BrandWidgetPosition;
};

interface WidgetAdditionProps {
  initialValues: AddWidgetFormValues;
  isOpen: boolean;
  onClose: () => void;
}

type AddWidgetParams = {
  title: string;
  description: string;
  link: string;
  brandId: string;
  image: string;
  position: BrandWidgetPosition;
};

type WidgetPositionKey = keyof typeof WIDGET_POSITIONS;

const widgetPositionKeys = Object.keys(WIDGET_POSITIONS) as WidgetPositionKey[];

const widgetPositionMenuItems = widgetPositionKeys.map((value) => {
  const { label } = WIDGET_POSITIONS[value];

  return {
    value,
    label,
  };
});

const WidgetAddition: React.FC<WidgetAdditionProps> = ({
  initialValues,
  isOpen,
  onClose,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const uploadFile = useUploadFile();

  const { setAlert } = useContext(UIContext);

  const { brands } = useSelector((state: RootState) => state.globalData);

  const brandsMenuItems = useMemo(() => {
    return brands.map(({ name, id }) => {
      return {
        value: id,
        label: name,
      };
    });
  }, [brands]);

  const firebase = useFirebaseApp();

  const functions = getFunctions(firebase, DEFAULT_REGION);

  const queryClient = useQueryClient();

  const onSubmit = async (
    { title, description, link, brandId, image, position }: AddWidgetFormValues,
    { resetForm, setSubmitting }: FormikHelpers<AddWidgetFormValues>,
  ) => {
    const addWidgetFunction = httpsCallable(functions, 'back-widget-addWidget');

    setSubmitting(true);
    try {
      let imageURL = '';

      if (position !== 'externalRegistration') {
        if (!image) throw new Error(intl.formatMessage(messages.imageNotFound));

        const widgetFileId = v4();

        imageURL = await uploadFile(
          `brands/${brandId}/widgets/${widgetFileId}`,
          image,
          {
            contentType: image.type,
          },
        );
      }

      const addWidgetParams: AddWidgetParams = {
        title,
        description,
        link,
        brandId,
        position,
        image: imageURL,
      };

      await addWidgetFunction(addWidgetParams);
      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.textSuccessfully),
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setAlert({
        show: true,
        severity: 'warning',
        message: error.toString(),
      });
    } finally {
      resetForm({ values: initialValues });
      setSubmitting(false);
      onClose();
      queryClient.refetchQueries(['brandWidgets', brandId]);
    }
  };

  return (
    <ModalCenter open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: 380, sm: 620, md: 900 },
          p: 4,
        }}
      >
        <ClosableBox
          onClose={onClose}
          label={intl.formatMessage(messages.addWidget)}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {(params) => {
              const {
                handleChange,
                handleBlur,
                errors,
                values,
                setFieldValue,
                touched,
                isSubmitting,
              } = params;
              const error = (name: keyof AddWidgetFormValues) =>
                Boolean(errors[name] && touched[name]);

              const handleUploadFile = (
                files: DropzoneFile[],
                name: string,
              ) => {
                setFieldValue(name, files[0]);
              };

              const getFieldDefaultProps = (
                name: keyof AddWidgetFormValues,
              ): typeof defaultProps => {
                const defaultProps = {
                  name,
                  fullWidth: true,
                  value: values[name],
                  onChange: handleChange,
                  onBlur: handleBlur,
                  error: error(name),
                };

                return defaultProps;
              };

              return (
                <Form>
                  <Grid container rowSpacing={4}>
                    <Grid paddingX={3} spacing={4} container item xs={12}>
                      <Grid marginTop={3} item xs={12}>
                        <Typography variant="h6">
                          <FormattedMessage {...messages.widget} />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="outlined"
                          label={intl.formatMessage(messages.title)}
                          placeholder={intl.formatMessage(messages.turkish)}
                          {...getFieldDefaultProps('title')}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="outlined"
                          label={intl.formatMessage(messages.description)}
                          placeholder={intl.formatMessage(
                            messages.descriptionPlaceholder,
                          )}
                          {...getFieldDefaultProps('description')}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid paddingX={3} spacing={4} container item xs={12}>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          <FormattedMessage {...messages.brand} />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="outlined"
                          label={intl.formatMessage(messages.link)}
                          placeholder={intl.formatMessage(messages.httpsLink)}
                          {...getFieldDefaultProps('link')}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Select
                          variant="outlined"
                          labelId="select-brandId"
                          {...getFieldDefaultProps('brandId')}
                          menuItems={brandsMenuItems}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid paddingX={3} spacing={4} container item xs={12}>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          <FormattedMessage {...messages.positionAndImage} />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Select
                          variant="outlined"
                          labelId="select-position"
                          {...getFieldDefaultProps('position')}
                          menuItems={widgetPositionMenuItems}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <ImageUpload
                          textFieldProps={{
                            onChange: handleUploadFile,
                            name: 'image',
                            translations: {
                              label: intl.formatMessage(messages.image),
                              placeholder: intl.formatMessage(
                                messages.imagePng,
                              ),
                            },
                            fullWidth: true,
                            value: values?.image?.name || '',
                            onBlur: handleBlur,
                            error: error('image'),
                            variant: 'outlined',
                          }}
                          dropzoneProps={{
                            accept: IMAGE_TYPES,
                            maxFiles: 1,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} padding={3}>
                      <LoadingButton
                        className={classes.button}
                        type="submit"
                        variant="contained"
                        color="primary"
                        loading={isSubmitting}
                      >
                        <FormattedMessage {...messages.save} />
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </ClosableBox>
      </Box>
    </ModalCenter>
  );
};

export default WidgetAddition;
