import {
  Box,
  InputAdornment,
  TextField,
} from '@miyagami-com/lsx-ui-components';
import SearchIcon from '@mui/icons-material/Search';

interface SearchMarketIdProps {
  marketId: string;
  setMarketId: React.Dispatch<React.SetStateAction<string>>;
}

const SearchMarketId = (props: SearchMarketIdProps): JSX.Element => {
  const { marketId, setMarketId } = props;

  return (
    <TextField
      value={marketId}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setMarketId(e.target.value)
      }
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Box color="text.secondary">
              <SearchIcon />
            </Box>
          </InputAdornment>
        ),
      }}
      id="table-search"
      placeholder="Search by Market Id"
      variant="standard"
      type="search"
    />
  );
};

export default SearchMarketId;
