import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Skeleton,
  Link,
  Breadcrumbs,
} from '@miyagami-com/lsx-ui-components';
import { Link as RouterLink } from 'react-router-dom';

import useStyles from './useStyles';

export type SubTagPath = {
  label: string;
  url?: string;
};

export type SubTagsProps = {
  path: SubTagPath[];
};

const SubTags: React.FC<SubTagsProps> = ({ path }) => {
  const classes = useStyles();

  return (
    <Paper classes={{ root: classes.paperRoot }}>
      <Box px={2} pt={{ xs: 2, md: 1 }} pb={1}>
        <Breadcrumbs aria-label="breadcrumb">
          {path.map(({ label, url }, index) => {
            const isLastItem = index === path.length - 1;

            if (url) {
              return (
                <Link
                  key={label + index}
                  component={RouterLink}
                  underline="hover"
                  color="inherit"
                  to={url}
                >
                  {label}
                </Link>
              );
            }

            return (
              <Typography
                key={label + index}
                color={!isLastItem ? 'text.secondary' : 'text.primary'}
                variant="h6"
              >
                {label || <Skeleton />}
              </Typography>
            );
          })}
        </Breadcrumbs>
      </Box>
    </Paper>
  );
};

export default SubTags;
