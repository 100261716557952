interface GetPhoneNumberParams {
  phoneNumber: string;
}

const getPhoneNumber = (params: GetPhoneNumberParams): string => {
  const { phoneNumber } = params;

  const isIncludePlus = phoneNumber.indexOf('+');

  if (isIncludePlus !== -1) return phoneNumber;

  return `+${phoneNumber}`;
};

export default getPhoneNumber;
