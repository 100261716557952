import { useIntl } from 'react-intl';
import messages from '../messages';
import { colors } from '../theme';

export type BetEventStatus = 'in_play' | 'coming';

export type StatusMapParams = {
  label: string;
  color: string;
};

const useBetEventStatusMap = (): typeof statusMap => {
  const intl = useIntl();

  const statusMap: Record<BetEventStatus, StatusMapParams> = {
    in_play: {
      label: intl.formatMessage(messages.inPlayStatus),
      color: colors.successMain,
    },
    coming: {
      label: intl.formatMessage(messages.notStartedStatus),
      color: colors.warningMain,
    },
  };

  return statusMap;
};

export default useBetEventStatusMap;
