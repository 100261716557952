import React from 'react';
import { Box, Paper } from '@miyagami-com/lsx-ui-components';
import HeaderWithFilter, { HeaderWithFilterProps } from '../HeaderWithFilter';

interface OverviewLayoutProps {
  headerProps?: HeaderWithFilterProps;
  withHeader?: boolean;
}

const OverviewLayout: React.FC<OverviewLayoutProps> = ({
  children,
  headerProps,
  withHeader = true,
}) => {
  const isShowHeader = withHeader && headerProps;

  return (
    <Box p={4}>
      <Paper>
        {isShowHeader && <HeaderWithFilter {...headerProps} />}
        {children}
      </Paper>
    </Box>
  );
};

export default OverviewLayout;
