import React from 'react';
import { Box } from '@miyagami-com/lsx-ui-components';
import { useMediaQuery, useTheme } from '@mui/material';

import AppNavigation from '../AppNavigation';
import Header from '../Header';
import SubTags, { SubTagsProps } from '../SubTags';
import UserWrapper from '../UserWrapper';

export type DefaultLayoutProps = SubTagsProps;

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children, path }) => {
  const theme = useTheme();

  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <UserWrapper>
      <Box height={1} bgcolor="background.typeA">
        <Header isMobile={isMobile} isAuth={true} />
        <Box
          height={1}
          display="flex"
          overflow="hidden"
          pt={isMobile ? 10 : 13.6}
        >
          <AppNavigation>
            <SubTags path={path} />
            <Box overflow="auto">{children}</Box>
          </AppNavigation>
        </Box>
      </Box>
    </UserWrapper>
  );
};

export default DefaultLayout;
