import React from 'react';
import {
  GridColumns,
  GridRenderCellParams,
  GridRowData,
} from '@mui/x-data-grid';
import { GridGroupingValueGetterParams } from '@mui/x-data-grid-premium';
import { useIntl } from 'react-intl';

import IpAddressCell from '../../Unknown/IpAddressCell';
import DataGridTable from '../../Unknown/DataGridTable';

import messages from './messages';

type LogOverviewListProps = {
  onPageChange: (page: number) => void;
  rows: GridRowData[];
  pageSize: number;
  page: number;
  isGettingLoading: boolean;
  rowsPerPageOptions: number[];
  onPageSizeChange: (size: number) => void;
};

const LogOverviewList: React.FC<LogOverviewListProps> = ({
  onPageChange,
  rows,
  pageSize,
  page,
  isGettingLoading,
  rowsPerPageOptions,
  onPageSizeChange,
}) => {
  const intl = useIntl();

  const propertiesColumn = {
    editable: false,
    sortable: true,
    filterable: false,
  };

  const columns: GridColumns = [
    {
      field: 'id',
      hide: true,
      headerName: intl.formatMessage(messages.id),
      editable: false,
      sortable: false,
      flex: 0.75,
      width: 82,
    },
    {
      ...propertiesColumn,
      field: 'firstName',
      headerName: intl.formatMessage(messages.firstName),
      flex: 0.75,
    },
    {
      ...propertiesColumn,
      field: 'surname',
      headerName: intl.formatMessage(messages.surname),
      flex: 0.75,
    },
    {
      ...propertiesColumn,
      field: 'email',
      headerName: intl.formatMessage(messages.email),
      flex: 1,
    },
    {
      ...propertiesColumn,
      field: 'role',
      headerName: intl.formatMessage(messages.role),
      flex: 0.75,
    },
    {
      ...propertiesColumn,
      field: 'action',
      headerName: intl.formatMessage(messages.action),
      flex: 2,
    },
    {
      field: 'brandName',
      headerName: intl.formatMessage(messages.brand),
      editable: false,
      sortable: false,
      flex: 0.75,
    },
    {
      ...propertiesColumn,
      field: 'ip',
      headerName: intl.formatMessage(messages.ip),
      flex: 0.8,
      minWidth: 120,
      groupingValueGetter: (params: GridGroupingValueGetterParams) => {
        const { ipAddress } = params.row;

        return ipAddress;
      },
      renderCell: (params: GridRenderCellParams) => {
        if (!params.row) return null;

        const { ipAddress, countryCode } = params.row;

        return (
          <IpAddressCell ipAddress={ipAddress} countryCode={countryCode} />
        );
      },
    },
  ];

  return (
    <DataGridTable
      loading={isGettingLoading}
      tableKey="log-overview-list"
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={onPageSizeChange}
      rowsPerPageOptions={rowsPerPageOptions}
      disableSelectionOnClick
      autoHeight
      rowHeight={35}
      headerHeight={35}
      page={page}
      onPageChange={onPageChange}
      hideFooterRowCount
      paginationMode="server"
    />
  );
};

export default LogOverviewList;
