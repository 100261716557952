import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  MenuItem,
  TextField,
  Typography,
  Divider,
} from '@miyagami-com/lsx-ui-components';
import { GridRowData } from '@mui/x-data-grid';

import messages from './messages';
import TableDefaultToolbar from '../../Unknown/TableDefaultToolbar';

type SecurityOverviewHeaderProps = {
  isMobile: boolean;
  defaultRows?: GridRowData[];
  setRows?: CallableFunction;
  onClickAdd?: CallableFunction;
};

const SecurityOverviewHeader: React.FC<SecurityOverviewHeaderProps> = ({
  isMobile,
  defaultRows,
  setRows,
  onClickAdd,
}) => {
  const intl = useIntl();

  const [value, setValue] = useState('Latency');

  return (
    <>
      <Box
        p={3}
        display={isMobile ? 'block' : 'flex'}
        justifyContent="space-between"
      >
        <Box pr={3}>
          <Typography variant="h4">
            <FormattedMessage {...messages.performance} />
          </Typography>
          <Typography variant="h3" color="textSecondary">
            From 27-10-2021 to 27-11-2021
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={isMobile ? 'wrap' : 'nowrap'}
        >
          <Box py={isMobile ? 2 : 0} pr={3}>
            <TextField
              id="outlined-select-currency"
              select
              label={intl.formatMessage(messages.dataSet)}
              defaultValue={value}
              onChange={(e) => setValue(e.target.value)}
              variant="outlined"
              size="small"
            >
              <MenuItem value="Latency">Latency</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </TextField>
          </Box>
          <TableDefaultToolbar
            defaultRows={defaultRows}
            setRows={setRows}
            onClickAdd={onClickAdd}
          />
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default SecurityOverviewHeader;
