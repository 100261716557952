import { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { format } from 'date-fns';

import { RootState } from '../../../../types';

import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
} from '../../../common/constants';

import DefaultLayout from '../../Unknown/DefaultLayout';
import OverviewLayout from '../../Unknown/OverviewLayout';
import LoadingBox from '../../Unknown/LoadingBox';

import normalizePlayers from './normalizePlayers';
import messages from './messages';
import PlayerList from './PlayerList';
import { DataGridProps } from '@mui/x-data-grid';
import { NormalizedPlayerData } from './normalizePlayerData';
import { PlayerWithStats } from '../../../../types/supabase';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';
import TESTusePlayers from '../../../common/hooks/usePlayers';

type QueryParams = {
  brandId: string;
};

const PlayerOverviewPage: FC = () => {
  const intl = useIntl();
  const { brandId }: QueryParams = useParams();
  const { brands } = useSelector((state: RootState) => state.globalData);

  const { roles } = useSelector((root: RootState) => root.user);
  const isSystemAdmin = checkIsSystemAdmin({ roles });

  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);

  const parentPath = roles?.[0].backoffice_user_id;

  const {
    data: fetchedPlayers,
    isLoading,
    refetch,
  } = TESTusePlayers({
    brandId,
    page,
    size: pageSize,
    parentPath: parentPath || '',
    isSystemAdmin,
  });

  const [players, setPlayers] = useState<NormalizedPlayerData[]>([]);

  useEffect(() => {
    if (!fetchedPlayers) return;

    const normalizedPlayers = normalizePlayers({
      players: (fetchedPlayers.data || []) as PlayerWithStats[],
    });

    const filteredPlayers = isSystemAdmin
      ? normalizedPlayers
      : normalizedPlayers.filter((player) => player.status !== 'closed');

    setPlayers(filteredPlayers);
  }, [brandId, fetchedPlayers, isSystemAdmin]);

  const defaultPlayers = useMemo(() => {
    const normalizedPlayers = normalizePlayers({
      players: (fetchedPlayers?.data || []) as PlayerWithStats[],
    });

    return normalizedPlayers;
  }, [fetchedPlayers]);

  const path = [
    { label: intl.formatMessage(messages.player) },
    { label: intl.formatMessage(messages.playerOverview) },
  ];

  const onPageChange = useCallback(
    (newPage: number) => {
      setPage(newPage);
    },
    [setPage],
  );

  const onPageSizeChange = useCallback(
    (newPage: number) => {
      setPageSize(newPage);
    },
    [setPageSize],
  );

  const dataGridProps: Omit<DataGridProps, 'columns'> = useMemo(() => {
    return {
      page,
      pageSize,
      rows: players,
      rowCount: fetchedPlayers?.count || 0,
      loading: isLoading,
      paginationMode: 'server',
      onPageChange,
      onPageSizeChange,
    };
  }, [
    fetchedPlayers?.count,
    isLoading,
    onPageChange,
    onPageSizeChange,
    page,
    pageSize,
    players,
  ]);

  const onRefetch = () => {
    refetch();
  };

  return (
    <DefaultLayout path={path}>
      <OverviewLayout
        headerProps={{
          name: intl.formatMessage(messages.playerOverview),
          from: format(0, DEFAULT_DATE_FORMAT),
          to: format(0, DEFAULT_DATE_FORMAT),
          brands: brands,
          defaultRows: defaultPlayers,
          setRows: setPlayers,
          isPlayerOverviewPage: true,
          brandId,
        }}
      >
        {isLoading ? (
          <LoadingBox />
        ) : (
          <PlayerList onRefetch={onRefetch} dataGridProps={dataGridProps} />
        )}
      </OverviewLayout>
    </DefaultLayout>
  );
};

export default PlayerOverviewPage;
