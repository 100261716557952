import React from 'react';
import {
  GridRenderCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import InputCell from '../InputCell';

const PriorityEditCell = (props: GridRenderCellParams<number>): JSX.Element => {
  const { value, id, field } = props;

  const apiRef = useGridApiContext();

  const onChangeInputCell = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { target } = event;
    apiRef.current.setEditCellValue({
      id,
      field,
      value: target.value,
    });
  };

  return (
    <InputCell
      type="number"
      defaultValue={value}
      inputProps={{ min: 0 }}
      onChange={onChangeInputCell}
    />
  );
};

export default PriorityEditCell;
