import Big from 'big.js';

interface LayLiabilityParams {
  size: number;
  price: number;
}

const layLiability = (params: LayLiabilityParams): number => {
  const { size, price } = params;

  return new Big(new Big(price).minus(1)).times(size).toNumber();
};

export default layLiability;
