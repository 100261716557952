import { useSelector } from 'react-redux';
import { RootState } from '../../../../types';
import { DialogType } from '../BackofficeUserForm';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';
import checkIsBrandOwner from '../../../common/checkIsBrandOwner';

const useBackOfficeUserType = (): DialogType => {
  const user = useSelector((root: RootState) => root.user);

  const { roles } = user;

  const isAdmin = checkIsSystemAdmin({ roles });

  if (isAdmin) return 'systemAdmins';

  const isOwner = checkIsBrandOwner({ roles });

  if (isOwner) return 'brandOwners';

  return 'backofficeUsers';
};

export default useBackOfficeUserType;
