import { defineMessages } from 'react-intl';

export default defineMessages({
  addNewApiKey: {
    id: 'BrandApiKey.BrandApiKeyAddition.addNewApiKey',
    defaultMessage: 'Add new API key',
  },
  domain: {
    id: 'BrandApiKey.BrandApiKeyAddition.domain',
    defaultMessage: 'Domain',
  },
  betfair: {
    id: 'BrandApiKey.BrandApiKeyAddition.betfair',
    defaultMessage: 'Betfair',
  },
  location: {
    id: 'BrandApiKey.BrandApiKeyAddition.location',
    defaultMessage: 'Location',
  },
  uk: {
    id: 'BrandApiKey.BrandApiKeyAddition.uk',
    defaultMessage: 'UK',
  },
  username: {
    id: 'BrandApiKey.BrandApiKeyAddition.username',
    defaultMessage: 'Username',
  },
  apiKeyId: {
    id: 'BrandApiKey.BrandApiKeyAddition.appKeyId',
    defaultMessage: 'App key id',
  },
  password: {
    id: 'BrandApiKey.BrandApiKeyAddition.password',
    defaultMessage: 'Password',
  },
  uploadCert: {
    id: 'BrandApiKey.BrandApiKeyAddition.uploadCert',
    defaultMessage: 'Upload certificate',
  },
  uploadKey: {
    id: 'BrandApiKey.BrandApiKeyAddition.uploadKey',
    defaultMessage: 'Upload key',
  },
  save: {
    id: 'BrandApiKey.BrandApiKeyAddition.save',
    defaultMessage: 'Save',
  },
});
