import { defineMessages } from 'react-intl';

export default defineMessages({
  brand: {
    id: 'Unknown.FilterByBrand.brand',
    defaultMessage: 'Brand',
  },
  brandPendingLabel: {
    id: 'Unknown.FilterByBrand.brandPendingLabel',
    defaultMessage: 'Brand pending',
  },
  errorUploadingUsers: {
    id: 'Unknown.FilterByBrand.errorUploadingUsers',
    defaultMessage: 'An error has occurred while getting users list.',
  },
});
