import { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { TextField, MenuItem } from '@miyagami-com/lsx-ui-components';

import { Brand } from '../../../../types';

import messages from './messages';
import { useHistory, useParams } from 'react-router';

export interface FilterByBrandProps {
  brands?: Brand[];
}

type QueryParams = {
  brandId: string;
};

const FilterByBrand: FC<FilterByBrandProps> = ({ brands }) => {
  const history = useHistory();
  const { brandId } = useParams<QueryParams>();

  const intl = useIntl();

  const onChange = useCallback(
    async (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = e.target.value;

      const pathName = history.location.pathname;

      const brandQuery = `/b/${brandId}`;

      const newPath = pathName.replace(brandQuery, `/b/${value}`);

      history.push(newPath);
    },
    [brandId, history],
  );

  const sortedBrands = useMemo(() => {
    const brandsToSort = brands?.length ? brands : [];

    const sortBrand = [...brandsToSort]?.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    return sortBrand;
  }, [brands]);

  return (
    <TextField
      id="outlined-select-currency"
      select
      label={intl.formatMessage(messages.brand)}
      value={brandId}
      onChange={onChange}
      variant="outlined"
      size="small"
    >
      {sortedBrands?.map((brandObj) => (
        <MenuItem key={brandObj.id} value={brandObj.id}>
          {brandObj.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default FilterByBrand;
