import { FC } from 'react';
import { Grid } from '@miyagami-com/lsx-ui-components';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../types';
import TransactionsOverview from '../../Transaction/TransactionsOverview';
import TransactionCreation from '../../Transaction/TransactionCreation';
import useSenderTransactionList from '../../../common/hooks/useSenderTransactionList';
import usePlayer from '../../../common/hooks/usePlayer';

type QueryParams = { brandId: string; userId: string };

interface UserBalancePageProps {
  type: 'player' | 'backofficeUser';
  brandId?: string;
}

const UserBalancePage: FC<UserBalancePageProps> = (props) => {
  const { type, brandId } = props;

  const { brandId: paramsBrandId, userId }: QueryParams = useParams();

  const selectedBrandId = (paramsBrandId || brandId) as string;

  const {
    users: { byBrandId: users },
  } = useSelector((state: RootState) => state.globalData);
  const isPlayer = type === 'player';

  const { data: player } = usePlayer({ playerId: userId });

  const agent = users[selectedBrandId]?.agents?.find(({ id }) => id === userId);

  const currentUser = isPlayer ? player : agent;

  const senderList = useSenderTransactionList({
    brandId: selectedBrandId,
    user: currentUser,
  });

  return (
    <Grid container spacing={4} pt={4}>
      <Grid item xs={12}>
        <TransactionsOverview />
      </Grid>
      {currentUser && Boolean(senderList?.length) && (
        <>
          <Grid item xs={12} md={6}>
            <TransactionCreation
              currentUser={currentUser}
              type="transfer"
              userType={type}
              senderList={senderList}
              brandId={selectedBrandId}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TransactionCreation
              currentUser={currentUser}
              type="withdraw"
              userType={type}
              senderList={senderList}
              brandId={selectedBrandId}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default UserBalancePage;
