import { Theme, useMediaQuery, useTheme } from '@mui/material';

interface UseBreakpoints {
  theme: Theme;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

const useBreakpoints = (): UseBreakpoints => {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
  const isTablet = !useMediaQuery(theme.breakpoints.up('md')) && !isMobile;

  const isDesktop = !isMobile && !isTablet;

  return { theme, isMobile, isTablet, isDesktop };
};

export default useBreakpoints;
