import { TreeRow } from './createTreeRow';
// import createTreeTotalRow from './createTreeTotalRow';

interface NormalizeTreeParams {
  rows: TreeRow[][];
  isSystemAdmin: boolean;
}

const normalizeTree = (params: NormalizeTreeParams): TreeRow[][] => {
  const { rows } = params;

  const normalizedTreeRows = rows.map<TreeRow[]>((treeRows) => {
    return treeRows;
  });

  return normalizedTreeRows;
};

export default normalizeTree;
