import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  image: {
    width: '100%',
    height: 'auto',
    maxWidth: '229px',
  },
}));

export default useStyles;
