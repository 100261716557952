import { UIContext } from '@miyagami-com/lsx-ui-components';
import { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useFirebaseApp } from 'reactfire';
import { TreeRow } from '../../components/Network/NetworkTreeList/createTreeRow';
import { DEFAULT_AMOUNT, DEFAULT_REGION } from '../constants';
import messages from '../messages';
import { UseQueryResult, useQuery } from 'react-query';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface UsePlayerTreeParams {
  playerId: string;
  brandId: string;
  parentPath: string | null;
}

const usePlayerTree = (
  params: UsePlayerTreeParams,
): UseQueryResult<TreeRow[] | null> => {
  const { setAlert } = useContext(UIContext);

  const intl = useIntl();

  const firebase = useFirebaseApp();

  const functions = getFunctions(firebase, DEFAULT_REGION);

  const getPlayerTree = httpsCallable(
    functions,
    'back-networkTree-getPlayerTree',
  );

  const fetchPlayerTree = useCallback(async () => {
    try {
      const { data } = await getPlayerTree(params);

      const trees = data as TreeRow[];

      const sortedTree = trees.sort(
        (a, b) =>
          (a.sortPriority || DEFAULT_AMOUNT) -
          (b.sortPriority || DEFAULT_AMOUNT),
      );

      return sortedTree;
    } catch (error) {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.failedUploadNetworkTree),
      });
      return [];
    }
  }, [getPlayerTree, intl, params, setAlert]);

  const queryResult = useQuery(['playerTree', params.playerId], () => {
    return fetchPlayerTree();
  });

  return queryResult;
};

export default usePlayerTree;
