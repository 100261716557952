import { FC, useState, useContext } from 'react';
import {
  CollapseBox,
  Box,
  UIContext,
  Backdrop,
  CircularProgress,
  LoadingButton,
} from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { useFirebaseApp } from 'reactfire';

import {
  DEFAULT_REGION,
  ONLY_POSITIVE_AMOUNT_ERROR,
} from '../../../common/constants';
import checkBalanceAmountOnlyPositive from '../../../common/checkBalanceAmountOnlyPositive';
import LimitList, { OnUpdateLimitParams } from '../../Limit/LimitList';

import messages from './messages';
import useStyles from './useStyles';
import useVerifyPermissions from '../../../common/hooks/useVerifyPermissions';
import useUserLimit from '../../../common/hooks/useUserLimit';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types';
import { useSetSystemAdminFlagged } from '../../../common/hooks/useSetSystemAdminFlagged';
import { getFunctions, httpsCallable } from 'firebase/functions';

type QueryParams = {
  brandId: string;
  userId: string;
};

interface UserLimitsPageProps {
  type: 'player' | 'agent';
}

const UserLimitPage: FC<UserLimitsPageProps> = ({ type }) => {
  const intl = useIntl();

  const classes = useStyles();

  const { brandId, userId }: QueryParams = useParams();

  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const { setAlert } = useContext(UIContext);

  const { roles } = useSelector((root: RootState) => root.user);

  const isSystemAdmin = checkIsSystemAdmin({ roles });

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const userLimitQueryLimit = useUserLimit({ userId });

  const { data: userLimitQueryData, isLoading: userLimitsLoading } =
    userLimitQueryLimit;

  const { system_admin_flagged: systemAdminFlagged } =
    userLimitQueryData?.[0] || { system_admin_flagged: false };

  const { isSuspended, isBrandSuspended } = useVerifyPermissions();

  const isDisabled = isSuspended || isBrandSuspended;

  const onUpdateLimit = async ({
    limitKey,
    limitValue,
  }: OnUpdateLimitParams) => {
    const updateLimitsFunction = httpsCallable(
      functions,
      'back-limit-updateUserLimits',
    );

    try {
      setIsLoadingData(true);
      checkBalanceAmountOnlyPositive(limitValue);

      await updateLimitsFunction({
        type: type === 'player' ? 'player' : 'backofficeUser',
        brandId,
        userId,
        limitKey,
        limitValue,
      });

      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.updateLimitsSuccess),
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message =
        error.message === ONLY_POSITIVE_AMOUNT_ERROR
          ? messages.maxAmountError
          : messages.updateLimitsError;

      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(message),
      });
    } finally {
      setIsLoadingData(false);
    }
  };

  const flagUserMutation = useSetSystemAdminFlagged({
    userId,
    isFlagged: !systemAdminFlagged,
  });

  return (
    <Box mt={4} className={classes.parent}>
      <CollapseBox defaultValue label={intl.formatMessage(messages.tableLabel)}>
        <LimitList
          onUpdateLimit={onUpdateLimit}
          userLimitQueryLimit={userLimitQueryLimit}
          isDisabled={isDisabled}
        />
        {isSystemAdmin ? (
          <Box mt={2}>
            <CollapseBox defaultValue label={'Flag user'}>
              <Box m={4}>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  loading={flagUserMutation.isLoading || userLimitsLoading}
                  disabled={flagUserMutation.isLoading || userLimitsLoading}
                  onClick={() => {
                    flagUserMutation.mutate();
                  }}
                >
                  {systemAdminFlagged ? 'Unflag user' : 'Flag user'}
                </LoadingButton>
              </Box>
            </CollapseBox>
          </Box>
        ) : null}
      </CollapseBox>
      <Backdrop open={isLoadingData} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};

export default UserLimitPage;
