import { defineMessages } from 'react-intl';

export default defineMessages({
  system: {
    id: 'User.UserListByRolePage.system',
    defaultMessage: 'System',
  },
  brandOwners: {
    id: 'User.UserListByRolePage.brandOwners',
    defaultMessage: 'Brand owners',
  },
  systemAdmins: {
    id: 'User.UserListByRolePage.systemAdmins',
    defaultMessage: 'System admins',
  },
  addUser: {
    id: 'User.UserListByRolePage.addUser',
    defaultMessage: 'Add user',
  },
  addBrandOwnerModalTitle: {
    id: 'User.UserListByRolePage.addBrandOwnerModalTitle',
    defaultMessage: 'Add brand owner',
  },
  addSystemAdminModalTitle: {
    id: 'User.UserListByRolePage.addSystemAdminModalTitle',
    defaultMessage: 'Add system admin',
  },
});
