import React from 'react';
import { logo } from '../Images';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@miyagami-com/lsx-ui-components';
import HeaderMenu from './HeaderMenu';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types';
import { SYSTEM_ADMIN_ROLE } from '../../../common/constants';

type HeaderProps = {
  isAuth: boolean;
  isMobile: boolean;
};
const Header: React.FC<HeaderProps> = ({ isAuth, isMobile }) => {
  const authUser = useSelector((state: RootState) => state.user);

  const { roles, brandIds } = authUser;

  const parsedRoles = roles?.map((role) => role.role_id) || [];

  const isAdmin = parsedRoles?.includes(SYSTEM_ADMIN_ROLE);

  const brandId = brandIds?.[0];

  const { brands } = useSelector((root: RootState) => root.globalData);

  const userBrand = brands.find((brand) => brand.id === brandId);

  const brandName = userBrand?.name || '';

  let headerName = isAdmin ? 'TechSoftX' : brandName;

  let headerId = isAdmin
    ? 'Unknown.Header.lsxTech'
    : 'Unknown.Header.brandName';

  headerName = isAuth ? headerName : '';

  headerId = isAuth ? headerId : ' ';

  const header = {
    id: headerId,
    defaultMessage: headerName,
  };

  return (
    <Box
      position="fixed"
      width="100%"
      zIndex={1000}
      px={4}
      py={2}
      bgcolor="grey.900"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex" alignItems="center">
        <Box>
          <img
            width={isMobile ? '25px' : '40px'}
            height={isMobile ? '25px' : '40px'}
            src={logo}
          />
        </Box>
        <Box color="common.white" pl={2}>
          <Typography variant={isMobile ? 'h2' : 'h1'}>
            <FormattedMessage {...header} />
          </Typography>
        </Box>
      </Box>
      {isAuth && (
        <Box pr={1}>
          <HeaderMenu isMobile={isMobile} />
        </Box>
      )}
    </Box>
  );
};

export default Header;
