import { useIntl } from 'react-intl';
import { DEFAULT_AMOUNT, DEFAULT_CURRENCY } from '../constants';

interface UseFormatCurrencyNumberParams {
  value?: number | null;
}

const useFormatCurrencyNumber = ({
  value,
}: UseFormatCurrencyNumberParams): string => {
  const intl = useIntl();

  const formattedNumber = intl.formatNumber(value || DEFAULT_AMOUNT, {
    currency: DEFAULT_CURRENCY,
    style: 'currency',
  });

  return formattedNumber;
};

export default useFormatCurrencyNumber;
