import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { UserRole } from '../../../types';
import messages from '../messages';

type UserRoleLabel = Record<UserRole, string>;

const useUserRoleLabel = (): UserRoleLabel => {
  const intl = useIntl();

  const userRoleLabel = useMemo(() => {
    const roleObject: UserRoleLabel = {
      systemAdmin: intl.formatMessage(messages.systemAdminLabel),
      owner: intl.formatMessage(messages.ownerLabel),
      superAgent: intl.formatMessage(messages.superAgentLabel),
      masterAgent: intl.formatMessage(messages.masterAgentLabel),
      agent: intl.formatMessage(messages.agentLabel),
      support: intl.formatMessage(messages.supportLabel),
      marketing: intl.formatMessage(messages.marketingLabel),
      finance: intl.formatMessage(messages.financeLabel),
      player: intl.formatMessage(messages.playerLabel),
    };

    return roleObject;
  }, [intl]);

  return userRoleLabel;
};

export default useUserRoleLabel;
