import { defineMessages } from 'react-intl';

export default defineMessages({
  activate: {
    id: 'Unknown.ToggleMaintenanceMode.activate',
    defaultMessage: 'Activate Maintenance Mode',
  },
  deactivate: {
    id: 'Unknown.ToggleMaintenanceMode.deactivate',
    defaultMessage: 'Deactivate Maintenance Mode',
  },
  successToggleMaintenance: {
    id: 'Unknown.ToggleMaintenanceMode.successToggleMaintenance',
    defaultMessage: 'Maintenance mode successfully updated',
  },
  errorToggleMaintenance: {
    id: 'Unknown.ToggleMaintenanceMode.errorToggleMaintenance',
    defaultMessage: 'An error occurred while updating maintenance mode',
  },
});
