import { defineMessages } from 'react-intl';

export default defineMessages({
  themeLogo: {
    id: 'Theme.ThemeLogo.themeLogo',
    defaultMessage: 'Theme logo',
  },
  mainLogo: {
    id: 'Theme.ThemeLogo.mainLogo',
    defaultMessage: 'Main logo',
  },
  dragImageToUpload: {
    id: 'Theme.ThemeLogo.dragImageToUpload',
    defaultMessage: 'Drag an image to upload',
  },
  secondaryLogo: {
    id: 'Theme.ThemeLogo.secondaryLogo',
    defaultMessage: 'Secondary logo',
  },
  save: {
    id: 'Theme.ThemeLogo.save',
    defaultMessage: 'Save',
  },
  successUpload: {
    id: 'Theme.ThemeLogo.successUpload',
    defaultMessage: 'Images has been uploaded successfully.',
  },
  failedUpload: {
    id: 'Theme.ThemeLogo.failedUpload',
    defaultMessage: 'Failed upload image.',
  },
  imageNotFound: {
    id: 'Widget.WidgetAddition.imageNotFound',
    defaultMessage: 'Image not found.',
  },
  errorBrand: {
    id: 'Widget.WidgetAddition.errorBrand',
    defaultMessage: 'Please select a brand.',
  },
});
