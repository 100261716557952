import { Box } from '@miyagami-com/lsx-ui-components';
import ImageUpload, { ImageUploadProps } from '../ImageUpload';
import React from 'react';
import ImageUploadPreview, {
  ImageUploadPreviewProps,
} from '../ImageUploadPreview';

export interface ImageUploadWithPreviewProps extends ImageUploadPreviewProps {
  imageUploadProps: ImageUploadProps;
}

const ImageUploadWithPreview: React.FC<ImageUploadWithPreviewProps> = ({
  imageSrc,
  status = 'success',
  imageUploadProps,
}) => {
  return (
    <Box py={1} display="flex" space-between>
      <ImageUploadPreview status={status} imageSrc={imageSrc} />
      <ImageUpload {...imageUploadProps} />
    </Box>
  );
};

export default ImageUploadWithPreview;
