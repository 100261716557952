import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BackOfficeUser, RootState } from '../../../../types';
import { Player } from '../../../../types/supabase';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';
import { SYSTEM_TRANSACTION_PARTICIPANT } from '../../../common/constants';
import { useIntl } from 'react-intl';
import messages from './messages';

export type SenderList = {
  value?: string;
  label?: string;
};

interface UseBrandOwnerSenderListParams {
  brandId: string;
  user?: Player | BackOfficeUser;
}

const useBrandOwnerSenderList = (
  params: UseBrandOwnerSenderListParams,
): SenderList[] => {
  const { brandId } = params;

  const { users } = useSelector((state: RootState) => state.globalData);

  const { roles } = useSelector((state: RootState) => state.user);

  const intl = useIntl();

  const senderList = useMemo(() => {
    const allUsers = users?.byBrandId[brandId]?.agents || [];

    const list: SenderList[] = allUsers?.map((backofficeUser) => {
      return {
        value: backofficeUser.id,
        label: backofficeUser.username,
      };
    });

    return list;
  }, [brandId, users]);

  const isSystemAdmin = useMemo(() => checkIsSystemAdmin({ roles }), [roles]);

  if (isSystemAdmin) {
    const systemItem: SenderList = {
      value: SYSTEM_TRANSACTION_PARTICIPANT,
      label: intl.formatMessage(messages.system),
    };

    senderList.unshift(systemItem);
  }

  return senderList;
};

export default useBrandOwnerSenderList;
