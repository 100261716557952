import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { FirebaseAppProvider } from 'reactfire';
import { Provider } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { UIContextProvider } from '@miyagami-com/lsx-ui-components';

import { store } from '../../../store';
import firebaseApp from '../../../common/firebaseApp';
import theme from '../../../common/theme';

import IntlProvider from '../IntlProvider';
import Root from '../Root';
import { SupabaseContextProvider } from '../SupabaseProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AuthContextProvider } from '../AuthContext';
import { CookiesProvider } from 'react-cookie';
import FirebaseSDKWrapper from '../../../components/Firebase/FirebaseSDKWrapper';
import TotpAuthProvider from '../../BackofficeUser/Totp/TotpAuthProvider';

const App: React.FC = () => {
  const queryClient = new QueryClient();

  return (
    <StyledEngineProvider injectFirst>
      <CookiesProvider>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <FirebaseAppProvider firebaseApp={firebaseApp}>
              <IntlProvider>
                <FirebaseSDKWrapper>
                  <SupabaseContextProvider>
                    <AuthContextProvider>
                      <QueryClientProvider client={queryClient}>
                        <ReactQueryDevtools />
                        <Router basename="/">
                          <CssBaseline />
                          <UIContextProvider>
                            <TotpAuthProvider>
                              <Root />
                            </TotpAuthProvider>
                          </UIContextProvider>
                        </Router>
                      </QueryClientProvider>
                    </AuthContextProvider>
                  </SupabaseContextProvider>
                </FirebaseSDKWrapper>
              </IntlProvider>
            </FirebaseAppProvider>
          </Provider>
        </ThemeProvider>
      </CookiesProvider>
    </StyledEngineProvider>
  );
};

export default App;
