import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, UIContext, ModalCenter } from '@miyagami-com/lsx-ui-components';
import { useFirebaseApp } from 'reactfire';

import {
  BackOfficeUserStatus,
  BackofficeUserRole,
  Brand,
  RootState,
  UserRole,
} from '../../../../types';
import ClosableBox from '../../Unknown/ClosableBox';
import BackofficeUserForm, {
  BackofficeUserFormValues,
  DialogType,
} from '../../Unknown/BackofficeUserForm';
import {
  DEFAULT_REGION,
  SYSTEM_ADMIN_ROLE,
  BRAND_OWNER_ROLE,
} from '../../../common/constants';

import messages from './messages';
import useBrandId from '../../../common/hooks/useBrandId';
import useRefetchBackofficeUsers from '../../../common/hooks/useRefetchBackofficeUsers';
import { useSelector } from 'react-redux';
import { getFunctions, httpsCallable } from 'firebase/functions';

type BackofficeUserAdditionProps = {
  isOpen: boolean;
  onClose: CallableFunction;
  brands: Brand[];
  dialogTitle?: string;
  type?: DialogType;
};

export type NetworkConfig = {
  positionTakingRate?: number;
  commissionRate?: number;
};

export type CreateUserParams = NetworkConfig & {
  email: string;
  name: string;
  surname: string;
  authUsername?: string;
  roleId: UserRole;
  mfa: boolean;
  brandId: string | null;
  isEnableFirewall: boolean;
  country: string | null;
  city: string | null;
  street: string | null;
  zipCode: string | null;
  phoneNumber: string | null;
  status: BackOfficeUserStatus;
  parentId: string | null;
  parentPath: string | null;
};

const mapRoleByType = {
  systemAdmins: SYSTEM_ADMIN_ROLE,
  brandOwners: BRAND_OWNER_ROLE,
  backofficeUsers: '',
};

const BackofficeUserAddition: React.FC<BackofficeUserAdditionProps> = (
  props,
) => {
  const {
    isOpen,
    onClose,
    brands,
    dialogTitle,
    type = 'backofficeUsers',
  } = props;

  const { uid } = useSelector((root: RootState) => root.user);

  const intl = useIntl();
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const { setAlert } = useContext(UIContext);

  const rootBrandId = useBrandId();

  const firebase = useFirebaseApp();

  const functions = getFunctions(firebase, DEFAULT_REGION);

  const initialValues: BackofficeUserFormValues = {
    name: '',
    surname: '',
    email: '',
    role: mapRoleByType[type] as BackofficeUserRole,
    mfa: false,
    isEnableFirewall: false,
  };

  const refetchBackofficeUsers = useRefetchBackofficeUsers();

  const onCreateUser = async (creationParams: BackofficeUserFormValues) => {
    const { email, name, role, surname, mfa, brandId, isEnableFirewall } =
      creationParams;

    const createUser = httpsCallable(functions, 'back-user-createUser');

    setIsButtonLoading(true);
    try {
      const isBackOfficeUser = type === 'backofficeUsers';

      const selectedBrandId = isBackOfficeUser
        ? brandId || !!rootBrandId
          ? rootBrandId
          : null
        : null;

      const isOwner = role === 'owner';

      const userCreationParams: Omit<
        CreateUserParams,
        'phoneNumber' | 'status'
      > = {
        email,
        name,
        roleId: role,
        surname,
        mfa,
        brandId: selectedBrandId,
        isEnableFirewall,
        city: null,
        country: null,
        zipCode: null,
        street: null,
        parentId: null,
        parentPath: null,
      };

      if (isOwner) {
        userCreationParams.parentId = uid;
        userCreationParams.parentPath = uid;
      }

      await createUser(userCreationParams);

      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.textForUserCreated),
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setAlert({
        show: true,
        severity: 'error',
        message:
          error.message || intl.formatMessage(messages.errorCreatingUser),
      });
    } finally {
      setIsButtonLoading(false);
      onClose();
      refetchBackofficeUsers();
    }
  };

  return (
    <ModalCenter open={isOpen} onClose={() => onClose()}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: 380, sm: 620, md: 900 },
          p: 4,
        }}
      >
        <ClosableBox
          label={dialogTitle || intl.formatMessage(messages.addBackofficeUser)}
          onClose={onClose}
        >
          <BackofficeUserForm
            initialValues={initialValues}
            onSubmit={onCreateUser}
            brands={brands}
            isButtonLoading={isButtonLoading}
            type={type}
          />
        </ClosableBox>
      </Box>
    </ModalCenter>
  );
};

export default BackofficeUserAddition;
