import React from 'react';
import {
  FormControl,
  InputLabel,
  Select as SelectBase,
  MenuItem as MenuItemBase,
} from '@miyagami-com/lsx-ui-components';
import { SelectProps as SelectPropsBase } from '@mui/material/Select';

export type MenuItem = {
  label: string;
  value?: string | number;
  hidden?: boolean;
};

export interface SelectProps extends SelectPropsBase {
  menuItems: MenuItem[];
}

const Select: React.FC<SelectProps> = ({
  label,
  labelId,
  menuItems,
  ...otherProps
}) => {
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id={labelId}>{label}</InputLabel>
      <SelectBase labelId={labelId} id={labelId} label={label} {...otherProps}>
        {menuItems.map(({ label: menuLabel, value, hidden }) => {
          return (
            <MenuItemBase
              key={value}
              value={value}
              sx={{ display: hidden ? 'none' : 'block' }}
            >
              {menuLabel}
            </MenuItemBase>
          );
        })}
      </SelectBase>
    </FormControl>
  );
};

export default Select;
