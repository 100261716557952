import usePlayerPlatformApiClient from './usePlayerPlatformApiClient';

export type CacheType =
  | 'betfairSession'
  | 'brandContext'
  | 'exchangeConfig'
  | 'exchangeNavigationNode';

export interface ForceUpdateCacheParams {
  type: CacheType;
}

interface UsePlatformForceCacheParams {
  brandId: string;
}

type ForceCache = (forceCacheParams: ForceUpdateCacheParams[]) => Promise<void>;

const usePlatformForceCache = (
  params: UsePlatformForceCacheParams,
): ForceCache => {
  const { brandId } = params;

  const apiClient = usePlayerPlatformApiClient(brandId);

  const forceCache = async (forceCacheParams: ForceUpdateCacheParams[]) => {
    await apiClient.post('/cache/forceUpdate', {
      forceCaches: forceCacheParams,
    });
  };

  return forceCache;
};

export default usePlatformForceCache;
