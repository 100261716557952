import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ExtendedBackOfficeUser } from '../../../../types';
import { TabsData } from '../../User/UserAccountTabs';
import BrandOwnerAccountPage from '../BrandOwnerAccountPage';
import BrandOwnerBalancePage from '../BrandOwnerBalancePage';
import messages from './messages';

interface UseBrandOwnerTabListParams {
  user: ExtendedBackOfficeUser;
  brandId: string;
  setLastActivityTime: React.Dispatch<React.SetStateAction<number | null>>;
}

const useBrandOwnerTabList = (
  params: UseBrandOwnerTabListParams,
): TabsData[] => {
  const intl = useIntl();

  const { user, brandId, setLastActivityTime } = params;

  const tabList = useMemo(
    () => [
      {
        label: intl.formatMessage(messages.accountLabel),
        value: 'account',
        component: (
          <BrandOwnerAccountPage
            setLastActivityTime={setLastActivityTime}
            user={user}
            brandId={brandId}
          />
        ),
      },
      {
        label: intl.formatMessage(messages.balanceLabel),
        value: 'balance',
        component: <BrandOwnerBalancePage brandId={brandId} user={user} />,
      },
    ],
    [brandId, intl, setLastActivityTime, user],
  );

  return tabList;
};

export default useBrandOwnerTabList;
