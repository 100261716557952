import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { UIContext } from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';
import { useFirebaseApp } from 'reactfire';
import {
  DataGridProps,
  GridEventListener,
  GridRowData,
} from '@mui/x-data-grid';
import {
  DEFAULT_REGION,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
} from '../../../common/constants';
import getLanguageTranslationsCount from './getLanguageTranslationsCount';
import getTranslationsByLanguageId from './getTranslationsByLanguageId';
import getLanguageById from './getLanguageById';
import commonMessages from '../../../common/messages';
import DefaultLayout from '../../Unknown/DefaultLayout';
import OverviewLayout from '../../Unknown/OverviewLayout';
import LanguageTranslationsList from '../LanguageTranslationsList';
import messages from './messages';
import { getFunctions, httpsCallable } from 'firebase/functions';

const LanguageTranslationsPage: FC = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [pageSize, setPageSize] = useState(DEFAULT_ROWS_PER_PAGE_OPTIONS[5]);
  const [currentPage, setCurrentPage] = useState(0);
  const [translations, setTranslations] = useState<GridRowData[]>([]);
  const intl = useIntl();
  const { languageId } = useParams<{ languageId: string }>();
  const { setAlert } = useContext(UIContext);
  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);
  const updateLanguageTranslation = httpsCallable(
    functions,
    'back-languageTranslation-updateLanguageTranslation',
  );

  const offset = currentPage * pageSize;

  const {
    data: language,
    isFetching: isLanguageFetching,
    isError: isLanguageError,
  } = useQuery(['getLanguageById', languageId], () =>
    getLanguageById({ id: languageId }),
  );

  const {
    data: translationsData,
    isFetching: isTranslationsFetching,
    isError: isTranslationsError,
  } = useQuery(
    ['getTranslationsByLanguageId', offset, pageSize, languageId],
    () => getTranslationsByLanguageId({ offset, limit: pageSize, languageId }),
  );

  // separate query as offset & limit inside RPC prevents from getting the total amount
  const {
    data: rowCount,
    isFetching: isCountFetching,
    isError: isCountError,
  } = useQuery(['getTranslationsCount', languageId], () =>
    getLanguageTranslationsCount({ languageId }),
  );

  const defaultTranslations = useMemo(() => {
    return (
      translationsData?.map(
        ({
          translation_id: id,
          translation_category: category,
          translation_key: key,
          translation_message: translation,
        }) => ({
          id,
          category,
          key,
          translation,
        }),
      ) || []
    );
  }, [translationsData]);

  useEffect(() => {
    if (isLanguageError || isTranslationsError || isCountError) {
      setAlert({
        message: intl.formatMessage(commonMessages.fetchErrorMessage),
        severity: 'error',
        show: true,
      });
    }
  }, [intl, isCountError, isLanguageError, isTranslationsError, setAlert]);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const onPageSizeChange = (size: number) => {
    setPageSize(size);
  };

  const onCellEditCommit = useCallback<GridEventListener<'cellEditCommit'>>(
    async ({ id, value }) => {
      try {
        setIsUpdating(true);
        await updateLanguageTranslation({
          id,
          translation: value,
        });
        setAlert({
          severity: 'success',
          message: intl.formatMessage(messages.successfullyUpdated),
          show: true,
        });
      } catch (error) {
        setAlert({
          severity: 'error',
          message: intl.formatMessage(messages.updateErrorMessage),
          show: true,
        });
      } finally {
        setIsUpdating(false);
      }
    },
    [intl, setAlert, updateLanguageTranslation],
  );

  const dataGridProps = useMemo<Omit<DataGridProps, 'columns'>>(() => {
    return {
      rows: translations,
      loading:
        isLanguageFetching ||
        isTranslationsFetching ||
        isCountFetching ||
        isUpdating,
      pageSize,
      currentPage,
      rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
      paginationMode: 'server',
      rowCount,
      onCellEditCommit,
      onPageChange,
      onPageSizeChange,
    };
  }, [
    translations,
    isLanguageFetching,
    isTranslationsFetching,
    isCountFetching,
    isUpdating,
    pageSize,
    currentPage,
    rowCount,
    onCellEditCommit,
  ]);

  if (!language) return null;

  const path = [
    {
      label: intl.formatMessage(messages.cms),
    },
    {
      label: intl.formatMessage(messages.internalization),
      url: '/internalization',
    },
    {
      label: language.name,
    },
  ];

  return (
    <DefaultLayout path={path}>
      <OverviewLayout
        headerProps={{
          name: language.name,
          defaultRows: defaultTranslations,
          setRows: setTranslations,
        }}
      >
        <LanguageTranslationsList dataGridProps={dataGridProps} />
      </OverviewLayout>
    </DefaultLayout>
  );
};

export default LanguageTranslationsPage;
