import React, { useContext } from 'react';
import {
  CollapseBox,
  Divider,
  Grid,
  LoadingButton,
  Typography,
  UIContext,
} from '@miyagami-com/lsx-ui-components';
import { Formik, FormikHelpers, Form } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFirebaseApp } from 'reactfire';

import { DEFAULT_REGION } from '../../../common/constants';

import messages from './messages';

import validationSchema from './validationSchema';
import { Box, TextField } from '@mui/material';
import useBackOfficeUserType from '../../Unknown/AuthenticatedRoot/useBackOfficeUserType';
import { getFunctions, httpsCallable } from 'firebase/functions';

const initialValues = {
  date: '',
};

type RenderInputs = {
  date: keyof typeof initialValues;
};

type FormValues = typeof initialValues;

const ProfitLossManualCreation: React.FC = () => {
  const intl = useIntl();
  const { setAlert } = useContext(UIContext);

  const userRole = useBackOfficeUserType();

  const isAdmin = userRole === 'systemAdmins';

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const onSubmit = async (
    { date }: FormValues,
    { resetForm, setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    setSubmitting(true);
    const createPLReport = httpsCallable(
      functions,
      'back-profitLoss-createReportManual',
    );
    try {
      await createPLReport({ date });

      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.succes),
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setAlert({
        show: true,
        severity: 'error',
        message: error?.message,
      });
    } finally {
      resetForm();
      setSubmitting(false);
    }
  };

  return (
    <CollapseBox
      label={intl.formatMessage(messages.collapseBoxLabel)}
      defaultValue={true}
    >
      <Box>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ handleChange, handleBlur, errors, isSubmitting, touched }) => {
            const error = (date: keyof RenderInputs) =>
              Boolean(errors[date] && touched[date]);

            return (
              <Form>
                <Grid container spacing={3}>
                  <Grid container marginX={3} pt={3} spacing={3}>
                    <Grid item xs={6} marginTop={3}>
                      <Typography variant="h6">
                        <FormattedMessage {...messages.collapseBoxLabel} />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} marginX={3}>
                    <TextField
                      disabled={!isAdmin}
                      fullWidth
                      id="date"
                      placeholder="YYYY-MM-DD"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={error('date')}
                    />
                    {error('date') && (
                      <Typography color="error">{errors.date}</Typography>
                    )}
                    <Divider />
                  </Grid>
                  <Grid item xs={12} marginX={3} marginBottom={3}>
                    <LoadingButton
                      disabled={!isAdmin}
                      loading={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      <FormattedMessage {...messages.saveButton} />
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </CollapseBox>
  );
};

export default ProfitLossManualCreation;
