import { useIntl } from 'react-intl';
import { SelectProps } from '../../Unknown/Select';
import messages from './messages';

const useReportPeriodMenuItems = (): SelectProps['menuItems'] => {
  const intl = useIntl();

  return [
    {
      value: 'today',
      label: intl.formatMessage(messages.today),
    },
    {
      value: 'yesterday',
      label: intl.formatMessage(messages.yesterday),
    },
    {
      value: 'lastWeek',
      label: intl.formatMessage(messages.lastWeek),
    },
    {
      value: 'lastMonth',
      label: intl.formatMessage(messages.lastMonth),
    },
    {
      value: 'currentYear',
      label: intl.formatMessage(messages.currentYear),
    },
    {
      value: 'lastYear',
      label: intl.formatMessage(messages.lastYear),
    },
  ];
};

export default useReportPeriodMenuItems;
