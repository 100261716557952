import { useMemo } from 'react';
import useBrandId from '../../../common/hooks/useBrandId';
import {
  GridCellParams,
  GridCellProps,
  GridColumns,
  GridRenderCellParams,
} from '@mui/x-data-grid-premium';
import { useIntl } from 'react-intl';
import messages from './messages';
import { PLReportRow } from '../ProfitLossReportPage/normalizeReports';
import { currencyValueFormatter, getReportLevelHref } from './utils';
import { ProfitLossReportLevel } from '../../../../types';
import { Typography } from '@miyagami-com/lsx-ui-components';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import useReportLevelMessage from './useReportLevelMessage';
import useStyles from './useStyles';

interface UseColumnsParams {
  reportLevel: ProfitLossReportLevel;
}

const propertiesColumn: Partial<GridCellProps> = {
  editable: false,
  filterable: false,
  minWidth: 150,
  flex: 1,
};

const useColumns = (props: UseColumnsParams): GridColumns => {
  const { reportLevel } = props;

  const brandId = useBrandId();

  const intl = useIntl();

  const messageByReportLevel = useReportLevelMessage();

  const { search } = useLocation();

  const classes = useStyles();

  const isAgent = reportLevel === 'agent';
  const isSystemAdmin = reportLevel === 'systemAdmin';

  const currencyCellProps: Partial<GridCellProps> = useMemo(() => {
    return {
      align: 'center',
      type: 'number',
      cellClassName: (params: GridCellParams<number>) =>
        params?.value && params.value < 0 ? classes.cellError : classes.cell,
      valueFormatter: currencyValueFormatter,
    };
  }, [classes.cell, classes.cellError]);

  const coloredCurrencyCellProps: Partial<GridCellProps> = useMemo(() => {
    return {
      align: 'center',
      type: 'number',
      cellClassName: (params: GridCellParams<number>) =>
        params?.value && params.value < 0
          ? classes.cellErrorWithUnderline
          : classes.blueText,
      valueFormatter: currencyValueFormatter,
    };
  }, [classes.blueText, classes.cellErrorWithUnderline]);

  const playerColumns: GridColumns = useMemo(() => {
    return [
      {
        ...propertiesColumn,
        ...currencyCellProps,
        field: 'playerWinLoss',
        headerName: intl.formatMessage(messages.winLoss),
      },
      {
        ...propertiesColumn,
        ...coloredCurrencyCellProps,
        field: 'playerCommission',
        headerName: intl.formatMessage(messages.commission),
      },
      {
        ...propertiesColumn,
        ...currencyCellProps,
        field: 'playerTotal',
        headerName: intl.formatMessage(messages.total),
      },
    ];
  }, [currencyCellProps, coloredCurrencyCellProps, intl]);

  const parentColumns: GridColumns = useMemo(() => {
    return [
      {
        ...propertiesColumn,
        ...currencyCellProps,
        field: 'parentWinLoss',
        headerName: intl.formatMessage(messages.winLoss),
      },
      {
        ...propertiesColumn,
        ...coloredCurrencyCellProps,
        field: 'parentCommission',
        headerName: intl.formatMessage(messages.commission),
      },
      {
        ...propertiesColumn,
        ...currencyCellProps,
        field: 'parentTotal',
        headerName: intl.formatMessage(messages.total),
      },
      {
        ...propertiesColumn,
        ...currencyCellProps,
        field: 'upLine',
        headerName: intl.formatMessage(messages.upLine),
        hide: isSystemAdmin,
      },
    ];
  }, [currencyCellProps, intl, coloredCurrencyCellProps, isSystemAdmin]);

  const generalColumns: GridColumns = useMemo(() => {
    return [
      {
        ...propertiesColumn,
        hide: true,
        field: 'id',
      },
      {
        ...propertiesColumn,
        field: 'name',
        headerName: intl.formatMessage(messages.name),
        renderCell: (params: GridRenderCellParams<string, PLReportRow>) => {
          const { row, value, aggregation } = params;

          const isAggregation = !!aggregation;

          const name = value;

          const href = row.refId
            ? getReportLevelHref({
                reportLevel,
                brandId,
                id: row.refId,
              })
            : null;

          if (isAggregation) {
            return (
              <Typography
                fontSize={14}
                color="primary.dark"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {name}
              </Typography>
            );
          }

          return (
            <Typography
              to={`${href}${search}`}
              component={Link}
              fontSize={14}
              color="primary.dark"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {name}
            </Typography>
          );
        },
      },
      {
        ...propertiesColumn,
        field: 'level',
        align: 'center',
        headerName: intl.formatMessage(messages.level),
        renderCell: (
          params: GridRenderCellParams<ProfitLossReportLevel, PLReportRow>,
        ) => {
          const { value } = params;

          if (!value) return null;

          const message = messageByReportLevel[value];

          return message;
        },
      },
      {
        ...propertiesColumn,
        field: 'totalBets',
        align: 'center',
        type: 'number',
        headerName: intl.formatMessage(messages.totalBets),
      },
      {
        ...propertiesColumn,
        ...currencyCellProps,
        field: 'tumOver',
        headerName: intl.formatMessage(messages.tumOver),
      },
      {
        ...propertiesColumn,
        ...currencyCellProps,
        field: 'payout',
        headerName: intl.formatMessage(messages.payout),
        hide: isAgent,
      },
    ];
  }, [
    brandId,
    currencyCellProps,
    intl,
    isAgent,
    messageByReportLevel,
    reportLevel,
    search,
  ]);

  if (isAgent) {
    const columns: GridColumns = [
      ...generalColumns,
      ...playerColumns,
      ...parentColumns,
    ];

    return columns;
  }

  const columns: GridColumns = [...generalColumns, ...parentColumns];

  return columns;
};

export default useColumns;
