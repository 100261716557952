import { CollapseBox } from '@miyagami-com/lsx-ui-components';
import React from 'react';
import { useIntl } from 'react-intl';
import { UseQueryResult } from 'react-query';
import { EventDetail } from '../../../common/hooks/useEventDetail';
import DataGridTable from '../../Unknown/DataGridTable';
import messages from './messages';
import useColumns from './useColumns';
import useNormalizeBets from './useNormalizeBets';

interface EventDetailExchangeBetLogsProps {
  eventDetailQuery: UseQueryResult<EventDetail, Error>;
  brandId: string;
}

const EventDetailExchangeBetLogs: React.FC<EventDetailExchangeBetLogsProps> = (
  props,
) => {
  const { eventDetailQuery, brandId } = props;

  const { data } = eventDetailQuery;

  const intl = useIntl();

  const columns = useColumns({ brandId });

  const normalizedData = useNormalizeBets({
    exchangeBets: data?.exchange_bets || [],
  });

  if (!data) return null;

  return (
    <CollapseBox label={intl.formatMessage(messages.betLog)} defaultValue>
      <DataGridTable
        rows={normalizedData}
        columns={columns}
        tableKey="eventDetailExchange_BetLogs"
        pageSize={100}
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        disableSelectionOnClick
        autoHeight
        rowHeight={35}
        headerHeight={35}
      />
    </CollapseBox>
  );
};

export default EventDetailExchangeBetLogs;
