import Big from 'big.js';
import { DEFAULT_PAGINATION_LIMIT } from './constants';

interface GetPaginationParams {
  page: number;
  size: number;
  paginationLimit?: number;
}

const DEFAULT_PAGE = 0;

export interface Pagination {
  from: number;
  to: number;
}

const getPagination = (params: GetPaginationParams): Pagination => {
  const { page, size, paginationLimit = DEFAULT_PAGINATION_LIMIT } = params;

  const limit = size ? +size : paginationLimit;

  const from = page ? new Big(page).times(limit).toNumber() : DEFAULT_PAGE;

  const to = page
    ? new Big(from).plus(size).minus(1).toNumber()
    : new Big(size).minus(1).toNumber();

  return { from, to };
};

export default getPagination;
