import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { ProfitLossReportPeriod } from '../../../../types';
import { colors } from '../../../common/theme';
import {
  getTicksValues,
  getFormattedDateLabel,
  ProfitLossReportGraphItem,
  withMargin,
  formatCurrencyValue,
} from './utils';
import TooltipContent from './TooltipContent';
import messages from './messages';

export interface ProfitLossReportDashboardGraphProps {
  data: ProfitLossReportGraphItem[];
  reportPeriod: ProfitLossReportPeriod;
}

const ProfitLossReportDashboardGraph: React.FC<ProfitLossReportDashboardGraphProps> =
  ({ data, reportPeriod }) => {
    const intl = useIntl();
    const theme = useTheme();
    const profitColor = theme.palette.success.main;
    const lossColor = theme.palette.error.main;
    const { ticks, domain } = useMemo(() => {
      return getTicksValues({ data, reportPeriod });
    }, [data, reportPeriod]);

    return (
      <Box py={2} height={500}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            margin={{
              top: 5,
              right: 20,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              domain={domain}
              type="number"
              ticks={ticks}
              tickFormatter={(timestamp) =>
                getFormattedDateLabel({ timestamp, reportPeriod })
              }
            />
            <YAxis
              type="number"
              domain={[
                (min: number) => Math.min(withMargin(min), 0),
                (max: number) => Math.max(withMargin(max), 0),
              ]}
              tickFormatter={(value) => formatCurrencyValue({ value })}
            />
            <Tooltip content={<TooltipContent reportPeriod={reportPeriod} />} />
            <Legend
              payload={[
                {
                  value: intl.formatMessage(messages.profit),
                  type: 'rect',
                  id: 'pl-report-profit-legend',
                  color: profitColor,
                },
                {
                  value: intl.formatMessage(messages.loss),
                  type: 'rect',
                  id: 'pl-report-loss-legend',
                  color: lossColor,
                },
              ]}
            />
            <ReferenceLine y={0} stroke={colors.contrastBlack} />
            <Bar dataKey="win_loss" maxBarSize={60}>
              {data.map((reportItem) => (
                <Cell
                  key={reportItem.date}
                  fill={reportItem.win_loss > 0 ? profitColor : lossColor}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    );
  };

export default ProfitLossReportDashboardGraph;
