import * as Yup from 'yup';
import { IMAGE_TYPES } from '../../../common/constants';

const imageValidate = Yup.mixed()
  .nullable()
  .test('fileType', 'Unsupported File Format', (value, context) => {
    if (value === null || value === undefined) return true;

    const isValid = IMAGE_TYPES.includes(value.type);

    if (!isValid) context.createError();

    return isValid;
  });

const validationSchema = Yup.object()
  .shape({
    mainLogo: imageValidate,
    secondaryLogo: imageValidate,
  })
  .test('atLeastOneLogo', 'At least one logo should be uploaded', (value) => {
    return value.mainLogo !== null || value.secondaryLogo !== null;
  });

export default validationSchema;
