import { useQuery, UseQueryResult } from 'react-query';
import { DEFAULT_REGION } from '../../../common/constants';
import { useFirebaseApp } from 'reactfire';
import { getFunctions, httpsCallable } from 'firebase/functions';

const useGetSpotTypes = (): UseQueryResult<string[]> => {
  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);
  const getListEventTypes = httpsCallable(
    functions,
    'back-exchangeBet-getListEventTypes',
  );
  return useQuery<string[]>({
    queryKey: 'spotTypes',
    queryFn: async () => {
      const response = await getListEventTypes();
      const responseData = response.data as { id: string; name: string }[];

      return responseData.map((item) => item.name);
    },
  });
};

export default useGetSpotTypes;
