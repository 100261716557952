import { startOfDay } from 'date-fns';
import { PublicPLReport } from '../../../../types';
import arrayByField from '../../../common/arrayByField';
import { ProfitLossReportGraphItem } from '../ProfitLossReportDashboardGraph/utils';
import { DEFAULT_AMOUNT } from '../../../common/constants';
import Big from 'big.js';

interface NormalizeReportsParams {
  reports?: PublicPLReport[] | null;
}

const normalizeReports = (
  params: NormalizeReportsParams,
): ProfitLossReportGraphItem[] => {
  const { reports } = params;

  if (!reports?.length) return [];

  const reportsByDay = arrayByField({
    array: reports,
    getId: (report) =>
      startOfDay(
        new Date(report.general?.createdAt || DEFAULT_AMOUNT),
      ).getTime(),
  });

  const entries = Object.entries(reportsByDay);

  const graphItems = entries.map<ProfitLossReportGraphItem>(
    ([dateTime, dayReports]) => {
      const total = dayReports.reduce(
        (reportTotal, report) =>
          new Big(reportTotal)
            .plus(report.parent?.total || DEFAULT_AMOUNT)
            .toNumber(),
        0,
      );

      const item: ProfitLossReportGraphItem = {
        date: Number(dateTime),
        win_loss: total,
      };

      return item;
    },
  );

  return graphItems;
};

export default normalizeReports;
