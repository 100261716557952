import React from 'react';
import { DEFAULT_LIMIT_LEVEL, RISK_LEVELS } from '../../../common/constants';
import SelectCell from '../SelectCell';
import { RiskLevel } from '../../../../types';
import {
  GridRenderCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-premium';

const RiskLevelEditCell = (
  props: GridRenderCellParams<RiskLevel>,
): JSX.Element => {
  const apiRef = useGridApiContext();

  const { value, id, field } = props;

  const riskLevel = value || DEFAULT_LIMIT_LEVEL;

  const defaultValue = RISK_LEVELS[riskLevel].label;

  const onChangeRiskLevel = (newValue: string) => {
    apiRef.current.setEditCellValue({
      id,
      field,
      value: newValue,
    });
  };

  return (
    <SelectCell
      optionsList={RISK_LEVELS}
      onChangeValue={onChangeRiskLevel}
      defaultValue={defaultValue}
    />
  );
};

export default RiskLevelEditCell;
