import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { MultiFactorResolver } from 'firebase/auth';
import {
  Box,
  Paper,
  Typography,
  UIContext,
} from '@miyagami-com/lsx-ui-components';
import { useHistory } from 'react-router';
import { FormikHelpers } from 'formik';

import useClientRect from '../../../common/hooks/useClientRect';
import confirmMfaCode from '../../../common/generalFunctions/confirmMfaCode';

import AuthenticationLayout from '../../Unknown/AuthenticationLayout';
import useAuth from '../../Unknown/AuthContext/useAuth';

import Recaptcha from '../Recaptcha';
import MfaCodeForm, { SubmitCodeValues } from '../MfaForm';

import messages from './messages';
import useStyles from './useStyles';
import LoginForm from './LoginForm';
import useSignIn from './useSignIn';
import useTotpAuthContext from '../Totp/useTotpAuthContext';

const LoginPage: React.FC = () => {
  const { setAlert } = useContext(UIContext);

  const { isWithMfa } = useAuth();

  const { mfaResolver: currentMFAResolver } = useTotpAuthContext();

  const [recaptcha, recaptchaRef] = useClientRect<HTMLDivElement>();

  const dispatch = useDispatch();

  const classes = useStyles();
  const history = useHistory();

  const stepState = useState<number>(0);

  const [step, setStep] = stepState;

  const verificationIdState = useState<string | null>(null);
  const resolverState = useState<MultiFactorResolver | null>(null);

  const [verificationId] = verificationIdState;

  const onSignIn = useSignIn({
    recaptcha,
    resolverState,
    verificationIdState,
    stepState,
  });

  const onSubmitMfaCode = async (
    values: SubmitCodeValues,
    { setSubmitting }: FormikHelpers<SubmitCodeValues>,
  ) => {
    setSubmitting(true);

    if (currentMFAResolver?.mfaResolver) {
      await confirmMfaCode({
        values,
        verificationId,
        resolver: currentMFAResolver.mfaResolver,
        dispatch,
        setAlert,
        history,
        setSubmitting,
      });
    }
  };

  return (
    <AuthenticationLayout>
      <Paper>
        <Box>
          <Box
            py={2.3}
            px={3}
            className={classes.borderRadius}
            bgcolor="grey.900"
            color="common.white"
          >
            <Typography>
              <FormattedMessage {...messages.login} />
            </Typography>
          </Box>
          {step === 0 && (
            <>
              <Recaptcha recaptchaRef={recaptchaRef} recaptcha={recaptcha} />
              <LoginForm onSubmit={onSignIn} />
            </>
          )}
          {isWithMfa && step === 1 && (
            <MfaCodeForm
              onBack={() => setStep(0)}
              onSubmitMfaCode={onSubmitMfaCode}
            />
          )}
        </Box>
      </Paper>
    </AuthenticationLayout>
  );
};

export default LoginPage;
