import { format } from 'date-fns';
import getMinMaxNumber from './getMinMaxNumber';
import { DEFAULT_DATE_FORMAT } from './constants';

interface GetFromToNumbersParams<T> {
  list: T[];
  key?: keyof T;
}

interface FromToNumbers {
  from: string | null;
  to: string | null;
}

const formatTimestamp = (timestamp: number): string | null => {
  return timestamp !== 0 ? format(timestamp, DEFAULT_DATE_FORMAT) : null;
};

const getFromToNumbers = <T>({
  list,
  key = 'created_at' as keyof T,
}: GetFromToNumbersParams<T>): FromToNumbers => {
  const fromToNumbers: FromToNumbers = {
    from: null,
    to: null,
  };

  const createdAtMills = list.map((item) =>
    item[key] ? new Date(item[key] as string).getTime() : 0,
  );

  if (!createdAtMills) return fromToNumbers;

  const { min, max } = getMinMaxNumber(createdAtMills);

  fromToNumbers.from = formatTimestamp(min);
  fromToNumbers.to = formatTimestamp(max);

  return fromToNumbers;
};

export default getFromToNumbers;
