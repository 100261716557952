import React, { useEffect } from 'react';

import { RootState } from '../../../../types';
import { useDispatch, useSelector } from 'react-redux';

import useBrandsByIds from '../../../common/hooks/useBrandsByIds';
import usePendingOwners from '../../../common/hooks/usePendingOwners';
import {
  updateBrandsAction,
  updateUsersByRole,
} from '../../../store/globalData';
import useBrandsOwners from '../../../common/hooks/useBrandsOwners';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';

const BackofficeUserBrandsWrapper: React.FC = ({ children }) => {
  const { brandIds } = useSelector((state: RootState) => state.user);
  const { roles } = useSelector((root: RootState) => root.user);
  const { uid: backOfficeUserId } = useSelector((root: RootState) => root.user);

  const isSystemAdmin = checkIsSystemAdmin({ roles });

  const brandIdSelect = isSystemAdmin
    ? '*'
    : 'created_at, id, name, status, theme_id, updated_at';
  const { data: brands, isLoading } = useBrandsByIds({
    brandIds,
    select: brandIdSelect,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading || !brands?.length) return;

    dispatch(updateBrandsAction(brands));
  }, [brands, dispatch, isLoading]);

  const { data: users, isLoading: isLoadingOwners } = usePendingOwners({
    isSystemAdmin,
    backOfficeUserId,
  });

  const { data: brandOwners, isLoading: isLoadingBrandOwners } =
    useBrandsOwners({
      brandIds,
      isSystemAdmin,
      backOfficeUserId,
    });

  useEffect(() => {
    if (isLoadingOwners || !users?.length) return;

    dispatch(updateUsersByRole({ usersList: users }));
  }, [dispatch, isLoadingOwners, users]);

  useEffect(() => {
    if (isLoadingBrandOwners || !brandOwners?.length) return;

    dispatch(updateUsersByRole({ usersList: brandOwners }));
  }, [brandOwners, dispatch, isLoadingBrandOwners]);

  return <>{children}</>;
};

export default BackofficeUserBrandsWrapper;
