import { GridColumns } from '@mui/x-data-grid-premium';
import { BetData } from './useGetOutcomes';

const useBetColumns = (): GridColumns<BetData> => {
  return [
    {
      field: 'selection_name',
      headerName: 'Selection',
      flex: 1,
    },
    {
      field: 'side',
      headerName: 'Side',
      flex: 0.5,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.5,
    },
    {
      field: 'price',
      headerName: 'Price',
      flex: 0.5,
    },
    {
      field: 'size',
      headerName: 'Size',
      flex: 0.5,
    },
    {
      field: 'matched',
      headerName: 'Matched',
      flex: 0.5,
    },
    {
      field: 'unmatched',
      headerName: 'Unmatched',
      flex: 0.5,
    },
    {
      field: 'cancelled',
      headerName: 'Cancelled',
      flex: 0.5,
    },
    {
      field: 'lapsed',
      headerName: 'Lapsed',
      flex: 0.5,
    },
    {
      field: 'voided',
      headerName: 'Voided',
      flex: 0.5,
    },
  ];
};

export default useBetColumns;
