import { getFunctions, httpsCallable } from 'firebase/functions';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../../../common/constants';
import { useQuery, UseQueryResult } from 'react-query';
import useBrandId from '../../../common/hooks/useBrandId';
import { BackofficeUserRole } from '../../../../types';

export interface DateRangeRow {
  id: string;
  ref: string;
  playerId: string;
  isDisabled?: boolean;
  disabledBy?: BackofficeUserRole;
}

export interface ListDateRangesResponse {
  success: boolean;
  data: DateRangeRow[];
}

const useGetDateRanges = (
  playerId?: string,
): UseQueryResult<ListDateRangesResponse, unknown> => {
  const firebase = useFirebaseApp();
  const brandId = useBrandId();

  const functions = getFunctions(firebase, DEFAULT_REGION);
  const fetchDateRanges = httpsCallable(
    functions,
    'back-exchangeConfig-callFetchDateRangeConfig',
  );

  return useQuery({
    queryKey: ['dateRangeConfig', brandId, playerId],
    queryFn: async () => {
      const response = await fetchDateRanges({
        brandId,
        playerId,
      });
      return response.data as ListDateRangesResponse;
    },
  });
};

export default useGetDateRanges;
