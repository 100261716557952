import { BRAND_PENDING_ROLE } from './constants';
import mapPermissions from './permissions/mapPermissions';
import { ParsedPermissions } from './permissions/parsePermissions';

interface CheckIsBrandPendingByRoleClaimsParams {
  roles?: string[] | null;
}

interface ReturnParams {
  mappedRoles?: ParsedPermissions[];
  isBrandPending: boolean;
}

const checkIsBrandPendingByRoleClaims = ({
  roles,
}: CheckIsBrandPendingByRoleClaimsParams): ReturnParams => {
  if (!roles)
    return {
      isBrandPending: false,
    };

  const mappedRoles = mapPermissions(roles);

  if (!mappedRoles)
    return {
      isBrandPending: false,
      mappedRoles,
    };

  const isBrandPendingUser =
    mappedRoles?.length === 1
      ? roles.includes(`${BRAND_PENDING_ROLE}owner`)
      : false;

  return {
    isBrandPending: isBrandPendingUser,
    mappedRoles,
  };
};

export default checkIsBrandPendingByRoleClaims;
