import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) => ({
  autocomplete: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.grey[400]}!important`, // Need "!important" selector to override theme level specificity
    },
  },
  autocompleteInput: {
    minHeight: 30,
  },
}));
