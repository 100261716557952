import { BetSide } from '../../../../types/supabase';
import backGrossProfit from './backGrossProfit';
import layGrossProfit from './layGrossProfit';

interface BackGrossProfitParams {
  price: number;
  size: number;
  side: BetSide | string;
}

const grossProfit = (params: BackGrossProfitParams): number => {
  const { price, size, side } = params;

  if (side === 'back') return backGrossProfit({ price, size });

  return layGrossProfit({ size });
};

export default grossProfit;
