import {
  List,
  ListItem,
  Checkbox,
  ListItemText,
  Typography,
  Divider,
  Box,
} from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { FormValues } from './types';

const ShareApiKeyList: React.FC = () => {
  const { values, handleChange } = useFormikContext<FormValues>();

  return (
    <List
      dense
      sx={{
        width: 1,
        height: 1,
        maxWidth: 500,
        maxHeight: 300,
        overflowY: 'auto',
        bgcolor: 'background.paper',
      }}
    >
      {values.listItems &&
        values.listItems.length > 0 &&
        values.listItems.map((listItem, index) => {
          return (
            <Box key={listItem.id}>
              <ListItem
                key={listItem.id}
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={handleChange}
                    name={`listItems[${index}].isShared`}
                    checked={listItem.isShared}
                    inputProps={{
                      'aria-labelledby': listItem.id,
                    }}
                  />
                }
              >
                <ListItemText
                  id={listItem.id}
                  primary={
                    <Typography color="black" variant="h5">
                      {listItem.name}
                    </Typography>
                  }
                />
              </ListItem>
              <Divider />
            </Box>
          );
        })}
    </List>
  );
};

export default ShareApiKeyList;
