import { useQuery, UseQueryResult } from 'react-query';
import { ExchangeBet } from '../../../types';
import useSupabase from './useSupabase';

interface UseExchangeBetParams {
  betId: string;
  select?: string;
}

const useExchangeBet = <T extends ExchangeBet>(
  params: UseExchangeBetParams,
): UseQueryResult<T, Error> => {
  const { betId, select = '*' } = params;

  const supabase = useSupabase();

  const exchangeBetsRef = supabase.from('exchange_bets');

  const exchangeBetSelect = exchangeBetsRef
    .select<typeof select, T>(select)
    .eq('id', betId);

  const exchangeBetSingleRequest = exchangeBetSelect.single();

  const getExchangeBet = () => {
    return exchangeBetSingleRequest;
  };

  const queryResult = useQuery<T, Error>({
    queryKey: ['exchangeBet', betId],
    queryFn: async () => {
      const playerResponse = await getExchangeBet();

      const { data, error } = playerResponse;

      if (error) throw new Error(error.message);

      return data;
    },
    onError: (error) => error,
  });

  return queryResult;
};

export default useExchangeBet;
