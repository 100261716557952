import { defineMessages } from 'react-intl';

export default defineMessages({
  errorLogsMessage: {
    id: 'Log.LogOverviewPage.errorLogsMessage',
    defaultMessage: 'An error occurred while loading logs!',
  },
  systemLogs: {
    id: 'Log.LogOverviewPage.systemLogs',
    defaultMessage: 'System logs',
  },
  system: {
    id: 'Log.LogOverviewPage.system',
    defaultMessage: 'System',
  },
});
