import { defineMessages } from 'react-intl';

export default defineMessages({
  id: {
    id: 'Language.LanguageTranslationsList.id',
    defaultMessage: 'ID',
  },
  category: {
    id: 'Language.LanguageTranslationsList.category',
    defaultMessage: 'Category',
  },
  key: {
    id: 'Language.LanguageTranslationsList.key',
    defaultMessage: 'Key',
  },
  translation: {
    id: 'Language.LanguageTranslationsList.translation',
    defaultMessage: 'Translation',
  },
});
