import { defineMessages } from 'react-intl';

export default defineMessages({
  errorUploadingUsers: {
    id: 'Unknown.UserWrapper.errorUploadingUsers',
    defaultMessage: 'An error has occurred while getting users list.',
  },
  errorUploadUserLocation: {
    id: 'Unknown.UserWrapper.errorUploadUserLocation',
    defaultMessage: 'An error while upload user location.',
  },
  errorUploadingBrandOwners: {
    id: 'Unknown.UserWrapper.errorUploadingBrandOwners',
    defaultMessage: 'An error has occurred while getting brand owner list.',
  },
});
