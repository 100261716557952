import React from 'react';
import { FormattedMessage } from 'react-intl';
import formatDistance from 'date-fns/formatDistance';

import { Brand, BackOfficeUser, BrandStatus } from '../../../../types';
import DetailsInformationHeader from '../../Unknown/DetailsInformationHeader';
import FormattedData from '../../Unknown/FormattedData';

import useBrandStatuses from '../../../common/hooks/useBrandStatuses';

import messages from './messages';

type BrandDetailsHeaderProps = {
  brand: Brand;
  brandOwner: BackOfficeUser | undefined;
};

const BrandDetailsHeader: React.FC<BrandDetailsHeaderProps> = ({
  brand,
  brandOwner,
}) => {
  const brandStatuses = useBrandStatuses();

  const brandMainData = {
    mainText: brand.name,
    secondText: (
      <>
        <FormattedMessage {...messages.addedOn} />
        <FormattedData dateValue={brand.created_at} />
      </>
    ),
  };

  const brandOwnerData = {
    mainText: brandOwner?.username || '',
    secondText: (
      <FormattedMessage
        {...messages.email}
        values={{ email: brandOwner?.email }}
      />
    ),
  };

  const { color, label } = brandStatuses[brand.status as BrandStatus];
  const brandStatusData = {
    statusColor: color || '',
    statusText: label || '',
    secondText: (
      <>
        <FormattedMessage
          {...messages.lastUpdate}
          values={{
            time: formatDistance(Date.now(), new Date(brand?.updated_at || 0)),
          }}
        />
      </>
    ),
  };

  const data = {
    left: brandMainData,
    centerRight: brandOwnerData,
    right: brandStatusData,
  };

  return <DetailsInformationHeader data={data} />;
};

export default BrandDetailsHeader;
