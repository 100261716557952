import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import {
  DateRangePickerProps,
  LocalizationProvider,
  LocalizationProviderProps,
  DesktopDateRangePicker,
} from '@mui/x-date-pickers-pro';
import { Box, TextField, TextFieldProps } from '@mui/material';
import enGb from 'date-fns/locale/en-GB';
import useStyles from './useStyles';

type FieldProps = Omit<TextFieldProps, 'variant' | 'value'>;

export interface DateRangeProps {
  localizationProviderProps: Omit<LocalizationProviderProps, 'dateAdapter'>;
  dateRangePickerProps: Omit<DateRangePickerProps<Date, Date>, 'renderInput'>;
  startInputProps?: FieldProps;
  endInputProps?: FieldProps;
}

const DateRange: React.FC<DateRangeProps> = (props) => {
  const {
    localizationProviderProps,
    dateRangePickerProps,
    startInputProps,
    endInputProps,
  } = props;

  const classes = useStyles();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={enGb}
      {...localizationProviderProps}
    >
      <DesktopDateRangePicker
        {...dateRangePickerProps}
        renderInput={(startProps, endProps) => {
          return (
            <React.Fragment>
              <TextField
                {...startProps}
                {...startInputProps}
                size="small"
                inputProps={{
                  ...startProps.inputProps,
                  ...startInputProps?.inputProps,
                  className: classes.inputWidth,
                  readOnly: true,
                }}
              />
              <Box pr={2} />
              <TextField
                {...endProps}
                {...endInputProps}
                size="small"
                inputProps={{
                  ...endProps.inputProps,
                  ...endProps?.inputProps,
                  className: classes.inputWidth,
                  readOnly: true,
                }}
              />
            </React.Fragment>
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default DateRange;
