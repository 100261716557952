import {
  BackOfficeUser,
  Brand,
  ExtendedBackOfficeUser,
} from '../../../../types';
import {
  AGENTS_ROLES,
  BRAND_PENDING_ROLE,
  USER_ROLES,
} from '../../../common/constants';
import checkIsBrandPendingRole from '../../../common/checkIsBrandPendingRole';

type SortedUserList = BackOfficeUser & {
  brandId: string | null;
  brandName: string | null;
  role: string | null;
};

const sortedUserList = (
  brands: Brand[],
  selectedBrandId: string,
  users?: ExtendedBackOfficeUser[],
): SortedUserList[] => {
  if (!users) return [];

  const userList = users.map((user) => {
    const { mappedRoles, isBrandPending } = checkIsBrandPendingRole({
      roles: user?.roles,
    });

    const findSelectedRole = mappedRoles?.find(
      ({ brand_id }) => brand_id === selectedBrandId,
    );

    const userRole = USER_ROLES.find(
      ({ value }) => value === findSelectedRole?.role_id,
    );

    const isAgent = AGENTS_ROLES.includes(userRole?.value || '');

    if (isAgent) return null;

    const brand = brands.find(({ id }) => id === findSelectedRole?.brand_id);

    const brandId = findSelectedRole?.brand_id;

    const brandPendingId = isBrandPending ? BRAND_PENDING_ROLE : null;

    return {
      ...user,
      role: userRole?.label || null,
      brandId: brandId || brandPendingId,
      brandName: brand?.name || null,
    };
  });

  const filteredUserList = userList.filter((user) => user) as SortedUserList[];

  return filteredUserList;
};

export default sortedUserList;
