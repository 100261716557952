import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../types';
import useBrandId from './useBrandId';

const useCheckBrandIsSuspend = (): boolean => {
  const brandId = useBrandId();

  const { brands } = useSelector((root: RootState) => root.globalData);

  const brandIsSuspend = useMemo(() => {
    const currentBrand = brands.find((brand) => brand.id === brandId);

    return currentBrand?.status === 'suspend';
  }, [brandId, brands]);

  return brandIsSuspend;
};

export default useCheckBrandIsSuspend;
