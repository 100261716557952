import * as Yup from 'yup';
import { Field } from '../../../common/validationMessages';

const patternss = [
  /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/,
  /^([A-Za-z0-9](?:(?:[-A-Za-z0-9]){0,61}[A-Za-z0-9])?(?:\.[A-Za-z0-9](?:(?:[-A-Za-z0-9]){0,61}[A-Za-z0-9])?)*)(\.?)$/,
];

const validationSchema = Yup.object().shape({
  domain: Yup.string()
    .test('domain', 'Domain not valid', (value) => {
      if (value === undefined || value.trim() === '' || value === null)
        return false;
      return patternss.every((regex) => regex.test(value));
    })
    .trim(Field.required)
    .required(Field.required),
});

export default validationSchema;
