import { FC, useCallback, useContext, useMemo } from 'react';
import useGetMarketTypes from './useGetMarketTypes';
import {
  Box,
  GridColumns,
  GridEnrichedColDef,
  SimpleDataGridPremium,
  UIContext,
} from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';
import messages from './messages';
import IsDisabledCell from '../DateRangeSettings/IsDisabledCell';
import { GridCellEditCommitParams } from '@mui/x-data-grid';
import useBrandId from '../../../common/hooks/useBrandId';
import useUpdateMarketTypeConfig from './useUpdateMarketTypeConfig';

interface MarketTypeSettingsProps {
  playerId?: string;
}

const MarketTypeSettings: FC<MarketTypeSettingsProps> = ({ playerId }) => {
  const { isLoading, data: responseData } = useGetMarketTypes(playerId);
  const intl = useIntl();
  const { setAlert } = useContext(UIContext);
  const { data: marketTypes } = responseData || {};
  const brandId = useBrandId();
  const { mutateAsync: updateMarketTypeConfig, isLoading: isSubmitting } =
    useUpdateMarketTypeConfig();

  const defaultColumnParams: Partial<GridEnrichedColDef> = useMemo(() => {
    return {
      editable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      align: 'left',
      headerAlign: 'left',
      flex: 1,
    };
  }, []);

  const columns = useMemo<GridColumns>(() => {
    return [
      {
        ...defaultColumnParams,
        field: 'ref',
        headerName: intl.formatMessage(messages.marketType),
      },
      {
        ...defaultColumnParams,
        field: 'isDisabled',
        headerName: intl.formatMessage(messages.disable),
        renderCell: (params) => <IsDisabledCell {...params} />,
        renderEditCell: (params) => <IsDisabledCell {...params} />,
      },
    ];
  }, [defaultColumnParams, intl]);

  const onRowEdit = useCallback(
    async (params: GridCellEditCommitParams) => {
      const { id, field, value } = params;

      if (field === 'isDisabled') {
        const isDisabled = value as boolean;

        try {
          await updateMarketTypeConfig({
            brandId,
            playerId,
            isDisabled,
            ref: id.toString().toLowerCase(),
          });

          setAlert({
            severity: 'success',
            show: true,
            message: intl.formatMessage(messages.successUpdate),
          });
        } catch (error) {
          setAlert({
            severity: 'error',
            show: true,
            message: intl.formatMessage(messages.errorUpdate),
          });
        }
      }
    },
    [brandId, intl, playerId, setAlert, updateMarketTypeConfig],
  );

  return (
    <Box display="flex" flex={1}>
      <SimpleDataGridPremium
        columns={columns}
        disableSelectionOnClick
        rows={marketTypes || []}
        rowHeight={40}
        loading={isLoading || isSubmitting}
        autoHeight
        getRowId={(row) => row.ref}
        onCellEditCommit={onRowEdit}
      />
    </Box>
  );
};

export default MarketTypeSettings;
