import { useMemo } from 'react';
import { colors } from '../theme';
import messages from '../messages';
import { useIntl } from 'react-intl';
import { BrandApiKeyStatus } from '../../../types';

type StatusMapParams = {
  label: string;
  color: string;
};

const useApiKeysStatusMap = (): Record<BrandApiKeyStatus, StatusMapParams> => {
  const intl = useIntl();

  const brandApiKeyStatusMap: Record<BrandApiKeyStatus, StatusMapParams> =
    useMemo(() => {
      return {
        connecting: {
          label: intl.formatMessage(messages.connectingStatus),
          color: colors.grey300,
        },
        fail: {
          label: intl.formatMessage(messages.failStatus),
          color: colors.errorMain,
        },
        success: {
          label: intl.formatMessage(messages.successStatus),
          color: colors.successMain,
        },
      };
    }, [intl]);

  return brandApiKeyStatusMap;
};

export default useApiKeysStatusMap;
