import React, { useState } from 'react';
import { Box, Grid } from '@miyagami-com/lsx-ui-components';
import { useParams } from 'react-router';
import useBrandId from '../../../common/hooks/useBrandId';
import LogAccountList from '../../Log/LogAccountList';
import LoadingBox from '../../Unknown/LoadingBox';
import BackofficeUserAccountHeader from '../BackofficeUserAccountHeader';
import BackofficeUserAccountInformation from '../BackofficeUserAccountInformation';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types';
import useVerifyPermissions from '../../../common/hooks/useVerifyPermissions';
import { DialogType } from '../../Unknown/BackofficeUserForm';
import useCheckIsAccountPath from '../../../common/hooks/useCheckIsAccountPath';
import useExtendedBackofficeUser from '../../../common/hooks/useExtendedBackofficeUser';

type QueryParams = {
  userId: string;
  brandId?: string;
};

interface DefaultBackofficeUserAccountPageProps {
  type?: DialogType;
}

const DefaultBackofficeUserAccountPage: React.FC<DefaultBackofficeUserAccountPageProps> =
  (props) => {
    const { type = 'brandOwners' } = props;

    const params: QueryParams = useParams();

    const brandId = useBrandId();

    const [lastActivityTime, setLastActivityTime] = useState<number | null>(
      null,
    );

    const { uid } = useSelector((state: RootState) => state.user);

    const isCurrentUser = useCheckIsAccountPath();

    const userId = isCurrentUser ? uid : params.userId;

    const { data: user, status } = useExtendedBackofficeUser({ userId });

    const { grantedPermissions } = useVerifyPermissions([
      `brand/${brandId}/permission/update`,
    ]);

    const isUpdatePermissionGranted = grantedPermissions?.permission?.update;

    const isLoading = status === 'loading';

    if (isLoading) {
      return <LoadingBox />;
    }

    if (!user) return <LoadingBox />;

    return (
      <Box p={4}>
        <Grid container>
          <Grid item xs={12}>
            <BackofficeUserAccountHeader
              brandId={brandId || params.brandId || null}
              user={user}
              lastActivityTime={lastActivityTime}
              hasEditPermissions={!!isUpdatePermissionGranted}
            />
          </Grid>
          <Grid container spacing={4} pt={4}>
            <Grid item xs={12} md={6}>
              <BackofficeUserAccountInformation user={user} type={type} />
            </Grid>
            <Grid item xs={12} md={6}>
              <LogAccountList
                setLastActivityTime={setLastActivityTime}
                userId={user.id}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

export default DefaultBackofficeUserAccountPage;
