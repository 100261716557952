import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

interface Props {
  isExpanded?: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => {
  return {
    dataGridPadding: {
      padding: theme.spacing(3),
    },
    groupingHeader: {
      paddingLeft: `${theme.spacing(16)} !important`,
    },
    groupingName: {
      paddingLeft: theme.spacing(7),
    },
    expandMoreIcon: {
      transform: (props) => `rotateZ(${props.isExpanded ? 180 : 0}deg)`,
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
  };
});

export default useStyles;
