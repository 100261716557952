import useSupabase from './useSupabase';
import { useQuery } from 'react-query';
import { UserNote } from '../../../types/supabase';

interface UseUserNotesParams {
  userId: string;
  select?: string;
}

const useUserNotes = <T extends UserNote>(
  params: UseUserNotesParams,
): typeof queryResult => {
  const { select = '*', userId } = params;

  const supabase = useSupabase();

  const queryResult = useQuery<T[] | null, Error>(
    ['userNotes', userId],
    async () => {
      const response = await supabase
        .from('user_notes')
        .select<typeof select, T>(select)
        .match({
          user_id: userId,
        })
        .throwOnError();

      return response.data;
    },
  );

  return queryResult;
};

export default useUserNotes;
