import { makeStyles } from '@mui/styles';

import { borderWidth } from '../../../common/theme';

const useStyles = makeStyles(() => ({
  tabsBorder: {
    '&:first-child': {
      borderTopLeftRadius: borderWidth.l,
    },
    '&:last-child': {
      borderTopRightRadius: borderWidth.l,
    },
  },
}));

export default useStyles;
