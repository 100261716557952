import { getFunctions, httpsCallable } from 'firebase/functions';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../../../common/constants';
import { useQuery, UseQueryResult } from 'react-query';
import { ListMarketTypesResponse } from './interfaces';
import useBrandId from '../../../common/hooks/useBrandId';

const useGetMarketTypes = (
  playerId?: string,
): UseQueryResult<ListMarketTypesResponse, unknown> => {
  const firebase = useFirebaseApp();
  const brandId = useBrandId();

  const functions = getFunctions(firebase, DEFAULT_REGION);
  const fetchMarketTypes = httpsCallable(
    functions,
    'back-exchangeConfig-callFetchMarketTypes',
  );

  return useQuery({
    queryKey: ['marketTypes', brandId, playerId],
    queryFn: async () => {
      const response = await fetchMarketTypes({
        brandId,
        playerId,
      });
      return response.data as ListMarketTypesResponse;
    },
  });
};

export default useGetMarketTypes;
