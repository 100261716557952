import { FC, useCallback, useState } from 'react';
import { CollapseBox, Divider } from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';

import PlayerNoteList from '../PlayerNoteList';

import messages from './messages';
import UserNoteAddition, { OnAddNote } from '../../UserNote/UserNoteAddition';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../../common/constants';
import usePlayerNotes from '../../../common/hooks/usePlayerNotes';

interface UserNoteOverviewProps {
  brandId: string;
  userId: string;
  isDisabled: boolean;
}

const UserNoteOverview: FC<UserNoteOverviewProps> = (props) => {
  const { brandId, userId, isDisabled } = props;

  const intl = useIntl();

  const [page, setPage] = useState<number>(DEFAULT_PAGE);

  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);

  const { data, isLoading, refetch } = usePlayerNotes({
    playerId: userId,
    page,
    size: pageSize,
  });

  const onPageChange = useCallback(
    (newPage: number) => {
      setPage(newPage);
    },
    [setPage],
  );

  const onPageSizeChange = useCallback(
    (newPage: number) => {
      setPageSize(newPage);
    },
    [setPageSize],
  );

  const onAddNote: OnAddNote = async () => {
    await refetch();
  };

  const onDeleteNote = async () => {
    await refetch();
  };

  return (
    <CollapseBox label={intl.formatMessage(messages.notes)} defaultValue>
      <PlayerNoteList
        brandId={brandId}
        playerId={userId}
        notes={data || []}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        isLoading={isLoading}
        page={page}
        pageSize={pageSize}
        onDeleteNote={onDeleteNote}
      />
      <Divider />
      <UserNoteAddition
        isDisabled={isDisabled}
        brandId={brandId}
        userId={userId}
        onAddNote={onAddNote}
        userType="player"
      />
    </CollapseBox>
  );
};

export default UserNoteOverview;
