import React, { useState, useRef } from 'react';
import {
  InputAdornment,
  TextField,
  Box,
  Dialog,
} from '@miyagami-com/lsx-ui-components';
import { SketchPicker, ColorChangeHandler, ColorResult } from 'react-color';
import { TextFieldProps } from '@mui/material';
import useStyles from './useStyles';
import classNames from 'classnames';

export interface ColorPickerInputProps
  extends Omit<
    TextFieldProps,
    'onChange' | 'InputProps' | 'onClick' | 'value'
  > {
  value?: string;
  onChange?: ColorChangeHandler;
  onSwatchHover?(color: ColorResult, event: MouseEvent): void;
}

const ColorPickerInput: React.FC<ColorPickerInputProps> = ({
  defaultValue,
  value,
  onChange,
  onSwatchHover,
  ...otherProps
}) => {
  const [isShowPicker, setIsShowPicker] = useState<boolean>(false);

  const ref = useRef<HTMLInputElement>(null);

  const classes = useStyles({ color: value });

  const onClick = () => {
    if (otherProps.disabled) return;
    setIsShowPicker(true);
  };

  const onClose = () => {
    setIsShowPicker(false);
  };

  const circleClassName = classNames(
    classes.circleIconBorder,
    classes.circleColor,
  );

  return (
    <Box ref={ref} width={1}>
      <TextField
        onClick={onClick}
        value={value?.toLocaleUpperCase() || defaultValue}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box className={circleClassName} />
            </InputAdornment>
          ),
        }}
        {...otherProps}
      />
      <Dialog open={isShowPicker} onClose={onClose} hideBackdrop>
        <Box
          position="fixed"
          left={ref.current?.offsetLeft}
          top={ref.current?.offsetTop}
        >
          <SketchPicker
            disableAlpha
            onSwatchHover={onSwatchHover}
            color={value}
            onChange={onChange}
          />
        </Box>
      </Dialog>
    </Box>
  );
};

export default ColorPickerInput;
