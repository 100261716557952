import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Typography } from '@miyagami-com/lsx-ui-components';
import formatDistance from 'date-fns/formatDistance';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import {
  RootState,
  BackOfficeUserStatus,
  ExtendedBackOfficeUser,
} from '../../../../types';

import useCheckBrandIsSuspend from '../../../common/hooks/useCheckBrandIsSuspend';
import useUserStatuses from '../../../common/hooks/useUserStatuses';

import FormattedData from '../../Unknown/FormattedData';

import messages from './messages';
import useStyles from './useStyles';
import DetailHeader, { HeaderItem } from '../../Unknown/DetailHeader';
import useUserRoleLabel from '../../../common/hooks/useUserRoleLabel';
import StatusCell from '../../Unknown/StatusCell';
import useUserStats from '../../../common/hooks/useUserStats';
import useFormatCurrencyNumber from '../../../common/hooks/useFormatCurrencyNumber';
import checkIsBrandPendingRole from '../../../common/checkIsBrandPendingRole';

type BrandOwnerAccountHeaderProps = {
  user: ExtendedBackOfficeUser;
  lastActivityTime: number | null;
  hasEditPermissions?: boolean;
  brandId: string | null;
};

const BrandOwnerAccountHeader: React.FC<BrandOwnerAccountHeaderProps> = (
  props,
) => {
  const { user, lastActivityTime, hasEditPermissions, brandId } = props;

  const history = useHistory();

  const userStatuses = useUserStatuses();

  const isBrandSuspend = useCheckBrandIsSuspend();

  const intl = useIntl();

  const { isBrandPending } = checkIsBrandPendingRole({
    roles: user?.roles,
  });

  const classes = useStyles();

  const { brands } = useSelector((state: RootState) => state.globalData);

  const [activityTime, setActivityTime] = useState<string | undefined>();

  const { data: stats } = useUserStats({ userId: user.id });

  const balance = useFormatCurrencyNumber({ value: stats?.balance });

  const brand = useMemo(
    () => brands?.find((brandData) => brandData.id === brandId),
    [brands, brandId],
  );

  const roleLabels = useUserRoleLabel();

  const getStatusDetails = useCallback(
    (status: BackOfficeUserStatus) => {
      if (isBrandSuspend) {
        return userStatuses.suspend;
      }

      if (userStatuses[status]) {
        return userStatuses[status];
      }

      return { color: '', label: '' };
    },
    [userStatuses, isBrandSuspend],
  );

  const userNameEmail: HeaderItem = {
    id: 'userNameEmail',
    main: <Typography variant="h4">{user.username}</Typography>,
    secondary: (
      <Typography variant="h3">
        <FormattedMessage {...messages.email} values={{ email: user?.email }} />
      </Typography>
    ),
  };

  const brandName = brand?.name;

  const noBrandLabel = intl.formatMessage(messages.noBrand);

  const userBrand: HeaderItem = {
    id: 'userBrand',
    main: (
      <Typography variant="h4">
        {isBrandPending ? noBrandLabel : brandName}
      </Typography>
    ),
    secondary:
      brand && !isBrandPending ? (
        <Typography variant="h3">
          <FormattedMessage {...messages.addedOn} />
          <FormattedData dateValue={brand.created_at} />
        </Typography>
      ) : null,
  };

  const ownerLabel = roleLabels.owner;

  const onClickPermission = useCallback(() => {
    history.push(`/b/${brandId}/backoffice-users/${user.id}/permissions`);
  }, [brandId, history, user.id]);

  const userRoleBlock: HeaderItem = {
    id: 'userRoleBlock',
    main: <Typography variant="h4">{ownerLabel}</Typography>,
    secondary: hasEditPermissions ? (
      <Button
        color="inherit"
        className={classes.buttonEditPermissions}
        onClick={onClickPermission}
      >
        <FormattedMessage {...messages.editPermissions} />
      </Button>
    ) : null,
  };

  const { color, label } = useMemo(() => {
    return getStatusDetails(user.status);
  }, [getStatusDetails, user.status]);

  const userStats: HeaderItem = {
    id: 'userStats',
    main: <Typography variant="h4">{balance}</Typography>,
  };

  const userStatus: HeaderItem = {
    id: 'userStatus',
    main: (
      <StatusCell
        typographyProps={{ variant: 'h4' }}
        color={color}
        label={label}
      />
    ),
    secondary: activityTime ? (
      <Typography variant="h3">
        <FormattedMessage
          {...messages.lastActivity}
          values={{ time: activityTime }}
        />
      </Typography>
    ) : null,
  };

  const items = [
    userNameEmail,
    userBrand,
    userRoleBlock,
    userStats,
    userStatus,
  ];

  useEffect(() => {
    if (lastActivityTime) {
      setActivityTime(formatDistance(Date.now(), lastActivityTime));
    }
  }, [lastActivityTime, setActivityTime]);

  return <DetailHeader items={items} />;
};

export default BrandOwnerAccountHeader;
