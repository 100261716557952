import React from 'react';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Grid,
  Box,
  TextField,
  Divider,
  Typography,
  Autocomplete,
  LoadingButton,
} from '@miyagami-com/lsx-ui-components';
import ReactCountryFlag from 'react-country-flag';
import messages from './messages';
import useStyles from './useStyles';

export type LanguageAdditionFormValues = {
  id: string;
  name: string;
  countryCode: string;
};

interface LanguageAdditionFormProps {
  languageOptions: LanguageAdditionFormValues[];
  onSubmit: (values: LanguageAdditionFormValues) => Promise<void>;
}

const initialValues = {
  id: '',
  name: '',
  countryCode: '',
};

const LanguageAdditionForm: React.FC<LanguageAdditionFormProps> = ({
  languageOptions,
  onSubmit,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const { values, isSubmitting, setValues, handleSubmit } = useFormik({
    initialValues,
    onSubmit: async (formValues, { resetForm }) => {
      await onSubmit(formValues);
      resetForm();
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid pt={4} px={4} pb={6} container rowSpacing={5}>
        <Grid item xs={12}>
          <Typography variant="h4">
            <FormattedMessage {...messages.language} />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Autocomplete
            id="id"
            onChange={(e, option) => setValues(option || initialValues)}
            classes={{ input: classes.autocompleteInput }}
            fullWidth
            options={languageOptions}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage(messages.name)}
                classes={{ root: classes.autocomplete }}
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <TextField
            InputProps={{
              startAdornment: !!values.countryCode && (
                <ReactCountryFlag
                  style={{ width: 30, height: 22 }}
                  svg
                  countryCode={values.countryCode}
                />
              ),
            }}
            label={intl.formatMessage(messages.flag)}
            variant="outlined"
            fullWidth
            disabled
          />
        </Grid>
      </Grid>
      <Divider />
      <Box p={3}>
        <LoadingButton
          disabled={isSubmitting}
          variant="contained"
          color="primary"
          type="submit"
          loading={isSubmitting}
        >
          <FormattedMessage {...messages.save} />
        </LoadingButton>
      </Box>
    </form>
  );
};

export default LanguageAdditionForm;
