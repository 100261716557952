import {
  Box,
  Button,
  MenuItem,
  TextField,
} from '@miyagami-com/lsx-ui-components';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  sportTypes: string[];
  selectedSportType: string | null;
  onChangeSportType: (sportType: string | null) => void | Promise<void>;
};

const ExchangeBetSportTypeFilter: FC<Props> = ({
  selectedSportType,
  sportTypes,
  onChangeSportType,
}) => {
  const intl = useIntl();
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeSportType(event.target.value);
  };
  const renderSportType = (sportType: string) => (
    <MenuItem key={sportType} value={sportType}>
      {sportType}
    </MenuItem>
  );

  return (
    <Box display="flex" alignItems="center">
      <TextField
        id="outlined-select-sport-type"
        select
        label={intl.formatMessage(messages.sportTypes)}
        value={selectedSportType}
        onChange={onChange}
        variant="outlined"
        size="small"
        sx={{
          minWidth: '120px',
          '& .MuiInputLabel-root': {
            fontSize: '0.75rem',
            transform: 'translate(14px, 6px) scale(1)',
          },
          '& .MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
          },
        }}
      >
        {sportTypes.map(renderSportType)}
      </TextField>

      {selectedSportType && (
        <Button variant="text" onClick={() => onChangeSportType(null)}>
          <CloseIcon color="primary" />
        </Button>
      )}
    </Box>
  );
};

export default ExchangeBetSportTypeFilter;
