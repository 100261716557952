import { defineMessages } from 'react-intl';

export default defineMessages({
  successUpdatedWidget: {
    id: 'Widget.WidgetDetails.successUpdatedWidget',
    defaultMessage: 'Widget was successfully updated.',
  },
  errorUpdatedWidget: {
    id: 'Widget.WidgetDetails.errorUpdatedWidget',
    defaultMessage: 'An error while updating widget.',
  },
  widgetDetails: {
    id: 'Widget.WidgetDetails.widgetDetails',
    defaultMessage: 'Widget details',
  },
  widget: {
    id: 'Widget.WidgetDetails.widget',
    defaultMessage: 'Widget',
  },
  discount: {
    id: 'Widget.WidgetDetails.discount',
    defaultMessage: '{ value } % discount',
  },
  winMoreMoneyNow: {
    id: 'Widget.WidgetDetails.winMoreMoneyNow',
    defaultMessage: 'Win more money now!',
  },
  description: {
    id: 'Widget.WidgetDetails.description',
    defaultMessage: 'Description',
  },
  xpage888: {
    id: 'Widget.WidgetDetails.xpage888',
    defaultMessage: 'Xpage888',
  },
  title: {
    id: 'Widget.WidgetDetails.title',
    defaultMessage: 'Title',
  },
  link: {
    id: 'Widget.WidgetDetails.link',
    defaultMessage: 'Link',
  },
  httpsLink: {
    id: 'Widget.WidgetDetails.httpsLink',
    defaultMessage: 'https://xbet888.com',
  },
  image: {
    id: 'Widget.WidgetDetails.image',
    defaultMessage: 'Image',
  },
  dragAnImage: {
    id: 'Widget.WidgetDetails.dragAnImage',
    defaultMessage: 'Drag an image to upload',
  },
  save: {
    id: 'Widget.WidgetDetails.save',
    defaultMessage: 'Save',
  },
  publish: {
    id: 'Widget.WidgetDetails.publish',
    defaultMessage: 'Publish',
  },
  unpublish: {
    id: 'Widget.WidgetDetails.unpublish',
    defaultMessage: 'Unpublish',
  },
  delete: {
    id: 'Widget.WidgetDetails.delete',
    defaultMessage: 'Delete',
  },
  brand: {
    id: 'Widget.WidgetDetails.brand',
    defaultMessage: 'Brand',
  },
  textSuccessfullyDeletion: {
    id: 'Widget.WidgetDetails.textSuccessfullyDeletion',
    defaultMessage: 'Widget was successfully deleted!',
  },
  textSuccessfullyCreated: {
    id: 'Widget.WidgetDetails.textSuccessfullyCreated',
    defaultMessage: 'Widget was successfully created!',
  },
  errorPublish: {
    id: 'Widget.WidgetDetails.errorPublish',
    defaultMessage: 'Publishing widget has been with error.',
  },
  successPublish: {
    id: 'Widget.WidgetDetails.successPublish',
    defaultMessage: 'Widget has successfully published.',
  },
  successArchive: {
    id: 'Widget.WidgetDetails.successArchive',
    defaultMessage: 'Widget has successfully archived.',
  },
});
