import { UseMutationResult, useMutation } from 'react-query';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../constants';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface UseVerifyBrandDomainConfigParams {
  domainId: string;
  brandId: string;
  onSuccess?: (data: boolean) => void;
  onError?: (err: unknown) => void;
}

const useVerifyBrandDomainConfig = (
  params: UseVerifyBrandDomainConfigParams,
): UseMutationResult<boolean, unknown, void, unknown> => {
  const { domainId, brandId, onSuccess, onError } = params;

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const mutation = useMutation(
    async () => {
      const verifyDomainConfig = httpsCallable(
        functions,
        'back-brandDomain-verifyBrandDomainConfig',
      );

      const verificationConfig = await verifyDomainConfig({
        domainId,
        brandId,
      });

      return verificationConfig.data as boolean;
    },
    {
      onSuccess,
      onError,
    },
  );

  return mutation;
};

export default useVerifyBrandDomainConfig;
