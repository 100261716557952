import { FC } from 'react';
import { Paper, Box } from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';

import DefaultLayout from '../../Unknown/DefaultLayout';
import ExchangeBetOverview from '../ExchangeBetOverview';

import messages from './messages';

const ExchangeBetPage: FC = () => {
  const intl = useIntl();

  const path = [
    { label: intl.formatMessage(messages.exchange) },
    { label: intl.formatMessage(messages.bettingOverview) },
  ];

  return (
    <DefaultLayout path={path}>
      <Box p={4}>
        <Paper>
          <ExchangeBetOverview pageType="brand" />
        </Paper>
      </Box>
    </DefaultLayout>
  );
};

export default ExchangeBetPage;
