import { defineMessages } from 'react-intl';

export default defineMessages({
  transferCredit: {
    id: 'Transaction.TransactionCreation.transferCredit',
    defaultMessage: 'Transfer credit',
  },
  withdrawCredit: {
    id: 'Transaction.TransactionCreation.withdrawCredit',
    defaultMessage: 'Withdraw credit',
  },
  successMessage: {
    id: 'Transaction.TransactionCreation.successMessage',
    defaultMessage: 'Transfer was successfully conducted!',
  },
  errorMessage: {
    id: 'Transaction.TransactionCreation.errorMessage',
    defaultMessage: 'An error occurred while trying to make a transfer!',
  },
  errorBalance: {
    id: 'Transaction.TransactionCreation.errorBalance',
    defaultMessage: 'Insufficient balance!',
  },
});
