import { defineMessages } from 'react-intl';

export default defineMessages({
  agentOverview: {
    id: 'Agent.AgentOverviewPage.agentOverview',
    defaultMessage: 'Agent overview',
  },
  playersAndAgents: {
    id: 'Agent.AgentOverviewPage.playersAndAgents',
    defaultMessage: 'Players & Agents',
  },
  errorUploadingUsers: {
    id: 'Agent.AgentOverviewPage.errorUploadingUsers',
    defaultMessage: 'An error has occurred while getting agent list.',
  },
  notFoundUsers: {
    id: 'Agent.AgentOverviewPage.notFoundUsers',
    defaultMessage: 'Not found users.',
  },
});
