import { useCallback } from 'react';
import { ExchangeBetCurrencyParams } from '../../../types';
import useSupabase from './useSupabase';
import { UseQueryResult, useQuery } from 'react-query';

interface UseCurrencyParams {
  brandId: string;
  playerId?: string;
  select?: string;
}

const createOrString = (params: UseCurrencyParams) => {
  const { brandId, playerId } = params;

  const conditions = ['type.eq.system'];

  if (brandId) {
    const brandString = `and(type.eq.brand,brand_id.eq.${brandId})`;
    conditions.push(brandString);
  }

  if (playerId) {
    const playerString = `and(type.eq.player,player_id.eq.${playerId})`;
    conditions.push(playerString);
  }

  return conditions.join(',');
};

const useCurrencyParams = (
  params: UseCurrencyParams,
): UseQueryResult<ExchangeBetCurrencyParams[], unknown> => {
  const supabase = useSupabase();

  const { brandId, playerId, select = '*' } = params;

  const getCurrencyParams = useCallback(async () => {
    const orString = createOrString(params);

    const result = await supabase
      .from('exchange_currency_params')
      .select<typeof select, ExchangeBetCurrencyParams>(select)
      .or(orString)
      .throwOnError();

    return result.data || [];
  }, [params, select, supabase]);

  const queryResult = useQuery(
    ['currencyParams', playerId, brandId],
    async () => {
      return getCurrencyParams();
    },
  );

  return queryResult;
};

export default useCurrencyParams;
