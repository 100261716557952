import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { FC } from 'react';

const ExchangeBetExportToolbar: FC<unknown> = (props) => {
  return (
    <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
      <GridToolbarExport
        sx={{
          color: 'common.black',
          border: '1px solid #000',
          '&.Mui-disabled': { border: '1px solid #ccc' },
        }}
        printOptions={{ disableToolbarButton: true }}
        {...props}
      />
    </GridToolbarContainer>
  );
};

export default ExchangeBetExportToolbar;
