import { useQuery } from 'react-query';
import { UserLimit } from '../../../types/supabase';
import useSupabase from './useSupabase';

interface UseUserLimitParams {
  userId: string;
  select?: string;
}

const useUserLimit = <T extends UserLimit>(
  params: UseUserLimitParams,
): typeof queryResult => {
  const { userId, select = '*' } = params;

  const supabase = useSupabase();

  const queryResult = useQuery<T[] | null, Error>(
    ['userLimit', userId],
    async () => {
      const response = await supabase
        .from('user_limits')
        .select<typeof select, T>(select)
        .eq('id', userId);

      return response.data;
    },
  );

  return queryResult;
};

export default useUserLimit;
