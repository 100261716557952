import usePlayerPlatformApiClient from './usePlayerPlatformApiClient';
import { MarketFilter, RootNode } from '../../../types';
import { UseQueryResult, useQuery } from 'react-query';
import useBrandId from './useBrandId';
import qs from 'qs';

export type NavigationNodeType =
  | 'root'
  | 'eventType'
  | 'competition'
  | 'event'
  | 'raceMarket'
  | 'group';

export interface UseNavigationNodeParams {
  type: NavigationNodeType;
  filter?: MarketFilter;
  brandId: string;
  playerId?: string;
  key: string;
}

export type ExchangeNavigationNode = {
  node: RootNode | null;
  error: string | null;
};

const useNavigationNode = (
  params: UseNavigationNodeParams | null,
  isEnabled?: boolean,
): UseQueryResult<RootNode | null, unknown> => {
  const brandId = useBrandId();

  const playerPlatformApiClient = usePlayerPlatformApiClient(brandId);

  return useQuery(
    ['navigationNode', brandId, params?.playerId, params?.key],
    async () => {
      if (!params) return null;

      const { brandId: id, ...otherParams } = params;

      const query = qs.stringify({
        ...otherParams,
        isClientRequest: false,
        playerId: params?.playerId,
      });

      const response =
        await playerPlatformApiClient.get<ExchangeNavigationNode>(
          `brand/${brandId}/exchange/navigation/exchangeNavigationNode?${query}`,
        );

      const { data } = response;

      return data.node;
    },
    { enabled: isEnabled },
  );
};

export default useNavigationNode;
