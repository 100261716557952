import { BackOfficeUser, ExtendedBackOfficeUser } from '../../../../types';
import { AGENTS_ROLES } from '../../../common/constants';

interface NormalizeAgentParams {
  user: ExtendedBackOfficeUser;
  brandId?: string | null;
}

export type NormalizedAgent = BackOfficeUser & {
  brandId?: string | null | undefined;
  role?: string | null;
};

const normalizeAgent = ({
  user,
  brandId,
}: NormalizeAgentParams): NormalizedAgent | null => {
  const roles = user.roles;

  const selectedRole = roles?.find(
    ({ brand_id: userBrandId }) => userBrandId === brandId,
  );

  const isAgent = AGENTS_ROLES.includes(selectedRole?.role_id || '');

  if (!isAgent) return null;

  const normalizedAgent: NormalizedAgent = {
    ...user,
    brandId: selectedRole?.brand_id,
    role: selectedRole?.role_id,
  };

  return normalizedAgent;
};

export default normalizeAgent;
