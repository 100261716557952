import { ExtendedBackOfficeUser, GlobalUsers } from '../../types';

interface GetAllBrandUsersParams {
  brandId: string;
  users: GlobalUsers;
}

const getAllBrandUsers = ({
  users,
  brandId,
}: GetAllBrandUsersParams): ExtendedBackOfficeUser[] => {
  const brandUsers = users.byBrandId[brandId];

  const agents = brandUsers?.agents || [];

  const backofficeUsers = brandUsers?.backofficeUsers || [];

  const allUsers = [...agents, ...backofficeUsers];

  return allUsers;
};

export default getAllBrandUsers;
