import React, { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';

import {
  Box,
  ModalCenter,
  UIContext,
  CircularProgress,
} from '@miyagami-com/lsx-ui-components';

import ClosableBox from '../../Unknown/ClosableBox';

import BrandDomainConfigConflicts from '../BrandDomainConfigConflicts';
import BrandDomainConfigDNSList from '../BrandDomainConfigDNSList';

import messages from './messages';
import useBrandDomainDNS from '../../../common/hooks/useBrandDomainDNS';
import useBrandDomainDNSConflicts from '../../../common/hooks/useBrandDomainDNSConflicts';
import useVerifyBrandDomainConfig from '../../../common/hooks/useVerifyBrandDomainConfig';
import useBrandDomainConfig from '../../../common/hooks/useBrandDomainConfig';
import { useQueryClient } from 'react-query';

type BrandDomainConfigProps = {
  brandId: string;
  domainId: string;
  domainName: string;
  handleClose: () => void;
};

const BrandDomainConfig: React.FC<BrandDomainConfigProps> = (props) => {
  const { brandId, domainId, domainName, handleClose } = props;

  const intl = useIntl();
  const { setAlert } = useContext(UIContext);

  const queryClient = useQueryClient();

  const brandDomainConfig = useBrandDomainConfig();

  const { data: brandDomainConflicts, isLoading: isLoadingDNSConflicts } =
    useBrandDomainDNSConflicts({
      domain: domainName,
      brandId,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        setAlert({
          show: true,
          severity: 'error',
          message: error?.message ? error.message : 'Unknown error',
        });
      },
    });

  const { data: selectedDomainDNSList, isLoading: isLoadingDNSList } =
    useBrandDomainDNS({
      domain: domainName,
      brandId,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        setAlert({
          show: true,
          severity: 'error',
          message: error?.message ? error.message : 'Unknown error',
        });
      },
    });

  const isLoading = isLoadingDNSConflicts || isLoadingDNSList;

  const handleCloseModal = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const { isLoading: isVerificationLoading, mutate: handleVerifyConfig } =
    useVerifyBrandDomainConfig({
      brandId,
      domainId,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        setAlert({
          show: true,
          severity: 'error',
          message: error?.message ? error.message : 'Unknown error',
        });
      },
      onSuccess: async (isMisconfigured) => {
        queryClient.refetchQueries([['brandDomains', brandId]]);
        if (!isMisconfigured) {
          setAlert({
            show: true,
            severity: 'success',
            message: intl.formatMessage(messages.verifyBrandDomainSuccess),
          });

          handleCloseModal();
          return;
        }

        const DNSConflicts = await brandDomainConfig({
          domain: domainName,
          brandId,
        });

        const isHaveConflicts = !!DNSConflicts.length;

        setAlert({
          show: true,
          severity: !isHaveConflicts ? 'info' : 'error',
          message: intl.formatMessage(
            isHaveConflicts
              ? messages.verifyBrandDomainConflicts
              : messages.verifyBrandDomainFailed,
          ),
        });
        handleCloseModal();
      },
    });

  return (
    <ModalCenter open={!!domainId} onClose={handleCloseModal}>
      <ClosableBox
        label={intl.formatMessage(messages.domainConfig)}
        onClose={handleCloseModal}
      >
        <Box minWidth={300} width={480} p={4}>
          {isLoading ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              {brandDomainConflicts?.length ? (
                <BrandDomainConfigConflicts
                  brandDomainConflicts={brandDomainConflicts}
                  getBrandDomainConfig={brandDomainConfig}
                  isVerificationLoading={isVerificationLoading}
                  domainName={domainName}
                  brandId={brandId}
                />
              ) : (
                <BrandDomainConfigDNSList
                  selectedDomainDNSList={selectedDomainDNSList}
                  handleVerifyConfig={handleVerifyConfig}
                  isVerificationLoading={isVerificationLoading}
                  brandDomainConflicts={brandDomainConflicts}
                />
              )}
            </>
          )}
        </Box>
      </ClosableBox>
    </ModalCenter>
  );
};

export default BrandDomainConfig;
