import { defineMessages } from 'react-intl';

export default defineMessages({
  editAction: {
    id: 'BackofficeUser.BackofficeUserList.editAction',
    defaultMessage: 'Edit',
  },
  deleteAction: {
    id: 'BackofficeUser.BackofficeUserList.deleteAction',
    defaultMessage: 'Delete',
  },
  id: {
    id: 'BackofficeUser.BackofficeUserList.id',
    defaultMessage: 'ID',
  },
  name: {
    id: 'BackofficeUser.BackofficeUserList.name',
    defaultMessage: 'First name',
  },
  surname: {
    id: 'BackofficeUser.BackofficeUserList.surname',
    defaultMessage: 'Surname',
  },
  email: {
    id: 'BackofficeUser.BackofficeUserList.email',
    defaultMessage: 'E-mail',
  },
  phoneNumber: {
    id: 'BackofficeUser.BackofficeUserList.phoneNumber',
    defaultMessage: 'Telephone',
  },
  role: {
    id: 'BackofficeUser.BackofficeUserList.role',
    defaultMessage: 'Role',
  },
  status: {
    id: 'BackofficeUser.BackofficeUserList.status',
    defaultMessage: 'Status',
  },
  brandName: {
    id: 'BackofficeUser.BackofficeUserList.brandName',
    defaultMessage: 'Brand',
  },
  actions: {
    id: 'BackofficeUser.BackofficeUserList.actions',
    defaultMessage: 'Actions',
  },
  successfullyDelete: {
    id: 'BackofficeUser.BackofficeUsersPage.successfullyDelete',
    defaultMessage: 'Successfully deleting user.',
  },
  errorDeleteUser: {
    id: 'User.UserList.errorDeleteUser',
    defaultMessage: 'An error has occurred while deleting user!',
  },
  editUser: {
    id: 'BackofficeUser.BackofficeUserList.editUser',
    defaultMessage: 'Edit',
  },
  deleteUser: {
    id: 'BackofficeUser.BackofficeUserList.deleteUser',
    defaultMessage: 'Delete',
  },
});
