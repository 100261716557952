import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { borderWidth } from '../../../common/theme';

const useStyles = makeStyles((theme: Theme) => ({
  parent: {
    position: 'relative',
  },
  tabsBorder: {
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
      paddingRight: theme.spacing(1.8),
      paddingLeft: theme.spacing(1.8),
    },
    '&:first-child': {
      borderTopLeftRadius: borderWidth.l,
    },
    '&:last-child': {
      borderTopRightRadius: borderWidth.l,
    },
  },
}));

export default useStyles;
