import { defineMessages } from 'react-intl';

export default defineMessages({
  lastActivity: {
    id: 'BackofficeUser.BackofficeUserAccountHeader.lastActivity',
    defaultMessage: 'Last activity: {time} ago',
  },
  email: {
    id: 'BackofficeUser.BackofficeUserAccountHeader.email',
    defaultMessage: 'E-mail: {email}',
  },
  editPermissions: {
    id: 'BackofficeUser.BackofficeUserAccountHeader.editPermissions',
    defaultMessage: 'Edit permissions',
  },
  lastUpdate: {
    id: 'BackofficeUser.BackofficeUserAccountHeader.lastUpdate',
    defaultMessage: 'Last update: ',
  },
  addedOn: {
    id: 'BackofficeUser.BackofficeUserAccountHeader.addedOn',
    defaultMessage: 'Added on ',
  },
  noBrand: {
    id: 'BackofficeUser.BackofficeUserAccountHeader.noBrand',
    defaultMessage: 'No brand',
  },
  system: {
    id: 'BackofficeUser.BackofficeUserAccountHeader.system',
    defaultMessage: 'System',
  },
});
