import { DuplicatePlayerData } from './getDuplicatePlayers';

const getDuplicatesByPlayerId = ({
  playersList,
  playerId,
  IPAddress,
}: {
  playersList: DuplicatePlayerData[];
  playerId: string;
  IPAddress: string;
}): DuplicatePlayerData[] => {
  return playersList
    .filter(
      (player) =>
        player.player_id !== playerId && player.log_ip_address === IPAddress,
    )
    .map((player) => {
      const path = [playerId, player.player_id];
      return {
        ...player,
        id: path.join('/'),
        path,
      };
    });
};

const getNormalizedDuplicatePlayersList = ({
  duplicatePlayersData,
  brandId,
}: {
  duplicatePlayersData: DuplicatePlayerData[];
  brandId: string;
}): DuplicatePlayerData[] => {
  return duplicatePlayersData.reduce(
    (acc, currentPlayer, _, rawDuplicatePlayersData) => {
      const duplicatePlayers = getDuplicatesByPlayerId({
        playersList: rawDuplicatePlayersData,
        playerId: currentPlayer.player_id,
        IPAddress: currentPlayer.log_ip_address,
      });

      const normalizedCurrentPlayer = {
        ...currentPlayer,
        path: [currentPlayer.player_id],
        id: currentPlayer.player_id,
      };

      if (currentPlayer.player_brand_id === brandId) {
        return [...acc, normalizedCurrentPlayer, ...duplicatePlayers];
      }

      return acc;
    },
    [] as DuplicatePlayerData[],
  );
};

export default getNormalizedDuplicatePlayersList;
