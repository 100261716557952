import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const drawerWidth = 214;

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    height: 'calc(100% - 50px)',
    backgroundColor: theme.palette.grey[800],
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 68px)',
    },
  },
  drawerOpen: {
    zIndex: theme.zIndex.mobileStepper - 1,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
  drawerClose: {
    zIndex: theme.zIndex.mobileStepper - 1,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
  drawerMobile: {
    position: 'absolute',
  },
  drawerPaperMobile: {
    position: 'relative',
    height: '100%',
  },
  whiteColor: {
    color: theme.palette.common.white,
  },
  onHoverBgColorGrey600: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.grey[600],
    },
  },
  onHoverBgColorGrey700: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.grey[700],
    },
  },
  bgColorGrey600: {
    backgroundColor: theme.palette.grey[600],
  },
  bgColorGrey700: {
    backgroundColor: theme.palette.grey[700],
  },
  bgColorGrey800: {
    backgroundColor: theme.palette.grey[800],
  },
  accordion: {
    '&.Mui-expanded': {
      margin: theme.spacing(0),
    },
  },
}));

export default useStyles;
