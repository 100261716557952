import React, { useMemo } from 'react';
import useSharedApiKeyBrandIds from '../../../common/hooks/useSharedApiKeyBrandIds';
import {
  Box,
  Modal,
  Paper,
  Divider,
  LoadingButton,
} from '@miyagami-com/lsx-ui-components';
import { FieldArray, Form, Formik } from 'formik';
import normalizeBrandList from './normalizeBrandList';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types';
import LoadingBox from '../../Unknown/LoadingBox';
import { FormValues } from './types';
import ClosableBox from '../../Unknown/ClosableBox';
import messages from './messages';
import useOnSubmit from './useOnSubmit';
import { useIntl } from 'react-intl';
import ShareApiKeyList from './ShareApiKeyList';

interface ShareApiKeyModalProps {
  onCloseModal: () => void;
  apiKeyId: string | null;
  isOpen: boolean;
  brandId: string;
}

const ShareApiKeyModal: React.FC<ShareApiKeyModalProps> = (props) => {
  const { apiKeyId, onCloseModal, isOpen, brandId } = props;

  const { data: brandApiKeys, isLoading } = useSharedApiKeyBrandIds({
    apiKeyId,
  });

  const { brands: initialBrands } = useSelector(
    (root: RootState) => root.globalData,
  );

  const brands = useMemo(() => {
    return initialBrands.filter((brand) => brand.id !== brandId);
  }, [brandId, initialBrands]);

  const listItems = useMemo(() => {
    return normalizeBrandList({ brands, brandApiKeys });
  }, [brandApiKeys, brands]);

  const initialValues: FormValues = useMemo(() => {
    return {
      listItems,
    };
  }, [listItems]);

  const intl = useIntl();

  const onSubmit = useOnSubmit({ listItems, apiKeyId });

  if (!apiKeyId) return null;

  if (isLoading) return <LoadingBox />;

  return (
    <Modal open={isOpen} onClose={onCloseModal}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minWidth={500}
        minHeight={500}
      >
        <ClosableBox
          onClose={onCloseModal}
          label={intl.formatMessage(messages.shareApiKeys)}
        >
          <Paper>
            <Box width={1} height={1} minWidth={300}>
              <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {(params) => {
                  const { isSubmitting } = params;

                  return (
                    <Form>
                      <FieldArray
                        name="listItems"
                        render={() => {
                          return (
                            <Box>
                              <ShareApiKeyList />
                              <Divider />
                              <Box py={2} px={2}>
                                <LoadingButton
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  loading={isSubmitting}
                                >
                                  {intl.formatMessage(messages.save)}
                                </LoadingButton>
                              </Box>
                            </Box>
                          );
                        }}
                      />
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </Paper>
        </ClosableBox>
      </Box>
    </Modal>
  );
};

export default ShareApiKeyModal;
