import { defineMessages } from 'react-intl';

export default defineMessages({
  personalInformation: {
    id: 'Player.PlayerPersonalInformation.personalInformation',
    defaultMessage: 'Personal information',
  },
  successMessageForUpdating: {
    id: 'Player.PlayerPersonalInformation.successMessageForUpdating',
    defaultMessage: 'Player successfully updated!',
  },
  errorMessageForUpdating: {
    id: 'Player.PlayerPersonalInformation.errorMessageForUpdating',
    defaultMessage: 'An error has occurred while updating player!',
  },
  successMessageForCreating: {
    id: 'Player.PlayerPersonalInformation.successMessageForCreating',
    defaultMessage: 'Player successfully created!',
  },
  errorMessageForCreating: {
    id: 'Player.PlayerPersonalInformation.errorMessageForCreating',
    defaultMessage: 'An error has occurred while creating player!',
  },
});
