import React from 'react';
import { Grid } from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

import DefaultLayout from '../../Unknown/DefaultLayout';

import ThemeLogo from '../ThemeLogo';
import ThemeSettings from '../ThemeSettings';

import useVerifyPermissions from '../../../common/hooks/useVerifyPermissions';
import messages from './messages';

type QueryParams = {
  brandId: string;
};

const ThemeSettingsPage: React.FC = () => {
  const intl = useIntl();

  const { brandId }: QueryParams = useParams();

  const { grantedPermissions, isSuspended, isBrandSuspended } =
    useVerifyPermissions([`brand/${brandId}/theme/update`]);

  const isUpdatePermissionGranted = grantedPermissions?.theme?.update;

  const isDisabled =
    !isUpdatePermissionGranted || isSuspended || isBrandSuspended;

  const path = [
    { label: intl.formatMessage(messages.cms) },
    { label: intl.formatMessage(messages.themeSettings) },
  ];

  return (
    <DefaultLayout path={path}>
      <Grid container spacing={4} padding={4}>
        <Grid item xs={12} md={6}>
          <ThemeSettings isDisabled={isDisabled} brandId={brandId} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ThemeLogo isDisabled={isDisabled} brandId={brandId} />
        </Grid>
      </Grid>
    </DefaultLayout>
  );
};

export default ThemeSettingsPage;
