import { useMemo } from 'react';
import { getReportLevelByPermission } from '../ProfitLossReportPage/getReportUrlByPermission';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types';

interface UseReportLevelByPermissionsParams {
  brandId: string;
}

const useReportLevelByPermissions = (
  params: UseReportLevelByPermissionsParams,
): typeof returnParams => {
  const { brandId } = params;

  const user = useSelector((root: RootState) => root.user);

  const reportLevel = useMemo(() => {
    return user.permissionMap[brandId]
      ? getReportLevelByPermission({ user, brandId })
      : null;
  }, [brandId, user]);

  const returnParams = { reportLevel, user };

  return returnParams;
};

export default useReportLevelByPermissions;
