import {
  Box,
  Button,
  InputBase,
  Modal,
  Typography,
} from '@miyagami-com/lsx-ui-components';
import { createElement, FC, useMemo } from 'react';
import useStyles from './useStyles';
import QRCode from 'react-qr-code';
import { Theme, useMediaQuery } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { object, string } from 'yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';

type Props = {
  isOpen: boolean;
  onClose: () => void | Promise<void>;
  totpUri: string;
  totpSecretKey: string;
  onVerify: (code: string) => void | Promise<void>;
};

type FormValues = {
  code: string;
};

const RequestTotpModal: FC<Props> = ({
  isOpen,
  onClose,
  onVerify,
  totpSecretKey,
  totpUri,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  const validationSchema = useMemo(() => {
    return object().shape({
      code: string()
        .min(6, 'Too Short!')
        .max(10, 'Too Long!')
        .required('Required'),
    });
  }, []);

  const onSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    try {
      actions.setSubmitting(true);
      await onVerify(values.code);
    } catch (e) {
      actions.setFieldError('code', intl.formatMessage(messages.invalidCode));
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Modal open={isOpen}>
      <Box className={classes.modalContainer}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="h4" textAlign="center">
            <FormattedMessage {...messages.enableTotpAuthentication} />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage
              {...messages.enableTotpAuthenticationDescription}
            />
          </Typography>

          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="h3">
              1. {intl.formatMessage(messages.downloadApp)}
            </Typography>
            <Typography variant="body2">
              <FormattedMessage {...messages.downloadAppDescription} />
            </Typography>
          </Box>

          {totpUri && (
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography variant="h3">
                2. {intl.formatMessage(messages.scanQRCode)}
              </Typography>
              <Typography variant="body2">
                <FormattedMessage {...messages.scanQRDescription} />
              </Typography>

              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                {createElement(QRCode, {
                  size: isMobile ? 64 : 128,
                  value: totpUri,
                })}
              </Box>
            </Box>
          )}

          {totpSecretKey && (
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography>
                <FormattedMessage {...messages.manuallyDescription} />
              </Typography>

              <Box
                sx={{
                  padding: 2,
                }}
                bgcolor="grey.500"
              >
                <Typography variant="h6" color="background.default">
                  {totpSecretKey}
                </Typography>
              </Box>
            </Box>
          )}

          <Formik<FormValues>
            initialValues={{
              code: '',
            }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ errors, values, handleChange, handleBlur, isSubmitting }) => (
              <Form>
                <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                  <Box display="flex" flexDirection="column" gap={1}>
                    <Typography variant="h3">
                      3. {intl.formatMessage(messages.enterOTP)}
                    </Typography>
                    <Typography variant="body2">
                      <FormattedMessage {...messages.enterOTPDescription} />
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={1}
                      marginTop={1}
                    >
                      <InputBase
                        name="code"
                        classes={{ input: classes.otpCodeInput }}
                        error={!!errors.code}
                        value={values.code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={intl.formatMessage(messages.enterOTP)}
                      />

                      {errors.code && (
                        <Typography variant="caption" color="error">
                          {errors.code}
                        </Typography>
                      )}
                    </Box>

                    <Box
                      display="flex"
                      flexDirection="column"
                      marginTop={2}
                      gap={2}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        <Typography variant="button">
                          <strong>
                            <FormattedMessage {...messages.verify} />
                          </strong>
                        </Typography>
                      </Button>

                      <Button
                        disabled={isSubmitting}
                        type="button"
                        variant="text"
                        onClick={onClose}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 1,
                          color: 'black',
                        }}
                        disableRipple
                      >
                        <ArrowBackIcon />
                        <Typography variant="button">
                          <FormattedMessage {...messages.returnToSite} />
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
};

export default RequestTotpModal;
