import { useQuery, useQueryClient } from 'react-query';
import useSupabase from './useSupabase';
import { ExtendedBackOfficeUser } from '../../../types';
import { SELECT_EXTENDED_BACKOFFICE_USER } from '../constants';

const useUnsettledBrandBackofficeUsers = (): typeof queryResult => {
  const supabase = useSupabase();

  const queryClient = useQueryClient();

  const queryResult = useQuery<ExtendedBackOfficeUser[] | null, Error>(
    'unsettledBrandBackofficeUsers',
    async () => {
      const { data: users } = await supabase
        .from('backoffice_users')
        .select<typeof SELECT_EXTENDED_BACKOFFICE_USER, ExtendedBackOfficeUser>(
          SELECT_EXTENDED_BACKOFFICE_USER,
        )
        .throwOnError();

      return users;
    },
    {
      onSuccess: (users) => {
        if (!users?.length) return;

        users.forEach((user) => {
          queryClient.setQueryData('backofficeUser', user.id);
        });
      },
    },
  );

  return queryResult;
};

export default useUnsettledBrandBackofficeUsers;
