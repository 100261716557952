import { defineMessages } from 'react-intl';

export default defineMessages({
  system: {
    id: 'ExchangeBet.ExchangeBetExport.system',
    defaultMessage: 'System',
  },
  betExport: {
    id: 'ExchangeBet.ExchangeBetExport.betExport',
    defaultMessage: 'Bet export',
  },
  getData: {
    id: 'ExchangeBet.ExchangeBetExport.getData',
    defaultMessage: 'Get data',
  },
});
