import { BackOfficeUser } from '../../../types';
import { Player } from '../../../types/supabase';

interface GetUserParentDataParams {
  user: BackOfficeUser | Player;
}

export type ParentData = {
  parentId: string | null;
  parentPath: string | null;
};

const getUserParentData = (params: GetUserParentDataParams): ParentData => {
  const { user } = params;

  return {
    parentId: user.parent_id,
    parentPath: user.parent_path,
  };
};

export default getUserParentData;
