import { useContext } from 'react';
import {
  AuthenticationContext,
  AuthenticationContextProps,
} from '../../components/Unknown/AuthenticationContext';

const useAuthenticationContext = (): AuthenticationContextProps => {
  return useContext(AuthenticationContext);
};

export default useAuthenticationContext;
