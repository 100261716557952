const transformToBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => {
      const binaryString = event.target?.result as string;
      if (binaryString) {
        const base64String = btoa(binaryString);
        resolve(base64String);
      }
    };
    reader.onerror = (error) => reject(error);
  });

export default transformToBase64;
