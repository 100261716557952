//TODO: will be changed in other task.

enum Password {
  validationMessage = 'The password should at least be 6 characters and contain 1 number, 1 uppercase letter and 1 lowercase letter',
  mustMatch = 'Passwords must match',
  confirmMessage = 'This field is required',
}

enum Field {
  required = 'This field is required',
  trim = 'This field cannot include leading and trailing spaces',
  withoutSpecialCharacters = 'The name must be without special characters',
}

enum Date {
  validationMessage = 'The date should be in format YYYY-MM-DD',
  confirmMessage = 'This field is required',
}

enum PhoneNumber {
  notValid = 'Phone number is not valid',
}

export { Password, Field, PhoneNumber, Date };
