import React from 'react';
import { Box, Grid, Paper, Divider } from '@miyagami-com/lsx-ui-components';
import useBreakpoints from '../../../common/hooks/useBreakpoints';
import useDesktopColumNumber from './useDesktopColumNumber';

export interface HeaderItem {
  id: string;
  main?: JSX.Element | null;
  secondary?: JSX.Element | null;
}

interface DetailHeaderProps {
  items: HeaderItem[];
}

const DetailHeader: React.FC<DetailHeaderProps> = (props) => {
  const { items } = props;

  const { isTablet, isDesktop } = useBreakpoints();

  const desktopColumNumber = useDesktopColumNumber({ items });

  if (isDesktop) {
    return (
      <Paper>
        <Grid
          container
          columns={desktopColumNumber}
          spacing={0}
          py={3}
          alignItems="center"
        >
          {items.map((item, index) => {
            const isLastItem = index === items.length - 1;

            return (
              <React.Fragment key={item.id}>
                <Grid item lg={3} px={3}>
                  <Box>{item?.main}</Box>
                  <Box>{item?.secondary}</Box>
                </Grid>
                {!isLastItem && (
                  <Grid height={1} item xs={1}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      height={1}
                      width={1}
                    >
                      <Box width="1px" height={25} bgcolor="grey.300"></Box>
                    </Box>
                  </Grid>
                )}
              </React.Fragment>
            );
          })}
        </Grid>
      </Paper>
    );
  }

  if (isTablet) {
    return (
      <Paper>
        <Grid container spacing={0} py={3} alignItems="center">
          {items.map((item, index) => {
            const isLastItem = index === items.length - 1;

            const isRowFirstItem = index % 2 === 0;

            return (
              <React.Fragment key={item.id}>
                <Grid item xs={5} px={3}>
                  <Box>{item?.main}</Box>
                  <Box>{item?.secondary}</Box>
                </Grid>
                {isRowFirstItem && (
                  <Grid height={1} item xs={2}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      height={1}
                      width={1}
                    >
                      <Box width="1px" height={25} bgcolor="grey.300"></Box>
                    </Box>
                  </Grid>
                )}
                {!isRowFirstItem && !isLastItem && (
                  <Grid item xs={12} m={3}>
                    <Divider />
                  </Grid>
                )}
              </React.Fragment>
            );
          })}
        </Grid>
      </Paper>
    );
  }

  return (
    <Paper>
      <Grid container spacing={0} py={3} alignItems="center">
        {items.map((item, index) => {
          const isLastItem = index === items.length - 1;

          return (
            <React.Fragment key={item.id}>
              <Grid item xs={12} px={3}>
                <Box>{item?.main}</Box>
                <Box>{item?.secondary}</Box>
              </Grid>
              {!isLastItem && (
                <Grid item xs={12} m={3}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          );
        })}
      </Grid>
    </Paper>
  );
};

export default DetailHeader;
