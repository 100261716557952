import {
  FC,
  useCallback,
  // useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  DataGridTree,
  // UIContext,
  useGridApiRef,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  DetailPanel,
} from '@miyagami-com/lsx-ui-components';
import {
  GridRenderCellParams,
  GridRowParams,
  GridColumns,
  DataGridProps,
  GridEventListener,
  GridRowModelUpdate,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
// import { useFirebaseApp } from 'reactfire';

import { BackOfficeUser, RootState, UserRole } from '../../../../types';

import useBrandId from '../../../common/hooks/useBrandId';
import useUserStatuses from '../../../common/hooks/useUserStatuses';
import useCheckBrandIsSuspend from '../../../common/hooks/useCheckBrandIsSuspend';

import {
  // DEFAULT_REGION,
  USER_ROLES,
  PLAYER_ROLE,
  MASTER_AGENT_ROLE,
  DEFAULT_AMOUNT,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
} from '../../../common/constants';

import StatusCell from '../../Unknown/StatusCell';
import ActionsBox from '../../Unknown/ActionsBox';

import useStyles from './useStyles';
import messages from './messages';
import useDataGridOrderedColumnsChange from '../../../common/hooks/useDataGridOrderedColumnsChange';
import useDataGridOrderedColumns from '../../../common/hooks/useDataGridOrderedColumns';
import useSupabase from '../../../common/hooks/useSupabase';
import getPagination from '../../../common/getPagination';
import { Player } from '../../../../types/supabase';
import isPlayer from '../../../common/isPlayer';
// import useRefetchBackofficeUsers from '../../../common/hooks/useRefetchBackofficeUsers';

const allRoleData = [...USER_ROLES, PLAYER_ROLE];

interface UserListCollapseParams {
  dataGridProps: Omit<DataGridProps, 'columns'>;
  playerAgentIds: string[];
}

type BackOfficeUserRow = BackOfficeUser & {
  path: string[];
  role: UserRole;
  childrenFetched?: boolean;
  canLoadMore?: boolean;
  page?: number | null;
};

type PlayerRow = Player & {
  path: string[];
  role: UserRole;
  childrenFetched?: boolean;
  canLoadMore?: boolean;
  page?: number | null;
};

type Row = BackOfficeUserRow | PlayerRow;

const propertiesColumn = {
  editable: false,
  filterable: false,
  groupable: false,
};

const rowHeight = 35;

const getDetailPanelHeight = () => rowHeight;

const SIZE = 10;

const UserListCollapse: FC<UserListCollapseParams> = ({
  dataGridProps,
  playerAgentIds,
}) => {
  const selectedBrandId = useBrandId();
  const isBrandSuspended = useCheckBrandIsSuspend();

  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();

  const userStatuses = useUserStatuses();

  const supabase = useSupabase();

  const [pageSize, setPageSize] = useState<number>(
    DEFAULT_ROWS_PER_PAGE_OPTIONS[5],
  );

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  // const { setAlert } = useContext(UIContext);

  const apiRef = useGridApiRef();
  const tableKey = 'agent-overview';

  const isColsReorderLoading = useDataGridOrderedColumnsChange({
    tableKey,
    apiRef,
  });

  const { permissionMap } = useSelector((root: RootState) => root.user);

  // const functions = useFirebaseApp().functions(DEFAULT_REGION);

  const onPushToDetail = useCallback(
    (id: string, brandId: string, role: UserRole) => {
      const link = role === PLAYER_ROLE.value ? 'player' : 'agent';

      history.push(`/b/${brandId}/${link}-overview/${id}`);
    },
    [history],
  );

  // const refetchBackofficeUsers = useRefetchBackofficeUsers();

  // const onDeleteUser = useCallback(
  //   async (userId: string, brandId: string, role: UserRole) => {
  //     const deleteUserFunction = functions.httpsCallable(
  //       'back-user-deleteUser',
  //     );
  //     const isPlayerRole = role === PLAYER_ROLE.value;

  //     try {
  //       await deleteUserFunction({
  //         userId,
  //         brandId,
  //       });

  //       const message = isPlayerRole
  //         ? messages.successDeletePlayer
  //         : messages.successDeleteAgent;

  //       setAlert({
  //         show: true,
  //         severity: 'success',
  //         message: intl.formatMessage(message),
  //       });
  //     } catch (error) {
  //       const message = isPlayerRole
  //         ? messages.errorDeletePlayer
  //         : messages.errorDeleteAgent;

  //       setAlert({
  //         show: true,
  //         severity: 'error',
  //         message: intl.formatMessage(message),
  //       });
  //     } finally {
  //       refetchBackofficeUsers();
  //     }
  //   },
  //   [functions, intl, refetchBackofficeUsers, setAlert],
  // );

  const loadPlayers = useCallback(
    async (agentId: string, page: number, size: number) => {
      const { from, to } = getPagination({ page, size });

      const { data: players, count } = await supabase
        .from('players')
        .select('*, user_stats(balance)', { count: 'exact' })
        .eq('brand_id', selectedBrandId)
        .eq('parent_id', agentId)
        .order('created_at', { ascending: false })
        .range(from, to);

      return {
        players,
        count,
      };
    },
    [selectedBrandId, supabase],
  );

  const loadNewPlayers = useCallback(
    async (id: string, rowPath: string[] = [], page: number) => {
      const { players, count } = await loadPlayers(id, page, SIZE);

      if (!players || !id) return [];

      const parenRowNode = apiRef.current.getRowNode(id);

      const parentRowChildrenLength =
        parenRowNode?.children?.length || DEFAULT_AMOUNT;

      const playersLength = players?.length;

      const parentChildrenCount = parentRowChildrenLength + playersLength;

      const canLoadMore = parentChildrenCount < (count || DEFAULT_AMOUNT);

      return players.map((player, index) => {
        const isLastPlayer = index === players.length - 1;

        const isCanLoadMore = canLoadMore && isLastPlayer;

        return {
          ...player,
          role: PLAYER_ROLE.value,
          brandId: selectedBrandId,
          path: [...rowPath, player.id],
          canLoadMore: isCanLoadMore,
          page: page + 1,
        };
      });
    },
    [apiRef, loadPlayers, selectedBrandId],
  );

  const updateRows = useCallback(
    (newRows: GridRowModelUpdate[], row: Row, lastPrevLoadedRow?: Row) => {
      if (lastPrevLoadedRow) {
        apiRef.current.updateRows([
          { ...row, childrenFetched: true },
          { ...lastPrevLoadedRow, canLoadMore: false, firestoreDoc: null },
          ...newRows,
        ]);
      } else {
        apiRef.current.updateRows([
          { ...row, childrenFetched: true },
          ...newRows,
        ]);
      }

      const expandedDetailPanels = apiRef.current.getExpandedDetailPanels();

      const newFilteredExpandedDetailPanels = newRows
        .filter(
          (newRow) =>
            !expandedDetailPanels.find((panelId) => panelId === newRow?.id) &&
            newRow?.canLoadMore,
        )
        .map((newRow) => newRow.id);

      const newExpandedDetailPanels = expandedDetailPanels.filter(
        (panelId) => panelId !== lastPrevLoadedRow?.id,
      );

      apiRef.current.setExpandedDetailPanels([
        ...newFilteredExpandedDetailPanels,
        ...newExpandedDetailPanels,
      ]);
    },
    [apiRef],
  );

  const onLoadMore = useCallback(
    async (row: Row) => {
      const isPlayerRow = isPlayer(row);

      const parentId = isPlayerRow ? row.parent_id : row.parent_id;

      const parentRow = apiRef.current.getRow(parentId as string);

      const newPlayers = await loadNewPlayers(
        parentId as string,
        parentRow?.path,
        row.page || DEFAULT_AMOUNT,
      );

      if (!newPlayers.length) return;

      updateRows(newPlayers, parentRow, row);
    },
    [apiRef, loadNewPlayers, updateRows],
  );

  const detailPanelContext = useMemo(() => {
    return (params: GridRowParams) => {
      return DetailPanel({
        onClick: () => onLoadMore(params.row),
        label: intl.formatMessage(messages.loadMore),
      });
    };
  }, [onLoadMore, intl]);

  const unorderedColumns: GridColumns = useMemo(
    () => [
      {
        ...propertiesColumn,
        field: 'id',
        hide: true,
        headerName: intl.formatMessage(messages.id),
        flex: 0.4,
      },
      {
        ...propertiesColumn,
        field: 'surname',
        headerName: intl.formatMessage(messages.surname),
        flex: 0.4,
      },
      {
        ...propertiesColumn,
        field: 'email',
        headerName: intl.formatMessage(messages.email),
        flex: 0.5,
      },
      {
        ...propertiesColumn,
        field: 'balance',
        headerName: intl.formatMessage(messages.balance),
        flex: 0.5,
        valueGetter: (params) => {
          const { row } = params;
          const { user_stats } = row;
          if (!user_stats) return 0;
          else return user_stats[0].balance;
          // console.log(user_stats);
          // return 'hi';
        },
      },
      {
        ...propertiesColumn,
        field: 'brandId',
        headerName: intl.formatMessage(messages.brand),
        flex: 0.7,
      },
      {
        ...propertiesColumn,
        field: 'role',
        headerName: intl.formatMessage(messages.role),
        flex: 0.3,
        valueFormatter: ({ value }) => {
          if (!value) return null;

          const userRole = value as UserRole;

          const roleData = allRoleData.find(
            ({ value: role }) => role === userRole,
          );

          return roleData?.label;
        },
      },
      {
        ...propertiesColumn,
        field: 'status',
        headerName: intl.formatMessage(messages.status),
        flex: 0.4,
        renderCell: (params: GridRenderCellParams) => {
          if (!params.value) return null;

          if (isBrandSuspended) {
            const userStatus = userStatuses.suspend;

            return <StatusCell {...userStatus} />;
          }

          const status =
            params.value.toLowerCase() as keyof typeof userStatuses;

          const userStatus = userStatuses[status];

          if (!userStatus) return null;

          return <StatusCell {...userStatus} />;
        },
      },
      {
        ...propertiesColumn,
        field: 'actions',
        headerName: intl.formatMessage(messages.actions),
        flex: 0.4,
        renderCell: (params: GridRenderCellParams) => {
          if (!params.row) return null;

          const { id, brandId } = params.row;

          const role = params.row.role as Exclude<UserRole, 'systemAdmin'>;

          const brandPermissionMap = permissionMap[selectedBrandId];

          const rolePermission = brandPermissionMap?.[role];

          const actions = [];

          const isHaveEditAccess = !!rolePermission?.update;

          if (isHaveEditAccess) {
            const editAction = {
              label: intl.formatMessage(messages.editUser),
              buttonProps: {
                onClick: () => onPushToDetail(id, brandId, role),
              },
            };

            actions.push(editAction);
          }

          return <ActionsBox actions={actions} />;
        },
      },
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        flex: 0,
        hideable: false,
        hide: true,
        headerName: intl.formatMessage(messages.loadMore),
        renderCell: () => {
          return <></>;
        },
        headerClassName: classes.detailPanelClassName,
        cellClassName: classes.detailPanelClassName,
      },
    ],
    [
      classes.detailPanelClassName,
      intl,
      isBrandSuspended,
      onPushToDetail,
      permissionMap,
      selectedBrandId,
      userStatuses,
    ],
  );

  const columns = useDataGridOrderedColumns({ tableKey, unorderedColumns });
  const groupingColDef = useMemo(
    () => ({
      headerName: intl.formatMessage(messages.name),
      headerClassName: classes.headerClassName,
      valueGetter: ({ row }: GridValueGetterParams) => {
        return row.name;
      },
      filterable: false,
      sortable: true,
      hideSortIcons: false,
      disableColumnMenu: false,
    }),
    [intl, classes],
  );

  const handleRowExpansionChange: GridEventListener<'rowExpansionChange'> =
    useCallback(
      async (node) => {
        const row = apiRef.current.getRow(node.id) as Row | null;

        if (
          row?.role === MASTER_AGENT_ROLE &&
          node.childrenExpanded &&
          node?.children?.length &&
          !row?.childrenFetched
        ) {
          if (row?.path?.length) {
            const newPlayers = await Promise.all(
              node.children?.map(async (id) => {
                if (playerAgentIds.find(async (agentId) => agentId === id)) {
                  return loadNewPlayers(
                    id as string,
                    [...row?.path, String(id)],
                    row.page || DEFAULT_AMOUNT,
                  );
                }
              }),
            );

            const filteredPlayers = newPlayers.filter(
              (newPlayersArr) => newPlayersArr?.length,
            );

            const newFilteredPlayers =
              filteredPlayers.flat() as GridRowModelUpdate[];

            updateRows(newFilteredPlayers, row);
          }
        }
      },
      [apiRef, loadNewPlayers, playerAgentIds, updateRows],
    );

  useEffect(() => {
    apiRef.current.subscribeEvent(
      'rowExpansionChange',
      handleRowExpansionChange,
    );
  }, [apiRef, handleRowExpansionChange]);

  const getRowClassName = (params: GridRowParams) => {
    const rowClasses = ['', classes.firstChild, classes.secondChild];

    if (params.row.path) {
      const nestingDepth = params.row.path.length - 1;
      return rowClasses[nestingDepth];
    }
    return '';
  };

  return (
    <Box p={3}>
      <DataGridTree
        {...dataGridProps}
        apiRef={apiRef}
        disableSelectionOnClick
        disableColumnReorder={isColsReorderLoading}
        autoHeight
        rowHeight={rowHeight}
        rowThreshold={0}
        headerHeight={rowHeight}
        columns={columns}
        getRowClassName={getRowClassName}
        getDetailPanelContent={detailPanelContext}
        getDetailPanelHeight={getDetailPanelHeight}
        getTreeDataPath={(row) => row.path}
        pagination
        rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
        groupingColDef={groupingColDef}
      />
    </Box>
  );
};

export default UserListCollapse;
