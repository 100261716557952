import { useQuery, useQueryClient } from 'react-query';
import useSupabase from './useSupabase';
import { Brand } from '../../../types';

interface UseBrandsByIdsParams {
  brandIds: string[];
  select?: string;
}

const useBrandsByIds = <T extends Brand>(
  params: UseBrandsByIdsParams,
): typeof queryResult => {
  const { brandIds, select = '*' } = params;

  const supabase = useSupabase();

  const queryClient = useQueryClient();

  const queryResult = useQuery<T[] | null, Error>(
    ['brands', brandIds],
    async () => {
      const response = await supabase
        .from('brands')
        .select<typeof select, T>(select)
        .in('id', brandIds)
        .throwOnError();

      return response.data;
    },
    {
      onSuccess: (brands) => {
        if (!brands) return;

        brands.map((brand) => {
          queryClient.setQueryData('brand', brand.id);
        });
      },
    },
  );

  return queryResult;
};

export default useBrandsByIds;
