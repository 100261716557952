import { defineMessages } from 'react-intl';

export default defineMessages({
  successMessage: {
    id: 'BrandApiKey.BrandApiKeys.successMessage',
    defaultMessage: 'New API key has successfully added!',
  },
  errorMessage: {
    id: 'BrandApiKey.BrandApiKeys.errorMessage',
    defaultMessage: 'An error occurred while adding key API!',
  },
  missingFile: {
    id: 'BrandApiKey.BrandApiKeys.missingFile',
    defaultMessage: 'Missing certificate or key',
  },
  validationErrorMessage: {
    id: 'BrandApiKey.BrandApiKeys.validationErrorMessage',
    defaultMessage: 'Certificate and key is required!',
  },
  apiKeys: {
    id: 'BrandApiKey.BrandApiKeys.apiKeys',
    defaultMessage: 'API Keys',
  },
});
