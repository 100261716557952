import { AxisDomain } from 'recharts/types/util/types';
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  min,
  max,
  minutesToMilliseconds,
  subDays,
  subMonths,
  addDays,
  addMonths,
  format,
  startOfTomorrow,
  startOfToday,
  startOfMonth,
  startOfYear,
  subWeeks,
  subYears,
  startOfYesterday,
} from 'date-fns';
import { ProfitLossReportPeriod } from '../../../../types/index';
import { convertToUTCDate } from '../ProfitLossReportList/utils';
import { DEFAULT_CURRENCY } from '../../../common/constants';

export interface ProfitLossReportGraphItem {
  win_loss: number;
  date: number;
}

const yearlyPeriods: ProfitLossReportPeriod[] = ['currentYear', 'lastYear'];

const toUTCTimestamp = (date: Date) => {
  const offset = minutesToMilliseconds(date.getTimezoneOffset());
  return date.getTime() - offset;
};

interface GetTicksCountParams {
  data: ProfitLossReportGraphItem[];
  reportPeriod: ProfitLossReportPeriod;
}

const profitLossMargin = 0.1;

const today = startOfToday();
const tomorrow = startOfTomorrow();
const yesterday = startOfYesterday();
const lastWeekStart = subWeeks(tomorrow, 1);
const lastMonthStart = subMonths(today, 1);
const lastYearStart = subYears(today, 1);
const lastYearCurrentMonthStart = startOfMonth(lastYearStart);
const lastYearNextMonthStart = addMonths(lastYearCurrentMonthStart, 1);
const currentYearStart = startOfYear(today);
const startOfCurrentMonth = startOfMonth(today);

export const withMargin = (value: number): number => {
  return value * (1 + profitLossMargin);
};

const intervalDatesByPeriod: Partial<
  Record<ProfitLossReportPeriod, [Date, Date]>
> = {
  lastYear: [lastYearNextMonthStart, startOfCurrentMonth],
  currentYear: [currentYearStart, startOfCurrentMonth],
  lastWeek: [lastWeekStart, today],
  lastMonth: [lastMonthStart, today],
};

export const getTicksValues = ({
  data,
  reportPeriod,
}: GetTicksCountParams): { ticks: number[]; domain: AxisDomain } => {
  const defaultDate = reportPeriod === 'yesterday' ? yesterday : today;
  const [startOfPeriod, endOfPeriod] = intervalDatesByPeriod[reportPeriod] || [
    defaultDate,
    defaultDate,
  ];

  const utcTimestamps = data.map(({ date }) => date);
  const start = min([...utcTimestamps, startOfPeriod]);
  const end = max([...utcTimestamps, endOfPeriod]);
  const isStepEqualsMonth = yearlyPeriods.includes(reportPeriod);

  const interval = {
    start,
    end,
  };

  const ticksDates = isStepEqualsMonth
    ? eachMonthOfInterval(interval)
    : eachDayOfInterval(interval);

  const scaleStart = isStepEqualsMonth
    ? subMonths(interval.start, 1)
    : subDays(interval.start, 1);

  const scaleEnd = isStepEqualsMonth
    ? addMonths(interval.end, 1)
    : addDays(interval.end, 1);

  return {
    ticks: ticksDates.map((date) => toUTCTimestamp(date)),
    domain: [toUTCTimestamp(scaleStart), toUTCTimestamp(scaleEnd)],
  };
};

export const formatCurrencyValue = ({
  value,
  withDecimal,
}: {
  value: number;
  withDecimal?: boolean;
}): string => {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: DEFAULT_CURRENCY,
    minimumFractionDigits: withDecimal ? 2 : 0,
    maximumFractionDigits: withDecimal ? 2 : 0,
  });

  return currencyFormatter.format(value);
};

export const getFormattedDateLabel = ({
  timestamp,
  reportPeriod,
}: {
  timestamp: number;
  reportPeriod: ProfitLossReportPeriod;
}): string => {
  const isStepEqualsMonth = yearlyPeriods.includes(reportPeriod);
  const date = convertToUTCDate(timestamp).toUTCString();
  const dateFormat = isStepEqualsMonth ? 'MM/yyyy' : 'dd/MM/yyyy';

  return format(new Date(date), dateFormat);
};
