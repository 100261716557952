import * as Yup from 'yup';
import { REG_EXP_PASSWORD } from '../../../common/constants';
import { Password, Field } from '../../../common/validationMessages';

export const validationSchemaEmail = Yup.object().shape({
  email: Yup.string().email().required(Field.required),
});

export const validationSchemaPasswords = Yup.object().shape({
  password: Yup.string()
    .matches(REG_EXP_PASSWORD, Password.validationMessage)
    .required(Field.required)
    .trim(Field.trim),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], Password.mustMatch)
    .required(Field.required)
    .trim(Field.trim),
});
