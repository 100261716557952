import { useCallback, useContext } from 'react';
import { UIContext } from '@miyagami-com/lsx-ui-components';
import useSupabase from './useSupabase';
import { useQuery } from 'react-query';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const usePastEvents = (): ReturnType<any> => {
  const { setAlert } = useContext(UIContext);

  const supabase = useSupabase();

  const fetchAllPastEvents = useCallback(async () => {
    try {
      const { data } = await supabase
        .from('markets')
        .select('*')
        .eq('is_closed', true)
        .order('event_open_date', { ascending: false });

      return data || [];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setAlert({
        show: true,
        severity: 'error',
        message: error?.message,
      });
      return [];
    }
  }, [setAlert, supabase]);

  const queryResult = useQuery(['pastEvents'], () => {
    return fetchAllPastEvents();
  });

  return queryResult;
};

export default usePastEvents;
