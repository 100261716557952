import React from 'react';
import { Box, Typography } from '@miyagami-com/lsx-ui-components';
import { BoxProps, TypographyProps } from '@mui/material';

interface StatusCellProps extends BoxProps {
  width?: number;
  height?: number;
  label?: string;
  color?: string;
  typographyProps?: TypographyProps;
}

const StatusCell: React.FC<StatusCellProps> = (props) => {
  const {
    width = 14,
    height = 14,
    color,
    label,
    typographyProps,
    ...otherProps
  } = props;

  return (
    <Box {...otherProps} display="flex" alignItems="center">
      <Box
        width={width}
        height={height}
        borderRadius="50%"
        bgcolor={color}
        mr={1}
      />
      <Typography {...typographyProps}>{label}</Typography>
    </Box>
  );
};

export default StatusCell;
