import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  borderRadius: {
    borderTopRightRadius: '4px',
    borderTopLeftRadius: '4px',
  },
  pointer: {
    cursor: 'pointer',
  },
  button: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: '0.4px',
  },
}));

export default useStyles;
