type MinMaxNumber = {
  min: number;
  max: number;
};

const isNumber = (number: number | string) => {
  return (
    (Number.isInteger(number) || Number.isFinite(number)) &&
    !Number.isNaN(number)
  );
};

const getMinMaxNumber = (list: number[]): MinMaxNumber => {
  const min = Math.min(...list);
  const max = Math.max(...list);

  const isMinNumber = isNumber(min);
  const isMaxNumber = isNumber(max);

  if (!isMinNumber || !isMaxNumber) return { min: 0, max: 0 };
  return { min, max };
};

export default getMinMaxNumber;
