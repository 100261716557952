import React, { useEffect, useState } from 'react';
import { GridRowData, GridColumns } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';

import DataGridTable from '../../Unknown/DataGridTable';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from '../../../common/constants';
import { RootState } from '../../../../types';

import sortBrandData from './sortBrandData';

type BrandOverviewListProps = {
  columns: GridColumns;
  rows: GridRowData[];
  setRows: CallableFunction;
  setDefaultRows: CallableFunction;
};

const BrandOverviewList: React.FC<BrandOverviewListProps> = ({
  rows,
  setRows,
  setDefaultRows,
  columns,
}) => {
  const [pageSize, setPageSize] = useState(DEFAULT_ROWS_PER_PAGE_OPTIONS[5]);

  const {
    users: { brandOwners },
    brands,
  } = useSelector((state: RootState) => state.globalData);

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  useEffect(() => {
    if (brands && brandOwners) {
      const sortedRows = sortBrandData(brands, brandOwners);
      setDefaultRows(sortedRows);
      setRows(sortedRows);
    }
  }, [brands, setDefaultRows, setRows, brandOwners]);

  return (
    <DataGridTable
      rows={rows}
      columns={columns}
      tableKey="brand-overview"
      pageSize={pageSize}
      onPageSizeChange={onPageSizeChange}
      rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
      disableSelectionOnClick
      autoHeight
      rowHeight={35}
      headerHeight={35}
    />
  );
};

export default BrandOverviewList;
