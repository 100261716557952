import { Form, Formik } from 'formik';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import {
  Box,
  LoadingButton,
  TextField,
  Typography,
  Grid,
} from '@miyagami-com/lsx-ui-components';

import PasswordOutlinedInput from '../../Unknown/PasswordOutlinedInput';

import {
  validationSchemaEmail,
  validationSchemaPasswords,
} from './validationSchema';
import messages from './messages';
import useStyles from './useStyles';
import { Password } from '../../../common/validationMessages';

type ResetPasswordFormProps = {
  onSubmitEmail: CallableFunction;
  onSubmitNewPassword: CallableFunction;
  isReset: boolean;
};

const initialValues = {
  email: '',
  password: '',
  confirmPassword: '',
};

type RenderFields = typeof initialValues;

type Fields = {
  name: keyof RenderFields;
  label: string;
  validationText: string;
};

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  onSubmitEmail,
  onSubmitNewPassword,
  isReset,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();

  const onPushToLogin = () => {
    history.push('/login');
  };

  const renderPasswordFields: Fields[] = [
    {
      name: 'password',
      label: intl.formatMessage(messages.password),
      validationText: Password.validationMessage,
    },
    {
      name: 'confirmPassword',
      label: intl.formatMessage(messages.confirmPassword),
      validationText: Password.confirmMessage,
    },
  ];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) =>
        isReset
          ? onSubmitNewPassword({ password: values.password }, actions)
          : onSubmitEmail({ email: values.email })
      }
      validationSchema={
        isReset ? validationSchemaPasswords : validationSchemaEmail
      }
    >
      {({
        errors,
        handleBlur,
        values,
        touched,
        handleChange,
        isSubmitting,
      }) => {
        const getFieldData = (name: keyof RenderFields): typeof fieldValue => {
          const error = errors[name];
          const isTouched = touched[name];

          const fieldValue = {
            fullWidth: true,
            name,
            error: Boolean(error && isTouched),
            onChange: handleChange,
            onBlur: handleBlur,
            helperText: errors[name],
            id: name,
          };

          return fieldValue;
        };

        return (
          <Form>
            <Grid container rowSpacing={3} paddingY={4} maxWidth="673px">
              {isReset ? (
                <Grid item container xs={12} marginX={3} rowSpacing={2}>
                  {renderPasswordFields.map(
                    ({ name, label, validationText }) => {
                      return (
                        <Grid key={name} item xs={12}>
                          <PasswordOutlinedInput
                            label={label}
                            value={values[name]}
                            validationText={validationText}
                            {...getFieldData(name)}
                          />
                        </Grid>
                      );
                    },
                  )}
                </Grid>
              ) : (
                <Grid item xs={12} marginX={3}>
                  <TextField
                    label={intl.formatMessage(messages.email)}
                    {...getFieldData('email')}
                  />
                </Grid>
              )}
              <Grid item container xs={12} marginX={3}>
                <LoadingButton
                  classes={{ root: classes.button }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={isSubmitting}
                >
                  <FormattedMessage {...messages.resetPassword} />
                </LoadingButton>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  ml={4}
                  onClick={onPushToLogin}
                  className={classes.pointer}
                  color="primary.dark"
                >
                  <Typography>
                    <FormattedMessage {...messages.login} />
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ResetPasswordForm;
