import React from 'react';
import { Box } from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';

import LogUserList from '../../Log/LogUserList';

import messages from './messages';

interface PlayerSecurityPageProps {
  playerId: string;
}

const PlayerSecurityPage: React.FC<PlayerSecurityPageProps> = ({
  playerId,
}) => {
  const intl = useIntl();

  return (
    <Box pt={4}>
      <LogUserList
        label={intl.formatMessage(messages.tableLabel)}
        userId={playerId}
        type="player"
        tableKey="player-log-list"
      />
    </Box>
  );
};

export default PlayerSecurityPage;
