import { PermissionObjectType, PermissionOperation } from '../../../types';

interface ParsePermission {
  permission: string;
}

export type ParsedPermission = {
  permissionType: PermissionObjectType;
  operation: PermissionOperation;
  brandId: string;
};

const parsePermission = ({ permission }: ParsePermission): ParsedPermission => {
  const isBrandPermission = permission.indexOf('brand/') === 0;

  if (!isBrandPermission) throw new Error('Incorrect permission.');

  const [, brandId, permissionType, operation] = permission.split('/');

  return { permissionType, operation, brandId } as ParsedPermission;
};

export default parsePermission;
