import { Box } from '@miyagami-com/lsx-ui-components';
import DataGridTable from '../Unknown/DataGridTable';
import { BetData } from './useGetOutcomes';
import useBetColumns from './useBetColumns';

interface OutcomeBetsDetailsProps {
  exchangeBets: BetData[];
}

const OutcomeBetsDetails = (props: OutcomeBetsDetailsProps): JSX.Element => {
  const { exchangeBets } = props;
  const columns = useBetColumns();

  return (
    <Box>
      <DataGridTable
        tableKey="outcome-bets-details"
        columns={columns}
        rows={exchangeBets || []}
        autoHeight={true}
        rowHeight={35}
        headerHeight={35}
        pagination={false}
        disableSelectionOnClick
        hideFooter
        hideFooterRowCount
      />
    </Box>
  );
};

export default OutcomeBetsDetails;
