import { defineMessages } from 'react-intl';

export default defineMessages({
  id: {
    id: 'User.UserListCollapse.id',
    defaultMessage: 'ID',
  },
  name: {
    id: 'User.UserListCollapse.name',
    defaultMessage: 'First name',
  },
  surname: {
    id: 'User.UserListCollapse.surname',
    defaultMessage: 'Surname',
  },
  email: {
    id: 'User.UserListCollapse.email',
    defaultMessage: 'E-mail',
  },
  phoneNumber: {
    id: 'User.UserListCollapse.phoneNumber',
    defaultMessage: 'Telephone',
  },
  balance: {
    id: 'User.UserListCollapse.balance',
    defaultMessage: 'Balance',
  },
  brand: {
    id: 'User.UserListCollapse.brand',
    defaultMessage: 'Brand',
  },
  role: {
    id: 'User.UserListCollapse.role',
    defaultMessage: 'Role',
  },
  status: {
    id: 'User.UserListCollapse.status',
    defaultMessage: 'Status',
  },
  actions: {
    id: 'User.UserListCollapse.actions',
    defaultMessage: 'Actions',
  },
  viewUser: {
    id: 'User.UserListCollapse.viewUser',
    defaultMessage: 'View',
  },
  editUser: {
    id: 'User.UserListCollapse.editUser',
    defaultMessage: 'Edit',
  },
  deleteUser: {
    id: 'User.UserListCollapse.deleteUser',
    defaultMessage: 'Delete',
  },
  successDeletePlayer: {
    id: 'User.UserListCollapse.successDeletePlayer',
    defaultMessage: 'Player was successfully deleted!',
  },
  successDeleteAgent: {
    id: 'User.UserListCollapse.successDeleteAgent',
    defaultMessage: 'Agent was successfully deleted!',
  },
  errorDeletePlayer: {
    id: 'User.UserListCollapse.errorDeletePlayer',
    defaultMessage: 'An error has occurred while deleting player!',
  },
  errorDeleteAgent: {
    id: 'User.UserListCollapse.errorDeleteAgent',
    defaultMessage: 'An error has occurred while deleting agent!',
  },
  errorPaginationMessage: {
    id: 'User.UserListCollapse.errorPaginationMessage',
    defaultMessage: 'An error has occurred while getting player list.',
  },
  loadMore: {
    id: 'User.UserListCollapse.loadMore',
    defaultMessage: 'Load more',
  },
});
