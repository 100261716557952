import { defineMessages } from 'react-intl';

export default defineMessages({
  accountLog: {
    id: 'Log.LogAccountList.accountLog',
    defaultMessage: 'Account log',
  },
  errorLogsMessage: {
    id: 'BackofficeUserDetail.BackofficeUserDetailAccountLog.errorLogsMessage',
    defaultMessage: 'An error occurred while loading logs!',
  },
});
