import { useState, useCallback } from 'react';

function useClientRect<T>(): [T | null, (node: T) => void] {
  const [rect, setRect] = useState<T | null>(null);
  const ref = useCallback((node: T) => {
    if (node) {
      setRect(node);
    }
  }, []);
  return [rect, ref];
}

export default useClientRect;
