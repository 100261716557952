import { useEffect, useMemo, useState } from 'react';
import {
  ReportPeriod,
  dateRangeByReportPeriod,
  defaultReportPeriod,
} from './reportPeriodData';
import { DateRange } from '@mui/x-date-pickers-pro';
import { useHistory, useLocation } from 'react-router';
import qs from 'qs';
import { SelectChangeEvent } from '@mui/material/Select';
import { getFormattedDate } from '../ProfitLossReportList/utils';
import useConvertPeriodToFromDate from '../ProfitLossReportDashboardSection/useConvertPeriodToFromDate';

export interface QueryString {
  reportPeriod?: ReportPeriod;
  fromDate?: Date | null;
  toDate?: Date | null;
}

const useDataPicker = (): typeof returnParams => {
  const { search } = useLocation();

  const history = useHistory();

  const [datePickerValues, setDatePickerValues] = useState<DateRange<Date>>(
    dateRangeByReportPeriod[defaultReportPeriod],
  );
  const [dateRange, setDateRange] = useState<DateRange<Date>>(
    () => datePickerValues,
  );

  const convertByPeriod = useConvertPeriodToFromDate();

  const routerQuery = useMemo(() => {
    if (!search) return null;

    const parsedString = qs.parse(search, {
      ignoreQueryPrefix: true,
    }) as unknown as QueryString;

    const { reportPeriod, fromDate = null, toDate = null } = parsedString;

    return { reportPeriod, fromDate, toDate };
  }, [search]);

  const reportPeriod = useMemo(() => {
    return routerQuery?.reportPeriod || defaultReportPeriod;
  }, [routerQuery?.reportPeriod]);

  const changeReportDateRange = () => {
    setDateRange(datePickerValues);
    if (reportPeriod === 'custom') {
      const newQuery = { ...routerQuery };
      newQuery.fromDate = datePickerValues[0];
      newQuery.toDate = datePickerValues[1];
      history.replace({ search: qs.stringify(newQuery) });
    }
  };

  const changeReportPeriod = ({ target }: SelectChangeEvent<unknown>) => {
    const newQuery = { ...routerQuery };
    if (typeof target.value === 'string' && target.value !== 'custom') {
      delete newQuery.toDate;
      delete newQuery.fromDate;
    } else {
      newQuery.fromDate = datePickerValues[0];
      newQuery.toDate = datePickerValues[1];
    }
    newQuery.reportPeriod = target.value as ReportPeriod;
    history.replace({ search: qs.stringify(newQuery) });
  };

  const onDatePickerChange = (values: DateRange<Date>) => {
    const fromDate = values[0];
    const toDate = values[1];

    const fromDateStr = fromDate?.toISOString();
    const toDateStr = toDate?.toISOString();

    const newQuery = {
      ...routerQuery,
      fromDate: fromDateStr,
      toDate: toDateStr,
    };

    history.replace({ search: qs.stringify(newQuery) });

    setDatePickerValues(values);
  };

  const { utcFrom, utcTo } = useMemo(() => {
    return {
      utcFrom: getFormattedDate(dateRange[0]),
      utcTo: getFormattedDate(dateRange[1]),
    };
  }, [dateRange]);

  useEffect(() => {
    if (reportPeriod !== 'custom') {
      const newDateRange: DateRange<Date> = [new Date(), new Date()];

      setDatePickerValues(newDateRange);
      setDateRange(newDateRange);
    }

    if (routerQuery?.fromDate && routerQuery?.toDate) {
      setDateRange([routerQuery.fromDate, routerQuery.toDate]);
      setDatePickerValues([routerQuery.fromDate, routerQuery.toDate]);
    }
  }, [convertByPeriod, reportPeriod, routerQuery, setDateRange]);

  const returnParams = {
    onDatePickerChange,
    utcFrom,
    utcTo,
    changeReportPeriod,
    changeReportDateRange,
    dateRange,
    datePickerValues,
    routerQuery,
  };

  return returnParams;
};

export default useDataPicker;
