import React, { useContext } from 'react';
import {
  ModalCenter,
  LoadingButton,
  Paper,
  Typography,
  TextField,
  UIContext,
  IconButton,
  Box,
} from '@miyagami-com/lsx-ui-components';
import { Formik, Form, FieldArray, FormikHelpers } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFirebaseApp } from 'reactfire';
import ClosableBox from '../../Unknown/ClosableBox';
import { RemoveIcon, AddIcon } from '../../Unknown/Icons';
import { DEFAULT_REGION } from '../../../common/constants';

import validationSchema from './validationSchema';
import useStyles from './useStyles';
import messages from './messages';
import { NormalizedBackofficeUser } from '../WhiteList/sortedBackofficeUsersData';
import { useQueryClient } from 'react-query';
import useVirtualWhitelist from './useVirtualWhitelist';
import { getFunctions, httpsCallable } from 'firebase/functions';

type WhiteListEditingProps = {
  isOpen: boolean;
  selectedRow: NormalizedBackofficeUser | null;
  onClose: CallableFunction;
  brandId: string;
};

type FormValues = {
  ips: string[];
};

const WhiteListEditing: React.FC<WhiteListEditingProps> = (props) => {
  const { isOpen, selectedRow, onClose, brandId } = props;

  const { setAlert } = useContext(UIContext);

  const firebase = useFirebaseApp();

  const functions = getFunctions(firebase, DEFAULT_REGION);

  const classes = useStyles();
  const intl = useIntl();

  const virtualWhitelist = useVirtualWhitelist({ item: selectedRow });

  const initialValues = {
    ips: virtualWhitelist.map((item) => item.ip_address),
  };

  const queryClient = useQueryClient();

  const onSubmit = async (
    { ips: ipAddresses }: FormValues,
    { resetForm, setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    setSubmitting(true);
    const updateWhitelist = httpsCallable(
      functions,
      'back-user-updateWhitelist',
    );
    try {
      await updateWhitelist({
        userId: selectedRow?.id,
        ipAddresses,
      });
      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.successfulUpdate),
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setAlert({
        show: true,
        severity: 'warning',
        message: error.toString(),
      });
    } finally {
      resetForm();
      onClose();
      setSubmitting(false);
      queryClient.refetchQueries(['backofficeUsers', brandId]);
      queryClient.refetchQueries(['unsettledBrandBackofficeUsers']);
    }
  };

  return (
    <ModalCenter open={isOpen} onClose={() => onClose()}>
      <ClosableBox
        label={intl.formatMessage(messages.changeUserIpAddress)}
        onClose={() => onClose()}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ handleChange, handleBlur, errors, values, isSubmitting }) => {
            return (
              <Form>
                <Paper sx={{ p: 3, minWidth: '370px' }}>
                  <Typography>
                    <FormattedMessage {...messages.username} />{' '}
                    <span className={classes.bold}>
                      {selectedRow?.username}
                    </span>
                  </Typography>
                  <FieldArray
                    name="ips"
                    render={(arrayHelpers) => (
                      <>
                        {values.ips &&
                          values.ips.length > 0 &&
                          values.ips.map((ipAddress, index) => (
                            <Box key={index}>
                              <Box display="flex" alignItems="center">
                                <TextField
                                  fullWidth
                                  name={`ips.${index}`}
                                  sx={{ mr: 3 }}
                                  key={index}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={Boolean(
                                    errors.ips && errors.ips[index]
                                      ? errors.ips[index]
                                      : false,
                                  )}
                                  helperText={
                                    errors.ips && errors.ips[index]
                                      ? intl.formatMessage(messages.validIp)
                                      : ''
                                  }
                                  label={intl.formatMessage(messages.ipAddress)}
                                  value={ipAddress}
                                  variant="outlined"
                                  margin="dense"
                                />
                                {values.ips.length !== 1 && (
                                  <IconButton
                                    sx={{ height: 1 }}
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                )}
                              </Box>
                              {values.ips.length - 1 === index && (
                                <IconButton
                                  sx={{ height: 1 }}
                                  onClick={() => {
                                    arrayHelpers.push('');
                                  }}
                                >
                                  <AddIcon />
                                </IconButton>
                              )}
                            </Box>
                          ))}
                      </>
                    )}
                  />
                  <LoadingButton
                    loading={isSubmitting}
                    type="submit"
                    sx={{ mt: 3 }}
                    variant="contained"
                    color="primary"
                  >
                    <FormattedMessage {...messages.save} />
                  </LoadingButton>
                </Paper>
              </Form>
            );
          }}
        </Formik>
      </ClosableBox>
    </ModalCenter>
  );
};

export default WhiteListEditing;
