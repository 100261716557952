import Big from 'big.js';

interface BackGrossProfitParams {
  price: number;
  size: number;
}

const backGrossProfit = (params: BackGrossProfitParams): number => {
  const { price, size } = params;

  return new Big(new Big(price).minus(1)).times(size).toNumber();
};

export default backGrossProfit;
