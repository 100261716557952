import { defineMessages } from 'react-intl';

export default defineMessages({
  domains: {
    id: 'BrandDomain.BrandDomains.domains',
    defaultMessage: 'Domains',
  },
  domainAddition: {
    id: 'BrandDomain.BrandDomains.domainAddition',
    defaultMessage: 'Successful domain addition',
  },
  domainAdditionError: {
    id: 'BrandDomain.BrandDomains.domainAdditionError',
    defaultMessage: 'An error occurred while adding a domain!',
  },
  uniqueName: {
    id: 'BrandDomain.BrandDomains.uniqueName',
    defaultMessage: 'The brand must have a unique name',
  },
});
