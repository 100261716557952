import React, { useCallback, useEffect } from 'react';
import { Tab, Tabs } from '@miyagami-com/lsx-ui-components';
import { useHistory, useParams } from 'react-router-dom';
import useStyles from './useStyles';

type Params = {
  userTab?: string;
  userId: string;
  brandId: string;
};

export type TabsData = {
  label: string;
  value: string;
  component: React.ReactNode;
};

export interface UserAccountTabsProps {
  tabs: TabsData[];
  isBrandPending: boolean;
}

const UserAccountTabs: React.FC<UserAccountTabsProps> = ({ tabs }) => {
  const classes = useStyles();

  const history = useHistory();

  const { userTab }: Params = useParams();

  const [tab] = tabs;

  const { value: defaultTab } = tab;

  const [basePath] = history.location.pathname.split(
    `/${userTab || defaultTab}`,
  );

  const onClickTab = useCallback(
    (tabKey: string) => {
      const pushPath = `${basePath}/${tabKey}`;

      history.push(pushPath);
    },
    [basePath, history],
  );

  useEffect(() => {
    if (userTab) return;

    onClickTab(defaultTab);

    return () => {};
  }, [defaultTab, onClickTab, userTab]);

  return (
    <Tabs
      value={userTab || defaultTab}
      onChange={(_e, value) => onClickTab(value)}
      textColor="inherit"
      variant="fullWidth"
    >
      {tabs.map(({ value, label }) => (
        <Tab
          className={classes.tabsBorder}
          key={value}
          label={label}
          value={value}
        />
      ))}
    </Tabs>
  );
};

export default UserAccountTabs;
