import { defineMessages } from 'react-intl';

export default defineMessages({
  tokenFailed: {
    id: 'Unknown.AuthContext.tokenFailed',
    defaultMessage: 'Not found auth token in URL',
  },
  authFailed: {
    id: 'Unknown.AuthContext.authFailed',
    defaultMessage: 'Auth failed, please contact with administrator',
  },
  logoutError: {
    id: 'Unknown.AuthContext.logoutError',
    defaultMessage: 'Some error while logout user.',
  },
  notFoundVerificationId: {
    id: 'Unknown.AuthContext.notFoundVerificationId',
    defaultMessage: 'Not found verification id, try again.',
  },
});
