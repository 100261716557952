import { defineMessages } from 'react-intl';

export default defineMessages({
  description: {
    id: 'BrandDomain.BrandDomainConfigDNSList.description',
    defaultMessage:
      'To verify your domain settings, add the following entries to your DNS zone editor.',
  },
  dnsRecordType: {
    id: 'BrandDomain.BrandDomainConfigDNSList.dnsRecordType',
    defaultMessage: 'Type',
  },
  dnsRecordValue: {
    id: 'BrandDomain.BrandDomainConfigDNSList.dnsRecordValue',
    defaultMessage: 'Value',
  },
  buttonText: {
    id: 'BrandDomain.BrandDomainConfigDNSList.buttonText',
    defaultMessage: 'Verify Domain Config',
  },
});
