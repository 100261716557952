import { defineMessages } from 'react-intl';

export default defineMessages({
  collapseBoxLabel: {
    id: 'ProfitLoss.ProfitLossManualCreation.collapseBoxLabel',
    defaultMessage: 'Create PL report',
  },
  succes: {
    id: 'ProfitLoss.ProfitLossManualCreation.succes',
    defaultMessage: 'Profit Loss report was successfully created.',
  },
  error: {
    id: 'ProfitLoss.ProfitLossManualCreation.error',
    defaultMessage: 'An error occurred while creating the Profit Loss report.',
  },
  saveButton: {
    id: 'ProfitLoss.ProfitLossManualCreation.saveButton',
    defaultMessage: 'Create',
  },
});
