import format from 'date-fns/format';
import { BackOfficeUser } from '../../../../types';
import { PlayerNote, UserNote } from '../../../../types/supabase';

interface NormalizeUserNotesParams {
  notes: PlayerNote[] | UserNote[];
  userMap: Map<string, BackOfficeUser>;
}

export interface NormalizedUserNote extends Omit<PlayerNote, 'player_id'> {
  date: string;
  time: string;
  author?: string;
  isLoading?: boolean;
}

const normalizeUserNotes = (
  params: NormalizeUserNotesParams,
): NormalizedUserNote[] => {
  const { notes, userMap } = params;

  const normalizedUserNotes = notes.map((note) => {
    const noteDate = new Date(note.created_at);

    const date = format(noteDate, 'M/d/yyyy');

    const time = format(noteDate, 'h:mm aa');

    const author = userMap.get(note.author_id);

    return {
      ...note,
      date,
      time,
      author: author?.username,
      isLoading: false,
    };
  });

  return normalizedUserNotes;
};

export default normalizeUserNotes;
