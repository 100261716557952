import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface Props {
  color?: string;
}

const circleDiameter = 15;

const useStyles = makeStyles<Theme, Props>((theme) => ({
  circleIconBorder: {
    width: circleDiameter,
    height: circleDiameter,

    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.black,
    borderRadius: '50%',
  },
  circleColor: {
    backgroundColor: (props) =>
      props.color ? props.color : theme.palette.common.white,
  },
}));

export default useStyles;
