import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import DefaultLayout from '../../Unknown/DefaultLayout';
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
} from '@miyagami-com/lsx-ui-components';
import { FC, useState } from 'react';
import ExchangeBetExportList from '../ExchangeBetExportList';
import OverviewLayout from '../../Unknown/OverviewLayout';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types';
import useBrandId from '../../../common/hooks/useBrandId';
import useBetRows from './useBetRows';
import { DateRange } from '@mui/lab';
import ExchangeBetSportTypeFilter from '../ExchangeBetSportTypeFilter';
import ExchangeBetDateRangeFilter from '../ExchangeBetDateRangeFilter';
import { endOfDay, startOfDay } from 'date-fns';
import useGetSpotTypes from './useGetSportTypes';

const ExchangeBetExportPage: FC<unknown> = () => {
  const intl = useIntl();
  const path = [
    { label: intl.formatMessage(messages.system), url: `./` },
    {
      label: intl.formatMessage(messages.betExport),
    },
  ];
  const brandId = useBrandId();
  const { data: sportTypes } = useGetSpotTypes();

  const [createdAtDateRange, setCreatedAtDateRange] = useState<DateRange<Date>>(
    [null, null],
  );
  const [settledAtDateRange, setSettledAtDateRange] = useState<DateRange<Date>>(
    [null, null],
  );
  const [filterSportType, setFilterSportType] = useState<string | null>(null);
  const { brands } = useSelector((state: RootState) => state.globalData);
  const [filterCreatedAtStartDate, filterCreatedAtEndDate] = createdAtDateRange;
  const [filterSettledStartDate, filterSettledEndDate] = settledAtDateRange;
  const {
    data: betRows,
    isLoading,
    isRefetching,
    refetch,
  } = useBetRows({
    shouldFetch: false,
    brandId,
    sportType: filterSportType,
    settledAtStart: filterSettledStartDate
      ? startOfDay(filterSettledStartDate)
      : null,
    settledAtEnd: filterSettledEndDate ? endOfDay(filterSettledEndDate) : null,
    createdAtStart: filterCreatedAtStartDate
      ? startOfDay(filterCreatedAtStartDate)
      : null,
    createdAtEnd: filterCreatedAtEndDate
      ? endOfDay(filterCreatedAtEndDate)
      : null,
  });

  const renderSportTypes = () => {
    return (
      <>
        <Box pr={2} py={{ xs: 2, md: 0 }}>
          <ExchangeBetSportTypeFilter
            selectedSportType={filterSportType}
            onChangeSportType={setFilterSportType}
            sportTypes={sportTypes || []}
          />
        </Box>

        <Box pr={2} height="100%" display={{ xs: 'none', md: 'block' }}>
          <Divider
            sx={{ height: '100%', background: 'transparent', margin: 0 }}
            orientation="vertical"
          />
        </Box>

        <ExchangeBetDateRangeFilter
          createdAtDateRange={createdAtDateRange}
          onChangeCreatedAtDateRange={setCreatedAtDateRange}
          settledAtDateRange={settledAtDateRange}
          onChangeSettledAtDateRange={setSettledAtDateRange}
        />

        <Button
          color="primary"
          size="medium"
          variant="contained"
          sx={{ minWidth: '100px' }}
          onClick={() => refetch()}
        >
          <Typography variant="body1" textTransform="uppercase">
            <FormattedMessage {...messages.getData} />
          </Typography>
        </Button>
      </>
    );
  };

  return (
    <DefaultLayout path={path}>
      <OverviewLayout
        headerProps={{
          name: intl.formatMessage(messages.betExport),
          brands,
          withBrandFilter: true,
          filteredKey: 'brandId',
          renderCustomFilter: renderSportTypes,
        }}
      >
        <Grid item xs={12}>
          <ExchangeBetExportList
            isLoading={isLoading || isRefetching}
            betRows={betRows || []}
            brandId={brandId}
          />
        </Grid>
      </OverviewLayout>
    </DefaultLayout>
  );
};

export default ExchangeBetExportPage;
