import { useState } from 'react';

const useShareApiKeyModal = (): typeof returnParams => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [shareApiKeyId, setShareApiKeyId] = useState<string | null>(null);

  const onClickShareApiKey = (apiKeyId: string) => {
    setShareApiKeyId(apiKeyId);
    setIsOpen(true);
  };

  const onClickCloseModal = () => {
    setShareApiKeyId(null);
    setIsOpen(false);
  };

  const returnParams = {
    isOpen,
    shareApiKeyId,
    onClickCloseModal,
    onClickShareApiKey,
  };

  return returnParams;
};

export default useShareApiKeyModal;
