import { Player, PlayerWithStats } from '../../../../types/supabase';
import { PLAYER_ROLE } from '../../../common/constants';

interface NormalizePlayerDataParams {
  player: PlayerWithStats;
}

export type NormalizedPlayerData = Player & {
  role: string;
  exposure: number;
  balance: number;
};

const normalizePlayerData = ({
  player,
}: NormalizePlayerDataParams): NormalizedPlayerData => {
  const { userStats } = player;

  const stats = userStats[0];

  const exposure = stats?.exposure || 0;

  const balance = stats?.balance || 0;

  return {
    ...player,
    role: PLAYER_ROLE.value,
    exposure,
    balance,
  };
};

export default normalizePlayerData;
