import { getFunctions, httpsCallable } from 'firebase/functions';
import { useCallback } from 'react';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../constants';

interface BrandDomainConfigParams {
  domain: string;
  brandId: string;
}

export type DNSConflict = {
  name: string;
  type: string;
  value: string;
};

export type BrandDomainConfig = (
  params: BrandDomainConfigParams,
) => Promise<DNSConflict[]>;

const useBrandDomainConfig = (): BrandDomainConfig => {
  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const getBrandDomainConfig = useCallback(
    async (params: BrandDomainConfigParams) => {
      const { domain, brandId } = params;

      const getDomainConfig = httpsCallable(
        functions,
        'back-brandDomain-getBrandDomainConfig',
      );

      const domainConfig = await getDomainConfig({
        domain,
        brandId,
      });

      const dnsConflicts = domainConfig.data as DNSConflict[];

      return dnsConflicts;
    },
    [functions],
  );

  return getBrandDomainConfig;
};

export default useBrandDomainConfig;
