import { FC } from 'react';
import { Grid } from '@miyagami-com/lsx-ui-components';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';

import UserStatus from '../../User/UserStatus';
import UserChangePassword from '../../User/UserChangePassword';
import PlayerPersonalInformation from '../PlayerPersonalInformation';

import messages from './messages';
import useVerifyPermissions from '../../../common/hooks/useVerifyPermissions';
import { ExtendedPlayer } from '../../../../types';
import PlayerNoteOverview from '../../PlayerNote/PlayerNoteOverview';

type QueryParams = {
  brandId: string;
  userId: string;
};

interface PlayerAccountPageProps {
  player?: ExtendedPlayer;
}

const PlayerAccountPage: FC<PlayerAccountPageProps> = ({ player }) => {
  const intl = useIntl();

  const { brandId, userId }: QueryParams = useParams();

  const { grantedPermissions, isSuspended, isBrandSuspended } =
    useVerifyPermissions([`brand/${brandId}/player/update`]);

  const isUpdatePermissionGranted = !!grantedPermissions?.player?.update;

  const isDisabled =
    !isUpdatePermissionGranted || isSuspended || isBrandSuspended;

  return (
    <Grid container pt={4} spacing={4}>
      <Grid item xs={12} md={6}>
        <PlayerPersonalInformation
          isDisabled={isDisabled}
          player={player}
          brandId={brandId}
          type="update"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <PlayerNoteOverview
          isDisabled={isDisabled}
          brandId={brandId}
          userId={userId}
        />
      </Grid>
      {player && (
        <Grid item xs={12} md={6}>
          <UserStatus
            isDisabled={isDisabled}
            brandId={brandId}
            user={player}
            translations={{
              label: intl.formatMessage(messages.userStatusLabel),
            }}
            type="player"
          />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <UserChangePassword
          isDisabled={isDisabled}
          userId={userId}
          brandId={brandId}
        />
      </Grid>
    </Grid>
  );
};

export default PlayerAccountPage;
