import { defineMessages } from 'react-intl';

export default defineMessages({
  playersAndAgents: {
    id: 'User.UserManualRegistration.playersAndAgents',
    defaultMessage: 'Players & Agents',
  },
  manualRegistration: {
    id: 'User.UserManualRegistration.manualRegistration',
    defaultMessage: 'Manual registration',
  },
  personal: {
    id: 'User.UserManualRegistration.personal',
    defaultMessage: 'Personal',
  },
  name: {
    id: 'User.UserManualRegistration.name',
    defaultMessage: 'Name',
  },
  surname: {
    id: 'User.UserManualRegistration.surname',
    defaultMessage: 'Surname',
  },
  authUsername: {
    id: 'User.UserManualRegistration.authUsername',
    defaultMessage: 'Username',
  },
  contact: {
    id: 'User.UserManualRegistration.contact',
    defaultMessage: 'Contact',
  },
  email: {
    id: 'User.UserManualRegistration.email',
    defaultMessage: 'Email address',
  },
  phoneNumber: {
    id: 'User.UserManualRegistration.phoneNumber',
    defaultMessage: 'Telephone',
  },
  address: {
    id: 'User.UserManualRegistration.address',
    defaultMessage: 'Address',
  },
  country: {
    id: 'User.UserManualRegistration.country',
    defaultMessage: 'Country',
  },
  city: {
    id: 'User.UserManualRegistration.city',
    defaultMessage: 'City',
  },
  street: {
    id: 'User.UserManualRegistration.street',
    defaultMessage: 'Street',
  },
  zipCode: {
    id: 'User.UserManualRegistration.zipCode',
    defaultMessage: 'ZIP code',
  },
  brandId: {
    id: 'User.UserManualRegistration.brandId',
    defaultMessage: 'Brand',
  },
  save: {
    id: 'User.UserManualRegistration.save',
    defaultMessage: 'Save',
  },
  role: {
    id: 'User.UserManualRegistration.role',
    defaultMessage: 'Role',
  },
  nextRole: {
    id: 'User.UserManualRegistration.nextRole',
    defaultMessage: 'Next role',
  },
  networkLabel: {
    id: 'User.UserManualRegistration.networkLabel',
    defaultMessage: 'Network',
  },
  errorCreatingUser: {
    id: 'User.UserManualRegistration.errorCreatingUser',
    defaultMessage: 'An error occurred while creating the user.',
  },
  successCreatingUser: {
    id: 'User.UserManualRegistration.successCreatingUser',
    defaultMessage: 'User has been created successfully.',
  },
  errorSelectedBrand: {
    id: 'User.UserManualRegistration.errorSelectedBrand',
    defaultMessage: 'Please select a brand.',
  },
  activateDirectlyTitle: {
    id: 'User.UserManualRegistration.activateDirectlyTitle',
    defaultMessage: 'Activate directly',
  },
  activate: {
    id: 'User.UserManualRegistration.activate',
    defaultMessage: 'Activate',
  },
  password: {
    id: 'User.UserManualRegistration.password',
    defaultMessage: 'Password',
  },
  infoParentId: {
    id: 'User.UserManualRegistration.infoParentId',
    defaultMessage: 'You did not assign {role}',
  },
  notFoundUsers: {
    id: 'User.UserManualRegistration.notFoundUsers',
    defaultMessage: 'Not found users',
  },
  userPT: {
    id: 'Network.NetworkTreeList.userPT',
    defaultMessage: 'PT',
  },
  minUserPT: {
    id: 'Network.NetworkTreeList.minUserPT',
    defaultMessage: 'Min. PT',
  },
  maxUserPT: {
    id: 'Network.NetworkTreeList.maxUserPT',
    defaultMessage: 'Max. PT',
  },
  extraPositionTaking: {
    id: 'Network.NetworkTreeList.extraPositionTaking',
    defaultMessage: 'Extra PT',
    description: 'Extra position taking',
  },
  extraPositionTakingCheckbox: {
    id: 'Network.NetworkTreeList.extraPositionTaking',
    defaultMessage: 'Extra PT',
    description: 'Extra position taking',
  },
  userCommission: {
    id: 'Network.NetworkTreeList.userCommission',
    defaultMessage: 'Com.',
  },
  commissionAndPT: {
    id: 'Network.NetworkTreeList.commissionAndPT',
    defaultMessage: 'Position taking & Commission',
  },
});
