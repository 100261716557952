import { GridCellParams, GridColumns } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { BackOfficeUserStatus, RootState, UserRole } from '../../../../types';
import {
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
  USER_ROLES,
} from '../../../common/constants';
import DataGridTable from '../../Unknown/DataGridTable';
import IpAddressCell from '../../Unknown/IpAddressCell';
import StatusCell from '../../Unknown/StatusCell';
import messages from './messages';
import sortedBackofficeUsersData, {
  NormalizedBackofficeUser,
} from './sortedBackofficeUsersData';
import getAllBrandUsers from '../../../common/getAllBrandUsers';
import checkIsUserInBrand from '../../../common/checkIsUserInBrand';
import ActionsBox from '../../Unknown/ActionsBox';
import useBrandId from '../../../common/hooks/useBrandId';
import useUserStatuses from '../../../common/hooks/useUserStatuses';
import { BackofficeUserWhitelist } from '../../../../types/supabase';

type WhiteListProps = {
  ipAddressesRows: NormalizedBackofficeUser[];
  onIpAddressesRowsWithoutFilterChange: CallableFunction;
  setSelectedRow: (row: NormalizedBackofficeUser) => void;
};

const WhiteList: React.FC<WhiteListProps> = ({
  ipAddressesRows,
  onIpAddressesRowsWithoutFilterChange,
  setSelectedRow,
}) => {
  const [pageSize, setPageSize] = useState(DEFAULT_ROWS_PER_PAGE_OPTIONS[5]);
  const intl = useIntl();
  const userStatuses = useUserStatuses();

  const brandId = useBrandId();

  const { users } = useSelector((state: RootState) => state.globalData);
  const { permissionMap } = useSelector((state: RootState) => state.user);

  const allUsers = useMemo(() => {
    const brandUsers = getAllBrandUsers({ brandId, users });

    const filteredUsers = brandUsers.filter((user) =>
      checkIsUserInBrand({ brandId, user }),
    );

    return filteredUsers;
  }, [brandId, users]);

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  useEffect(() => {
    if (!brandId) return;

    const sortedRows = sortedBackofficeUsersData(allUsers, brandId);

    onIpAddressesRowsWithoutFilterChange(sortedRows);
  }, [onIpAddressesRowsWithoutFilterChange, brandId, users, allUsers]);

  const columns: GridColumns = [
    { field: 'id', headerName: 'ID', hide: true, flex: 0.55 },
    {
      field: 'firstName',
      headerName: intl.formatMessage(messages.firstName),
      editable: false,
      sortable: true,
      filterable: false,
      flex: 0.75,
    },
    {
      field: 'surname',
      headerName: intl.formatMessage(messages.surname),
      editable: false,
      sortable: true,
      filterable: false,
      flex: 0.75,
    },
    {
      field: 'email',
      headerName: intl.formatMessage(messages.email),
      editable: false,
      sortable: true,
      filterable: false,
      flex: 0.75,
    },
    {
      field: 'telephone',
      headerName: intl.formatMessage(messages.telephone),
      editable: false,
      sortable: true,
      filterable: false,
      flex: 0.75,
    },
    {
      field: 'type',
      headerName: intl.formatMessage(messages.type),
      editable: false,
      sortable: true,
      filterable: false,
      flex: 0.75,
      valueFormatter: (params) => {
        const role = params.value?.toString() as UserRole;

        const userRole = USER_ROLES.find(({ value }) => value === role);

        return userRole?.label;
      },
    },
    {
      field: 'status',
      headerName: intl.formatMessage(messages.status),
      editable: false,
      sortable: true,
      filterable: false,
      flex: 0.75,
      renderCell: (params: GridCellParams) => {
        const status = params.value as BackOfficeUserStatus;

        const userStatus = userStatuses[status];
        if (!userStatus) return null;
        return <StatusCell {...userStatus} />;
      },
    },
    {
      field: 'whitelist',
      headerName: intl.formatMessage(messages.ip),
      editable: false,
      sortable: true,
      filterable: false,
      groupable: false,
      flex: 0.75,
      minWidth: 120,
      renderCell: (props: GridCellParams) => {
        if (!props.row) return null;

        const whitelistItems = props.value as BackofficeUserWhitelist[];
        if (!whitelistItems?.length) return null;

        return (
          <>
            {whitelistItems.map((whitelistItem) => (
              <IpAddressCell
                key={whitelistItem.id}
                ipAddress={whitelistItem.ip_address}
                countryCode={whitelistItem.country_code}
              />
            ))}
          </>
        );
      },
    },
    {
      field: 'actions',
      headerName: intl.formatMessage(messages.actions),
      editable: false,
      groupable: false,
      sortable: true,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
      flex: 0.5,
      renderCell: (props: GridCellParams<NormalizedBackofficeUser>) => {
        if (!props.row || !props.row?.role_id) return null;

        const userRoleValue = props.row?.role_id as Exclude<
          UserRole,
          'systemAdmin'
        >;

        const isFirewallEnabled = props.row.isFirewallEnabled;

        const rowBrandId: string = props.row.brandId;

        const isUpdatePermissionGranted =
          !!permissionMap[rowBrandId][userRoleValue]?.update;

        const actions = [];

        if (isUpdatePermissionGranted && isFirewallEnabled) {
          const updateAction = {
            label: intl.formatMessage(messages.edit),
            buttonProps: {
              onClick: () => setSelectedRow(props.row),
            },
          };

          actions.push(updateAction);
        }

        return <ActionsBox actions={actions} />;
      },
    },
  ];

  return (
    <DataGridTable
      columns={columns}
      rows={ipAddressesRows}
      tableKey="white-list"
      loading={false}
      pageSize={pageSize}
      onPageSizeChange={onPageSizeChange}
      rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
      disableSelectionOnClick
      autoHeight
      rowHeight={35}
      headerHeight={35}
    />
  );
};

export default WhiteList;
