import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import capitalizeFirstLetter from '../../common/capitalizeFirstLetter';

interface ColumnProps {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  setBetId: (value: any) => void;
  setMarketName: (value: string) => void;
  setSelectionName: (value: string) => void;
  setEventOpenDateTime: (value: string) => void;
  setBetType: (value: string) => void;
  setModalOpen: (value: boolean) => void;
}

const mapRiskLevelColumn = (riskLevel: string) => {
  if (riskLevel === 'medium') {
    return (
      <Typography variant={'h3'} color="orange">
        MEDIUM
      </Typography>
    );
  }
  if (riskLevel === 'high') {
    return (
      <Typography variant={'h3'} color="red">
        HIGH
      </Typography>
    );
  }
  return (
    <Typography variant={'h3'} color="green">
      LOW
    </Typography>
  );
};

const useColumns = (props: ColumnProps): GridColumns => {
  const {
    setModalOpen,
    setBetId,
    setMarketName,
    setSelectionName,
    setEventOpenDateTime,
    setBetType,
  } = props;

  return [
    {
      field: 'id',
      headerName: 'id',
      hide: true,
      editable: false,
      filterable: false,
    },
    {
      field: 'exchange_bets_ref',
      headerName: 'Betfair bet ref',
      editable: false,
      filterable: false,
      flex: 0.6,
      renderCell: ({ row }) => {
        return row.exchange_bets?.betfair_exchange_bet_id;
      },
    },
    {
      field: 'risk_level',
      headerName: 'Risk level',
      editable: false,
      filterable: false,
      flex: 0.6,
      renderCell: ({ row }) => {
        return mapRiskLevelColumn(row.risk_level);
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      editable: false,
      filterable: false,
      flex: 0.6,
      renderCell: ({ row }) => {
        return row.exchange_bets
          ? capitalizeFirstLetter(row.exchange_bets.side)
          : '';
      },
    },
    {
      field: 'matched_price',
      headerName: 'Matched price',
      editable: false,
      filterable: false,
      flex: 0.6,
      renderCell: ({ row }) => {
        return row?.matched_price;
      },
    },
    {
      field: 'price_difference_ratio',
      headerName: 'Price difference ratio',
      editable: false,
      filterable: false,
      flex: 0.6,
      renderCell: ({ row }) => {
        return `${Number(row.price_difference_ratio * 100).toFixed(2)}%`;
      },
    },
    {
      field: 'price_difference_percentage',
      headerName: 'Price difference percentage',
      editable: false,
      filterable: false,
      flex: 0.6,
      renderCell: ({ row }) => {
        return row.price_difference_percentage
          ? `${parseFloat(row.price_difference_percentage).toFixed(2)}%`
          : '0';
      },
    },
    {
      field: 'bet_placed_time',
      headerName: 'Placed time',
      editable: false,
      filterable: false,
      flex: 0.6,
      renderCell: ({ row }) => {
        return row.exchange_bets?.created_at
          ? new Date(row.exchange_bets.created_at).toISOString()
          : '';
      },
    },
    {
      field: 'bet_matched_time',
      headerName: 'Matched time',
      editable: false,
      filterable: false,
      flex: 0.6,
      renderCell: ({ row }) => {
        return row.exchange_bets?.matched_time
          ? new Date(row.exchange_bets.matched_time).toISOString()
          : '';
      },
    },
    {
      field: 'exchange_bets_player',
      headerName: 'Player',
      editable: false,
      filterable: false,
      flex: 0.6,
      renderCell: ({ row }) => {
        const { brand_id = '', player_id = '' } = row?.exchange_bets || {};
        return (
          <Link to={`/b/${brand_id}/player-overview/${player_id}`}>
            {row?.player_username}
          </Link>
        );
      },
    },
    {
      field: 'brand_id',
      headerName: 'Brand',
      editable: false,
      filterable: false,
      flex: 0.6,
      renderCell: ({ row }) => {
        const brand_id = row?.exchange_bets?.brand_id || '';
        return <Link to={`/b/${brand_id}/overview`}>{brand_id}</Link>;
      },
    },
    {
      field: 'match_status',
      headerName: 'Market status',
      editable: false,
      filterable: false,
      flex: 0.6,
      renderCell: ({ row }) => {
        if (row?.exchange_bets?.is_closed) {
          return 'Closed';
        } else if (
          new Date(row?.exchange_bets?.event_open_date || '').getTime() >
          Date.now()
        ) {
          return 'Upcoming';
        } else {
          return 'In play';
        }
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      editable: false,
      filterable: false,
      flex: 0.6,
      renderCell: ({ row }) => {
        return (
          <LoadingButton
            onClick={() => {
              const { bet_id: betId, is_in_play: isInPlay } = row;
              const {
                market_name: marketName,
                selection_name: selectionName,
                event_open_date: eventOpenDate,
              } = row?.exchange_bets;

              setBetId(betId);
              setMarketName(marketName);
              setSelectionName(selectionName);
              setEventOpenDateTime(eventOpenDate);
              setBetType(isInPlay ? 'Live bet' : 'Pre-match bet');
              setModalOpen(true);
            }}
            type="submit"
            variant="contained"
            color="primary"
          >
            View Details
          </LoadingButton>
        );
      },
    },
  ];
};

export default useColumns;
