import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  paperRoot: {
    borderRadius: 0,
    width: '100%',
  },
}));

export default useStyles;
