import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  input: {
    '& >input': {
      fontSize: '12px',
    },
  },
}));

export default useStyles;
