import React, { useState, useMemo } from 'react';
import { Box } from '@miyagami-com/lsx-ui-components';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';

import { BackOfficeUser, RootState } from '../../../../types';

import { DEFAULT_DATE_FORMAT } from '../../../common/constants';

import DefaultLayout from '../../Unknown/DefaultLayout';
import OverviewLayout from '../../Unknown/OverviewLayout';

import BackofficeUserAddition from '../BackofficeUserAddition';
import BackofficeUserList from '../BackofficeUserList';

import messages from './messages';
import sortedUserList from './sortedUserList';
import getFromToNumbers from '../../../common/getFromToNumbers';
import useBrandId from '../../../common/hooks/useBrandId';
import useVerifyPermissions from '../../../common/hooks/useVerifyPermissions';

const BackofficeUserOverviewPage: React.FC = () => {
  const intl = useIntl();

  const brandId = useBrandId();

  const { grantedPermissions, isSuspended, isBrandSuspended } =
    useVerifyPermissions([
      `brand/${brandId}/finance/create`,
      `brand/${brandId}/support/create`,
      `brand/${brandId}/owner/create`,
      `brand/${brandId}/marketing/create`,
    ]);

  const {
    users: { byBrandId: users },
    brands,
  } = useSelector((state: RootState) => state.globalData);

  const backofficeUsers = useMemo(
    () => users[brandId]?.backofficeUsers || [],
    [brandId, users],
  );

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const [backofficeUsersRows, setBackofficeUsersRows] = useState<
    BackOfficeUser[]
  >([]);

  const defaultUsers = useMemo(() => {
    const actualUsers = backofficeUsers?.filter(({ created_at }) => created_at);
    const usersList = sortedUserList(brands, brandId, actualUsers);

    setBackofficeUsersRows(usersList);
    return usersList;
  }, [backofficeUsers, brandId, brands]);

  const { from, to } = useMemo(
    () => getFromToNumbers({ list: backofficeUsersRows }),
    [backofficeUsersRows],
  );

  const isCreatePermissionGranted =
    !!grantedPermissions?.finance?.create ||
    !!grantedPermissions?.support?.create ||
    !!grantedPermissions?.owner?.create ||
    !!grantedPermissions?.marketing?.create;

  const onClickAddButton = isCreatePermissionGranted
    ? () => setIsOpenModal(true)
    : undefined;

  const onCloseModal = () => {
    setIsOpenModal(false);
  };

  const path = [
    { label: intl.formatMessage(messages.system) },
    { label: intl.formatMessage(messages.backofficeUsers) },
  ];

  const isDisabled = isSuspended || isBrandSuspended;

  return (
    <DefaultLayout path={path}>
      <OverviewLayout
        headerProps={{
          name: intl.formatMessage(messages.backofficeUsers),
          from: format(from, DEFAULT_DATE_FORMAT),
          to: format(to, DEFAULT_DATE_FORMAT),
          brands,
          setRows: setBackofficeUsersRows,
          defaultRows: defaultUsers,
          onClickAdd: onClickAddButton,
          tooltipAdd: intl.formatMessage(messages.addUser),
          withBrandFilter: true,
          filteredKey: 'brandId',
          disabled: isDisabled,
          rows: backofficeUsersRows,
        }}
      >
        <BackofficeUserList rows={backofficeUsersRows} />
      </OverviewLayout>
      <Box pt={3} />
      <BackofficeUserAddition
        isOpen={isOpenModal}
        brands={brands}
        onClose={onCloseModal}
      />
    </DefaultLayout>
  );
};

export default BackofficeUserOverviewPage;
