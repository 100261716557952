import { useMemo } from 'react';
import { colors } from '../theme';
import messages from '../messages';
import { useIntl } from 'react-intl';
import { ExchangeBetStatus } from '../../../types';

type StatusMapParams = {
  label: string;
  color: string;
};

const useExchangeBetStatusMap = (): Record<
  ExchangeBetStatus,
  StatusMapParams
> => {
  const intl = useIntl();

  const exchangeBetStatusMap: Record<ExchangeBetStatus, StatusMapParams> =
    useMemo(() => {
      return {
        won: {
          label: intl.formatMessage(messages.won),
          color: colors.successMain,
        },
        loss: {
          label: intl.formatMessage(messages.loss),
          color: colors.errorMain,
        },
        voided: {
          label: intl.formatMessage(messages.voidedStatus),
          color: colors.grey300,
        },
      };
    }, [intl]);

  return exchangeBetStatusMap;
};

export default useExchangeBetStatusMap;
