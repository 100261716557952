import { GridRowData } from '@mui/x-data-grid';
import { Brand, BackOfficeUser } from '../../../../types';

const sortBrandData = (
  brands: Brand[] | undefined,
  brandOwners: BackOfficeUser[],
): GridRowData[] => {
  const brandsData = brands?.map((brand) => {
    const userEmail = brandOwners?.find(
      (user) => user.id === brand.owner_id,
    )?.email;

    const createdAt = brand?.created_at;
    const updatedAt = brand?.updated_at;

    return {
      id: brand.id,
      name: brand.name,
      admin: userEmail || '',
      domainsCount: 0,
      pl: 0,
      status: brand.status,
      commission: brand.commission,
      directPlayerComission: brand.is_direct_player_enable
        ? brand.direct_player_commission
        : '-',
      netPL: 0,
      createdAt,
      updatedAt,
    };
  });

  if (brandsData) {
    return brandsData;
  }

  return [];
};

export default sortBrandData;
