import { defineMessages } from 'react-intl';

export default defineMessages({
  domain: {
    id: 'BrandDomain.BrandDomainList.domain',
    defaultMessage: 'Domain',
  },
  id: {
    id: 'BrandDomain.BrandDomainList.id',
    defaultMessage: 'ID',
  },
  date: {
    id: 'BrandDomain.BrandDomainList.date',
    defaultMessage: 'Date',
  },
  status: {
    id: 'BrandDomain.BrandDomainList.status',
    defaultMessage: 'Status',
  },
  type: {
    id: 'BrandDomain.BrandDomainList.type',
    defaultMessage: 'Type',
  },
});
