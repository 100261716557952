import {
  getAuth,
  PhoneAuthProvider,
  RecaptchaVerifier,
  MultiFactorResolver,
} from 'firebase/auth';

type HandleIfMfaRequiredErrorProps = {
  setWithMFA: (value: boolean) => void;
  setResolver: (
    value: React.SetStateAction<MultiFactorResolver | null>,
  ) => void;
  resolver: MultiFactorResolver | null;
  recaptcha: HTMLDivElement;
  setVerificationId: (value: React.SetStateAction<string | null>) => void;
  setStep: (value: React.SetStateAction<number>) => void;
  setSubmitting: (isSubmitting: boolean) => void;
};

const handleIfErrorCodeEqualsMfaRequired = async ({
  setWithMFA,
  setResolver,
  resolver,
  recaptcha,
  setVerificationId,
  setStep,
  setSubmitting,
}: HandleIfMfaRequiredErrorProps): Promise<void> => {
  if (!resolver) throw new Error('Auth failed');
  setWithMFA(true);
  setResolver(resolver);
  const phoneOpts = {
    multiFactorHint: resolver.hints[0],
    session: resolver.session,
  };
  const auth = getAuth();
  const phoneAuthProvider = new PhoneAuthProvider(auth);

  const recaptchaVerifier = new RecaptchaVerifier(auth, recaptcha, {
    size: 'invisible',
  });

  const verId = await phoneAuthProvider.verifyPhoneNumber(
    phoneOpts,
    recaptchaVerifier,
  );

  setVerificationId(verId);

  setStep(1);
  setSubmitting(false);
};

export default handleIfErrorCodeEqualsMfaRequired;
