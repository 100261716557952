import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { UIContext } from '@miyagami-com/lsx-ui-components';
import { DataGridProps } from '@mui/x-data-grid';
import { useQuery } from 'react-query';
import { useIntl } from 'react-intl';
import { GridRowData } from '@mui/x-data-grid';
import commonMessages from '../../../common/messages';
import { useFirebaseApp } from 'reactfire';
import {
  DEFAULT_REGION,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
} from '../../../common/constants';
import DefaultLayout from '../../Unknown/DefaultLayout';
import OverviewLayout from '../../Unknown/OverviewLayout';
import LanguageAdditionDialog from '../LanguageAdditionDialog';
import LanguageList from '../LanguageList';
import getDefaultLanguageId from './getDefaultLanguageId';
import getLanguages from './getLanguages';
import messages from './messages';
import { getFunctions, httpsCallable } from 'firebase/functions';

const LanguageListPage: FC = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [languages, setLanguages] = useState<GridRowData[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const intl = useIntl();
  const { setAlert } = useContext(UIContext);
  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);
  const updateLanguageStatus = httpsCallable(
    functions,
    'back-language-updateLanguageStatus',
  );

  const path = [
    {
      label: intl.formatMessage(messages.cms),
    },
    {
      label: intl.formatMessage(messages.internalization),
    },
  ];

  const {
    data: languagesResponse,
    isFetching,
    isError,
    refetch: refetchLanguages,
  } = useQuery(['getSupabaseLanguages'], () => getLanguages());

  const openAddLanguageDialog = () => {
    setIsDialogOpen(true);
  };

  const closeAddLanguageDialog = () => {
    setIsDialogOpen(false);
  };

  const {
    data: defaultLanguageId,
    isFetching: isDefaultLanguageIdFetching,
    isError: isDefaultLanguageIdError,
  } = useQuery(['getDefaultLanguageId'], () => getDefaultLanguageId());

  const defaultRows = useMemo(() => {
    if (!defaultLanguageId || !languagesResponse?.data) return [];
    return (
      languagesResponse.data.map((row) => ({
        ...row,
        isDefault: row.id === defaultLanguageId,
      })) || []
    );
  }, [defaultLanguageId, languagesResponse?.data]);

  const onUpdateStatus = useCallback(
    async ({ id, status }) => {
      try {
        setIsUpdating(true);
        await updateLanguageStatus({
          id,
          status,
        });
        refetchLanguages();
        setAlert({
          message: intl.formatMessage(messages.successfullyUpdated),
          severity: 'success',
          show: true,
        });
      } catch (error) {
        setAlert({
          message: intl.formatMessage(messages.updateError),
          severity: 'error',
          show: true,
        });
      } finally {
        setIsUpdating(false);
      }
    },
    [intl, refetchLanguages, setAlert, updateLanguageStatus],
  );

  useEffect(() => {
    if (isError || isDefaultLanguageIdError) {
      setAlert({
        message: intl.formatMessage(commonMessages.fetchErrorMessage),
        severity: 'error',
        show: true,
      });
    }
  }, [intl, isDefaultLanguageIdError, isError, setAlert]);

  const languageIds = useMemo(() => {
    return languages.map(({ id }) => id);
  }, [languages]);

  const dataGridProps = useMemo<Omit<DataGridProps, 'columns'>>(() => {
    return {
      rows: languages,
      loading: isFetching || isDefaultLanguageIdFetching || isUpdating,
      rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
      rowCount: languages.length,
      initialState: {
        sorting: {
          sortModel: [{ field: 'name', sort: 'asc' }],
        },
      },
    };
  }, [isFetching, isUpdating, isDefaultLanguageIdFetching, languages]);

  return (
    <DefaultLayout path={path}>
      <OverviewLayout
        headerProps={{
          name: intl.formatMessage(messages.internalization),
          defaultRows: defaultRows,
          setRows: setLanguages,
          onClickAdd: openAddLanguageDialog,
          tooltipAdd: intl.formatMessage(messages.addLanguage),
        }}
      >
        <LanguageList
          dataGridProps={dataGridProps}
          onUpdateStatus={onUpdateStatus}
        />
      </OverviewLayout>
      <LanguageAdditionDialog
        open={isDialogOpen}
        languageIds={languageIds}
        onAddLanguage={refetchLanguages}
        onClose={closeAddLanguageDialog}
      />
    </DefaultLayout>
  );
};

export default LanguageListPage;
