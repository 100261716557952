import React, { useContext } from 'react';
import {
  Box,
  CollapseBox,
  Divider,
  Typography,
  LoadingButton,
  UIContext,
} from '@miyagami-com/lsx-ui-components';
import { Formik, Form, FormikHelpers } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFirebaseApp } from 'reactfire';

import { DEFAULT_REGION, IMAGE_TYPES } from '../../../common/constants';

import { DropzoneFile } from '../../Unknown/ImageUpload';
import ImageUploadWithPreview from '../../Unknown/ImageUploadWithPreview';

import messages from './messages';
import validationSchema from './validationSchema';
import useUploadFile from '../../../common/hooks/useUploadFile';
import useBrandTheme from '../../../common/hooks/useBrandTheme';
import LoadingBox from '../../Unknown/LoadingBox';
import usePlatformForceCache from '../../../common/hooks/usePlatformForceCache';
import { getFunctions, httpsCallable } from 'firebase/functions';

type ThemeLogoFormValues = {
  main_logo_link?: DropzoneFile;
  secondary_logo_link?: DropzoneFile;
};

const initialValues: ThemeLogoFormValues = {
  main_logo_link: undefined,
  secondary_logo_link: undefined,
};

type ImageUploadValues = {
  label: string;
  name: keyof ThemeLogoFormValues;
  translations?: {
    placeholder?: string;
    defaultValue?: string;
  };
};

interface ThemeLogoProps {
  brandId: string;
  isDisabled: boolean;
}

const ThemeLogo: React.FC<ThemeLogoProps> = ({ brandId, isDisabled }) => {
  const intl = useIntl();

  const { data: brandTheme, isLoading, refetch } = useBrandTheme({ brandId });

  const { setAlert } = useContext(UIContext);

  const uploadFile = useUploadFile();

  const forceCache = usePlatformForceCache({ brandId });

  const firebase = useFirebaseApp();

  const functions = getFunctions(firebase, DEFAULT_REGION);

  const defaultTranslations = {
    placeholder: intl.formatMessage(messages.dragImageToUpload),
    defaultValue: intl.formatMessage(messages.dragImageToUpload),
  };

  const imageUploadsData: ImageUploadValues[] = [
    {
      label: intl.formatMessage(messages.mainLogo),
      name: 'main_logo_link',
      translations: defaultTranslations,
    },
    {
      label: intl.formatMessage(messages.secondaryLogo),
      name: 'secondary_logo_link',
      translations: defaultTranslations,
    },
  ];

  const onSubmit = async (
    { main_logo_link, secondary_logo_link }: ThemeLogoFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ThemeLogoFormValues>,
  ) => {
    setSubmitting(true);
    if (!main_logo_link && !secondary_logo_link)
      throw new Error(intl.formatMessage(messages.imageNotFound));

    const uploadLogo = httpsCallable(functions, 'back-theme-uploadThemeLogo');

    try {
      if (!brandTheme) {
        setAlert({
          show: true,
          severity: 'info',
          message: intl.formatMessage(messages.errorBrand),
        });
        return;
      }

      const uploadParams = {
        brandId: brandTheme.id,
        mainLogo: brandTheme.main_logo_link,
        secondaryLogo: brandTheme.secondary_logo_link,
      };

      if (main_logo_link) {
        const mainLogoUrl = await uploadFile(
          `brands/${brandTheme.id}/logos/main/mainLogo`,
          main_logo_link,
          {
            contentType: main_logo_link.type,
          },
        );

        uploadParams.mainLogo = mainLogoUrl;
      }

      if (secondary_logo_link) {
        const secondaryLogoUrl = await uploadFile(
          `brands/${brandTheme.id}/logos/main/secondaryLogoUrl`,
          secondary_logo_link,
          {
            contentType: secondary_logo_link.type,
          },
        );

        uploadParams.secondaryLogo = secondaryLogoUrl;
      }

      await uploadLogo(uploadParams);

      await forceCache([{ type: 'brandContext' }]);

      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.successUpload),
      });
    } catch (error) {
      setAlert({
        show: true,
        severity: 'warning',
        message: intl.formatMessage(messages.failedUpload),
      });
    } finally {
      setSubmitting(false);
      resetForm();
      refetch();
    }
  };

  if (isLoading) return <LoadingBox />;

  return (
    <CollapseBox
      label={intl.formatMessage(messages.themeLogo)}
      defaultValue={true}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          setFieldValue,
          setTouched,
          errors,
          touched,
          isSubmitting,
          isValid,
        }) => {
          const handleUploadFile = (files: DropzoneFile[], name: string) => {
            setFieldValue(name, files[0]);

            setTouched({ [name]: true });
          };

          const touchedKeys = Object.keys(touched);

          const isTouched = touchedKeys.length !== 0;

          const error = (name: keyof ThemeLogoFormValues) =>
            Boolean(errors[name] && touched[name]);

          return (
            <Form>
              {imageUploadsData.map(({ label, name, translations }) => {
                return (
                  <Box key={name}>
                    <Box p={3}>
                      <Typography variant="h6">{label}</Typography>
                      <ImageUploadWithPreview
                        imageSrc={values?.[name]?.preview || brandTheme?.[name]}
                        imageUploadProps={{
                          dropzoneProps: {
                            accept: IMAGE_TYPES,
                          },
                          textFieldProps: {
                            name,
                            translations: { ...translations, label },
                            value: values?.[name]?.name,
                            onChange: handleUploadFile,
                            multiline: true,
                            fullWidth: true,
                            rows: 3,
                            error: error(name),
                            disabled: isDisabled,
                          },
                        }}
                      />
                    </Box>
                    <Divider />
                  </Box>
                );
              })}
              <Box m={3}>
                <LoadingButton
                  disabled={isDisabled || !isTouched || !isValid}
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={isSubmitting}
                >
                  <FormattedMessage {...messages.save} />
                </LoadingButton>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </CollapseBox>
  );
};

export default ThemeLogo;
