import { defineMessages } from 'react-intl';

export default defineMessages({
  id: {
    id: 'Widget.WidgetOverviewPage.id',
    defaultMessage: 'ID',
  },
  createdAt: {
    id: 'Widget.WidgetOverviewPage.createdAt',
    defaultMessage: 'Created at',
  },
  position: {
    id: 'Widget.WidgetOverviewPage.position',
    defaultMessage: 'Position',
  },
  title: {
    id: 'Widget.WidgetOverviewPage.Title',
    defaultMessage: 'Title',
  },
  brand: {
    id: 'Widget.WidgetOverviewPage.brand',
    defaultMessage: 'Brand',
  },
  status: {
    id: 'Widget.WidgetOverviewPage.status',
    defaultMessage: 'Status',
  },
  actions: {
    id: 'Widget.WidgetOverviewPage.actions',
    defaultMessage: 'Actions',
  },
  view: {
    id: 'Widget.WidgetOverviewPage.view',
    defaultMessage: 'View',
  },
  edit: {
    id: 'Widget.WidgetOverviewPage.edit',
    defaultMessage: 'Edit',
  },
  cms: {
    id: 'Widget.WidgetOverviewPage.cms',
    defaultMessage: 'CMS',
  },
  widgets: {
    id: 'Widget.WidgetOverviewPage.widgets',
    defaultMessage: 'Widgets',
  },
  deleteAction: {
    id: 'Widget.WidgetOverviewPage.deleteAction',
    defaultMessage: 'Delete',
  },
  successDelete: {
    id: 'Widget.WidgetDetails.successDelete',
    defaultMessage: 'Widget was successfully deleted!',
  },
  errorDelete: {
    id: 'Widget.WidgetDetails.errorDelete',
    defaultMessage: 'Error delete widget',
  },
});
