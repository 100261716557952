import { defineMessages } from 'react-intl';

export default defineMessages({
  login: {
    id: 'BackofficeUser.LoginPage.login',
    defaultMessage: 'Login',
  },
  email: {
    id: 'BackofficeUser.LoginPage.email',
    defaultMessage: 'Email',
  },
  password: {
    id: 'BackofficeUser.LoginPage.password',
    defaultMessage: 'Password',
  },
  forgotPassword: {
    id: 'BackofficeUser.LoginPage.forgotPassword?',
    defaultMessage: 'Forgot password?',
  },
  authFailed: {
    id: 'BackofficeUser.LoginPage.authFailed',
    defaultMessage: 'Auth failed, please contact with administrator',
  },
  authRoleFailed: {
    id: 'BackofficeUser.LoginPage.authRoleFailed',
    defaultMessage:
      'You cannot log in because your user has an old role format, please contact support to resolve this issue.',
  },
  playerAuthFailed: {
    id: 'BackofficeUser.LoginPage.playerAuthFailed',
    defaultMessage:
      'This user does not exist in the system, or you entered the wrong username or password.',
  },
});
