import { defineMessages } from 'react-intl';

export default defineMessages({
  profitLoss: {
    id: 'ProfitLoss.ProfitLossReportDashboardSection.profitLoss',
    defaultMessage: 'Profit & Loss',
  },
  from: {
    id: 'ProfitLoss.ProfitLossReportDashboardSection.from',
    defaultMessage: 'From',
  },
  to: {
    id: 'ProfitLoss.ProfitLossReportDashboardSection.to',
    defaultMessage: 'To',
  },
  period: {
    id: 'ProfitLoss.ProfitLossReportDashboardSection.period',
    defaultMessage: 'Period',
  },
  today: {
    id: 'ProfitLoss.ProfitLossReportDashboardSection.today',
    defaultMessage: 'Today',
  },
  yesterday: {
    id: 'ProfitLoss.ProfitLossReportDashboardSection.yesterday',
    defaultMessage: 'Yesterday',
  },
  lastWeek: {
    id: 'ProfitLoss.ProfitLossReportDashboardSection.lastWeek',
    defaultMessage: 'Last week',
  },
  lastMonth: {
    id: 'ProfitLoss.ProfitLossReportDashboardSection.lastMonth',
    defaultMessage: 'Last month',
  },
  lastYear: {
    id: 'ProfitLoss.ProfitLossReportDashboardSection.lastYear',
    defaultMessage: 'Last year',
  },
  currentYear: {
    id: 'ProfitLoss.ProfitLossReportDashboardSection.currentYear',
    defaultMessage: 'Current year',
  },
});
