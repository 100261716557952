import {
  Box,
  CollapseBox,
  Grid,
  ModalCenter,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@miyagami-com/lsx-ui-components';
import ClosableBox from '../Unknown/ClosableBox';
import { useCallback, useEffect, useState } from 'react';
import useSupabase from '../../common/hooks/useSupabase';
import format from 'date-fns/format';
import { DEFAULT_DATE_TIME_FORMAT } from '../../common/constants';
import AdditionalDetailBox, {
  AdditionalDetailBoxProps,
} from './AdditionalDetailBox';

interface BetSnapshot {
  ex: {
    availableToBack: { price: number; size: number }[];
    availableToLay: { price: number; size: number }[];
  };
  totalMatched: number;
  selectionId: number;
}

interface AdditionalDetails {
  sportName: string;
  eventName: string;
  competitionName: string | null;
}

interface BetSnapshotModalProps {
  isOpen: boolean;
  onClose: () => void;
  betId: number;
  marketName: string;
  selectionName: string;
  eventOpenDateTime: string;
  betType: string;
}

const BetSnapshotModal = (props: BetSnapshotModalProps): JSX.Element | null => {
  const {
    isOpen,
    onClose,
    betId,
    marketName,
    selectionName,
    eventOpenDateTime,
    betType,
  } = props;
  const [snapshotData, setSnapshotData] = useState<BetSnapshot | null>(null);
  const [additionalDetails, setAdditionalDetails] =
    useState<AdditionalDetails | null>(null);

  const supabase = useSupabase();

  const fetchBetSnapshotAsync = useCallback(async () => {
    const { data: betSnapshotData, error } = await supabase
      .from('bet_snapshots')
      .select('*')
      .eq('bet_id', betId)
      .single();

    if (error) {
      return null;
    }

    if (betSnapshotData) {
      const { selection_id: selectionId } = betSnapshotData;
      const { bet_runners } = betSnapshotData;
      if (!bet_runners) return null;
      // @ts-expect-error: BetRunners is an array
      const betRunners = bet_runners.find(
        (runner: BetSnapshot) => runner.selectionId === selectionId,
      );

      setSnapshotData(betRunners as BetSnapshot);
    }
  }, [betId, supabase]);

  const fetchBetAdditionalDetailsAsync = useCallback(async () => {
    const { data: betDetailsData, error } = await supabase
      .from('betfair_exchange_bets')
      .select('event_type_name, event_name, competition_name')
      .eq('id', betId)
      .single();

    if (error) {
      return null;
    }

    if (betDetailsData) {
      const {
        event_type_name: sportName,
        event_name: eventName,
        competition_name: competitionName,
      } = betDetailsData;
      setAdditionalDetails({ sportName, eventName, competitionName });
    }
  }, [betId, supabase]);

  useEffect(() => {
    fetchBetSnapshotAsync();
    fetchBetAdditionalDetailsAsync();
  }, [fetchBetSnapshotAsync, fetchBetAdditionalDetailsAsync]);

  if (!snapshotData || !additionalDetails) return null;

  const { ex, totalMatched } = snapshotData;
  if (!ex) return null;

  const { sportName, eventName, competitionName } = additionalDetails;

  const formattedSelectionName = selectionName.replace(/^\d+\.\s*/, '');
  const formattedEventDateTime = format(
    new Date(eventOpenDateTime),
    DEFAULT_DATE_TIME_FORMAT,
  );
  const formattedEventName = `${eventName} ${
    competitionName ? `(${competitionName})` : ''
  }`;

  const additionalDetailsMap: AdditionalDetailBoxProps[] = [
    { title: 'Sport', value: sportName },
    { title: 'Event', value: formattedEventName },
    { title: 'Market Type', value: marketName },
    { title: 'Selection', value: formattedSelectionName },
    { title: 'Event Start Date & Time', value: formattedEventDateTime },
    { title: 'Bet Type', value: betType },
  ];

  return (
    <ModalCenter open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: 380, sm: 620, md: 940 },
          p: 2,
        }}
      >
        <ClosableBox label={'Bet snapshot detail'} onClose={() => onClose()}>
          <Grid container spacing={2} p={2}>
            <Grid item xs={12} md={6}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Typography variant={'h2'}>
                          Available to Back
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant={'h3'}>Price</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant={'h3'}>Size</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ex.availableToBack.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant={'body1'}>
                            {data.price}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant={'body1'}>{data.size}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={6}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Typography variant={'h2'}>Available to Lay</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant={'h3'}>Price</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant={'h3'}>Size</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ex.availableToLay.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant={'body1'}>
                            {data.price}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant={'body1'}>{data.size}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} m={2}>
              <Typography variant={'h3'} mb={3}>
                Total Matched: {totalMatched}
              </Typography>
              <CollapseBox label="Additional details" defaultValue>
                <Box p={3}>
                  {additionalDetailsMap.map((detail, index) => (
                    <AdditionalDetailBox
                      key={index}
                      title={detail.title}
                      value={detail.value}
                    />
                  ))}
                </Box>
              </CollapseBox>
            </Grid>
          </Grid>
        </ClosableBox>
      </Box>
    </ModalCenter>
  );
};

export default BetSnapshotModal;
