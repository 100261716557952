import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { UIContext } from '@miyagami-com/lsx-ui-components';

import {
  updateBrandsAction,
  updateUsersByRole,
} from '../../../store/globalData';

import messages from './messages';
import useUnsettledBrandBackofficeUsers from '../../../common/hooks/useUnsettledBrandBackofficeUsers';
import useBrands from '../../../common/hooks/useBrands';

const SystemAdminBrandsWrapper: React.FC = ({ children }) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const { setAlert } = useContext(UIContext);

  const { data: users, status: usersStatus } =
    useUnsettledBrandBackofficeUsers();

  const { data: brands, status: brandsStatus } = useBrands({});

  useEffect(() => {
    if (brandsStatus !== 'success' || !brands?.length) return;

    dispatch(updateBrandsAction(brands));
  }, [brands, brandsStatus, dispatch]);

  useEffect(() => {
    if (usersStatus === 'error') {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.errorUploadingUsers),
      });
    }

    if (usersStatus !== 'success' || !users?.length) return;

    dispatch(updateUsersByRole({ usersList: users }));
  }, [dispatch, intl, setAlert, users, usersStatus]);

  return <>{children}</>;
};

export default SystemAdminBrandsWrapper;
