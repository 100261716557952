import React from 'react';

import BackofficeUserBrandsWrapper from '../BackofficeUserBrandsWrapper';
import SystemAdminBrandsWrapper from '../SystemAdminBrandsWrapper';
import UserRoots from './UserRoots';
import IdleTimer from '../IdleTimer';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';
import { BackofficeUserRole } from '../../../../types/supabase';

interface AuthenticatedRootProps {
  roles: BackofficeUserRole[] | null;
}

const AuthenticatedRoot: React.FC<AuthenticatedRootProps> = ({ roles }) => {
  const isSystemAdmin = checkIsSystemAdmin({ roles });

  if (isSystemAdmin) {
    return (
      <SystemAdminBrandsWrapper>
        <IdleTimer />
        <UserRoots />
      </SystemAdminBrandsWrapper>
    );
  }

  return (
    <BackofficeUserBrandsWrapper>
      <IdleTimer />
      <UserRoots />
    </BackofficeUserBrandsWrapper>
  );
};

export default AuthenticatedRoot;
