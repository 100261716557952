import { defineMessages } from 'react-intl';

export default defineMessages({
  account: {
    id: 'BackofficeUser.BackofficeUserAccountInformation.account',
    defaultMessage: 'Account',
  },
  brand: {
    id: 'BackofficeUser.BackofficeUserAccountInformation.brand',
    defaultMessage: 'Brand',
  },
  status: {
    id: 'BackofficeUser.BackofficeUserAccountInformation.status',
    defaultMessage: 'Status',
  },
  role: {
    id: 'BackofficeUser.BackofficeUserAccountInformation.role',
    defaultMessage: 'Role',
  },
  accountInformation: {
    id: 'BackofficeUser.BackofficeUserAccountInformation.accountInformation',
    defaultMessage: 'Account information',
  },
  save: {
    id: 'BackofficeUser.BackofficeUserAccountInformation.save',
    defaultMessage: 'Save',
  },
  enableMFA: {
    id: 'BackofficeUser.BackofficeUserAccountInformation.enableMFA',
    defaultMessage: 'Enable MFA',
  },
  email: {
    id: 'BackofficeUser.BackofficeUserAccountInformation.email',
    defaultMessage: 'E-mail',
  },
  username: {
    id: 'BackofficeUser.BackofficeUserAccountInformation.username',
    defaultMessage: 'Username',
  },
  textWhenTheUserIsUpdated: {
    id: 'BackofficeUser.BackofficeUserAccountInformation.textWhenTheUserIsUpdated',
    defaultMessage: 'User was successfully updated!',
  },
});
