import { Box, CollapseBox, UIContext } from '@miyagami-com/lsx-ui-components';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useEffect, useState, useContext } from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import IpAddressCell from '../../Unknown/IpAddressCell';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from '../../../common/constants';
import groupingTimeCellGetter from '../../../common/groupingTimeCellGetter';
import DataGridTable from '../../Unknown/DataGridTable';
import getUserLogs from '../LogUserList/getUserLogs';
import messages from './messages';
import { Log } from '../../../../types/supabase';

type LogAccountListProps = {
  setLastActivityTime: React.Dispatch<React.SetStateAction<number | null>>;
  userId: string;
};

const LogAccountList: React.FC<LogAccountListProps> = ({
  userId,
  setLastActivityTime,
}) => {
  const intl = useIntl();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(
    DEFAULT_ROWS_PER_PAGE_OPTIONS[5],
  );
  const { setAlert } = useContext(UIContext);

  const offset = page * pageSize;
  const limit = offset + pageSize - 1;

  const {
    data: logsData,
    isFetching,
    isError,
  } = useQuery(['getLogs', userId, offset, limit], () =>
    getUserLogs({ type: 'user', userId, offset, limit }),
  );

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const onPageSizeChange = (size: number) => {
    setPageSize(size);
  };

  useEffect(() => {
    if (logsData?.data) {
      const lastActivityTime = logsData.data[0]?.created_at
        ? new Date(logsData.data[0].created_at).getTime()
        : null;
      if (lastActivityTime) {
        setLastActivityTime((time) => {
          if (!time) return lastActivityTime;

          if (time > lastActivityTime) return time;

          return lastActivityTime;
        });
      }
    }
  }, [logsData, setLastActivityTime]);

  useEffect(() => {
    if (isError) {
      setAlert({
        message: intl.formatMessage(messages.errorLogsMessage),
        severity: 'error',
        show: true,
      });
    }
  }, [intl, isError, setAlert]);

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.5,
      editable: false,
      filterable: false,
    },
    {
      field: 'created_at',
      groupingValueGetter: groupingTimeCellGetter,
      headerName: 'Date',
      editable: false,
      filterable: false,
      flex: 0.65,
      renderCell: (
        params: GridRenderCellParams<string | number | Date, Log>,
      ) => {
        const createdAt = params.value;
        if (!createdAt) return null;
        return <FormattedDate value={new Date(createdAt)} />;
      },
    },
    {
      field: 'ip_address',
      headerName: 'IP address',
      editable: false,
      filterable: false,
      flex: 0.75,
      renderCell: (params: GridRenderCellParams<string, Log>) => {
        if (!params.row) return null;

        const { ip_address, country_code } = params.row;

        return (
          <IpAddressCell ipAddress={ip_address} countryCode={country_code} />
        );
      },
    },
    {
      field: 'message',
      headerName: 'Action',
      editable: false,
      filterable: false,
      flex: 0.75,
    },
  ];

  return (
    <Box width={1}>
      <CollapseBox label={intl.formatMessage(messages.accountLog)} defaultValue>
        <DataGridTable
          tableKey="log-account-list"
          withoutPadding
          boxProps={{
            px: 2,
            py: 3,
          }}
          loading={isFetching}
          rows={logsData?.data || []}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
          disableSelectionOnClick
          autoHeight
          rowHeight={35}
          headerHeight={35}
          page={page}
          rowCount={logsData?.count || 0}
          onPageChange={onPageChange}
          hideFooterRowCount
          paginationMode="server"
        />
      </CollapseBox>
    </Box>
  );
};

export default LogAccountList;
