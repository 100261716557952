import { FC } from 'react';
import { DataGridProps } from '@mui/x-data-grid';
import {
  GridColumns,
  GRID_AGGREGATION_FUNCTIONS,
} from '@mui/x-data-grid-premium';

import DataGridTable from '../../Unknown/DataGridTable';

import useStyles from './useStyles';
import {
  aggregationModel,
  aggregationRowTitle,
  customSumAggregation,
} from './utils';
import { useParams } from 'react-router-dom';
import { ProfitLossReportLevel } from '../../../../types';
import useGroupingModel from './useGroupingModel';
import useColumns from './useColumns';

interface ProfitLossReportListProps {
  dataGridProps: Omit<DataGridProps, 'columns'>;
  isLoading: boolean;
  headerGroupTitle: string;
}

export interface ProfitLossReportQuery {
  reportLevel: ProfitLossReportLevel;
  agentId?: string;
}

const ProfitLossReportList: FC<ProfitLossReportListProps> = (props) => {
  const { dataGridProps, isLoading, headerGroupTitle } = props;

  const classes = useStyles();

  const { reportLevel } = useParams<ProfitLossReportQuery>();

  const columns: GridColumns = useColumns({ reportLevel });

  const columnGroupingModel = useGroupingModel({
    level: reportLevel,
    headerGroupTitle,
  });

  return (
    <DataGridTable
      {...dataGridProps}
      classes={classes}
      disableSelectionOnClick={true}
      pageSize={dataGridProps.rows.length}
      autoHeight={true}
      rowHeight={35}
      headerHeight={25}
      tableKey="profitLoss"
      columns={columns}
      hideFooterRowCount
      hideFooterPagination
      hideFooterSelectedRowCount
      showColumnRightBorder
      showCellRightBorder
      experimentalFeatures={{
        columnGrouping: true,
        aggregation: !isLoading && dataGridProps.rows.length > 0,
      }}
      columnGroupingModel={columnGroupingModel}
      aggregationFunctions={{
        ...GRID_AGGREGATION_FUNCTIONS,
        customSumAggregation,
        aggregationRowTitle,
      }}
      initialState={{
        aggregation: { model: aggregationModel },
      }}
    />
  );
};

export default ProfitLossReportList;
