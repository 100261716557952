import { FC, useMemo } from 'react';
import {
  GridColumns,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { useIntl } from 'react-intl';

import DataGridTable from '../../Unknown/DataGridTable';

import messages from './messages';
import {
  ApiKey,
  BrandApiKeyDomain,
  BrandApiKeyStatus,
  RootState,
} from '../../../../types';
import format from 'date-fns/esm/format';
import { DEFAULT_DATE_FORMAT } from '../../../common/constants';
import useApiKeysStatusMap from '../../../common/hooks/useApiKeysStatusMap';
import StatusCell from '../../Unknown/StatusCell';
import groupingTimeCellGetter from '../../../common/groupingTimeCellGetter';
import normalizeApiKeys, { ApiKeyRow } from './normalizeApiKeys';
import ActionsBox from '../../Unknown/ActionsBox';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';
import { useSelector } from 'react-redux';

const propertiesColumn = {
  editable: false,
  sortable: true,
  filterable: false,
};

interface BrandApiKeysListProps {
  brandApiKeys?: ApiKey[] | null;
  sharedApiKeys?: ApiKey[] | null;
  onClickShareApiKey: (apiKeyId: string) => void;
}

const BrandApiKeysList: FC<BrandApiKeysListProps> = (props) => {
  const { brandApiKeys, sharedApiKeys, onClickShareApiKey } = props;

  const rows = useMemo(() => {
    return normalizeApiKeys({ brandApiKeys, sharedApiKeys });
  }, [brandApiKeys, sharedApiKeys]);

  const intl = useIntl();

  const apiKeysStatusMap = useApiKeysStatusMap();

  const { roles } = useSelector((state: RootState) => state.user);

  const isSystemAdmin = checkIsSystemAdmin({ roles });

  const columns: GridColumns = [
    {
      ...propertiesColumn,
      field: 'id',
      headerName: intl.formatMessage(messages.id),
      flex: 0.5,
    },
    {
      ...propertiesColumn,
      field: 'createdAt',
      headerName: intl.formatMessage(messages.date),
      flex: 1,
      groupingValueGetter: groupingTimeCellGetter,
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        const { value } = params;

        if (!value) return null;

        const date = new Date(value);

        const formattedDate = format(date, DEFAULT_DATE_FORMAT);

        return formattedDate;
      },
    },
    {
      ...propertiesColumn,
      field: 'domain',
      headerName: intl.formatMessage(messages.domain),
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<BrandApiKeyDomain>) => {
        const { value } = params;

        if (!value) return null;

        switch (value) {
          case 'betfair': {
            return intl.formatMessage(messages.betfair);
          }
          default:
            return null;
        }
      },
    },
    {
      ...propertiesColumn,
      field: 'status',
      headerName: intl.formatMessage(messages.status),
      flex: 1,
      renderCell: (params: GridRenderCellParams<BrandApiKeyStatus>) => {
        const { value } = params;

        if (!value) return null;

        const statusProps = apiKeysStatusMap[value];

        return <StatusCell {...statusProps} />;
      },
    },
    {
      ...propertiesColumn,
      field: 'isShared',
      headerName: intl.formatMessage(messages.isShared),
      flex: 0.5,
      type: 'boolean',
    },
    {
      ...propertiesColumn,
      field: 'actions',
      groupable: false,
      headerName: intl.formatMessage(messages.actions),
      flex: 0.5,
      renderCell: (params: GridRenderCellParams<void, ApiKeyRow>) => {
        const { row } = params;

        const { isShared, apiKeyId } = row;

        if (isShared) return null;

        const actions = [];

        if (isSystemAdmin) {
          const editAction = {
            label: intl.formatMessage(messages.share),
            buttonProps: {
              onClick: () => onClickShareApiKey(apiKeyId),
            },
          };

          actions.push(editAction);
        }

        return <ActionsBox actions={actions} />;
      },
    },
  ];

  return (
    <DataGridTable
      rows={rows}
      tableKey="brand-api-keys-list"
      columns={columns}
      disableSelectionOnClick
      pageSize={100}
      hideFooter
      autoHeight
      rowHeight={32}
      headerHeight={36}
    />
  );
};

export default BrandApiKeysList;
