import React, { useMemo, useCallback, useState } from 'react';
import {
  GridColumns,
  GridRenderCellParams,
  GridRowData,
} from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
// import { UIContext } from '@miyagami-com/lsx-ui-components';
// import { useFirebaseApp } from 'reactfire';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import DataGridTable from '../../Unknown/DataGridTable';
import StatusCell from '../../Unknown/StatusCell';
import ActionsBox from '../../Unknown/ActionsBox';

import {
  // DEFAULT_REGION,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
  USER_ROLES,
} from '../../../common/constants';
import useUserStatuses from '../../../common/hooks/useUserStatuses';
import useCheckBrandIsSuspend from '../../../common/hooks/useCheckBrandIsSuspend';
import useMyAccountPath from '../../../common/hooks/useMyAccountPath';

import parsePermissions from '../../../common/permissions/parsePermissions';

import { RootState } from '../../../../types';

import messages from './messages';
import checkIsBrandPendingRole from '../../../common/checkIsBrandPendingRole';
import checkIsBrandOwner from '../../../common/checkIsBrandOwner';
import { BackofficeUserRole } from '../../../../types/supabase';
// import useRefetchBackofficeUsers from '../../../common/hooks/useRefetchBackofficeUsers';

type BackofficeUserListProps = {
  rows: GridRowData[];
  type?: 'brandOwners' | 'systemAdmins' | 'backofficeUsers';
};

const defaultColumnProps = {
  editable: false,
  filterable: false,
  flex: 0.5,
};

const userPathsByType = {
  systemAdmins: 'system-admins',
  brandOwners: 'brand-owners',
  backofficeUsers: 'backoffice-users',
};

const pushPathType = {
  systemAdmins: (userId: string) => `/system-admins/${userId}`,
  brandOwners: (userId: string, brandId: string | null) =>
    `/b/${brandId}/brand-owners/${userId}`,
  backofficeUsers: (userId: string, brandId: string | null) =>
    `/b/${brandId}/backoffice-users/${userId}`,
  brandOwnerPending: (userId: string) => `/brand-owners/${userId}/account`,
};

const BackofficeUserList: React.FC<BackofficeUserListProps> = ({
  rows,
  type = 'backofficeUsers',
}) => {
  const [pageSize, setPageSize] = useState(DEFAULT_ROWS_PER_PAGE_OPTIONS[5]);
  const intl = useIntl();
  const history = useHistory();
  const userStatuses = useUserStatuses();
  const isBrandSuspended = useCheckBrandIsSuspend();

  const { uid } = useSelector((state: RootState) => state.user);

  // const { setAlert } = useContext(UIContext);

  const tableKey = userPathsByType[type];

  const myAccountPath = useMyAccountPath();

  // const functions = useFirebaseApp().functions(DEFAULT_REGION);

  // const refetchBackofficeUsers = useRefetchBackofficeUsers();

  // const onDelete = useCallback(
  //   async (userId: string, brandId: string) => {
  //     const deleteUserFunction = functions.httpsCallable(
  //       'back-user-deleteUser',
  //     );

  //     try {
  //       await deleteUserFunction({ userId, brandId });

  //       setAlert({
  //         show: true,
  //         severity: 'success',
  //         message: intl.formatMessage(messages.successfullyDelete),
  //       });
  //     } catch (error) {
  //       setAlert({
  //         show: true,
  //         severity: 'error',
  //         message: intl.formatMessage(messages.errorDeleteUser),
  //       });
  //     } finally {
  //       refetchBackofficeUsers();
  //     }
  //   },
  //   [functions, intl, refetchBackofficeUsers, setAlert],
  // );

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const onEdit = useCallback(
    (id: string, brandId: string | null, roles: BackofficeUserRole[]) => {
      const isCurrentUser = id === uid;

      if (isCurrentUser) {
        history.push(myAccountPath);

        return;
      }

      const { isBrandPending } = checkIsBrandPendingRole({ roles });

      if (isBrandPending) {
        const createPushPath = pushPathType.brandOwnerPending;

        const pushPath = createPushPath(id);

        history.push(pushPath);

        return;
      }

      const isBrandOwner = checkIsBrandOwner({ roles });

      const createPushPath = isBrandOwner
        ? pushPathType.brandOwners
        : pushPathType[type];

      const pushPath = createPushPath(id, brandId);

      history.push(pushPath);
    },
    [history, myAccountPath, type, uid],
  );

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'id',
        hide: true,
        headerName: intl.formatMessage(messages.id),
        ...defaultColumnProps,
      },
      {
        field: 'name',
        headerName: intl.formatMessage(messages.name),
        ...defaultColumnProps,
      },
      {
        field: 'surname',
        headerName: intl.formatMessage(messages.surname),
        ...defaultColumnProps,
      },
      {
        field: 'email',
        headerName: intl.formatMessage(messages.email),
        ...defaultColumnProps,
        flex: 0.75,
      },
      {
        field: 'phone_number',
        headerName: intl.formatMessage(messages.phoneNumber),
        ...defaultColumnProps,
        flex: 0.6,
      },
      {
        field: 'role',
        headerName: intl.formatMessage(messages.role),
        ...defaultColumnProps,
        valueFormatter: ({ value }) => {
          const userRoleParams = value?.toString();
          const { role } = parsePermissions(userRoleParams);
          const roleData = USER_ROLES.find(
            (userRole) => userRole.value === role,
          );
          return roleData?.label;
        },
        renderCell: (params: GridRenderCellParams) => {
          const userRoleParams = params.value?.toString();
          const { role } = parsePermissions(userRoleParams);
          return USER_ROLES.find((userRole) => userRole.value === role)?.label;
        },
      },
      {
        field: 'status',
        headerName: intl.formatMessage(messages.status),
        ...defaultColumnProps,
        renderCell: (params: GridRenderCellParams) => {
          const status = params.value as keyof typeof userStatuses;
          if (
            isBrandSuspended &&
            type === 'backofficeUsers' &&
            !params.row?.customClaims?.roles.some((role: string) =>
              role.includes('owner'),
            )
          ) {
            const userStatus = userStatuses.suspend;

            return <StatusCell {...userStatus} />;
          }

          const userStatus = userStatuses[status];

          if (!userStatus) return null;

          return <StatusCell {...userStatus} />;
        },
      },
      {
        field: 'brandName',
        headerName: intl.formatMessage(messages.brandName),
        ...defaultColumnProps,
      },
      {
        field: 'actions',
        headerName: intl.formatMessage(messages.actions),
        sortable: false,
        ...defaultColumnProps,
        flex: 0.65,
        groupable: false,
        renderCell: (params: GridRenderCellParams) => {
          if (!params.row) return null;

          const { id, brandId, roles } = params.row;

          const actions = [
            {
              label: intl.formatMessage(messages.editAction),
              buttonProps: {
                onClick: () => onEdit(id, brandId, roles || []),
              },
            },
          ];

          return <ActionsBox actions={actions} />;
        },
      },
    ],
    [intl, isBrandSuspended, onEdit, type, userStatuses],
  );

  return (
    <DataGridTable
      rows={rows}
      columns={columns}
      tableKey={tableKey}
      pageSize={pageSize}
      onPageSizeChange={onPageSizeChange}
      rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
      disableSelectionOnClick
      autoHeight
      rowHeight={35}
      headerHeight={35}
    />
  );
};

export default BackofficeUserList;
