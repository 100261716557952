import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  firstChild: {
    backgroundColor: theme.palette.grey[200],
  },
  secondChild: {
    backgroundColor: theme.palette.grey[400],
  },
  iconTransform: {
    transform: 'rotate(-180deg)',
    transitionDelay: '1s',
  },
  headerClassName: {
    '&.MuiDataGrid-columnHeader': {
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(2),
    },
  },
  detailPanelClassName: {
    '&.MuiDataGrid-columnHeader': {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
    '&.MuiDataGrid-cell': {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
}));

export default useStyles;
