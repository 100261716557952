import React, { MutableRefObject, useCallback, useMemo, useState } from 'react';
import { Box, Typography } from '@miyagami-com/lsx-ui-components';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import {
  GridCellParams,
  GridColumns,
  GridRenderCellParams,
  GridEvents,
  GridEventListener,
  GridApi,
} from '@mui/x-data-grid';

import ActionsBox from '../../Unknown/ActionsBox';

import messages from './messages';
import {
  AGENT_ROLE,
  DEFAULT_AMOUNT,
  NETWORK_TREE_ROWS,
  PLAYER_ROLE,
  USER_ROLES,
} from '../../../common/constants';
import { TreeRow } from './createTreeRow';
import { RootState, UserRole } from '../../../../types';
import getPathByRole from './getPathByRole';
import Big from 'big.js';
import DataGridTable from '../../Unknown/DataGridTable';
import { useSelector } from 'react-redux';
import SkeletonCell from '../../Unknown/SkeletonCell';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';
import checkIsBrandOwner from '../../../common/checkIsBrandOwner';
import roundToDecimalPlaces from '../../../common/roundToDecimalPlaces';

const defaultColumnParams = {
  editable: false,
  sortable: false,
  flex: 0.5,
};

const userRoles = [...USER_ROLES, PLAYER_ROLE];

const withoutViewActionRowIds = Object.keys(NETWORK_TREE_ROWS);

const numberColumnParams = {
  // editable: true,
  sortable: false,
  flex: 0.5,
};

interface NetworkTreeListProps {
  brandId: string;
  trees: TreeRow[][] | null | undefined;
  userId: string;
  onCellEditCommit?: GridEventListener<GridEvents.cellEditCommit>;
  apiRef?: MutableRefObject<GridApi | undefined>;
  parentId?: string | null;
}

const NetworkTreeList: React.FC<NetworkTreeListProps> = (props) => {
  const { brandId, trees, userId } = props;

  const intl = useIntl();
  const history = useHistory();

  const [page, setPage] = useState<number>(0);

  const { uid: currentUserId, roles } = useSelector(
    (root: RootState) => root.user,
  );

  const isSystemAdmin = checkIsSystemAdmin({ roles });
  const isBrandOwner = checkIsBrandOwner({ roles });

  const isCurrentUserAdminOrOwner = useMemo(() => {
    return isSystemAdmin || isBrandOwner;
  }, [isSystemAdmin, isBrandOwner]);

  const onClickView = useCallback(
    (id: string, role: UserRole) => {
      if (currentUserId === id) {
        const path = `/b/${brandId}/my-account/account`;

        history.push(path);

        return;
      }

      const rolePath = getPathByRole({ role });

      if (!rolePath) history.push('backoffice-users');

      const path = `/b/${brandId}/${rolePath}/${id}`;

      history.push(path);
    },
    [brandId, currentUserId, history],
  );

  const columns: GridColumns = useMemo(
    () => [
      // {
      //   field: 'id',
      //   headerName: intl.formatMessage(messages.idColumn),
      //   ...defaultColumnParams,
      //   renderCell: (params: GridRenderCellParams) => {
      //     // if (apiRef) {
      //     //   apiRef.current = params.api;
      //     // }

      //     if (!params.id) return null;
      //     const id = params.id as keyof typeof NETWORK_TREE_ROWS;

      //     const withoutAction = withoutViewActionRowIds.includes(id);

      //     if (withoutAction) return NETWORK_TREE_ROWS[id].label;

      //     return params.id;
      //   },
      // },
      {
        field: 'role',
        headerName: intl.formatMessage(messages.userRole),
        ...defaultColumnParams,
        valueFormatter: (params) => {
          const role = userRoles.find(({ value }) => value === params.value);
          if (role && role?.label) return role.label;
          return 'Total';
        },
      },
      {
        field: 'name',
        headerName: intl.formatMessage(messages.userName),
        ...defaultColumnParams,
      },
      {
        field: 'positionTakingRate',
        headerName: intl.formatMessage(messages.userPT),
        renderCell: (params) => {
          // if (!params.isEditable) return;

          const isTotalRow = params.row.id === 'total';
          const isPlayerRow =
            params.row.role === PLAYER_ROLE.value && !params.row.childUserId;

          if (isTotalRow && !isSystemAdmin) return '';
          if (params.row.isLoading) return <SkeletonCell />;
          if (isPlayerRow) return '-';
          return `${roundToDecimalPlaces(params.value * 100, 3) || 0}%`;

          // return `${roundToDecimalPlaces(params.value, 3) * 100 || 0}%`;
        },
        ...numberColumnParams,
      },
      {
        field: 'commissionRate',
        headerName: intl.formatMessage(messages.userCommission),
        renderCell: (params) => {
          // if (!params.isEditable) return;

          const isPlayerRow =
            params.row.role === PLAYER_ROLE.value && !params.row.childUserId;

          if (params.row.isLoading) return <SkeletonCell />;
          if (isPlayerRow) return '-';
          return `${roundToDecimalPlaces(params.value * 100, 3) || 0}%`;
        },
        ...numberColumnParams,
      },
      {
        field: 'minPositionTakingRate',
        headerName: intl.formatMessage(messages.minUserPT),
        renderCell: (params) => {
          // if (!params.isEditable) return;

          const isPlayerRow =
            params.row.role === PLAYER_ROLE.value && !params.row.childUserId;

          if (isPlayerRow) return '-';
          if (params.row.isLoading) return <SkeletonCell />;
          return `${roundToDecimalPlaces(params.value * 100, 3) || 0}%`;
        },
        ...numberColumnParams,
      },
      {
        field: 'maxPositionTakingRate',
        headerName: intl.formatMessage(messages.maxUserPT),
        renderCell: (params) => {
          // if (!params.isEditable) return;
          const isPlayerRow =
            params.row.role === PLAYER_ROLE.value && !params.row.childUserId;

          if (isPlayerRow) return '-';
          if (params.row.isLoading) return <SkeletonCell />;
          return `${roundToDecimalPlaces(params.value * 100, 3) || 0}%`;
        },
        ...numberColumnParams,
      },
      // {
      //   field: 'extraPositionTakingRate',
      //   headerName: intl.formatMessage(messages.extraPositionTaking),
      //   renderCell: (params) => {
      //     // if (!params.isEditable) return;

      //     if (params.row.isLoading) return <SkeletonCell />;
      //     if (!params.row?.isExtraPTEnabled) return '-';
      //     return `${roundToDecimalPlaces(params.value * 100, 3) || 0}%`;
      //   },
      //   ...numberColumnParams,
      // },
      {
        field: 'sortPriority',
        headerName: '',
        hide: true,
      },
      {
        field: 'action',
        headerName: intl.formatMessage(messages.actionColumn),
        ...defaultColumnParams,
        renderCell: (params: GridRenderCellParams) => {
          if (!params.row) return null;

          const id = params.id.toString();

          const withoutAction = withoutViewActionRowIds.includes(id);

          const isSameUser = userId === id;

          if (withoutAction || isSameUser) return null;

          const { role } = params.row;

          const actions = [
            {
              label: intl.formatMessage(messages.actionView),
              buttonProps: {
                onClick: () => onClickView(id, role),
              },
            },
          ];

          return <ActionsBox actions={actions} />;
        },
      },
    ],
    [intl, onClickView, userId, isSystemAdmin],
  );

  const editableUserIds = [NETWORK_TREE_ROWS.newUser.id, currentUserId];

  const isCellEditable = (params: GridCellParams<TreeRow>) => {
    const isPlayer = params.row.role === PLAYER_ROLE.value;

    if (isPlayer) return false;

    const isAgent = params.row.role === AGENT_ROLE;

    if (isCurrentUserAdminOrOwner && isAgent) return true;

    return editableUserIds.includes(params.id.toString());
  };

  const pagination = useMemo(() => {
    if (!trees) return { rowCount: 0, rows: [], pageSize: 0 };
    const rows = trees[page];

    const pagesCount = rows?.length || DEFAULT_AMOUNT;

    const isLastPage = page === pagesCount;

    const hideFooterPagination = trees.length <= 1;

    const pageSize = trees[page]?.length || DEFAULT_AMOUNT;

    const rowsPerPageOptions = [pageSize];

    const allRows = trees.flat();

    const displayedRowsCount = new Big(allRows?.length || DEFAULT_AMOUNT)
      .minus(rows?.length || DEFAULT_AMOUNT)
      .minus(1)
      .toNumber();

    const rowCount = isLastPage ? displayedRowsCount : allRows.length;

    return {
      rowCount,
      rows: rows || [],
      pageSize,
      rowsPerPageOptions,
      hideFooterPagination,
    };
  }, [page, trees]);

  const onPageSizeChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <Box p={3} width={1}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6">
          <FormattedMessage {...messages.label} />
        </Typography>
      </Box>
      <DataGridTable
        {...pagination}
        withoutPadding
        tableKey="network-tree-list"
        onPageChange={onPageSizeChange}
        disableSelectionOnClick={true}
        disableColumnMenu
        isCellEditable={isCellEditable}
        autoHeight={true}
        rowHeight={35}
        headerHeight={35}
        paginationMode="server"
        loading={!Boolean(pagination.rows?.length)}
        columns={columns}
        hideFooterRowCount
        pagination
      />
    </Box>
  );
};

export default NetworkTreeList;
