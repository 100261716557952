import { FC, useState, useMemo, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import { DataGridProps } from '@mui/x-data-grid';
import { UIContext } from '@miyagami-com/lsx-ui-components';
import { RootState } from '../../../../types';
import commonMessages from '../../../common/messages';
import DefaultLayout from '../../Unknown/DefaultLayout';
import OverviewLayout from '../../Unknown/OverviewLayout';
import LoadingBox from '../../Unknown/LoadingBox';
import PlayerDuplicatesList from '../PlayerDuplicatesList';
import messages from './messages';
import getDuplicatePlayers, {
  DuplicatePlayerData,
} from './getDuplicatePlayers';
import getFromToNumbersSupabase from '../../../common/getFromToNumbersSupabase';
import getNormalizedDuplicatePlayersList from './getNormalizedDuplicatePlayersList';

type QueryParams = {
  brandId: string;
};

const PlayerOverviewPage: FC = () => {
  const [players, setPlayers] = useState<DuplicatePlayerData[]>([]);
  const intl = useIntl();
  const { setAlert } = useContext(UIContext);
  const { brandId }: QueryParams = useParams();
  const { brands } = useSelector((state: RootState) => state.globalData);

  const {
    data: duplicatePlayersResponse,
    isFetching,
    isError,
  } = useQuery(['getDuplicatePlayers', brandId], () =>
    getDuplicatePlayers({ brandId }),
  );

  const defaultRows = useMemo(() => {
    const normalizedPlayers = getNormalizedDuplicatePlayersList({
      duplicatePlayersData: duplicatePlayersResponse?.data || [],
      brandId,
    });

    return normalizedPlayers;
  }, [brandId, duplicatePlayersResponse?.data]);

  const path = [
    { label: intl.formatMessage(messages.player) },
    { label: intl.formatMessage(messages.duplicatePlayers) },
  ];

  useEffect(() => {
    if (isError) {
      setAlert({
        message: intl.formatMessage(commonMessages.fetchErrorMessage),
        severity: 'error',
        show: true,
      });
    }
  }, [intl, isError, setAlert]);

  const { from, to } = getFromToNumbersSupabase({
    list: players,
    key: 'player_created_at',
  });

  const dataGridProps: Omit<DataGridProps, 'columns'> = useMemo(() => {
    return {
      rows: players,
      loading: isFetching,
    };
  }, [players, isFetching]);

  return (
    <DefaultLayout path={path}>
      <OverviewLayout
        headerProps={{
          name: intl.formatMessage(messages.duplicatePlayers),
          from,
          to,
          brands: brands,
          defaultRows,
          setRows: setPlayers,
        }}
      >
        {isFetching ? (
          <LoadingBox />
        ) : (
          <PlayerDuplicatesList dataGridProps={dataGridProps} />
        )}
      </OverviewLayout>
    </DefaultLayout>
  );
};

export default PlayerOverviewPage;
