import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import useFirebaseAuth from '../../Unknown/AuthContext/useFirebaseAuth';
import RequestTotpModal from './RequestTotpModal';
import TotpAuthContext from './TotpAuthContext';
import { MultiFactorError } from 'firebase/auth';
import ReAuthUserModal from './ReAuthUserModal';
import TotpConfirmModal from './TotpConfirmModal';
import { useAuth } from 'reactfire';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types';
import { SYSTEM_ADMIN_ROLE } from '../../../common/constants';

const TotpAuthContextProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const {
    signIn,
    totpSecret,
    totpUri,
    enrollTotp,
    unenrollTotp,
    resolveTotp,
    requestTotpSecret,
    resetTotpSecret,
    mfaResolver,
    resolveMFA,
    getEnrolledFactors,
  } = useFirebaseAuth();
  const { currentUser } = useAuth();
  const authUser = useSelector((state: RootState) => state.user);
  const { brands } = useSelector((state: RootState) => state.globalData);
  const { roles, brandIds } = authUser || {};
  const isAdmin = useMemo(
    () => roles?.map((role) => role.role_id)?.includes(SYSTEM_ADMIN_ROLE),
    [roles],
  );
  const displayName = useMemo(() => {
    if (isAdmin) {
      return 'TechSoftX';
    }

    const brandId = brandIds?.[0];
    const currentBrand = brands?.find((brand) => brand.id === brandId);
    return currentBrand?.name || '';
  }, [isAdmin, brandIds, brands]);
  const [showReAuthModal, setShowReAuthModal] = useState<boolean>(false);

  const requestTotpAuth = useCallback(async () => {
    try {
      await requestTotpSecret(displayName);
    } catch (error) {
      const totpAuthError = error as MultiFactorError;

      switch (totpAuthError.code) {
        case 'auth/unsupported-first-factor': {
          setShowReAuthModal(true);
          break;
        }
        default: {
          console.error(error);
          break;
        }
      }
    }
  }, [displayName, requestTotpSecret]);

  // Need to reauth with email password for setting up TOTP
  // Firebase will throw auth/unsupported-first-factor if we use customer auth
  const onReAuthFirebaseUser = async (password: string) => {
    if (!currentUser?.email) throw new Error('Email is not found');

    await signIn({
      email: currentUser.email,
      password,
    });

    setShowReAuthModal(false);

    requestTotpAuth();
  };

  return (
    <TotpAuthContext.Provider
      value={{
        totpSecret,
        totpUri,
        resetTotpSecret,
        mfaResolver,
        enrollTotp,
        unenrollTotp,
        resolveTotp,
        resolveMFA,
        requestTotpAuth,
        getEnrolledFactors,
      }}
    >
      {children}

      <ReAuthUserModal
        isOpen={showReAuthModal}
        onClose={() => setShowReAuthModal(false)}
        onReAuth={onReAuthFirebaseUser}
      />

      <RequestTotpModal
        isOpen={!!totpSecret}
        totpSecretKey={totpSecret?.secretKey || ''}
        totpUri={totpUri || ''}
        onClose={() => resetTotpSecret()}
        onVerify={async (code: string) => {
          await enrollTotp(code, displayName);
        }}
      />

      <TotpConfirmModal
        isOpen={!!mfaResolver && mfaResolver.factorId === 'totp'}
        onClose={() => {
          resetTotpSecret();
        }}
        onConfirm={async (code: string) => {
          await resolveTotp(code);
        }}
      />
    </TotpAuthContext.Provider>
  );
};

export default TotpAuthContextProvider;
