import { FC, ReactNode } from 'react';
import { Grid } from '@miyagami-com/lsx-ui-components';

import useStyles from './useStyles';

interface DetailsInformationGridItemProps {
  isBorderRight?: boolean;
  children: ReactNode;
}

const DetailsInformationGridItem: FC<DetailsInformationGridItemProps> = ({
  isBorderRight,
  children,
}) => {
  const classes = useStyles();

  return (
    <Grid
      item
      xs={12}
      sm={6}
      lg={3}
      px={3}
      className={isBorderRight ? classes.borderRight : undefined}
    >
      {children}
    </Grid>
  );
};

export default DetailsInformationGridItem;
