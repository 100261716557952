import {
  ExtendedBackofficeUserPermission,
  PermissionMap,
} from '../../../types';

interface ParsePermissionsMapParams {
  permissions: ExtendedBackofficeUserPermission[];
}

interface ParsedPermissionsMap {
  [brandId: string]: PermissionMap;
}

const parsePermissionsMap = ({
  permissions,
}: ParsePermissionsMapParams): ParsedPermissionsMap => {
  const permissionMap: ParsedPermissionsMap = permissions.reduce(
    (currentMap: ParsedPermissionsMap, userPermission) => {
      const { brand_id: brandId, permission, operation } = userPermission;

      const { permission: permissionType } = permission;

      const currentBrandIdObj = { ...currentMap[brandId] };
      const updatedMap = {
        ...currentMap,
        [brandId]: {
          ...currentBrandIdObj,
          [permissionType]: {
            ...currentBrandIdObj[permissionType],
            [operation]: true,
          },
        },
      };

      return updatedMap;
    },
    {},
  );
  return permissionMap;
};

export default parsePermissionsMap;
