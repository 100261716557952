import { defineMessages } from 'react-intl';

export default defineMessages({
  editBetResultLabel: {
    id: 'ExchangeBet.ExchangeBetEditBet.editBetResultLabel',
    defaultMessage: 'Result',
  },
  editBetLabel: {
    id: 'ExchangeBet.ExchangeBetEditBet.editBetLabel',
    defaultMessage: 'Edit bet',
  },
  saveLabel: {
    id: 'ExchangeBet.ExchangeBetEditBet.saveLabel',
    defaultMessage: 'Save',
  },
  errorUpdateExchangeBetStatus: {
    id: 'ExchangeBet.ExchangeBetEditBet.errorUpdateExchangeBetStatus',
    defaultMessage: 'Error while updating exchange bet status.',
  },
  voidBetLabel: {
    id: 'ExchangeBet.ExchangeBetEditBet.voidBetLabel',
    defaultMessage: 'Void Bet',
  },
  errorVoidBet: {
    id: 'ExchangeBet.ExchangeBetEditBet.errorVoidBet',
    defaultMessage: 'Error while voiding the bet.',
  },
});
