import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';

import { UserState } from '../../types';

import { resetGlobalData } from './globalData';
import createUserPermissionsMap from '../common/permission/createUserPermissionsMap';

const initialState: UserState = {
  permissions: [],
  email: '',
  isAuth: false,
  roles: null,
  unParsedRoles: null,
  brandIds: [],
  uid: '',
  permissionMap: {},
  status: null,
};

interface CreatePermissionMapParams {
  brandIds: string[];
}

const { reducer, actions } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetState(state) {
      state.email = initialState.email;
      state.isAuth = initialState.isAuth;
      state.roles = initialState.roles;
      state.brandIds = initialState.brandIds;
      state.uid = initialState.uid;
      state.permissions = initialState.permissions;
      state.unParsedRoles = initialState.unParsedRoles;
    },
    loginUser(state, action) {
      const {
        uid,
        email,
        roles,
        brandIds,
        permissions,
        unParsedRoles,
        status,
      } = action.payload;

      const filteredBrandIds = brandIds?.filter(
        (brandId: string | null) => brandId,
      );

      state.uid = uid;
      state.email = email;
      state.roles = roles;
      state.brandIds = filteredBrandIds;
      state.permissions = permissions || initialState.permissions;
      state.isAuth = true;
      state.unParsedRoles = unParsedRoles;
      state.status = status;
    },

    logoutUser(state) {
      state.email = initialState.email;
      state.roles = initialState.roles;
      state.uid = initialState.uid;
      state.permissions = initialState.permissions;
      state.unParsedRoles = initialState.unParsedRoles;
      state.isAuth = false;
      state.status = initialState.status;
    },
    setBrandIds(state, action) {
      state.brandIds = action.payload;
    },
    createPermissionMap(
      state,
      { payload: { brandIds } }: PayloadAction<CreatePermissionMapParams>,
    ) {
      const roles = state.unParsedRoles || [];
      const permissions = state.permissions;

      const permissionMap = createUserPermissionsMap({
        roles,
        permissions,
        brandIds,
      });

      state.permissionMap = {
        ...state.permissionMap,
        ...permissionMap,
      };
    },
  },
});

export const {
  resetState,
  loginUser,
  logoutUser,
  setBrandIds,
  createPermissionMap,
} = actions;

export const resetStateAction =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(resetState());
  };

export const loginUserAction =
  (user: UserState) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(
      loginUser({
        ...user,
      }),
    );
  };

export const setBrandIdsAction =
  (brandIds?: string[]) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setBrandIds(brandIds));
  };

export const logoutUserAction =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(logoutUser());
    dispatch(resetGlobalData());
  };

export default reducer;
