import React from 'react';
import {
  Box,
  IconButton,
  Paper,
  Typography,
} from '@miyagami-com/lsx-ui-components';
import CloseIcon from '@mui/icons-material/Close';

type ClosableBoxProps = {
  label: string;
  onClose: CallableFunction;
};

const ClosableBox: React.FC<ClosableBoxProps> = ({
  label,
  onClose,
  children,
}) => {
  return (
    <Box>
      <Paper>
        <Box
          sx={{ borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}
          bgcolor="grey.800"
          px={3}
          py={1}
          display="flex"
          color="common.white"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography variant="body2">{label}</Typography>
          </Box>
          <Box>
            <IconButton
              sx={{ color: 'common.white' }}
              onClick={() => onClose()}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        {children}
      </Paper>
    </Box>
  );
};

export default ClosableBox;
