type FirebaseConfig = {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  databaseURL: string;
};

export type ReleaseChannel = 'production' | 'development' | 'staging';

const channels = ['production', 'development', 'staging'];

const releaseChannel = process.env.REACT_APP_RELEASE_CHANNEL as ReleaseChannel;

const isEnableReleaseChannel = channels.includes(releaseChannel);

if (!isEnableReleaseChannel) {
  throw new Error('Not found release channel');
}

const firebaseConfigByChannel: Record<ReleaseChannel, FirebaseConfig> = {
  production: {
    apiKey: 'AIzaSyBUn6J0tkqb3ElpW1DxKwOpawSoGX4gh4I',
    authDomain: 'lsx-tech---production.firebaseapp.com',
    projectId: 'lsx-tech---production',
    storageBucket: 'lsx-tech---production.appspot.com',
    messagingSenderId: '583557852301',
    appId: '1:583557852301:web:a4ee5903ebe8ce445a06c4',
    databaseURL:
      'https://lsx-tech---production-default-rtdb.europe-west1.firebasedatabase.app/',
  },
  development: {
    apiKey: 'AIzaSyABO2tFhIZopeN_q3e_3Is_EMWDxdFBvzU',
    authDomain: 'lsx-tech---dev.firebaseapp.com',
    databaseURL:
      'https://lsx-tech---dev-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'lsx-tech---dev',
    storageBucket: 'lsx-tech---dev.appspot.com',
    messagingSenderId: '1009303978570',
    appId: '1:1009303978570:web:a5608df44c3c9efac35cb3',
  },
  staging: {
    apiKey: 'AIzaSyAqfZAS6mk1dtJ_WBLAcbL550Tz4FmjfHE',
    authDomain: 'lsx-tech.firebaseapp.com',
    projectId: 'lsx-tech',
    storageBucket: 'lsx-tech.appspot.com',
    messagingSenderId: '465256762587',
    appId: '1:465256762587:web:294c8df8bfaf470681d0fe',
    databaseURL:
      'https://lsx-tech-default-rtdb.europe-west1.firebasedatabase.app',
  },
};

const firebaseConfig: FirebaseConfig = firebaseConfigByChannel[releaseChannel];

export default firebaseConfig;
