import createSupabaseClient from '../../../common/createSupabaseClient';

const getDefaultLanguageId = async (): Promise<string> => {
  const supabaseClient = createSupabaseClient({});

  const ref = supabaseClient.from('system_config');

  const response = await ref.select('*').range(0, 0).throwOnError().single();

  const defaultLanguageId = response.data?.default_language_id;

  if (!defaultLanguageId) throw new Error('Default language not set');

  return defaultLanguageId;
};

export default getDefaultLanguageId;
