import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { CollapseBox, UIContext } from '@miyagami-com/lsx-ui-components';
import { useFirebaseApp } from 'reactfire';
import { FormikHelpers } from 'formik';

import transformToBase64 from '../../../common/transformToBase64';
import { DEFAULT_REGION } from '../../../common/constants';
import BrandApiKeyAddition, {
  AddBrandApiKeyFormParams,
} from '../BrandApiKeyAddition';

import messages from './messages';
import LoadingBox from '../../Unknown/LoadingBox';
import BrandApiKeysList from '../BrandApiKeysList';
import useBrandApiKeys from '../../../common/hooks/useBrandApiKeys';
import useSharedApiKeys from '../../../common/hooks/useSharedApiKeys';
import useShareApiKeyModal from '../ShareApiKeyModal/useShareApiKeyModal';
import ShareApiKeyModal from '../ShareApiKeyModal';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface BrandApiKeysProps {
  brandId: string;
}

const initialValues: AddBrandApiKeyFormParams = {
  locationId: '',
  username: '',
  password: '',
};

const BrandApiKeys: React.FC<BrandApiKeysProps> = ({ brandId }) => {
  const apiKeys = useBrandApiKeys({ brandId });

  const { data: sharedApiKeys, isLoading } = useSharedApiKeys({ brandId });

  const intl = useIntl();

  const firebase = useFirebaseApp();

  const functions = getFunctions(firebase, DEFAULT_REGION);

  const { onClickCloseModal, onClickShareApiKey, isOpen, shareApiKeyId } =
    useShareApiKeyModal();

  const { setAlert } = useContext(UIContext);

  const onSubmitForm = async (
    values: AddBrandApiKeyFormParams,
    { resetForm, setSubmitting }: FormikHelpers<AddBrandApiKeyFormParams>,
  ) => {
    setSubmitting(true);

    const {
      domain,
      locationId,
      username,
      password,
      certificate,
      key,
      apiKeyId,
    } = values;

    try {
      const addApiKeys = httpsCallable(
        functions,
        'back-brandApiKey-addBrandApiKey',
      );
      if (!certificate || !key) {
        setAlert({
          show: true,
          severity: 'warning',
          message: intl.formatMessage(messages.missingFile),
        });
        return;
      }
      const certificateInBase64 = await transformToBase64(certificate);
      const keyInBase64 = await transformToBase64(key);

      await addApiKeys({
        domain,
        locationId,
        username,
        password,
        certificate: {
          name: certificate.name,
          contentType: certificate.type,
          base64string: certificateInBase64,
        },
        key: {
          name: key.name,
          contentType: key.type,
          base64string: keyInBase64,
        },
        brandId,
        apiKeyId,
      });

      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.successMessage),
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(error.message),
      });
    } finally {
      resetForm();
      setSubmitting(false);
    }
  };

  if (isLoading) {
    return <LoadingBox />;
  }

  return (
    <>
      <CollapseBox
        defaultValue={true}
        label={intl.formatMessage(messages.apiKeys)}
      >
        <BrandApiKeysList
          brandApiKeys={apiKeys}
          sharedApiKeys={sharedApiKeys}
          onClickShareApiKey={onClickShareApiKey}
        />
        <BrandApiKeyAddition
          initialValues={initialValues}
          onSubmitForm={onSubmitForm}
          rows={apiKeys || []}
        />
      </CollapseBox>
      {isOpen ? (
        <ShareApiKeyModal
          onCloseModal={onClickCloseModal}
          apiKeyId={shareApiKeyId}
          isOpen
          brandId={brandId}
        />
      ) : null}
    </>
  );
};

export default BrandApiKeys;
