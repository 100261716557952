import { defineMessages } from 'react-intl';

export default defineMessages({
  id: {
    id: 'BrandApiKey.BrandApiKeysList.id',
    defaultMessage: 'Id',
  },
  date: {
    id: 'BrandApiKey.BrandApiKeysList.date',
    defaultMessage: 'Date',
  },
  domain: {
    id: 'BrandApiKey.BrandApiKeysList.domain',
    defaultMessage: 'Domain',
  },
  status: {
    id: 'BrandApiKey.BrandApiKeysList.status',
    defaultMessage: 'Status',
  },
  betfair: {
    id: 'BrandApiKey.BrandApiKeysList.betfair',
    defaultMessage: 'Betfair',
  },
  isShared: {
    id: 'BrandApiKey.BrandApiKeysList.isShared',
    defaultMessage: 'Is shared',
  },
  actions: {
    id: 'BrandApiKey.BrandApiKeysList.actions',
    defaultMessage: 'Actions',
  },
  share: {
    id: 'BrandApiKey.BrandApiKeysList.share',
    defaultMessage: 'Share',
  },
});
