import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  firstChild: {
    backgroundColor: theme.palette.grey[200],
  },
  groupingCellClassName: {
    '& .MuiDataGrid-treeDataGroupingCell': {
      marginLeft: theme.spacing(0),
    },
  },
}));

export default useStyles;
