import { defineMessages } from 'react-intl';

export default defineMessages({
  shareApiKeys: {
    id: 'BrandApiKey.ShareApiKeyModal.shareApiKeys',
    defaultMessage: 'Share api keys',
  },
  save: {
    id: 'BrandApiKey.ShareApiKeyModal.save',
    defaultMessage: 'Save',
  },
  success: {
    id: 'BrandApiKey.ShareApiKeyModal.success',
    defaultMessage: 'Success updated shared api keys',
  },
  error: {
    id: 'BrandApiKey.ShareApiKeyModal.error',
    defaultMessage: 'An error while trying update shared api keys',
  },
});
