const roundToDecimalPlaces = (
  inputNumber: number,
  decimalPlaces: number,
): number => {
  const multiplier = Math.pow(10, decimalPlaces);
  const roundedNumber = Math.round(inputNumber * multiplier) / multiplier;
  return parseFloat(roundedNumber.toFixed(decimalPlaces));
};

export default roundToDecimalPlaces;
