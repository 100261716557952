import { useQueryClient } from 'react-query';
import useBackofficeUsers from './useBackofficeUsers';
import useBrandId from './useBrandId';
import { useDispatch } from 'react-redux';
import { updateUsers } from '../../store/globalData';

type UseRefetchBackofficeUsers = () => void;

const useRefetchBackofficeUsers = (): UseRefetchBackofficeUsers => {
  const queryClient = useQueryClient();

  const brandId = useBrandId();

  const dispatch = useDispatch();

  const { refetch } = useBackofficeUsers({ brandId });

  return async () => {
    if (brandId) {
      const { data: backofficeUsers } = await refetch();
      dispatch(
        updateUsers({
          users: backofficeUsers || [],
          brandId,
        }),
      );
    }

    await queryClient.refetchQueries(['pendingOwners']);
    await queryClient.refetchQueries(['unsettledBrandBackofficeUsers']);
  };
};

export default useRefetchBackofficeUsers;
