import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface StyleProps {
  rowHeight: number;
}

export default makeStyles<Theme, StyleProps>((theme) => ({
  aggregationColumnHeaderLabel: {
    display: 'none',
  },
  errorCell: {
    color: theme.palette.error.main,
  },
  pinnedRows: {
    position: 'absolute',
    top: 0,
    bottom: 'auto',
  },
  pinnedRowsRenderZone: {
    backgroundColor: theme.palette.grey[300],
  },
  virtualScroller: {
    paddingTop: '35px',
  },
}));
