import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  colorSecondary: {
    color: theme.palette.text.secondary,
  },
}));

export default useStyles;
