import calculateGrossProfit from '../../../common/betCalculation/grossProfit';
import calculateLiability from '../../../common/betCalculation/liability/liability';
import { ExtendedExchangeBet, ProfitLossRow } from '../../../../types';

export interface ConvertExchangeBetToPL {
  exchangeBet: ExtendedExchangeBet;
  outcomeId: string;
}

const convertExchangeBetToPL = (
  params: ConvertExchangeBetToPL,
): ProfitLossRow => {
  const { exchangeBet, outcomeId } = params;

  const {
    id,
    settled_at,
    created_at,
    betfairExchangeBet,
    size: betSize,
    market_name,
    selection_name,
    status,
    side,
  } = exchangeBet;

  const {
    event_name,
    event_type_name,
    average_price: price,
    betfairExchangeSizes,
    betfair_bet_size: betfairBetSize,
  } = betfairExchangeBet;

  const matchedSizeObject = betfairExchangeSizes?.find(
    (typeSize) => typeSize.type === 'matched',
  );

  const matchedSize = matchedSizeObject?.size || 0;

  const betfairMatchedRatio = matchedSize / betfairBetSize;

  const trueSize = parseFloat((betSize * betfairMatchedRatio).toFixed(2));

  const liability = calculateLiability({ side, price, size: trueSize });

  let grossProfit = 0;

  const netProfit = null;

  if (status === 'loss') {
    grossProfit = side === 'back' ? -trueSize : -liability;
  } else {
    grossProfit = calculateGrossProfit({ price, side, size: trueSize });
  }

  const profitLossRow: ProfitLossRow = {
    id,
    path: [outcomeId, id],
    settledAt: settled_at || created_at,
    eventTypeName: event_type_name || null,
    eventName: event_name || null,
    marketName: market_name || null,
    totalBets: 1,
    totalVolume: trueSize,
    netProfit,
    grossProfit,
    liability,
    outcome: selection_name,
    side,
    odds: price,
    status,
  };

  return profitLossRow;
};

export default convertExchangeBetToPL;
