import React from 'react';
import { Box } from '@miyagami-com/lsx-ui-components';
import { useRouteMatch, Route, Switch, Redirect } from 'react-router-dom';

import DefaultLayout, { DefaultLayoutProps } from '../../Unknown/DefaultLayout';
import UserAccountHeader, {
  UserAccountHeaderProps,
} from '../UserAccountHeader';
import UserAccountTabs, { UserAccountTabsProps } from '../UserAccountTabs';

interface UserDetailPageLayoutProps {
  accountTabsProps: UserAccountTabsProps;
  defaultLayoutProps: DefaultLayoutProps;
  headerProps: UserAccountHeaderProps;
}

const UserDetailPageLayout: React.FC<UserDetailPageLayoutProps> = ({
  accountTabsProps: { tabs, type },
  defaultLayoutProps,
  headerProps,
}) => {
  const { path, url } = useRouteMatch();

  return (
    <DefaultLayout {...defaultLayoutProps}>
      <Box p={4}>
        <UserAccountHeader {...headerProps}>
          <UserAccountTabs tabs={tabs} type={type} />
        </UserAccountHeader>
        <Switch>
          {tabs.map(({ value, component }) => (
            <Route key={value} path={`${path}/${value}`}>
              {component}
            </Route>
          ))}
          <Route path={`/b/:brandId/${type}-overview/:userId`}>
            <Redirect to={`${url}/${tabs[0].value}`} />
          </Route>
        </Switch>
      </Box>
    </DefaultLayout>
  );
};

export default UserDetailPageLayout;
