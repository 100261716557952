import { useCallback } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import {
  BetfairExchangeBetStatus,
  BetfairExchangeSize,
  ExchangeBet,
} from '../../../types';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../constants';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface UseEventDetailParams {
  userId?: string;
  marketId: string;
}

export interface EventDetailExchangeBet extends ExchangeBet {
  player_username: string;
  betfair_exchange_bet_status: BetfairExchangeBetStatus;
  betfair_exchange_sizes: BetfairExchangeSize[];
}

export type EventDetail = {
  event_id: string;
  event_open_date: string;
  event_name: string;
  event_type_id: string;
  event_type_name: string;
  competition_id: string | null;
  competition_name: string | null;
  total_size: number;
  total_exposure: number;
  last_updated_at: string | null;
  exchange_bets: EventDetailExchangeBet[];
};

const useEventDetail = (
  params: UseEventDetailParams,
): UseQueryResult<EventDetail, Error> => {
  const { marketId, userId } = params;

  const firebase = useFirebaseApp();

  const functions = getFunctions(firebase, DEFAULT_REGION);

  const getUserMarketBets = httpsCallable(
    functions,
    'back-exchangeBetNetwork-getUserMarketBets',
  );

  const fetchUserMarketBets = useCallback(async () => {
    try {
      const { data } = await getUserMarketBets(params);

      return { data, error: null };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log('error', error);
      return { data: [], error: error.message };
    }
  }, [getUserMarketBets, params]);

  const queryResult = useQuery<EventDetail, Error>({
    queryKey: ['eventDetail', marketId, userId],
    keepPreviousData: true,
    retry: 0,
    queryFn: async () => {
      const playersResponse = await fetchUserMarketBets();

      const { data, error } = playersResponse;

      if (error) throw new Error(error.message);

      return data as unknown as EventDetail;
    },
    onError: (error) => error,
  });

  return queryResult;
};

export default useEventDetail;
