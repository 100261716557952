import React, { useState } from 'react';
import { Box, Paper } from '@miyagami-com/lsx-ui-components';
import { useMediaQuery, useTheme } from '@mui/material';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { GridCellParams } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';

import PerformanceOverviewChart from '../PerformanceOverviewChart';
import PerformanceOverviewHeader from '../PerformanceOverviewHeader';
import DefaultLayout from '../../Unknown/DefaultLayout';
import DataGridTable from '../../Unknown/DataGridTable';

import messages from './messages';

const PerformanceOverviewPage: React.FC = () => {
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
  const [defaultRows] = useState([
    {
      id: '1',
      transaction: 'Database',
      average: '0.15',
      min: '0',
      max: '2',
      total: '22.52',
      status: 'Active',
    },
    {
      id: '2',
      transaction: 'CPU',
      average: '0.15',
      min: '0',
      max: '2',
      total: '22.52',
      status: 'Active',
    },
    {
      id: '3',
      transaction: 'Betfair API',
      average: '0.15',
      min: '0',
      max: '2',
      total: '22.52',
      status: 'Active',
    },
    {
      id: '4',
      transaction: 'Network',
      average: '0.15',
      min: '0',
      max: '2',
      total: '22.52',
      status: 'Active',
    },
  ]);

  const [rows, setRows] = useState(defaultRows);

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.55 },
    {
      field: 'transaction',
      headerName: 'Transaction',
      editable: false,
      flex: 0.75,
    },
    {
      field: 'average',
      headerName: 'Average (ms)',
      editable: false,
      flex: 0.75,
    },
    {
      field: 'min',
      headerName: 'Min (ms)',
      editable: false,
      flex: 0.75,
    },
    {
      field: 'max',
      headerName: 'Max (ms)',
      editable: false,
      flex: 0.75,
    },
    {
      field: 'total',
      headerName: 'Total (ms)',
      editable: false,
      flex: 0.75,
    },
    {
      field: 'status',
      headerName: 'Status',
      editable: false,
      flex: 0.75,
      renderCell: (props: GridCellParams) => {
        return (
          <Box display="flex" alignItems="center">
            <Box display="flex" pr={1} color="success.main">
              <Brightness1Icon fontSize="small" />
            </Box>
            <Box>{props.value}</Box>
          </Box>
        );
      },
    },
  ];

  const path = [
    { label: intl.formatMessage(messages.system) },
    { label: intl.formatMessage(messages.performance) },
  ];

  return (
    <DefaultLayout path={path}>
      <Box p={4}>
        <Paper>
          <PerformanceOverviewHeader
            defaultRows={defaultRows}
            setRows={setRows}
            isMobile={isMobile}
          />
          <PerformanceOverviewChart />
          <DataGridTable
            tableKey="performance-overview"
            columns={columns}
            rows={rows}
            pageSize={100}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            disableSelectionOnClick
            autoHeight
            rowHeight={35}
            headerHeight={35}
          />
        </Paper>
      </Box>
    </DefaultLayout>
  );
};

export default PerformanceOverviewPage;
