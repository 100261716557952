import { defineMessages } from 'react-intl';

export default defineMessages({
  login: {
    id: 'BackofficeUser.ResetPasswordPage.login',
    defaultMessage: 'Login',
  },
  email: {
    id: 'BackofficeUser.ResetPasswordPage.email',
    defaultMessage: 'E-mail',
  },
  resetPassword: {
    id: 'BackofficeUser.ResetPasswordPage.resetPassword',
    defaultMessage: 'Reset password',
  },
  forgotPassword: {
    id: 'BackofficeUser.ResetPasswordPage.forgotPassword',
    defaultMessage: 'Forgot password',
  },
  successSendResetPassword: {
    id: 'BackofficeUser.ResetPasswordPage.successSendResetPassword',
    defaultMessage: 'Sent email for reset password!',
  },
  password: {
    id: 'BackofficeUser.ResetPasswordPage.password',
    defaultMessage: 'Password',
  },
  confirmPassword: {
    id: 'BackofficeUser.ResetPasswordPage.confirmPassword',
    defaultMessage: 'Confirm password',
  },
  textDoneResetPassword: {
    id: 'BackofficeUser.ResetPasswordPage.textDoneResetPassword',
    defaultMessage: 'Resetting password was successful done!',
  },
  authFailed: {
    id: 'BackofficeUser.ResetPasswordPage.authFailed',
    defaultMessage: 'Auth failed, please contact with administrator',
  },
  passwordValidation: {
    id: 'BackofficeUser.ResetPasswordPage.passwordValidation',
    defaultMessage:
      'The password should at least be 6 characters and contain 1 number and 1 capital letter',
  },
  confirmPasswordValidation: {
    id: 'BackofficeUser.ResetPasswordPage.confirmPasswordValidation',
    defaultMessage: 'This field is required',
  },
  errorResetPassword: {
    id: 'BackofficeUser.ResetPasswordPage.errorResetPassword',
    defaultMessage: 'Error sending reset password mail.',
  },
  errorSubmitNewPassword: {
    id: 'BackofficeUser.ResetPasswordPage.errorSubmitNewPassword',
    defaultMessage: 'Error while updating password.',
  },
  notFoundOobCode: {
    id: 'BackofficeUser.ResetPasswordPage.notFoundOobCode',
    defaultMessage: 'Not found secure code.',
  },
});
