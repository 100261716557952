import { defineMessages } from 'react-intl';

export default defineMessages({
  sender: {
    id: 'Transaction.TransactionCreationForm.sender',
    defaultMessage: 'Sender',
  },
  receiver: {
    id: 'Transaction.TransactionCreationForm.receiver',
    defaultMessage: 'Receiver',
  },
  amount: {
    id: 'Transaction.TransactionCreationForm.amount',
    defaultMessage: 'Amount',
  },
  note: {
    id: 'Transaction.TransactionCreationForm.note',
    defaultMessage: 'Note',
  },
  transfer: {
    id: 'Transaction.TransactionCreationForm.transfer',
    defaultMessage: 'Transfer',
  },
  save: {
    id: 'Transaction.TransactionCreationForm.save',
    defaultMessage: 'Save',
  },
});
