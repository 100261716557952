import { useMutation, useQueryClient, UseMutationResult } from 'react-query';
import useSupabase from './useSupabase';
import { BetfairExchangeSize } from '../../../types';

interface UpdateBetfairBetSizesParams {
  betId: string;
  newSize: number;
}

const useUpdateBetfairBetSizes = (): UseMutationResult<
  BetfairExchangeSize[] | null,
  unknown,
  UpdateBetfairBetSizesParams,
  unknown
> => {
  const supabase = useSupabase();
  const queryClient = useQueryClient();

  const updateBetfairExchangeSizes = async (
    params: UpdateBetfairBetSizesParams,
  ): Promise<BetfairExchangeSize[] | null> => {
    const { betId, newSize } = params;

    const { data } = await supabase
      .from('betfair_exchange_sizes')
      .update({ size: newSize })
      .eq('id', betId)
      .select();

    return data;
  };

  return useMutation(updateBetfairExchangeSizes, {
    onSuccess: () => {
      queryClient.invalidateQueries('exchangeBetfairSizes');
    },
  });
};

export default useUpdateBetfairBetSizes;
