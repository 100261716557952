import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Grid,
  Typography,
  Divider,
  TextField,
  CollapseBox,
  MenuItem,
  UIContext,
  LoadingButton,
  // Checkbox,
  // Box,
  // FormControlLabel,
} from '@miyagami-com/lsx-ui-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { BackOfficeUser, Brand, RootState } from '../../../../types';

import NetworkTreeList from '../NetworkTreeList';
import { useFormik } from 'formik';
import messages from './messages';
import LoadingBox from '../../Unknown/LoadingBox';
import { Player } from '../../../../types/supabase';
import { GridApi } from '@mui/x-data-grid';
import { TreeRow } from '../NetworkTreeList/createTreeRow';
import generateAvailableOptions, {
  generateAvailableOptionsHalfs,
} from '../../../common/generateAvailableOptions';
import {
  DEFAULT_AMOUNT,
  MAX_COMMISSION_RATE,
  MAX_PT_RATE,
} from '../../../common/constants';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';
import checkIsBrandOwner from '../../../common/checkIsBrandOwner';
import { useSelector } from 'react-redux';
import useUpdateUserNetworkConfig from '../../../common/hooks/useUpdateUserNetworkConfig';
// import {
//   RadioButtonCheckedIcon,
//   RadioButtonUncheckedIcon,
// } from '../../Unknown/Icons';

type ParentUserData = {
  parentUser?: BackOfficeUser | Player;
  parentUserRoleLabel?: string;
};

interface UserNetworkInformationProps {
  parentUserData: ParentUserData;
  brand: Brand;
  isLoading: boolean;
  networkTree: TreeRow[][];
  fullNetworkTree?: TreeRow[][] | null;
  userId: string;
  playerId: string | null;
  parentId?: string | null;
  onRefetchTree: () => Promise<void>;
  isPlayerPage: boolean;
}

interface NetworkConfig {
  commissionRate: number | undefined;
  positionTakingRate: number | undefined;
  minPositionTakingRate?: number;
  maxPositionTakingRate?: number;
  extraPositionTakingRate?: number;
  isExtraPTEnabled?: boolean;
}

const NetworkInformation: React.FC<UserNetworkInformationProps> = (props) => {
  const {
    parentUserData: { parentUser, parentUserRoleLabel },
    brand,
    isLoading,
    networkTree,
    userId,
    parentId,
    playerId,
    onRefetchTree,
  } = props;

  const [isExtraPositionTakingEnabled, setExtraPositionTakingEnabled] =
    useState(false);

  const dataGridRef = useRef<GridApi>();

  const { setAlert } = useContext(UIContext);

  const intl = useIntl();

  const { uid, roles } = useSelector((root: RootState) => root.user);

  const isCurrentUserAdminOrOwner = useMemo(() => {
    const isSystemAdmin = checkIsSystemAdmin({ roles });

    const isBrandOwner = checkIsBrandOwner({ roles });

    return isSystemAdmin || isBrandOwner;
  }, [roles]);

  const isPlayer = !!playerId;

  const agentNetworkConfig = networkTree?.[0]?.filter(
    (item) => item.id === userId,
  )[0];

  const parentUserNetworkConfig = networkTree?.[0]?.filter(
    (item) => item.id === parentId,
  )[0];

  const positionTakingRate = parentUserNetworkConfig?.positionTakingRate;

  const commissionRate = parentUserNetworkConfig?.commissionRate;

  const extraPositionTakingRate =
    parentUserNetworkConfig?.extraPositionTakingRate;

  const minPositionTakingRate = agentNetworkConfig?.minPositionTakingRate;

  const maxPositionTakingRate = agentNetworkConfig?.maxPositionTakingRate;

  const childUserId = parentUserNetworkConfig?.childUserId;

  const updateUserNetworkConfig = useUpdateUserNetworkConfig();

  const availablePTOptions = generateAvailableOptionsHalfs(
    DEFAULT_AMOUNT,
    MAX_PT_RATE,
    0.005,
  );

  const availableCommissionOptions = generateAvailableOptions(
    DEFAULT_AMOUNT,
    MAX_COMMISSION_RATE,
    0.001,
    3,
  );

  const availableMinPTOptions = generateAvailableOptionsHalfs(
    DEFAULT_AMOUNT,
    MAX_PT_RATE,
    0.005,
  );

  const availableMaxPTOptions = generateAvailableOptionsHalfs(
    DEFAULT_AMOUNT,
    MAX_PT_RATE,
    0.005,
  );

  useEffect(() => {
    if (!parentUserNetworkConfig) return;
    setExtraPositionTakingEnabled(!!parentUserNetworkConfig.isExtraPTEnabled);
  }, [networkTree, parentUserNetworkConfig]);

  const formik = useFormik<NetworkConfig>({
    initialValues: {
      commissionRate,
      positionTakingRate,
      minPositionTakingRate,
      maxPositionTakingRate,
      extraPositionTakingRate,
      isExtraPTEnabled: parentUserNetworkConfig?.isExtraPTEnabled,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        if (
          values.minPositionTakingRate === undefined &&
          values.maxPositionTakingRate === undefined &&
          values.extraPositionTakingRate === undefined &&
          values.positionTakingRate === undefined &&
          values.commissionRate === undefined
        )
          throw new Error('No values to update');

        if (!parentId) throw new Error('No user found');
        if (!isPlayer && !childUserId) throw new Error('No child user found');

        const updatedCommissionRate =
          values.commissionRate !== undefined
            ? values.commissionRate
            : parentUserNetworkConfig.commissionRate;

        const updatedPositionTakingRate =
          values.positionTakingRate !== undefined
            ? isExtraPositionTakingEnabled
              ? 0
              : values.positionTakingRate
            : parentUserNetworkConfig.positionTakingRate;

        const updatedMinPositionTakingRate =
          values.minPositionTakingRate !== undefined
            ? values.minPositionTakingRate
            : minPositionTakingRate;

        const updatedMaxPositionTakingRate =
          values.maxPositionTakingRate !== undefined
            ? values.maxPositionTakingRate
            : maxPositionTakingRate;

        const updatedExtraPositionTakingRate =
          values.extraPositionTakingRate !== undefined
            ? isExtraPositionTakingEnabled
              ? values.extraPositionTakingRate
              : 0
            : extraPositionTakingRate;

        const tree = networkTree;

        await updateUserNetworkConfig({
          userId: parentId,
          payload: {
            commissionRate: updatedCommissionRate,
            positionTakingRate: updatedPositionTakingRate,
            minPositionTakingRate: updatedMinPositionTakingRate,
            maxPositionTakingRate: updatedMaxPositionTakingRate,
            extraPositionTakingRate: updatedExtraPositionTakingRate,
            isExtraPTEnabled: isExtraPositionTakingEnabled,
          },
          playerId,
          childUserId: childUserId || playerId,
          networkTree: tree,
        });

        await onRefetchTree();

        setAlert({
          show: true,
          severity: 'success',
          message: intl.formatMessage(messages.networkConfigSuccessChanged),
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setAlert({
          show: true,
          severity: 'error',
          message: error?.message,
        });
      }
    },
  });

  const isPartOfNetwork =
    networkTree &&
    networkTree?.[0]?.filter((item) => item.id === uid).length > 0;

  const isParentInNetwork = parentId === uid;

  const canEditPTandCommission =
    isCurrentUserAdminOrOwner || (isPartOfNetwork && isParentInNetwork);

  return (
    <CollapseBox
      defaultValue
      label={intl.formatMessage(messages.collapseBoxLabel)}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container rowSpacing={3} pt={3}>
          <Grid px={3} spacing={4} container item xs={12}>
            <Grid item xs={12}>
              <Typography variant="h6">
                <FormattedMessage {...messages.general} />
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                fullWidth
                name="name"
                value={parentUser?.name || ''}
                label={intl.formatMessage(messages.name)}
                disabled
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                fullWidth
                name="role"
                value={parentUserRoleLabel}
                label={intl.formatMessage(messages.role)}
                disabled
              />
            </Grid>

            <>
              <Grid item xs={6} md={3}>
                <TextField
                  fullWidth
                  select
                  disabled={
                    !canEditPTandCommission || isExtraPositionTakingEnabled
                  }
                  label="PT"
                  variant="outlined"
                  name="positionTakingRate"
                  value={formik.values.positionTakingRate}
                  defaultValue={positionTakingRate}
                  key={`pt ${positionTakingRate}`}
                  onChange={formik.handleChange('positionTakingRate')}
                  children={availablePTOptions?.map(({ value, label }) => (
                    <MenuItem key={`pt ${value}`} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  fullWidth
                  select
                  label="Commission"
                  name="commissionRate"
                  variant="outlined"
                  disabled={!canEditPTandCommission}
                  value={formik.values.commissionRate}
                  defaultValue={commissionRate}
                  key={`commission ${commissionRate}`}
                  onChange={formik.handleChange('commissionRate')}
                  children={availableCommissionOptions?.map(
                    ({ value, label }) => (
                      <MenuItem key={`pt ${value}`} value={value}>
                        {label}
                      </MenuItem>
                    ),
                  )}
                />
              </Grid>
            </>
            {!isPlayer && (
              <>
                <Grid item xs={6} md={3}>
                  <TextField
                    fullWidth
                    select
                    disabled={!canEditPTandCommission}
                    label="Min PT"
                    name="minPositionTakingRate"
                    variant="outlined"
                    value={formik.values.minPositionTakingRate}
                    defaultValue={minPositionTakingRate}
                    key={`minPT ${minPositionTakingRate}`}
                    onChange={formik.handleChange('minPositionTakingRate')}
                    children={availableMinPTOptions?.map(({ value, label }) => (
                      <MenuItem key={`minPT ${value}`} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <TextField
                    fullWidth
                    select
                    disabled={!canEditPTandCommission}
                    label="Max PT"
                    name="maxPositionTakingRate"
                    id="maxPositionTakingRate"
                    defaultValue={maxPositionTakingRate}
                    key={`maxPT ${maxPositionTakingRate}`}
                    variant="outlined"
                    value={formik.values.maxPositionTakingRate}
                    onChange={formik.handleChange('maxPositionTakingRate')}
                    children={availableMaxPTOptions?.map(({ value, label }) => (
                      <MenuItem key={`maxPT ${value}`} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  {/* <Box display="flex" py={1.5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<RadioButtonUncheckedIcon />}
                          checkedIcon={
                            <RadioButtonCheckedIcon color="primary" />
                          }
                          defaultChecked={
                            parentUserNetworkConfig?.isExtraPTEnabled
                          }
                          key={`extraPT ${parentUserNetworkConfig?.isExtraPTEnabled}`}
                        />
                      }
                      onChange={(e) => {
                        const target = e.target as HTMLInputElement;
                        setExtraPositionTakingEnabled(target.checked);
                      }}
                      label={'Extra PT'}
                    />
                  </Box> */}
                </Grid>
                <Grid item xs={6} md={3}>
                  {/* <TextField
                    fullWidth
                    select
                    disabled={
                      !canEditPTandCommission || !isExtraPositionTakingEnabled
                    }
                    label="Extra PT"
                    variant="outlined"
                    value={formik.values.extraPositionTakingRate}
                    defaultValue={extraPositionTakingRate}
                    key={`extraPT ${extraPositionTakingRate}`}
                    onChange={formik.handleChange('extraPositionTakingRate')}
                    children={availableMaxPTOptions?.map(({ value, label }) => (
                      <MenuItem key={`extraPT ${value}`} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  /> */}
                </Grid>
              </>
            )}
          </Grid>
          <Grid item container padding={4}>
            <Grid item xs={12}>
              <LoadingButton
                loading={isLoading}
                type="submit"
                variant="contained"
                color="primary"
              >
                <FormattedMessage {...messages.saveButton} />
              </LoadingButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {isLoading ? (
            <LoadingBox />
          ) : (
            <NetworkTreeList
              userId={userId}
              apiRef={dataGridRef}
              trees={networkTree}
              parentId={parentId}
              brandId={brand.id}
            />
          )}
        </Grid>
      </form>
    </CollapseBox>
  );
};

export default NetworkInformation;
