import createSupabaseClient from '../../../common/createSupabaseClient';

const getLanguageTranslationsCount = async ({
  languageId,
}: {
  languageId: string;
}): Promise<number> => {
  const supabaseClient = createSupabaseClient({});

  const response = await supabaseClient
    .from('language_translations')
    .select('*', { count: 'exact', head: true })
    .eq('language_id', languageId)
    .throwOnError();

  return response.count || 0;
};

export default getLanguageTranslationsCount;
