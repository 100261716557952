import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  borderRight: {
    borderRight: '1px solid',
    borderRightColor: theme.palette.text.disabled,
  },
}));

export default useStyles;
