import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { PermissionMap, RootState } from '../../../types';

import parsePermission from '../permission/parsePermission';

import useCheckBrandIsSuspend from './useCheckBrandIsSuspend';

export type UseVerifyPermissionsReturnParams = {
  grantedPermissions: PermissionMap;
  isSuspended: boolean;
  isBrandSuspended: boolean;
};

const useVerifyPermissions = (
  permissions?: string[],
): UseVerifyPermissionsReturnParams => {
  const { permissionMap, status } = useSelector((root: RootState) => root.user);

  const isBrandSuspended = useCheckBrandIsSuspend();

  const permissionAccess = useMemo(() => {
    const map: PermissionMap = {};

    permissions?.forEach((permission) => {
      const { brandId, operation, permissionType } = parsePermission({
        permission,
      });

      const foundedBrandPermissionMap = permissionMap[brandId];

      map[permissionType] = {
        ...map[permissionType],
        [operation]: false,
      };

      if (!foundedBrandPermissionMap) return;

      const foundedPermissionType = foundedBrandPermissionMap[permissionType];

      if (!foundedPermissionType) return;

      const foundedPermissionOperation = foundedPermissionType[operation];

      map[permissionType] = {
        ...map[permissionType],
        [operation]: !!foundedPermissionOperation,
      };
    });

    return {
      grantedPermissions: map,
      isSuspended: status === 'suspend',
      isBrandSuspended,
    };
  }, [isBrandSuspended, permissionMap, permissions, status]);

  return permissionAccess;
};

export default useVerifyPermissions;
