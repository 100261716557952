import { ExtendedExchangeBet } from '../../types';
import { ExchangeBetStatus } from './hooks/useExtendedExchangeBetStatusMap';
import createExchangeBetStatus from './createExchangeBetStatus';

interface GetExchangeBetStatusParams {
  exchangeBet: ExtendedExchangeBet;
}

const getExchangeBetStatus = (
  params: GetExchangeBetStatusParams,
): ExchangeBetStatus => {
  const { exchangeBet } = params;

  const { betfairExchangeBet, is_closed } = exchangeBet;

  const { status } = betfairExchangeBet;

  const { betfairExchangeSizes } = betfairExchangeBet;

  return createExchangeBetStatus({
    betfairExchangeBetStatus: status,
    betfairExchangeSizes,
    isClosed: is_closed,
  });
};

export default getExchangeBetStatus;
