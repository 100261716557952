import {
  Box,
  DataGridPremiumProps,
  SimpleDataGridPremium,
  UIContext,
} from '@miyagami-com/lsx-ui-components';
import useColumns from './useColumns';
import useUpdateDateRangeConfig from './useUpdateDateRangeConfig';
import useBrandId from '../../../common/hooks/useBrandId';
import useFetchDateRange from './useFetchDateRange';
import { useCallback, useContext } from 'react';
import { GridCellEditCommitParams } from '@mui/x-data-grid';
import useSanitiseRows from './useSanitiseRows';
import { useIntl } from 'react-intl';
import messages from './messages';

interface DateRangeProps
  extends Omit<DataGridPremiumProps, 'columns' | 'rows'> {
  playerId?: string;
}

const DateRangeSettings: React.FC<DateRangeProps> = (props) => {
  const { playerId } = props;

  const brandId = useBrandId();
  const columns = useColumns();

  const {
    isLoading,
    data: responseData,
    refetch,
  } = useFetchDateRange(playerId);
  const { data: rows } = responseData || {};

  const sanitisedRows = useSanitiseRows(rows || []);
  const rowHeight = 40;

  const intl = useIntl();
  const { setAlert } = useContext(UIContext);

  const { mutateAsync: updateMarketTypeConfig, isLoading: isSubmitting } =
    useUpdateDateRangeConfig();

  const onRowEdit = useCallback(
    async (params: GridCellEditCommitParams, event) => {
      const { id, field, value } = params;
      // prevent click on the page to trigger update
      const isClickOnCheckbox = !event.altitudeAngle;

      if (field === 'isDisabled' && isClickOnCheckbox) {
        // quickfix to prevent click on page to trigger checkbox
        const clickEvent1 = new MouseEvent('click', {
          clientX: Math.random() * window.innerWidth,
          clientY: Math.random() * window.innerHeight,
          bubbles: true,
          cancelable: true,
        });
        document.dispatchEvent(clickEvent1);

        const clickEvent2 = new MouseEvent('click', {
          clientX: Math.random() * window.innerWidth,
          clientY: Math.random() * window.innerHeight,
          bubbles: true,
          cancelable: true,
        });
        document.dispatchEvent(clickEvent2);
        //

        const isDisabled = value as boolean;
        try {
          await updateMarketTypeConfig({
            brandId,
            playerId,
            isDisabled,
            ref: id.toString().toLowerCase(),
          });

          setAlert({
            severity: 'success',
            show: true,
            message: intl.formatMessage(messages.successUpdate),
          });
        } catch (error) {
          setAlert({
            severity: 'error',
            show: true,
            message: intl.formatMessage(messages.errorUpdate),
          });
        }

        refetch();
      }
    },
    [brandId, intl, playerId, refetch, setAlert, updateMarketTypeConfig],
  );

  return (
    <>
      <Box height={1} display="flex">
        <SimpleDataGridPremium
          columns={columns}
          rows={sanitisedRows || []}
          rowHeight={rowHeight}
          autoHeight
          disableSelectionOnClick
          onCellEditCommit={onRowEdit}
          loading={isLoading || isSubmitting}
        />
      </Box>
    </>
  );
};

export default DateRangeSettings;
