import * as Yup from 'yup';
import { REG_EXP_NUMBER_ALPHABETS } from '../../../common/constants';
import { Field } from '../../../common/validationMessages';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(20, 'Maximum 20 characters')
    .trim(Field.trim)
    .matches(REG_EXP_NUMBER_ALPHABETS, {
      message: Field.withoutSpecialCharacters,
    })
    .required(Field.required),
  ownerId: Yup.string().required(Field.required),
  pt: Yup.number().min(0).max(1).required(Field.required),
  commission: Yup.number().min(0).max(0.1).required(Field.required),
  isDirectPlayerEnabled: Yup.boolean(),
  directPlayerComission: Yup.number().when('isDirectPlayerEnabled', {
    is: true,
    then: Yup.number().min(0).max(1).required(Field.required),
    otherwise: Yup.number().nullable().optional(),
  }),
  minimumBet: Yup.number(),
  betfairBetType: Yup.string().required(),
});

export default validationSchema;
