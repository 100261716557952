import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, useParams } from 'react-router';
import { format } from 'date-fns';

import { DEFAULT_DATE_FORMAT } from '../../../common/constants';

import UserDetailPageLayout from '../../User/UserDetailPageLayout';
import UserLimitsPage from '../../User/UserLimitPage';
import UserBalancePage from '../../User/UserBalancePage';
import AgentAccountPage from '../AgentAccountPage';
import AgentSecurityPage from '../AgentSecurityPage';

import messages from './messages';
import getRoleByBrandId from '../../../common/getRoleByBrandId';
import AgentNetworkPage from '../AgentNetworkPage';
import DefaultLayout from '../../Unknown/DefaultLayout';
import LoadingBox from '../../Unknown/LoadingBox';
import useExtendedBackofficeUser from '../../../common/hooks/useExtendedBackofficeUser';
import useBrand from '../../../common/hooks/useBrand';
import useUserStats from '../../../common/hooks/useUserStats';

type QueryParams = {
  brandId: string;
  userId: string;
};

export type AgentRole = 'superAgent' | 'masterAgent' | 'agent';

const AgentDetailPage: React.FC = () => {
  const intl = useIntl();

  const { brandId, userId }: QueryParams = useParams();

  const { data: agent, isLoading: isLoadingUser } = useExtendedBackofficeUser({
    userId,
  });

  const { data: brand, isLoading: isLoadingBrand } = useBrand({ brandId });

  const { data: userStats } = useUserStats({ userId });

  const userRole = useMemo(
    () => getRoleByBrandId({ roles: agent?.roles, brandId }),
    [agent?.roles, brandId],
  );

  const agentCreatedAt = useMemo(
    () => format(new Date(agent?.created_at || 0), DEFAULT_DATE_FORMAT),
    [agent?.created_at],
  );

  const defaultLayoutProps = useMemo(
    () => ({
      path: [
        { label: intl.formatMessage(messages.playersAndAgents) },
        {
          label: intl.formatMessage(messages.agentOverview),
          url: `/b/${brandId}/agent-overview`,
        },
        { label: agent?.username || '' },
      ],
    }),
    [brandId, intl, agent?.username],
  );

  if (isLoadingBrand || isLoadingUser) {
    return (
      <DefaultLayout {...defaultLayoutProps}>
        <LoadingBox />
      </DefaultLayout>
    );
  }

  if (!brand || !agent) {
    return <Redirect to={`/b/${brandId}/agent-overview`} />;
  }

  const tabList = [
    {
      label: intl.formatMessage(messages.accountLabel),
      value: 'account',
      component: (
        <AgentAccountPage
          user={agent}
          brandId={brandId}
          role={userRole?.value as AgentRole}
        />
      ),
    },
    {
      label: intl.formatMessage(messages.networkLabel),
      value: 'network',
      component: <AgentNetworkPage user={agent} brand={brand} />,
    },
    {
      label: intl.formatMessage(messages.balanceLabel),
      value: 'balance',
      component: <UserBalancePage type="backofficeUser" />,
    },
    {
      label: intl.formatMessage(messages.securityLabel),
      value: 'security',
      component: <AgentSecurityPage agentId={userId} />,
    },
    {
      label: intl.formatMessage(messages.limitsLabel),
      value: 'limits',
      component: <UserLimitsPage type="agent" />,
    },
  ];

  return (
    <UserDetailPageLayout
      headerProps={{
        username: agent?.username || '',
        role: userRole?.label || '',
        status: agent?.status || 'inactive',
        userCreatedAt: agentCreatedAt,
        lastActivityTime: '',
        stats: userStats,
      }}
      defaultLayoutProps={{
        path: [
          { label: intl.formatMessage(messages.playersAndAgents) },
          {
            label: intl.formatMessage(messages.agentOverview),
            url: `/b/${brandId}/agent-overview`,
          },
          { label: agent?.username || '' },
        ],
      }}
      accountTabsProps={{ tabs: tabList, type: 'agent' }}
    />
  );
};

export default AgentDetailPage;
