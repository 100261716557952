import { BetSide } from '../../../../types/supabase';
import backLiability from './backLiability';
import layLiability from './layLiability';

interface LiabilityParams {
  size: number;
  price: number;
  side: BetSide | string;
}

const liability = (params: LiabilityParams): number => {
  const { side, price, size } = params;

  if (side === 'back') return backLiability({ size });

  return layLiability({ price, size });
};

export default liability;
