import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { colors } from '../theme';
import messages from '../messages';
import { ExchangeBetSide } from '../../../types';

type SideMapParams = {
  label: string;
  color: string;
};

export type ExchangeBetSideMap = Record<ExchangeBetSide, SideMapParams>;

const useExchangeBetSideMap = (): ExchangeBetSideMap => {
  const intl = useIntl();

  const exchangeBetSideMap: ExchangeBetSideMap = useMemo(() => {
    return {
      back: {
        label: intl.formatMessage(messages.backSide),
        color: colors.infoDark,
      },
      lay: {
        label: intl.formatMessage(messages.laySide),
        color: colors.errorDark,
      },
    };
  }, [intl]);

  return exchangeBetSideMap;
};

export default useExchangeBetSideMap;
