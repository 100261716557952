import { UseQueryResult, useQuery } from 'react-query';
import useSupabase from './useSupabase';
import {
  ExtendedOutcomes,
  ExchangeBet,
  BetfairExchangeBet,
} from '../../../types';
import { DEFAULT_IDS_CHUNK_SIZE, DEFAULT_FETCH_LIMIT_BETS } from '../constants';

interface UseExtendedOutcomesParams {
  playerId?: string;
  select?: string;
  dateRange: string[];
}

const fetchByIdChunks = async <T>(
  table: string,
  idField: string,
  ids: string[],
  supabase: ReturnType<typeof useSupabase>,
): Promise<T[]> => {
  let data: T[] = [];
  for (let i = 0; i < ids.length; i += DEFAULT_IDS_CHUNK_SIZE) {
    const chunk = ids.slice(i, i + DEFAULT_IDS_CHUNK_SIZE);
    const { data: chunkData, error } = await supabase
      .from(table)
      .select('*')
      .in(idField, chunk);

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    if (error) throw error;

    if (chunkData) {
      data = [...data, ...chunkData];
    }
  }

  return data;
};

const useExchangeBetOutcome = <T extends ExtendedOutcomes>(
  params: UseExtendedOutcomesParams,
): UseQueryResult<T[] | null> => {
  const { playerId, select = '*', dateRange } = params;
  const supabase = useSupabase();

  const queryResult = useQuery(
    ['betOutcome', playerId, dateRange],
    async () => {
      if (!playerId) return null;

      // adjust date range to include selected days
      const startDateString = new Date(dateRange[0])
        .toISOString()
        .split('T')[0];

      const endDate = new Date(dateRange[1]);
      endDate.setDate(endDate.getDate() + 1);
      const endDateString = endDate.toISOString().split('T')[0];

      // fetch bet outcomes data - in chunks of 1000
      let offset = 0;
      let outcomesData: T[] = [];

      let fetching = true;
      while (fetching) {
        const { data, error } = await supabase
          .from('exchange_bet_outcomes')
          .select<typeof select, T>(select)
          .order('created_at', { ascending: false })
          .eq('player_id', playerId)
          .gte('created_at', startDateString)
          .lte('created_at', endDateString)
          .range(offset, offset + DEFAULT_FETCH_LIMIT_BETS - 1);

        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        if (error) throw error;

        if (data) outcomesData = [...outcomesData, ...data];

        if (!data || data.length < DEFAULT_FETCH_LIMIT_BETS) {
          fetching = false;
        } else {
          offset += DEFAULT_FETCH_LIMIT_BETS;
        }
      }

      // fetch exchange bets by outcome id - in chunks of 100
      const outcomeIds = (outcomesData || []).map((outcome) => outcome.id);
      const exchangeBetsData: ExchangeBet[] =
        await fetchByIdChunks<ExchangeBet>(
          'exchange_bets',
          'outcome_id',
          outcomeIds,
          supabase,
        );

      // fetch betfair exchange bets by exchange bet id - in chunks of 100
      const betfairExchangeBetIds = (exchangeBetsData || []).map(
        (bet) => bet.betfair_exchange_bet_id,
      );

      const betfairExchangeBetData: BetfairExchangeBet[] =
        await fetchByIdChunks<BetfairExchangeBet>(
          'betfair_exchange_bets',
          'id',
          betfairExchangeBetIds,
          supabase,
        );

      // fetch betfair exchange sizes by betfair bet id - in chunks of 100
      const betfairExchangeBetSizesIds = (betfairExchangeBetData || []).map(
        (bet) => bet.id,
      );

      const betfairExchangeSizesData: BetfairExchangeBet[] =
        await fetchByIdChunks<BetfairExchangeBet>(
          'betfair_exchange_sizes',
          'betfair_exchange_bet_id',
          betfairExchangeBetSizesIds,
          supabase,
        );

      // build extended outcomes object
      const extendedOutcomes = (outcomesData || []).map((outcome) => {
        // for each outcome, find associated exchange bets
        const exchangeBets = (exchangeBetsData || [])
          .filter((bet) => bet.outcome_id === outcome.id)
          .map((bet) => {
            // for each bet, find associated betfair exchange bet
            const betfairExchangeBet = (betfairExchangeBetData || []).find(
              (betfairBet) => betfairBet.id === bet.betfair_exchange_bet_id,
            );

            // for each betfair exchange bet, find associated betfair exchange sizes
            const betfairExchangeSizes = (
              betfairExchangeSizesData || []
            ).filter(
              (size) => size.betfair_exchange_bet_id === betfairExchangeBet?.id,
            );

            // combine bet data with betfair exchange bet data and betfair exchange sizes
            return {
              ...bet,
              betfairExchangeBet: {
                ...betfairExchangeBet,
                betfairExchangeSizes,
              },
            };
          });

        // combine outcome data with combined bets structure
        return {
          ...outcome,
          exchange_bets: exchangeBets,
        };
      });

      // remove empty outcomes
      const filteredOutcomes = extendedOutcomes.filter(
        (outcome) => outcome.exchange_bets.length,
      );

      return filteredOutcomes;
    },
  );

  return queryResult;
};

export default useExchangeBetOutcome;
