import React, { useMemo } from 'react';
import Select from '../../Unknown/Select';
import { SelectProps } from '@mui/material';
import useExchangeBetStatusMap from '../../../common/hooks/useExchangeBetStatusMap';
import { ExchangeBetStatus } from '../../../../types';

const ExchangeBetStatusSelect: React.FC<SelectProps> = (props) => {
  const exchangeBetStatusMap = useExchangeBetStatusMap();

  const menuItems = useMemo(() => {
    const betStatuses = Object.keys(
      exchangeBetStatusMap,
    ) as ExchangeBetStatus[];

    const items = betStatuses.map((value) => {
      const { label } = exchangeBetStatusMap[value];

      const item = {
        value,
        label,
      };

      return item;
    });

    return items;
  }, [exchangeBetStatusMap]);

  return <Select {...props} menuItems={menuItems} />;
};

export default ExchangeBetStatusSelect;
