import * as Yup from 'yup';
import {
  REG_EXP_PHONE_NUMBER,
  REG_EXP_PASSWORD,
} from '../../../common/constants';
import getMessageMaxCharacters from '../../../common/getMessageMaxCharacters';

import { Password, Field } from '../../../common/validationMessages';

interface UseValidationSchemaParams {
  maxPT: number;
  maxCommission: number;
}

const useValidationSchema = (
  params: UseValidationSchemaParams,
): typeof validationSchema => {
  const { maxPT, maxCommission } = params;

  const maxCharacters = getMessageMaxCharacters(20);

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().max(20, maxCharacters).required(Field.required),
    surname: Yup.string()
      .trim()
      .max(20, maxCharacters)
      .required(Field.required),
    email: Yup.string().email().required(Field.required),
    phoneNumber: Yup.string().matches(
      REG_EXP_PHONE_NUMBER,
      'Phone number is not valid',
    ),
    // country: Yup.string().trim().required(Field.required),
    // city: Yup.string().trim().required(Field.required),
    // street: Yup.string().trim().required(Field.required),
    // zipCode: Yup.string().trim().required(Field.required),
    roleId: Yup.string().trim().required(Field.required),
    positionTakingRate: Yup.number().min(0).max(maxPT).required(Field.required),
    commissionRate: Yup.number()
      .min(0)
      .max(maxCommission)
      .required(Field.required),
    parentId: Yup.string().required(Field.required),
    parentPath: Yup.string().required(Field.required),
    brandId: Yup.string().required(Field.required),
    isActivate: Yup.boolean(),
    password: Yup.string().when('isActivate', {
      is: true,
      then: Yup.string()
        .matches(REG_EXP_PASSWORD, Password.validationMessage)
        .trim(Field.trim)
        .required(Field.required),
      otherwise: Yup.string(),
    }),
  });

  return validationSchema;
};

export default useValidationSchema;
