import {
  ExtendedBackOfficeUser,
  ExtendedPlayer,
  RiskLevel,
} from '../../../types';
import isPlayerTypeGuard from '../../common/isPlayer';

interface GetUserRiskLevelParams {
  user: ExtendedBackOfficeUser | ExtendedPlayer;
}

export type RiskLevelData = {
  riskLevel: RiskLevel | null;
};

const getUserRiskLevel = (params: GetUserRiskLevelParams): RiskLevelData => {
  const { user } = params;

  const isPlayer = isPlayerTypeGuard(user);

  if (isPlayer)
    return {
      riskLevel: user?.playerDetail?.risk_level as RiskLevel | null,
    };

  return {
    riskLevel: user?.detail.risk_level as RiskLevel | null,
  };
};

export default getUserRiskLevel;
