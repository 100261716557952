import React from 'react';
import { useIntl } from 'react-intl';
import { NameType } from 'recharts/types/component/DefaultTooltipContent';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import Paper from '@miyagami-com/lsx-ui-components/dist/components/Paper';
import Typography from '@miyagami-com/lsx-ui-components/dist/components/Typography';
import { ProfitLossReportPeriod } from '../../../../types';
import { formatCurrencyValue, getFormattedDateLabel } from './utils';
import messages from './messages';

interface TooltipContentProps extends TooltipProps<number, NameType> {
  reportPeriod: ProfitLossReportPeriod;
}

const TooltipContent: React.FC<TooltipContentProps> = (props) => {
  const { active, payload, label, reportPeriod } = props;

  const intl = useIntl();

  if (active && payload && payload.length) {
    const value = payload[0].value || 0;
    const title =
      value >= 0
        ? intl.formatMessage(messages.profit)
        : intl.formatMessage(messages.loss);

    const formattedLabel = getFormattedDateLabel({
      timestamp: label,
      reportPeriod,
    });

    const formattedValue = formatCurrencyValue({ value, withDecimal: true });

    return (
      <Paper sx={{ px: 3, py: 2 }}>
        <Typography variant="body1">{formattedLabel}</Typography>
        <Typography
          mt={1}
          variant="body1"
        >{`${title}: ${formattedValue}`}</Typography>
      </Paper>
    );
  }

  return null;
};

export default TooltipContent;
