import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '@miyagami-com/lsx-ui-components';
import formatDistance from 'date-fns/formatDistance';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import {
  RootState,
  BackOfficeUserStatus,
  ExtendedBackOfficeUser,
} from '../../../../types';

import { USER_ROLES } from '../../../common/constants';

import useCheckBrandIsSuspend from '../../../common/hooks/useCheckBrandIsSuspend';
import useUserStatuses from '../../../common/hooks/useUserStatuses';

import DetailsInformationHeader from '../../Unknown/DetailsInformationHeader';
import FormattedData from '../../Unknown/FormattedData';

import messages from './messages';
import useStyles from './useStyles';
import checkIsBrandOwner from '../../../common/checkIsBrandOwner';

type BackofficeUserAccountHeaderProps = {
  user: ExtendedBackOfficeUser;
  lastActivityTime: number | null;
  hasEditPermissions?: boolean;
  brandId: string | null;
};

const BackofficeUserAccountHeader: React.FC<BackofficeUserAccountHeaderProps> =
  ({ user, lastActivityTime, hasEditPermissions, brandId }) => {
    const history = useHistory();

    const userStatuses = useUserStatuses();

    const isBrandSuspend = useCheckBrandIsSuspend();

    const isSystemAdmin = useMemo(
      () => user.roles.some((role) => role.role_id),
      [user.roles],
    );

    const isBrandOwner = useMemo(() => {
      return checkIsBrandOwner({ roles: user.roles });
    }, [user.roles]);

    const classes = useStyles();

    const getStatusDetails = useCallback(
      (status: BackOfficeUserStatus) => {
        if (isBrandSuspend && !isSystemAdmin && !isBrandOwner) {
          return userStatuses.suspend;
        }

        if (userStatuses[status]) {
          return userStatuses[status];
        }

        return { color: '', label: '' };
      },
      [userStatuses, isBrandSuspend, isBrandOwner, isSystemAdmin],
    );

    const { brands } = useSelector((state: RootState) => state.globalData);

    const permission = useMemo(() => {
      if (isSystemAdmin) {
        const brandPermission = user?.roles.find(
          ({ role_id }) => role_id === 'systemAdmin',
        );

        return brandPermission;
      }

      const brandPermission = user.roles?.find(
        ({ brand_id: permissionBrand }) => permissionBrand === brandId,
      );

      return brandPermission;
    }, [brandId, isSystemAdmin, user.roles]);

    const [activityTime, setActivityTime] = useState<string | undefined>();

    const userMainData = {
      mainText: user.username,
      secondText: (
        <>
          <FormattedMessage
            {...messages.email}
            values={{ email: user.email }}
          />
        </>
      ),
    };

    const brand = useMemo(
      () => brands?.find((brandData) => brandData.id === brandId),
      [brands, brandId],
    );
    const message = useMemo(
      () => (isSystemAdmin ? 'system' : 'noBrand'),
      [isSystemAdmin],
    );
    const brandName = (!isSystemAdmin && brand?.name) || (
      <FormattedMessage {...messages[message]} />
    );
    const userBrandData = {
      mainText: brandName,
      secondText:
        brand && !isSystemAdmin ? (
          <>
            <FormattedMessage {...messages.addedOn} />
            <FormattedData dateValue={brand.created_at} />
          </>
        ) : null,
    };

    const userRole = useMemo(
      () => USER_ROLES.find((role) => role.value === permission?.role_id),
      [permission?.role_id],
    );

    const userRoleData = {
      mainText: userRole?.label || '',
      secondText: hasEditPermissions ? (
        <Button
          color="inherit"
          className={classes.buttonEditPermissions}
          onClick={() =>
            history.push(
              `/b/${brandId}/backoffice-users/${user.id}/permissions`,
            )
          }
        >
          <FormattedMessage {...messages.editPermissions} />
        </Button>
      ) : null,
    };

    const { color, label } = useMemo(() => {
      return getStatusDetails(user.status);
    }, [getStatusDetails, user.status]);

    const userStatusData = {
      statusColor: color || '',
      statusText: label || '',
      secondText: activityTime ? (
        <>
          <FormattedMessage
            {...messages.lastActivity}
            values={{ time: activityTime }}
          />
        </>
      ) : null,
    };

    const data = {
      left: userMainData,
      centerLeft: userBrandData,
      centerRight: userRoleData,
      right: userStatusData,
    };

    useEffect(() => {
      if (lastActivityTime) {
        setActivityTime(formatDistance(Date.now(), lastActivityTime));
      }
    }, [lastActivityTime, setActivityTime]);

    return <DetailsInformationHeader data={data} />;
  };

export default BackofficeUserAccountHeader;
