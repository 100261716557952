import React, { useState } from 'react';
import {
  Box,
  GridRenderCellParams,
  IconButton,
  Typography,
  useGridApiContext,
} from '@miyagami-com/lsx-ui-components';
import { ExpandMoreIcon } from '../Icons';
import useStyles from './useStyles';
import classNames from 'classnames';
import { SportLineRow } from './useColumns';

const GroupingDataCell: React.FC<
  GridRenderCellParams<SportLineRow, SportLineRow>
> = (params) => {
  const { row, id, rowNode } = params;

  const { children } = rowNode;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const isHaveChildren = !!children?.length;

  const { name, type } = row;

  const isMarketType = type === 'MARKET';

  const classes = useStyles({ isExpanded: isExpanded });

  const apiRef = useGridApiContext();

  const onClick = () => {
    if (isHaveChildren) {
      apiRef.current.setRowChildrenExpansion(id, !isExpanded);
      setIsExpanded((prev) => !prev);
      return;
    }

    apiRef.current.toggleDetailPanel(id);
    setIsExpanded((prev) => !prev);
  };

  const className = classNames(classes.expandMoreIcon, {
    [classes.expandMoreIcon]: isExpanded,
  });

  return (
    <Box display="flex" alignItems="center">
      {isMarketType ? null : (
        <IconButton onClick={onClick} tabIndex={-1} aria-label={name}>
          <ExpandMoreIcon className={className} fontSize="inherit" />
        </IconButton>
      )}
      <Typography variant="h5" className={classes.groupingName}>
        {name}
      </Typography>
    </Box>
  );
};

export default GroupingDataCell;
