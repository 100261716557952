import { IntlShape } from 'react-intl';
import messages from './messages';
import { ProfitLossReportLevel } from '../../../../types';

interface ReportTranslations {
  reportLevel: string;
  headerGroupTitle: string;
  tableTitle: React.ReactNode;
}

const useTranslationsByReportLevel = ({
  reportLevel,
  intl,
  name,
}: {
  reportLevel: ProfitLossReportLevel;
  intl: IntlShape;
  name?: string;
}): ReportTranslations => {
  const mapTranslationsByReportLevel: Record<
    ProfitLossReportLevel,
    ReportTranslations
  > = {
    systemAdmin: {
      tableTitle: intl.formatMessage(messages.systemLevel),
      reportLevel: intl.formatMessage(messages.system),
      headerGroupTitle: intl.formatMessage(messages.platform),
    },
    brand: {
      tableTitle: intl.formatMessage(messages.brandLevel, { name }),
      reportLevel: intl.formatMessage(messages.brand),
      headerGroupTitle: intl.formatMessage(messages.brand),
    },
    superAgent: {
      tableTitle: intl.formatMessage(messages.superAgentLevel, { name }),
      reportLevel: intl.formatMessage(messages.superAgent),
      headerGroupTitle: intl.formatMessage(messages.superAgent),
    },
    masterAgent: {
      tableTitle: intl.formatMessage(messages.masterAgentLevel, { name }),
      reportLevel: intl.formatMessage(messages.masterAgent),
      headerGroupTitle: intl.formatMessage(messages.masterAgent),
    },
    agent: {
      tableTitle: intl.formatMessage(messages.agentLevel, { name }),
      reportLevel: intl.formatMessage(messages.agent),
      headerGroupTitle: intl.formatMessage(messages.agent),
    },
    player: {
      tableTitle: intl.formatMessage(messages.agentLevel, { name }),
      reportLevel: intl.formatMessage(messages.agent),
      headerGroupTitle: intl.formatMessage(messages.agent),
    },
  };

  return mapTranslationsByReportLevel[reportLevel];
};

export default useTranslationsByReportLevel;
