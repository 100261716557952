import { defineMessages } from 'react-intl';

export default defineMessages({
  add: {
    id: 'Unknown.TableDefaultToolbar.add',
    defaultMessage: 'Add',
  },
  downloadAsCsv: {
    id: 'Unknown.TableDefaultToolbar.downloadAsCsv',
    defaultMessage: 'Download as csv',
  },
});
