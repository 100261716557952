import { UseUserStatusesMap } from '../../../common/hooks/useUserStatuses';

import { BackOfficeUserStatus } from '../../../../types';

const getUserStatus = (
  userStatuses: UseUserStatusesMap,
  status?: BackOfficeUserStatus,
  isBrandSuspended?: boolean,
): typeof emptyStatus => {
  if (isBrandSuspended) {
    return userStatuses.suspend;
  }

  if (status && userStatuses[status]) {
    return userStatuses[status];
  }

  const emptyStatus = {
    label: '',
    color: '',
  };

  return emptyStatus;
};

export default getUserStatus;
