import { FC } from 'react';
import { FormControlLabel, Checkbox } from '@miyagami-com/lsx-ui-components';
import { CheckboxProps, FormControlLabelProps } from '@mui/material';

import { RadioButtonUncheckedIcon, RadioButtonCheckedIcon } from '../Icons';

interface CheckBoxCellProps extends Omit<FormControlLabelProps, 'control'> {
  checkboxProps: CheckboxProps;
  label: string;
}

const CheckBoxCell: FC<CheckBoxCellProps> = (props) => {
  const { checkboxProps, label, ...otherProps } = props;

  return (
    <FormControlLabel
      {...otherProps}
      sx={{ '& .MuiFormControlLabel-label': { fontSize: 14 } }}
      control={
        <Checkbox
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<RadioButtonCheckedIcon color="primary" />}
          {...checkboxProps}
        />
      }
      label={label}
    />
  );
};

export default CheckBoxCell;
