import { PermissionMap } from '../../../types';

interface StringifyPermissionMapParams {
  permissionMap: PermissionMap;
  brandId: string;
}

const stringifyPermissionMap = ({
  permissionMap,
  brandId,
}: StringifyPermissionMapParams): string[] => {
  const permissionEntries = Object.entries(permissionMap);

  const stringifyPermissions = permissionEntries.flatMap(
    ([permission, operations]) => {
      const operationsEntries = Object.entries(operations);

      const operationsEqualTrue = operationsEntries.filter(
        ([, value]) => value,
      );

      const createdPermissions = operationsEqualTrue.map(
        ([operation]) => `brand/${brandId}/${permission}/${operation}`,
      );

      return createdPermissions;
    },
  );

  return stringifyPermissions;
};

export default stringifyPermissionMap;
