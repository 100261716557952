import { FC, useContext, useMemo } from 'react';
import { UIContext, LoadingButton } from '@miyagami-com/lsx-ui-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import { useFirebaseApp } from 'reactfire';
import { useSelector } from 'react-redux';

import { BackOfficeUser, RootState } from '../../../../types';

import { DEFAULT_REGION } from '../../../common/constants';

import getAllBrandUsers from '../../../common/getAllBrandUsers';
import DataGridTable from '../../Unknown/DataGridTable';

import messages from './messages';
import normalizeUserNotes, { NormalizedUserNote } from './normalizeUserNotes';
import { UserNote } from '../../../../types/supabase';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface UserNoteListProps {
  brandId: string;
  userId: string;
  notes?: UserNote[] | null;
}

const UserNoteList: FC<UserNoteListProps> = (props) => {
  const { brandId, userId, notes } = props;

  const intl = useIntl();

  const { setAlert } = useContext(UIContext);

  const { users } = useSelector((state: RootState) => state.globalData);

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const allUsers = useMemo(
    () => getAllBrandUsers({ users, brandId }),
    [brandId, users],
  );

  const userMap = useMemo(() => {
    const map = new Map<string, BackOfficeUser>();

    allUsers.forEach((user) => {
      map.set(user.id, user);
    });

    return map;
  }, [allUsers]);

  const rows = useMemo(() => {
    return normalizeUserNotes({ notes: notes || [], userMap });
  }, [notes, userMap]);

  const onDelete = async (noteId: string) => {
    try {
      const deleteUserNoteFunction = httpsCallable(
        functions,
        'back-userNote-deleteUserNote',
      );

      await deleteUserNoteFunction({
        noteId,
        userId,
        brandId,
      });
    } catch (error) {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.errorMessage),
      });
    }
  };

  const propertiesColumn = {
    editable: false,
    filterable: false,
  };

  const columns: GridColumns = [
    {
      ...propertiesColumn,
      field: 'id',
      headerName: intl.formatMessage(messages.id),
      flex: 0.5,
    },
    {
      ...propertiesColumn,
      field: 'date',
      headerName: intl.formatMessage(messages.date),
      flex: 0.7,
    },
    {
      ...propertiesColumn,
      field: 'time',
      headerName: intl.formatMessage(messages.time),
      flex: 0.5,
    },
    {
      ...propertiesColumn,
      field: 'author',
      headerName: intl.formatMessage(messages.author),
      flex: 0.7,
    },
    {
      ...propertiesColumn,
      field: 'note',
      headerName: intl.formatMessage(messages.note),
      flex: 1,
    },
    {
      ...propertiesColumn,
      field: 'isLoading',
      hide: true,
      headerName: intl.formatMessage(messages.id),
      flex: 0.5,
    },
    {
      ...propertiesColumn,
      field: 'action',
      headerName: intl.formatMessage(messages.action),
      flex: 0.6,
      renderCell: (params: GridRenderCellParams<void, NormalizedUserNote>) => {
        return (
          <LoadingButton
            color="inherit"
            onClick={() => onDelete(params.row.id)}
            loading={params.row.isLoading}
          >
            <FormattedMessage {...messages.delete} />
          </LoadingButton>
        );
      },
    },
  ];

  return (
    <DataGridTable
      tableKey="user-note-list"
      boxProps={{ pb: 0 }}
      rows={rows}
      columns={columns}
      rowsPerPageOptions={[5, 10, 15, 25]}
      disableSelectionOnClick
      autoHeight
      rowHeight={35}
      headerHeight={35}
      pagination
      hideFooterRowCount
    />
  );
};

export default UserNoteList;
