import { format } from 'date-fns';
import { Log } from '../../../../types/supabase';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from '../../../common/constants';

export interface NormalizedLog extends Log {
  date: string;
  time: string;
}

interface NormalizeLogParams {
  logs: Log[];
}

const normalizeLogs = ({ logs }: NormalizeLogParams): NormalizedLog[] => {
  const normalizedLogs = logs.map<NormalizedLog>((log) => {
    const createdAtMills = new Date(log.created_at).getTime();

    const date = format(createdAtMills, DEFAULT_DATE_FORMAT);
    const time = format(createdAtMills, DEFAULT_TIME_FORMAT);

    return {
      ...log,
      date,
      time,
    };
  });

  return normalizedLogs;
};

export default normalizeLogs;
