import { Box, IconButton, Collapse } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DataGridTable from '../Unknown/DataGridTable';
import { DEFAULT_ROWS_PER_PAGE } from '../../common/constants';
import useColumns from './useOutcomeColumns';
import { OutcomeBets } from './useGetOutcomes';
import { useState } from 'react';
import OutcomeBetsDetails from './OutcomeBetsDetails';
import { GridRenderCellParams } from '@miyagami-com/lsx-ui-components';

interface MarketBetsListProps {
  rows: OutcomeBets[];
}

const MarketBetsList = (props: MarketBetsListProps): JSX.Element => {
  const { rows } = props;
  const [openRowId, setOpenRowId] = useState<string | null>(null);

  const handleToggle = (id: string) => {
    setOpenRowId(openRowId === id ? null : id);
  };

  const columns = useColumns();
  // REF: JSX wont render correctly in useColumns
  const dynamicColumns = [
    ...columns,
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        const isOpen = openRowId === params.row.id;
        return (
          <IconButton size="small" onClick={() => handleToggle(params.row.id)}>
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        );
      },
    },
  ];

  return (
    <Box>
      <DataGridTable
        tableKey={'market-bets-list'}
        columns={dynamicColumns}
        rows={rows}
        rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE}
        autoHeight={true}
        rowHeight={35}
        headerHeight={35}
        pagination
        disableSelectionOnClick
      />
      {rows.map((row) => {
        const isOpen = openRowId === row.id;
        return (
          <Collapse in={isOpen} key={`collapse-${row.id}`}>
            <Box>
              <OutcomeBetsDetails exchangeBets={row.exchange_bets || []} />
            </Box>
          </Collapse>
        );
      })}
    </Box>
  );
};

export default MarketBetsList;
