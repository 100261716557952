import { useMemo } from 'react';
import {
  ExchangeBetCurrencyParams,
  ExchangeBetCurrencyParamsType,
} from '../../../../types';
import arrayByField from '../../../common/arrayByField';

interface UsePrimaryCurrencyParams {
  exchangeBetCurrencyParams?: ExchangeBetCurrencyParams[] | null;
}

interface ExecutePrimaryCurrencyParams {
  currencies: ExchangeBetCurrencyParams[];
}

type ExchangeBetCurrencyByType = Record<
  ExchangeBetCurrencyParamsType,
  ExchangeBetCurrencyParams
>;

const executePrimaryCurrencyParams = (
  params: ExecutePrimaryCurrencyParams,
): ExchangeBetCurrencyParams => {
  const { currencies } = params;

  const exchangeBetCurrencyByType =
    currencies.reduce<ExchangeBetCurrencyByType>((map, currency) => {
      return {
        ...map,
        [currency.type]: currency,
      };
    }, {} as ExchangeBetCurrencyByType);

  if (exchangeBetCurrencyByType?.player)
    return exchangeBetCurrencyByType.player;

  if (exchangeBetCurrencyByType?.brand) return exchangeBetCurrencyByType.brand;

  return exchangeBetCurrencyByType?.system;
};

const usePrimaryCurrencyParams = (
  params: UsePrimaryCurrencyParams,
): ExchangeBetCurrencyParams[] => {
  const { exchangeBetCurrencyParams } = params;

  return useMemo(() => {
    const currencyParamsByCurrencyCode = arrayByField({
      array: exchangeBetCurrencyParams || [],
      getId: (currency) => currency.currency_code,
    });

    const entries = Object.entries(currencyParamsByCurrencyCode);

    const primaryParams = entries.map(([, currencies]) => {
      return executePrimaryCurrencyParams({ currencies });
    });

    return primaryParams;
  }, [exchangeBetCurrencyParams]);
};

export default usePrimaryCurrencyParams;
