import { useContext } from 'react';
import { SupabaseContext } from '../../components/Unknown/SupabaseProvider';

const useSupabase = (): typeof supabase => {
  const { supabase } = useContext(SupabaseContext);

  return supabase;
};

export default useSupabase;
