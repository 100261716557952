import { AxiosInstance, AxiosResponse } from 'axios';
import { UserState } from '../../types';

export interface SignInParams {
  idToken: string;
  isFirstLogin?: boolean;
  isAfterUpdate?: boolean;
  axiosInstance: AxiosInstance;
}

export interface SignInResponse {
  session: string;
  key: string;
  supabaseAccessToken: string;
  user: UserState;
}

const signIn = (
  params: SignInParams,
): Promise<AxiosResponse<SignInResponse, Error>> => {
  const { idToken, isFirstLogin, isAfterUpdate, axiosInstance } = params;

  return axiosInstance.post<SignInResponse>('/back-auth-signIn', {
    idToken,
    isFirstLogin,
    isAfterUpdate,
  });
};

export default signIn;
