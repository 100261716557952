import axios, { AxiosInstance } from 'axios';
import { SESSION_KEY } from '../constants';
import getApiURL from '../getApiURL';
import { useCookies } from 'react-cookie';

const url = getApiURL();

const useApiClient = (): AxiosInstance => {
  const [cookies] = useCookies([SESSION_KEY]);

  return axios.create({
    baseURL: url,
    withCredentials: true,
    headers: {
      'x-auth': cookies[SESSION_KEY],
    },
  });
};

export default useApiClient;
