import { configureStore } from '@reduxjs/toolkit';

import userReducer from './user';
import globalDataReducer from './globalData';

export const store = configureStore({
  reducer: {
    user: userReducer,
    globalData: globalDataReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
