import {
  // BetEvent,
  MarketExposure,
} from '../../../common/hooks/useEventOverviewBets';

interface NormalizeEventOverviewRowsParams {
  betEvents: MarketExposure[];
}

// interface ExtendedBetEvent extends BetEvent {
//   exposure: number;
// }

const normalizeEventOverviewRows = (
  params: NormalizeEventOverviewRowsParams,
): MarketExposure[] => {
  const { betEvents } = params;

  const filteredEvents = betEvents?.filter((event) => event.exposure > 0) || [];

  const sortedBetEvents =
    filteredEvents.sort((a, b) => {
      return (
        new Date(a.eventOpenDate).getTime() -
        new Date(b.eventOpenDate).getTime()
      );
    }) || [];

  return sortedBetEvents;
};

export default normalizeEventOverviewRows;
