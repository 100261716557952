import { SupabaseClient } from '@supabase/supabase-js';
import React, { useState, useEffect } from 'react';
import { createContext, useCallback } from 'react';
import { Database } from '../../../../types/supabase.db';
import createSupabaseClient from '../../../common/createSupabaseClient';

export type SetAuth = (accessToken: string) => void;

export type RemoveAuth = () => void;

interface SupabaseContextProps {
  supabase: SupabaseClient<Database, 'public'>;
  setAuth: SetAuth;
  removeAuth: RemoveAuth;
}

export const SupabaseContext = createContext<SupabaseContextProps>(
  {} as SupabaseContextProps,
);

export const SupabaseContextProvider: React.FC = (props) => {
  const { children } = props;

  const [accessToken, setAccessToken] = useState<string | null>(null);

  const [supabase, setSupabase] = useState<SupabaseClient<Database, 'public'>>(
    () => createSupabaseClient({ accessToken }),
  );

  useEffect(() => {
    setSupabase(() => createSupabaseClient({ accessToken }));
  }, [accessToken]);

  const setAuth: SetAuth = useCallback(
    (token) => {
      setAccessToken(token);
    },
    [setAccessToken],
  );

  const removeAuth = useCallback(() => {
    setAccessToken(null);
  }, [setAccessToken]);

  return (
    <SupabaseContext.Provider value={{ supabase, setAuth, removeAuth }}>
      {children}
    </SupabaseContext.Provider>
  );
};
