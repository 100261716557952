import { useSelector } from 'react-redux';

import { RootState } from '../../../types';
import { UseQueryResult, useQuery } from 'react-query';
import useSupabase from './useSupabase';
import { UserDataGridTableConfig } from '../../../types/supabase';

type UseUserDataGridColumnsConfigProps = {
  tableKey?: string;
};

const useUserDataGridColumnsConfig = ({
  tableKey,
}: UseUserDataGridColumnsConfigProps): UseQueryResult<string[]> => {
  const { uid } = useSelector((root: RootState) => root.user);

  const supabase = useSupabase();

  const queryResult = useQuery(['dataGridConfig', tableKey, uid], async () => {
    const matchPayload: Partial<UserDataGridTableConfig> = {
      backoffice_user_id: uid,
      table_key: tableKey,
    };

    const { data } = await supabase
      .from('user_data_grid_table_configs')
      .select('*')
      .match(matchPayload)
      .maybeSingle();

    return (data?.columns || []) as string[];
  });

  return queryResult;
};

export default useUserDataGridColumnsConfig;
