import { useQuery } from 'react-query';
import useSupabase from './useSupabase';
import { Brand } from '../../../types';

interface UseBrandParams {
  brandId: string;
  select?: string;
}

const useBrand = <T extends Brand>(
  params: UseBrandParams,
): typeof queryResult => {
  const { brandId, select = '*' } = params;

  const supabase = useSupabase();

  const queryResult = useQuery<T | null, Error>(
    ['brand', brandId],
    async () => {
      const response = await supabase
        .from('brands')
        .select<typeof select, T>(select)
        .eq('id', brandId)
        .maybeSingle();

      return response.data;
    },
  );

  return queryResult;
};

export default useBrand;
