import { CollapseBox } from '@miyagami-com/lsx-ui-components';
import { Paper } from '@mui/material';
import React from 'react';
import CurrencyTable from '../../Currency/CurrencyTable';
import useCurrencyParams from '../../../common/hooks/useCurrencyParams';
import { useIntl } from 'react-intl';
import messages from './messages';

interface BrandDetailCurrencyProps {
  brandId: string;
}

const BrandDetailCurrency: React.FC<BrandDetailCurrencyProps> = (props) => {
  const { brandId } = props;

  const currencyQuery = useCurrencyParams({
    brandId,
  });

  const intl = useIntl();

  return (
    <CollapseBox defaultValue={true} label={intl.formatMessage(messages.label)}>
      <Paper>
        <CurrencyTable {...props} currencyQuery={currencyQuery} />
      </Paper>
    </CollapseBox>
  );
};

export default BrandDetailCurrency;
