import { Grid } from '@miyagami-com/lsx-ui-components';
import { useFormikContext } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ManualRegistrationFormFields } from '.';
import { RootState } from '../../../../types';
import SelectBrand from '../../Unknown/SelectBrand';
import SelectParentUser from './SelectParentUser';
import RoleSelect from './SelectRole';
import messages from './messages';

interface SelectableNetworkHeaderProps {
  isDisabled?: boolean;
}

const SelectableNetworkHeader: React.FC<SelectableNetworkHeaderProps> = ({
  isDisabled,
}) => {
  const intl = useIntl();

  const { brands } = useSelector((root: RootState) => root.globalData);

  const { values, handleBlur, errors, handleChange, touched } =
    useFormikContext<ManualRegistrationFormFields>();

  const overWriteHandleChange = (val: unknown) => {
    values.positionTakingRate = undefined;

    values.commissionRate = 0;

    values.minPositionTakingRate = 0;

    handleChange(val);
  };

  return (
    <Grid item container xs={12} spacing={3}>
      <Grid item xs={12} sm={12} md={12}>
        <SelectBrand
          name="brandId"
          variant="outlined"
          value={values.brandId}
          label={intl.formatMessage(messages.brandId)}
          onChange={overWriteHandleChange}
          onBlur={handleBlur}
          error={Boolean(errors.brandId && touched.brandId)}
          fullWidth
          brands={brands}
          disabled={isDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <RoleSelect
          name="roleId"
          variant="outlined"
          onChange={overWriteHandleChange}
          onBlur={handleBlur}
          error={Boolean(errors.roleId && touched.roleId)}
          fullWidth
          value={values.roleId}
          disabled={isDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SelectParentUser
          name="parentId"
          variant="outlined"
          onChange={overWriteHandleChange}
          onBlur={handleBlur}
          error={Boolean(errors.parentId && touched.parentId)}
          fullWidth
          value={values.parentId}
          disabled={isDisabled}
        />
      </Grid>
    </Grid>
  );
};

export default SelectableNetworkHeader;
