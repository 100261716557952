import React from 'react';
import useCurrencyFormatNumber from '../../../common/hooks/useCurrencyFormatNumber';

interface CurrencyProps {
  value: number;
}

const Currency: React.FC<CurrencyProps> = (props) => {
  const { value } = props;

  const formattedValue = useCurrencyFormatNumber({ value });

  return <>{formattedValue}</>;
};

export default Currency;
