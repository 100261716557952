import { Grid } from '@miyagami-com/lsx-ui-components';
import React from 'react';
import { useIntl } from 'react-intl';
import { Redirect, useParams } from 'react-router';
import { ExtendedExchangeBet, RootState } from '../../../../types';
import useExchangeBet from '../../../common/hooks/useExchangeBet';
import DefaultLayout from '../../Unknown/DefaultLayout';
import LoadingBox from '../../Unknown/LoadingBox';
import ExchangeBetEditing from '../ExchangeBetEditing';
import ExchangeBetHeader from '../ExchangeBetHeader';
import ExchangeBetLogOverview from '../ExchangeBetLogOverview';

import messages from './messages';
import { useSelector } from 'react-redux';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';
import useExchangeBetfairSizes from '../../../common/hooks/useExchangeBetfairSizes';

type QueryParams = {
  brandId: string;
  betId: string;
};

const ExchangeBetDetailPage: React.FC = () => {
  const intl = useIntl();
  const { betId, brandId } = useParams<QueryParams>();

  const {
    data: exchangeBet,
    isLoading: isLoadingBet,
    refetch: refetchBet,
  } = useExchangeBet<ExtendedExchangeBet>({
    betId,
    select: '*, betfairExchangeBet:betfair_exchange_bet_id(*)',
  });

  const {
    isLoading: isLoadingLog,
    data: exchangeBetLog,
    refetch: refetchLog,
  } = useExchangeBetfairSizes({
    betId,
  });

  const isSameBrand = exchangeBet?.brand_id === brandId;

  const { roles } = useSelector((root: RootState) => root.user);

  const isSystemAdmin = checkIsSystemAdmin({ roles });

  if (!isLoadingBet && !isSameBrand) {
    return <Redirect to="./" />;
  }

  const path = [
    {
      label: intl.formatMessage(messages.exchange),
    },
    {
      label: intl.formatMessage(messages.bettingOverview),
      url: `./`,
    },
    { label: betId },
  ];

  return (
    <DefaultLayout path={path}>
      <Grid container p={4}>
        {isLoadingBet || isLoadingLog ? (
          <LoadingBox />
        ) : (
          <>
            {exchangeBet && (
              <>
                <Grid item xs={12}>
                  <ExchangeBetHeader exchangeBet={exchangeBet} />
                </Grid>
                {isSystemAdmin && (
                  <Grid item container xs={12} pt={4} spacing={4}>
                    <Grid item xs={6}>
                      <ExchangeBetEditing
                        brandId={brandId}
                        exchangeBet={exchangeBet}
                        refetchBet={refetchBet}
                        refetchLog={refetchLog}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ExchangeBetLogOverview
                        data={exchangeBetLog || []}
                        isLoading={isLoadingLog}
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </>
        )}
      </Grid>
    </DefaultLayout>
  );
};

export default ExchangeBetDetailPage;
