import { DataGridPremiumProps } from '@miyagami-com/lsx-ui-components';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import useStyles from './useStyles';
import messages from './messages';
import useRowTreeStyles from './useRowTreeStyles';
import GroupingDataCell from './GroupingDataCell';
import { DEFAULT_MIN_COLUMN_WIDTH } from '../../../common/constants';

const useGroupingColumn = (): DataGridPremiumProps['groupingColDef'] => {
  const classes = useStyles({});

  const intl = useIntl();

  const rowClassName = useRowTreeStyles();

  const groupingColDef: DataGridPremiumProps['groupingColDef'] = useMemo(() => {
    return {
      headerName: intl.formatMessage(messages.sport),
      headerClassName: classes.groupingHeader,
      renderCell: (params) => <GroupingDataCell {...params} />,
      cellClassName: (cellParams) => rowClassName(cellParams.row.path),
      minWidth: DEFAULT_MIN_COLUMN_WIDTH,
    };
  }, [classes.groupingHeader, intl, rowClassName]);

  return groupingColDef;
};

export default useGroupingColumn;
