import { UseQueryResult } from 'react-query';
import useNavigationNode from '../../../common/hooks/useNavigationNode';
import { RootNode } from '../../../../types';

export type TimeRange = {
  from?: string;
  to?: string;
};

interface UseSportLineProps {
  brandId: string;
  playerId?: string;
}

const useSportLine = (
  props: UseSportLineProps,
): UseQueryResult<RootNode | null, unknown> => {
  const { brandId, playerId } = props;

  const key = playerId
    ? `exchange_${playerId}_${brandId}`
    : `exchange_${brandId}`;
  const queryState = useNavigationNode({
    key,
    brandId,
    filter: {},
    type: 'root',
    playerId,
  });

  return queryState;
};

export default useSportLine;
