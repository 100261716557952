import React, { useEffect, useMemo, useRef } from 'react';
import { useFormikContext } from 'formik';
import { Box, Grid } from '@miyagami-com/lsx-ui-components';
import { GridApi } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';

import { Brand, RootState } from '../../../../types';

import { ManualRegistrationFormFields } from '../../User/UserManualRegistration';

import NetworkTreeList from '../NetworkTreeList';

import getAllUsers from '../../../common/getAllUsers';
import { ChosenTotals } from '../../User/UserManualRegistration/UserManualRegistrationForm';
import createTreeRow, { TreeRow } from '../NetworkTreeList/createTreeRow';
import useManualRegistrationTree from '../../../common/hooks/useManualRegistrationTree';
import { MAX_COMMISSION_RATE, MAX_PT_RATE } from '../../../common/constants';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';

type SetTotalParams = {
  minPositionTakingRate: number;
  availablePT: number;
  availableCommission: number;
  maxPositionTakingRate: number;
  extraPositionTakingRate: number;
  parentUserRow?: TreeRow;
};

interface ManualRegistrationNetworkTreeProps {
  setTotals: (params: SetTotalParams) => void;
  brand: Brand;
  chosenTotals?: ChosenTotals;
}

const NetworkTree: React.FC<ManualRegistrationNetworkTreeProps> = (props) => {
  const { brand, setTotals } = props;

  const dataGridRef = useRef<GridApi>();

  const { users } = useSelector((root: RootState) => root.globalData);

  const { uid, roles } = useSelector((root: RootState) => root.user);

  const isAdmin = useMemo(() => {
    return checkIsSystemAdmin({ roles });
  }, [roles]);

  const { values } = useFormikContext<ManualRegistrationFormFields>();
  const { parentId, roleId } = values;

  const allUsers = useMemo(
    () => getAllUsers({ users, brandId: brand.id }),
    [brand.id, users],
  );

  const parentUser = allUsers.find(({ id }) => id === parentId);

  const treeParams = useMemo(() => {
    const {
      name,
      surname,
      minPositionTakingRate,
      maxPositionTakingRate,
      roleId: role,
    } = values;
    const createdUserRow = createTreeRow({
      id: '',
      role,
      name: `${name} ${surname}`,
      minPositionTakingRate,
      maxPositionTakingRate,
    });
    return {
      brandId: brand.id,
      userId: parentId,
      registeredUserId: parentUser?.id || '',
      parentPath: parentUser?.parent_path || '',
      userRole: roleId,
      newUser: createdUserRow,
    };
  }, [parentId, brand, roleId, parentUser, values]);

  const { data: newNetworkTree } = useManualRegistrationTree(treeParams);

  const manualRegistrationTree = newNetworkTree?.[0];

  useEffect(() => {
    const totalRow = manualRegistrationTree?.find((row) => row.id === 'total');

    const parentRow = manualRegistrationTree?.find(
      (row) => row.id === parentId,
    );

    if (totalRow && parentRow) {
      const availablePT = MAX_PT_RATE - totalRow.positionTakingRate;

      const availableCommission = MAX_COMMISSION_RATE - totalRow.commissionRate;

      setTotals({
        minPositionTakingRate: 0,
        availablePT,
        availableCommission,
        maxPositionTakingRate: 0,
        extraPositionTakingRate: 0,
        parentUserRow: parentRow,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualRegistrationTree, setTotals, parentId, values]);

  const displayNetworkTree = useMemo(() => {
    if (!manualRegistrationTree) return [];
    const {
      name,
      surname,
      minPositionTakingRate,
      maxPositionTakingRate,
      positionTakingRate,
      commissionRate,
      roleId: role,
    } = values;

    const parentRowIndex = manualRegistrationTree.findIndex(
      (row) => row.id === parentId,
    );

    const totalRow = [...manualRegistrationTree].pop() as TreeRow;

    const parentRow = manualRegistrationTree[parentRowIndex];

    const newParentRow = {
      ...parentRow,
      positionTakingRate: positionTakingRate,
      commissionRate: commissionRate,
    } as TreeRow;

    const createdUserRow = createTreeRow({
      id: '',
      role,
      name: `${name} ${surname}`,
      minPositionTakingRate,
      maxPositionTakingRate,
      positionTakingRate: 0,
      commissionRate: 0,
    });
    const uplineRows = manualRegistrationTree.slice(0, parentRowIndex);

    if (!isAdmin) {
      totalRow.positionTakingRate = 0;
      totalRow.minPositionTakingRate = undefined;
      totalRow.maxPositionTakingRate = undefined;
      totalRow.name = 'upline total';
    }

    const newTree = [...uplineRows, newParentRow, createdUserRow, totalRow];

    return [newTree];
  }, [manualRegistrationTree, values, parentId, isAdmin]);

  return (
    <Grid item xs={12} mt={3}>
      <Box>
        <NetworkTreeList
          userId={uid}
          apiRef={dataGridRef}
          brandId={brand.id}
          trees={displayNetworkTree}
          parentId={parentId}
        />
      </Box>
    </Grid>
  );
};

export default NetworkTree;
