import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { BrandStatus } from '../../../types';

import { colors } from '../theme';

import messages from '../messages';

export type BrandStatusObject = {
  label: string;
  color: string;
};

export type UseBrandStatusesObject = Record<BrandStatus, BrandStatusObject>;

const useBrandStatuses = (): UseBrandStatusesObject => {
  const intl = useIntl();

  return useMemo(() => {
    return {
      inactive: {
        label: intl.formatMessage(messages.inactive),
        color: colors.grey300,
      },
      active: {
        label: intl.formatMessage(messages.active),
        color: colors.successMain,
      },
      pending: {
        label: intl.formatMessage(messages.pending),
        color: colors.warningMain,
      },
      disabled: {
        label: intl.formatMessage(messages.disabled),
        color: colors.errorMain,
      },
      suspend: {
        label: intl.formatMessage(messages.suspend),
        color: colors.infoMain,
      },
    };
  }, [intl]);
};

export default useBrandStatuses;
