import {
  ApiKey,
  BrandApiKeyDomain,
  BrandApiKeyStatus,
} from '../../../../types';

export interface ApiKeyRow {
  id: string;
  createdAt: string;
  apiKeyId: string;
  domain: BrandApiKeyDomain;
  status: BrandApiKeyStatus;
  isShared: boolean;
}

interface NormalizeApiKeysParams {
  brandApiKeys?: ApiKey[] | null;
  sharedApiKeys?: ApiKey[] | null;
}

const normalizeApiKeys = (params: NormalizeApiKeysParams): ApiKeyRow[] => {
  const apiKeys: ApiKeyRow[] = [];

  const { brandApiKeys, sharedApiKeys } = params;

  if (brandApiKeys) {
    brandApiKeys.forEach((apiKey) => {
      const row: ApiKeyRow = {
        id: apiKey.id,
        apiKeyId: apiKey.id,
        domain: apiKey.domain,
        createdAt: apiKey.created_at,
        status: apiKey.status,
        isShared: false,
      };

      apiKeys.push(row);
    });
  }

  if (sharedApiKeys) {
    sharedApiKeys.forEach((apiKey) => {
      const row: ApiKeyRow = {
        id: `${apiKey.id}_${apiKey.brand_id}`,
        apiKeyId: apiKey.id,
        domain: apiKey.domain,
        createdAt: apiKey.created_at,
        status: apiKey.status,
        isShared: true,
      };

      apiKeys.push(row);
    });
  }

  return apiKeys;
};

export default normalizeApiKeys;
