import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { borderWidth } from '../../../common/theme';

const useStyles = makeStyles((theme: Theme) => ({
  tabsBorder: {
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
      paddingRight: theme.spacing(1.8),
      paddingLeft: theme.spacing(1.8),
    },
    '&:first-child': {
      borderBottomLeftRadius: borderWidth.l,
    },
    '&:last-child': {
      borderBottomRightRadius: borderWidth.l,
    },
  },
}));

export default useStyles;
